import React, { useState, useEffect } from "react";
import { CircularProgress } from '@mui/material';
import { Modal, Button }  from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import classes from "../../css/modules/ImportMaxDiffUtilityScoresModal.module.css";
import useHttpGet from "../utilities/AxiosHttpGet";
import useHttpPut from "../utilities/AxiosHttpPut";
import useHttpPost from "../utilities/AxiosHttpPost";
import MeService from "../../services/MeService";
import "../../css/swytchbackStyles.css";
import SwytchbackButton from "./controls/SwytchbackButton";
import SwytchbackFrameButton from "./controls/SwytchbackFrameButton";



function ImportMaxDiffUtilityScores(props) {

  const importPreFlightMessage = "Click 'Choose File' to select a Max Diff Utility Scores file to import."
  const importFileChosenMessage = "File selected and ready to import. Click Upload to import."
  const importImportingMessage = "Importing Max Diff data. Stand by..."
  const importDoneMessage = "Max Diff data has been successfully imported."
  const importErrorMessage = "The Max Diff data file is invalid."
  var reader = new FileReader();
  const newServerUrl = MeService.serverUrl + ":" + MeService.wm_new_port;
  
  const [importingState, setImportingState] = useState(props.importingState);
  const [importMessage, setImportMessage] = useState(importPreFlightMessage);
  const [stimuliData, setStimuliData] = useState(null);
  const [utilityScoreData, setUtilityScoreData] = useState(null);
  const [swydget, setSwydget] = useState(null);

  const { putData, putLoading, putError, put } = useHttpPut(props.user);
  const { postData, postLoading, postError, post } = useHttpPost(props.user);
  const { getData, getLoading, getError, get } = useHttpGet(props.user);

  useEffect(() => {

    setImportingState(props.importingState);
    if (props.importingState === "done") {
      setImportMessage(importDoneMessage);
    }

  }, [props.importingState]);

  const fileOnLoad = (evt) => {

    const data = evt.target.result;

    // Parse the Excel data using XLSX
    const workbook = XLSX.read(data, { type: 'array' });
    const stimuliWorksheet = workbook.Sheets["Stimuli"];
    const utilityScoreWorksheet = workbook.Sheets["Utility Scores"];

    if (utilityScoreWorksheet && stimuliWorksheet) {
      setUtilityScoreData(XLSX.utils.sheet_to_json(utilityScoreWorksheet));
      setStimuliData(XLSX.utils.sheet_to_json(stimuliWorksheet))
    }
    else {
      setImportMessage(importErrorMessage);
    }
  }

  const fileLoaded = () => {

    setImportMessage(importFileChosenMessage);
    
  }

  const handleFileChosen = (importFile) => {
    setImportMessage(importFileChosenMessage);
    reader = new FileReader();
    reader.onload = fileOnLoad;
    reader.onloadend = fileLoaded;
    reader.readAsArrayBuffer(importFile);
  }
  
  const scoresUploadedCallback = (data) => {
    close();
    console.log(data);
  }

  const uploadFile = () => {

    setImportMessage(importImportingMessage);
    setImportingState("importing");

    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + props.maxDiffBlock.event.eventId + "/wyshes/" + props.maxDiffBlock.wyshId + "/max_diff_scores";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");
    console.log(props.maxDiffBlock);
    console.log(encodedEndpoint);

    const parametersJson = {
      "stimuli": stimuliData,
      "utility_scores": utilityScoreData
    }
    post(encodedEndpoint, parametersJson, scoresUploadedCallback);
  }

  const close = () => {
    setImportMessage(importPreFlightMessage);
    setImportingState("ready");
    props.handleClose(swydget ? swydget.eventId : null);
  }

  const uploadDisabled = importingState === "importing" || importingState === "done" ? true : false;

  return (
    <div style={{width: '100%'}}>
      <div className="swytchbackFileUpload">
        <label className="swytchbackFileInput">
          <input 
            type="file" 
            onChange={e => handleFileChosen(e.target.files[0])} 
          />
          <div className="swytchbackFileUploadText">Choose File</div>
        </label>
      </div>
      {importingState === "importing" &&
          <CircularProgress size={20}/>
        }
      <div style={{color: "#ffffff", fontSize:14, marginTop: 12}}>
        {importMessage}
      </div>
      <SwytchbackFrameButton
        style={{width: '100%', marginTop: 30}}
        onClick={uploadFile}
      >
        Upload
      </SwytchbackFrameButton>
    </div>
  );
}


export default ImportMaxDiffUtilityScores;