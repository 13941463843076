import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { readString } from 'react-papaparse';
import classes from "../../../css/modules/SegmentAdminMapping.module.css";
import "../../../css/swytchbackStyles.css";
import '../../../css/swytchbackModal.css';
import MeService from '../../../services/MeService';
import meStore from '../../../stores/MeStore';
import useFileReader from '../../utilities/UseFileReader';;

const SegmentAdminMapping = ({ user, swydget, showModal, onHideAction }) => {
  const [segmentMappingDict, setSegmentMappingDict] = useState({});
  const [segmentationMappingComplete, setSegmentationMappingComplete] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [reader, setReader] = useState(new FileReader());

  useEffect(() => {
    meStore.on("meStore-postSegmentationMapping", postSegmentationMappingStart);
    meStore.on("meStore-postSegmentationMappingSuccessful", postSegmentationMappingSuccessful);

    return () => {
      meStore.removeListener("meStore-postSegmentationMapping", postSegmentationMappingStart);
      meStore.removeListener("meStore-postSegmentationMappingSuccessful", postSegmentationMappingSuccessful);
    };
  }, []);

  const postSegmentationMappingStart = () => {
    // console.log("postSegmentationMappingStart");
  };

  const postSegmentationMappingSuccessful = () => {
    setSegmentationMappingComplete(true);
  };

  const handleFileRead = (fileData) => {
    let segmentMappingArray = readString(fileData).data;

    var segmentMappingDict = {};

    for (var i = 0; i < segmentMappingArray.length; i++) {
      if (segmentMappingArray[i].length === 2) {
        segmentMappingDict[segmentMappingArray[i][0]] = typeof segmentMappingArray[i][1] === 'string' ? parseInt(segmentMappingArray[i][1], 10) : segmentMappingArray[i][1];
      }
    }

    setSegmentMappingDict(segmentMappingDict);
    setIsFileSelected(true);
    setSegmentationMappingComplete(false);
  };

  const handleFileChosen = (evt) => {
    const file = evt.target.files[0];
    readFile(file);
    
    // console.log(file);
    // const newReader = new FileReader();
    // newReader.onloadend = handleFileRead;
    // newReader.readAsText(file);
    // setReader(newReader);
  };

  const { fileContent, error, readFile } = useFileReader(handleFileRead);
  

  const displaySegmentMapping = () => {
    var statusMessage = "";

    var midCount = Object.keys(segmentMappingDict).length;

    if (segmentationMappingComplete) {
      statusMessage = "CSV File Uploaded";
    }
    else if (midCount > 0) {
      statusMessage = `Ready to submit segmentation information for ${midCount} swydget takers.`;
    }
    else {
      statusMessage = "Select a CSV file";
    }

    return (
      <div>
        <div>{statusMessage}</div>
      </div>
    );
  };

  return (
    <Modal show={showModal} onHide={onHideAction} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Import Segmantation Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <div className="widget">
            <div className='content'>
              <div className="swytchbackAdminControl">
                <input
                  type="file"
                  onChange={e => handleFileChosen(e)}
                />
              </div>
            </div>
          </div>
          <div className="widget fullWidth">
            <div className='content'>
              <div className="swytchbackAdminControl">
                {displaySegmentMapping()}
                {error && <p>Error: {error}</p>}
                {fileContent && <pre>{fileContent}</pre>}
              </div>
            </div>
          </div>  
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          className="swytchbackInactiveButton oneThird" 
          style={{marginLeft: 5,marginRight: 5}} 
          disabled={!isFileSelected} 
          onClick={() => MeService.postSegmentationMapping(user, swydget, segmentMappingDict)}
        >
          Import
        </Button>
        <Button 
          className="swytchbackActiveButton oneThird" 
          style={{marginLeft: 5,marginRight: 5}} 
          onClick={onHideAction}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>


    
  );
};

export default SegmentAdminMapping;
