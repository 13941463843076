import React, { useState, useEffect, useCallback } from 'react';
import { Image, Modal, Button }  from 'react-bootstrap';
import classes from '../../css/modules/MediaModal.module.css';
import "../../css/swydgetStyles.css";
import CivicScience from "../../hooks/swydget/CivicScience";


export default function MediaModal(props) {

  const [displayDoneButton, setDisplayDoneButton] = useState(false);

  const handleCivicDone = useCallback(

    (event) => {
      setDisplayDoneButton(true);
      if (props.onHide) {
        props.onHide();
      }
    }, []
  );

  useEffect(() => {
    document.addEventListener("civicscience:afterAskingQuestions", handleCivicDone);

    return () => {
      document.removeEventListener('civicscience:afterAskingQuestions', handleCivicDone);
    };
  });





  var cardHeight = props.cardHeight || 40;
  var cardHeightStyle = cardHeight + 'vh';
  var currentProductLink = props.currentProductLink;
  let mediaModalWidth = props.mediaModalWidth || 260;
  let mediaModalHeight = props.mediaModalHeight || 300;

  let xdApp = (currentProductLink && currentProductLink.indexOf("xd.adobe") !== -1) ? true : false;
  let civicscience = (currentProductLink && currentProductLink.indexOf("civicscience") !== -1) ? true : false;

  let mediaModalStyle = xdApp ? {
    marginLeft: 36,
    marginRight: 36,
    marginTop: 12,
    marginBottom: 12,
    padding: 0,
    width: mediaModalWidth,
    height: mediaModalHeight + 140
  } : {};

  let mediaContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minHeight: '70vh',
    backgroundColor: '#002861',
    backgroundImage: 'linear-gradient(180deg, #002861, #005EE1)',
  }

  if (props.isCurrentProductLinkImage === true) {

    return (
      <div className={`${classes.containerDivStyle}`}><Image className={`${classes.imageStyle}`} src={currentProductLink}/></div>
    );
  } else {
    return (
      <Modal style={mediaModalStyle} dialogClassName="media-modal" show={props.show} onHide={props.onHide} backdrop="static">
        <Modal.Header closeButton={civicscience === false}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{margin: 0, padding: 0}}>
          <div style={mediaContainerStyle}>
            { civicscience ?
              <CivicScience csId={"civsci-id-211520734"} targetId={"2992aa42-282b-43e4-5986-ec03bb653e77"} />
              :
              <iframe
                className={`${classes.responsiveIframeStyle}`}
                src={currentProductLink}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            }
          </div>
          </Modal.Body>
        <Modal.Footer style={{height: 55, padding: 8, margin: 0}}>
          { (displayDoneButton || (civicscience === false) )&&
            <Button style={{height: 38, padding: '4px 12px 4px 12px', borderRadius: 7}} onClick={props.onHide}>Done</Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
