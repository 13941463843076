import React from 'react';
import { Container, Row, Col, FormControl, ControlLabel, FormGroup, Modal, Button }  from 'react-bootstrap';
import loginStore from "../stores/LoginStore";
import StepNumbers from "../components/StepNumbers";
import meStore from "../stores/MeStore";
import MeService from "../services/MeService";


class CreateForcedRankSurvey extends React.Component {

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    this.createSurvey = this.createSurvey.bind(this);
    this.createEventComplete = this.createEventComplete.bind(this);
    this.openErrorModal = this.openErrorModal.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.clearForm = this.clearForm.bind(this);

    this.nextButtonStyle = {
      color: '#0059ff',
      width: '100%',
      backgroundColor: '#ffffff',
      borderColor: '#0059ff',
      marginTop: 12,
      float: 'right'
    };

    this.state = {
      question: "",
      showErrorModal: false,
    };



  }

  componentWillMount() {
    meStore.on("change", this.createEventComplete);
  }

  componentWillUnmount() {
    meStore.removeListener("change", this.createEventComplete);
  }

  openErrorModal() {
    this.setState({ showErrorModal: true });
  }

  closeErrorModal() {
    this.setState({ showErrorModal: false });
  }


  createEventComplete() {

  }

  clearForm() {
    this.setState({
      question: ""
    });
  }

  createSurvey(e) {
    e.preventDefault();

    if (this.state.question !== "") {
      MeService.createForcedRankSurvey(loginStore.getLoggedInUser(), meStore.newSwydgetTitle, this.state.question);
    }
    else {
      this.openErrorModal();
    }
  }

  handleChange(event) {
    // if (event.target.value.length > 0) {
    //   this.nextButtonStyle["backgroundColor"] = "#0059ff";
    //   this.nextButtonStyle["color"] = "#ffffff";
    // }
    // else {
    //   this.nextButtonStyle["backgroundColor"] = "#ffffff";
    //   this.nextButtonStyle["color"] = "#0059ff";
    // }


    this.setState({question: event.target.value});

  }

  render() {

    let titleStyle = {
      height: 60,
      color: '#53585f',
      textAlign: 'left',
      fontSize: 24,
      fontWeight: 600,
      paddingTop: 20
    };

    let grayTitleSpanStyle = {
      color: '#a7aaa9',
      fontSize: 24,
      fontWeight: 300,
      paddingTop: 20
    };


    let dividerStyle = {
      height: 74,
      borderBottom: 2,
      borderBottomColor: '#666',
      borderBottomStyle: 'solid',
      marginBottom: 0,
      paddingLeft: 0,
      textAlign: 'center'
    };

    let toolbarStyle = {
      paddingLeft: 5,
      paddingRight: 5
    };

    let fullWidthWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    };

    let addContentFormDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 30,
      marginRight: 30
    };

    let tableContainerStyle = {
      border: 1,
      borderColor: '#afb8c8',
      borderStyle: 'solid',
      backgroundColor: '#f7f6f7'
    };

    let tableTitleDivStyle = {
      height: 60,
      backgroundColor: '#e0e5ee',
      color: '#53585f',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
      paddingTop: 20
    };

    let questionInputStyle = {
      fontSize: 20,
      fontWeight: 300,
      height: 70
    }

    let instructionsContainerDivStyle = {
      width: '100%',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 100
    }

    let instructionsTitleDivStyle = {
      color: '#53585f',
      fontSize: 20,
      fontWeight: 300,
      marginTop: 50,
      marginBottom:50
    }

    let instructionsExampleDivStyle = {
      color: '#53585f',
      fontSize: 20,
      fontWeight: 300,
      marginBottom: 20
    }



    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col md={4} style={toolbarStyle}>
                <div style={titleStyle}><span style={grayTitleSpanStyle}>Step 1:</span> Survey Question</div>
              </Col>
              <Col md={5} style={toolbarStyle}>
                <StepNumbers idx={0} totalSteps={3}/>
              </Col>
              <Col md={3} style={toolbarStyle}>
                <button type="button" className="btn btn-primary" style={this.nextButtonStyle} onClick={this.createSurvey}>Add Survey Content</button>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={fullWidthWidgetContainerStyle}>
                <div style={tableContainerStyle}>
                  <div style={tableTitleDivStyle}>Survey Question</div>
                  <div style={addContentFormDivStyle}>
                    <FieldGroup
                      style={questionInputStyle}
                      id="inputQuestion"
                      value={this.state.question}
                      type="text"
                      placeholder="Enter your survey question..."
                      onChange={this.handleChange}
                    />
                  </div>
                  <div style={instructionsContainerDivStyle}>
                    <div style={instructionsTitleDivStyle}>Examples...</div>
                    <div style={instructionsExampleDivStyle}>Would you consider this?</div>
                    <div style={instructionsExampleDivStyle}>Would you recommend this to a friend?</div>
                    <div style={instructionsExampleDivStyle}>Were you satisfied with this?</div>
                  </div>
                </div>

              </Col>
            </Row>
            <Row>
              <Col md={12} style={dividerStyle}>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal show={this.state.showErrorModal} onHide={this.closeErrorModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Question Missing!</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              You must enter a question for the Survey.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeErrorModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <label>{label}</label>
      <FormControl {...props} />
    </FormGroup>
  );
}

export default CreateForcedRankSurvey;
