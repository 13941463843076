export default class Decision
{
  constructor() {
    this.productId = "";
    this.decisionCreatedTimestampStr = "";
    this.decisionCreatedTimestamp = 0;
    this.requestDataJson = {};
    this.resultLiteral = "";
    this.resultNormalized = 0;
    this.score = 0.0;
    this.surveyCompletedTimestamp = "";
    this.surveyCreatedTimestamp = "";
    this.eventId = "";
    this.takerMid = "";
    this.round = 0;
    this.trial = 0;
    this.freeResponseAnswers = [];
    this.segmentId = null;
    this.versionId = -1;

    this.wysh = null;
  }

  toJsonObject() {
    return {
      wysh_id: this.wysh ? this.wysh.wyshId : "",
      product_id: this.wysh ? this.wysh.product.productId: this.productId,
      decision_created_timestamp_str: this.decisionCreatedTimestampStr,
      decision_created_timestamp: this.decisionCreatedTimestamp,
      request_data_json: this.requestDataJson,
      result_literal: this.resultLiteral,
      result_normalized: this.resultNormalized,
      score: this.score,
      survey_completed_timestamp: this.surveyCompletedTimestamp,
      survey_created_timestamp: this.surveyCreatedTimestamp,
      event_id: this.eventId,
      taker_mid: this.takerMid,
      round: this.round,
      trial: this.trial,
      free_response_answers: this.freeResponseAnswers,
      segment_id: this.segment_id,
      max_diff_version_id: this.versionId
    }
  }

  static compareByResultNormalizedAsc(a, b) {
    return a.resultNormalized - b.resultNormalized;
  }

  static compareByResultNormalizedDesc(a, b) {
    return b.resultNormalized - a.resultNormalized;
  }

  static compareByCreationDateAsc(a, b) {
    return a.decisionCreatedTimestamp - b.decisionCreatedTimestamp
  }

  static compareByCreationDateDesc(a, b) {
    return b.decisionCreatedTimestamp - a.decisionCreatedTimestamp
  }

  static compareByFreeResponseLengthDesc(a, b) {
    if (a.freeResponseAnswers.length > 0 && b.freeResponseAnswers.length > 0) {
      return b.freeResponseAnswers[0].length - a.freeResponseAnswers[0].length;
    }
    else if (a.freeResponseAnswers.length > 0) {
      return -1;
    }
    else if (b.freeResponseAnswers.length > 0) {
      return 1;
    }
    else {
      return 0;
    }

  }

  static formatDate(date) {

    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return "";
      }
      else {
        // date is valid
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
      }
    }
    else {
      // not a date
      return "";
    }
  }

  static formatDateAndTime(date) {

    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return "";
      }
      else {
        // date is valid
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minutes = d.getMinutes(),
            seconds = d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        var date = [year, month, day].join('-');
        var time = [String(hour).padStart(2, '0'), String(minutes).padStart(2, '0'), String(seconds).padStart(2, '0')].join(':');
        return date + " " + time;
      }
    }
    else {
      // not a date
      return "";
    }
  }

  static formatDateAndTimeUTC(date) {

    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return "";
      }
      else {
        // date is valid
        var d = new Date(date),
            month = '' + (d.getUTCMonth() + 1),
            day = '' + d.getUTCDate(),
            year = d.getUTCFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
      }
    }
    else {
      // not a date
      return "";
    }
  }

  static formatTime(date) {

    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return "";
      }
      else {
        // date is valid
        const d = new Date(date);
        const hours = d.getHours();
        const minutes = d.getMinutes();

        let formattedHours;
        let meridiem;

        if (hours >= 12) {
          formattedHours = hours % 12 === 0 ? 12 : hours % 12;
          meridiem = 'PM';
        } else {
          formattedHours = hours === 0 ? 12 : hours;
          meridiem = 'AM';
        }

        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${meridiem}`;
      }
    }
    else {
      // not a date
      return "";
    }
  }

  static formatTimeUTC(date) {

    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return "";
      }
      else {
        // date is valid
        const d = new Date(date);
        const hours = d.getUTCHours();
        const minutes = d.getUTCMinutes();

        let formattedHours;
        let meridiem;

        if (hours >= 12) {
          formattedHours = hours % 12 === 0 ? 12 : hours % 12;
          meridiem = 'PM';
        } else {
          formattedHours = hours === 0 ? 12 : hours;
          meridiem = 'AM';
        }

        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${meridiem}`;
      }
    }
    else {
      // not a date
      return "";
    }
  }

  static getTimeZoneOffset(date) {
    const timezoneOffsetMinutes = date.getTimezoneOffset();
    const sign = timezoneOffsetMinutes >= 0 ? '-' : '+';
    const hours = Math.abs(Math.floor(timezoneOffsetMinutes / 60)).toString().padStart(2, '0');
    const minutes = (Math.abs(timezoneOffsetMinutes) % 60).toString().padStart(2, '0');
    const formattedOffset = `UTC${sign}${hours}:${minutes}`;
    return formattedOffset;
  }


  getHash() {
    return this.takerMid + this.productId;
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  initFromJson(json) {
    this.decisionCreatedTimestampStr = json["svd_created"];
    this.decisionCreatedTimestamp = new Date(this.decisionCreatedTimestampStr).getTime();
    this.productId = json["svd_prd_uuid"];
    this.requestDataJson = json["svd_request_data_json"];
    this.resultLiteral = json["svd_result_literal"];
    this.resultNormalized = json["svd_result_normalized"];
    this.score = json["svd_score"] ? json["svd_score"] : 0;
    this.surveyCompletedTimestamp = json["svt_completed"];
    this.surveyCreatedTimestamp = json["svt_created"];
    this.eventId = json["svt_event_id"];
    this.takerMid = json["svt_mid"];

    if (json["svd_event_id"]) {
      this.eventId = json["svd_event_id"]
    }

    if (json["svd_mid"]) {
      this.takerMid = json["svd_mid"]
    }

    if (json["svt_segment_id"]) {
      this.segmentId = parseInt(json["svt_segment_id"]);
    }

    if (json["svt_segment_id"]) {
      this.segmentId = parseInt(json["svt_segment_id"]);
    }

    if (json["svt_max_diff_version_id"]) {
      this.versionId = json["svt_max_diff_version_id"];
    }

    if (this.requestDataJson && this.isString(this.requestDataJson) === false && "round" in this.requestDataJson) {
      this.round = this.requestDataJson["round"];
    }

    if (this.requestDataJson && this.isString(this.requestDataJson) === false && "trial" in this.requestDataJson) {
      this.trial = this.requestDataJson["trial"];
    }

    if (this.requestDataJson && this.isString(this.requestDataJson) === false && "free_response_answers" in this.requestDataJson) {
      this.freeResponseAnswers = this.requestDataJson["free_response_answers"];
    }
  }

  static createFromJson(json)
  {
    var decision = new Decision();
    decision.initFromJson(json);
    return decision;
  }
}
