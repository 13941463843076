import React from "react";
import { useNavigate, useParams } from "react-router";
import authStore from "../stores/LoginStore";


class EventTableRow extends React.Component {

  constructor() {
    super();

    this.openSurvey = this.openSurvey.bind(this);
    this.surveyPath = "/";
  }


  openSurvey() {
    this.props.navigationHandler(this.surveyPath);
  }

  generateHasReward(rewardText, rewardUrl) {
    var hasReward = false;
    if (rewardUrl.length > 0 || rewardText.length > 0) {
      hasReward = true;
    }

    if (hasReward === true) {
      return <div>Yes</div>;
    }
    else {
      return <div></div>;
    }
  }

  render() {

    const { eventInFocus } = this.props;
    const { imageUrl } = this.props;
    const { rowTitle } = this.props;
    const { updatedDate } = this.props;
    const { eventId } = this.props;

    var creatorName = "Me";

    if (authStore.getLoggedInUser().isEqual(eventInFocus.creator) === false) {
      creatorName = eventInFocus.creator.firstName + " " + eventInFocus.creator.lastName;
    }

    var path = "survey/";
    if (eventInFocus.published === true) {
      path = "results/";
    }

    path += eventId

    this.surveyPath = path;

    var cardHeight = 7;
    var cardHeightStyle = cardHeight + 'vh';

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardHeightStyle,
      height: cardHeightStyle,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundImage: "url('" + imageUrl + "')",
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      marginBottom: 0
    };

    let trStyle = {
      height: 100,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    }

    let tdStyle = {
      verticalAlign: 'middle'
    }

    let tdNameStyle = {
      textAlign: 'left',
      verticalAlign: 'middle',
      fontWeight: 400
    }

    return (
      <tr onClick={this.openSurvey} style={trStyle}>
        <td style={tdStyle}><div style={cardStyle}></div></td>
        <td style={tdNameStyle}>{rowTitle}</td>
        <td style={tdStyle}>{creatorName}</td>
        <td style={tdStyle}>{eventInFocus.getDateUpdatedStr()}</td>
        <td style={tdStyle}>{eventInFocus.takerData.takerCompletedCount}</td>
        <td style={tdStyle}>{eventInFocus.getLastCompletionDateStr()}</td>
      </tr>
    );
  }
}

const EventTableRowWrapper = (props) => {

  const navigate = useNavigate();
  
  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <EventTableRow 
          navigationHandler={handleNavigation} 
          eventInFocus={props.eventInFocus}
          eventId={props.eventId}
          imageUrl={props.imageUrl}
          creatorName={props.creatorName}
          rowTitle={props.rowTitle}
          updatedDate={props.updatedDate}
          />
}

export default EventTableRowWrapper;