export default class Segment
{
  constructor() {
    this.segment_id = 0;
    this.ordinal = 0; // represents not in order as segment ordering begins with 1
    this.segment_name = "";
    this._created = 0;
    this._updated = 0;
  }

  equals(segment) {
    if (segment) {
      if (this.segment_id === segment.segment_id) {
        return true;
      }
    }
    return false;
  }

  equalsByOrdinal(segment) {
    if (segment) {
      if (this.ordinal === segment.ordinal) {
        return true;
      }
    }
    return false;
  }
}
