import React, { useState, useEffect }from 'react';
import { Slider } from '@mui/material';
import classes from '../../../css/modules/ForcedChoiceToolbar.module.css';
import { ArrowCircleRight } from '@mui/icons-material';


export default function ForcedChoiceToolbar(props) {

  const forcedChoiceBlock = props.forcedChoiceBlock;
  const submitFunction = props.submitFunction; 
  const sliderChangeCallback = props.sliderChangeCallback;
  const maxLimit = forcedChoiceBlock && forcedChoiceBlock.orderedWyshList.getWyshes().length > 0 ? Math.round(forcedChoiceBlock.orderedWyshList.getWyshes().length - 1) : 0;

  // Forced Choice requires ALL stimuli be seen. We use a set to track what stimuli have been seen.

  const [currentSliderValue, setCurrentSliderValue] = useState(maxLimit > 0 ? Math.round(maxLimit / 2) : 0);
  const [isOptionTextHidden, setIsOptionTextHidden] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(forcedChoiceBlock.mustViewAll);
  const [forcedChoiceSelectionSet, setForcedChoiceSelectionSet] = useState(new Set());

  const cardHeight = 45;
  const cardWidth = cardHeight * (2.5/3.5); // standard card width
  const containerWidth = cardWidth + 12;
  const nextButtonWidthStyle = cardWidth + 'vh';
  const containerWidthStyle = containerWidth + 'vh';

  useEffect(() => {
    sliderChangeCallback(currentSliderValue);
  }, []);

  var forcedChoiceWyshArray = forcedChoiceBlock.orderedWyshList.getWyshes();
  
  let toolbarContainerNoTopBorderDivStyle = {
    paddingTop: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: containerWidthStyle,
    textAlign: 'center'
  };

  let sliderWrapper = {
    width: nextButtonWidthStyle,
    marginTop: 0,
    marginBottom: 0
  };

  let activeButtonStyle = {
    width: 26,
    marginLeft: 8,
    padding: 0,
    backgroundColor: 'transparent',
    border: 0
    // WebkitBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    // MozBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Firefox 3.5 - 3.6 */
    // boxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  };

  let buttonImageStyle = {
    margin: 0,
    padding: 0
  };

  let optionsTextActiveStyle = {
    width: nextButtonWidthStyle,
    minHeight: 40,
    backgroundColor: '#ffffff',
    color: '#000000',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  let optionsTextInactiveStyle = {
    width: nextButtonWidthStyle,
    minHeight: 40,
    backgroundColor: '#ffffff',
    color: '#aeaeae',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  var optionsTextStyle = optionsTextActiveStyle;
  var optionText = forcedChoiceWyshArray[currentSliderValue].product && forcedChoiceWyshArray[currentSliderValue].product.name ? forcedChoiceWyshArray[currentSliderValue].product.name : ""

  if (isOptionTextHidden === true) {
    optionText = "Drag to view ALL, then select 1.";
    optionsTextStyle = optionsTextInactiveStyle;
  }

  const handleSliderChange = (evt, value) => {
    setCurrentSliderValue(Math.round(value));
    sliderChangeCallback(Math.round(value));
    forcedChoiceSelectionSet.add(value);
    setIsOptionTextHidden(false);
  }

  const submitForcedChoice = () => {
    setIsOptionTextHidden(false);
    setIsNextDisabled(true);
    setForcedChoiceSelectionSet(new Set());
    submitFunction(forcedChoiceWyshArray, currentSliderValue);
  }

  const handleSliderUnclicked = () => {
    console.log(forcedChoiceSelectionSet);
    if (forcedChoiceBlock.mustViewAll === false || forcedChoiceSelectionSet.size === forcedChoiceWyshArray.length) {
      // We have seen ALL the stimuli
      setIsNextDisabled(false);
    }
    else {
      setIsNextDisabled(true);
    }
  }

  return (
    <div style={toolbarContainerNoTopBorderDivStyle}>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
        }}>
        <div style={{
          width: 50,
          marginBottom: 20
          }}>

        </div>


        <div style={optionsTextStyle}>
          {optionText}
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: 50,
          marginBottom: 12,
          }}>
          { isNextDisabled === false &&
            <div className="circle pulse">
              <ArrowCircleRight
                style={{fontSize: 34}}
                className="forcedChoiceNextGlyphStyle"
                onClick={() => {
                  submitForcedChoice()
                }}
              />
            </div>
          }
        </div>
      </div>

      <div style={sliderWrapper}>
        <Slider
          id="forceChoiceSlider"
          classes={{root: `${classes.slider}`,
                    thumb: `${classes.thumb}`,
                    track: `${classes.track}`,
                    rail: `${classes.rail}`}}
          min={0}
          max={maxLimit}
          value={currentSliderValue}
          onClick={() => setIsOptionTextHidden(false)}
          onChange={handleSliderChange}
          onChangeCommitted={() => handleSliderUnclicked()}
        />
      </div>
    </div>
  );
};