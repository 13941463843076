export default class SwydgetCompletionInfo
{
  constructor(swydget) {
    this.event = swydget;
    this.authorPrivacyAcceptedPct = 0.0;
    this.completionCount = 0;
    this.completionPct = 0.0;
    this.firstCompletionInSeconds = 0;
    this.lastCompletionInSeconds = 0;
    this.message = 0;
    this.segmentIdCounts = {};
    this.swytchbackPrivacyAcceptedPct = 0.0;
    this.swytchbackTosAcceptedPct = 0.0;
  }

  toJson() {
    return {
      author_privacy_accepted_pct: this.authorPrivacyAcceptedPct,
      completion_count: this.completion_count,
      completion_pct: this.completionPct,
      first_completion_date: this.firstCompletionInSeconds,
      last_completion_date: this.lastCompletionInSeconds,
      message: this.message,
      segment_id_counts: this.segmentIdCounts,
      swytchback_privacy_accepted_pct: this.swytchbackPrivacyAcceptedPct,
      swytchback_tos_accepted_pct: this.swytchbackTosAcceptedPct
    };
  }

  getFirstCompletionDate() {
    return this._getCompletionDate(this.firstCompletionInSeconds);
  }

  getLastCompletionDate() {
    return this._getCompletionDate(this.lastCompletionInSeconds)
  }

  _getCompletionDate(timeInt) {
    return new Date(timeInt * 1000);
  }

  static createFromJson(json, swydget) {
    var compInfo = new SwydgetCompletionInfo(swydget);

    if (json.author_privacy_accepted_pct) {
      compInfo.authorPrivacyAcceptedPct = json.author_privacy_accepted_pct;
    }

    if (json.completion_count) {
      compInfo.completionCount = json.completion_count;
    }

    if (json.completion_pct) {
      compInfo.completionPct = json.completion_pct;
    }

    if (json.first_completion_date) {
      compInfo.firstCompletionInSeconds = json.first_completion_date;
    }

    if (json.last_completion_date) {
      compInfo.lastCompletionInSeconds = json.last_completion_date;
    }

    if (json.message) {
      compInfo.message = json.message;
    }

    if (json.segment_id_counts) {
      compInfo.segmentIdCounts = json.segment_id_counts;
    }

    if (json.swytchback_privacy_accepted_pct) {
      this.swytchbackPrivacyAcceptedPct = json.swytchback_privacy_accepted_pct;
    }

    if (json.swytchback_tos_accepted_pct) {
      compInfo.swytchbackTosAcceptedPct = json.swytchback_tos_accepted_pct;
    }

    return compInfo;
  }
}
