import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers';

const SwytchbackDatePicker = styled(DatePicker)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ffffff', // Normal outline color
    },
    '&:hover fieldset': {
      borderColor: '#ffffff', // Outline color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff', // Outline color when focused
    },
    '&:not(:focus-within) fieldset': {
      borderColor: '#ffffff', // Outline color when not focused
    },
  },
  '& .MuiFormLabel-root' : {
    color: '#ffffff',
  },
  '& .MuiSvgIcon-root' : {
    color: '#ffffff',
  },
  '& .MuiInputBase-root' : {
    color: '#ffffff',
  }
});

export default SwytchbackDatePicker;