import React from 'react';
import SwydgetNotAvailable from "../components/swydget/SwydgetNotAvailable";

class SwydgetNotAvailableContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      eventInFocus: {}
    };
  }


  render() {

    let leftWidgetContainerStyle = {
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 0
    }

    return (
      <div style={leftWidgetContainerStyle}>
        <SwydgetNotAvailable />
      </div>
    );
  }
}

export default SwydgetNotAvailableContainer;
