import styled from '@emotion/styled';
import { TextField } from '@mui/material';

const SwytchbackTextField = styled(TextField)({
  '& .MuiFormLabel-root': {
    color: '#ffffff'
  },
  '& .MuiInputBase-input': {
    WebkitTextFillColor: "#ffffff",
    color: "#ffffff"
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: "#aeaeae",
    color: "#aeaeae"
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    color: '#ffffff',
    borderBottomColor: '#ffffff'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    '&.Mui-focused fieldset': {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    '&.Mui-disabled fieldset': {
      color: '#ffffff',
      borderColor: '#ffffff',
    },
  },
});

export default SwytchbackTextField;


