import FilterWyshWrapper from "./FilterWyshWrapper";

export default class Filter
{
  constructor(swydget) {
    this.filterContent = this.filterContent.bind(this);
    this.isContentSelected = this.isContentSelected.bind(this);

    this.requiredPositiveWyshesForFilteringRespondents = {};
    this.attributeDictForFilteringRespondents = {};
    this.requiredSegmentIdsForFilteringRespondents = [];
    this.attributeDictForFilteringContent = {};
    this.wyshesToDisplay = [];
    this.selectedProductId = null;
    this.filterStartDate = null;
    this.filterEndDate = null;
    this.event = swydget;
  }

  /**
  * Generates a stripped down json version of the filter to be passed to the backend.
  * The Filter class has circular references that are not permitted in serially
  * representing the Filter.
  *
  * @return json respresentation without circular references
  */
  toJson() {

    var json = {}
    json.requiredPositiveWyshesForFilteringRespondents = {};

    for (var wyshId in this.requiredPositiveWyshesForFilteringRespondents) {
      json.requiredPositiveWyshesForFilteringRespondents[wyshId] = this.requiredPositiveWyshesForFilteringRespondents[wyshId].toJson();
    }

    json.requiredSegmentIdsForFilteringRespondents = this.requiredSegmentIdsForFilteringRespondents;
    json.attributeDictForFilteringRespondents = this.attributeDictForFilteringRespondents;
    json.attributeDictForFilteringContent = this.attributeDictForFilteringContent;
    json.wyshesToDisplay = {};

    for (var i = 0; i < this.wyshesToDisplay.length; i++) {
      json.wyshesToDisplay[this.wyshesToDisplay[i].wyshId] = this.wyshesToDisplay[i].product.productId;
    }

    if (this.filterStartDate) {
      json.filterStartDate = this.filterStartDate;
    }

    if (this.filterEndDate) {
      json.filterEndDate = this.filterEndDate;
    }

    if (this.selectedProductId) {
      json.selectedProductId = this.selectedProductId;;
    }

    return json;
  }


  filterContent(wyshes) {

    var filteredWyshes = [];

    for (var i = 0; i < wyshes.length; i++) {

      var passesSelectedToDisplay = this.passesContentSelectedToDisplayFilter(wyshes[i]);
      var passesAttributesFilter = this.passesContentAttributesFilter(wyshes[i]);
      var isBlock = wyshes[i].isBlock();

      // if (isBlock) {
      //   console.log(wyshes[i].product.name + " --> passesSelectedToDisplay: " + passesSelectedToDisplay + " / passesAttributesFilter:" + passesAttributesFilter);
      // }


      if (passesAttributesFilter === true && passesSelectedToDisplay === true) {
        filteredWyshes.push(wyshes[i]);
      }
      else if (passesSelectedToDisplay === true && passesAttributesFilter === false && isBlock === true) {

        // checkout my children. If any of them are on the list, push this Wysh
        var children = wyshes[i].orderedWyshList.wyshes;

        for (var j = 0; j < children.length; j++) {
          var childPasses = this.passesContentAttributesFilter(children[j]);
          if (childPasses === true) {
            filteredWyshes.push(wyshes[i]);
            break;
          }
        }
      }
    }

    return filteredWyshes;
  }

  /**
  * Determines the index of the argument wysh in the Filter's wyshesToDisplay array
  *
  * @param wysh
  * @return index of wysh (-1 if not found)
  */
  isContentSelected(wysh) {

    if (wysh === undefined || wysh === null) {
      return -1;
    }

    // Go through selected indices in the getEventInFocus
    for (var i = 0; i < this.wyshesToDisplay.length; i++) {
      if (wysh.wyshId === this.wyshesToDisplay[i].wyshId) {
        return i;
      }
    }

    return -1;
  }

  /**
  * Determines the index of the argument blockWysh in the Filter has children in the wyshesToDisplay array
  *
  * @param blockWysh
  * @return boolean indicating the block's children are in the filter
  */
  isBlockSelected(blockWysh) {

    if (blockWysh === undefined || blockWysh === null) {
      return false;
    }

    // Go through selected indices in the getEventInFocus
    for (var i = 0; i < this.wyshesToDisplay.length; i++) {
      if (blockWysh.orderedWyshList.contains(this.wyshesToDisplay[i]) === true) {
        return true;
      }
    }

    return false;
  }

  /**
  * Determines if the argument Wysh's attributes match this filter's settings
  * for required attributes. If all the filter attributes are not found in the
  * argument wysh, false is returned.
  *
  * @param wysh
  * @return boolean passes
  */
  passesContentAttributesFilter(wysh) {

    for (var key in this.attributeDictForFilteringContent) {

      if (this.attributeDictForFilteringContent.hasOwnProperty(key)) {

        var attributeValue = wysh.product.attributes[key];

        if (attributeValue === undefined) {
          // The attribute in the filter is NOT present in the product.
          // We fail!
          return false
        }
        else if (attributeValue !== this.attributeDictForFilteringContent[key])
        {
          // The attribute exists, but it does not match the value
          // of the filter.
          // we fail!
          return false
        }
      }
    }

    return true;
  }

  /**
  * If the Wysh has been explicitly selected for display, return true. If not,
  * return false. If no Wyshes have been set in the filter to display, return
  * true to display all Wyshes.
  *
  * @param wysh
  * @return boolean passes
  */
  passesContentSelectedToDisplayFilter(wysh) {

    // if (wysh.isBlock() === true) {
    //   console.log(wysh.product.name);
    // }


    if (this.wyshesToDisplay.length === 0) {
      return true;
    }

    for (var i = 0; i < this.wyshesToDisplay.length; i++) {

      // is the argument specifically listed as selected to display, return true
      if (this.wyshesToDisplay[i].wyshId === wysh.wyshId) {
        return true;
      }

      if (this.wyshesToDisplay[i].parentWysh && wysh.isBlock()) {

        // We need to see of the wysh to display is in one of my children,
        var wtdAncestor = this.wyshesToDisplay[i].parentWysh;

        for (;wtdAncestor;) {
          if (wtdAncestor.wyshId === wysh.wyshId) {
            return true;
          }

          wtdAncestor = wtdAncestor.parentWysh;
        }
      }


      // // If the argument wysh matches the parent of a wysh to display (i.e. the block card). return true
      // if (this.wyshesToDisplay[i].parentWysh && this.wyshesToDisplay[i].parentWysh.wyshId === wysh.wyshId) {
      //
      //   // The argument Wysh might be a higher order parent wysh of one of the wyshes to display
      //   // generate an array of my ancestors
      //
      //   var wtdAncestor = this.wyshesToDisplay[i].parentWysh;
      //
      //   console.log(wtdAncestor);
      //
      //
      //   for (;wtdAncestor;) {
      //     if (wtdAncestor.wyshId === wysh.wyshId) {
      //       console.log(wysh.caption + " in chain");
      //       return true;
      //     }
      //
      //     wtdAncestor = wtdAncestor.parentWysh;
      //   }
      //
      //
      //   return true;
      // }
    }

    return false;
  }

  /**
  * Determines if the argument decision array passes the filter requirements for this Event.
  *
  * @param decisionArray (of Decision objects)
  * @return boolean filter requirements satisfied
  */
  areDecisionsSignificant(decisionsArray) {

    // 1. Has the taker made decisions on the required pieces of content
    // - filter.requiredPositiveWyshesForFilteringRespondents is an array of Wyshes that the taker MUST respond positively to (> 0)
    // - Go through each required wyshId and find any corresponding decision.
    // - if a decision is positive, break and go on to the next requiredWyshId

    var positiveWyshesFound = [];
    var pwDict = {};

    for (var key in this.requiredPositiveWyshesForFilteringRespondents) {
      if (this.requiredPositiveWyshesForFilteringRespondents.hasOwnProperty(key)) {
        for (var j = 0; j < decisionsArray.length; j++) {
          if (decisionsArray[j].wysh.wyshId === this.requiredPositiveWyshesForFilteringRespondents[key].wysh.wyshId) {
            if (this.requiredPositiveWyshesForFilteringRespondents[key].selections.includes(decisionsArray[j].resultNormalized)) {


              const filterWyshWrapper = this.requiredPositiveWyshesForFilteringRespondents[key];
              if (pwDict[filterWyshWrapper.wysh.wyshId]) {
                pwDict[filterWyshWrapper.wysh.wyshId].push(decisionsArray[j].resultNormalized);
              }
              else {
                pwDict[filterWyshWrapper.wysh.wyshId] = [decisionsArray[j].resultNormalized];
              }


              positiveWyshesFound.push(decisionsArray[j].wysh);
            }
          }
        }
      }
    }

    var passes = true;
    for (const wyshId in this.requiredPositiveWyshesForFilteringRespondents) {
      var wyshPasses = false;
      if (pwDict[wyshId]) {
        const selectionsArray = pwDict[wyshId];
        for (const s of this.requiredPositiveWyshesForFilteringRespondents[wyshId].selections) {
          if (selectionsArray.includes(s)) {
            wyshPasses = true;
          }
        }
      }

      if (wyshPasses === false) {
        return false;
      }
    }
      // if (pwDict[wyshId]) {
      //   const optionsArray = pwDict[wyshId];
      //
      //   for (var s in this.requiredSegmentIdsForFilteringRespondents[wyshId].selections) {
      //     if (optionsArray.includes(s)) {
      //       passes = true;
      //     }
      //     else {
      //       console.log(s + " is missing");
      //       passes = false;
      //       break;
      //     }
      //   }
      // }



    // If the array of required wyshes encountered with a positve response does not match
    // the length of the required wyshes, the decisions are not significant. RETURN FALSE!
    // if (positiveWyshesFound.length !== Object.keys(this.requiredPositiveWyshesForFilteringRespondents).length) {
    //   if (positiveWyshesFound.length !== 0) {console.log(positiveWyshesFound.length)};
    //   return false;
    // }



    // 2. Is there a positive decision made for all of the required filter attributes
    if (Object.keys(this.attributeDictForFilteringRespondents).length > 0) {

      for (key in this.attributeDictForFilteringRespondents) {
        if (this.attributeDictForFilteringRespondents.hasOwnProperty(key)) {

          var filterPassed = false;

          for (var i = 0; i < decisionsArray.length && filterPassed === false; i++) {
            if (decisionsArray[i].resultNormalized > 0) {
              let product = decisionsArray[i].wysh.product;
              for (var productAttributeKey in product.attributes) {
                if (product.attributes[productAttributeKey] === this.attributeDictForFilteringRespondents[key]){
                  filterPassed = true;
                }
              }
            }
          }

          if (filterPassed === false) {
            return false;
          }
        }
      }
    }


    // 3. Did the taker address the minimum number of decisions required
    // BLAINE --> THIS SEEMS TO NOT BE DOING ANYTHING AS WE ARE DOING NOTHING WITH THE wyshesAnswered ARRAY!!!!
    // var wyshesAnswered = [];
    //
    // // remove duplicates
    // const seen = new Set();
    //
    // let uniqueDecisions = decisionsArray.filter(decision => {
    //   const wyshId = decision.wysh.wyshId;
    //
    //   if (seen.has(wyshId)) {
    //     return false;
    //   }
    //   seen.add(wyshId);
    //   wyshesAnswered.push(decision.wysh);
    //   return true;
    // });

    return true;
  }

  /**
  * Resets filter settings related to filtering content
  *
  * @return
  */
  resetContentFilter() {
    this.attributeDictForFilteringContent = {};
    this.wyshesToDisplay = [];
  }

  /**
  * Resets filter settings related to filtering respondents
  *
  * @return
  */
  resetRespondentFilter() {
    this.requiredPositiveWyshesForFilteringRespondents = {};
    this.requiredSegmentIdsForFilteringRespondents = [];
    this.attributeDictForFilteringRespondents = {};
  }

  /**
  * Determines if the argument wysh and the argument option are specified in the
  * filter's requiredPositiveWyshesForFilteringRespondents dictionary.
  *
  * @param wysh
  * @param choice of current wysh choice in question
  * @return boolean
  */
  isChoiceSelected(wysh, choice) {

    if (wysh.event.filter.requiredPositiveWyshesForFilteringRespondents[wysh.wyshId] !== undefined &&
        wysh.event.filter.requiredPositiveWyshesForFilteringRespondents[wysh.wyshId] !== null) {

      var wrapper = wysh.event.filter.requiredPositiveWyshesForFilteringRespondents[wysh.wyshId];

      for (var i = 0; i < wrapper.selections.length; i++) {
        if (choice === wrapper.selections[i]) {
          return true;
        }
      }
    }

    // Nothing matched
    return false
  }

  isContentSelected(wysh) {
    for (var i = 0; i < this.wyshesToDisplay.length; i++) {
      if (wysh && wysh.equals(this.wyshesToDisplay[i]) === true) {
        return true;
      }
    }

    return false;
  }

  import(snapshot) {

    if (snapshot) {
      this.requiredPositiveWyshesForFilteringRespondents = snapshot.filter.requiredPositiveWyshesForFilteringRespondents;
      this.attributeDictForFilteringRespondents = snapshot.filter.attributeDictForFilteringRespondents;
      this.requiredSegmentIdsForFilteringRespondents = snapshot.filter.requiredSegmentIdsForFilteringRespondents;
      this.attributeDictForFilteringContent = snapshot.filter.attributeDictForFilteringContent;
      this.wyshesToDisplay = snapshot.filter.wyshesToDisplay;
      this.selectedProductId = snapshot.filter.selectedProductId;
      this.filterStartDate = snapshot.filter.filterStartDate;
      this.filterEndDate = snapshot.filter.filterEndDate;
    }
  }

  static createFromJson(json, swydget) {
    var filter = new Filter(swydget);


    if (json.requiredPositiveWyshesForFilteringRespondents) {

      // Have to include FilterWyshWrapper
      for (const k in json.requiredPositiveWyshesForFilteringRespondents) {
        const wrapper = new FilterWyshWrapper(swydget.findWysh(k), json.requiredPositiveWyshesForFilteringRespondents[k]['selections']);
        filter.requiredPositiveWyshesForFilteringRespondents[k] = wrapper;
      }
    }

    if (json.attributeDictForFilteringRespondents) {
      filter.attributeDictForFilteringRespondents = json.attributeDictForFilteringRespondents;
    }

    if (json.requiredSegmentIdsForFilteringRespondents) {
      filter.requiredSegmentIdsForFilteringRespondents = json.requiredSegmentIdsForFilteringRespondents;
    }

    if (json.attributeDictForFilteringContent) {
      filter.attributeDictForFilteringContent = json.attributeDictForFilteringContent;
    }

    if (json.wyshesToDisplay) {
      // Key/value pairs of wyshIds to productIds
      filter.wyshesToDisplay = [];
      for (var wyshId in json.wyshesToDisplay) {

        filter.wyshesToDisplay.push(swydget.findWysh(wyshId))
      }
    }

    if (json.filterStartDate) {
      filter.filterStartDate = json.filterStartDate;
    }

    if (json.filterEndDate) {
      filter.filterEndDate = json.filterEndDate;
    }


    return filter
  }
}
