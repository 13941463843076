import React, { useState } from "react";
import { Button }  from 'react-bootstrap';
import classes from '../../../css/modules/StimulusAttributesComponent.module.css';
import { TextField } from "@mui/material";
import '../../../css/swytchbackModal.css';
import SwytchbackTextField from "../controls/SwytchbackTextField";
import { DeleteForever } from "@mui/icons-material";


export default function StimulusAttributesComponent(props) {

  const updateStimulusCallback = props.updateStimulusallback;
  
  const [localStimulus, setLocalStimulus] = useState(props.stimulus);
  const [newStimulusAttribute, setNewStimulusAttribute] =useState("");

  const handleNewStimulusAttributeChange = (evt) => {
    setNewStimulusAttribute(evt.target.value);
  }

  const addStimulusAttribute = () => {
    
    const stimulus = localStimulus;
    const attributeKeyValueArray = newStimulusAttribute.split("=");

    if (attributeKeyValueArray.length === 1) {
      stimulus.product.attributes[attributeKeyValueArray[0].trim()] = attributeKeyValueArray[0].trim();
    }
    else if (attributeKeyValueArray.length > 1) {
      stimulus.product.attributes[attributeKeyValueArray[0].trim()] = attributeKeyValueArray[1].trim();
    }

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleNewProductAttributeKeyPressed = (target) => {
    if (target.key === "Enter") {
      addStimulusAttribute();
      setNewStimulusAttribute("");
    }
  }

  const removeAttribute = (key, evt) => {
    const stimulus = localStimulus;
    delete stimulus.product.attributes[key];
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const generateAttributeDivs = (readOnly) => {
    const stimulus = localStimulus;
    const divStyle = {};
    const divs = [];

    const keys = Object.keys(stimulus.product.attributes);
    keys.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    if (keys.length === 0) {
      divs.push(
        <div key={'0'} className="rowContainerSpaced marginBottom">
          No Attributes
        </div>  
      );
    }

    for (var i = 0; i < keys.length; i++) {
      var attributeText = stimulus.product.attributes[keys[i]];
      if (keys[i] !== stimulus.product.attributes[keys[i]]) {
        attributeText = keys[i] + ": " + stimulus.product.attributes[keys[i]];
      }

      let key = keys[i];

      divs.push(
        <div key={i} className="rowContainerSpaced marginBottom">
          <SwytchbackTextField
            id={"hide-free-response-" + i}
            style={{width: '72%'}}
            value={attributeText}
            margin="normal"
            variant="outlined"
          />
          { readOnly === false &&
            <Button
              className="swytchbackButton textFieldHeight" 
              style={{width: '25%'}}
              onClick={(e) => removeAttribute(key, e)}>
              <DeleteForever sx={{ width: '30px', height: '30px' }} className={`${classes.removeButtonStyle}`} />
            </Button>
          }
        </div>
      );
    }

    return divs;
  }

  return (
    <div id="stimulusAttributesComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <div className="widget fullWidth">
          <div className="titleContainer">
            <div className='title'>
              Attributes:
            </div>
            <div className='instruction'>
              Add additional attributes for additional analysis
            </div>
          </div>  
          <div className='content'>
            <div className="swytchbackAdminControl">
              <div className="rowContentContainer marginBottom">
                <SwytchbackTextField
                  id="newProductAttribute"
                  style={{width: '100%'}}
                  value={newStimulusAttribute}
                  onChange={handleNewStimulusAttributeChange}
                  onKeyDown={handleNewProductAttributeKeyPressed}
                  margin="normal"
                  variant="outlined"
                  placeholder="Add attribute..."
                />
              </div>  
              {generateAttributeDivs(false)}
              
            </div>
          </div>  
        </div>
      </div>
    </div>
  );
}      
