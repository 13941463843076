import NotificationHeader from "./NotificationHeader";
import User from "../User";
import Wysh from "../Wysh";

export default class Notification
{
  constructor() {
    this.header = undefined;
    this.wysh = null;
    this.senderContact = null;
    this.title = "";
    this.photo = "";
    this.eventId = "";
    this.eventSummary = {};
    this.acceptText = "Accept";
    this.rejectText = "Reject";
  }

  initFromBackendJson(json) {
    if (typeof json === "undefined" || json.hdr === "undefined") {
      return;
    }
    else {
      if (json.hasOwnProperty("hdr")){
        this.header = NotificationHeader.createNotificationHeaderFromJson(json.hdr);
      }

      if (this.header.type === "wyshlist-created") {
        this.initConnectionRequest(json);
      }
      else if (this.header.type === "event-invite") {
        this.initSwydgetInviteRequest(json);
      }
      else if (this.header.type === "survey-available") {
        if (json['event_id']) {
          this.eventId = json['event_id']
        }
        if (json['event_summary']) {
          // console.log(json['event_summary']);
          this.eventSummary = json['event_summary']['event']
        }
        this.initSurveyAvailableNotifucation(json);
      }
      else if (this.header.type === "spreadsheet-batch-upload-complete") {
        if (json['event_id']) {
          this.eventId = json['event_id']
        }
        if (json['event_summary']) {
          console.log(json['event_summary']);
          // this.eventSummary = json['event_summary']
        }
        this.initSpreadsheetBatchUploadComplete(json);
      }
    }
  }

  initConnectionRequest(json) {
    if (json.hasOwnProperty("wysh")) {
      this.wysh = json.wysh;
    }
    if (json.hasOwnProperty("wysher_contact")) {
      this.senderContact = User.createUserFromJson(json.wysher_contact);
    }
    else if (json.hasOwnProperty("senderContact")) {
      if (json.senderContact) {
        this.senderContact = User.createUserFromJson(json.senderContact);
      }
    }

    if (this.senderContact) {
      this.title = this.senderContact.firstName + " " + this.senderContact.lastName + " wants to connect";
      this.photo = this.senderContact.getProfilePhotoUrl();
    }
    else if (json.title) {
      this.title = json.title;
    }

    if (json.photo) {
      this.photo = json.photo;
    }

    this.acceptText = "Accept";
    this.rejectText = "Reject";
  }

  initSwydgetInviteRequest(json) {
    if (json.hasOwnProperty("event_id")) {
      this.eventId = json.event_id
    }
    if (json.hasOwnProperty("eventId")) {
      this.eventId = json.eventId;
    }


    if (json.hasOwnProperty("event_summary")) {
      let creator = User.createUserFromJson(json.event_summary.event.creator_contact);
      this.title = creator.firstName + " " + creator.lastName + " shared " + json.event_summary.event.title + " with you";
      this.photo = json.event_summary.event.front_image;
      if (this.photo.indexOf("no-product-event") > 0) {

        // create the wyshes
        if (json.event_summary.hasOwnProperty("wyshes") && Object.keys(json.event_summary.wyshes).length > 0){

          let wysh = Wysh.createFromJson(json.event_summary.wyshes[0]);
          this.photo = wysh.product.imageUrl;
        }

      }
    }

    if (json.hasOwnProperty("title")) {
      this.title = json.title;
    }

    if (json.hasOwnProperty("photo")) {
      this.photo = json.photo;
    }

    this.acceptText = "Accept";
    this.rejectText = "Reject";
  }

  initSpreadsheetBatchUploadComplete(json) {

    if (json['senderContact']) {
      this.senderContact = User.createUserFromJson(json['senderContact']);
    }
    else if (json['event_summary'] && json['event_summary']['event'] && json['event_summary']['event']['creator_contact']) {
      this.senderContact = User.createUserFromJson(json['event_summary']['event']['creator_contact']);
    }

    if (this.senderContact) {
      this.photo = this.senderContact.getProfilePhotoUrl();
    }

    if (json.event_summary && json.event_summary.event && json.event_summary.event.survey_name) {
      this.title =  "Excel upload complete in " + json.event_summary.event.survey_name;
    }
    else if (json.title) {
      this.title = json.title;
    }

    if (json.hasOwnProperty("wysh")) {
      this.wysh = json.wysh;
    }

    this.acceptText = "OK"
    this.rejectText = null;
  }

  initSurveyAvailableNotifucation(json) {

    if (json['senderContact']) {
      this.senderContact = User.createUserFromJson(json['senderContact']);
    }
    else if (json['event_summary'] && json['event_summary']['event'] && json['event_summary']['event']['creator_contact']) {
      this.senderContact = User.createUserFromJson(json['event_summary']['event']['creator_contact']);
    }

    if (this.senderContact) {
      this.photo = this.senderContact.getProfilePhotoUrl();
    }

    if (json.event_summary && json.event_summary.event && json.event_summary.event.survey_name) {
      this.title =  json.event_summary.event.survey_name + " has been published.";
    }
    else if (json.title) {
      this.title = json.title;
    }

    if (json.hasOwnProperty("wysh")) {
      this.wysh = json.wysh;
    }

    this.acceptText = "OK"
    this.rejectText = null;
  }

  initFromJson(json) {
    if (typeof json === "undefined" || json.header === "undefined") {
      return;
    }
    else {
      if (json.hasOwnProperty("header")){
        this.header = NotificationHeader.createNotificationHeaderFromJson(json.header);
      }

      if (this.header.type === "wyshlist-created") {
        this.initConnectionRequest(json);
      }
      else if (this.header.type === "event-invite") {
        this.initSwydgetInviteRequest(json);
      }
      else if (this.header.type === "spreadsheet-batch-upload-complete") {
        this.initSpreadsheetBatchUploadComplete(json);
      }
      else if (this.header.type === "survey-available") {
        this.initSurveyAvailableNotifucation(json);
      }
    }
  }

  static createNotificationFromBackendJson(json) {
    var notification = new Notification();
    notification.initFromBackendJson(json);
    return notification;
  }

  static createNotificationFromJson(json) {
    var notification = new Notification();
    notification.initFromJson(json);
    return notification;
  }
}
