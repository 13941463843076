import React from "react";
import { TextField } from '@mui/material';
import classes from '../../../css/modules/PromptWidget.module.css';
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';
import SwytchbackTextField from "./SwytchbackTextField";


const PromptWidget = ({readOnly, title, value, callback}) => {

  return (
    <div className="widget fullWidth">
      <div className='title marginBottom'>
        {title}
      </div>
      <div className='content rowContainerSpaced'>
        <SwytchbackTextField
          disabled={readOnly}
          id={"defaultPrompt"}
          style={{width: '100%'}}
          value={value}
          onChange={(evt) => callback(evt)}
          margin="normal"
          variant="outlined"
          placeholder="Enter text..."
        />
      </div>  
    </div>  
  );
}

export default PromptWidget;