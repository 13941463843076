import React, { Component } from 'react';
import { Modal, Button, FormGroup, FormControl }  from 'react-bootstrap';
import "../../css/swytchbackStyles.css";
import sha256 from "js-sha256";
import loginStore from "../../stores/LoginStore";
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router';

class CredentialsModal extends Component {

  constructor() {
    super();

    this.cleanUp = this.cleanUp.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updatePasswordSuccessful = this.updatePasswordSuccessful.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      loggedInUser: loginStore.getLoggedInUser(),
      password: "",
      updateStatus: this.statusMessage
    };
  }

  componentDidMount() {
    loginStore.on("loginStore_user_logged_in", this.updatePasswordSuccessful);
  }

  componentWillUnmount() {
    loginStore.removeListener("loginStore_user_logged_in", this.updatePasswordSuccessful);
  }

  cleanUp(onHideAction) {

    this.setState({
      updateStatus: "",
      password: ""
    })

    onHideAction();

  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value})
  }

  updatePassword(onHideAction) {
    console.log(this.state.password);
    AuthService.updatePassword(this.state.loggedInUser, this.props.navigationHandler, sha256(this.state.password));
    onHideAction();
  }

  updatePasswordSuccessful() {
    this.setState({
      loggedInUser: loginStore.getLoggedInUser(),
      password: ""
    });
  }

  logout() {
    AuthService.logout(this.state.loggedInUser.userId, this.state.loggedInUser.password)
  }

  render() {

    // trigger to display the modal
    const { showModal } = this.props;

    // The method to execute when the modal is closed or cancelled manually
    const { onHideAction } = this.props;

    let inputStyle = {
      height: 50,
      width: '100%',
      backgroundColor: '#f1f2f6',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    };

    let messageDivStyle = {
      marginTop: 12,
      marginBottom: 12
    };

    let modalHeaderStyle = {
      backgroundColor: '#eceef0',
      color: '#606368'
    };

    return (
      <Modal show={showModal} onHide={() => this.cleanUp(onHideAction)} backdrop="static">
        <Modal.Header style={modalHeaderStyle} closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
          <FieldGroup
            id="swytchback_password"
            style={inputStyle}
            type="text"
            value={this.state.password}
            placeholder="Enter New Password"
            onChange={this.handlePasswordChange}
          />
        </div>
        <div style={messageDivStyle}>{this.statusMessage}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="swytchbackActiveButton" onClick={() => this.updatePassword(onHideAction)}>Reset</Button>
        </Modal.Footer>
      </Modal>

    );
  }
}

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <FormControl {...props} />
    </FormGroup>
  );
}

const CredentialsModalWrapper = (props) => {

  const navigate = useNavigate();
  
  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <CredentialsModal navigationHandler={handleNavigation} showModal={props.showModal} onHideAction={props.onHideAction}/>
}

export default CredentialsModalWrapper;
