import PairwiseTrialSet from "./PairwiseTrialSet";

export default class PairwiseVersion
{
  constructor() {
    this.version = -1;
    this.trialSets = [];
    this.wyshes = [];
  }

  getTrialSets(blockWysh) {

    // Here we are going to go through the ORDERED wyshes of the block and 
    // return an ORDERED array of Wyshes.
    // IF there are more items in the trial set than Wyshes, return empty array
    // This can indicated something is wrong

    const orderedWyshes =  blockWysh.orderedWyshList.getOrderedWyshes();
    
    for (const ts of this.trialSets) {
      ts.setWyshes(orderedWyshes);
    }

    // shuffle the array
    for (let i = this.trialSets.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.trialSets[i], this.trialSets[j]] = [this.trialSets[j], this.trialSets[i]];
    }
    
    // const auditMap = new Map();

    // for (const ts of this.trialSets) {
    //   for (const itemIndex of ts.itemIndices) {
    //     if (auditMap.has(itemIndex)) {
    //       let itemCount = auditMap.get(itemIndex)
    //       itemCount += 1;
    //       auditMap.set(itemIndex, itemCount)
    //     }
    //     else {
    //       auditMap.set(itemIndex, 1)
    //     }
    //   }
    // }

    // for (let [key, value] of auditMap) {
    //   console.log(key + ' = ' + value);
    // }
    
    return this.trialSets;
  }


  initFromJson(json) {
    if (json) {
      if ("version" in json) {
        this.version = json.version;
      }
      if ("trial_sets" in json) {
        for (const tsJson of json.trial_sets) {
          this.trialSets.push(PairwiseTrialSet.createFromJson(tsJson, this));
        }
      }
    }
  }

  static createFromJson(json)
  {
    var version = new PairwiseVersion();
    version.initFromJson(json);
    return version;
  }
}