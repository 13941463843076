import { EventEmitter } from "events";
import dispatcher from "../Dispatcher";

class SwydgetStore extends EventEmitter {
  constructor() {
    super();
    this.swydget = null;
    this.surveyTakerKey = null;
    this.surveyTakerMid = null;
    this.mostRecentDecision = "";
    this.mostRecentDecisionEventId = "";
    this.mostRecentDecisionProductId = "";
    this.completionData = [];
    this.sessionTimestamp = 0;
    this.versionId = -1;
  }

  getSwydgetInFocus() {
    return this.swydget;
  }

  handleActions(action) {

    switch(action.type) {
      case "SWYDGET_POST_PRODUCT_DECISION": {
        this.emit("change-SWYDGET_POST_PRODUCT_DECISION");
        break;
      }
      case "SWYDGET_POST_PRODUCT_DECISION_SUCCESSFUL": {
        this.surveyTakerKey = action.userKey;
        this.surveyTakerKey["v"] = this.surveyTakerKey["v"].toLowerCase();
        this.surveyTakerMid = action.surveyTakerMid;
        this.mostRecentDecision = action.decision;
        this.mostRecentDecisionEventId = action.eventId;
        this.mostRecentDecisionProductId = action.productId;
        this.emit("change-userKeySet");
        break;
      }
      case "SWYDGET_REGISTER_ANONYMOUS_USER_SUCCESSFUL": {
        this.surveyTakerKey = action.userKey;
        this.surveyTakerKey["v"] = this.surveyTakerKey["v"].toLowerCase();
        this.surveyTakerMid = action.surveyTakerMid;
        this.mostRecentDecisionEventId = action.eventId;
        this.emit("change-anonymousKeySet");
        break;
      }
      case "SWYDGET_GET_SESSION_TIMESTAMP_SUCCESSFUL": {
        this.swydget = action.eventInFocus;
        this.sessionTimestamp = action.sessionTimestamp;
        this.versionId = this.swydget ? this.swydget.getVersionId() : -1;
        this.emit("swydget-sessionTimestampSet");
        break;
      }
      case "SWYDGET_RESOLVE_KEY_SUCCESSFUL": {
        this.surveyTakerKey = action.validatedKey;
        this.surveyTakerKey["v"] = this.surveyTakerKey["v"].toLowerCase();
        this.emit("change-userKeyResolved");
        break;
      }
      case "SWYDGET_FETCH_COMPLETION_DATA_SUCCESSFUL": {
        this.completionData = action.stats;
        this.emit("change-fetchCompletionDataSuccessful");
        break;
      }
      default: {

      }
    }
  }
}

const swydgetStore = new SwydgetStore();
dispatcher.register(swydgetStore.handleActions.bind(swydgetStore));

export default swydgetStore;
