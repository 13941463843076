import { EventEmitter } from "events";
import dispatcher from "../Dispatcher";
import User from "../classes/User";
import Event from "../classes/Event";
import Decision from "../classes/Decision";
import WyshMeProduct from "../classes/WyshMeProduct";
import SurveyStatus from "../classes/SurveyStatus";
import * as Utilities from "../services/Utilities";

class MeStore extends EventEmitter {
  constructor() {
    super();
    this.surveyStatusArray = [];
    this.myEvents = [];
    this.myProducts = [];
    this.myProductsTotalSize = 0;
    this.myEventsCurrentWorkspaceTotalSize = 0;
    this.surveyCreatorProductLibrary = [];
    this.latestCreatedEventId = "";
    this.eventInFocus = null;
    this.decisions = [];
    this.productInFocus = {};
    this.freshlyCreatedProduct = "";
    this.friendsToInvite = [];
    this.nonFriendsToInvite = [];
    this.nonFriendsInvitedSuccessfully = [];
    this.surveyTakers = {};
    this.newSwydgetTitle = "";
    this.wordFrequencyArray = [];
    this.midToMdv = {};
  }

  getMyEvents() {
    return this.myEvents;
  }

  getMyProducts() {
    return this.myProducts;
  }

  getSurveyCreatorProductLibrary() {
    return this.surveyCreatorProductLibrary;
  }

  getEventInFocus() {
    return this.eventInFocus;
  }

  getSurveyTakers() {
    return this.surveyTakers;
  }

  getFreshlyCreatedProduct() {
    return this.freshlyCreatedProduct;
  }

  setFriendsToInvite(friends) {
    this.friendsToInvite = friends;
  }

  setNonFriendsToInvite(prospectiveFriends) {
    this.nonFriendsToInvite = prospectiveFriends;
  }

  setMyProducts(productsJsonArray) {
    this.myProducts = [];
    for (var i = 0; i < productsJsonArray.length; i++) {
      this.myProducts.push(WyshMeProduct.createFromRdbmsJson(productsJsonArray[i]));
    }
  }

  setSurveyCreatorProductLibrary(productsJsonArray) {
    this.surveyCreatorProductLibrary = [];
    for (var i = 0; i < productsJsonArray.length; i++) {
      this.surveyCreatorProductLibrary.push(WyshMeProduct.createFromRdbmsJson(productsJsonArray[i]));
    }
  }

  processCompletionInfo(completionInfo) {
    if ('swydgets_completion_info_dict' in completionInfo) {
      for (var key in completionInfo.swydgets_completion_info_dict) {
        for (var i = 0; i < this.myEvents.length; i++) {
          if (this.myEvents[i].eventId === key) {
            this.myEvents[i].lastCompletionDate = new Date(completionInfo.swydgets_completion_info_dict[key].last_completion_date);
            this.myEvents[i].completionCount = completionInfo.swydgets_completion_info_dict[key].completion_count
            this.myEvents[i].completionPct = completionInfo.swydgets_completion_info_dict[key].completion_pct;
          }
        }
      }
    }
  }

  handleActions(action) {

    switch (action.type) {
      case "ME_FETCH_PUBLISHED_SURVEYS": {
        break;
      }
      case "ME_FETCH_PUBLISHED_SURVEYS_SUCCESSFUL": {

        this.surveyStatusArray = [];
        var surveysDictJson = action.publishedSurveysJson["published"];
        var keysArray = Object.keys(surveysDictJson);

        for (var i = 0; i < keysArray.length; i++) {

          var surveyJson = surveysDictJson[keysArray[i]];
          var surveyStatus = SurveyStatus.createFromJson(keysArray[i], surveyJson);
          this.surveyStatusArray.push(surveyStatus);
        }

        // Call a function to do something with the data and then emit the change.
        this.emit("change");
        break;
      }
      case "ME_FETCH_EVENT": {
        this.eventInFocus = "";
        break;
      }
      case "ME_FETCH_EVENT_SUCCESSFUL": {
        this.eventInFocus = action.eventInFocus;
        this.emit("change-eventLoaded");
        break;
      }
      case "ME_FETCH_MY_EVENTS": {
        this.myEvents = [];

        break;
      }
      case "ME_FETCH_MY_EVENTS_WITH_WYSHES_SUCCESSFUL": {

        this.myEvents = [];

        var eventsArray = action.myEventsJson["events"];
        var resultsArray = action.surveyResultsJson;

        for (i = 0; i < eventsArray.length; i++) {
          var eventJson = eventsArray[i];

          var e = Event.createFromJsonAndResults(eventJson, resultsArray);
          e.findSurveyTakers(resultsArray);

          if (Utilities.startsWith(e.eventId, "me-") === false) {
            this.myEvents.push(e);
          }
        }

        this.emit("change-eventsWithWyshesLoaded");
        break;
      }
      case "ME_FETCH_MY_EVENTS_SUCCESSFUL": {

        this.myEvents = [];

        eventsArray = action.myEventsJson["events"];
        this.myEventsCurrentWorkspaceTotalSize = action.myEventsCurrentWorkspaceTotalSize;

        for (i = 0; i < eventsArray.length; i++) {
          eventJson = eventsArray[i];

          e = Event.createFromJson(eventJson, []);

          if (Utilities.startsWith(e.eventId, "me-") === false) {
            this.myEvents.push(e);
          }
        }

        this.emit("change");

        /*
        1. create surveys
        2. send it to myself
        3. Hits my phone (new survey)
        4. Swipe
        5. See the results
        
        */

        break;
      }
      case "ME_FETCH_EVENT_SURVEY_TAKERS_SUCCESSFUL": {
        this.surveyTakers = action.surveyTakers;
        if (this.eventInFocus) {
          this.eventInFocus.swydgetTakersArray = this.surveyTakers;
        }
        this.emit("change-surveyTakersLoaded");
        break;
      }
      case "ME_CREATE_SURVEY": {
        break;
      }
      case "ME_CREATE_SURVEY_SUCCESSFUL": {
        this.latestCreatedEventId = action.eventId;
        // We need to ADD the event to MY EVENTS
        if (action.event) {
          this.myEvents.push(action.event);
        }
        this.emit("change");
        break;
      }
      case "ME_UPDATE_SURVEY": {
        break;
      }
      case "ME_UPDATE_SURVEY_SUCCESSFUL": {
        this.emit("change-eventUpdated");
        break;
      }
      case "ME_CREATE_PRODUCT": {
        this.emit("change-productUploading");
        break;
      }
      case "ME_CREATE_PRODUCT_SUCCESSFUL": {
        this.freshlyCreatedProduct = action.product;
        this.emit("change-productAddedToLibrary");
        break;
      }
      case "ME_UPLOAD_SURVEY_COVER_PHOTO": {
        this.emit("change-coverPhotoUploading");
        break;
      }
      case "ME_ADD_PRODUCT_TO_SWYDGET": {
        this.emit("change-addProductToSwydget")
        break;
      }
      case "ME_ADD_PRODUCT_TO_SWYDGET_SUCCESSFUL": {
        // console.log("FIRING change-productAdded");
        this.emit("change-productAdded");
        this.emit("change-wyshesUpdated");
        break;
      }
      case "ME_UPDATE_PRODUCT": {
        this.emit("change-productUpdating");
        break;
      }
      case "ME_UPDATE_PRODUCT_SUCCESSFUL": {
        this.productInFocus = action.product;
        this.emit("change-productUpdated");
        break;
      }
      case "ME_REMOVE_PRODUCT": {
        break;
      }
      case "ME_REMOVE_WYSH_SUCCESSFUL": {
        this.emit("change-wyshRemoved");
        break;
      }
      case "ME_UPDATE_WYSH": {
        this.emit("change-wyshUpdating");
        break;
      }
      case "ME_UPDATE_WYSH_SUCCESSFUL": {
        this.emit("change-wyshUpdated");
        break;
      }
      case "ME_UPDATE_WYSHES_SUCCESSFUL": {
        this.emit("change-wyshesUpdated");
        break;
      }
      case "ME_FETCH_PRODUCTS_SUCCESSFUL": {
        this.setMyProducts(action.products);
        this.myProductsTotalSize = action.myProductsTotalSize;
        this.emit("change-productLibraryLoaded");
        this.emit("change-productAddedToLibrary");
        break;
      }
      case "ME_FETCH_PRODUCTS_BY_MID_SUCCESSFUL": {
        this.setSurveyCreatorProductLibrary(action.products);
        this.emit("change-surveyCreatorProductLibraryLoaded");
        break;
      }
      case "ME_PUBLISH_SURVEY": {
        this.friendsToInvite = [];
        this.nonFriendsToInvite = [];
        this.nonFriendsInvitedSuccessfully = [];
        break;
      }
      case "ME_PUBLISH_SURVEY_SUCCESSFUL": {
        this.friendsToInvite = action.friendsToInvite;
        this.nonFriendsToInvite = action.nonFriendsToInvite;
        this.emit("change-surveyPublished");
        break;
      }
      case "ME_UNPUBLISH_SURVEY_SUCCESSFUL": {
        this.emit("change-surveyUnPublished");
        break;
      }
      case "ME_INVITE_NON_FRIEND_SURVEY_TAKERS": {
        break;
      }
      case "ME_INVITE_NON_FRIEND_SURVEY_TAKERS_SUCCESSFUL": {
        console.log("Invitee: " + action.invitee.userId);
        this.nonFriendsInvitedSuccessfully.push(action.invitee.userId);
        this.emit("change-surveyInvitationSent");
        break;
      }
      case "AUTH_FETCH_USERS_REGISTRATIOM_STATUS_SUCCESSFUL": {
        this.friendsToInvite = action.friendsToInvite;
        this.nonFriendsToInvite = action.nonFriendsToInvite;
        this.emit("change-surveyInviteesSet");
        break;
      }
      case "ME_CLONE_SWYDGET_STARTED": {
        this.emit("change-cloneSwydgetStarted");
        break;
      }
      case "ME_CLONE_SWYDGET_SUCCESSFUL": {
        this.emit("change-cloneSwydgetSuccessful");
        break;
      }
      case "ME_GET_DECISIONS_SUCCESSFUL": {
        this.decisions = action.decisionsArray;
        if (this.eventInFocus) {
          this.eventInFocus.setAllDecisionsArray(this.decisions);
        }
        this.emit("change-decisionsLoaded");
        break;
      }
      case "ME_AUDIT_DECISIONS_SUCCESSFUL": {
        console.log("ME_AUDIT_DECISIONS_SUCCESSFUL");
        this.midToMdv = action.midToMdv;
        this.emit("change-decisionsAudited");
        break;
      }
      case "ME_GET_DECISION_COUNTS_SUCCESSFUL": {
        if (this.eventInFocus) {
          this.eventInFocus.processDecisionCounts(action.decisionCounts, action.takerCountsByProduct);
          this.eventInFocus.completionPct = action.completionPct;
          this.eventInFocus.completionCount = action.completionCount;
          this.eventInFocus.setFirstCompletionDate(action.firstCompletionDate);
          this.eventInFocus.setLastCompletionDate(action.lastCompletionDate);
          this.eventInFocus.authorPrivacyAcceptedPct = action.authorPrivacyAcceptedPct;
          this.eventInFocus.swytchbackPrivacyAcceptedPct = action.swytchbackPrivacyAcceptedPct;
          this.eventInFocus.swytchbackTosAcceptedPct = action.swytchbackTosAcceptedPct;
          this.eventInFocus.avgDurationInSeconds = action.avgDurationInSeconds;
          this.eventInFocus.segmentIdCountMap = action.segmentIdCountMap;
        }
        this.emit("change-decisionCountsLoaded");
        break;
      }
      case "ME_GET_GEOLOCATION_DATA_SUCCESSFUL": {
        if (this.eventInFocus) {
          this.eventInFocus.geolocationData = (action.geolocationData);
        }
        this.emit("change-geolocationDataLoaded");
        break;
      }
      case "ME_GET_FREE_RESPONSE_ANSWERS_DATA_SUCCESSFUL": {
        var decsions = [];
        if (this.eventInFocus) {
          this.eventInFocus.freeResponseAnswersByProduct = {};
          for (var productId in action.freeResponseAnswersByProduct) {
            var decJsonArray = action.freeResponseAnswersByProduct[productId];
            var decArray = [];
            for (var i = 0; i < decJsonArray.length; i++) {
              decArray.push(Decision.createFromJson(decJsonArray[i]));
            }
            this.eventInFocus.freeResponseAnswersByProduct[productId] = decArray;
          }

          // Clear Word Frequency Arrays for all wyshes. They are about to be set
          for (var i = 0; i < this.eventInFocus.wyshes.length; i++) {
            this.eventInFocus.wyshes[i].wordFrequencyArray = [];
          }

          for (productId in action.wordFrequencyDictionary) {
            var wfa = action.wordFrequencyDictionary[productId];
            var wysh = this.eventInFocus.findWyshByProductId(productId);
            wysh.wordFrequencyArray = wfa;
          }
        }

        this.emit("change-freeResponseAnswersByProductLoaded");
        break;
      }
      case "SWYDGET_POST_SWYDGET_COMPLETE_SUCCESSFUL": {
        console.log("marked swydget complete!");
        break;
      }
      case "ME_FETCH_COMPLETION_INFO_SUCCESSFUL": {
        this.processCompletionInfo(action.completionInfo);
        this.emit("change-completionInfoLoaded");
        break;
      }
      case "ME_POST_SEGMENTATION_MAPPING": {
        this.emit("meStore-postSegmentationMapping");
        break;
      }
      case "ME_POST_SEGMENTATION_MAPPING_SUCCESSFUL": {
        this.emit("meStore-postSegmentationMappingSuccessful");
        break;
      }
      case "ME_CREATE_PRODUCTS": {
        this.emit("meStore-createProducts");
        break;
      }
      case "ME_ADD_PRODUCTS_TO_SWYDGET_SUCCESSFUL": {
        this.emit("meStore-addProductsToSwydgetSuccessful");
        break;
      }
      case "ME_BATCH_STIMULI_UPLOAD_SUCCESSFUL": {
        this.emit("meStore-spreadSheetStimuliUploadSuccessful");
        break;
      }
      case "ME_IMPORT_PRODUCTS_TO_SWYDGET_SUCCESSFUL": {
        this.eventInFocus = action.eventInFocus;
        this.emit("meStore-importStimuliSuccessful");
        break;
      }

      default: {

      }
    }
  }
}

const meStore = new MeStore();
dispatcher.register(meStore.handleActions.bind(meStore));

export default meStore;
