import React from "react";
import { ProgressBar }  from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import classes from '../../css/modules/ScalarResults.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class MultipleChoiceResults extends React.Component {

  _generateHorizontalChart(wysh, snapshot=null) {

    const wyshTakerCount = wysh.takerCount;
    const borderColor = this.props.mode ? (this.props.mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = this.props.mode ? (this.props.mode === "dark" ? "#ffffff" : "#000000") : "#000000";
    const backgroundColor = this.props.mode ? (this.props.mode === "dark" ? "#0168EC" : "#0168EC") : "#0168EC";

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          
        },
      },
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          ticks:{
            color: textColor,
            callback: function(value) {
              return this.getLabelForValue(value)
              // return this.getLabelForValue(value).substring(0, 20)
            }
            
          },
          grid: {
            color: borderColor, // Grid line color
            borderColor: 'red', // Axis line color
          }
        },
        x: {
          beginAtZero: true,
          grid: {
            color: borderColor, // Grid line color
            borderColor: 'red', // Axis line color
          },
          ticks: {
            color: textColor,
            callback: function(value) {
              return value + "%";
            }
          }
        } 
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += context.raw; // Use context.parsed.x for horizontal bars
              label += '%'; // Append your custom text here
              return label;
            }
          }
        },
        title: {
          display: false,
          text: 'Swytchback Rocks',
        },
      },
    };

    const labels = wysh.wyshOptions.map((option) => {
      return option.resultLiteral + " (" + option.decisionsCount + ")";
    });

    const dataArray = wysh.wyshOptions.map((option) => {
      const percentage = option.decisionsCount > 0 ? 100 * option.decisionsCount / wyshTakerCount : 0.0;
      return percentage.toFixed(1);
    });
    
    const data = {
      labels,
      datasets: this._loadDatasets(wysh, snapshot)
    };

    return (
      <Bar options={options} data={data} />
    );
  }

  _loadDatasets = (stimulus, snapshot) => {

    const viewAllCuts = true;
    
    const datasets = [];
    if (stimulus && stimulus.event) {
      const snapshotsToDisplay = [];
      if (viewAllCuts === true) {
        const snapshotKeys = stimulus.event.snapshotContainer.snapshots.keys();
        for (const k of snapshotKeys) {
          const s = stimulus.event.snapshotContainer.snapshots.get(k);
          snapshotsToDisplay.push(s);
        }
      }
      else if (snapshot) {
        snapshotsToDisplay.push(snapshot);
      }

      const labels = stimulus.wyshOptions.map((option) => {
        return option.resultLiteral + " (" + option.decisionsCount + ")";
      });

      for (const s of snapshotsToDisplay) {
        
        stimulus.applySnapshot(s);
        const wyshTakerCount = stimulus.takerCount;

        
        const dataArray = stimulus.wyshOptions.map((option) => {
          const percentage = option.decisionsCount > 0 ? 100 * option.decisionsCount / wyshTakerCount : 0.0;
          return percentage.toFixed(1);
        });
        
        datasets.push({
          label: s.name,
          borderColor: s.color,
          backgroundColor: s.color,
          data: dataArray,
          snapshot: s
        })
      }
    }

    return datasets;
  }

  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;
    console.log(snapshot);
    
    return (
      <div style={{width: '100%', height: '100%'}}>
        {this._generateHorizontalChart(wysh, snapshot)}
      </div>
    );
  }
}

export default MultipleChoiceResults;
