import React, { useState } from "react";
import { Button }  from 'react-bootstrap';
import { TextField, Select, MenuItem } from '@mui/material';
import classes from '../../../css/modules/BranchLogicManager.module.css';
import CardEditor from "../../../components/admin/CardEditor";
import SkipRule from "../../../classes/SkipRule";
import BranchLogicStatement from "../../../classes/BranchLogicStatement";
import '../../../css/swytchbackStyles.css';
import '../../../css/swytchbackModal.css';
import SwytchbackTextField from "../controls/SwytchbackTextField";
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";


export default function BlockBranchLogicManagerComponent(props) {

  const [localBlockWysh, setLocalBlockWysh] = useState(props.blockWysh);
  const [optionTarget, setOptionTarget] = useState(null);
  const [parentWyshId, setParentWyshId] = useState("no_parent_wysh");

  const cardHeight = 30;
  const cardWidth = cardHeight * (2.5/3.5); 
  const cardHeightStyle = cardHeight + 'vh';
  const cardWidthStyle = cardWidth + 'vh';
  
      
  const imagePreviewStyle = {
    width: cardWidthStyle,
    height: cardHeightStyle,
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '5%',
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    overflow: 'hidden'
  };


  // I AM PORTING ALL THE METHODS FROM BRANCH LOGIC MANAGER AND THEN STYLING!!!!


  const handleNextWyshChange = (idx, w, negation) => (evt) => {

    const blockWysh = localBlockWysh;
    
    let skipRule = new SkipRule();
    skipRule.observedWyshId = blockWysh.wyshId;
    skipRule.resultNormalized = blockWysh.getWyshOptions()[idx] ? blockWysh.getWyshOptions()[idx].resultNormalized : idx;
    skipRule.negation = negation;

    let bls = new BranchLogicStatement();
    bls.skipRules.push([skipRule]);
    if (evt.target.value === "surveyComplete" || evt.target.value === "blockComplete") {
      bls.nextWyshId = evt.target.value;
    }
    else {
      bls.nextWyshId = blockWysh.getRemainingWyshes()[evt.target.value].wyshId;
    }

    blockWysh.wyshRouter.addBranchLogicStatement(bls);

    displayNextOption(idx)

    setLocalBlockWysh(blockWysh);
    props.updateBlockWyshCallback(blockWysh);
  }

  const isResponseChecked = (blockWysh, option) => {
    // default to true unless there is a skip rule for a given option that has its negation set
    for (var i = 0; i < blockWysh.wyshRouter.branchLogicStatements.length; i++){
      for (var j = 0; j < blockWysh.wyshRouter.branchLogicStatements[i].skipRules.length; j++) {
        for (var k = 0; k <  blockWysh.wyshRouter.branchLogicStatements[i].skipRules[j].length; k++) {
          if (option.resultNormalized === blockWysh.wyshRouter.branchLogicStatements[i].skipRules[j][k].resultNormalized) {
            return blockWysh.wyshRouter.branchLogicStatements[i].skipRules[j][k].negation;
          }
        }
      }
    }
    return false;
  }

  const getNextWyshIndex = (blockWysh, optionIndex) => {

    const resultNormalized = blockWysh.getWyshOptions()[optionIndex] ? blockWysh.getWyshOptions()[optionIndex].resultNormalized : optionIndex
    
    let remainingWyshes = blockWysh.getRemainingWyshes();
    var nextWysh = blockWysh.getNextWysh();

    let nextWyshId = blockWysh.wyshRouter.getNextWysh(resultNormalized);
    if (nextWyshId && (nextWyshId === "surveyComplete" || nextWyshId === "blockComplete")) {
      return nextWyshId;
    }
    else if (nextWyshId) {
      nextWysh = blockWysh.event.findWysh(nextWyshId);
    }
    
    let nwi = blockWysh.getWyshIndex(nextWysh, remainingWyshes);

    if (nwi < 0) {
      return (blockWysh.parentWysh) ? "blockComplete" : "surveyComplete"
    }

    return nwi
  }

  const displayNextOption = (idx) => {
    const nextWyshIndex = getNextWyshIndex(localBlockWysh, idx);
    const remainingWyshes = localBlockWysh.getRemainingWyshes();
    if (nextWyshIndex && nextWyshIndex === "surveyComplete") {
      setOptionTarget(null);
    }
    else if (nextWyshIndex && nextWyshIndex === "blockComplete") {
      setOptionTarget(null);
    }
    else if (nextWyshIndex !== null) {
      setOptionTarget(remainingWyshes[nextWyshIndex]);
    }
  };

  const handleMouseLeave = (idx) => {
    setOptionTarget(null);
  };


    

  const getNextWyshPreview = () => {
    if (optionTarget) {
      
      const imageStyle = {
        width: cardWidthStyle,
        height: cardHeightStyle,
        objectFit: optionTarget.product.styles.objectFit
      };
  
      const product = optionTarget.product;
      return  <div style={imagePreviewStyle}>
                <div style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  height: cardHeightStyle,
                  width: cardWidthStyle,
                  background: 'linear-gradient(#0167EC, #00B6F9)',
                  overflow: 'hidden'
                }}>
                  {(product.getSecureImageUrl() && product.getSecureImageUrl() !== "") &&
                    <img src={product.getSecureImageUrl()} style={imageStyle} alt='edit content' />
                  }
                  {CardEditor.toMarkup(product, cardHeight, optionTarget.isBlock() === true ? optionTarget.product.name : "")}
                </div>
              </div>
    }
    else {
      return <div style={imagePreviewStyle}>
        <div style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          height: cardHeightStyle,
          width: cardWidthStyle,
          background: 'linear-gradient(#0167EC, #00B6F9)',
          overflow: 'hidden'
        }}>
          
        </div>
      </div>
    }
  }


  const clear = () => {
    const blockWysh = localBlockWysh;
    blockWysh.wyshRouter.clearAllBranchLogicStements();

    setLocalBlockWysh(blockWysh);
    props.updateBlockWyshCallback(blockWysh);
  }

  const generateChoiceFields = (readOnly) => {

    const blockWysh = localBlockWysh;

    if (blockWysh === null || blockWysh === undefined) {
      return (
        <div></div>
      );
    }

    if (blockWysh.getWyshOptions().length === 0) {
      return (
        <div>
          Skip rules not supported for this stimuli type
        </div>
      );
    }

    let allWyshes = blockWysh.getAllSiblingWyshes();
    let remainingWyshes = blockWysh.getRemainingWyshes();
    let myIndex = blockWysh.getWyshIndex(blockWysh, allWyshes);

    return (

      <div className="divRowStyle" style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          {blockWysh.getWyshOptions().map((option, idx) => (
            <div key={idx} className="rowContainerSpaced marginBottom" style={{width: '100%'}}>
              <SwytchbackTextField
                id={"skip-rules-prompt-input" + idx}
                className="bigDog"
                style={{margin: '0px 6px 0px 0px', padding: 0}}
                value={blockWysh.questionType && blockWysh.questionType !== "binary" ? (idx + 1) + " - " + option.resultLiteral : option.resultLiteral}
                margin="normal"
                variant="outlined"
              />
              <SwytchbackSelect
                style={{width: 160}}
                onMouseEnter={() => displayNextOption(idx)}
                onMouseLeave={() => handleMouseLeave(idx)}
                inputProps={{ IconComponent: () => null }}
                id={"skip-to-select-" + idx}
                value={getNextWyshIndex(blockWysh, idx)}
                onChange={handleNextWyshChange(idx, blockWysh, isResponseChecked(blockWysh, option))}
              >
                {remainingWyshes.map((blockWysh, wyshIndex) => (
                  <MenuItem key={wyshIndex} value={wyshIndex}>Q{myIndex + wyshIndex + 2}:</MenuItem>
                ))}
                { blockWysh.parentWysh &&
                  <MenuItem key={"blockComplete"} value={"blockComplete"}>Block Complete</MenuItem>
                }
                <MenuItem key={"surveyComplete"} value={"surveyComplete"}>Survey Complete</MenuItem>
              </SwytchbackSelect>
            </div>
          ))}
        </div>
      </div>  
    )
  }
  
  return (
    <div id="blockWyshVerbatimComponent" className="rowContainer fullWidth">
      <div className="divColumnStyle bigDog">
        <div className="widget fullWidth standardModalHeight mainPane">
          <div className="titleContainer">
            <div className='title'>
              Skip To:
            </div>
            <div className='instruction'>
              Select a Skip To destination for each response option
            </div>
          </div>  
          <div className='content'>
            <div className="swytchbackAdminControl">
              {generateChoiceFields()}
            </div>
            <div className={`${classes.rightJustifiedContainer}`}>
              <SwytchbackFrameButton style={{width: 160, marginTop:20}} onClick={clear}>Clear Logic</SwytchbackFrameButton>
            </div>
          </div>
        </div>    
      </div>
      <div className="columnCenteredContainer rightPane standardModalHeight">
        <div className="widget">
          <div className="titleContainer">
            <div className='title' style={{marginBottom: 10}}>
              Preview:
            </div>
          </div>  
          <div className='content marginBottom'>
            {getNextWyshPreview()}
          </div>
        </div>  
      </div>
    </div>
  );
}