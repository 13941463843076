import dispatcher from "../../Dispatcher";
import axios from "axios";
import User from "../../classes/User";
import Notification from "../../classes/notifications/Notification";

const LoginActionsHook = () => {

  const loginUser = (
    username, 
    passwordSha256, 
    serverUrl, 
    authEndpoint, 
    destination, 
    navigationHandler,
    firstIndex, 
    lastIndex) => {

    dispatcher.dispatch({
      type: "LOGIN_USER_START"
    });
  
    const myFriendsEndpoint = "/1/friends/";
    const myUnacknowledgedNotificationsEndpoint = "/1/messages/all/";
  
    const uid = username;
    localStorage.setItem("username", uid);
    localStorage.setItem("password", passwordSha256);
  
    const clearText = uid + ":" + passwordSha256;
    const base64String = "Basic " + btoa(clearText);
  
  
    var config = {
      headers: {'Authorization': base64String}
    };
  
    var rangeString = "entities=" + (firstIndex ? firstIndex : 0) + "-" + (lastIndex ? lastIndex : 9);
    var rangedConfig = {
      headers: {'Authorization': base64String, 'range': rangeString}
    };
  
    axios.all([
      axios.get(serverUrl + authEndpoint, config),
      axios.get(serverUrl + myFriendsEndpoint, config),
      axios.get(serverUrl + myUnacknowledgedNotificationsEndpoint, rangedConfig)
    ]).then(axios.spread(function(loginResponse, myFriendsResponse, myUnacknowldgedNotificationsResponse) {
  
          // get the response code and log the user in
          // 1. Get the code
          const { results } = loginResponse.data;
  
          var friends = [];
          var unacknowledgedNotifications = [];
  
          for (var i = 0; i < myFriendsResponse.data.results.friends.length; i++) {
            let friendJson = myFriendsResponse.data.results.friends[i];
            friends.push(User.createUserFromJson(friendJson));
          }
  
          for (i = 0; i < myUnacknowldgedNotificationsResponse.data.results.all.length; i++) {
            var messageJson = myUnacknowldgedNotificationsResponse.data.results.all[i];
            var notification = Notification.createNotificationFromBackendJson(messageJson);
  
            // console.log(notification);
            // if (notification.header && notification.header.type && (notification.header.type === "wyshlist-created" || notification.header.type === "event-invite" || notification.header.type === "spreadsheet-batch-upload-complete")) {
            // if (notification.header && notification.header.type && (notification.header.type !== "survey-available")) {
              unacknowledgedNotifications.push(notification);
            // }
          }
  
          var loggedInUser = User.createUserFromNetwork(uid, passwordSha256, results);
          loggedInUser.myFriends = friends;
          loggedInUser.myUnacknowldgedNotifications = unacknowledgedNotifications;
          loggedInUser.totalNotificationsCount = myUnacknowldgedNotificationsResponse.data.fullLength;
  
          dispatcher.dispatch({
            type: "LOGIN_USER_SUCCESSFUL",
            loggedInUserJsonString: loggedInUser.toJsonString()
          });
  
          navigationHandler(destination);
      }
    ))
    .catch(function (error) {
      console.log(error);
  
      if (error.response) {
        dispatcher.dispatch({
          type: "LOGIN_USER_UNSUCCESSFUL",
          error: error,
          reason: error.response.data.message
        });
      }
      else {
        dispatcher.dispatch({
          type: "LOGIN_USER_UNSUCCESSFUL",
          error: error,
          reason: "Network Error"
        });
      }
  
    });
  }

  /*
  acknowledgeFriendRequest

  Send an acknowlegedment of a friend request.
  POST /1/friends/invitations/responses/
  {
    "msg_id": "38",
    "accept": true,
    "acknowledge": true
  }
  */
  const acknowledgeFriendRequest = (serverUrl, user, navigationHandler, friendRequestNotification, accept) => {
    // password is ALREADY sha256 encoded
    const passwordSha256 = user.password;
    const uid = user.userId
    const clearText = uid + ":" + passwordSha256;
    const base64String = "Basic " + btoa(clearText);

    var config = {
      headers: {'Authorization': base64String}
    };

    var parametersJson = {
      msg_id: friendRequestNotification.header.index.toString(),
      accept: accept,
      acknowledge: true
    };

    const endpoint = "/1/friends/invitations/responses/";

    axios.post(serverUrl + endpoint, parametersJson, config)
      .then((data) => {

        // Done acknowledging request. Let's fetch fresh user unfo
        loginUser(uid, passwordSha256, serverUrl, "/1/users/user/profile", "/notifications", navigationHandler);

      }
    )
    .catch(function (error) {
      console.log(error);
      console.log("error");
    });
  }

  /*
  deleteFriend

  Deletes a friend from the User's list of friends

  */
  const deleteFriend = (serverUrl, user, navigationHandler, friendToDelete) => {

    // password is ALREADY sha256 encoded
    const passwordSha256 = user.password;
    const uid = user.userId;
    const clearText = uid + ":" + passwordSha256;
    const base64String = "Basic " + btoa(clearText);

    const config = {
      headers: {'Authorization': base64String}
    };

    const endpoint = "/1/friends/" + friendToDelete.contactIdx;

    axios.delete(serverUrl + endpoint, config)
      .then((data) => {

          console.log("NO DISPATCH");
          console.log(data);

          loginUser(uid, passwordSha256, serverUrl, "/1/users/user/profile", "/account_info", navigationHandler);
      }
    )
    .catch((error) => {
      console.log("error");
    });
  }

  /*
  updatePassword

  Send an acknowlegedment of a friend request.
  PUT /1/users/user/password
  {
    "password": "eec308d1635553649b9605e55124726e11546ff760e3218c05a8256a02755583"
  }
  */
  const updatePassword = (serverUrl, user, navigationHandler, newPasswordSha256) => {

    // password is ALREADY sha256 encoded
    const oldPasswordSha256 = user.password;
    const uid = user.userId;
    const clearText = uid + ":" + oldPasswordSha256;
    const base64String = "Basic " + btoa(clearText);

    const config = {
      headers: {'Authorization': base64String}
    };


    const parametersJson = {
      password: newPasswordSha256
    };

    const endpoint = "/1/users/user/password";

    axios.post(serverUrl + endpoint, parametersJson, config)
      .then((data) => {

        // Done acknowledging request. Let's fetch fresh user info
        loginUser(uid, newPasswordSha256, serverUrl, "/1/users/user/profile", "/account_info", navigationHandler);
      }
    )
    .catch((error) => {
      console.log(error);
      console.log("error");
    });
  }

  /*
  inviteFriend

  Invite a friend
  POST /1/friends/
  {
    "user_id": {
                "t": "email",
                "v": "test105@catboytech.com"
              },
    "first_name": "James",
    "last_name": "Hetfield"
  }

  */
  const inviteFriend = (serverUrl, user, navigationHandler, email, firstName, lastName) => {

    // password is ALREADY sha256 encoded
    const passwordSha256 = user.password;
    const uid = user.userId;
    const clearText = uid + ":" + passwordSha256;
    const base64String = "Basic " + btoa(clearText);

    const config = {
      headers: {'Authorization': base64String}
    };

    const parametersJson = {
      user_id: {
        t: "email",
        v: email
      },
      first_name: firstName,
      last_name: lastName
    };

    const endpoint = "/1/friends/";

    axios.post(serverUrl + endpoint, parametersJson, config)
      .then((data) => {

        // Done acknowledging request. Let's fetch fresh user info
        loginUser(uid, passwordSha256, serverUrl, "/1/users/user/profile", "/account_info", navigationHandler);

      }
    )
    .catch((error) => {
      console.log(error);
      console.log("error");
    });
  }



  

  return {
    loginUser,
    acknowledgeFriendRequest,
    deleteFriend,
    updatePassword,
    inviteFriend
  }
}

export default LoginActionsHook;