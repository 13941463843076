import React, { useState } from "react";
import * as ld from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import '../../../css/swytchbackModal.css';
import CardEditor from "../../../components/admin/CardEditor";
import ExportWyshPopover from "../ExportWyshPopover";


const EditStimulusModal = (props) => {

  const { onHideAction } = props;
  const { showModal } = props;
  const { swydget } = props;
  const { editStimulusAction} = props;
  const { wysh } = props;

  const [stimulus, setStimulus] = useState(wysh);
  const [imageFiles, setImageFiles] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  
  const cleanUp = (onHideAction) => {
    setStimulus(wysh);
    onHideAction();
  }

  const fire = (stimulus, imageFiles) => {
    editStimulusAction(stimulus, imageFiles)
    onHideAction();
  }

  const buttonStyle = {
    width: 130,
    marginLeft: 5,
    marginRight: 5
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setImageFiles(acceptedFiles);
  }

  const handleNameChange = (evt) => {
    const s = ld.cloneDeep(stimulus);
    s.product.name = evt.target.value;
    setStimulus(s);
  }

  const handleProductLinkChange = (evt) => {
    const s = ld.cloneDeep(stimulus);
    s.product.buyPage = evt.target.value;
    setStimulus(s);
  }

  const handleDescriptionChange = (evt) => {
    const s = ld.cloneDeep(stimulus);
    s.product.description = evt.target.value;
    setStimulus(s);
  }

  const handleThemeRadioBoxChange = (evt) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.textShadow = evt.target.value;
    setStimulus(s);
  }

  const handleJustificationChange = (value) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.justification = value;
    setStimulus(s);
  }

  const handleVerticalAlignChange = (value) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.verticalAlign = value;
    setStimulus(s);
  }

  const handleFontSizeChange = (value) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.fontSize = value;
    setStimulus(s);
  }

  const handleColorPickerChange = (color) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.textColor = color.hex;
    setStimulus(s);
  }

  const handleFontFamilyChange = (evt) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.fontFamily = evt.target.value;
    setStimulus(s);
  }

  const handleIsBoldChange = () => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.isBold = !s.product.styles.isBold;
    setStimulus(s);
  }

  const handleIsItalicizedChange = () => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.isItalicized = !s.product.styles.isItalicized;
    setStimulus(s);
  }

  const handleObjectFitChange = (objectFit) => {
    const s = ld.cloneDeep(stimulus);
    s.product.styles.objectFit = objectFit;
    setStimulus(s);
  }

  const resetImage = (stimulus) => {
    const s = ld.cloneDeep(stimulus);
    s.product.imageUrl = "";
    setStimulus(s);
  }

  const updateStimulusTypeCallback = (mutatedStimulus) => {
    const s = ld.cloneDeep(stimulus);

    s.questionType = mutatedStimulus.questionType;
    s.binaryIconSetID = mutatedStimulus.binaryIconSetID;
    s.wyshOptions = mutatedStimulus.wyshOptions;
    s.mcIncludeNoneOfTheAbove = mutatedStimulus.mcIncludeNoneOfTheAbove;
    s.mcIncludeOther = mutatedStimulus.mcIncludeOther;
    s.mcOptionsLimit = mutatedStimulus.mcOptionsLimit;
    s.mustViewAll = mutatedStimulus.mustViewAll;
    s.randomizeWyshOptions = mutatedStimulus.randomizeWyshOptions;
    s.caption = mutatedStimulus.caption;
    
    setStimulus(s);
  }

  const updateStimulusVerbatimCallback = (mutatedStimulus) => {
    const s = ld.cloneDeep(stimulus);
    s.freeResponseQuestions = mutatedStimulus.freeResponseQuestions;
    s.hiddenFreeResponseOptions = mutatedStimulus.hiddenFreeResponseOptions;
    setStimulus(s);
  }

  const updateStimulusProductAttributesCallback = (mutatedStimulus) => {
    const s = ld.cloneDeep(stimulus);
    s.product.attributes = mutatedStimulus.product.attributes;
    setStimulus(s);
  }

  const updateStimulusBranchLogicCallback = (mutatedStimulus) => {
    console.log("updateStimulusBranchLogicCallback");
    const s = ld.cloneDeep(stimulus);
    s.wyshRouter.branchLogicStatements = mutatedStimulus.wyshRouter.branchLogicStatements;
    setStimulus(s);
  }
  
  const updateStimulusCallback = (stimulus) => {
    const s = ld.cloneDeep(stimulus);
    setStimulus(s);
  }

  return (
    <Modal
      onHide={onHideAction}
      size="xl"
      show={showModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Edit Stimulus</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="divRowStyle">
          <div className="divRowStyle" style={{width: '100%'}}>
            <CardEditor
              readOnly={false}
              stimulus={stimulus}
              swydget={swydget}
              showSkipRules={true}
              productLink={""}
              imageFiles={imageFiles}
              onDropMethod={onDrop}
              handleNameChange={handleNameChange}
              handleProductLinkChange={handleProductLinkChange}
              handleDescriptionChangeMethod={handleDescriptionChange}
              handleThemeRadioBoxChangeMethod={handleThemeRadioBoxChange}
              handleJustificationChangeMethod={handleJustificationChange}
              handleVerticalAlignChangeMethod={handleVerticalAlignChange}
              handleFontSizeChangeMethod={handleFontSizeChange}
              handleColorPickerChangeMethod={handleColorPickerChange}
              handleFontFamilyChangeMethod={handleFontFamilyChange}
              handleIsBoldChangeMethod={handleIsBoldChange}
              handleIsItalicizedChangeMethod={handleIsItalicizedChange}
              handleObjectFitChangeMethod={handleObjectFitChange}
              updateStimulusTypeCallback={updateStimulusTypeCallback}
              updateStimulusVerbatimCallback={updateStimulusVerbatimCallback}
              updateStimulusProductAttributesCallback={updateStimulusProductAttributesCallback}
              updateStimulusBranchLogicCallback={updateStimulusBranchLogicCallback}
              resetImageMethod={resetImage}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <ExportWyshPopover
          id={"export-name-popover"}
          buttonText="Export"
          title="Set Export Name"
          content="This is the content of the popover."
          placement="top"
          stimuli={stimulus}
        />
        <Button className="swytchbackActiveButton" style={buttonStyle} onClick={() => fire(stimulus, imageFiles)}>Update</Button>
        <Button className="swytchbackActiveButton" style={buttonStyle} onClick={() => cleanUp(onHideAction)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditStimulusModal;