export default class MessagingCampaign
{
  constructor() {
    this.messaging_campaign_id = 0;
    this.messaging_campaign_name = "";
    this.messages = [];
    this.phone_numbers = [];
    this.phone_numbers_string = "";
    this._created = 0;
    this._updated = 0;
  }

  getPhoneNumbersAsString() {


    return this.phone_numbers;
  }

  setPhoneNumbers(phoneNumbersString) {
    this.phone_numbers_string = phoneNumbersString;
    this.phone_numbers = this.phone_numbers_string.split("\n");
  }
}
