import BlockType from "./BlockType";
import MaxDiffBlockType from "./MaxDiffBlockType";
import PairwiseBlockType from "./PairwiseBlockType";

export default class BlockTypes {

  static STANDARD = new BlockType("standard", "Standard");
  static FORCEDCHOICE = new BlockType("forced_choice", "Forced Choice");
  static SEQUENTIALMONADIC = new BlockType("sequential_monadic", "Sequential Monadic");
  static MAXDIFF = new MaxDiffBlockType("max_diff", "Max Diff");
  static PAIRWISE = new PairwiseBlockType("pairwise", "Pairwise Tradeoff");

  static getBlockType(blockTypeId) {

    switch(blockTypeId) {
      
      case BlockTypes.STANDARD.blockTypeId:
        return BlockTypes.STANDARD;
      
      case BlockTypes.FORCEDCHOICE.blockTypeId:
        return BlockTypes.FORCEDCHOICE;

      case BlockTypes.SEQUENTIALMONADIC.blockTypeId:
        return BlockTypes.SEQUENTIALMONADIC;
          
      case BlockTypes.MAXDIFF.blockTypeId:
        return BlockTypes.MAXDIFF;

      case BlockTypes.PAIRWISE.blockTypeId:
        return BlockTypes.PAIRWISE;

      default:
        return BlockTypes.STANDARD;
    }
  }
}
