import React from "react";
import { Link } from '@mui/material';
import { Image }  from 'react-bootstrap';
import Cookies from 'universal-cookie';
import classes from "../../css/modules/Swydget.module.css";
import swytchbackLogoBorder from '../../SwytchbackLogoBorder.png';
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';


class SwydgetRetired extends React.Component {


  constructor() {
    super();

    this.openCookieWarningSnackBar = this.openCookieWarningSnackBar.bind(this);
    this.closeCookieWarningSnackBar = this.closeCookieWarningSnackBar.bind(this);
    this.acceptCookiePolicy = this.acceptCookiePolicy.bind(this);

    this.state = {
      showCookieWarningSnackBar: true,
      cookiePolicyAccepted: false
    };
  }

  componentWillMount() {
    const cookies = new Cookies();
    let cpa = cookies.get("cpa");

    if (cpa === "true") {
      this.setState({
        cookiePolicyAccepted: true
      });
    }
  }

  openCookieWarningSnackBar() {
    this.setState({ showCookieWarningSnackBar: true });
  }

  closeCookieWarningSnackBar() {
    this.setState({ showCookieWarningSnackBar: false });
  }

  setCookie(cookieName, cookieValue) {
    const d = new Date();
    d.setTime(d.getTime() + (10*365*24*60*60*1000)); //Expires in 10 years
    let expires = "expires=" + d.toUTCString();
    const cookieString = cookieName + "=" + cookieValue + ";" + expires + ";path=/;SameSite=None;secure;partitioned"; 
    document.cookie = cookieString;
  }

  acceptCookiePolicy() {

    this.setCookie("cpa", true);
    
    this.setState({
      showCookieWarningSnackBar: false,
      cookiePolicyAccepted: true
    });
  }

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back&#8480;</span></span>;
  }

  getDeclarationsMessage(eventInFocus) {

    if (eventInFocus && eventInFocus.privacyLink && eventInFocus.privacyLink.trim() !== '' && eventInFocus.creator.getCompanyName() !== '') {
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
            This service uses cookies to preserve the integrity of survey results and improve performance.
            By clicking the button above to continue, you are acknowledging our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> and
            the <Link href={eventInFocus.privacyLink} className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> of {eventInFocus.creator.getCompanyName()}
          </div>
        </div>
      )
    }
    else {
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
            This service uses cookies to preserve the integrity of survey results and improve performance.
            By clicking the button above to continue, you are acknowledging our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link>.
          </div>
        </div>
      )
    }
  }


  render() {

    const { height } = this.props;
    const { eventInFocus} = this.props;
    let productImageUrl = eventInFocus.getCoverPhotoUrl();

    var ht = 100;

    if (height) {
      ht = height;
    }

    var imageWidthAndHeight = ht * 0.26;
    var imageWidthAndHeightStyle = imageWidthAndHeight + 'vh';
    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (eventInFocus && eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }


    let containerDivStyle = {
      height: ht + 'vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: -10
    };

    let sectionContainerDivStyle = {
      height: ht*0.17 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let logoContainerDivStyle = {
      height: ht*0.26 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: imageWidthAndHeightStyle,
      minHeight: imageWidthAndHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + productImageUrl + "')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    }

    let greetingDivStyle = {
      margin: 0,
      padding: 10,
      color: '#fff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 'normal'
    };

    let emphasizedMessageDivStyle = {
      textAlign: 'center',
      margin: 0,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 8,
      paddingBottom: 10,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#a9a9a9',
      borderRadius: 6,
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500,
      background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`


    };

    let titleDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: ht*0.12 + 'vh',
      color: '#fff',
      fontSize: 18,
      fontWeight: 'bold',
      padding: 10,
      zIndex: 10
    };

    let completionImageDivStyle = {
      height: imageWidthAndHeightStyle,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 300
    };

    let swytchbackLogoStyle = {
      margin: 10,
      height: 30,
      width: 'auto',
      zIndex: 10
    };

    let defaultCardBackgroundStyle = {
      borderRadius: '50%',
      margin: 0,
      height: ht*0.1 + 'vh',
      width: ht*0.1 + 'vh',
      objectFit: 'cover'
    };

    return (
      <div style={containerDivStyle}>
          {
            (eventInFocus && eventInFocus.getDefaultCardBackgroundUrl() !== '') ?
            <div style={{zIndex: 10}}>
              <Image style={defaultCardBackgroundStyle} src={eventInFocus.getDefaultCardBackgroundUrl()} responsive="true"/>
            </div>
            :
            <div></div>
          }


          <div style={titleDivStyle}>{eventInFocus.title}</div>
          <div key={0} style={completionImageDivStyle}>
            <div style={thumbnailImgStyle}>

            </div>
          </div>
          <div style={sectionContainerDivStyle}>
            <div style={greetingDivStyle}>{eventInFocus.description}</div>
            <div style={greetingDivStyle}>Sorry, this survey is no longer available.</div>
          </div>
          <div style={logoContainerDivStyle}>
            <div style={completionDivContainerStyle}>
              <div>
                powered by
              </div>
            </div>
            <div style={completionDivContainerStyle}>
              <Image style={swytchbackLogoStyle} src={swytchbackLogoBorder} responsive="true"/>
            </div>
            <div style={completionDivContainerStyle}>
              <div>
                {this.generateLogo()}
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default SwydgetRetired;
