import React, { useState } from "react";
import { ProgressBar } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import '../../css/swytchbackStats.css';
import classes from "../../css/modules/Stats.module.css";
import SwytchbackFormControlLabel from "../admin/controls/SwytchbackFormControlLabel";
import SwytchbackCheckbox from "../admin/controls/SwytchbackCheckbox";
import SwytchbackRadioButtonGroup from "../admin/controls/SwytchbackRadioButtonGroup";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";


ChartJS.register(ArcElement, Tooltip, Legend);

const BinaryResults = ({ 
  wysh, 
  snapshot,
  width, 
  mode, 
  snapshotsToDisplay=[] 
}) => {

  const [selectedChart, setSelectedChart] = useState("donut");
  const [sortOrder, setSortOrder] = useState("ordered");
  const [horizontal, setHorizontal] = useState(true);
  const [sortByHighestScore, setSortByHighestScore] = useState(false);
  const [showBarChart, setShowBarChart] = useState(false);
  
  const cardWidth = width ? width : 250;

  const pctLiked = wysh.aggregatedResults.getPctLikedFloat(snapshot);
  const pctDisliked = wysh.aggregatedResults.getPctDislikedFloat(snapshot);
  
  const generateDataArray = (stimulus) => {
    return [
      stimulus.aggregatedResults.getPctLikedFloat(snapshot), 
      stimulus.aggregatedResults.getPctDislikedFloat(snapshot)
    ];
  }

  const loadDatasets = (stimulus) => {

    const datasets = [];

    // The stimulus is loaded with data from either a live pull or from the snapshot that has been applied.
    // This is the view of the stimulus in focus and should always be displayed first.
    // The snapshot is undefined if a fresh pull has been initiated.

    // Load additional snapshots to display
    if (!snapshot) {
      datasets.push({
        label: "All Data",
        backgroundColor: [
          "#0059ff",
          '#dde0dc'
        ],
        borderColor: [
          "#0059ff",
          '#dde0dc'
        ],
        data: generateDataArray(stimulus),
        snapshot: null
      });
    }
    

    const sortedSnapshotsToData = [];

    
    if (stimulus && stimulus.event) {
      for (const s of snapshotsToDisplay) {
        if (s && s.equals(snapshot) === false) {
          stimulus.applySnapshot(s);
          sortedSnapshotsToData.push({
            snapshot: s, 
            data: generateDataArray(stimulus)
          });
        }
      }
      if (snapshot) {
        stimulus.applySnapshot(snapshot);
        sortedSnapshotsToData.push({snapshot: snapshot, data: generateDataArray(stimulus)});
      }

      switch(sortOrder) {
        case 'high-to-low':
          sortedSnapshotsToData.sort((a, b) => {
            return b.data[0] - a.data[0];
          });
          break;
        case 'low-to-high':
          sortedSnapshotsToData.sort((a, b) => {
            return a.data[0] - b.data[0];
          })  
          break;
      }
    }

    const sortedSnapshots = [];
    for (const s of sortedSnapshotsToData) {
      sortedSnapshots.push(s.snapshot);
    }
    

    if (stimulus && stimulus.event) {
      for (const s of sortedSnapshots) {
        stimulus.applySnapshot(s);
        const data = generateDataArray(stimulus);
        datasets.push({
          label: s.name + " ("+ stimulus.takerCount + ")",
          // borderColor: s.color,
          // backgroundColor: s.color,
          backgroundColor: [
            s.color,
            '#dde0dc'
          ],
          borderColor: [
            s.color,
            '#dde0dc'
          ],
          data: data,
          snapshot: s,
          takerCount: stimulus.takerCount,
          likesCount: stimulus.aggregatedResults.likesCount,
          dislikesCount: stimulus.aggregatedResults.dislikesCount
        });
      }
    }

    return datasets;
  };

  
  const generateHorizontalChart = (wysh) => {
    const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";
    
    const doughnutOptions = {
      plugins: {
        legend: {
          labels: {
            // Customize legend text color here
            color: textColor, // Example color
            generateLabels: function(chart) {
              const datasets = chart.data.datasets;
              return datasets.map((dataset, i) => ({
                text: dataset.label,
                fillStyle: dataset.backgroundColor[0],
                hidden: false,
                datasetIndex: i,
              }));
            },
          },
          display: true
        },
        tooltip: {
          padding: 14,
          displayColors: false,
          titleFont: {
            size: 20
          },
          callbacks: {
            title: (context) => {
              const labels = [];
              const cohort = context.length > 0 ? context[0].dataset.label : '';
              labels.push(cohort);
              const decisionLabel = context.length > 0 ? context[0].label : '';
              labels.push(decisionLabel)
              return labels;
            },
            label: function (context) {
              const labels = [];
              const yesPct = context.dataset.data.length > 0 ? context.dataset.data[0] : 0;
              labels.push("Yes Percentage: " + yesPct);
              labels.push("Yes Count: " + context.dataset.likesCount);
              return labels;
            }
          }
        }
      },
    };

    const horizontalBarOptions = {
      indexAxis: 'y',
      elements: {
        bar: {},
      },
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColor,
            callback: function (value) {
              return this.getLabelForValue(value);
            }
          },
          grid: {
            color: borderColor,
            borderColor: 'red',
          }
        },
        x: {
          beginAtZero: true,
          grid: {
            color: borderColor,
            borderColor: 'red',
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            // Customize legend text color here
            color: textColor, // Example color
            generateLabels: function(chart) {
              const datasets = chart.data.datasets;
              return datasets.map((dataset, i) => ({
                text: dataset.label,
                fillStyle: dataset.backgroundColor[0],
                hidden: false,
                datasetIndex: i,
              }));
            },
          },
          display: true,
        },
        tooltip: {
          padding: 14,
          displayColors: false,
          titleFont: {
            size: 20
          },
          callbacks: {
            title: (context) => {
              const labels = [];
              const cohort = context.length > 0 ? context[0].dataset.label : '';
              labels.push(cohort);
              const decisionLabel = context.length > 0 ? context[0].label : '';
              labels.push(decisionLabel)
              return labels;
            },
            label: function (context) {
              const labels = [];
              const yesPct = context.dataset.data.length > 0 ? context.dataset.data[0] : 0;
              labels.push("Yes Percentage: " + yesPct);
              labels.push("Yes Count: " + context.dataset.likesCount);
              return labels;
            }
          }
        },
        title: {
          display: false
        },
      },
    };

    const verticalBarOptions = {
      indexAxis: 'x',
      elements: {
        bar: {},
      },
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColor,
            callback: function (value) {
              return this.getLabelForValue(value);
            }
          },
          grid: {
            color: borderColor,
            borderColor: 'red',
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: borderColor,
            borderColor: 'red',
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            // Customize legend text color here
            color: textColor, // Example color
            generateLabels: function(chart) {
              const datasets = chart.data.datasets;
              return datasets.map((dataset, i) => ({
                text: dataset.label + " (" + dataset.takerCount + ")",
                fillStyle: dataset.backgroundColor[0],
                hidden: false,
                datasetIndex: i,
              }));
            },
          },
          display: true
        },
        tooltip: {
          padding: 10,
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += context.raw;
              label += '%';
              return label;
            }
          }
        },
        title: {
          display: false
        },
      },
    };

    const labels = ["Yes", "No" ];
    const data = {
      labels,
      datasets: loadDatasets(wysh)
    };

    const selectChartType = (e) => {
      const chartType = e.target.value;
  
      setSelectedChart(chartType);
          
      switch (chartType) {
        case 'bar-horizontal':
          setShowBarChart(true);
          setHorizontal(true);
          break;
        case 'bar-vertical':
          setShowBarChart(true);
          setHorizontal(false);
          break;
        case 'donut':
          setShowBarChart(false);
          setHorizontal(false)
          break;
        default:
          setShowBarChart(false);  
          setHorizontal(false);
      }
      
    }

    return (
      <div className={`${classes.sectionContainer}`}>
        <div className={`${classes.chartContainer}` + " " + `${classes.centeredColumnContainer}`}>
          {showBarChart === true ?
          <Bar options={horizontal ? horizontalBarOptions : verticalBarOptions} data={data} />
          :
          <div style={{width: cardWidth}}>
            <Doughnut data={data} options={doughnutOptions} />
          </div>
          }
          <div className={`${classes.controlPanelContainer}`}>
            <div className={`${classes.centeredColumnContainer}`}>
              <div className={`${classes.centeredRowContainer}`}>
                <div className={`${classes.label}`} style={{color: textColor}}>
                  Chart Type:
                </div>
                <FormControl>
                  <RadioGroup
                    row
                    className={`${classes.buttonRowContainer}`}
                    name="if-selected"
                    value={selectedChart}
                    onChange={(e) => {selectChartType(e)}}>
                    <FormControlLabel
                      value={"donut"} 
                      style={{color: textColor}}
                      label={"Donut"} 
                      control={
                        <Radio
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      value={"bar-horizontal"} 
                      style={{color: textColor}}
                      label={"Horizontal Bar"}
                      control={
                        <Radio
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      } 
                    />
                    <FormControlLabel
                      value={"bar-vertical"} 
                      style={{color: textColor}}
                      label={"Vertical Bar"} 
                      control={
                        <Radio
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={`${classes.centeredRowContainer}`}>
                <div className={`${classes.label}`} style={{color: textColor}}>
                  Sort:
                </div>
                <FormControl>
                  <RadioGroup
                    row
                    className={`${classes.buttonRowContainer}`}
                    name="if-selected"
                    value={sortOrder}
                    onChange={(e) => {setSortOrder(e.target.value)}}>
                    <FormControlLabel
                      value={"ordered"} 
                      style={{color: textColor}}
                      label={"Ordered"}
                      control={
                        <Radio 
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      } 
                    />
                    <FormControlLabel 
                      value={"high-to-low"} 
                      style={{color: textColor}}
                      label={"High to Low"} 
                      control={
                        <Radio
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                    />
                    <FormControlLabel 
                      value={"low-to-high"} 
                      style={{color: textColor}}
                      label={"Low to High"} 
                      control={
                        <Radio
                          color='primary'
                          disabled={false}
                          sx={{
                            color: textColor,
                            '&.Mui-checked': {
                              color: textColor,
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>
          </div>
        </div>
      </div>  
      
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {generateHorizontalChart(wysh)}
    </div>
  );
};

export default BinaryResults;
