import React from "react";
import { ProgressBar }  from 'react-bootstrap';
import '../../css/swytchbackStats.css';



export default class MaxDiffStimuliResults extends React.Component {
  
  render() {

    const { wysh } = this.props;

    let aggregatedResultsDivStyle = {
      padding: 0,
      color: '#329147',
      fontSize: 12,
      fontWeight: 500
    };

    return (
      <div style={aggregatedResultsDivStyle}></div>
    );
  }
}
