import React, { Component } from 'react';
import { Switch } from '@mui/material';
import { Modal, Button, Tabs, Tab}  from 'react-bootstrap';
import classes from '../../css/modules/ResultsModal.module.css';
import Wysh from "../../classes/Wysh";
import Card from '../../hooks/admin/Card';
import "../../css/swytchbackModal.css";
import "../../css/swytchbackTable.css";
import AdminWyshStats from "../../components/stats/AdminWyshStats";
import AdminWyshResults from "../../hooks/stats/AdminWyshResults";
import meStore from "../../stores/MeStore";
import loginStore from "../../stores/LoginStore";
import MeService from "../../services/MeService";
import VerbatimDecisionsTable from '../../hooks/admin/stimuli/VerbatimDecisionsTable';
import SwytchbackWordCloud from '../../hooks/admin/controls/SwytchbackWordCloud';
import ExportWyshPopover from '../../hooks/admin/ExportWyshPopover';


class ResultsModal extends Component {

  constructor(props) {
    super(props);

    this.user = loginStore.getLoggedInUser();

    this.state = {
      isReportFullyRendered: false
    }
  }

  componentDidMount() {
    meStore.on("change-eventLoaded", this.eventLoaded);
  }

  componentWillUnmount() {
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
  }


  cleanUp = (onHideAction) => {
    // this.reset();
    // MeService.updateWysh(this.user, this.props.wysh.event, this.props.wysh, false);
    onHideAction();
  }

  eventLoaded = () => {
    MeService.getFreeResponseAnswers(this.user, this.props.wysh.event, this.props.wysh);
  }

  //var width = (100/wordArrayColumns.length).toFixed(0) + "%";

  generateWordFrequencyList = (wordArray, width, idx) => {

    return (
      <div className="columnContainer" key={idx} style={{width: width}}>
        { wordArray.map((wordFreq, idx) => {

            let word = wordFreq.word;
            let count = wordFreq.count;
            let weight = wordFreq.weight;
            let fontSize = wordFreq.fontSize;
            let fontWeight = wordFreq.fontWeight;

            return (<div style={{fontSize: 16, fontWeight: fontWeight, color: "#ffffff"}} key={word}>{word}: {count}</div>);
          })
        }

      </div>
    )
  }

  generateWordFrequencyColumns = (wordArrayColumns) => {

    var width = 100/wordArrayColumns.length
    return ( 
      <div className="rowContainer fullWidth">
        { wordArrayColumns.map((wordArray, idx) => {
            return this.generateWordFrequencyList(wordArray, width + "%", idx);
          })
        }
      </div>
    )
  }

  generateWordFrequencyTable= (wordArrayColumns) => {

    var width = 100/wordArrayColumns.length
    return (
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
        { wordArrayColumns.map((wordArray, idx) => {
            return <td style={{width: width + "%", verticalAlign: 'top'}}>{this.generateWordFrequencyList(wordArray, 100 + "%", idx)}</td>
          })
        }
          </tr>
        </tbody>
      </table>
    )
  }

  toggleWordSwitch = (word, evt, wysh, setWordsToOmitCallback) => {

    var wto = wysh.wordsToOmit;

    if (evt.target.checked === true) {
      wto.splice(wysh.wordsToOmit.indexOf(word), 1);
      this.setState({isReportFullyRendered: false});
    }
    else {
      wto.push(word);
      this.setState({isReportFullyRendered: false});
    }

    setWordsToOmitCallback(wysh, wto);
  }

  generateStopWordSwitches = (wysh, setWordsToOmitCallback) => {

    return (
        <table className="adminTable">
          <thead>
            <tr className="tHeadTr">
              <th className="tHeadLeft">Word</th>
              <th className="tHeadRight">Frequency</th>
              <th className="tHeadRight">Display</th>
            </tr>
          </thead>
          <tbody>
        { wysh.wordFrequencyArray.map((wordFreq) => {
            return (
              <tr key={"stopword-" + wordFreq.word}>
                <td className="tdLeft">
                  {wordFreq.word}
                </td>
                <td className="tdRight">
                  {wordFreq.count}
                </td>
                <td className="tdRight">
                  <Switch
                    key={wordFreq.word + "-switch"}
                    color="primary"
                    checked={wysh.wordsToOmit.indexOf(wordFreq.word) > -1 ? false : true}
                    onChange={(e) => this.toggleWordSwitch(wordFreq.word, e, wysh, setWordsToOmitCallback)}
                  />
                </td>
              </tr>
            )
          })
        }
          </tbody>
        </table>
    )
  }

  generateAttributesTable = (wysh) => {

    var rows = [];

    var attributeKeysArray = Object.keys(wysh.product.attributes);
    attributeKeysArray.sort();
    attributeKeysArray.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    attributeKeysArray.forEach((key, index) => {
      if (wysh.product.attributes.hasOwnProperty(key)) {

        var value = (key === wysh.product.attributes[key]) ? "" : wysh.product.attributes[key];

        rows.push(
          <tr key={"attribute-" + key}>
            <td className="tdLeft">
              {key}
            </td>
            <td className="tdLeft">
              {value}
            </td>
          </tr>
        )
      }
    });

    return (
        <table className="adminTable">
          <thead>
            <tr className="tHeadTr">
              <th className="tHeadLeft" colSpan="2">Attribute</th>
            </tr>
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>
    )
  }

  toggleFreeResponseAnswer = (decision, evt) => {

    this.setState({ isReportFullyRendered: false});

    let sfrd = this.props.wysh.selectedFreeResponseDecisions;
    const decisionHash = decision.takerMid + decision.productId;

    if (Wysh.containsDecision(decision, this.props.wysh.selectedFreeResponseDecisions) === false) {
      sfrd.push(decision);
    }
    else {
      for (var i = 0; i < sfrd.length; i++) {
        const sfrdHash = sfrd[i].takerMid + sfrd[i].productId;
        if (sfrdHash === decisionHash) {
          sfrd.splice(i, 1);
          break;
        }
      }
    }

    this.props.setSelectedFreeResponseDecisions(this.props.wysh, sfrd);
  }

  renderCompleteCallback = (wyshId) => {
    this.setState({ isReportFullyRendered: true });
  }

  getWordColor = (word, wordFrequencyArray) => {

    var normalizedResult = 0.0;

    var wordFreq = null;
    for (var i = 0; i < wordFrequencyArray.length; i++) {
      if (wordFrequencyArray[i].word.toLowerCase() === word.text.toLowerCase()) {
        wordFreq = wordFrequencyArray[i];
        break;
      }
    }

    normalizedResult = wordFreq.totalResultsNormalized/wordFreq.count;

    if (normalizedResult >= 0 && normalizedResult <= 0.1) {
      return "#da020d";
    }
    else if (normalizedResult > 0.1 && normalizedResult <= 0.2) {
      return "#ff020d";
    }
    else if (normalizedResult > 0.2 && normalizedResult <= 0.3) {
      return "#ff7d7f";
    }
    else if (normalizedResult > 0.3 && normalizedResult <= 0.4) {
      return "#ddbdbd";
    }
    else if (normalizedResult > 0.4 && normalizedResult <= 0.5) {
      return "#beb9b9";
    }
    else if (normalizedResult > 0.5 && normalizedResult <= 0.6) {
      return "#bebfc5";
    }
    else if (normalizedResult > 0.6 && normalizedResult <= 0.7) {
      return "#b1c0d4";
    }
    else if (normalizedResult > 0.7 && normalizedResult <= 0.8) {
      return "#2db5ff";
    }
    else if (normalizedResult > 0.8 && normalizedResult <= 0.9) {
      return "#0070ff";
    }
    else if (normalizedResult > 0.9 && normalizedResult <= 1.0) {
      return "#0018ff";
    }
    else {
      return "#000000";
    }
  }

  getTooltip = (word, wordFrequencyArray) => {

    var normalizedResult = 0.0;

    var wordFreq = null;
    for (var i = 0; i < wordFrequencyArray.length; i++) {
      if (wordFrequencyArray[i].word.toLowerCase() === word.text.toLowerCase()) {
        wordFreq = wordFrequencyArray[i];
        break;
      }
    }

    normalizedResult = wordFreq.totalResultsNormalized/wordFreq.count;

    return "likes: " + wordFreq.totalResultsNormalized + ", count: " + wordFreq.count + ", " + " pct: " + (normalizedResult * 100).toFixed(1) + "%";

  }

  render() {

    // wysh in focus
    const { wysh } = this.props;

    // Optional WyshSnapshot to draw data scrubbing parameters
    const { snapshot } = this.props;

    const { snapshotsToDisplay } = this.props;

    // index of Stimiuli in swydget
    const { selectedProductIndex } = this.props;

    // trigger to display the modal
    const { showModal } = this.props;

    // All decisions that have a free response answer
    const { decisionsWithFreeResponses } = this.props;

    // The method to execute when the modal is closed or cancelled manually
    const { onHideAction } = this.props;

    // The method to execute when we change anything on the reporting side (words to omit or responses to display)
    const { setWordsToOmitCallback } = this.props;

    if (snapshot && wysh.wyshSnapshotMap.get(snapshot.created.toString())) {
      var wfa = wysh.removeStopWords(wysh.wyshSnapshotMap.get(snapshot.created.toString()));
    }
    else {
      var wfa = Wysh.removeStopWords(wysh.wordFrequencyArray, wysh.wordsToOmit);
    }

    var wordArrayColumns = [[]];
    var words = [];

    var currColumn = 0;
    for (var i = 0; i < wfa.length; i++) {
      if (i > 0 && i % 14 === 0) {
        currColumn += 1;
        wordArrayColumns.push([]);
      }
      wordArrayColumns[currColumn].push(wfa[i]);
      words.push({text: wfa[i].word, value: wfa[i].count});
    }

    let buttonStyle = {
      width: 130,
      marginLeft: 5,
      marginRight: 5
    };

    const screenOptions = {
      colors: ["#346CF9", "#809DF2", "#342BD9", "#282BA9", "#D7E9FC", "#2E3192"],
      enableTooltip: false,
      deterministic: false,
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSizes: [10, 80],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 1,
      rotations: 1,
      rotationAngles: [0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 2000
    };

    const printPreviewOtions = {
      colors: ["#346CF9", "#809DF2", "#342BD9", "#282BA9", "#D7E9FC", "#2E3192"],
      enableTooltip: false,
      deterministic: true,
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSizes: [10, 80],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 1,
      rotations: 1,
      rotationAngles: [0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 0
    };




    const callbacks = {
      getWordColor:  (word) => Wysh.getWordColor(word, wfa),
      getWordTooltip: (word) => this.getTooltip(word, wfa),
    }

    return (
      <Modal 
        size="xl" 
        show={showModal} 
        onHide={() => this.cleanUp(onHideAction)} 
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header className="swytchbackModalHeader" closeButton>
          <Modal.Title className="swytchbackModalTitle">Stimulus Results</Modal.Title>
        </Modal.Header>
        <Modal.Body className="swytchbackModalBody coloredBackground">
          <div className="hookFullPaneContainer swytchbackGradientPane">
            <Tabs className="swytchbackModalTabs"
                  id="uncontrolled-tab-example"
                  defaultActiveKey={1}
                  style={{width: '100%'}}>
              <Tab eventKey={1} title="Quantitative">
                <div className={`${classes.divRowStyle}`}>
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className={`${classes.divColumnStyle}`}>
                    <AdminWyshStats wysh={wysh} mode="dark" snapshot={snapshot} />
                    <AdminWyshResults wysh={wysh} width={500} mode="dark" snapshot={snapshot} snapshotsToDisplay={snapshotsToDisplay}/>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Qualitative">
                <div className="rowContainer">
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className="contentContainerCentered standardModalHeight">
                    <SwytchbackWordCloud
                      wysh={wysh}
                      height={500}
                      width={700}
                      mode="dark"
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey={3} title="Words: Edit">
                <div className="rowContainer standardModalHeight">
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className="rightPaneContainer standardModalHeight" style={{paddingTop: 20, flexGrow: 5}}>
                    {this.generateStopWordSwitches(wysh, setWordsToOmitCallback)}
                  </div>
                </div>
              </Tab>
              <Tab eventKey={4} title="Responses: Edit">
                <div className="rowContainer">
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className="rightPaneContainer standardModalHeight" style={{paddingTop: 20, flexGrow: 5}}>
                    <VerbatimDecisionsTable decisionsWithFreeResponses={decisionsWithFreeResponses} toggleHandler={this.toggleFreeResponseAnswer} selectedFreeResponseDecisions={this.props.wysh.selectedFreeResponseDecisions}/>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={5} title="Word Count">
                <div className="rowContainer">
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className="contentContainerCentered standardModalHeight" style={{paddingTop: 20, flexGrow: 5}}>
                    {this.generateWordFrequencyColumns(wordArrayColumns)}
                  </div>
                </div>
              </Tab>
              <Tab eventKey={6} title="Attributes">
                <div className="rowContainer">
                  <div className="cardDiv">
                    <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                  </div>
                  <div className="contentContainerCentered standardModalHeight" style={{paddingTop: 20, flexGrow: 5}}>
                    {this.generateAttributesTable(wysh)}
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey={6} title="Print Preview">
                <div ref={el => (this.componentRef = el)}>
                  <WyshResultsPrintReport
                    renderCompleteCallback={this.renderCompleteCallback}
                    key={"wrpr-" + wysh.wyshId}
                    wordCloudId= {"individual-product-WRPR-" + wysh.wyshId}
                    isReportFullyRendered={this.state.isReportFullyRendered}
                    wysh={wysh}
                    wordsToOmit={wysh.wordsToOmit}
                    selectedFreeResponseDecisions={this.props.wysh.selectedFreeResponseDecisions}
                    showTopResponses={true}
                  />
                </div>
              </Tab> */}
            </Tabs>
          </div>  
        </Modal.Body>  
        <Modal.Footer className="swytchbackModalFooter">
          <ExportWyshPopover
            id={"export-name-popover"}
            buttonText="Export"
            title="Set Export Name"
            content="This is the content of the popover."
            placement="top"
            stimuli={wysh}
          />
          <Button className="swytchbackActiveButton oneThird" style={buttonStyle} onClick={() => this.cleanUp(onHideAction)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ResultsModal;
