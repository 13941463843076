import React, { Component } from 'react';
import { TextField } from '@mui/material';
import { Table, Button, OverlayTrigger, Popover }  from 'react-bootstrap';
import classes from '../../css/modules/SegmentsAdmin.module.css';
import AuthService from '../../services/AuthService';


class SegmentsAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      segmentName: "",
      user: null
    }
  }

  handleSegmentNameChange = (evt) => {
    this.setState({
      segmentName: evt.target.value
    });
  }

  handleUpdateSegmentName = (evt, user, segment) => {
    segment.segment_name = evt.target.value;
    AuthService.updateSegment(user, segment);
  }


  addSegment = (user) => {
    AuthService.addSegment(user, this.state.segmentName);
    this.setState({
      segmentName: ""
    });
  }

  deleteSegment = (evt, user, segment) => {
    AuthService.deleteSegment(user, segment);
  }

  submitSegmentForm = (evt, user) => {
    evt.preventDefault();
    this.addSegment(user);

    console.log("persist to back en");
  }

  generateSegmentRows = (user) => {

    let tdLeftStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 100
    }

    let tdStyle = {
      textAlign: 'center',
      verticalAlign: 'middle'
    }

    let tdRightStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 150
    }


    var rows = [];

    var keys = Object.keys(user.segmentBucket.segments);

    if (user) {
      for (var i = 0; i < keys.length; i++) {
        let segment = user.segmentBucket.segments[keys[i]];
        rows.push(
          <tr key={"segment-" + keys[i]}>
            <td style={tdLeftStyle}>
              {segment.ordinal}
            </td>
            <td style={tdStyle}>
              {segment.segment_name}
            </td>
            <td style={tdRightStyle}>
              <Button onClick={(evt) => this.deleteSegment(evt, user, segment)}>Remove</Button>
            </td>
          </tr>
        );
      }
    }

    return rows;
  }


  render() {

    const { user } = this.props;

    let thLeftStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 100,
      borderBottom: '1px solid black'
    }

    let thStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      borderBottom: '1px solid black'
    }

    let thRightStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 150,
      borderBottom: '1px solid black'
    }

    const addSegmentPopup = (
      <Popover id="popover-segment" title="Name & Add Segment">
        <form onSubmit={(evt) => this.submitSegmentForm(evt, user)} className={`${classes.container}`} noValidate autoComplete="off">
          <TextField
            disabled={false}
            id="segmentNameTextField"
            label="Segment Name"
            className={`${classes.textFieldPopover}`}
            InputProps={{
              classes: {
                input: `${classes.swytchbackPopoverInputStyles}`,
              },
            }}
            InputLabelProps={{
              classes: {
                root: `${classes.swytchbackPopoverInputLabelStyles}`
              },
            }}
            value={this.state.segmentName}
            onChange={this.handleSegmentNameChange}
            margin="normal"
            variant="outlined"
            placeholder="Name..."
          />
        </form>
        <div style={{margin: 5}}>
          <Button className="swytchbackActiveButton" onClick={() => this.addSegment(user)}>
            Add
          </Button>
        </div>
      </Popover>
    );



    return (
      <div style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          <Table style={{}} responsive>
            <thead style={{}}>
              <tr>
                <th style={thLeftStyle}>Segment ID</th>
                <th style={thStyle}>Segment Name</th>
                <th style={thRightStyle}></th>
              </tr>
            </thead>
            <tbody>
              {this.generateSegmentRows(user)}
            </tbody>
          </Table>
        </div>
        <div>
          <OverlayTrigger ref="addSegmentOverlayTrigger" container={this} trigger="click" placement="top" overlay={addSegmentPopup} rootClose>
            <Button className="swytchbackBorderlessActiveButton">
              + Add Segment
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

export default SegmentsAdmin;
