import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

const SwytchbackFormControlLabel = styled(FormControlLabel)(({textcolor="#ffffff", fontsize=20}) => ({
  color: textcolor,
  fontSize: fontsize
}));


export default SwytchbackFormControlLabel;
