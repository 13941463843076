import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import loginStore from "../stores/LoginStore";
import meStore from "../stores/MeStore";
import MeService from "../services/MeService";
import ImportSwydgetModal from "../hooks/admin/ImportSwydgetModal";
import SwydgetLibraryModal from '../hooks/admin/SwydgetLibraryModal';
import { useNavigate } from 'react-router';





class SelectSurveyType extends React.Component {

  constructor() {
    super();

    this.loggedInUser = loginStore.getLoggedInUser();

    this.navigateToCreateScalarSurvey = this.navigateToCreateScalarSurvey.bind(this);
    this.navigateToCreateForcedRankSurvey = this.navigateToCreateForcedRankSurvey.bind(this);
    this.createCustomizedSurvey = this.createCustomizedSurvey.bind(this);

    this.state = {
      showSwydgetLibraryModal: false,
      showImportSwydgetModal: false,
      importingState: "ready"
    };
  }

  componentDidMount() {
    meStore.on("meStore-importStimuliSuccessful", this.importComplete);
  }

  componentWillUnmount() {
    meStore.removeListener("meStore-importStimuliSuccessful", this.importComplete);
  }

  openSwydgetLibraryModal = () => {
    this.setState({ showSwydgetLibraryModal: true });
  }

  closeSwydgetLibraryModal = () => {
    this.setState({ showSwydgetLibraryModal: false });
  }

  navigateToCreateScalarSurvey() {
    this.props.navigationHandler("/create_survey/create_scalar_survey");
  }

  navigateToCreateForcedRankSurvey() {
    this.props.navigationHandler("/create_survey/create_forced_rank_survey");
  }

  createCustomizedSurvey(e) {
    e.preventDefault();

    if (this.state.question !== "") {
      MeService.createSurvey(loginStore.getLoggedInUser(), this.props.navigationHandler, meStore.newSwydgetTitle, "");
    }
    else {
      this.openErrorModal();
    }
  }

  openImportSwydgetModal = () => {
    this.setState({ showImportSwydgetModal: true });
  }

  closeImportSwydgetModal = (eventId) => {
    this.setState({ showImportSwydgetModal: false, importingState: "ready" });
    if (eventId && eventId.startsWith("evt.")) {
      this.props.navigationHandler("/survey/" + eventId);
    }
  }

  importComplete = () => {
    this.setState({ importingState: "done" });
  }

  render() {

    var colWidth = 12 / 3; //this.loggedInUser.getSurveyTypesCount();

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid',
      marginBottom: 20
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };


    let colBoxStyle = {
      padding: 10
    };

    let boxStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f1f2f6',
      textAlign: 'center',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      height: 210,
      margin: 0,
      padding: 0
    }

    let surveyTypeNameStyle = {
      margin: 20,
      paddingBottom: 10,
      fontSize: 20,
      fontWeight: 300,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#9fa2a7'
    }

    let buttonContainerStyle = {
      width: '80%',
      padding: 10,

    }

    let enabledButtonStyle = {
      width: '100%',
      height: 70,
      fontSize: 20,
      fontWeight: 500,
      backgroundColor: '#1994fc',
      borderRadius: 12,
      border: 0
    }

    return (
      <div>
        <Container>
          <Row style={headerRowStyle}>
            <Col md={12} style={toolbarStyle}>
              <div style={titleStyle}>New Swydget</div>
            </Col>
          </Row>
          <Row>
            <Col md={colWidth} style={colBoxStyle}>
              <div style={boxStyle}>
                <div style={surveyTypeNameStyle}>Create From Scratch</div>
                <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.createCustomizedSurvey}>Create</Button></div>
              </div>
            </Col>
            <Col md={colWidth} style={colBoxStyle}>
              <div style={boxStyle}>
                <div style={surveyTypeNameStyle}>Import Existing</div>
                <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openImportSwydgetModal}>Import</Button></div>
              </div>
            </Col>
            <Col md={colWidth} style={colBoxStyle}>
              <div style={boxStyle}>
                <div style={surveyTypeNameStyle}>Template Library</div>
                <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openSwydgetLibraryModal}>Choose</Button></div>
              </div>
            </Col>
            {/* {this.loggedInUser.isAuthorized(SwytchbackServiceFactory.getService("service_research")) &&
              <Col md={colWidth} style={colBoxStyle}>
                <div style={boxStyle}>
                  <div style={surveyTypeNameStyle}>Forced Ranking Survey</div>
                  <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.navigateToCreateForcedRankSurvey}>Create</Button></div>
                </div>
              </Col>
            } */}

          </Row>
        </Container>
        { this.state.showImportSwydgetModal &&
        <ImportSwydgetModal
          user={this.loggedInUser}
          show={this.state.showImportSwydgetModal}
          handleClose={this.closeImportSwydgetModal}
          importingState={this.state.importingState}
        />
        }
        { this.state.showSwydgetLibraryModal && 
        <SwydgetLibraryModal
          user={this.loggedInUser}
          show={this.state.showSwydgetLibraryModal}
          handleClose={this.closeSwydgetLibraryModal}
        />
        }
      </div>
    );
  }
}

const SelectSurveyTypeWrapper = () => {

  const navigate = useNavigate();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <SelectSurveyType navigationHandler={handleNavigation} />
}

export default SelectSurveyTypeWrapper;
