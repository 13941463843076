import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import "../../css/swytchbackStyles.css";
import InviteFriendFormModal from './InviteFriendFormModal';
import AuthService from '../../services/AuthService';
import User from '../../classes/User';



class FriendsTable extends Component {

  constructor() {
    super();

    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));

    this.inviteFriend = this.inviteFriend.bind(this);
    this.openInviteFriendsModal = this.openInviteFriendsModal.bind(this);
    this.closeInviteFriendsModal = this.closeInviteFriendsModal.bind(this);

    this.state= {
      showInviteFriendsModal: false
    }
  }

  inviteFriend(email, firstName, lastName) {
    AuthService.inviteFriend(this.loggedInUser, null, email, firstName, lastName);
  }

  openInviteFriendsModal() {
    this.setState({ showInviteFriendsModal: true });
  }

  closeInviteFriendsModal() {
    this.setState({ showInviteFriendsModal: false });
  }

  render() {

    const { rows, actionLabel } = this.props;

    const { enableInvitePeople } = this.props;

    let containerStyle = {
      backgroundColor: '#ffffff',
      width: '100%'
    };

    let tableContainerStyle = {
      backgroundColor: '#f7f6f7'
    };


    let addContentTitleDivStyle = {
      height: 60,
      backgroundColor: '#ffffff',
      color: '#a1b2b7',
      fontSize: 18,
      fontWeight: 800,
      paddingTop: 20,
      paddingLeft: 6
    };

    let theadStyle = {
      backgroundColor: '#e0e5ee',
      color: '#53585f',
      fontSize: 14
    };

    let theadStyleTr = {
      height: 44
    };

    let tableStyle = {
      backgroundColor: '#ffffff',
      margin: 0,
      padding: 0
    };

    let tableContainerDivStyle = {
      // overflow: 'scroll'
    };

    let toolbarDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    };

    var topButtonStyle = {
      width: 160,
      paddingTop: 6,
      paddingBottom: 6,
      margin: 0
    };

    return (
      <div style={containerStyle}>
        <div style={toolbarDivStyle}>
          <div style={addContentTitleDivStyle}>Team: {rows.length}</div>
          <div>
          { enableInvitePeople === true &&
            <Button className="swytchbackActiveButton" style={topButtonStyle} onClick={this.openInviteFriendsModal}>+ Invite People</Button>
          }
          </div>
        </div>
        <div style={tableContainerStyle}>
          <div style={tableContainerDivStyle}>
            <Table style={tableStyle} responsive>
              <thead style={theadStyle}>
                <tr style={theadStyleTr}>
                  <th style={{width: 30}}>Photo</th>
                  <th>Name</th>
                  <th style={{width: 30}}>{actionLabel}</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </Table>
          </div>
        </div>
        <InviteFriendFormModal showModal={this.state.showInviteFriendsModal} onHideAction={this.closeInviteFriendsModal} inviteFriendHandler={this.inviteFriend} />


      </div>
    );
  }
}

export default FriendsTable;
