import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

class NotificationsTable extends Component {

  render() {

    const { rows, singularLabel, multipleLabel } = this.props;

    var label = multipleLabel;
    if (rows.length === 1) {
      label = singularLabel;
    }

    let tableContainerStyle = {
      backgroundColor: '#f7f6f7'
    };

    let addContentTitleDivStyle = {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#ffffff',
      paddingTop: 20,
      paddingBottom: 10,
      color: '#a1b2b7',
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 800
    };

    let theadStyle = {
      backgroundColor: '#e0e5ee',
      color: '#53585f',
      fontSize: 14
    };

    let theadStyleTr = {
      height: 44
    };

    let theadsStyleTd = {
      fontWeight: 400
    }

    let theadsStyleRightTd = {
      fontWeight: 400,
      textAlign: 'right',
      paddingRight: 20
    }

    let tableStyle = {
      backgroundColor: '#ffffff',
      margin: 0
    }

    let tableContainerDivStyle = {
      overflow: 'auto'
    }

    let infoDivStyle = {
      height: 60,
      backgroundColor: '#ffffff',
      color: '#a1b2b7',
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 800,
      paddingTop: 20
    }

    return (
      <div>
        <div style={addContentTitleDivStyle}>
          <div>
            {label}: {rows.length}
          </div>
        </div>
        <div style={tableContainerStyle}>
          <div style={tableContainerDivStyle}>
            <Table style={tableStyle} responsive>
              <thead style={theadStyle}>
                <tr style={theadStyleTr}>
                  <th style={theadsStyleTd}>From</th>
                  <th style={theadsStyleTd}>Sent</th>
                  <th style={theadsStyleTd}>Acknowledged</th>
                  <th style={theadsStyleTd}>Message</th>
                  <th style={theadsStyleRightTd}>Action</th>
                </tr>
              </thead>
              <tbody>
                { rows }
              </tbody>
            </Table>
          </div>
        </div>
        { rows.length === 0 &&
          <div style={infoDivStyle}>
            You have no new Messages
          </div>
        }

      </div>
    );
  }
}

export default NotificationsTable;
