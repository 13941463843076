import WyshMeProduct from "./WyshMeProduct";
import WyshScore from "./WyshScore";
import WyshOption from "./WyshOption";
import WyshRouter from "./WyshRouter";
import WyshSnapshot from "./WyshSnapshot";
import OrderedWyshList from "./OrderedWyshList";
import BranchLogicStatement from "./BranchLogicStatement";
import BlockType from "./BlockType";
import BlockTypes from "./BlockTypes";
import Decision from "./Decision";
import WyshSurveyAggregateResults from "./WyshSurveyAggregateResults";
import removeButtonGray from '../assets/swydget/no-check-button-gray.png';
import BinaryIconSet from "./BinaryIconSet";






export default class Wysh {
  constructor() {
    this.wyshId = "";
    this.event = null;
    // this.previousWysh = null; // OPTIONAL: the string Wysh ID for the Wysh before this Wysh in an ordered OrderedWyshList.
    this.nextWysh = null; // OPTIONAL: the string Wysh ID for the Wysh after this Wysh in an ordered OrderedWyshList.
    this.parentWysh = null; // OPTIONAL: The parentWysh represents the block of which this Wysh is a member.
    this.parentWyshId = null; // OPTIONAL: artifact needed to stich together Wyshes on import/export
    this._block = false;
    this._blockType = null;
    this.orderedWyshList = new OrderedWyshList(this);
    this.updated = 0;
    this.created = 0;
    this.v = 0;
    this.caption = "";
    this.t = "";
    this.creatorName = "";
    this.accepted = false;
    this.claimed = false;
    this.claimedBy = "";
    this.product = new WyshMeProduct();
    this.aggregatedResults = WyshSurveyAggregateResults.create(this, 0, 0);
    this.decisionDataArray = []; // For the map...might be going
    this.decisionsArray = [];
    this.decisionsByMidDict = {};
    this.takerCount = 0;
    this.wyshScore = new WyshScore();
    this.questionType = "binary";
    this.wyshOptions = [];
    this.randomizeWyshOptions = false;
    this.mcIncludeNoneOfTheAbove = false;
    this.mcIncludeOther = false;
    this.mcOptionsLimit = 0;
    this.mustViewAll = false;
    this.binaryIconSetID = "yes_no";
    this.binaryIconSet = BinaryIconSet.getBinaryIconSet(this.binaryIconSetID);
    this.scalarDescription = "";
    this.freeResponseQuestions = [];
    this.wyshRouter = new WyshRouter();
    this.wyshRouter.wysh = this;
    this.randomize = false;
    this.forcedChoice = false;
    this.displayBlockCard = false;
    this.issueSubGroups = false;
    this.subGroupCount = 1;
    this.limitFreeResponse = false;
    this.limitFreeResponseCount = 1;
    this.gatherFreeResponse = false;
    this.hiddenFreeResponseOptions = [];
    this.wordFrequencyArray = [];
    this.wordsToOmit = [];
    this.selectedFreeResponseDecisions = [];
    this.questionNumber = "";
    this.wyshSnapshotMap = new Map();
    this.maxDiffTrialSets = [];
    this.maxDiffVersion = null;
    this.pairwiseTrialSets = [];
    this.pairwiseVersion = null;
    this.averageScore = 0; // MAX DIFF ARTIFACT
  }

  isBlock() {

    if (this._blockType || this._block === true || this.orderedWyshList.wyshes.length > 0) {
      return true;
    }

    return false;
  }

  getBlockType() {
    if (this._blockType) {
      return this._blockType;
    }
    else if (this._block) {
      return BlockTypes.SEQUENTIALMONADIC;
    }
    else if (this.isBlock()) {
      // Weird fringe case where we don't have a blocktype, but there is a child. I think
      // this is a busted swydget
      if (this.orderedWyshList.wyshes.length > 0) {
        return BlockTypes.SEQUENTIALMONADIC;
      }
    }

    return null;
  }

  setBlockType(blockType) {
    this._blockType = blockType;
  }

  isBlockType(blockType) {
    return this.getBlockType() && this.getBlockType().equals(blockType) ? true : false;
  }

  isEligibleForPublishing() {

    if (this.isBlock() && this.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
      return this.orderedWyshList.getAllDescendantWyshes().length < 8 ? false : true;
    }

    return true;
  }

  getWyshPrompt() {

    // Priority:
    // 1. Wysh caption
    // 2. Event Default Default Prompt
    // 3. Event Title

    var question = this.caption;

    if (question === "") {
      if (this.parentWysh && this.parentWysh.getWyshPrompt().length > 0) {
        return this.parentWysh.getWyshPrompt();
      }
      else if (this.event && this.event.defaultPrompt !== "") {
        question = this.event.defaultPrompt;
      }
    }

    return question;

  }

  getStimuliImageUrl(swydget=null) {

    let imageUrl = null;

    if (this.product && this.product.getSecureImageUrl(this) && this.product.getSecureImageUrl(this) !== "") {
      return this.product.getSecureImageUrl(this);
    }
    else if (swydget) {
      return swydget.defaultProduct.getSecureImageUrl(this);
    }

    return null;
  }

  getBlockName() {
    return this.product && this.product.name ? this.product.name : "no title"
  }

  hasFreeResponseStats() {
    return this.event && this.event.published === true && this.freeResponseQuestions.length > 0 ? true : false;
  }

  getWyshOption(value) {
    for (var i = 0; i < this.wyshOptions.length; i++) {
      if (this.wyshOptions[i].resultNormalized === value) {
        return this.wyshOptions[i];
      }
    }

    return null;
  }
  // 
  getWyshOptions() {
    var wyshOptions = [];

    if (this.isBlock()) {
      let nextOption = new WyshOption();
      nextOption.resultLiteral = "next";
      nextOption.resultNormalized = 0.0;

      wyshOptions.push(nextOption);
    }
    else if (this.questionType && this.questionType === "scalar" || this.questionType === "single-select" || this.questionType === "multiple-choice") {
      for (var i = 0; i < this.wyshOptions.length; i++) {
        wyshOptions.push(this.wyshOptions[i]);
      }
    }
    else {
      let noWo = new WyshOption();
      noWo.resultLiteral = "no";
      noWo.resultNormalized = 0.0;

      let yesWo = new WyshOption();
      yesWo.resultLiteral = "yes";
      yesWo.resultNormalized = 1.0;

      wyshOptions.push(noWo);
      wyshOptions.push(yesWo);
    }

    return wyshOptions;
  }

  setWyshOptions(optionsArray) {
    // KLUDGE ALERT: GET RID OF THIS!!!
    // add in resultLiteral if not present
    for (let option of optionsArray) {
      if ('resultLiteral' in option === false) {
        option['resultLiteral'] = option.name;
      }
    }

    this.wyshOptions = optionsArray
  }

  getRemoveButtonImage() {
    return removeButtonGray;
  }

  getNextWysh() {
    return this.nextWysh;
  }

  getRemainingWyshes() {

    let allSiblingWyshes = this.getAllSiblingWyshes();
    var remainingWyshes = [];

    // returns -1 if next wysh is not found
    let nextWyshIndex = this.getWyshIndex(this.nextWysh, allSiblingWyshes);

    if (nextWyshIndex > -1) {
      for (var i = this.getWyshIndex(this.nextWysh, allSiblingWyshes); i < allSiblingWyshes.length; i++) {
        remainingWyshes.push(allSiblingWyshes[i]);
      }
    }

    if (this.parentWysh) {
      let parentRemainingWyshes = this.parentWysh.getRemainingWyshes();
    }

    return remainingWyshes;
  }

  getAllSiblingWyshes() {
    let allSiblingWyshes = [];

    if (this.parentWysh) {
      allSiblingWyshes = this.parentWysh.orderedWyshList.getOrderedWyshes();
    }
    else {
      allSiblingWyshes = this.event.orderedWyshList.getOrderedWyshes();
    }

    return allSiblingWyshes;
  }

  getWyshIndex(wysh, wyshes) {
    // let wyshes = wysh.event.getWyshes();

    if (wysh) {
      for (var i = 0; i < wyshes.length; i++) {
        if (wysh.wyshId === wyshes[i].wyshId) {
          return i;
        }
      }
    }

    return -1; // not found
  }

  getFilteredOrderedWyshes() {
    if (this.event) {
      return this.event.filter.filterContent(this.orderedWyshList.getOrderedWyshes());
    }

    return this.orderedWyshList.getOrderedWyshes();
  }

  getScalarOptionsAsNames() {

    var namesArray = [];

    this.wyshOptions.sort(WyshOption.compareByResultNormalizedAsc);


    for (var i = 0; i < this.wyshOptions.length; i++) {
      namesArray.push(
        {
          "name": this.wyshOptions[i].resultLiteral,
          "resultLiteral": this.wyshOptions[i].resultLiteral,
          "resultNormalized": this.wyshOptions[i].resultNormalized
        }
      );
    }

    return namesArray;
  }

  initFromJson(wyshJson) {
    this.wyshId = wyshJson["_id"];
    this.updated = wyshJson["_updated"];
    this.created = wyshJson["_updated"];
    this.v = wyshJson["_v"];
    this.caption = wyshJson["caption"];
    this.t = wyshJson["_t"];
    this.v = wyshJson["_updated"];
    this.creatorName = wyshJson["creator_name"];

    if (wyshJson["product_info"]) {
      this.product = WyshMeProduct.createFromJson(wyshJson["product_info"]);
    }

    if (wyshJson["question_type"]) {
      this.questionType = wyshJson["question_type"];
    }

    if (wyshJson["block"]) {
      this._block = wyshJson["block"];
    }

    if (wyshJson.snapshots) {
      for (const k in wyshJson.snapshots) {
        const ws = WyshSnapshot.createFromJson(wyshJson.snapshots[k], parseInt(k));
        this.wyshSnapshotMap.set(k, ws);
      }
    }

    if (wyshJson["block_type"]) {
      this._blockType = BlockTypes.getBlockType(wyshJson["block_type"]);
    }

    if (wyshJson["options"]) {
      let jsonOptions = wyshJson["options"];
      for (var i = 0; i < jsonOptions.length; i++) {
        let opt = new WyshOption();
        opt.resultLiteral = jsonOptions[i].name

        if (jsonOptions[i].resultNormalized) {
          opt.resultNormalized = jsonOptions[i].resultNormalized;
        }
        else {
          opt.resultNormalized = i; // TOTAL KLUDGE!!!
        }
        this.wyshOptions.push(opt);
      }
    }

    if (wyshJson["binary_icon_set_id"]) {
      this.binaryIconSetID = wyshJson["binary_icon_set_id"];
      this.binaryIconSet = BinaryIconSet.getBinaryIconSet(this.binaryIconSetID);
    }

    if (wyshJson["randomize_wysh_options"]) {
      this.randomizeWyshOptions = wyshJson["randomize_wysh_options"];
    }

    if (wyshJson["mc_include_none_of_the_above"]) {
      this.mcIncludeNoneOfTheAbove = wyshJson["mc_include_none_of_the_above"];
    }

    if (wyshJson["mc_include_other"]) {
      this.mcIncludeOther = wyshJson["mc_include_other"];
    }

    if (wyshJson["mc_options_limit"]) {
      this.mcOptionsLimit = wyshJson["mc_options_limit"];
    }

    if (wyshJson["must_view_all"]) {
      this.mustViewAll = wyshJson["must_view_all"];
    }

    if (wyshJson["scalar_description"]) {
      this.scalarDescription = wyshJson["scalar_description"];
    }

    if (wyshJson["free_response_questions"]) {
      let freeResponseQuestionsArray = wyshJson["free_response_questions"];
      for (i = 0; i < freeResponseQuestionsArray.length; i++) {
        this.freeResponseQuestions.push(freeResponseQuestionsArray[i]);
      }
    }

    if (wyshJson["branch_logic_statements"]) {

      let blsArray = wyshJson["branch_logic_statements"];
      for (var i = 0; i < blsArray.length; i++) {
        this.wyshRouter.addBranchLogicStatement(BranchLogicStatement.createFromJson(blsArray[i]));
      }
    }

    if (wyshJson["randomize"]) {
      this.randomize = wyshJson["randomize"];
      this.orderedWyshList.isRandomized(this.randomize);
    }

    if (wyshJson["forced_choice"]) {
      this.forcedChoice = wyshJson["forced_choice"];
    }

    if (wyshJson["display_block_card"]) {
      this.displayBlockCard = wyshJson["display_block_card"];
    }

    if (wyshJson["issue_sub_groups"]) {
      this.issueSubGroups = wyshJson["issue_sub_groups"];
    }

    if (wyshJson["sub_group_count"]) {
      this.subGroupCount = wyshJson["sub_group_count"];
    }

    if (wyshJson["limit_free_response"]) {
      this.limitFreeResponse = wyshJson["limit_free_response"];
    }

    if (wyshJson["limit_free_response_count"]) {
      this.limitFreeResponseCount = wyshJson["limit_free_response_count"];
    }

    if (wyshJson["hidden_free_response_options"]) {
      let hiddenOptionsArray = wyshJson["hidden_free_response_options"];
      for (i = 0; i < hiddenOptionsArray.length; i++) {
        this.hiddenFreeResponseOptions.push(hiddenOptionsArray[i]);
      }
    }

    if (wyshJson["words_to_omit"]) {
      this.wordsToOmit = wyshJson["words_to_omit"];
    }

    if (wyshJson["selected_free_response_decisions"]) {
      this.selectedFreeResponseDecisions = wyshJson["selected_free_response_decisions"];
    }
  }

  getDataScrubbingDict() {
    var m = {}
    m.words_to_omit = this.wordsToOmit;
    m.selected_free_response_decisions = this.selectedFreeResponseDecisions;
    return m;
  }

  importWyshFromJson(swydget, wyshJson) {

    this.wyshId = wyshJson['wysh_id'] ? wyshJson['wysh_id'] : null
    this.parentWyshId = wyshJson['parent_wysh_id'] ? wyshJson['parent_wysh_id'] : null
    this.nextWyshId = wyshJson['wysh_id_next'] ? wyshJson['wysh_id_next'] : null
    this.binaryIconSetID = wyshJson['binary_icon_set_id'] ? wyshJson['binary_icon_set_id'] : null
    this.binaryIconSet = BinaryIconSet.getBinaryIconSet(this.binaryIconSetID);
    this._block = wyshJson['block'] ? wyshJson['block'] : false
    this._blockType = wyshJson['block_type'] ? BlockTypes.getBlockType(wyshJson['block_type']) : null
    this.event = swydget
    this.caption = wyshJson['caption'] ? wyshJson['caption'] : ""
    this.created = wyshJson['created'] ? wyshJson['created'] : 0
    this.displayBlockCard = wyshJson['display_block_card'] ? wyshJson['display_block_card'] : false
    this.forcedChoice = wyshJson['forced_choice'] ? wyshJson['forced_choice'] : false
    this.product = wyshJson['product'] ? WyshMeProduct.createFromJson(wyshJson['product']) : {}
    this.freeResponseQuestions = wyshJson['free_response_questions'] ? wyshJson['free_response_questions'] : []
    this.gatherFreeResponse = wyshJson['gather_free_response'] ? wyshJson['gather_free_response'] : false
    this.hiddenFreeResponseOptions = wyshJson['hidden_free_response_options'] ? wyshJson['hidden_free_response_options'] : []
    this.issueSubGroups = wyshJson['issue_sub_groups'] ? wyshJson['issue_sub_groups'] : false
    this.limitFreeResponse = wyshJson['limit_free_response'] ? wyshJson['limit_free_response'] : false
    this.limitFreeResponseCount = wyshJson['limit_free_response_count'] ? wyshJson['limit_free_response_count'] : 0
    this.mcIncludeNoneOfTheAbove = wyshJson['mc_include_none_of_the_above'] ? wyshJson['mc_include_none_of_the_above'] : false
    this.mcIncludeOther = wyshJson['mc_include_other'] ? wyshJson['mc_include_other'] : false
    this.mcOptionsLimit = wyshJson['mc_options_limit'] ? wyshJson['mc_options_limit'] : 0
    this.mustViewAll = wyshJson['must_view_all'] ? wyshJson['must_view_all'] : false
    this.nextWysh = null; // This will be wired later
    if (wyshJson['ordered_wysh_list']) {
      this.orderedWyshList.importFromJson(swydget, wyshJson['ordered_wysh_list']);
    }
    this.questionType = wyshJson['question_type'] ? wyshJson['question_type'] : "binary"
    this.randomize = wyshJson['randomize'] ? wyshJson['randomize'] : false
    this.randomizeWyshOptions = wyshJson['randomize_wysh_options'] ? wyshJson['randomize_wysh_options'] : false
    this.scalarDescription = wyshJson['scalar_description'] ? wyshJson['scalar_description'] : ""
    this.subGroupCount = wyshJson['sub_group_count'] ? wyshJson['sub_group_count'] : 0
    this.t = wyshJson['t'] ? wyshJson['t'] : ""
    this.updated = wyshJson['updated'] ? wyshJson['updated'] : 0
    this.v = wyshJson['v'] ? wyshJson['v'] : 0
    this.setWyshOptions(wyshJson['wysh_options']);

    this.wyshRouter.importWyshRouterFromJson(wyshJson['wysh_router']);

    // this.wyshRouter --> WyshRouter
  }

  toSimpleArray(indexLabel) {
    var wysh = [];
    wysh.push("Q" + indexLabel);
    wysh.push(this.product.productId);
    wysh.push(this.product.name);
    wysh.push(this.caption);
    wysh.push(this.product.description); // card text
    wysh.push(this.product.imageUrl);

    var globalAttributesArray = this.event.getGlobalAttributesArray();

    for (var i = 0; i < globalAttributesArray.length; i++) {
      for (var key in globalAttributesArray[i]) {
        if (this.product.hasAttribute(key, globalAttributesArray[i][key])) {
          wysh.push(1);
        }
        else {
          wysh.push("");
        }
      }
    }

    return wysh;
  }

  childWyhsesToSimpleArray(indexLabel) {

    var childArray = [];
    var childWyshes = this.orderedWyshList.getOrderedWyshes();
    for (var i = 0; i < childWyshes.length; i++) {
      childArray.push(childWyshes[i].toSimpleArray(indexLabel + "-" + (i + 1)));
    }

    return childArray;
  }

  getAllDescendantWyshes() {

    var childArray = [];

    for (const child of this.orderedWyshList.wyshes) {
      childArray.push(child);
      childArray = childArray.concat(child.getAllDescendantWyshes());
    }

    return childArray
  }

  toExportJsonString() {

    // Prune Top Level BranchLogicStatements.
    // An export of a Wysh is a top level activity. There is no context surrounding
    // The stimuli. Therefore, all BLS that are not either blockComplete or survey
    // must be pruned.

    var myJsonObject = this.toJsonObject();
    var prunedBranchLogicStatements = [];

    for (const bls of myJsonObject.wysh_router.branch_logic_statements) {
      if (bls.nextWyshId === 'blockComplete' || bls.nextWyshId === 'surveyComplete') {
        prunedBranchLogicStatements.push(bls);
      }
    }

    myJsonObject.wysh_router.branch_logic_statements = prunedBranchLogicStatements;

    return JSON.stringify(myJsonObject);
  }

  toJsonObject() {

    return {
      binary_icon_set_id: this.binaryIconSetID,
      block: this._block,
      block_type: this._blockType ? this._blockType.blockTypeId : null,
      caption: this.caption,
      created: this.created,
      display_block_card: this.displayBlockCard,
      forced_choice: this.forcedChoice,
      free_response_questions: this.freeResponseQuestions,
      gather_free_response: this.gatherFreeResponse,
      hidden_free_response_options: this.hiddenFreeResponseOptions,
      issue_sub_groups: this.issueSubGroups,
      limit_free_response: this.limitFreeResponse,
      limit_free_response_count: this.limitFreeResponseCount,
      mc_include_none_of_the_above: this.mcIncludeNoneOfTheAbove,
      mc_include_other: this.mcIncludeOther,
      mc_options_limit: this.mcOptionsLimit,
      must_view_all: this.mustViewAll,
      ordered_wysh_list: this.orderedWyshList.toJsonObject(),
      parent_wysh_id: this.parentWysh ? this.parentWysh.wyshId : null,
      product: this.product.toJsonObject(),
      question_type: this.questionType,
      randomize: this.randomize,
      randomize_wysh_options: this.randomizeWyshOptions,
      scalar_description: this.scalarDescription,
      selected_free_response_decisions: this.selectedFreeResponseDecisions,
      sub_group_count: this.subGroupCount,
      updated: this.updated,
      words_to_omit: this.wordsToOmit,
      wysh_id: this.wyshId,
      wysh_id_next: this.nextWysh ? this.nextWysh.wyshId : null,
      wysh_options: this.wyshOptions,
      wysh_router: this.wyshRouter.toJsonObject()
    };



    // These guys need similar "toJsonObject" methods:
    // orderedWyshList
    // wyshOptions


  }

  getQuestionTypeLabel() {

    // Max Diff is a special case
    if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
      return "Max Diff"
    }
    else if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
      return "Pairwise"
    }

    switch (this.questionType) {
      case "binary":
        return "Binary";

        break;
      case "next":
        return "Next";

        break;
      case "free-response-only":
        return "Verbatim";

        break;
      case "scalar":
        return "Scalar";

        break;
      case "single-select":
        return "Single Select";

        break;
      case "multiple-choice":
        return "Multiple Choice";

        break;
      default:
        return "";

    }
  }

  getQuestionTypeDescription() {

    // Max Diff is a special case
    if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
      return "Max Diff"
    }
    else if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
      return "Pairwise Comparison"
    }

    switch (this.questionType) {
      case "binary":
        return "Respondents select between two choices";

        break;
      case "next":
        return "Respondents are prompted with infomation they must acknowedge";

        break;
      case "free-response-only":
        return "Respondents are prompted to enter a verbatim response only";

        break;
      case "scalar":
        return "Respondents must select a range of options";

        break;
      case "single-select":
        return "Respondents must select a single response option";

        break;
      case "multiple-choice":
        return "Respondents can select multiple response option";

        break;
      default:
        return "";

    }
  }

  isFreeResponseOnly() {
    return this.questionType === "free-response-only" ? true : false;
  }

  hasSkipLogic(decisionValue) {

    if (this.wyshRouter.branchLogicStatements.length > 0) {
      for (var i = 0; i < this.wyshRouter.branchLogicStatements.length; i++) {
        for (const skipRule of this.wyshRouter.branchLogicStatements[i].skipRules) {
          for (const sr of skipRule) {
            if (decisionValue === sr.resultNormalized) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  getExportFileName() {
    return this.caption ? this.caption : this.product.name;
  }

  isSwipeSupported() {

    // console.log(this);

    if (this.isBlock()) {
      return false;
    }
    else {
      return (this.questionType === "binary" || this.questionType === "next") ? true : false;
    }
  }

  static compareByLikesDesc(a, b) {
    return b.aggregatedResults.getPctLiked() - a.aggregatedResults.getPctLiked();
  }

  static compareByLikesAsc(a, b) {
    return a.aggregatedResults.getPctLiked() - b.aggregatedResults.getPctLiked();
  }

  static compareByTotalResponsesDesc(a, b) {
    return b.aggregatedResults.getTotalResponses() - a.aggregatedResults.getTotalResponses();
  }

  static compareBTotalResponsesAsc(a, b) {
    return a.aggregatedResults.getTotalResponses() - b.aggregatedResults.getTotalResponses();
  }

  static compareAverageNormalizedDesc(a, b) {
    return b.getAverageNormalized() - a.getAverageNormalized();
  }

  static compareAverageNormalizedAsc(a, b) {
    return a.getAverageNormalized() - b.getAverageNormalized();
  }

  static compareAverageScoreDesc(a, b) {
    return b.averageScore - a.averageScore;
  }

  static compareAverageScoreAsc(a, b) {
    return a.averageScore - b.averageScore;
  }

  static removeDuplicates(wyshArray) {

    // remove duplicates
    const seen = new Set();

    let uniqueWyshes = wyshArray.filter(wysh => {
      const hash = wysh.wyshId;

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    return uniqueWyshes;
  }

  getOptionValue(index) {
    if (this.wyshOptions.length < 1 || index > this.wyshOptions.length) {
      return "";
    }
    else {
      return this.wyshOptions[index] ? this.wyshOptions[index].resultLiteral : this.wyshOptions[this.wyshOptions.length - 1].resultLiteral
    }
  }

  getTakerCount(snapshot) {
    if (snapshot) {
      this.applySnapshot(snapshot);
    }
    return this.takerCount;
  }

  getOptionsArray(snapshotKeys) {
    // KLUDGE ALERT: Binary, MC, MaxDiff, all added at different times and are not super consistent. The whole thing needs an overhaul

    let questionType = this.questionType;

    if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF)) {
      questionType = "maxdiff";
    }
    else if (this.parentWysh && this.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE)) {
      questionType = "pairwise";
    }

    switch (questionType) {
      case "pairwise":
        const pairwiseArray = [];
        pairwiseArray.push("", "Importance Score");

        // Option Counts
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            pairwiseArray.push(this.takerCount);
          }
        }

        // Option Percentages
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            pairwiseArray.push(this.getAverageScore());
          }
        }
        
        console.log([pairwiseArray]);
        return [pairwiseArray];
      case "maxdiff":
        const maxdiffArray = [];
        maxdiffArray.push("", "Utility Score");

        // Option Counts
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            maxdiffArray.push(this.takerCount);
          }
        }

        // Option Percentages
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            maxdiffArray.push(this.getAverageScore());
          }
        }

        return [maxdiffArray];
      case "binary":
      case "next":
        const yesArray = [1, "Yes"];
        const noArray = [0, "No"];

        // Option Counts
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            yesArray.push(this.aggregatedResults.likesCount);
            noArray.push(this.aggregatedResults.dislikesCount);
          }
        }

        // Option Percentages
        for (const snapshotKey of snapshotKeys) {
          const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
          if (wyshSnapshot) {
            this.applySnapshot(wyshSnapshot);
            yesArray.push(this.getAverage());
            noArray.push(1 - this.getAverage());
          }
        }

        yesArray.push(this.hasSkipLogic(1) ? "Yes" : "");
        yesArray.push("");
        yesArray.push(this.freeResponseQuestions.length > 0 ? this.hiddenFreeResponseOptions.includes(1) ? "No" : "Yes" : "");
        noArray.push(this.hasSkipLogic(0) ? "Yes" : "");
        noArray.push("");
        noArray.push(this.freeResponseQuestions.length > 0 ? this.hiddenFreeResponseOptions.includes(0) ? "No" : "Yes" : "");

        return [yesArray, noArray];
      case "free-response-only":
          console.log("free response");
          return [];
      case "scalar":
      case "single-select":
      case "multiple-choice":
        const optionsArray = []
        for (const option of this.wyshOptions) {
          const optionArray = [];
          const resultNormalized = (option.resultNormalized !== 99.0 && option.resultNormalized !== 98.0) ? option.resultNormalized + 1 : option.resultNormalized;
          optionArray.push(resultNormalized, option.resultLiteral);

          // Option Counts
          for (const snapshotKey of snapshotKeys) {
            const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
            if (wyshSnapshot) {
              this.applySnapshot(wyshSnapshot);
              optionArray.push(option.decisionsCount);
            }
          }

          // Option Percentages
          for (const snapshotKey of snapshotKeys) {
            const wyshSnapshot = this.wyshSnapshotMap.get(snapshotKey);
            if (wyshSnapshot) {
              this.applySnapshot(wyshSnapshot);

              let percentage = 0.0;
              if (option.decisionsCount > 0) {
                percentage = option.decisionsCount / this.takerCount;
              }

              optionArray.push(percentage);
            }
          }

          optionArray.push(this.hasSkipLogic(option.resultNormalized) ? "Yes" : "");
          optionArray.push(""); // Verbatim Prompt at Stimulus Metadata level
          optionArray.push(this.freeResponseQuestions.length > 0 ? this.hiddenFreeResponseOptions.includes(option.resultNormalized) ? "No" : "Yes" : "");

          optionsArray.push(optionArray);
        }


        return optionsArray;
      default:
        return [];
    }
  }

  getTotalResponses() {

    // return this.aggregatedResults.getTotalResponses();

    if (this.questionType === "" || this.questionType === "binary") {
      return this.aggregatedResults.getTotalResponses();
    }
    else if (this.questionType === "scalar") {
      return this._getScalarTotalResponses();
    }
    else {
      return 0;
    }
  }

  _getScalarTotalResponses() {

    var totalResponses = 0;

    for (var i = 0; i < this.wyshOptions.length; i++) {
      totalResponses += this.wyshOptions[i].decisionsCount;
    }

    return totalResponses;
  }

  _getScalarAverage() {
    var numberOfDecisions = 0.0;
    var total = 0.0;

    for (var i = 0; i < this.wyshOptions.length; i++) {
      total += (this.wyshOptions[i].resultNormalized * this.wyshOptions[i].decisionsCount);
    }

    return total / this.getTotalResponses();
  }

  _getScalarAverageForPresentation() {
    var numberOfDecisions = 0.0;
    var total = 0.0;

    for (var i = 0; i < this.wyshOptions.length; i++) {
      total += ((this.wyshOptions[i].resultNormalized + 1) * this.wyshOptions[i].decisionsCount);
    }

    return total / this.getTotalResponses();
  }

  getAverageNormalized() {
    if (this.getTotalResponses() > 0) {
      if (this.questionType === "scalar") {
        if (this.wyshOptions.length > 2) {
          return this._getScalarAverage() / this.wyshOptions.length;
        }
        else {
          return this._getScalarAverage(); // when there is only 2 don't divide it again.
        }

      }
      else {
        return this.aggregatedResults.getAverage();
      }
    }
    else {
      return 0;
    }
  }

  getAverage() {
    if (this.getTotalResponses() > 0) {
      if (this.questionType === "scalar") {
        return this._getScalarAverage(); // when there is only 2 don't divide it again.
      }
      else {
        return this.aggregatedResults.getAverage();
      }
    }
    else {
      return 0;
    }
  }

  getDecisionsArray() {

    var decisions = [];

    for (var i = 0; i < this.wyshOptions.length; i++) {
      for (var j = 0; j < this.wyshOptions[i].decisionsArray.length; j++)
        decisions.push(this.wyshOptions[i].decisionsArray[j]);
    }

    // remove duplicates
    const seen = new Set();

    let uniqueDecisions = decisions.filter(decision => {
      const hash = decision.getHash();

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    return uniqueDecisions;
  }

  getMedianDecision() {
    var decisions = this.getDecisionsArray()

    decisions.sort(Decision.compareByResultNormalizedAsc);

    var index = Math.round(decisions.length / 2);

    return decisions[index];
  }

  getModesOptionLiteral() {
    var decisionsMap = {};
    var modeDecisionsText = [];
    var highCount = 0;

    let decisions = this.getDecisionsArray();

    for (var i = 0; i < decisions.length; i++) {
      if (decisions[i].productId === this.product.productId && decisions[i].eventId === this.event.eventId) {
        if (decisionsMap[decisions[i].resultLiteral]) {
          var count = decisionsMap[decisions[i].resultLiteral];
          decisionsMap[decisions[i].resultLiteral] = count + 1;
        }
        else {
          decisionsMap[decisions[i].resultLiteral] = 1;
        }
      }
    }

    // let keys = Object.keys(this.decisionsByMidDict);
    // for (var i = 0; i < keys.length; i++) {
    //   var decArray = this.decisionsByMidDict[keys[i]];
    //   for (var j = 0; j < decArray.length; j++) {
    //     if (decArray[j].productId === this.product.productId && decArray[j].eventId === this.event.eventId) {
    //       if (decisionsMap[decArray[j].resultLiteral]){
    //         var count = decisionsMap[decArray[j].resultLiteral];
    //         decisionsMap[decArray[j].resultLiteral] = count + 1;
    //       }
    //       else {
    //         decisionsMap[decArray[j].resultLiteral] = 1;
    //       }
    //     }
    //   }
    // }

    let modeKeys = Object.keys(decisionsMap);
    for (i = 0; i < modeKeys.length; i++) {
      if (decisionsMap[modeKeys[i]] > highCount) {
        highCount = decisionsMap[modeKeys[i]];
        modeDecisionsText = [];
        modeDecisionsText.push(modeKeys[i]);
      }
      else if (decisionsMap[modeKeys[i]] === highCount) {
        modeDecisionsText.push(modeKeys[i]);
      }
    }

    return modeDecisionsText;
  }

  getStandardDeviation() {
    var squaredNumbersMinusMeanTotal = 0.0;
    var squaredNumbersMinusMeanMean = 0.0;
    let mean = this._getScalarAverage();
    let decisions = this.getDecisionsArray();

    for (var i = 0; i < decisions.length; i++) {
      squaredNumbersMinusMeanTotal += Math.pow((decisions[i].resultNormalized - mean), 2);
    }

    squaredNumbersMinusMeanMean = squaredNumbersMinusMeanTotal / decisions.length;

    return Math.sqrt(squaredNumbersMinusMeanMean);

  }

  getAverageOption() {
    var index = Math.round(this._getScalarAverage());
    if (index < this.wyshOptions.length) {
      return this.wyshOptions[index].resultLiteral + " (" + this._getScalarAverageForPresentation().toFixed(3) + ")";
    }
    else {
      return "Indeterminate";
    }
  }

  resetAggregateResults() {
    this.aggregatedResults.reset();

    for (var i = 0; i < this.wyshOptions.length; i++) {
      this.wyshOptions[i].decisionsCount = 0;
    }

    var wyshes = this.orderedWyshList.getOrderedWyshes();
    for (i = 0; i < wyshes.length; i++) {
      wyshes[i].resetAggregateResults();
    }

  }

  getAverageScore() {

    // if (this.parentWysh && this.parentWysh.isBlock() === true && (this.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF))){
    //   let topAvgScore = -1000;
    //   let bottomAvgScore = 1000;
    //   let total = 0;
    //   for (const w of this.parentWysh.getFilteredOrderedWyshes()) {
    //     total += w.averageScore;
    //     if (w.averageScore > topAvgScore) {
    //       topAvgScore = w.averageScore;
    //     }
    //     if (w.averageScore < bottomAvgScore) {
    //       bottomAvgScore = w.averageScore;
    //     }
    //   }

    //   const spread = topAvgScore - bottomAvgScore
    //   const delta = topAvgScore - this.averageScore;
    //   const avgScore = 100 * (spread - delta) / spread;
    //   return avgScore;
    // }

    return this.averageScore;
  }

  processDecisionCounts(decisionCountsDict) {

    if (this.parentWysh && this.parentWysh.isBlock() === true && (this.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) || this.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE))) {
      for (var optionValue in decisionCountsDict) {
        if (optionValue === "average_score") {

          // const spread = topAvgScore - bottomAvgScore
          // const delta = topAvgScore - decisionCountsDict[optionValue];
          // const avgScore = 100 * (spread - delta) / spread;

          this.averageScore = decisionCountsDict[optionValue];
        }
      }
    }

    if (this.questionType === "binary" ||
      this.questionType === "next" ||
      this.questionType === "" ||
      this.questionType === undefined ||
      this.questionType === null) {
      if (this.aggregatedResults) {
        if (decisionCountsDict["1.0"]) {
          this.aggregatedResults.likesCount = decisionCountsDict["1.0"];
        }
        else {
          this.aggregatedResults.likesCount = 0.0;
        }
        if (decisionCountsDict["0.0"]) {
          this.aggregatedResults.dislikesCount = decisionCountsDict["0.0"];
        }
        else {
          this.aggregatedResults.dislikesCount = 0.0;
        }

        this.aggregatedResults.totalResponses = this.aggregatedResults.likesCount + this.aggregatedResults.dislikesCount;
      }
    }
    else {
      var totResp = 0.0;
      this.aggregatedResults.totalResponses = 0.0;
      for (var i = 0; i < this.wyshOptions.length; i++) {
        this.wyshOptions[i].decisionsCount = 0;
      }


      for (var optionValue in decisionCountsDict) {
        let intValue = parseInt(optionValue, 10);
        var wyshOption = this.getWyshOption(intValue);
        if (wyshOption) {
          wyshOption.decisionsCount = decisionCountsDict[optionValue];
          this.aggregatedResults.totalResponses += wyshOption.decisionsCount;
        }

      }
    }
  }

  isFreeResponseHidden(optionIndex) {

    // if there are no questions, it should be hidden
    // if (this.freeResponseQuestions.length === 0) {
    //   return true;
    // }

    return this.hiddenFreeResponseOptions.includes(optionIndex) ? true : false;
  }

  equals(wysh) {
    if (wysh) {
      if (this.wyshId === wysh.wyshId) {
        return true;
      }
    }
    return false;
  }

  generateDecisionsWithFreeResponses() {

    var decisionsWithFreeResponses = [];
    if (this.event.freeResponseAnswersByProduct[this.product.productId]) {
      decisionsWithFreeResponses = this.event.freeResponseAnswersByProduct[this.product.productId].filter(decision => {

        if (decision.freeResponseAnswers && decision.freeResponseAnswers.length > 0) {
          return true;
        }

        return false;
      });
    }

    return decisionsWithFreeResponses;
  }

  calculateDepth() {
    var level = 1;
    var pw = this.parentWysh;

    if (pw) {
      for (var i = 0; pw !== null; i++) {
        level += 1;
        pw = pw.parentWysh;
      }
    }

    return level;
  }

  generateQuestionNumbersMap(parentQuestionNumber, labelMap) {

    let filteredOrderedWyshes = this.getFilteredOrderedWyshes();

    for (var i = 0; i < filteredOrderedWyshes.length; i++) {
      var myQuestionNumber = parentQuestionNumber + "-" + (i + 1).toString();
      filteredOrderedWyshes[i].questionNumber = myQuestionNumber;
      labelMap.set(myQuestionNumber, filteredOrderedWyshes[i]);
      if (filteredOrderedWyshes[i].isBlock() === true) {
        filteredOrderedWyshes[i].generateQuestionNumbersMap(myQuestionNumber, labelMap);
      }
    }
  }

  setMaxDiffVersion(mdv) {
    if (mdv) {
      this.maxDiffVersion = mdv;
      this.maxDiffTrialSets = this.maxDiffVersion.getTrialSets(this);
    }
  }

  getMaxDiffVersion() {
    return this.maxDiffVersion;
  }

  setPairwiseVersion(version) {
    if (version) {
      this.pairwiseVersion = version;
      this.pairwiseTrialSets = this.pairwiseVersion.getTrialSets(this);
    }
  }

  getPairwiseVersion() {
    return this.pairwiseVersion;
  }

  static generateAttributesMap(wyshes) {

    var attributesMap = new Map();

    for (var i = 0; i < wyshes.length; i++) {
      var keys = Object.keys(wyshes[i].product.attributes);
      for (const k of keys) {
        if (attributesMap.has(k)) {
          var valuesArray = attributesMap.get(k);
          valuesArray.push(wyshes[i].product.attributes[k]);
          attributesMap.set(k, valuesArray)
        }
        else {
          attributesMap.set(k, [wyshes[i].product.attributes[k]]);
        }
      }
    }

    return attributesMap;
  }

  removeStopWords(snapshot = null) {
    if (snapshot) {
      return Wysh.removeStopWords(this.wordFrequencyArray, snapshot.wordsToOmit);
    }
    else {
      return Wysh.removeStopWords(this.wordFrequencyArray, this.wordsToOmit);
    }
  }

  applySnapshot(snapshot) {
    if (snapshot) {
      const ws = this.wyshSnapshotMap.get(snapshot.created.toString());
      if (ws) {
        this.takerCount = ws.wyshTakerCount;
        this.processDecisionCounts(ws.wyshDecisionCounts);
      }
    }
  }

  static removeStopWords = (wordFrequencyArray, wordsToOmit) => {

    // remove duplicates
    const seen = new Set();

    let filteredWfa = wordFrequencyArray.filter(wordFreq => {

      if (seen.has(wordFreq.word)) {
        return false;
      }

      if (wordsToOmit.indexOf(wordFreq.word) < 0) {
        seen.add(wordFreq.word);
        return true;
      }

      return false;
    });

    return filteredWfa;
  }

  static containsDecision = (decision, selectedFreeResponseDecisions) => {

    const decisionHash = decision.takerMid + decision.productId;

    for (var i = 0; i < selectedFreeResponseDecisions.length; i++) {
      let selHash = selectedFreeResponseDecisions[i].takerMid + selectedFreeResponseDecisions[i].productId;
      if (decisionHash === selHash) {
        return true;
      }
    }

    return false;
  }

  static determineFontWeight(weight) {
    if (weight > 900) {
      return 900;
    }
    else if (weight > 800) {
      return 800;
    }
    else if (weight > 700) {
      return 700
    }
    else if (weight > 600) {
      return 600
    }
    else if (weight > 500) {
      return 500
    }
    else if (weight > 400) {
      return 400
    }
    else if (weight > 300) {
      return 300
    }
    else if (weight > 200) {
      return 200
    }
    else if (weight > 100) {
      return 100
    }
    else {
      return 100;
    }
  }

  static getWordColor(word, wordFrequencyArray) {

    var normalizedResult = 0.0;

    var wordFreq = null;
    for (var i = 0; i < wordFrequencyArray.length; i++) {
      if (wordFrequencyArray[i].word.toLowerCase() === word.text.toLowerCase()) {
        wordFreq = wordFrequencyArray[i];
        break;
      }
    }

    normalizedResult = wordFreq.totalResultsNormalized / wordFreq.count;

    if (normalizedResult >= 0 && normalizedResult <= 0.1) {
      return "#da020d";
    }
    else if (normalizedResult > 0.1 && normalizedResult <= 0.2) {
      return "#ff020d";
    }
    else if (normalizedResult > 0.2 && normalizedResult <= 0.3) {
      return "#ff7d7f";
    }
    else if (normalizedResult > 0.3 && normalizedResult <= 0.4) {
      return "#ddbdbd";
    }
    else if (normalizedResult > 0.4 && normalizedResult <= 0.5) {
      return "#beb9b9";
    }
    else if (normalizedResult > 0.5 && normalizedResult <= 0.6) {
      return "#bebfc5";
    }
    else if (normalizedResult > 0.6 && normalizedResult <= 0.7) {
      return "#b1c0d4";
    }
    else if (normalizedResult > 0.7 && normalizedResult <= 0.8) {
      return "#2db5ff";
    }
    else if (normalizedResult > 0.8 && normalizedResult <= 0.9) {
      return "#0070ff";
    }
    else if (normalizedResult > 0.9 && normalizedResult <= 1.0) {
      return "#0018ff";
    }
    else {
      return "#000000";
    }
  }

  static arraysHiddenOptionsEqual(hiddenOptions1, hiddenOptions2) {
    if (hiddenOptions1.length !== hiddenOptions2.length)
      return false;
    for (var i = hiddenOptions1.length; i--;) {
      if (hiddenOptions1[i] !== hiddenOptions2[i])
        return false;
    }

    return true;
  }

  static createFromJson(wyshJson) {
    var wysh = new Wysh();
    wysh.initFromJson(wyshJson);
    return wysh;
  }

  static importWyshFromJson(swydget, wyshJson) {
    var wysh = new Wysh();
    wysh.importWyshFromJson(swydget, wyshJson);
    return wysh;
  }
}
