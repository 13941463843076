import React, { Component } from 'react';
import { Table, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import classes from "../../css/modules/ProductLibraryPickerTable.module.css"; 
import MeService from '../../services/MeService';
import meStore from "../../stores/MeStore";
import loginStore from "../../stores/LoginStore";
import ProductLibraryPickerTableRow from "./ProductLibraryPickerTableRow";
import WyshMeProduct from "../../classes/WyshMeProduct";
import { ChevronLeft, ChevronRight, Refresh } from '@mui/icons-material';


class ProductLibraryPickerTable extends Component {

  constructor(props) {
    super(props);

    this._generateRows = this._generateRows.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.getSortMethod = this.getSortMethod.bind(this);
    this.addingStimuliToSwydget = this.addingStimuliToSwydget.bind(this);
    this.stimuliAdded = this.stimuliAdded.bind(this);
    this.productLibraryLoaded = this.productLibraryLoaded.bind(this);

    this.user = loginStore.getLoggedInUser();

    this.state = {
      sortOrder: "newest",
      isUploading: false,
      myProducts: meStore.getMyProducts,
      myProductsTotalSize: meStore.myProductsTotalSize,
      page: 1
    }
  };

  componentDidMount() {
    meStore.on("change-productLibraryLoaded", this.productLibraryLoaded);
    meStore.on("change-addProductToSwydget", this.addingStimuliToSwydget);
    meStore.on("change-eventLoaded", this.stimuliAdded);
    if (this.user) {
      if (this.user.userId.length > 0) {
        MeService.getProducts(this.user, this.user.password, 0, 9, this.state.sortOrder);
      }
    }
  }

  componentWillUnmount() {
    meStore.removeListener("change-productLibraryLoaded", this.productLibraryLoaded);
    meStore.removeListener("change-addProductToSwydget", this.addingStimuliToSwydget);
    meStore.removeListener("change-eventLoaded", this.stimuliAdded);
  }

  productLibraryLoaded() {
    this.setState({ myProducts: meStore.getMyProducts(), myProductsTotalSize: meStore.myProductsTotalSize });
  }


  setSortOrder(e) {
    this.setState({ sortOrder: e.target.value, page: 1 });

    var first = 0;
    var last = 9;
    MeService.getProducts(this.user, this.user.password, first, last, e.target.value);
  }

  getSortMethod() {
    var sortMethod = WyshMeProduct.compareByDateUploadedDesc;

    switch (this.state.sortOrder) {
      case "newest":
        sortMethod = WyshMeProduct.compareByDateUpdatedDesc;
        break;
      case "oldest":
        sortMethod = WyshMeProduct.compareByDateUpdatedAsc;
        break;
      case "a-z":
        sortMethod = WyshMeProduct.compareByNameAsc;
        break;
      case "z-a":
        sortMethod = WyshMeProduct.compareByNameDesc;
        break;

      default:

    }

    return sortMethod;
  }

  _generateRows(myProducts) {

    var eif = this.props.eventInFocus;

    var usedProducts = eif.getAllProductsArray();

    var rows = [];
    if (myProducts) {

      // myProducts.sort(this.getSortMethod());

      for (var i = 0; i < myProducts.length; i++) {

        rows.push(<ProductLibraryPickerTableRow
          disabled={(usedProducts.filter(p => p.productId === myProducts[i].productId).length > 0) ? true: false}
          isUploading={this.state.isUploading}
          eventInFocus={eif}
          parentWysh={this.props.parentWysh}
          key={myProducts[i].productId}
          product={myProducts[i]}
          />
        );
      }
    }

    return rows;
  }

  addingStimuliToSwydget() {
    this.setState({
      isUploading: true
    });
  }

  stimuliAdded() {
    this.setState({
      isUploading: false
    })
  }

  pageForward = () => {
    var page = this.state.myProductsTotalSize > this.state.page * 10 ? this.state.page + 1 : this.state.page;

    if (page > this.state.page) {
      var first = 0 + (page - 1) * 10;
      var last = 9 + (page - 1) * 10;

      this.setState({page: page});
      MeService.getProducts(this.user, this.user.password, first, last, this.state.sortOrder);
    }
  }

  pageBackward = () => {
    var page = this.state.page > 1 ? this.state.page - 1 : 1;

    if (page < this.state.page) {
      var first = 0 + (page - 1) * 10;
      var last = 9 + (page - 1) * 10;

      this.setState({page: page});
      MeService.getProducts(this.user, this.user.password, first, last, this.state.sortOrder);
    }
  }

  refresh = () => {
    var first = 0 + (this.state.page - 1) * 10;
    var last = 9 + (this.state.page - 1) * 10;
    MeService.getProducts(this.user, this.user.password, first, last);
  }

  render() {

    const { parentWysh } = this.props;

    let divModalTitle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      paddingRight: 20
    }

    let buttonStyle = {
      height: 30,
      width: 30,
      padding: 0,
      marginLeft: 15,
      marginRight: 0,
      marginBottom: 0
    }


    return (
      <div className={`${classes.container}`}>
        <div className={`${classes.tableHeaderContainerStyle}`}>
          <div>
            <div>Page: {this.state.page}</div>
            <div>
              Products: {this.state.myProductsTotalSize}
            </div>
          </div>
          <div>
            <Button variant="secondary" style={buttonStyle} onClick={this.refresh}><Refresh /></Button>
            <Button variant="secondary" style={buttonStyle} onClick={this.pageBackward}><ChevronLeft /></Button>
            <Button variant="secondary" style={buttonStyle} onClick={this.pageForward}><ChevronRight /></Button>
          </div>
          <div className={`${classes.circularProgressDivStyle}`}>
            { this.state.isUploading &&
              <CircularProgress size={20}/>
            }
          </div>
          <div className={`${classes.formControlContainerStyle}`}>
            <label className={`${classes.controlLabelStyle}`}>SORT BY</label>
            <FormControl
              value={this.state.sortOrder}
              onChange={this.setSortOrder}
              componentClass="select"
              placeholder="select">
              <option value="newest">Most Recent</option>
              <option value="oldest">Oldest</option>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
            </FormControl>
          </div>
        </div>
        <div>
          <Table className={`${classes.tableStyle}`} responsive>
            <thead className={`${classes.theadStyle}`}>
              <tr className={`${classes.theadStyleTr}`}>
                <th className={`${classes.theadImgStyleTh}`}>Question Card</th>
                <th className={`${classes.theadsStyleTh}`}>Name</th>
              </tr>
            </thead>
            <tbody>
              { this._generateRows(this.state.myProducts) }
            </tbody>
          </Table>

        </div>
      </div>
    )
  }
}

export default ProductLibraryPickerTable;
