import React from 'react';
import { TextField } from '@mui/material';
import { Modal, Button }  from 'react-bootstrap';
import sha256 from "js-sha256";
import classes from "../../css/modules/EditUserInfo.module.css";
import "../../css/swytchbackStyles.css";
import Auth from '../../services/AuthService';
import User from "../../classes/User";
import LoginStore from "../../stores/LoginStore";


class EditUserInfo extends React.Component {

  constructor() {
    super();
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.userExists = this.userExists.bind(this);
    this.userCreated = this.userCreated.bind(this);
    this.loginFailed = this.loginFailed.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }

    this.primaryAction = null;
    this.modalTitle = "Title";
    this.modalMessage = "Message";

    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      birthday: "",
      password: "",
      gender: "male",
      loggedInUser: this.loggedInUser,
      isUserLoggedIn: this.isUserLoggedIn,
      showAlertModal: false
    };
  }

  componentWillMount() {
    this.registerWithLoginStore();
  }

  componentWillUnmount() {
    this.unRegisterWithLoginStore();
  }

  registerWithLoginStore() {
    LoginStore.on("loginStore_user_logged_in", this.userExists);
    LoginStore.on("loginStore_user_created", this.userCreated);
    LoginStore.on("change-logout", this.setLoggedInUser);
    LoginStore.on("change-loginFailed", this.loginFailed);
  }

  unRegisterWithLoginStore() {
    LoginStore.removeListener("loginStore_user_logged_in", this.userExists);
    LoginStore.removeListener("loginStore_user_created", this.userCreated);
    LoginStore.removeListener("change-logout", this.setLoggedInUser);
    LoginStore.removeListener("change-loginFailed", this.loginFailed);
  }

  openAlertModal() {
    this.modalTitle = "Coming Soon";
    this.modalMessage = "You will be able to specify a reward for this survey."
    this.setState({ showAlertModal: true });
  }

  closeAlertModal() {
    console.log(this.state.loggedInUser);
    this.setState({ showAlertModal: false });
    if (this.state.loggedInUser.userId && this.state.loggedInUser.userId.length > 0) {
      console.log("TAKE NEXT STEP...");
      if (this.primaryAction !== null) {
        this.primaryAction();
      }
    }
  }

  setLoggedInUser() {
    this.loggedInUser = LoginStore.getLoggedInUser();
    if (this.loggedInUser != null) {
      this.isUserLoggedIn = true;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: true
      });
    }
    else {
      this.isUserLoggedIn = false;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: false
      });
    }
  }

  userExists() {
    this.setLoggedInUser();
    this.modalTitle = "Account Already Exists"
    this.modalMessage = this.loggedInUser.userId + " already has an account and you nailed the password. Click Continue." ;
    this.setState({ showAlertModal: true });
  }

  userCreated() {
    this.setLoggedInUser();
    this.modalTitle = "Account Created"
    this.modalMessage = "Account successfully created for " + this.loggedInUser.userId + ". Click Continue." ;
    this.setState({ showAlertModal: true });
  }

  createAccount(e) {
    // 1. Check to see if this guy exists
    //    - Try to log in
    console.log("CreateAccount.login...");
    this.modalTitle = "";
    this.modalMessage = "";

    if (this.state.username && this.state.username.length > 0) {
      e.preventDefault();
      var birthday = this.state.birthday && this.state.birthday !== "" ? this.state.birthday : "1970-01-01";
      console.log(birthday);
      Auth.createUser(this.state.username.toLowerCase(), sha256(this.state.password), birthday, this.state.firstName, this.state.lastName, this.state.gender);
    }
  }

  logout(e) {
    e.preventDefault();
    Auth.logout(this.state.username, this.state.password)
  }

  loginFailed() {

    if (LoginStore.errorOccurred === true) {
      this.modalMessage = LoginStore.errorMessage;

      switch (LoginStore.errorCode) {
        case "network_error":
          console.log("Network error");
          break;
        case "bad_creds":
          console.log("Bad creds, but user exists");
          break;
        case "unknown_user":
          console.log("Unknown user, we can create");
          break;
        default:
          console.log("default");
      }
    }

    this.modalTitle = "Login Failed";

    this.setState({ showAlertModal: true });
  }


  handleUsernameChange(event) {
    this.setState({username: event.target.value})
  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value})
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  handleBirthdayChange(event) {
    this.setState({
      birthday: event.target.value
    });
  }

  generateModal() {
    return (
      <Modal show={this.state.showAlertModal} onHide={this.closeAlertModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.modalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="swytchbackActiveButton" onClick={this.closeAlertModal}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {

    this.primaryAction = this.props.primaryAction;

    let formStyle = {
      marginLeft: 0,
      marginRight: 0,
      width: '75%'
    }

    let submitButtonStyle = {
      width: '100%',
      backgroundColor: '#1994fc'
    }

    let datepickersDivStyle = {
      height: 100,
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      color: '#53585f',
      fontSize: 16
    };

    let datePickerDivStyle = {
      paddingLeft: 5,
      paddingRight: 5
    }

    return (
      <div style={formStyle}>
        <form>
          <div className="form-group">
            <input type="text" className="form-control" id="first_name" value={this.state.firstName} onChange={this.handleFirstNameChange} placeholder="First Name" autoCapitalize="yes"/>
          </div>
          <div className="form-group">
            <input type="text" className="form-control" id="last_name" value={this.state.lastName} onChange={this.handleLastNameChange} placeholder="Last Name" autoCapitalize="yes"/>
          </div>
          <div className="form-group">
            <input type="email" className="form-control" id="username" value={this.state.username} onChange={this.handleUsernameChange} placeholder="Email" autoCapitalize="none"/>
          </div>
          <div className="form-group">
            <input type="password" className="form-control" id="password" ref="password" value={this.state.password}  onChange={this.handleUPasswordChange} placeholder="Password" />
          </div>
          <div style={datepickersDivStyle}>
            <div style={datePickerDivStyle}>
              <div className={`${classes.labelStyle}`}>Birthday</div>
              <div style={datePickerDivStyle}>
                <TextField
                  id="bithdayDatePicker"
                  type="date"
                  value={this.state.birthday}
                  onChange={this.handleBirthdayChange}
                  className={`${classes.datePickerStyle}`}
                  InputProps={{
                    classes: {
                      input: `${classes.swytchbackInputStyles}`,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: `${classes.swytchbackInputLabelStyles}`
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-default" style={submitButtonStyle} onClick={this.createAccount.bind(this)}>Create Account</button>
        </form>
        {this.generateModal()}
      </div>
    );
  }
}

export default EditUserInfo;
