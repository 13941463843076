import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function CivicScience({ csId, targetId, testMode = false }) {

  useEffect(() => {
    const domain = testMode ? "get.civicsciencedev.com" : "get.civicscience.com";
    const scriptId = testMode ? "csw-test-js" : "csw-js";
    const existing = document.getElementById(scriptId);
    if (!existing) {
      const script = document.createElement('script');
      script.src = `https://${domain}/jspoll/5/csw-polyfills.js`;
      script.async = true;
      script.id = scriptId;
      document.body.appendChild(script);
    }
  }, [testMode]);

  return (
      <div
        style={{padding: 0,
          margin: 0,
          width: '100%',
          minHeight: '70vh',
          overflow: 'hidden'}}
        id={csId}
        data-civicscience-widget={targetId}>
      </div>
  );
}

CivicScience.propTypes = {
  csId: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
  testMode: PropTypes.bool
};

export default CivicScience;

// Example Use //
// Obtain csId and targetId from InsightStore Target
// Integration page
// <CivicScience csId="civcsci-id-12345" targetId="7"/>
