import React from "react";

export default class BinaryStats extends React.Component {

  getPctLiked(likesCount, totalResponses) {
    if (likesCount === 0.0) {
      return (0.0).toFixed(1);
    }

    return (Math.round((likesCount / (totalResponses)) * 1000) / 10).toFixed(1);
  }

  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;
    
    var pctLikedFloat = wysh.aggregatedResults.getPctLikedFloat(snapshot);
    var pctLiked = wysh.aggregatedResults.getPctLiked(snapshot);

    let circleStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: wysh.aggregatedResults.getPctLikedFloat() >= 50.0 ? 'blue' : 'red',
      border: '3px solid',
      borderColor: wysh.aggregatedResults.getPctLikedFloat() >= 50.0 ? 'blue' : 'red',
      color: '#ffffff',
      fontSize: 14,
      borderRadius: '50%',
      width: '7vh',
      height: '7vh',
      padding: 0,
      marginRight: 20
    }
    
    return (
      <div></div>
    );
    // return (
    //   <div style={circleStyle}>
    //     {wysh.aggregatedResults.getPctLiked()}%
    //   </div>
    // );
  }
}
