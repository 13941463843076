import SkipRule from "./SkipRule";


export default class BranchLogicStatement
{
  constructor() {
    this.skipRules = []; //Array of arrays
    this.nextWyshId = null;
  }

  toJson() {

    var json = {};
    if (this.nextWyshId) {
      json["next_wysh_id"] = this.nextWyshId
    }

    var skipRules = [];
    for (var i = 0; i < this.skipRules.length; i++) {
      var andRow = [];
      for (var j = 0; j < this.skipRules[i].length; j++) {
        andRow.push(this.skipRules[i][j].toSimpleJson());
      }
      skipRules.push(andRow);
    }

    json["skip_rules"] = skipRules;

    return json;
  }

  // argument wyshId is passed because we might have skipRules for previously scrutinzed stimuli/Wyshes
  containsNegation(wyshId) {
    for (const andRow of this.skipRules) {
      for (const sr of andRow) {
        if (sr.negation === true && sr.observedWyshId === wyshId) {
          return true;
        }
      }
    }
    return false
  }

  interpret(decisionsToBeSubmittedArray) {
    // here is where the array of arrays gets a little more detail.
    // We march through the rows looking for a row to be completely satisfied. These are all ANDs
    // If the next row is completely satisfied, return true. These are the ORs

    for (var i = 0; i < this.skipRules.length; i++) {

      var andRow = true;
      for (var j = 0; j < this.skipRules[i].length && andRow === true; j++) {
        if (this.skipRules[i][j].interpret(decisionsToBeSubmittedArray) === false) {
          andRow = false;
        }
      }

      if (andRow === true) {
        return true;
      }
    }

    return false;
  }

  equals(bls) {

    // two BranchLogicStatements are equal if their SkipRules are identical
    if (bls.skipRules.length !== this.skipRules.length) {
      return false;
    }

    for (var i = 0; i < bls.skipRules.length; i++) {
      let blsAndRow = bls.skipRules[i];
      let thisAndRow = this.skipRules[i];
      if (blsAndRow.length !== thisAndRow.length) {
        return false;
      }

      for (var j = 0; j < blsAndRow.length; j++) {
        if (blsAndRow[j].observedWyshId === thisAndRow[j].observedWyshId &&
            blsAndRow[j].resultNormalized === thisAndRow[j].resultNormalized)
        {
          return true;
        }
      }
    }

    return false;
  }

  initFromJson(blsJson) {
    this.nextWyshId = blsJson.next_wysh_id;

    // harvest skip rules. remember, this is an array of arrays.
    if (blsJson && blsJson.skip_rules) {
      for (var i = 0; i < blsJson.skip_rules.length; i++) {
        var andRow = [];
        for (var j = 0; j < blsJson.skip_rules[i].length; j++) {
          let skipRule = SkipRule.createFromJson(blsJson.skip_rules[i][j]);
          andRow.push(skipRule);
        }
        this.skipRules.push(andRow);
      }
    }
  }

  static compareByNegation(a, b) {

    var aTotalNegation = 0;
    var bTotalNegation = 0;

    // determine if negation is present in the skipRules
    for (const srArray of a.skipRules) {
      for (const sr of srArray) {
        if (sr.negation) {
          aTotalNegation += 1;
        }
      }
    }

    for (const srArray of b.skipRules) {
      for (const sr of srArray) {
        if (sr.negation) {
          bTotalNegation += 1;
        }
      }
    }

    return bTotalNegation - aTotalNegation;
  }

  static createFromJson(blsJson) {
    var bls = new BranchLogicStatement();
    bls.initFromJson(blsJson);
    return bls;
  }
}
