import React from "react";
import { Select, FormControl, MenuItem, Switch } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, DeleteForever } from "@mui/icons-material";
import { Modal, Button, Collapse } from 'react-bootstrap';
import '../css/productTableRow.css';
import classes from "../css/modules/BlockTableRow.module.css";
import Card from "../hooks/admin/Card";
import ResultsModal from "./admin/ResultsModal";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import MeService from "../services/MeService";
import WyshMeProduct from "../classes/WyshMeProduct";
import AdminWyshStats from "../components/stats/AdminWyshStats";
import AdminWyshResults from "../hooks/stats/AdminWyshResults";
import WyshTable from "../components/WyshTable";
import SwytchbackTooltip from "../components/admin/SwytchbackTooltip";
import BlockTypes from "../classes/BlockTypes";
import EditStimulusModal from "../hooks/admin/stimuli/EditStimulusModal";

class ProductTableRow extends React.Component {

  constructor() {
    super();
    this.user = loginStore.getLoggedInUser();
    this.wyshOptions = [];

    this.handleParentWyshChange = this.handleParentWyshChange.bind(this);
    this.moveWyshDown = this.moveWyshDown.bind(this);
    this.moveWyshUp = this.moveWyshUp.bind(this);
    this.openRemoveWyshModal = this.openRemoveWyshModal.bind(this);
    this.closeRemoveWyshModal = this.closeRemoveWyshModal.bind(this);
    this.openProductModal = this.openProductModal.bind(this);
    this.closeProductModal = this.closeProductModal.bind(this);
    this.openFlypBookModal = this.openFlypBookModal.bind(this);
    this.closeFlypBookModal = this.closeFlypBookModal.bind(this);
    this.updateWysh = this.updateWysh.bind(this);
    this.wyshUpdated = this.wyshUpdated.bind(this);
    this.removeWysh = this.removeWysh.bind(this);
    this.wyshRemoved = this.wyshRemoved.bind(this);

    this.state = {
      parentWyshId: "no_parent_wysh",
      showProductModal: false,
      showFlypBookModal: false,
      showRemoveWyshModal: false,
      removalFeedbackMessage: "Would you like to remove this card from the Swydget?",
      removalEnabled: false,
      removalCancelButtonLabel: "Cancel",
      showChildTable: false,
      activeTabKey: 4,
      activeTabKeyFlypBook: 1
    }

    meStore.setMaxListeners(502);
  }

  componentDidMount() {
    meStore.on("change-wyshUpdated", this.wyshUpdated);
    meStore.on("change-wyshRemoved", this.wyshRemoved);

    var wysh = this.props.wysh;
    if (wysh && wysh.parentWysh) {
      this.setState({ parentWyshId: wysh.parentWysh.wyshId });
    }
    else {
      this.setState({ parentWyshId: "no_parent_wysh" });
    }
  }

  componentWillUnmount() {
    meStore.removeListener("change-wyshUpdated", this.wyshUpdated);
    meStore.removeListener("change-wyshRemoved", this.wyshRemoved);
  }

  openProductModal(wysh, activeTabKey) {

    this.setState({ showProductModal: true, activeTabKey: activeTabKey });
  }

  closeProductModal() {
    this.setState({ showProductModal: false });
  }

  openFlypBookModal(wysh, activeTabKey) {
    this.setState({ showFlypBookModal: true, activeTabKeyFlypBook: activeTabKey });
    MeService.getFreeResponseAnswers(this.user, wysh.event, wysh);
  }

  closeFlypBookModal() {
    this.setState({ showFlypBookModal: false });
  }

  openRemoveWyshModal(wysh) {
    if (wysh.orderedWyshList.wyshes.length > 0) {
      this.setState({
        removalFeedbackMessage: "This block has items in it. Please remove the items first before removing the block.",
        removalEnabled: false,
        removalCancelButtonLabel: "OK",
        showRemoveWyshModal: true
      });
    }
    else {
      this.setState({
        removalFeedbackMessage: "Would you like to remove this card from the Swydget?",
        removalEnabled: true,
        removalCancelButtonLabel: "Cancel",
        showRemoveWyshModal: true
      });
    }
  }

  closeRemoveWyshModal() {
    this.setState({ showRemoveWyshModal: false });
  }

  toggleChildTable() {
    this.setState({ showChildTable: !this.state.showChildTable });
  }

  generateChoiceCheckboxes(wysh, onCheckboxChangeMethod) {

    // Here, I have a wysh. That wysh has a filter. That filter tells me
    // if this wysh should have it's box checked and which box should be checked

    // for (var i = 0; i < wysh.filter)

    var checkboxes = [];

    let divStyle = {
      padding: 0,
      margin: 0,
      height: 30,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row'
    }

    if (onCheckboxChangeMethod) {

      if (wysh.questionType === "free-response-only") {
        // Not necessary, but I want to emphasize that free-response-only is NOT filterable!!!
      }

      if (wysh.questionType === "binary" || wysh.questionType === "next") {

        var isYesInFilter = wysh.event.filter.isChoiceSelected(wysh, 1);
        var isNoInFilter = wysh.event.filter.isChoiceSelected(wysh, 0);


        checkboxes.push(
          <div className="switchLeftDivStyle" key="yes">
            <Switch
              checked={isYesInFilter}
              onChange={(e) => onCheckboxChangeMethod(wysh, 1, e)}
              color="primary"
            />
            <div className="labelStyle">
              Yes
            </div>
          </div>
        );
        checkboxes.push(
          <div className="switchLeftDivStyle" key="no">
            <Switch
              checked={isNoInFilter}
              onChange={(e) => onCheckboxChangeMethod(wysh, 0, e)}
              color="primary"
            />
            <div className="labelStyle">
              No
            </div>
          </div>
        );
      }
      else if (wysh.questionType === "scalar" || wysh.questionType === "single-select" || wysh.questionType === "multiple-choice") {
        for (var i = 0; i < wysh.wyshOptions.length; i++) {

          let score = wysh.wyshOptions[i].resultNormalized;

          checkboxes.push(
            <div className="switchLeftDivStyle" key={wysh.wyshOptions[i].resultNormalized}>
              <Switch
                checked={wysh.event.filter.isChoiceSelected(wysh, wysh.wyshOptions[i].resultNormalized)}
                onChange={(e) => onCheckboxChangeMethod(wysh, score, e)}
                color="primary"
              />
              <div className="labelStyle">
                {wysh.wyshOptions[i].resultLiteral}
              </div>
            </div>
          );
        }
      }
    }

    return <div>{checkboxes}</div>;
  }

  determineFontSize(product, cardHeight) {
    return "1.4vh";
  }

  generateParentSelect(wysh, updateWyshesAction) {

    let allBlocks = wysh.event.getBlocks();
    if (wysh.block === true || wysh.parentWysh) {
      allBlocks = [];
    }

    // remove argument Wysh so it isn't displayed in the Select dropdown
    let pos = allBlocks.map(function (w) { return w.wyshId; }).indexOf(wysh.wyshId);
    if (pos > -1) {
      allBlocks.splice(pos, 1);
    }


    return (
      <div key="set-parent-wysh-div" className="productTableRow_container">
        <FormControl className="productTableRow_formControl">
          <Select
            className="productTableRow_swytchbackSelect"
            id="set-block-select"
            value={this.state.parentWyshId}
            onChange={this.handleParentWyshChange(wysh, updateWyshesAction)}
          >
            <MenuItem key="no_parent_wysh" className="productTableRow_swytchbackMenuItem" value="no_parent_wysh">None</MenuItem>
            {allBlocks.map((wysh, wyshIndex) => {
              return (
                <MenuItem key={wysh.wyshId} className="productTableRow_swytchbackMenuItem" value={wysh.wyshId}>{wysh.caption}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  moveWyshDown = (wysh, updateWyshesAction) => {

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    updateWyshesAction(wysh.event, owl.moveWyshDown(wysh));
  }

  moveWyshUp = (wysh, updateWyshesAction) => {

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    updateWyshesAction(wysh.event, owl.moveWyshUp(wysh));
  }

  removeWysh = (wysh, updateWyshesAction) => {

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    if (wysh.orderedWyshList.wyshes.length > 0) {
      console.log(wysh.caption + " has wyshes. Remove child wyshes first.");
    }
    else {
      updateWyshesAction(wysh.event, owl.remove(wysh), wysh);
    }
  }

  handleParentWyshChange = (wysh, updateWyshesAction) => (evt) => {

    var parentWysh = wysh.event.findWysh(evt.target.value);

    // if the parentWysh is NULL, this means we are moving the
    // wysh to the Swydget (Event's) orderedWyshList
    if (wysh.parentWysh === null && parentWysh === null) {
      // The wysh didn't have a parent and the user did not specify a parent
      // so do nothing
      return;
    }

    if (parentWysh && parentWysh.equals(wysh.parentWysh)) {
      // already in the right spot, do nothing
      return;
    }

    if (parentWysh === null && wysh.parentWysh !== null) {

      wysh.wyshRouter.clearAllBranchLogicStements();

      // The wysh was in a block and is now being moved to the Swydget level
      updateWyshesAction(wysh.event, wysh.event.orderedWyshList.add(wysh));
    }
    else if (parentWysh !== null && wysh.parentWysh === null) {

      // Wysh was at the Swydget level and is being moved down into
      // a block
      if (parentWysh.equals(wysh) === false) {
        updateWyshesAction(wysh.event, parentWysh.orderedWyshList.add(wysh));
      }
    }

    // Clear out the parentWyshId state variable because it is going to be set properly when the OrderedWyshList tree is updated.
    this.setState({ parentWyshId: "no_parent_wysh" });
  }

  updateWysh(questionType,
    scalarOptions,
    imageFile,
    question,
    attributes,
    productStyles,
    name,
    price,
    description,
    contentLink,
    removeImage,
    freeResponseQuestion,
    binaryIconSetID,
    randomizeWyshOptions,
    mcIncludeNoneOfTheAbove,
    mcIncludeOther,
    mcOptionsLimit,
    mustViewAll,
    randomize,
    issueSubGroups,
    subGroupCount,
    limitFreeResponse,
    limitFreeResponseCount,
    branchLogicStatements,
    hiddenFreeResponseOptions
  ) {


    var containingEvent = this.props.wysh.event;
    var wysh = this.props.wysh;

    wysh.questionType = questionType;
    wysh.setWyshOptions(scalarOptions);
    wysh.binaryIconSetID = binaryIconSetID;
    wysh.randomizeWyshOptions = randomizeWyshOptions;
    wysh.mcIncludeNoneOfTheAbove = mcIncludeNoneOfTheAbove;
    wysh.mcIncludeOther = mcIncludeOther;
    wysh.mcOptionsLimit = mcOptionsLimit;
    wysh.mustViewAll = mustViewAll;
    wysh.caption = question;
    wysh.product.attributes = attributes;
    wysh.product.styles = productStyles;
    wysh.product.name = name;
    wysh.product.price = price;
    wysh.product.description = description;
    wysh.product.buyPage = contentLink;

    if (freeResponseQuestion.trim() !== "") {
      // Only 1 free response question allowed at the moment
      wysh.freeResponseQuestions = [];
      wysh.freeResponseQuestions.push(freeResponseQuestion);
    }
    else {
      wysh.freeResponseQuestions = [];
    }
    wysh.randomize = randomize;
    wysh.issueSubGroups = issueSubGroups;
    wysh.subGroupCount = subGroupCount;
    wysh.limitFreeResponse = limitFreeResponse;
    wysh.limitFreeResponseCount = limitFreeResponseCount;
    wysh.hiddenFreeResponseOptions = hiddenFreeResponseOptions;

    wysh.wyshRouter.branchLogicStatements = branchLogicStatements;

    if (imageFile) {
      MeService.updateWysh(this.user, wysh.event, wysh, null, imageFile);
    }
    else {
      MeService.updateWysh(this.user, wysh.event, wysh);
    }
  }


  editStimulusAction = (stimulus, imageFiles = null) => {

    if (imageFiles && imageFiles.length > 0) {
      MeService.updateWysh(this.user, stimulus.event, stimulus, true, imageFiles[0]);
    }
    else {
      MeService.updateWysh(this.user, stimulus.event, stimulus, true);
    }
  }

  wyshUpdated() {
    this.closeProductModal();
  }

  wyshRemoved() {
    this.closeProductModal();
  }

  _determineQuestionType(wysh) {

    if (wysh) {
      if (wysh.questionType) {
        return wysh.questionType;
      }
    }
    else if (wysh && wysh.event) {
      if (wysh.event.surveyType) {
        return wysh.event.surveyType;
      }
    }
    else {
      return "select";
    }
  }

  _generateWyshTable(mode, sortOrder, wysh, snapshot, tableRef, wyshes, swydget, handleScrollFunction, myProducts, setWordsToOmitCallback, setSelectedFreeResponseDecisions) {

    var key = "root"
    var blockPrompt = swydget.surveyName;
    var blockCardText = "";

    let rightWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    };

    var wt = <WyshTable
      myRef={tableRef}
      myProducts={myProducts}
      title={blockPrompt}
      parentWysh={wysh}
      eventInFocus={swydget}
      snapshot={snapshot}
      orderedWyshList={wysh.orderedWyshList}
      onHandleFreeResponseQuestionChange={this.props.onHandleFreeResponseQuestionChange}
      setWordsToOmitCallback={setWordsToOmitCallback}
      setSelectedFreeResponseDecisions={setSelectedFreeResponseDecisions}
      surveyTitle={swydget.title}
      mode={mode}
      sortOrder={sortOrder}
      blockCardText={wysh.product.description}
      blockPrompt={wysh.caption} />;

    if (wysh) {
      // wysh.wyshTable = wt;
    }

    return (
      <div key={key}>
        {wt}
      </div>
    );
  }

  render() {

    const { productIndex } = this.props;
    const { wysh } = this.props;
    const { snapshot } = this.props;
    const { snapshotsToDisplay } = this.props;
    const { mode } = this.props;
    const { sortOrder } = this.props;
    const { size } = this.props;
    const { selectionHandler } = this.props;
    const { updateWyshesAction } = this.props;
    const { setWordsToOmitCallback } = this.props;
    const { setSelectedFreeResponseDecisions } = this.props;
    const { selected } = this.props;
    const { onCheckboxChangeMethod } = this.props;
    const { myProducts } = this.props;
    const { isUpdating } = this.props;
    const { fullSize } = this.props;

    var binaryIconSetID = "";
    var prompt = "";
    var freeResponseQuestion = "";
    this.wyshOptions = wysh.wyshOptions;

    var takerCount = 0;

    if (wysh) {
      prompt = wysh.caption;
      freeResponseQuestion = (wysh.freeResponseQuestions.length > 0) ? wysh.freeResponseQuestions[0] : "";

      if (wysh.binaryIconSetID && wysh.binaryIconSetID !== "") {
        binaryIconSetID = wysh.binaryIconSetID;
      }
      else {
        binaryIconSetID = wysh.event.binaryIconSetID;
      }

      takerCount = wysh.takerCount;

    }


    var options = "";
    if (wysh.questionType !== "binary" && wysh.wyshOptions) {
      options = wysh.wyshOptions.length;
    }

    var cardHeight = WyshMeProduct.getCardHeightAsPct(this.props.cardSize);
    cardHeight = fullSize ? cardHeight : cardHeight * 0.5;
    var cardWidth = cardHeight * (2.5 / 3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';
    var blockCardHeightStyle = (cardHeight * 0.8) + 'vh';
    var blockCardWidthStyle = (cardWidth * 0.8) + 'vh';

    var textColor = "#000000";
    var textShadow = "0.0vh 0.0vh 0.5vh rgba(255,255,255,1.0)";

    if (wysh.product.styles.textShadow && wysh.product.styles.textShadow === "light") {
      textColor = "#ffffff";
      textShadow = "0.0vh 0.0vh 0.5vh rgba(0,0,0,1.0)";
    }

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidthStyle,
      height: cardHeightStyle,
      padding: '0.75vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: wysh.product.styles.verticalAlign,
      alignItems: 'center',
      textAlign: wysh.product.styles.justification,
      color: wysh.product.styles.textColor,
      fontFamily: wysh.product.styles.getFontFamily(),
      // textShadow: textShadow,
      fontSize: wysh.product.determineFontSize(1.07),
      backgroundImage: "url('" + wysh.product.getSecureImageUrl(wysh) + "')",
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      marginBottom: 0,
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let tdStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      paddingTop: 20,
      paddingBottom: 20,
      border: 0
    }

    let leftTdStyle = {
      textAlign: 'left',
      verticalAlign: 'middle',
      border: 0
    };

    let rightTdStyle = {
      textAlign: 'right',
      verticalAlign: 'middle',
      border: 0
    };

    let trStyle = {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    };

    let trSelectedStyle = {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7',
      backgroundColor: '#e0e5ee'
    };

    let buttonStyle = {
      backgroundColor: '#ffffff',
      color: '#a7aaa9',
      border: 0,
      width: 50,
      marginBottom: 3
    }

    let divColumnStyle = {
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
    let divRowStyle = {
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row'
    }


    let productIndexDivStyle = {
      margin: 5
    }

    var rowStyle = trStyle;
    if (selected === true) {
      rowStyle = trSelectedStyle;
    }

    var freeResponseLabel = "No";
    if (wysh.freeResponseQuestions.length > 0) {
      freeResponseLabel = "Yes";
    }

    var skipRulesPresentLabel = "no";
    if (wysh.wyshRouter.branchLogicStatements.length > 0) {
      skipRulesPresentLabel = "yes";
    }

    var wyshTypeLabel = "binary";
    if (wysh.isBlock() === true) {
      wyshTypeLabel = "BLOCK";
    }
    else if (wysh.questionType === "scalar") {
      wyshTypeLabel = wysh.questionType + ": " + options;
    }

    switch (mode) {
      case "active":
        var decisionsWithFreeResponses = wysh.generateDecisionsWithFreeResponses();
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.openFlypBookModal(wysh, 1)}>
                <SwytchbackTooltip title="view" placement="top">
                  <div>
                    <Card wysh={wysh} product={wysh.product} cardHeight={cardHeight} />
                  </div>
                </SwytchbackTooltip>
              </td>
              <td style={tdStyle}>Q{wysh.questionNumber}</td>
              <td style={leftTdStyle}>
                <div className="productTableRow_contentBox">
                  {wysh.parentWysh && (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) || wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE)) ?
                    <div className="productTableRow_cellContainer">

                    </div>
                    :
                    <div>
                      <div className="productTableRow_cellContainer" style={{ marginBottom: 20 }}>
                        <div className="productTableRow_cellTopStyle">
                          Question Type:
                        </div>
                        <div className="productTableRow_cellBottomStyle">
                          {wysh.getQuestionTypeLabel()}
                        </div>
                      </div>
                      <div className="productTableRow_cellContainer" style={{ marginBottom: 20 }}>
                        <div className="productTableRow_cellTopStyle">
                          Verbatim:
                        </div>
                        <div className="productTableRow_cellBottomStyle">
                          {freeResponseLabel}
                        </div>
                      </div>
                      <SwytchbackTooltip title="add/update skip logic" placement="top-start">
                        <div className="productTableRow_cellContainer" onClick={() => this.openProductModal(wysh, 7)}>
                          <div className="productTableRow_cellTopStyle">
                            Logic:
                          </div>
                          <div className="productTableRow_cellBottomStyle">
                            {skipRulesPresentLabel}
                          </div>
                        </div>
                      </SwytchbackTooltip>
                    </div>

                  }
                </div>
              </td>
              <td style={tdStyle} colSpan={2}>
                <div style={divColumnStyle}>
                  <div className="productTableRow_rowContainer">
                    <div className="productTableRow_cellContainer" onClick={() => this.openProductModal(wysh, 2)}>
                      <div className="productTableRow_cellTopStyle">
                        {wysh.getWyshPrompt()}
                      </div>
                    </div>
                  </div>
                  <AdminWyshResults wysh={wysh} mode={"light"} width={400} snapshot={snapshot} snapshotsToDisplay={snapshotsToDisplay} />
                  <AdminWyshStats wysh={wysh} mode={"light"} snapshot={snapshot} />
                </div>
              </td>
              {this.state.showFlypBookModal === true &&
                <ResultsModal wysh={wysh}
                  snapshot={snapshot}
                  snapshotsToDisplay={snapshotsToDisplay}
                  showModal={this.state.showFlypBookModal}
                  onHideAction={this.closeFlypBookModal}
                  setWordsToOmitCallback={setWordsToOmitCallback}
                  setSelectedFreeResponseDecisions={setSelectedFreeResponseDecisions}
                  decisionsWithFreeResponses={decisionsWithFreeResponses}
                  selectedProductIndex={productIndex} />
              }
            </tr>
            <tr style={trStyle}>
              <td colSpan="5"></td>
            </tr>
          </React.Fragment>
        );
      case "draft":
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.openProductModal(wysh, 1)}>
                <SwytchbackTooltip title="view" placement="top">
                  <div>
                    <Card wysh={wysh} product={wysh.product} cardHeight={cardHeight} />
                  </div>
                </SwytchbackTooltip>
              </td>
              <td style={tdStyle}>
                <Button style={buttonStyle} disabled={isUpdating} onClick={() => this.moveWyshUp(wysh, updateWyshesAction)}><KeyboardArrowUp /></Button>
                <div style={productIndexDivStyle}>{productIndex + 1}</div>
                <Button style={buttonStyle} disabled={isUpdating} onClick={() => this.moveWyshDown(wysh, updateWyshesAction)}><KeyboardArrowDown /></Button>
              </td>
              <td style={leftTdStyle}>
                <div className="productTableRow_contentBox">
                  <SwytchbackTooltip title="update prompt" placement="top-start">
                    <div className="productTableRow_cellContainer" onClick={() => this.openProductModal(wysh, 2)}>
                      <div className="productTableRow_cellTopStyle">
                        Prompt: {wysh.getQuestionTypeLabel()}
                      </div>
                      <div className="productTableRow_cellBottomStyle">
                        {wysh.getWyshPrompt()}
                      </div>
                    </div>
                  </SwytchbackTooltip>
                </div>
              </td>
              <td style={tdStyle}>
                {wysh.parentWysh && (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) || wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE)) ?
                  <div className="productTableRow_contentBox"></div>
                  :
                  <div className="productTableRow_contentBox">
                    <SwytchbackTooltip title="update verbatim question" placement="top-start">
                      <div className="productTableRow_cellContainer" onClick={() => this.openProductModal(wysh, 3)}>
                        <div className="productTableRow_cellTopStyle">
                          Verbatim:
                        </div>
                        <div className="productTableRow_cellBottomStyle">
                          {freeResponseLabel}
                        </div>
                      </div>
                    </SwytchbackTooltip>
                    <SwytchbackTooltip title="add/update skip logic" placement="top-start">
                      <div className="productTableRow_cellContainer" onClick={() => this.openProductModal(wysh, 7)}>
                        <div className="productTableRow_cellTopStyle">
                          Logic:
                        </div>
                        <div className="productTableRow_cellBottomStyle">
                          {skipRulesPresentLabel}
                        </div>
                      </div>
                    </SwytchbackTooltip>
                  </div>
                }
              </td>
              <td style={rightTdStyle}>
                <div className="productTableRow_contentBox">
                  <div className="productTableRow_cellContainer">
                    <div className="productTableRow_cellTopStyle"></div>
                    <div className="productTableRow_cellBottomStyle">
                      <Button
                        variant="contained"
                        onClick={() => this.openRemoveWyshModal(wysh)}
                      >
                        <DeleteForever sx={{ width: '40px', height: '40px' }} className={`${classes.removeButtonStyle}`} />
                      </Button>
                    </div>
                  </div>
                </div>
              </td>
              <Modal show={this.state.showRemoveWyshModal} onHide={this.closeRemoveWyshModal} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title>Remove Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.removalFeedbackMessage}
                </Modal.Body>
                <Modal.Footer>
                  <Button className="swytchbackActiveButton" style={{ marginLeft: 10, width: 100 }} onClick={this.closeRemoveWyshModal}>{this.state.removalCancelButtonLabel}</Button>
                  {this.state.removalEnabled === true &&
                    <Button className="swytchbackAlertButton" style={{ marginLeft: 10, width: 100 }} onClick={() => this.removeWysh(wysh, updateWyshesAction)}>Remove</Button>
                  }
                </Modal.Footer>
              </Modal>

              {this.state.showProductModal === true &&
                <EditStimulusModal
                  showModal={this.state.showProductModal}
                  onHideAction={this.closeProductModal}
                  swydget={wysh.event}
                  editStimulusAction={this.editStimulusAction}
                  wysh={wysh}
                />
              }
            </tr>
            {wysh.isBlock() === true ?
              <tr style={trStyle}>
                <td colSpan="1"></td>
                <td colSpan="4">
                  <Collapse in={this.state.showChildTable} timeout={1000}>
                    <div id="example-collapse-text">
                      {this._generateWyshTable(mode, sortOrder, wysh, null, wysh.orderedWyshList.getOrderedWyshes(), wysh.event, null, myProducts)}
                    </div>
                  </Collapse>
                </td>
              </tr>
              :
              <tr style={trStyle}>
                <td colSpan="5"></td>
              </tr>
            }
          </React.Fragment>
        );
      case "filter-content":
        return (
          <tr style={rowStyle}>
            <td style={tdStyle}>
              <div>
                <Card wysh={wysh} product={wysh.product} cardHeight={cardHeight} />
              </div>
            </td>
            <td style={tdStyle}>Q{productIndex + 1}</td>
            <td style={leftTdStyle}>
              <div style={divRowStyle}>
                <div style={{ width: '30%' }}>
                  <AdminWyshStats wysh={wysh} mode={"light"} />
                </div>
                <div style={{ width: '70%' }}>
                  <AdminWyshResults wysh={wysh} mode={"light"} snapshotsToDisplay={snapshotsToDisplay} />
                </div>
              </div>
            </td>
            <td style={tdStyle}>
              <Switch
                checked={selected}
                color="primary"
                onChange={() => selectionHandler(productIndex, wysh)}
              />
            </td>
          </tr>
        );
      case "filter-respondents":
        return (
          <tr style={trStyle}>
            <td style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              paddingTop: 20,
              paddingBottom: 20,
              border: 0,
              width: 30
            }}>Q{productIndex + 1}</td>
            <td style={tdStyle}>
              <div>
                <Card wysh={wysh} product={wysh.product} cardHeight={cardHeight} />
              </div>
            </td>
            <td style={leftTdStyle}>{this.generateChoiceCheckboxes(wysh, onCheckboxChangeMethod)}</td>
            <td style={leftTdStyle}>
              <Switch
                checked={selected}
                color="primary"
                onChange={() => selectionHandler(productIndex, wysh)}
              />
            </td>
          </tr>
        );
      default:
        return (
          <tr style={trStyle}>
            <td style={tdStyle} onClick={() => selectionHandler(productIndex, wysh)}>
              <div>
                <Card wysh={wysh} product={wysh.product} cardHeight={cardHeight} />
              </div>
            </td>
            <td style={tdStyle}>
              <Button style={buttonStyle} onClick={() => this.moveWyshUp(wysh, updateWyshesAction)}><KeyboardArrowUp /></Button>
              <div style={productIndexDivStyle}>Q{productIndex + 1}</div>
              <Button style={buttonStyle} onClick={() => this.moveWyshDown(wysh, updateWyshesAction)}><KeyboardArrowDown /></Button>
            </td>
            <td style={leftTdStyle}>{wysh.getWyshPrompt()}</td>
            <td style={tdStyle}>{wysh.questionType}</td>
            <td style={tdStyle}>{options}</td>
          </tr>
        );
    }
  }
}

export default ProductTableRow;
