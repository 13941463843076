import React, { useState } from "react";
import RandomizeWidget from "../controls/RandomizeWidget";
import PromptWidget from "../controls/PromptWidget";


export default function StandardConfigureComponent(props) {

  const [localBlockWysh, setLocalBlockWysh] = useState(props.blockWysh);
  const [updatedBlockAttributesDict, setUpdatedBlockAttributesDict] = useState({});
  
  
  const handleRandomizeStimuli = (randomize) => {

    var bw = localBlockWysh;
    bw.randomize = randomize;

    if (bw.randomize === false ) {
      // clear out settings that cannot be enabled when a Swydget is randomized
      bw.issueSubGroups = false;
      bw.subGroupCount = 1;
      bw.limitFreeResponse = false;
      bw.limitFreeResponseCount = 1;
    }
    
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBlockNameChange = (evt) => {
    const bw = localBlockWysh;
    bw.product.name = evt.target.value;
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  return (
    <div id="stimulusVerbatimComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <PromptWidget
          readOnly={props.readOnly}
          title={"Block Name:"}
          value={localBlockWysh.product.name}
          callback={handleBlockNameChange}
        />
        <RandomizeWidget 
          blockWysh={localBlockWysh} 
          callback={handleRandomizeStimuli}
        />
      </div>
    </div>  
  );
}