import React from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Row, Col, Button }  from 'react-bootstrap';
import "../css/swytchbackStyles.css";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import MeService from '../services/MeService';
import EventTableRow from "../components/EventTableRow";
import SwydgetsTable from "../hooks/admin/SwydgetsTable";

class Me extends React.Component {

  constructor() {
    super();
    this.processPublishedSurveys = this.processPublishedSurveys.bind(this);
    this.completionInfoLoaded = this.completionInfoLoaded.bind(this);

    this.user = loginStore.getLoggedInUser();
    this.myEvents = meStore.getMyEvents();
    this.swydgetTableRows = [];
    this.myEventsCurrentWorkspaceTotalSize = 0;

    this.setWorkspace = this.setWorkspace.bind(this);
    this.handleFilterTermChange = this.handleFilterTermChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);

    var workspace = "active";

    if (localStorage.getItem("workspace")) {
      workspace = localStorage.getItem("workspace");
    }

    var swydgetStateFilter = "active";

    if (localStorage.getItem("swydgetStateFilter")) {
      swydgetStateFilter = localStorage.getItem("swydgetStateFilter");
    }

    this.state = {
      myEvents: this.myEvents,
      workspace: workspace, // active, drafts (ie, published, not-published)
      swydgetStateFilter: swydgetStateFilter, // active, retired, archived
      filterTerm: "",
      sliderValue: 2
    };
  }

  getMyEvents = () => {

    if (this.user) {
      if (this.user.userId.length > 0) {
        MeService.getEvents(this.user, this.user.password, this.state.workspace);
      }
    }
  }

  componentDidMount() {
    meStore.on("change", this.processPublishedSurveys);
    meStore.on("change-completionInfoLoaded", this.completionInfoLoaded);

    this.getMyEvents();
  }

  componentWillUnmount() {
    meStore.removeListener("change", this.processPublishedSurveys);
    meStore.removeListener("change-completionInfoLoaded", this.completionInfoLoaded);
  }

  setWorkspace(workspace) {

    var swydgetStateFilter = this.state.swydgetStateFilter;

    if (workspace === "retired") {
      swydgetStateFilter = "retired";
    }
    else if (workspace === "active" || workspace === "drafts") {
      swydgetStateFilter = "active";
    }

    localStorage.setItem("workspace", workspace);
    localStorage.setItem("swydgetStateFilter", swydgetStateFilter);

    MeService.getEvents(this.user, this.user.password, workspace);

    this.setState({ workspace: workspace, swydgetStateFilter: swydgetStateFilter });
  }

  getButtonStyleName(workspace) {
    if (workspace === this.state.workspace) {
      return "swytchbackActiveButton";
    }
    else {
      return "swytchbackInactiveButton";
    }
  };

  handleFilterTermChange(event) {
    if (event.target.value.trim().length > 0) {
      this.setState({filterTerm: event.target.value});
    }
    else if (event.target.value.trim().length === 0) {
      this.setState({filterTerm: ""});
    }
  }

  processPublishedSurveys() {
    this.myEvents = meStore.getMyEvents();

    this.myEventsCurrentWorkspaceTotalSize = meStore.myEventsCurrentWorkspaceTotalSize;
    this.setState({
      myEvents: this.myEvents,
    });

  }

  completionInfoLoaded() {
    this.myEvents = meStore.getMyEvents();

    this.setState({
      myEvents: this.myEvents,
    });
  }

  isSwydgetVisible(swydget) {
    // The state attribute filter determines whether or not a Swydget should be
    // listed. Scrutine this value and return a boolean indicating the Swydget should
    // be visible.

    if ((this.state.workspace === "active" || this.state.workspace === "retired") && swydget.published === false)  {
      return false;
    }
    else if (this.state.workspace === "drafts" && swydget.published === true)  {
      return false;
    }

    var isVisible = false;

    switch (this.state.filter) {
      case "all":
        isVisible = true;
        break;
      case "mine":
        if (this.user && swydget) {
          if (this.user.myMid && swydget.creatorMid && this.user.myMid === swydget.creatorMid) {
            isVisible = true;
          }
        }
        else {
          isVisible = false;
        }
        break;
      case "shared":
        if (this.user && swydget) {
          if (this.user.myMid && swydget.creatorMid && this.user.myMid !== swydget.creatorMid) {
            isVisible = true;
          }
        }
        else {
          isVisible = false;
        }
        break;
      default:
        isVisible = true;
    }

    if (isVisible === true) {
      switch (this.state.swydgetStateFilter) {
        case "all":
          isVisible = true;
          break;
        case "active":
          if (swydget.swydgetState === "active") {
            isVisible = true;
          }
          else {
            isVisible = false;
          }
          break;
        case "retired":
          if (swydget.swydgetState === "retired") {
            isVisible = true;
          }
          else {
            isVisible = false;
          }
          break;
        case "archived":
          if (swydget.swydgetState === "archived") {
            isVisible = true;
          }
          else {
            isVisible = false;
          }
          break;
        default:
          isVisible = true;
      }
    }


    return isVisible;
  }

  filterSwydget(swydget, filterString) {
    if (filterString.length === 0) {
      return false;
    }

    var filterJson = filterString.match(/\S+/g);
    var bounceIt = true;

    if (filterJson) {
      for (var i = 0; i < filterJson.length; i++) {
        if (swydget.surveyName.toLowerCase().indexOf(filterJson[i].toLowerCase()) > -1) {
          bounceIt = false;
        }
        else {
          return true;
        }
      }
    }
    return bounceIt;
  }

  generateSwydgetTableRows() {
    this.swydgetTableRows = [];

    for (var i = 0; i < this.myEvents.length; i++) {

      if (this.isSwydgetVisible(this.myEvents[i]) === true && this.filterSwydget(this.myEvents[i], this.state.filterTerm) === false) {
        var creatorName = this.myEvents[i].creator.firstName + " " + this.myEvents[i].creator.lastName;

        var updatedDateStr = "";

        if (this.myEvents[i].updatedTimeSinceEpoch) {
          var updatedDate = new Date(this.myEvents[i].updatedTimeSinceEpoch*1000);
          updatedDateStr = (updatedDate.getMonth() + 1) + "/" + updatedDate.getDate() + "/" + updatedDate.getFullYear().toString().substr(2,2);
        }

        this.swydgetTableRows.push(<EventTableRow
          key={i}
          eventInFocus={this.myEvents[i]}
          imageUrl={this.myEvents[i].getCoverPhotoUrl()}
          rowTitle={this.myEvents[i].surveyName}
          eventId={this.myEvents[i].eventId}
          creatorName={creatorName}
          updatedDate={updatedDateStr}
          />);
      }
    }
  }

  handleSliderChange(event, value) {
    this.setState({ sliderValue: value });
  }

  render() {

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid'
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };

    var buttonStyle = {
      width: '100%',
      marginLeft: 5,
      marginRight: 5
    };

    let glyphButtonStyle = {
      height: 30,
      width: 30,
      padding: 0,
      marginLeft: 15,
      marginRight: 0,
      marginBottom: 0
    }

    let fullWidthWidgetContainerStyle = {
      marginTop: 20,
      marginBottom: 0,
      paddingRight: 5,
      paddingLeft: 5
    }

    this.generateSwydgetTableRows();

    if (this.user) {

      return (

        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={6} style={toolbarStyle}>
                  <div style={titleStyle}>Swydgets</div>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <Button className={this.getButtonStyleName("drafts")} style={buttonStyle} onClick={() => this.setWorkspace("drafts")}>Drafts</Button>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <Button className={this.getButtonStyleName("active")} style={buttonStyle} onClick={() => this.setWorkspace("active")}>Active</Button>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <Button className={this.getButtonStyleName("retired")} style={buttonStyle} onClick={() => this.setWorkspace("retired")}>Retired</Button>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={fullWidthWidgetContainerStyle}>
                  <div>
                    <SwydgetsTable
                      user={this.user}
                      myEventsCurrentWorkspaceTotalSize={this.myEventsCurrentWorkspaceTotalSize}
                      rows={this.swydgetTableRows}
                      workspace={this.state.workspace}
                      itemLabel="Swydget"
                      itemsLabel="Swydgets"/>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

      );
    }
    else {
      return (
        <Navigate to="/guest/login" />
      )
    }

  }
}

export default Me;
