import React, { useState } from 'react';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import Wysh from '../../../classes/Wysh';



const SwytchbackWordCloud = ({ wysh, width, height, mode }) => {

  const spiralType = "archimedean"; // archimedean or rectangular
  const withRotation = false;

  let words = [];
  const wfa = Wysh.removeStopWords(wysh.wordFrequencyArray, wysh.wordsToOmit);
  
  for (const wf of wfa) {
    words.push({text: wf.word, value: wf.count});
  }

  const colors = mode && mode === "dark" ? ['#FFDAB9', '#ADD8E6', '#90EE90', '#CCCCCC', '#FFFFE0', '#E6E6FA'] : ['#333333', '#000080', '#228B22', '#800000', '#654321', '#708090'];

  const getRotationDegree = () => {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
  }

  const fixedValueGenerator = () => 0.5;

  const fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
    range: [10, 100],
  });

  const fontSizeSetter = (datum) => fontScale(datum.value);
  
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', userSelect: 'none' }}>
      <Wordcloud
        words={words}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font={'Helvetica'}
        padding={2}
        spiral={spiralType}
        rotate={withRotation ? getRotationDegree : 0}
        random={fixedValueGenerator}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </div>
  );
}

export default SwytchbackWordCloud;