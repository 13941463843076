import React from 'react';
import { Container, Row, Col, Modal, Button, FormControl, ControlLabel, HelpBlock, FormGroup}  from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';


class PublishSurvey extends React.Component {

  constructor() {
    super();

    this.state = {
      showComingSoonModal: false,
      showSurveyLinkModal: false,
      surveyLinkCopied: false,
      surveyLink: ''
    };

    this.openComingSoonModal = this.openComingSoonModal.bind(this);
    this.closeComingSoonModal = this.closeComingSoonModal.bind(this);
    this.getTrackableLinkClicked = this.getTrackableLinkClicked.bind(this);
    this.sendWithSwytchbackClicked = this.sendWithSwytchbackClicked.bind(this);
    this.openSurveyLink = this.openSurveyLink.bind(this);
    this.closeSurveyLink = this.closeSurveyLink.bind(this);
    this.doneClicked = this.doneClicked.bind(this);
    this.handleCopySurveyLinkChanged = this.handleCopySurveyLinkChanged.bind(this);
    this.handleCopySurveyLinkClicked = this.handleCopySurveyLinkClicked.bind(this);
    this.previewSurvey = this.previewSurvey.bind(this);

    this.eventId = '';
    this.modalTitle = "Title";
    this.modalMessage = "Message";

  }

  componentDidMount() {
    this.eventId = this.props.params.eventId;
  }

  openComingSoonModal() {
    this.modalTitle = "Coming Soon";
    this.modalMessage = "Swytchback examples are currently under construction and will be coming soon.";
    this.setState({ showComingSoonModal: true });
  }

  closeComingSoonModal() {
    this.setState({ showComingSoonModal: false });
  }

  openSurveyLink() {

    var port = '';
    if (window.location.port !== '') {
      port = ":" + window.location.port;
    }

    var swydgetUrl = "http://" + window.location.hostname + port + "/#/solo/swydget/" + this.eventId;
    this.setState({ surveyLink: swydgetUrl, showSurveyLinkModal: true });
  }

  closeSurveyLink() {
    this.setState({ showSurveyLinkModal: false });
  }

  getTrackableLinkClicked() {
    this.modalTitle = "Swytchback Trackable Link";
    this.modalMessage = "Swytchback trackable links are currently under construction and will be coming soon.";
    this.setState({ showComingSoonModal: true });
  }

  sendWithSwytchbackClicked() {
    this.modalTitle = "Swytchback Email Distribution";
    this.modalMessage = "Swytchback Email Distribution is currently under construction and will be coming soon.";
    this.setState({ showComingSoonModal: true });
  }

  handleCopySurveyLinkChanged(event) {
    this.setState({
      surveyLink: event.target.value,
      surveyLinkCopied: false
    });
  }

  handleCopySurveyLinkClicked(event) {
    this.setState({
      surveyLinkCopied: true
    });
  }



  doneClicked() {
    this.props.navigationHandler("/");
  }

  previewSurvey() {
    this.props.navigationHandler("/solo/swydget/" + this.eventId );
  }

  generateModal() {
    return (
      <Modal show={this.state.showComingSoonModal} onHide={this.closeComingSoonModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            {this.modalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeComingSoonModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  render() {

    let titleStyle = {
      height: 60,
      color: '#53585f',
      textAlign: 'left',
      fontSize: 24,
      fontWeight: 600,
      paddingTop: 20
    };

    let dividerStyle = {
      height: 74,
      borderBottom: 2,
      borderBottomColor: '#666',
      borderBottomStyle: 'solid',
      marginBottom: 0,
      paddingLeft: 0,
      textAlign: 'center'
    };

    let toolbarStyle = {
      paddingLeft: 5,
      paddingRight: 5
    }

    let colBoxStyle = {
      padding: 5,
      marginTop: 50
    }

    let boxStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#f1f2f6',
      textAlign: 'center',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      height: '40vh',
      margin: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom:30

    }

    let surveyTypeNameStyle = {
      width: '90%',
      margin: 20,
      paddingBottom: 10,
      fontSize: 20,
      fontWeight: 300,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#9fa2a7'
    }

    let nextButtonStyle = {
      color: '#0059ff',
      width: '100%',
      backgroundColor: '#ffffff',
      borderColor: '#0059ff',
      marginTop: 12,
      float: 'right'
    };

    let buttonContainerStyle = {
      width: '80%',
      padding: 5,
    }

    let enabledButtonStyle = {
      width: '100%',
      height: 70,
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: '#1994fc',
      borderRadius: 12,
      border: 0
    }

    let disabledButtonStyle = {
      width: '100%',
      height: 70,
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: '#acb5c4',
      borderRadius: 12,
      border: 0
    }


    let linkCopiedMessageDiv = {
      color: '#1997fc',
      width: '100%',
      marginTop: 20,
      textAlign: 'center'
    }

    let horizontallyAndVerticallyCenteredContainerStyle = {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }


    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col md={10} style={toolbarStyle}>
                <div style={titleStyle}>Distribute Survey</div>
              </Col>
              <Col md={2} style={toolbarStyle}>
                <button type="button" className="btn btn-primary" style={nextButtonStyle} onClick={this.previewSurvey}>Preview Survey</button>
              </Col>
            </Row>
            <Row>
              <Col md={4} style={colBoxStyle}>
                <div style={boxStyle}>
                  <div style={surveyTypeNameStyle}>Publish Now</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.openSurveyLink}>Get a single resusable link</Button></div>
                    <div style={buttonContainerStyle}><Button style={disabledButtonStyle} onClick={this.openComingSoonModal}>View Example</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={boxStyle}>
                  <div style={surveyTypeNameStyle}>Use Your Own Email System</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.getTrackableLinkClicked}>Generate a trackable link</Button></div>
                    <div style={buttonContainerStyle}><Button style={disabledButtonStyle} onClick={this.openComingSoonModal}>View Example</Button></div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={colBoxStyle}>
                <div style={boxStyle}>
                  <div style={surveyTypeNameStyle}>Send with Swytchback</div>
                  <div style={horizontallyAndVerticallyCenteredContainerStyle}>
                    <div style={buttonContainerStyle}><Button style={enabledButtonStyle} onClick={this.sendWithSwytchbackClicked}>Compose email</Button></div>
                    <div style={buttonContainerStyle}><Button style={disabledButtonStyle} onClick={this.openComingSoonModal}>View Example</Button></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={dividerStyle}>
              </Col>
            </Row>
          </Container>
        </div>
        {this.generateModal()}
        <Modal show={this.state.showSurveyLinkModal} onHide={this.closeSurveyLink} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Reuseable Survey Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              <FieldGroup
                id="formControlsPassword"
                value={this.state.surveyLink}
                type="text"
                label=""
                placeholder=""
                onChange={this.handleCopySurveyLinkChanged}
              />

              <CopyToClipboard text={this.state.surveyLink}
                onCopy={this.handleCopySurveyLinkClicked}>
                <Button bsStyle="primary" bsSize="large" block>Copy Link</Button>
              </CopyToClipboard>

              {
                this.state.surveyLinkCopied ?
                <div style={linkCopiedMessageDiv}>Survey link copied to your clipboard.</div>
                :
                null
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeSurveyLink}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <label>{label}</label>
      <FormControl {...props} />
    </FormGroup>
  );
}

const PublishSurveyWrapper = () => {

  const navigate = useNavigate();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <PublishSurvey navigationHandler={handleNavigation} />
}

export default PublishSurveyWrapper;
