import React, { useState, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Dropzone from 'react-dropzone';
import classes from "../../../css/modules/BuildModals.module.css";
import addContentImg from '../../../Plus-Trans-96.png';
import SwytchbackFrameButton from '../controls/SwytchbackFrameButton';

const ImageUploadDialog = ({ imageFiles, imageUrl, caption, onDrop, clearImage }) => {
  
  const handleDrop = (acceptedFiles) => {
    onDrop(acceptedFiles);
  };

  const handleDropRejected = () => {
    console.log("drop rejected");
  };

  const handleClearImage = () => {
    clearImage();
  };

  return (
    <div className={`${classes.centeredContainerDivStyle}`}>
      <div className={`${classes.captionDivStyle}`}>
        {caption}
      </div>
      <div>
        { imageFiles.length > 0?
          <div className={`${classes.imagePreviewStyle}`}>
            <div className={`${classes.imageContainerDivStyle}`}>
              {imageFiles.map((file) => <img key={0} alt='' src={URL.createObjectURL(imageFiles[0])} className={`${classes.imageStyle}`}/> )}
            </div>
          </div>
          :
          <Dropzone
            onDrop={handleDrop}
            onDropRejected={handleDropRejected}
            multiple={false}
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif']
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section className={`${classes.swytchbackDropzoneContainer}`}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <div className={`${classes.swytchbackDropzoneMessage}`}>
                    { imageUrl === ""?
                      <div className={`${classes.imagePreviewStyle}`}>
                        <div className={`${classes.imageContainerDivStyle}`}>
                          <AddIcon sx={{ width: '80px', height: '80px' }} className={`${classes.buttonStyle}`} />
                          <div className={`${classes.addContentInstructionsDiv}`}>Click to Select</div>
                          <div className={`${classes.addContentInstructionsDiv}`}>Image</div>
                        </div>
                      </div>
                      :
                      <div className={`${classes.imagePreviewStyle}`}>
                        <div className={`${classes.imageContainerDivStyle}`}>
                          <img src={imageUrl} className={`${classes.imageStyle}`} alt='Swytchback card background'/>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        }
      </div>
      <SwytchbackFrameButton style={{width: 160, margin: 30}} onClick={handleClearImage}>
          Clear  
        </SwytchbackFrameButton>
    </div>

  );
};

export default ImageUploadDialog;