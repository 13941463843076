import React, { useState, useEffect } from "react";
import { Switch, CircularProgress, Slider } from '@mui/material';
// import ReactWordcloud from 'react-wordcloud';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import Wysh from '../../classes/Wysh';
import FreeResponseDecisionsTable from '../../components/admin/FreeResponseDecisionsTable';
import Card from "./Card";
import '../../css/swytchbackModal.css';
import '../../css/swytchbackTable.css';

export default function ResultsModalBlock(props) {

  const { swydget } = props;
  const { blockWyshes } = props;
  const { compositeWfa } = props;
  const { orderedWyshList } = props;
  const { sortOrder } = props;
  const { myProducts } = props;
  const { onHideAction } = props;

  const [cardSize, setCardSize] = useState(5);
  const [wordsToOmit, setWordsToOmit] = useState([]);
  const [selectedFreeResponseDecisions, setSelectedFreeResponseDecisions] = useState([]);

  var cardMargin = (cardSize * 0.1) + 'vh';

  useEffect(() => {
    // code to run on component mount

    // console.log("ONLY ONCE");
  }, [])

  var compositeWords = [];
  for (var i = 0; i < compositeWfa.length; i++) {
    compositeWords.push({text: compositeWfa[i].word, value: compositeWfa[i].count});
  }

  var scrubbedWfa = Wysh.removeStopWords(compositeWfa, wordsToOmit);
  var scrubbedWords = [];
  var wordArrayColumns = [[]];

  var currColumn = 0;
  for (i = 0; i < scrubbedWfa.length; i++) {
    if (i < 60) {
      if (i > 0 && i % 15 === 0) {
        currColumn += 1;
        wordArrayColumns.push([]);
      }
      wordArrayColumns[currColumn].push(scrubbedWfa[i]);
    }
    scrubbedWords.push({text: scrubbedWfa[i].word, value: scrubbedWfa[i].count});
  }

  const handleSliderChange = (event, newValue) => {
    setCardSize(newValue);
  };

  const addWordToOmit = (word, evt) => {

    if (evt.target.checked === true) {
      wordsToOmit.splice(wordsToOmit.indexOf(word), 1);
      setWordsToOmit([
        ...wordsToOmit
      ]);
    }
    else {
      setWordsToOmit([
        ...wordsToOmit,
        word
      ]);
    }
  }

  const toggleFreeResponseAnswer = (decision, evt) => {
    if (Wysh.containsDecision(decision, selectedFreeResponseDecisions) === false) {
      //add the
      setSelectedFreeResponseDecisions([
        ...selectedFreeResponseDecisions,
        decision
      ]);

    }
    else {
      //remove it
      setSelectedFreeResponseDecisions([
        ...selectedFreeResponseDecisions
      ])
    }
  }

  let buttonStyle = {
    width: 130,
    marginLeft: 5,
    marginRight: 5
  };

  const cleanUp = (onHideAction) => {
    onHideAction();
  }


  const generateProductCards = (blockWyshes) => {
    var rows = [];

    if (blockWyshes && blockWyshes.length > 0 && blockWyshes[0].parentWysh !== null) {
      for (var i = 0; i < blockWyshes.length; i++) {
        rows.push(<div key={"grid-card-" + i} style={{margin: cardMargin}}><Card wysh={blockWyshes[i]} product={blockWyshes[i].product} cardHeight={cardSize} /></div>);
      }
    }

    return rows;
  }


  const generateImageGrid = () => {

    var myCards = generateProductCards(blockWyshes);

    return (
      <div className="cardsContainer">
        {myCards}
      </div>
    )
  }

  const generateBlockFreeResponseDecisions = () => {

    var decArray = [];

    for (var i = 0; i < blockWyshes.length; i++) {
      var decisionsWithFreeResponses = blockWyshes[i].generateDecisionsWithFreeResponses();
      decArray = decArray.concat(decisionsWithFreeResponses);
    }

    return decArray;
  }

  var decisionsWithFreeResponses = generateBlockFreeResponseDecisions();

  const generateStopWordSwitches = () => {

    return (
        <table className="adminTable">
          <thead>
            <tr className="tHeadTr">
              <th className="tHeadLeft">Word</th>
              <th className="tHeadRight">Word</th>
              <th className="tHeadRight">Display</th>
            </tr>
          </thead>
          <tbody>
        { compositeWfa.map((wordFreq) => {
            return (
              <tr key={"stopword-" + wordFreq.word}>
                <td className="tdLeft">
                  {wordFreq.word}
                </td>
                <td className="tdRight">
                  {wordFreq.count}
                </td>
                <td className="tdRight">
                  <Switch
                    key={wordFreq.word + "-switch"}
                    color="primary"
                    checked={wordsToOmit.indexOf(wordFreq.word) > -1 ? false : true}
                    onChange={(e) => addWordToOmit(wordFreq.word, e)}
                  />
                </td>
              </tr>
            )
          })
        }
          </tbody>
        </table>
    )
  }

  const generateAttributesTable = () => {

    var rows = [];

    const attributesMap = Wysh.generateAttributesMap(blockWyshes);
    const attributesMapAsc = new Map([...attributesMap.entries()].sort());
    const attributesArray = [...attributesMapAsc.entries()];

    for (var i = 0; i < attributesArray.length; i++) {
      const key = attributesArray[i][0];
      const valuesArray = attributesArray[i][1];
      const valuesSet = new Set(valuesArray);
      const reduced = [...valuesSet].sort();

      rows.push(
        <tr key={"attribute-" + attributesArray[i][0]}>
          <td className="tdLeft" style={{width: 300}}>
            {key}
          </td>
          <td className="tdLeft" style={{width: 300}}>
            {reduced.join(", ")}
          </td>
        </tr>
      )
    }

    return (
        <table className="adminTable">
          <thead>
            <tr className="tHeadTr">
              <th className="tHeadLeft" colSpan="2">Attribute</th>
            </tr>
          </thead>
          <tbody>
            { rows }
          </tbody>
        </table>
    );
  }

  const generateWordFrequencyColumns = (wordArrayColumns) => {

    var width = 100/wordArrayColumns.length
    return (
      <div className="rowContainer">
        { wordArrayColumns.map((wordArray, idx) => {
            return generateWordFrequencyList(wordArray, width + "%", idx);
          })
        }
      </div>
    )
  }

  const generateWordFrequencyList = (wordArray, width, idx) => {

    return (
      <div className="columnContainer" key={idx} style={{width: width}}>
        { wordArray.map((wordFreq, idx) => {

            let word = wordFreq.word;
            let count = wordFreq.count;
            let fontWeight = wordFreq.fontWeight;

            return (<div style={{fontSize: 16, fontWeight: fontWeight}} key={word}>{word}: {count}</div>);
          })
        }

      </div>
    )
  }


  const screenOptions = {
    colors: ["#346CF9", "#809DF2", "#342BD9", "#282BA9", "#D7E9FC", "#2E3192"],
    enableTooltip: false,
    deterministic: false,
    fontFamily: "Helvetica, Arial, sans-serif",
    fontSizes: [10, 80],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 1,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 2000
  };

  return (
    <Modal
      onHide={onHideAction}
      dialogClassName="swytchbackModal90w"
      show={props.showModal || false}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Block Results</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody">
        <div className="divRowStyle">
          <div className="divColumnStyle">
            <Tabs className="swytchbackModalTabs"
                  id="resultsModalBlock-tabs"
                  defaultActiveKey={1}
                  style={{width: '100%'}}>
              <Tab eventKey={1} title="Cards">
                <div className="rowContainer">
                    <div className="fixedTopContainer">
                      <Slider
                        id="resultsBlockModalSizeSlider"
                        min={5}
                        step={1}
                        max={50}
                        value={cardSize}
                        onChange={handleSliderChange}
                      />
                    </div>
                </div>
                <div className="rowContainer">
                  <div className="contentContainerNoMargin">
                    <div className="divColumnStyle">
                      { props.mode === "active" && generateImageGrid(orderedWyshList, swydget, sortOrder, myProducts) }
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Word Cloud">
              { props.isLoadingFreeResponses === false && scrubbedWords.length > 0 ?
                <div className="centeredContainerDivStyle">
                  {/* <ReactWordcloud
                    style={{}}
                    words={scrubbedWords}
                    size={[650, 400]}
                    options={screenOptions}
                    callbacks={props.callbacks}
                  /> */}
                </div>
                :
                <div className="centeredContainerDivStyle">
                  <div className="activityIndicatorMessage">
                    Loading Word Cloud...
                  </div>
                  <CircularProgress size={20}/>
                </div>
              }
              </Tab>
              <Tab eventKey={3} title="Words: Edit">
                <div className="rowContainer">
                  <div className="contentContainer">
                    {generateStopWordSwitches()}
                  </div>
                </div>
              </Tab>
              <Tab eventKey={4} title="Responses: Edit">
                <div className="rowContainer">
                  <div className="contentContainer">
                    <div style={{ paddingTop: 0, paddingLeft: 20, paddingRight: 20 }}>
                      <FreeResponseDecisionsTable
                        decisionsWithFreeResponses={decisionsWithFreeResponses}
                        toggleHandler={toggleFreeResponseAnswer}
                        selectedFreeResponseDecisions={selectedFreeResponseDecisions}/>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={5} title="Word Count">
                <div className="rowContainer">
                  <div className="contentContainer">
                    {generateWordFrequencyColumns(wordArrayColumns)}
                  </div>
                </div>
              </Tab>
              <Tab eventKey={6} title="Attributes">
                <div className="rowContainer">
                  <div className="contentContainer">
                    {generateAttributesTable()}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className="swytchbackActiveButton" style={buttonStyle} onClick={() => cleanUp(onHideAction)}>Close</Button>
      </Modal.Footer>
    </Modal>

  );
}
