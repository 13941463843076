import React, { useState } from 'react';
import classes from '../../css/modules/Stats.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
} from 'chart.js';
import { Bar, Line } from "react-chartjs-2";
import SwytchbackFormControlLabel from '../admin/controls/SwytchbackFormControlLabel';
import SwytchbackCheckbox from '../admin/controls/SwytchbackCheckbox';
import SwytchbackRadioButtonGroup from '../admin/controls/SwytchbackRadioButtonGroup';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);


const MaxDiffBlockStats = ({
  block,
  mode = null,
  snapshot=null,
  snapshotsToDisplay=[]
}) => {

  const [sortByHighestScore, setSortByHighestScore] = useState(false);
  const [showLineChart, setShowLineChart] = useState(false);
  const [horizontal, setHorizontal] = useState(true);
  const [focusOnPrimaryCut, setFocusOnPrimaryCut] = useState(true);
  
  const borderColor = mode ? (mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
  const textColor = mode ? (mode === "dark" ? "#ffffff" : "#000000") : "#000000";
    
  const getWyshesSortedByScore = (snapshot, block) => {
    if (snapshot) {
      if (sortByHighestScore === true) {
        for (const stimulus of block.orderedWyshList.getOrderedWyshes()) {
          stimulus.applySnapshot(snapshot);
        }

        return block.orderedWyshList.getFilteredSortedWyshes("desc", snapshot.filter);
      }  
    }

    return block.orderedWyshList.getOrderedWyshes();
  };

  const orderedStimuli = getWyshesSortedByScore(snapshot, block);

  
  const loadDatasets = (block) => {

    const datasets = [];
    
    if (block && block.event) {
      for (const s of snapshotsToDisplay) {
        if (s && s.equals(snapshot) === false) {
          const dataArray = [];
          for (const stimulus of orderedStimuli) {
            stimulus.applySnapshot(s);
            dataArray.push(stimulus.averageScore.toFixed(1));
          }

          datasets.push({
            label: s.name,
            backgroundColor: s.color,
            borderColor: focusOnPrimaryCut ? "#aeaeae" : s.color,
            borderWidth: focusOnPrimaryCut ? 1 : 2,
            data: dataArray,
            snapshot: s
          })
        }  
      }
      
      if (snapshot) {
        const dataArray = [];
        for (const stimulus of orderedStimuli) {
          stimulus.applySnapshot(snapshot);
          dataArray.push(stimulus.averageScore.toFixed(1));
        }

        datasets.push({
          label: snapshot.name,
          borderColor: snapshot.color,
          backgroundColor: snapshot.color,
          borderWidth: focusOnPrimaryCut ? 4 : 2,
          data: dataArray,
          snapshot: snapshot
        });
      }

      
    }

    return datasets;
  }

  const datasets = loadDatasets(block);

  const labelsArray = orderedStimuli.map((wysh) => {
    return "Q" + wysh.questionNumber;
  });
  
  const data = {
    labels: labelsArray,
    datasets: datasets,
  };

  const horizontalBarOptions = {
    indexAxis: 'y',
    elements: {
      bar: {},
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: textColor
        },
        grid: {
          color: borderColor
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.raw;
            // label += '%';
            return label;
          }
        }
      },
      title: {
        display: false,
        text: 'Swytchback Rocks',
      },
    },
  };

  const verticalBarOptions = {
    indexAxis: 'x',
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            return this.getLabelForValue(value);
          }
        },
        grid: {
          color: borderColor, // Grid line color
        }
      },
      x: {
        beginAtZero: true,
        grid: {
          color: borderColor, // Grid line color
        },
        ticks: {
          color: textColor,
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.snapshot.name || '';
            if (label) {
              label += ': ';
            }
            label += context.formattedValue; // Use context.parsed.x for horizontal bars
            // label += '%'; // Append your custom text here
            return label;
          }
        }
      },
      title: {
        display: true,
        text: block.product.name,
        color: textColor,
        font: {
          size: 24 // Set the title font size here
        }
      },
    },
  };

  const lineOptions = {
    indexAxis: 'x',
    elements: {
      bar: {},
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: textColor
        },
        grid: {
          color: borderColor
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: borderColor
        },
        ticks: {
          color: textColor
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.raw;
            // label += '%';
            return label;
          }
        }
      },
      title: {
        display: false,
        text: 'Swytchback Rocks',
      },
    },
  };

  

  return (
    <div className={`${classes.sectionContainer}`}>
      <div className={`${classes.chartContainer}`}>
        {showLineChart === true ?
        <Line options={lineOptions} data={data} />
        :
        <Bar options={horizontal ? horizontalBarOptions : verticalBarOptions} data={data} />
        }
      </div>
      <div>
        <div className={`${classes.centeredColumnContainer}`}>
          <div>
            <SwytchbackRadioButtonGroup
              ifSelected={showLineChart}
              ifSelectedChanged={(e) => {setShowLineChart(!showLineChart)}}
              leftLabel="Bar"
              rightLabel="Line"
              circleColor={textColor}
              checkColor={textColor}
            />
          </div>
          <div>
            <SwytchbackRadioButtonGroup
              ifSelected={horizontal}
              ifSelectedChanged={(e) => {setHorizontal(!horizontal)}}
              disabled={showLineChart}
              leftLabel="Vertical"
              rightLabel="Horizontal"
              circleColor={textColor}
              checkColor={textColor}
            />
          </div>
          <div>
            <SwytchbackRadioButtonGroup
              ifSelected={focusOnPrimaryCut}
              ifSelectedChanged={(e) => {setFocusOnPrimaryCut(!focusOnPrimaryCut)}}
              disabled={!showLineChart}
              leftLabel="All cuts"
              rightLabel="Primary cut"
              circleColor={textColor}
              checkColor={textColor}
            />
          </div>
          <SwytchbackFormControlLabel
            textcolor={textColor}
            control={<SwytchbackCheckbox
                        textcolor={textColor}
                        name={"sort"}
                        checked={sortByHighestScore}
                        onChange={() => {setSortByHighestScore(!sortByHighestScore)}}
                      />}
            label={"Sort"}
          />
        </div>  
      </div>
    </div>
  );
}

export default MaxDiffBlockStats;