import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';
import sha256 from "js-sha256";
import Dropzone from 'react-dropzone';
import "../../../css/swytchbackStyles.css";
import "../../../css/swytchbackModal.css";
import classes from '../../../css/modules/UserInfoFormModal.module.css';
import '../../../css/imageuploaddialog.css';
import "../../../productCreationModal.css";
import LoginStore from "../../../stores/LoginStore";
import Auth from '../../../services/AuthService';
import SwytchbackServiceFactory from "../../../classes/services/SwytchbackServiceFactory";
import addContentImg from '../../../Plus-Trans-96.png';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import SwytchbackCheckbox from '../controls/SwytchbackCheckbox';
import SwytchbackFormControlLabel from '../controls/SwytchbackFormControlLabel';

function UserInfoFormModal({ showModal, onHideAction, advancedMode, cardHeight }) {
  const [loggedInUser, setLoggedInUser] = useState(LoginStore.getLoggedInUser());
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [firstName, setFirstName] = useState(loggedInUser.firstName);
  const [lastName, setLastName] = useState(loggedInUser.lastName);
  const [company, setCompany] = useState(loggedInUser.company);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState(loggedInUser.privacyPolicyLink);
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState(loggedInUser.dob);
  const [gender, setGender] = useState(loggedInUser.gender);
  const [authorizedServices, setAuthorizedServices] = useState(loggedInUser.authorizedServices);
  const [imageFiles, setImageFiles] = useState([]);

  useEffect(() => {
    const handleUserUpdateStart = () => {
      // handle update start logic
    };

    const handleUserUpdateSuccessful = () => {
      // handle update successful logic
    };

    const handleUserLoggedIn = () => {
      // handle user logged in logic
    };

    LoginStore.on("loginStore_user_update_start", handleUserUpdateStart);
    LoginStore.on("loginStore_user_update_successful", handleUserUpdateSuccessful);
    LoginStore.on("loginStore_user_logged_in", handleUserLoggedIn);

    return () => {
      LoginStore.removeListener("loginStore_user_update_start", handleUserUpdateStart);
      LoginStore.removeListener("loginStore_user_update_successful", handleUserUpdateSuccessful);
      LoginStore.removeListener("loginStore_user_logged_in", handleUserLoggedIn);
    };
  }, []);

  const updateUser = () => {
    const updatedUser = { ...loggedInUser, firstName, lastName, company, dob: birthday, gender, privacyPolicyLink };
    if (imageFiles.length > 0) {
      Auth.uploadProfilePhoto(updatedUser, imageFiles[0]);
    } else {
      Auth.updateUser(updatedUser);
    }
    setImageFiles([]);
  };

  const updatePassword = () => {
    Auth.updatePassword(loggedInUser, sha256(password));
  };

  const updateSuccessful = () => {
    const updatedUser = LoginStore.getLoggedInUser();
    setLoggedInUser(updatedUser);
    setFirstName(updatedUser.firstName);
    setLastName(updatedUser.lastName);
    setCompany(updatedUser.company);
    setPassword("");
    setBirthday(updatedUser.dob);
    setGender(updatedUser.gender);
    setAuthorizedServices(updatedUser.authorizedServices);
    setShowResetPasswordModal(false);
  };

  const cleanUp = (onHideAction) => {
    console.log("removing imageFiles");
    setImageFiles([]);
    onHideAction();
  };

  const clearImage = () => {
    setImageFiles([]);
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setImageFiles(acceptedFiles);
  };
  
  const handleCheckboxChange = (evt) => {
    const authSvcs = { ...authorizedServices };
    if (evt.target.checked) {
      authSvcs[evt.target.name] = SwytchbackServiceFactory.getService(evt.target.name);
    } else {
      delete authSvcs[evt.target.name];
    }
    setAuthorizedServices(authSvcs);
  };

  const handlePrivacyPolicyChange = (evt) => {
    setPrivacyPolicyLink(evt.target.value);
  };

  const generateServicesCheckBoxes = () => {
    const array = [];
    for (let i = 0; i < SwytchbackServiceFactory.getAllServices().length; i++) {
      const shouldBeChecked = authorizedServices.hasOwnProperty(SwytchbackServiceFactory.getAllServices()[i].id);
      array.push(
        <div key={SwytchbackServiceFactory.getAllServices()[i].id}>
          <SwytchbackFormControlLabel
            control={<SwytchbackCheckbox
                        name={SwytchbackServiceFactory.getAllServices()[i].id}
                        checked={shouldBeChecked}
                        onChange={handleCheckboxChange}
                      />}
            label={SwytchbackServiceFactory.getAllServices()[i].name}
          />
        </div>
      );
    }
    return array;
  };

  return (
    <Modal 
      size="xl" 
      show={showModal} 
      onHide={() => cleanUp(onHideAction)}
      enforceFocus={false} 
      backdrop="static"
    >
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">User Info</Modal.Title>
      </Modal.Header>
    
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="UserInfoFormModal-Tabs">
            <Tab eventKey={1} title="Update Photo">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCenteredContainer fullWidthPane standardModalHeight'>
                  {imageFiles.length > 0 ? (
                    <div className="imagePreviewStyle">
                      <div className="imageContainerDivStyle">
                        {imageFiles.map((file, index) => (
                          <img key={index} alt="" src={URL.createObjectURL(file)} className="imageStyle" />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Dropzone
                      onDrop={onDrop}
                      onDropRejected={() => { console.log("drop rejected") }}
                      multiple={false}
                      accept={{
                        'image/png': ['.png'],
                        'image/jpg': ['.jpg', '.jpeg'],
                        'image/gif': ['.gif']
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="swytchbackDropzoneContainer">
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div className="swytchbackDropzoneMessage">
                              {loggedInUser.photo === "" ? (
                                <div className="imagePreviewStyle">
                                  <div className="imageContainerDivStyle">
                                    <img src={addContentImg} className="addContentImageStyle" alt="add content" />
                                    <div className="addContentInstructionsDiv">Click to Select</div>
                                    <div className="addContentInstructionsDiv">Image</div>
                                  </div>
                                </div>
                              ) : (
                                <div className="imagePreviewStyle">
                                  <div className="imageContainerDivStyle">
                                    <img src={loggedInUser.photo} className="imageStyle" alt="Swytchback card background" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  )}
                  </div>
                </div>
              </div>
            </Tab>

            {/* Tab 2: Update Details */}
            <Tab eventKey={2} title="Update Details">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCenteredContainer fullWidthPane standardModalHeight'>
                      {/* First Name */}
                      <SwytchbackTextField
                        id="first_name_TF"
                        style={{width: '70%'}}
                        label="Enter First Name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        margin="normal"
                        variant="outlined"
                      />
                      {/* Last Name */}
                      <SwytchbackTextField
                        id="last_name_TF"
                        style={{width: '70%'}}
                        label="Enter Last Name"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        margin="normal"
                        variant="outlined"
                      />
                      {/* Company */}
                      <SwytchbackTextField
                        id="company_TF"
                        style={{width: '70%'}}
                        label="Enter Company"
                        value={company}
                        onChange={(event) => setCompany(event.target.value)}
                        margin="normal"
                        variant="outlined"
                      />
                      {/* Birthday */}
                      <SwytchbackTextField
                        id="birthday_TF"
                        style={{width: '70%'}}
                        label="Enter Birthday"
                        type="date"
                        value={birthday}
                        onChange={(event) => setBirthday(event.target.value)}
                        margin="normal"
                        variant="outlined"
                      />
                      {/* Privacy Policy Link */}
                      <SwytchbackTextField
                        id="privacy_link_TF"
                        style={{width: '70%'}}
                        label="Enter Privacy Policy Link"
                        value={privacyPolicyLink}
                        onChange={handlePrivacyPolicyChange}
                        margin="normal"
                        variant="outlined"
                      />
                  </div>
                </div>
              </div>
            </Tab>

            {/* Tab 3: Services */}
            {advancedMode && advancedMode === true && (
              <Tab eventKey={3} title="Services">
                <div className="fullPaneContainer">
                  <div className="contentContainer">
                    <div className='columnCenteredContainer fullWidthPane standardModalHeight'>
                      <div className="widget">
                        <div className='title marginBottom'>
                          Swytchback Services
                        </div>
                        <div className='content columnContainer'>
                          {generateServicesCheckBoxes()}
                        </div>
                      </div>    
                    </div>
                  </div>  
                </div>  
              </Tab>
            )}
          </Tabs>
        </div>
      </Modal.Body>

      {/* Modal Footer */}
      <Modal.Footer className="swytchbackModalFooter">
        {imageFiles.length > 0 && (
          <Button onClick={clearImage}>Clear</Button>
        )}
        <Button onClick={updateUser}>Apply</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserInfoFormModal;
