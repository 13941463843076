import React from 'react';
import Dropzone from 'react-dropzone';
import { Container, Row, Col, Button, Modal }  from 'react-bootstrap';
import sha256 from "js-sha256";
import NotificationsTable from '../components/admin/NotificationsTable';
import NotificationsTableRow from '../components/admin/NotificationsTableRow';
import AuthService from '../services/AuthService';
import MeService from '../services/MeService';
import User from "../classes/User";
import LoginStore from "../stores/LoginStore";
import addContentImg from '../Plus-Trans-96.png';
import { useLocation, useNavigate } from 'react-router';
import { ChevronLeft, ChevronRight, Refresh } from '@mui/icons-material';


class Notifications extends React.Component {

  constructor() {
    super();
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.acknowledgeNotification = this.acknowledgeNotification.bind(this);
    this.logout = this.logout.bind(this);


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }

    this.state = {
      loggedInUser: this.loggedInUser,
      isUserLoggedIn: this.isUserLoggedIn,
      page: 1
    };
  }

  componentWillMount() {
    LoginStore.on("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.on("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.on("change-logout", this.setLoggedInUser);
  }

  componentWillUnmount() {
    LoginStore.removeListener("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.removeListener("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.removeListener("change-logout", this.setLoggedInUser);
  }

  componentDidMount() {
    AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/notifications", this.props.navigationHandler);
  }

  setLoggedInUser() {
    this.loggedInUser = LoginStore.getLoggedInUser();
    if (this.loggedInUser != null) {
      this.isUserLoggedIn = true;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: true,
      });
    }
    else {
      this.isUserLoggedIn = false;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: false,
      });
    }
  }


  // login(e) {
  //   console.log("login --> Is this ever called?");
  //   e.preventDefault();
  //   AuthService.login(this.state.username, sha256(this.state.password))
  // }

  logout(e) {
    e.preventDefault();
    AuthService.logout(this.state.username, this.state.password)
  }


  handleUsernameChange(event) {
    this.setState({username: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  acknowledgeNotification(notification, accept) {

    if (notification) {
      if (notification.header.type === "event-invite") {
        MeService.acknowledgeEventInviteRequest(this.loggedInUser, notification, accept);
      }
      else if (notification.header.type === "wyshlist-created") {
        AuthService.acknowledgeFriendRequest(this.loggedInUser, this.props.navigationHandler, notification, accept);
      }
      else if (notification.header.type === "spreadsheet-batch-upload-complete" ||
               notification.header.type === "survey-available") {
        MeService.acknowledgeNotification(this.loggedInUser, notification, accept);
      }
    }
  }

  generateUnackNotificationsRows() {

    var rows = [];

    if (this.state.loggedInUser.myUnacknowldgedNotifications) {
      for (var i = 0; i < this.state.loggedInUser.myUnacknowldgedNotifications.length; i++) {
        rows.push(<NotificationsTableRow key={i} notification={this.state.loggedInUser.myUnacknowldgedNotifications[i]} notificationHandler={this.acknowledgeNotification}/>);
      }
    }

    return rows;
  }

  pageForward = () => {
    var page = this.loggedInUser.totalNotificationsCount > this.state.page * 10 ? this.state.page + 1 : this.state.page;

    if (page > this.state.page) {
      var first = 0 + (page - 1) * 10;
      var last = 9 + (page - 1) * 10;

      this.setState({page: page});
      AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/notifications", this.props.navigationHandler, first, last);
    }
  }

  pageBackward = () => {
    var page = this.state.page > 1 ? this.state.page - 1 : 1;

    if (page < this.state.page) {
      var first = 0 + (page - 1) * 10;
      var last = 9 + (page - 1) * 10;

      this.setState({page: page});
      AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/notifications", first, last);
    }
  }

  refresh = () => {
    var first = 0 + (this.state.page - 1) * 10;
    var last = 9 + (this.state.page - 1) * 10;
    AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/notifications", this.props.navigationHandler, first, last);
  }


  render() {

    var unackNotificationsRows = this.generateUnackNotificationsRows();

    var cardHeight = 40;
    var cardWidth = cardHeight;
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid'
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
    };

    let colBoxStyle = {
      padding: 5,
      marginTop: 0
    }

    let imageStyle = {
      borderRadius: '50%',
      margin: 10,
      height: '30vh',
      width: '30vh',
      objectFit: 'cover'
    };

    let tableContainerStyle = {
      border: 1,
      borderColor: '#afb8c8',
      borderStyle: 'solid',
      backgroundColor: '#f7f6f7'
    };

    let centeredContainerDivStyle = {
      minHeight: 400,
      marginTop: 0,
      backgroundColor: '#f7f6f7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    };

    let imagePreviewStyle = {
      width: cardHeightStyle,
      height: 'auto',
      backgroundColor: '#ffffff',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff',
      marginTop: 40,
      marginBottom: 40,
      marginLeft: 40,
      marginRight: 40,
      padding: 0,
      overflow: 'hidden',
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let imagePreviewContainerDivStyle = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      height: cardHeightStyle,
      width: cardWidthStyle,
      overflow: 'hidden'
    };


    let dropzoneStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: cardWidthStyle,
      height: cardHeightStyle,
      backgroundColor: '#ffffff',
      padding: 0,
      marginTop: 40,
      marginBottom: 40,
      marginLeft: 40,
      marginRight: 40,
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let dropzoneActiveStyle = {
      borderStyle: 'solid',
      backgroundColor: '#eee'
    };

    let addContentImgDiv = {
      textAlign: 'center',
      color:'#0c34fb'
    };

    let addContentImageStyle = {
      width: 50,
      paddingTop: 0,
      marginBottom:20
    };

    let addContentInstructionsDiv = {
      color: '#0c34fb',
      fontSize: 16,
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: cardHeightStyle,
      minHeight: cardHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + this.state.loggedInUser.getProfilePhotoUrl() + "')",
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    };

    let imagePreviewNoMarginStyle = {
      width: 'auto',
      height: cardHeightStyle,
      backgroundColor: '#ffffff',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let buttonStyle = {
      height: 40,
      width: 40,
      padding: 0,
      marginLeft: 20,
      marginRight: 0,
      marginBottom: 0
    }

    return (
      <div>
        <Container>
          <Row style={headerRowStyle}>
            <Col md={12} style={toolbarStyle}>
              <div style={titleStyle}>Notifications</div>
              <div>
                <Button variant="secondary" style={buttonStyle} onClick={this.refresh}><Refresh /></Button>
                <Button variant="secondary" style={buttonStyle} onClick={this.pageBackward}><ChevronLeft /></Button>
                <Button variant="secondary" style={buttonStyle} onClick={this.pageForward}><ChevronRight /></Button>
              </div>
              <div style={{marginLeft: 20}}>Page: {this.state.page}</div>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={colBoxStyle}>
              <NotificationsTable rows={unackNotificationsRows} singularLabel="Message" multipleLabel="Messages"/>
            </Col>
          </Row>
        </Container>
      </div>
    );


  }
}

const NotificationsWrapper = () => {

  const navigate = useNavigate();
  const location = useLocation()

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <Notifications navigationHandler={handleNavigation} location={location} />
}

export default NotificationsWrapper;
