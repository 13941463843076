import React from "react";
import { Snackbar, Link } from '@mui/material';
import { Image }  from 'react-bootstrap';
import Cookies from 'universal-cookie';
import classes from "../../css/modules/Swydget.module.css";
import ProductStyles from "../../classes/ProductStyles";
import MeService from '../../services/MeService';
import swytchbackLogoBorder from '../../SwytchbackLogoBorder.png';
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';


class SwydgetWelcome extends React.Component {


  constructor() {
    super();

    this.openCookieWarningSnackBar = this.openCookieWarningSnackBar.bind(this);
    this.closeCookieWarningSnackBar = this.closeCookieWarningSnackBar.bind(this);
    this.acceptCookiePolicy = this.acceptCookiePolicy.bind(this);
    this.advanceToSwydget = this.advanceToSwydget.bind(this);

    this.state = {
      showCookieWarningSnackBar: true,
      cookiePolicyAccepted: false
    };
  }

  componentWillMount() {
    const cookies = new Cookies();
    let cpa = cookies.get("cpa");

    if (cpa === "true") {
      this.setState({
        cookiePolicyAccepted: true
      });
    }
  }

  openCookieWarningSnackBar() {
    this.setState({ showCookieWarningSnackBar: true });
  }

  closeCookieWarningSnackBar() {
    this.setState({ showCookieWarningSnackBar: false });
  }

  setCookie(cookieName, cookieValue) {
    const d = new Date();
    d.setTime(d.getTime() + (10*365*24*60*60*1000)); //Expires in 10 years
    let expires = "expires=" + d.toUTCString();
    const cookieString = cookieName + "=" + cookieValue + ";" + expires + ";path=/;SameSite=None;secure;partitioned"; 
    document.cookie = cookieString;
  }

  acceptCookiePolicy() {

    this.setCookie("cpa", true);
    
    this.setState({
      showCookieWarningSnackBar: false,
      cookiePolicyAccepted: true
    });
  }

  advanceToSwydget(action) {
    // COMMENTED OUT ONLY BECAUSE LEGISLATION WILL LIKELY MANDATE AN EXPLICIT ACTION BE TAKEN
    // if (this.state.cookiePolicyAccepted === false) {
    //   this.openCookieWarningSnackBar();
    // }
    // else {
      // this.acceptCookiePolicy();
      // action();
    // }


    if (this.props.sessionTimestamp > 0) {
      this.acceptCookiePolicy();
      action();
    }
    else {
      MeService.getSessionTimestamp(this.props.eventId);
    }
  }

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back&#8480;</span></span>;
  }

  getDeclarationsMessage(eventInFocus) {

    // There are 4 different states with distinct messaging:

    // Swytchback TOS: true
    // Client TOS: true

    // Swytchback TOS: true
    // Client TOS: false

    // Swytchback TOS: false
    // Client TOS: true

    // Swytchback TOS: false
    // Client TOS: false

    // Showing Swytchback TOS is a simple boolean in the Swydget
    // Showing Client TOS is determined by presence of privacyPolicyLink AND the Company name


    const showSwytchbackTOS = eventInFocus.showSwytchbackTOS;
    const showClientTOS = eventInFocus &&
                          eventInFocus.creator.privacyPolicyLink &&
                          eventInFocus.creator.privacyPolicyLink.trim() !== '' &&
                          eventInFocus.creator.getCompanyName() !== '' ? true : false;

    if (showSwytchbackTOS && showClientTOS) {
      // State 1: Swytchback Yes, Client Yes
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
          This service uses cookies to preserve the integrity of survey results and measure performance.
          By clicking the button above to continue, you are acknowledging our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> and the <Link href={eventInFocus.creator.privacyPolicyLink} className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> of {eventInFocus.creator.getCompanyName()}.
          Please exit this page if you do not if you do not agree to our use of cookies or accept our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> and the <Link href={eventInFocus.creator.privacyPolicyLink} className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> of {eventInFocus.creator.getCompanyName()}.
          </div>
        </div>
      )  
    }
    else if (showSwytchbackTOS && showClientTOS === false) {
      // State 2: Swytchback Yes, Client No
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
            This service uses cookies to preserve the integrity of survey results and measure performance. 
            By clicking the button above to continue, you are acknowledging and agreeing to 
            our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link>. 
            Please exit this page if you do not agree to our use of cookies and accept our <Link href="https://swytchback.com/terms-of-service/" className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link> and <Link href="https://swytchback.com/privacy-policy/" className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link>.
          </div>
        </div>
      )
    }
    else if (showSwytchbackTOS === false && showClientTOS) {
      // State 3: Swytchback No, Client Yes
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
            This service uses cookies to preserve the integrity of survey results and measure performance. 
            By clicking the button above to continue, you are acknowledging and agreeing to 
            the the <Link href={eventInFocus.creator.privacyPolicyLink} className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> of {eventInFocus.creator.getCompanyName()}.
            Please exit this page if you do not agree to our use of cookies and accept the <Link href={eventInFocus.creator.privacyPolicyLink} className={`${classes.linkStyle}`} underline="always" target="_blank">privacy policy</Link> of {eventInFocus.creator.getCompanyName()}.
          </div>
        </div>
      )
    }
    else {
      // State 4: Swytchback No, Client No
      return (
        <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
          <div>
          This service uses cookies to preserve the integrity of survey results and measure performance. 
          Please exit this page if you do not agree to our use of cookies.
          </div>
        </div>
      )
    }
  }


  render() {

    const { height } = this.props;
    const { eventInFocus} = this.props;
    const { action } = this.props;
    let productImageUrl = eventInFocus.getCoverPhotoUrl();

    var ht = 100;

    
    if (height) {
      ht = height;
    }

    var imageWidthAndHeight = ht * 0.26;
    var imageWidthAndHeightStyle = imageWidthAndHeight + 'vh';
    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (eventInFocus && eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }


    let containerDivStyle = {
      height: ht + 'vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: -10
    };

    let sectionContainerDivStyle = {
      height: ht*0.17 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let logoContainerDivStyle = {
      height: ht*0.26 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: imageWidthAndHeightStyle,
      minHeight: imageWidthAndHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + productImageUrl + "')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    }

    let greetingDivStyle = {
      margin: 0,
      padding: 10,
      color: '#fff',
      textAlign: 'center',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(eventInFocus.description),
      fontWeight: 'normal'
    };

    let emphasizedMessageDivStyle = {
      textAlign: 'center',
      margin: 0,
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 8,
      paddingBottom: 10,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#a9a9a9',
      borderRadius: 6,
      color: '#ffffff',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(eventInFocus.getStartedText),
      fontWeight: 500,
      background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`


    };

    let titleDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: ht*0.12 + 'vh',
      color: '#fff',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(eventInFocus.title),
      fontWeight: 'bold',
      padding: 10,
      zIndex: 10
    };

    let completionImageDivStyle = {
      height: imageWidthAndHeightStyle,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 300
    };

    let swytchbackLogoStyle = {
      margin: 10,
      height: 30,
      width: 'auto',
      zIndex: 10
    };

    let defaultCardBackgroundStyle = {
      borderRadius: '50%',
      margin: 0,
      height: ht*0.1 + 'vh',
      width: ht*0.1 + 'vh',
      objectFit: 'cover'
    };

    return (
      <div style={containerDivStyle}>
        <div style={titleDivStyle}>{eventInFocus.title}</div>
        <div key={0} style={completionImageDivStyle}>
          <div style={thumbnailImgStyle}>

          </div>
        </div>
        <div style={sectionContainerDivStyle}>
          <div style={greetingDivStyle}>{eventInFocus.description}</div>
          <div style={emphasizedMessageDivStyle} onClick={() => this.advanceToSwydget(action)}>{eventInFocus.getStartedText}</div>
        </div>
        <div style={logoContainerDivStyle}>
          <div style={completionDivContainerStyle}>
            <div>
              powered by
            </div>
          </div>
          <div style={completionDivContainerStyle}>
            <Image style={swytchbackLogoStyle} src={swytchbackLogoBorder} responsive="true"/>
          </div>
          <div style={completionDivContainerStyle}>
            <div>
              {this.generateLogo()}
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          open={this.state.showCookieWarningSnackBar}
          onClose={this.closeCookieWarningSnackBar}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: `${classes.fullWidthSnackBar}`
          }}
          message={this.getDeclarationsMessage(eventInFocus)}
        />
      </div>
    );
  }
}

export default SwydgetWelcome;
