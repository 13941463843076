import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import "../../../css/swytchbackStyles.css";
import InviteFriendFormModal from '../../../components/admin/InviteFriendFormModal';
import AuthService from '../../../services/AuthService';
import User from '../../../classes/User';

const FriendsTable = ({ rows, actionLabel, enableInvitePeople }) => {
  const [showInviteFriendsModal, setShowInviteFriendsModal] = useState(false);
  const loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));

  const inviteFriend = (email, firstName, lastName) => {
    AuthService.inviteFriend(loggedInUser, email, firstName, lastName);
  };

  const openInviteFriendsModal = () => {
    setShowInviteFriendsModal(true);
  };

  const closeInviteFriendsModal = () => {
    setShowInviteFriendsModal(false);
  };

  let containerStyle = {
    width: '100%'
  };

  let tableContainerStyle = {
    padding: 0
  };

  let addContentTitleDivStyle = {
    height: 60,
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 800,
    paddingTop: 20,
    paddingLeft: 6
  };

  let theadStyle = {
    color: '#53585f',
    fontSize: 14
  };

  let theadStyleTr = {
    height: 44
  };

  let tableStyle = {
    margin: 0,
    padding: 0
  };

  let tableContainerDivStyle = {
    // overflow: 'scroll',
    margin: 0,
    paddingLeft: 30,
    paddingRight: 30
  };

  let toolbarDivStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30
  };

  var topButtonStyle = {
    width: 160,
    paddingTop: 6,
    paddingBottom: 6,
    margin: 0
  };

  let thStyle = {
    textAlign: 'center',
    verticalAlign: 'middle',
    background: 'none',
    paddingLeft: 100
  }

  return (
    <div style={containerStyle}>
      <div style={toolbarDivStyle}>
        <div style={addContentTitleDivStyle}>Team: {rows.length}</div>
        <div>
          {enableInvitePeople === true &&
            <Button className="swytchbackActiveButton" style={topButtonStyle} onClick={openInviteFriendsModal}>+ Invite People</Button>
          }
        </div>
      </div>
      <div style={tableContainerStyle}>
        <div style={tableContainerDivStyle}>
          <Table style={{borderTop: '1px solid #ffffff'}} responsive> 
            <tbody>
              {rows}
            </tbody>
          </Table>
        </div>
      </div>
      <InviteFriendFormModal showModal={showInviteFriendsModal} onHideAction={closeInviteFriendsModal} inviteFriendHandler={inviteFriend} />
    </div>
  );
};

export default FriendsTable;
