import React, { useState, useEffect } from 'react';
import classes from "../../../css/modules/ScalarToolbar.module.css";
import { Slider } from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import SwytchbackSlider from '../../admin/controls/SwytchbackSlider';


export default function ScalarToolbar(props) {

  // STATE ITEMS:
  // 1. isScalarOptionHidden
  //    - Lightweight state parameter to help display options and instructions
  // 2. currentSliderValue
  // 3. isScalarNextDisabled

  const wysh = props.wysh;
  const submitFunction = props.submitFunction; 
  const maxLimit = wysh && wysh.wyshOptions.length > 0 ? Math.round(wysh.wyshOptions.length - 1) : 0;

  
  const [currentSliderValue, setCurrentSliderValue] = useState(maxLimit > 0 ? Math.round(maxLimit / 2) : 0);
  const [isScalarOptionHidden, setIsScalarOptionHidden] = useState(true);
  const [isScalarNextDisabled, setIsScalarNextDisabled] = useState(true);
  
  useEffect(() => {
    setCurrentSliderValue(maxLimit > 0 ? Math.round(maxLimit / 2) : 0)
  }, [wysh]);
  
  const cardHeight = 45;
  const cardWidth = cardHeight * (2.5/3.5); // standard card width
  const containerWidth = cardWidth + 12;
  const nextButtonWidthStyle = cardWidth + 'vh';
  const containerWidthStyle = containerWidth + 'vh';

  const toolbarContainerNoTopBorderDivStyle = {
    paddingTop: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: containerWidthStyle,
    textAlign: 'center'
  };

  const sliderWrapper = {
    width: nextButtonWidthStyle,
    marginTop: 0,
    marginBottom: 0
  };
  
  let optionText = "";

  if (wysh.event &&
      wysh.event.scalarDescription &&
      wysh.event.scalarDescription.length > 0) {
    optionText = wysh.event.scalarDescription;
  }

  if (isScalarOptionHidden === false) {
    optionText = wysh.getOptionValue(currentSliderValue);
  }

  const scalarOptionsTextActiveStyle = {
    width: nextButtonWidthStyle,
    backgroundColor: '#ffffff',
    color: '#000000',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  const scalarOptionsTextInactiveStyle = {
    width: nextButtonWidthStyle,
    backgroundColor: '#ffffff',
    color: '#aeaeae',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  let scalarOptionsTextStyle = scalarOptionsTextActiveStyle;

  if (isScalarOptionHidden === true) {
    scalarOptionsTextStyle = scalarOptionsTextInactiveStyle;
  }

  const handleSliderChange = (evt, value) => {
    // reset scalar slider
    setCurrentSliderValue(Math.round(value));
    setIsScalarOptionHidden(false);
  }

  const submitDecision = (decisionRaw, decisionValue) => {
    setCurrentSliderValue(maxLimit > 0 ? Math.round(maxLimit / 2) : 0)
    submitFunction(decisionRaw, decisionValue);
  }

  return (
    <div style={toolbarContainerNoTopBorderDivStyle}>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
        }}>
        <div style={{
          width: 50,
          marginBottom: 20
          }}>

        </div>


        <div style={scalarOptionsTextStyle}>
          {optionText}
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: 50,
          marginBottom: 12,
          }}>
          { isScalarNextDisabled === false &&
            <div className="circle pulse">
              <ArrowCircleRight
                style={{fontSize: 34}}
                className="forcedChoiceNextGlyphStyle"
                onClick={() => {
                  submitDecision(wysh.getOptionValue(currentSliderValue), currentSliderValue.toString())
                }}
              />
            </div>
          }
        </div>

      </div>

      <div style={sliderWrapper}>
        <SwytchbackSlider
          id="scalarSlider"
          min={0}
          max={maxLimit}
          value={currentSliderValue}
          onClick={() => setIsScalarOptionHidden(false)}
          onChange={handleSliderChange}
          onChangeCommitted={() => setIsScalarNextDisabled(false)}
        />
      </div>
    </div>
  );
};