export default class MaxDiffTrialSet
{
  constructor() {
    this.maxDiffVersion = null;
    this.trialSetId = -1;
    this.itemIndices = [];
    this.items = [];
    this.decisions = [];
  }

  setWyshes(orderedWyshes) {

    let highestIndex = -1;

    for (const i of this.itemIndices){
      if (i > highestIndex) {
        highestIndex = i
      }
    }

    if (orderedWyshes.length >= highestIndex) {
      for (const i of this.itemIndices) {
        this.items.push(orderedWyshes[i - 1]);
      }
    }
  }

  
  initFromJson(json) {
    if (json) {
      if ("trial_set_id" in json) {
        this.trialSetId = json.trial_set_id;
      }
      if ("items" in json) {
        this.itemIndices = json.items;
      }
    }
  }

  static createFromJson(json, maxDiffVersion) {
    var trialSet = new MaxDiffTrialSet();
    trialSet.initFromJson(json);
    trialSet.maxDiffVersion = maxDiffVersion;
    return trialSet;
  }
}