import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

const SwytchbackTooltip = styled(Tooltip)({
  tooltip: {
    padding: 12,
    fontSize: 12,
    color: "#ffffff",
    backgroundColor: "#1579fb",
  }
});

export default SwytchbackTooltip;
