import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import SwytchbackServiceFactory from '../../../classes/services/SwytchbackServiceFactory';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import classes from "../../../css/modules/BuildModals.module.css";
import "../../../css/swytchbackModal.css"
import "../../../css/swytchbackStyles.css"
import { Switch } from '@mui/material';

const AdvancedModal = ({
  showAdvancedModal,
  closeAdvancedModal,
  randomize, 
  handleRandomizeClicked, 
  issueSubGroupsDisabled, 
  issueSubGroups, 
  handleIssueSubGroupClicked, 
  subGroupCount,
  handleSubGroupCountChanged,
  limitFreeResponseDisabled,
  limitFreeResponse,
  handleLimitFreeResponseClicked,
  limitFreeResponseCount,
  handleLimitFreeResponseCountChanged,
  showResults,
  handleShowResultsClicked,
  locationEnabled,
  handleLocationEnabledClicked,
  defaultPrompt,
  handleDefaultPromptChange,
  scalarDescription,
  handleScalarDescriptionChange,
  mcSingleInstruction,
  handleMcSingleInstructionChange,
  mcMultipleInstruction,
  handleMcMultipleInstructionChange,
  showRecommendations,
  handleShowRecommendationsClicked,
  swydget,
  user
}) => {

  return (

    <Modal
      onHide={closeAdvancedModal}
      size="xl"
      show={showAdvancedModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Advanced</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="advanced-tabs">
            <Tab eventKey={1} title="Logic">
              <div className="columnCenteredContainer">
                <div className={"rowContainerSpaced " + `${classes.marginBottom}`}>
                  <div className={`${classes.paddedItem}`}>
                    Randomize
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={randomize}
                      onChange={handleRandomizeClicked}
                      color="primary"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    Batch
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      disabled={issueSubGroupsDisabled}
                      checked={issueSubGroups}
                      onChange={handleIssueSubGroupClicked}
                      color="primary"
                    />
                  </div>
                </div>
                <div className={"rowContainerSpaced " + `${classes.marginBottom}`}>
                  <div className={`${classes.rightPaddedItem}`}>
                  
                  </div>
                  <div className={`${classes.rightPaddedItem}`}>
                    <SwytchbackTextField
                      style={{width: 130}}
                      id="subGroupCountTF"
                      label="Count"
                      value={subGroupCount}
                      onChange={handleSubGroupCountChanged}
                      margin="normal"
                      variant="outlined"
                      type="number"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    Limit Free Response
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      disabled={limitFreeResponseDisabled}
                      checked={limitFreeResponse}
                      onChange={handleLimitFreeResponseClicked}
                      color="primary"
                    />
                  </div>
                </div>
                <div className={"rowContainerSpaced " + `${classes.marginBottom}`}>
                  <div className={`${classes.rightPaddedItem}`}>
                  
                  </div>
                  <div className={`${classes.rightPaddedItem}`}>
                    <SwytchbackTextField
                      style={{width: 130}}
                      id="limitFreeResponseCountTF"
                      label="Count"
                      value={limitFreeResponseCount}
                      onChange={handleLimitFreeResponseCountChanged}
                      margin="normal"
                      variant="outlined"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Results">
              <div className="columnCenteredContainer">
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    Display Swydget Results
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={showResults}
                      onChange={handleShowResultsClicked}
                      color="primary"
                    />
                  </div>
                </div>
              </div>              
            </Tab>
            <Tab eventKey={3} title="Location">
              <div className="columnCenteredContainer">
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    Gather Taker Location
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={locationEnabled}
                      onChange={handleLocationEnabledClicked}
                      color="primary"
                    />
                  </div>
                </div>
              </div>              
            </Tab>
            <Tab eventKey={4} title="Prompts">
              <div className="columnCenteredContainer" style={{padding: 50}}>
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="defaultPromptTF"
                  value={defaultPrompt}
                  onChange={handleDefaultPromptChange}
                  margin="normal"
                  variant="outlined"
                  label="Enter Default Prompt"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="scalarDescriptionTF"
                  value={scalarDescription}
                  onChange={handleScalarDescriptionChange}
                  margin="normal"
                  variant="outlined"
                  label="Scalar Instruction"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="mcSingleInstructionTF"
                  value={mcSingleInstruction}
                  onChange={handleMcSingleInstructionChange}
                  margin="normal"
                  variant="outlined"
                  label="Single Select Instruction"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="mcMultipleInstructionTF"
                  value={mcMultipleInstruction}
                  onChange={handleMcMultipleInstructionChange}
                  margin="normal"
                  variant="outlined"
                  label="Multiple Choice Instruction"
                />
              </div>
            </Tab>
            { user.isAuthorized(SwytchbackServiceFactory.getService("service_internal")) &&
                <Tab eventKey={5} title="Recommendations">
                  <div className="columnCenteredContainer">
                    <div className='rowContainerSpaced'>
                      <div className={`${classes.paddedItem}`}>
                      Display Recommendations
                      </div>
                      <div className={`${classes.paddedItem}`}>
                        <Switch
                          checked={showRecommendations}
                          onChange={handleShowRecommendationsClicked}
                          color="primary"
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                }
          </Tabs>  
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={closeAdvancedModal}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
    
  );

}

export default AdvancedModal;
  



