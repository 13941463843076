import { useState } from "react";
import axios from "axios";

const useHttpGet = (user) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  const config = {
    headers: {'Authorization': base64String}
  };

  const get = async (url, callback) => {

    try {
      setLoading(true);
      const response = await axios.get(url, config);
      setData(response.data);
      setError(null);

      // If the caller submitted a callback call it
      if (callback) {
        callback(response.data);
      }
    } catch (error) {
      setError(error);
      if (callback) {
        callback();
      }
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, get };
};

export default useHttpGet;
