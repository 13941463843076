import React from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'react-bootstrap';
import addContentImg from '../../Plus-Trans-96.png';
import '../../css/imageuploaddialog.css';


class ImageUploadDialog extends React.Component {

  render() {

    var { imageFiles } = this.props;
    var { imageUrl } = this.props;
    var { caption } = this.props;

    // Callback Handlers
    var { onDrop } = this.props;
    var { clearImage } = this.props;

    let imageStyle = {
      height: '40vh'
    };

    let dropzoneStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '40vh',
      height: '40vh',
      maxHeight: 400,
      // maxWidth: 400,
      backgroundColor: '#ffffff',
      padding: 0,
      marginTop: 0,
      marginBottom: 40,
      marginLeft: 0,
      marginRight: 0,
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let dropzoneActiveStyle = {
      borderStyle: 'solid',
      backgroundColor: '#eee'
    };

    return(
      <div className="tableContainerStyle">
        <div className="centeredContainerDivStyle">
          <div className="captionDivStyle">
            {caption}
          </div>

        { imageFiles.length > 0 ?
          <div className="imagePreviewStyle">
            <div className="imageContainerDivStyle">
              {imageFiles.map((file) => <img key={0} alt='' src={URL.createObjectURL(imageFiles[0])} className="imageStyle"/> )}
            </div>
          </div>
          :
          <Dropzone
            onDrop={onDrop} 
            onDropRejected={() => {console.log("drop rejected")}}
            multiple={false} 
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif']
            }}
            style={dropzoneStyle} 
            activeStyle={dropzoneActiveStyle} >
            {({getRootProps, getInputProps}) => (
              <section className="swytchbackDropzoneContainer">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <div className="swytchbackDropzoneMessage">
                  { imageUrl === "" ?
                    <div className="imagePreviewStyle">
                      <div className="imageContainerDivStyle">
                        <img src={addContentImg} className="addContentImageStyle" alt='add content'/>
                        <div className="addContentInstructionsDiv">Click to Select</div>
                        <div className="addContentInstructionsDiv">Image</div>
                      </div>  
                    </div>
                    :
                    <div className="imagePreviewStyle">
                      <div className="imageContainerDivStyle">
                        <img src={imageUrl} className="imageStyle" alt='Swytchback card background'/>
                      </div>
                    </div>
                  } 
                  </div>  
                </div>
              </section>
            )}


          {/* { imageUrl === "" ?
            <div style={addContentImgDiv}>
              <img src={addContentImg} style={addContentImageStyle} alt='add content'/>
              <div style={addContentInstructionsDiv}>Click to Select</div>
              <div style={addContentInstructionsDiv}>Image</div>
            </div>
            :
            <div className="imagePreviewNoMarginStyle">
              <div className="thumbnailImgStyle">
                <img src={imageUrl} className="imageStyle" alt='Swytchback card background'/>
              </div>
            </div>

          } */}
          </Dropzone>
        }
        

          <Button className="clearButtonStyle" onClick={clearImage}>Clear</Button>

        </div>
      </div>
    );
  };
}export default ImageUploadDialog;
