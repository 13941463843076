import BlockType from "./BlockType";


export default class MaxDiffBlockType extends BlockType {

  constructor(blockTypeId, name) {
    super(blockTypeId, name);
  }

  generateResponsesArray(maxDiffBlock) {

    // mdResponsesArray is the array of resposnes that will be dumped to the Decisions XLSX spreadsheet
    const mdHeaderArray = [];
    const mdResponsesArray = [];
    let trialCount = 0;
    const takerDict = this._generateTakerDict(maxDiffBlock);
    const stimuliArray = maxDiffBlock.orderedWyshList.getOrderedWyshes();
    const maxDiffStimuliProductIdArray = [];

    for (const stimulus of stimuliArray) {
      maxDiffStimuliProductIdArray.push(stimulus.product.productId);
    }


    for (const takerMid in takerDict) {
      const takerRow = [];
      takerRow.push(takerMid);
      takerRow.push(takerDict[takerMid].version_id);

      const decisionsByTrialSet = {};

      for (const dec of takerDict[takerMid].decisions) {

        trialCount = dec.trial > trialCount ? dec.trial : trialCount;

        if (decisionsByTrialSet[dec.trial]) {
          decisionsByTrialSet[dec.trial].push(dec);
        }
        else {
          decisionsByTrialSet[dec.trial] = [];
          decisionsByTrialSet[dec.trial].push(dec);
        }
      }


      for (const trial in decisionsByTrialSet) {
        takerRow.push(this._getBestIndex(decisionsByTrialSet[trial], maxDiffStimuliProductIdArray));
        takerRow.push(this._getWorstIndex(decisionsByTrialSet[trial], maxDiffStimuliProductIdArray));
      }

      mdResponsesArray.push(takerRow);
    }

    const headerRow = [
      "sys_RespNum",
      "sys_Version_MXD"
    ];

    for (let i = 0; i <= trialCount; i++) {
      headerRow.push("MXD_" + (i + 1) + "_b");
      headerRow.push("MXD_" + (i + 1) + "_w");
    }

    mdHeaderArray.push(headerRow);

    return mdHeaderArray.concat(mdResponsesArray);
  }

  generateUtilityScoreArray(block) {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    const takerDict = this._generateTakerDict(block);
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
    }

    const headerRow = [
      "sys_RespNum"
    ];

    for (const s in stimuliArray) {
      const index = parseInt(s, 10) + 1;
      headerRow.push("STIM_" + index)
    }

    utilityScoresArray.push(headerRow);

    for (const takerMid in takerDict) {
      const takerRow = [];
      takerRow.push(takerMid);

      for (const productId of stimuliProductIdArray) {
        
        for (const dec of takerDict[takerMid].decisions) {
          if (dec.productId === productId) {
            takerRow.push(dec.score);
            break;
          }
        }
      }
      
      if (takerRow.length > 1) {
        utilityScoresArray.push(takerRow);
      }
    }

    return utilityScoresArray;

  }

  calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod) {
    this.generateVersions(takerDict);

    for (const takerMid in takerDict) {

      const trialSetsDecisionsMap = takerDict[takerMid].version;

      for (const stimuli of stimuliArray) {

        switch (calculationMethod) {
          case "best-worst-counting":
            this._calculateUtilityScoresBestWorstCounting(stimuli, trialSetsDecisionsMap);
            break;
          case "opponent-result":
            this._generateIndividualUtilityScore(stimuli, trialSetsDecisionsMap);
            break;  
        
          default:
            this._generateIndividualUtilityScore(stimuli, trialSetsDecisionsMap);
            break;
        }
      }
    }
  }

  // Called in Swydget toolbars
  generateIndividualUtilityScores(stimuliArray, trialSetsDecisionsMap) {

    for (const stimuli of stimuliArray) {

      // this._generateIndividualUtilityScore(stimuli, trialSetsDecisionsMap);
      this._calculateUtilityScoresBestWorstCounting(stimuli, trialSetsDecisionsMap);

    }
  }

  _calculateUtilityScoresBestWorstCounting(stimulus, trialSetsDecisionsMap) {

    // 1. find the trials for this stimulus
    const indiciesSet = this._findTrialIndices(stimulus, trialSetsDecisionsMap);

    let totalWins = 0;
    let totalLosses = 0;

    for (const trialSetIndex of indiciesSet) {

      if (this._isVictorious(stimulus, trialSetIndex, trialSetsDecisionsMap) === true) {
        // We WON our match YAY!!!
        totalWins += 1;
      }
      else if (this._isDefeated(stimulus, trialSetIndex, trialSetsDecisionsMap) === true) {
        // We LOST our match BOOOO!!!
        totalLosses += 1;
      }
      else {
        // console.log(stimulus.product.description + " NEUTRAL");
      }
    }

    
    const delta = totalWins - totalLosses;
    const utilityScore = delta !== 0 ? 100 * delta / indiciesSet.size : 0
    
    for (const trialSetIndex of indiciesSet) {
      for (const dec of trialSetsDecisionsMap.get(trialSetIndex)) {
        if (dec.wysh.equals(stimulus)) {
          dec.score = utilityScore; 
        }
      }
    }
  }

  _generateIndividualUtilityScore(stimulus, trialSetsDecisionsMap) {

    const utilityScores = [];

    // This is what I think is happening:
    // if you WON a match: 
    // - If the guy you beat WON his other match, you get 60
    // - If the guy you beat LOST his other match, you get 40
    // ELSE if you LOST:
    // - If the guy you beat WON his other match, you get 10
    // - If the guy you beat LOST his other match, you get 0

    // 1. find the trials for this stimulus
    const indiciesSet = this._findTrialIndices(stimulus, trialSetsDecisionsMap);
    console.log(stimulus.product.description + ": " + indiciesSet.size);

    // console.log("SCORE FOR: " + stimulus.product.description);

    let totalWins = 0;
    let totalLosses = 0;

    for (const trialSetIndex of indiciesSet) {

      let utilityScore = 0;

      const opponents = this._findOpponents(stimulus, trialSetIndex, trialSetsDecisionsMap);
      const opponentIndiciesSetMap = new Map();


      for (const opponent of opponents) {
        const opponentIndiciesSet = this._findTrialIndices(opponent, trialSetsDecisionsMap);
        opponentIndiciesSetMap.set(opponent, opponentIndiciesSet);
      }

      let opponentsMultiplier = 0;
      let opponentTrialSetCount = 0;
      

      for (const [opponent, opponentIndiciesSet] of opponentIndiciesSetMap) {
        for (const opponentTrialSetIndex of opponentIndiciesSet) {
          if (opponentTrialSetIndex !== trialSetIndex) {
            opponentTrialSetCount += 1;
            if (this._isVictorious(opponent, opponentTrialSetIndex, trialSetsDecisionsMap) === true) {
              // My opponent won his OTHER match
              opponentsMultiplier += 1;
            }
            else if (this._isDefeated(opponent, opponentTrialSetIndex, trialSetsDecisionsMap) === true) {
              // My opponent lost his OTHER match
              opponentsMultiplier -= 1;
            }
            else {
              // console.log("OPPONENT: " + opponent.product.description + " NEUTRAL");
            }
          }
        }
      }

      // console.log("opponentsMultiplier: " + opponentsMultiplier + " / " + "opponentTrialSetCount: " + opponentTrialSetCount);
      opponentsMultiplier = opponentsMultiplier !== 0 ? opponentsMultiplier / opponentTrialSetCount : 0;

      // console.log("opponentsMultiplier: " + opponentsMultiplier + " [" + opponentIndiciesSetMap.size + "]");

      if (this._isVictorious(stimulus, trialSetIndex, trialSetsDecisionsMap) === true) {
        // We WON our match YAY!!!
        // console.log(stimulus.product.description + " WON");
        // How did our opponents do?
        totalWins += 1;
        utilityScore = utilityScore + 100;
        utilityScore = utilityScore + (10 * opponentsMultiplier);
      }
      else if (this._isDefeated(stimulus, trialSetIndex, trialSetsDecisionsMap) === true) {
        // We LOST our match BOOOO!!!
        // console.log(stimulus.product.description + " LOST");
        totalLosses += 1;
        utilityScore = utilityScore - 100;
        utilityScore = utilityScore + (10 * opponentsMultiplier);
      }
      else {
        // console.log(stimulus.product.description + " NEUTRAL");
        utilityScore = utilityScore + (10 * opponentsMultiplier);
      }

      utilityScores.push(utilityScore)
    }

    let utilityScoreTotal = 0;
    // console.log(utilityScores);
    for (const us of utilityScores) {
      utilityScoreTotal = utilityScoreTotal + us;
    }

    const delta = totalWins - totalLosses;
    const utilityScore = delta !== 0 ? delta / indiciesSet.size : 0;
    // console.log("Wins:           " + totalWins);
    // console.log("Losses:         " + totalLosses);
    // console.log("Utiility Score: " + utilityScore + " [" + indiciesSet.size + "]");

    const adjustedUtilityScore = utilityScoreTotal / utilityScores.length; 

    for (const trialSetIndex of indiciesSet) {
      for (const dec of trialSetsDecisionsMap.get(trialSetIndex)) {
        if (dec.wysh.equals(stimulus)) {
          dec.score = adjustedUtilityScore;
          // console.log("setting " + adjustedUtilityScore);
          // dec.score = utilityScore * 100 + 100; 
        }
      }
    }
  }

  _findOpponents(stimulus, trialSetIndex, trialSetsDecisionsMap) {

    const opponents = [];
    const trialSetDecArray = trialSetsDecisionsMap.get(trialSetIndex);
    if (trialSetDecArray) {
      for (const dec of trialSetDecArray) {
        if (dec.wysh.equals(stimulus) === false) {
          opponents.push(dec.wysh);
        }
      }
    }

    return opponents;
  }
  _isVictorious(stimulus, trialSetIndex, trialSetsDecisionsMap) {

    // find the decisions involving the trial
    const trialSetDecArray = trialSetsDecisionsMap.get(trialSetIndex);

    if (trialSetDecArray) {
      for (const dec of trialSetDecArray) {
        if (dec.wysh.equals(stimulus) === true && dec.resultNormalized === 1) {
          // console.log(stimulus.product.description + " WON");
          return true;
        }
      }
    }

    return false;

  }

  _isDefeated(stimulus, trialSetIndex, trialSetsDecisionsMap) {

    // find the decisions involving the trial
    const trialSetDecArray = trialSetsDecisionsMap.get(trialSetIndex);

    if (trialSetDecArray) {
      for (const dec of trialSetDecArray) {
        if (dec.wysh.equals(stimulus) === true && dec.resultNormalized === -1) {
          // console.log(stimulus.product.description + " LOST");
          return true;
        }
      }
    }

    return false;

  }

}