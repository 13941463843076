import React, { Component } from 'react';
import { TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Snackbar, Alert } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Table, Button, OverlayTrigger, Popover }  from 'react-bootstrap';
import classes from '../../css/modules/MessagingCampaignsAdmin.module.css';
import AuthService from '../../services/AuthService';
import LoginStore from "../../stores/LoginStore";

function SwytchbackAlert(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

class MessagingCampaignsAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      messagingCampaignName: "",
      user: null,
      messageIndex: 0,
      messageInFocus: "",
      showSendingStatus: false,
      showMessageCampaignUpdatingDialog: false
    }
  }

  componentDidMount() {
    LoginStore.on("auth_update_messaing_campaign_successful", this.messagingCampaignUpdated);
  }

  componentWillUnmount() {
    LoginStore.removeListener("auth_update_messaing_campaign_successful", this.messagingCampaignUpdated);
  }

  messagingCampaignUpdated = () => {
    this.setState({ showMessageCampaignUpdatingDialog: true });
  }

  handleClose(evt, reason) {
    this.setState({ showSendingStatus: false });
  }

  handleMessagingCampaignUpdateClose = (evt) => {
    this.setState({ showMessageCampaignUpdatingDialog: false});
  }

  handleMessagingCampaignNameChange = (evt) => {
    this.setState({
      messagingCampaignName: evt.target.value
    });
  }

  addMessagingCampaign = (user) => {
    AuthService.addMessagingCampaign(user, this.state.messagingCampaignName);
    this.setState({
      messagingCampaignName: ""
    });
  }

  deleteMessagingCampaign = (evt, user, messagingCampaign) => {
    AuthService.deleteMessagingCampaign(user, messagingCampaign);
  }

  saveMessagingCampaign = (evt, user, messagingCampaign) => {
    console.log("start some sort of activity indicator");
    AuthService.updateMessagingCampaign(user, messagingCampaign);
  }

  submitMessagingCampaignForm = (evt, user) => {
    evt.preventDefault();
    this.addMessagingCampaign(user);

    console.log("persist to back end");
  }

  sendMessages = (evt, user, messagingCampaign) => {
    this.setState({ showSendingStatus: true });
    AuthService.sendMessaingCampaignMessages(user, messagingCampaign);
  }

  generateMessagingCampaignRows = (user) => {

    const { handleMessageChangeMethod, handleUpdateMessagingCampaignName , handlePhoneNumbersChangeMethod} = this.props;

    var rows = [];

    let tdStyle = {

    }

    let trStyle = {
      borderBottom: '1px solid #b7b7b7'
    }

    if (user) {
      for (let k in user.messagingCampaignBucket.messaging_campaigns) {
        let messagingCampaign = user.messagingCampaignBucket.messaging_campaigns[k];
        let message = messagingCampaign.messages.length > 0 ? messagingCampaign.messages[0] : ""
        rows.push(
          <tr style={{borderBottom: '1px solid #b7b7b7'}} key={"messaging-campaign-" + k}>
            <td style={{
              width: '25%',
              padding: 30,
              paddingLeft: 0,
              textAlign: 'left'
            }}>
              <TextField
                disabled={false}
                id={"mc-tf-" + k}
                label="Messaging Campaign Name"
                className={`${classes.textFieldPopover}`}
                InputProps={{
                  classes: {
                    input: `${classes.swytchbackPopoverInputStyles}`,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: `${classes.swytchbackPopoverInputLabelStyles}`
                  },
                }}
                value={messagingCampaign.messaging_campaign_name}
                onChange={(evt) => handleUpdateMessagingCampaignName(evt, user, messagingCampaign)}
                margin="normal"
                variant="outlined"
                placeholder="Name..."
              />
            </td>
            <td style={{
              padding: 30,
              paddingLeft: 0,
              textAlign: 'left'
            }}>
              <div className={`${classes.accordionRoot}`}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="messages-header"
                  >
                    <Typography className={`${classes.accordionHeading}`}>Message</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${classes.columnContainer}`}>
                        <TextField
                          id="messages-tf"
                          label="Text"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={message}
                          onChange={(evt) => handleMessageChangeMethod(evt, messagingCampaign)}
                          margin="normal"
                          variant="outlined"
                          multiline={true}
                          minRows={5}
                          placeholder="Message body..."
                        />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="phoneNumbers-header"
                  >
                    <Typography className={`${classes.accordionHeading}`}>Phone Numbers</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`${classes.columnContainer}`}>
                      <div style={{ width: '100%', paddingTop: 6, paddingBottom: 20}}>
                        <TextField
                          id="phoneNumbers-tf"
                          label="Text"
                          className={`${classes.textField}`}
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={messagingCampaign.phone_numbers_string}
                          onChange={(evt) => handlePhoneNumbersChangeMethod(evt, messagingCampaign)}
                          margin="normal"
                          variant="outlined"
                          multiline={true}
                          minRows={5}
                          placeholder="Card Text..."
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </td>
            <td style={{
              width: '35%',
              padding: 30,
              paddingRight: 0,
              textAlign: 'right'
            }}>
              <Button className="swytchbackActiveButton" style={{width: 100, margin: 5}} onClick={(evt) => this.deleteMessagingCampaign(evt, user, messagingCampaign)}>Remove</Button>
              <Button className="swytchbackActiveButton" style={{width: 100, margin: 5}}onClick={(evt) => this.saveMessagingCampaign(evt, user, messagingCampaign)}>Save</Button>
              <Button className="swytchbackActiveButton" style={{width: 100, margin: 5}} onClick={(evt) => this.sendMessages(evt, user, messagingCampaign)}>Send</Button>

            </td>
          </tr>
        );
      }
    }

    return rows;
  }


  render() {

    const { user } = this.props;

    const addMessagingCampaignPopup = (
      <Popover id="popover-messaging-campaign" title="Name & Add Messaging Campaign">
        <form onSubmit={(evt) => this.submitMessagingCampaignForm(evt, user)} className={`${classes.container}`} noValidate autoComplete="off">
          <TextField
            disabled={false}
            id="messagingCampaignNameTextField"
            label="Messaging Campaign Name"
            className={`${classes.textFieldPopover}`}
            InputProps={{
              classes: {
                input: `${classes.swytchbackPopoverInputStyles}`,
              },
            }}
            InputLabelProps={{
              classes: {
                root: `${classes.swytchbackPopoverInputLabelStyles}`
              },
            }}
            value={this.state.messagingCampaignName}
            onChange={this.handleMessagingCampaignNameChange}
            margin="normal"
            variant="outlined"
            placeholder="Name..."
          />
        </form>
        <div style={{margin: 5}}>
          <Button className="swytchbackActiveButton" onClick={() => this.addMessagingCampaign(user)}>
            Add
          </Button>
        </div>
      </Popover>
    );



    return (
      <div style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          <Table style={{width: '100%', padding: 0, margin: 0, border: '1x solid blue'}} responsive>
            <thead style={{}}>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.generateMessagingCampaignRows(user)}
            </tbody>
          </Table>
        </div>
        <div>
          <OverlayTrigger ref="addMessagingCampaignOverlayTrigger" container={this} trigger="click" placement="top" overlay={addMessagingCampaignPopup} rootClose>
            <Button className="swytchbackBorderlessActiveButton">
              + Add Messaging Campaign
            </Button>
          </OverlayTrigger>
        </div>
        <Snackbar open={this.state.showSendingStatus} autoHideDuration={6000} onClose={(evt) => this.handleClose(evt)}>
          <SwytchbackAlert onClose={(evt) => this.handleClose(evt)} severity="success">
            <Typography className={`${classes.accordionHeading}`}>Message sent</Typography>
          </SwytchbackAlert>
        </Snackbar>
        <Snackbar open={this.state.showMessageCampaignUpdatingDialog} autoHideDuration={6000} onClose={(evt) => this.handleMessagingCampaignUpdateClose(evt)}>
          <SwytchbackAlert onClose={(evt) => this.handleMessagingCampaignUpdateClose(evt)} severity="success">
            <Typography className={`${classes.accordionHeading}`}>Messaging Campaign Updated</Typography>
          </SwytchbackAlert>
        </Snackbar>
      </div>
    );
  }
}

export default MessagingCampaignsAdmin;