import React, { useState } from "react";
import PromptWidget from "../controls/PromptWidget";

export default function MaxDiffConfigureComponent(props) {

  const [localBlockWysh, setLocalBlockWysh] = useState(props.blockWysh);
  const [updatedBlockAttributesDict, setUpdatedBlockAttributesDict] = useState({});
  
  
  const handleDefaultPromptChange = (evt) => {
    const bw = localBlockWysh;
    bw.caption = evt.target.value;
    updatedBlockAttributesDict["defaultPrompt"] = evt.target.value;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBlockNameChange = (evt) => {
    const bw = localBlockWysh;
    bw.product.name = evt.target.value;
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  
  return (
    <div id="stimulusVerbatimComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <PromptWidget
          readOnly={props.readOnly}
          title={"Block Name:"}
          value={localBlockWysh.product.name}
          callback={handleBlockNameChange}
        />
        <PromptWidget
          readOnly={props.readOnly}
          title={"Question Prompt:"}
          value={localBlockWysh.caption}
          callback={handleDefaultPromptChange}
        />
      </div>
    </div>  
  );
}