import React from 'react';
import Cookies from 'universal-cookie';
import Event from "../classes/Event";
import WyshOption from "../classes/WyshOption";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import swydgetStore from "../stores/SwydgetStore";
import MeService from '../services/MeService';
import Swydget from "../components/Swydget";
import SwydgetForcedRanking from "../components/SwydgetForcedRanking";
import SwydgetLocationEnabled from "../hooks/swydget/SwydgetLocationEnabled";
import { useLocation, useNavigate, useParams } from 'react-router';

class SwydgetContainer extends React.Component {

  constructor() {
    super();
    this.user = loginStore.getLoggedInUser();
    this.eventId = "";
    this.userId = "";
    this.logout = this.logout.bind(this);
    this.eventLoaded = this.eventLoaded.bind(this);

    this.state = {
      eventInFocus: {},
      sessionTimestamp: 0
    };
  }

  logout() {
    this.user = null;
  }

  setEventId(mid, eid) {

    // prepend the necessary 0's to the mid.
    var fullMid = "evt.";

    for (var i = 0; i < 16 - mid.length; i++) {
      fullMid += "0";
    }

    fullMid += mid;

    // prepend the necessary 0's to the eventId.
    var fullEventId = "-";

    for (i = 0; i < 8 - eid.length; i++) {
      fullEventId += "0";
    }

    fullEventId += eid;

    this.eventId = fullMid + fullEventId;
  }

  componentDidMount() {

    const cookies = new Cookies();
    // this.userKey = swydgetStore.surveyTakerKey;
    // this.userKey = cookies.get("sb");

    this.eventId = this.props.params.eventId;
    this.userId = this.props.params.userId;
    var mid = this.props.params.mid;
    var eid = this.props.params.eid;

    if (!this.eventId) {
      this.eventId = Event.generateEventId(mid, eid);
    }

    var userId = "test105@catboytech.com";
    var password = "6fec2a9601d5b3581c94f2150fc07fa3d6e45808079428354b868e412b76e6bb";

    if (this.user) {
      if (this.user.userId) {
        if (this.user.userId !== "") {
          userId = this.user.userId;
          password = this.user.password;
        }
      }
    }

    // get the session timestamp
    MeService.getSessionTimestamp(userId, password, this.eventId, this.userKey);
    // MeService.getEvent(userId, password, this.eventId, this.userKey);
  }

  componentWillMount() {
    loginStore.on("change-logout", this.logout);
    meStore.on("change-eventLoaded", this.eventLoaded);
    meStore.on("change-decisionsLoaded", this.eventLoaded);
    meStore.on("change-decisionCountsLoaded", this.eventLoaded);
    swydgetStore.on("swydget-sessionTimestampSet", this.sessionTimestampSet);

  }

  componentWillUnmount() {
    loginStore.removeListener("change-logout", this.logout)
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
    meStore.removeListener("change-decisionsLoaded", this.eventLoaded);
    meStore.removeListener("change-decisionCountsLoaded", this.eventLoaded);
    meStore.removeListener("swydget-sessionTimestampSet", this.sessionTimestampSet);
  }

  eventLoaded() {

    var swydget = meStore.getEventInFocus();

    for (var i = 0; i < swydget.wyshes.length; i++) {

      switch (swydget.wyshes[i].questionType) {
        case "multiple-choice":
          if (swydget.wyshes[i].randomizeWyshOptions) {
            swydget.wyshes[i].wyshOptions = WyshOption.shuffle(swydget.wyshes[i].wyshOptions);
          }
          break;
        case "single-select":
          if (swydget.wyshes[i].randomizeWyshOptions) {
            swydget.wyshes[i].wyshOptions = WyshOption.shuffle(swydget.wyshes[i].wyshOptions);
          }
          break;
        default:
      }
    }

    this.setState({
      eventInFocus: swydget
    });
  }

  sessionTimestampSet = () => {

    var swydget = swydgetStore.getSwydgetInFocus();

    for (var i = 0; i < swydget.wyshes.length; i++) {

      switch (swydget.wyshes[i].questionType) {
        case "multiple-choice":
          if (swydget.wyshes[i].randomizeWyshOptions) {
            swydget.wyshes[i].wyshOptions = WyshOption.shuffle(swydget.wyshes[i].wyshOptions);
          }
          break;
        case "single-select":
          if (swydget.wyshes[i].randomizeWyshOptions) {
            swydget.wyshes[i].wyshOptions = WyshOption.shuffle(swydget.wyshes[i].wyshOptions);
          }
          break;
        default:
      }
    }
    
    this.setState({
      eventInFocus: swydget,
      sessionTimestamp: swydgetStore.sessionTimestamp
    });
  }

  generateSwydget() {

    var isEventValid = false;

    if (this.state.eventInFocus.wyshes) {

      if (this.state.eventInFocus.wyshes.length > 0) {
        isEventValid = true;
      }
    }

    if (isEventValid === true) {
      if (this.state.eventInFocus.surveyType === "forced_rank") {
        return <SwydgetForcedRanking sessionTimestamp={this.state.sessionTimestamp} eventInFocus={this.state.eventInFocus} location={location}/>
      }
      else if (this.state.eventInFocus.locationEnabled) {
        if (this.userId !== undefined) {
          return <SwydgetLocationEnabled sessionTimestamp={this.state.sessionTimestamp} email={this.userId} eventInFocus={this.state.eventInFocus} location={location}/>
        }
        else {
          return <SwydgetLocationEnabled sessionTimestamp={this.state.sessionTimestamp} eventInFocus={this.state.eventInFocus} location={location}/>
        }
      }
      else {
        if (this.userId !== undefined) {
          return <Swydget sessionTimestamp={this.state.sessionTimestamp} email={this.userId} eventInFocus={this.state.eventInFocus} location={location}/>
        }
        else {
          return <Swydget sessionTimestamp={this.state.sessionTimestamp} eventInFocus={this.state.eventInFocus} location={location}/>
        }
      }
    }
    else {
      return <div></div>
    }

  }

  render() {

    let leftWidgetContainerStyle = {
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 0
    }

    return (
      <div style={leftWidgetContainerStyle}>
        { this.generateSwydget() }
      </div>
    );
  }
}

const SwydgetContainerWrapper = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <SwydgetContainer navigationHandler={handleNavigation} params={params} location={location} />
}

export default SwydgetContainerWrapper;
