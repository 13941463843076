import { EventEmitter } from "events";

import dispatcher from "../Dispatcher";
import User from "../classes/User";

class LoginStore extends EventEmitter {
  constructor() {
    super();
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.myFriends = "";
    this.errorOccurred = false;
    this.errorMessage = "";
    this.errorCode = "";
  }

  loginUserFromLocalStorage(loggedInUserJsonString) {
    localStorage.setItem("loggedInUserJsonString", loggedInUserJsonString);
    this.loggedInUser = User.createUserFromJsonString(loggedInUserJsonString);
    this.emit("loginStore_user_logged_in");
  }

  logoutUser() {
    // 1. remove user from localStorage
    localStorage.removeItem("loggedInUserJsonString");
    this.loggedInUser = null;
    this.emit("change-logout");
  }

  getLoggedInUser() {
    if (this.loggedInUser) {
      if (this.loggedInUser.userId !== "") {
        return this.loggedInUser;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  handleActions(action) {
    this.errorOccurred = false;
    this.errorMessage = "";

    switch(action.type) {
      case "LOGIN_USER_START": {
        break;
      }
      case "LOGIN_USER_SUCCESSFUL": {
        this.loginUserFromLocalStorage(action.loggedInUserJsonString);
        break;
      }
      case "LOGIN_USER_UNSUCCESSFUL": {
        this.errorOccurred = true;
        if (action.error.message === "Network Error") {
          // If it is a network error, that transcends anything application specific
          this.errorMessage = "An error occurred accessing the Swtychback servers. Please try again later.";
        }
        else {
          // We are communicating properly, but login Failed. The reason message is
          // coming from the http response from the backend.
          //
          // Possible Reasons:
          // 1. Bad credentials
          // 2. Unknown user

          var reason = action.reason;
          if (reason === "Bad credentials") {
            this.errorCode = "bad_creds";
            this.errorMessage = "User already exists, but your password is incorrect.";
          }
          else {
            this.errorCode = "unknown_user";
            this.errorMessage = "User not in system! Yay!!! Let's create him.";
          }

        }

        this.emit("change-loginFailed");
        break;
      }
      case "LOGIN_CREATE_USER_START": {
        break;
      }
      case "LOGIN_CREATE_USER_USER_EXISTS": {
        this.loginUserFromLocalStorage(action.loggedInUserJsonString);
        break;

      }
      case "LOGIN_CREATE_USER_NETWORK_ERROR": {
        this.errorOccurred = true;
        this.errorCode = "network_error";
        this.errorMessage = "An error occurred accessing the Swtychback servers. Please try again later.";
        this.emit("change-loginFailed");
        break;
      }
      case "LOGIN_CREATE_USER_BAD_CREDS": {
        this.errorOccurred = true;
        this.errorCode = "bad_creds";
        this.errorMessage = "User already exists, but your password is incorrect.";
        this.emit("change-loginFailed");
        break;
      }
      case "LOGIN_CREATE_USER_SUCCESSFUL": {
        localStorage.setItem("loggedInUserJsonString", action.loggedInUserJsonString);
        this.loggedInUser = User.createUserFromJsonString(action.loggedInUserJsonString);
        this.emit("loginStore_user_created");
        break;
      }
      case "LOGIN_UPDATE_USER_START": {
        this.emit("loginStore_user_update_start");
        break
      }
      case "LOGIN_UPDATE_USER_SUCCESSFUL": {
        localStorage.setItem("loggedInUserJsonString", action.loggedInUserJsonString);
        this.loggedInUser = User.createUserFromJsonString(action.loggedInUserJsonString);
        this.emit("loginStore_user_update_successful");
        break;
      }
      case "LOGOUT_USER": {
        this.logoutUser();
        break;
      }
      case "AUTH_FETCH_USERS_REGISTRATIOM_STATUS_SUCCESSFUL": {

        this.myFriends = action.myFriends;
        this.loggedInUser.myFriends = action.myFriends;

        this.emit("change-usersProcessed");
        break;
      }
      case "AUTH_DELETE_FRIEND": {
        this.emit("auth-delete-friend-initiated");
        break;
      }
      case "AUTH_DELETE_FRIEND_SUCCESSFUL": {
        this.emit("auth-delete-friend-successful");
        break;
      }
      case "AUTH_ADD_SEGMENT_SUCCESSFUL": {
        this.loggedInUser.segmentBucket = action.segmentBucket;
        this.emit("auth_add_segment_successful");
        break;
      }
      case "AUTH_UPDATE_SEGMENT_SUCCESSFUL": {
        this.loggedInUser.segmentBucket = action.segmentBucket;
        this.emit("auth_update_segment_successful");
        break;
      }
      case "AUTH_DELETE_SEGMENT_SUCCESSFUL": {
        this.loggedInUser.segmentBucket = action.segmentBucket;
        this.emit("auth_delete_segment_successful");
        break;
      }
      case "AUTH_ADD_MESSAGING_CAMPAIGN_SUCCESSFUL": {
        this.loggedInUser.messagingCampaignBucket = action.messagingCampaignBucket;
        this.emit("auth_add_messaing_campaign_successful");
        break;
      }
      case "AUTH_UPDATE_MESSAGING_CAMPAIGN_SUCCESSFUL": {
        this.loggedInUser.messagingCampaignBucket = action.messagingCampaignBucket;
        this.emit("auth_update_messaing_campaign_successful");
        break;
      }
      case "AUTH_DELETE_MESSAGING_CAMPAIGN_SUCCESSFUL": {
        this.loggedInUser.messagingCampaignBucket = action.messagingCampaignBucket;
        this.emit("auth_delete_messaing_campaign_successful");
        break;
      }
      default: {

      }
    }
  }
}

const loginStore = new LoginStore();
dispatcher.register(loginStore.handleActions.bind(loginStore));

export default loginStore;
