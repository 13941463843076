import dispatcher from "../../Dispatcher";
import axios from "axios";
import swydgetStore from "../../stores/SwydgetStore";

const SwydgetActionsHook = () => {

  /*
  postProductDecision

  Submit a product survey decision to the back end. The email is optional as anonymous
  decisions must be logged as well as those tied to a particular Swytchback user.
  */
  const postProductDecision = (serverUrl, fireAndForget, userKey, firstName, lastName, productId, eventId, decisionRaw, decisionValue, coordinates, freeResponseAnswer, userInteractionJson={}, location) => {

    const geo_dict = {};
    if (coordinates) {
      geo_dict["accuracy"] = coordinates["accuracy"];
      geo_dict["latitude"] = coordinates["latitude"];
      geo_dict["longitude"] = coordinates["longitude"];
    }

    const freeResponseAnswers = [];
    if (freeResponseAnswer && freeResponseAnswer.trim() !== "") {
      freeResponseAnswers.push(freeResponseAnswer);
    }

    const parametersJson = {
      "product_id": productId,
      "event_id": eventId,
      "decision": decisionRaw,
      "decision_value": decisionValue,
      "geolocation_info": geo_dict,
      "session_timestamp": swydgetStore.sessionTimestamp,
      "free_response_answers": freeResponseAnswers,
      "user_interaction_json": userInteractionJson
    };

    if (userKey) {
      parametersJson["user_key"] = userKey;
    }

    if (firstName) {
      parametersJson["first_name"] = firstName;
    }

    if (lastName) {
      parametersJson["last_name"] = lastName;
    }

    if (swydgetStore.versionId) {
      parametersJson["version_id"] = swydgetStore.versionId;
    }  

    const queryStringParamsJson = {}
    const searchParams = new URLSearchParams(location.search);
    searchParams.forEach((key, value) => {
      queryStringParamsJson[value] = key;
    })
         
    if (Object.keys(queryStringParamsJson).length > 0) {
      parametersJson["query_params"] = queryStringParamsJson;
    }

    const endpoint = "/1/swydget/surveys/decision";

    dispatcher.dispatch({
      type: "SWYDGET_POST_PRODUCT_DECISION",
      eventId: eventId,
      productId: productId,
      decision: decisionRaw
    });

    axios.post(serverUrl + endpoint, parametersJson)
      .then((data) => {

        if(fireAndForget === false) {
          if (data) {
            dispatcher.dispatch({
              type: "SWYDGET_POST_PRODUCT_DECISION_SUCCESSFUL",
              userKey: data.data.survey_taker_key,
              surveyTakerMid: data.data.survey_taker_mid,
              eventId: eventId,
              productId: productId,
              decision: decisionRaw
            });
          }
        }
      });
  }


  

  return {
    postProductDecision
  }
}

export default SwydgetActionsHook;