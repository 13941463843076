export default class NotificationHeader
{
  constructor() {
    this.acknowledged = 0;
    this.created = 0;
    this.deleted = 0;
    this.index = 0;
    this.mid = "";
    this.type = ""
  }

  initFromJson(json) {
    if (typeof json === "undefined") {
      return;
    }
    else {
      if (json.hasOwnProperty("_acknowledged" )) {
        this.acknowledged = json._acknowledged;
      }
      if (json.hasOwnProperty("_created")) {
        this.created = json._created;
      }
      if (json.hasOwnProperty("_deleted")) {
        this.deleted = json._deleted;
      }
      if (json.hasOwnProperty("_index")) {
        this.index = json._index;
      }
      if (json.hasOwnProperty("_mid")) {
        this.mid = json._mid;
      }
      if (json.hasOwnProperty("_type")) {
        this.type = json._type;
      }
      if (json.hasOwnProperty("acknowledged" )) {
        this.acknowledged = json.acknowledged;
      }
      if (json.hasOwnProperty("created")) {
        this.created = json.created;
      }
      if (json.hasOwnProperty("deleted")) {
        this.deleted = json.deleted;
      }
      if (json.hasOwnProperty("index")) {
        this.index = json.index;
      }
      if (json.hasOwnProperty("mid")) {
        this.mid = json.mid;
      }
      if (json.hasOwnProperty("type")) {
        this.type = json.type;
      }
    }
  }

  static createNotificationHeaderFromJson(json) {
    var header = new NotificationHeader();
    header.initFromJson(json);
    return header;
  }
}
