import styled from '@emotion/styled';
import { Slider } from '@mui/material';

const SwytchbackSlider = styled(Slider)({
  '& .MuiSlider-thumb' : {
    backgroundColor: '#1e70fb',
    height: '6vh',
    width: '6vh',
    border: '0.7vh solid #ffffff',
    marginLeft: 0,
    marginTop: 0
  },
  '& .MuiSlider-track' : {
    height: '2vh',
    border: '0.5vh solid #ffffff',
    borderRadius: '20px'
  },
  '& .MuiSlider-rail' : {
    height: '2vh',
    border: '0.5vh solid #ffffff',
    backgroundColor: '#1657c8',
    borderRadius: '20px',
    opacity: 1.0
  }
});

export default SwytchbackSlider;