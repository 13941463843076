import React from "react";
import { Button } from 'react-bootstrap';
import '../../../css/swytchbackModal.css';
import '../../../css/swytchbackStyles.css';

export default function MustViewAllWidget(props) {

  return (
    <div className="widget fullWidth">
      <div className='title marginBottom'>
        Must View All:
      </div>
      <div className='content rowContainerSpaced'>
        <div className="instruction">
        Must View All:
        </div>
        <div style={{width: '25%'}} className="rowContainer">
          <Button
            className={props.blockWysh.mustViewAll === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
            style={{width: '50%'}}
            active={props.blockWysh.mustViewAll === true ? true : false} 
            onClick={(e) => props.callback(true)}>
            Yes
          </Button>
          <Button 
            className={props.blockWysh.mustViewAll === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
            style={{width: '50%', borderLeft: '1px solid white'}}
            active={(props.blockWysh.mustViewAll === false) ? true : false} 
            onClick={(e) => props.callback(false)}>
            No
          </Button>
        </div>
      </div>
    </div>  
  );
}
