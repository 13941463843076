import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import "../../css/swytchbackStyles.css";
import classes from "../../css/modules/AccountManager.module.css";
import SwytchbackFrameButton from './controls/SwytchbackFrameButton';
import SwytchbackTextField from './controls/SwytchbackTextField';
import { Paper, Popover } from '@mui/material';
import SwytchbackButton from './controls/SwytchbackButton';


const TakeSnapshotPopover = ({takeSnapshot}) => {

  const [name, setName] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleSave = () => {
    takeSnapshot(name)
    setShowPopover(false);
    setName("");
  }

  return (
    <div style={{width: '100%'}}>
      <SwytchbackButton
        style={{width: '100%'}}
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          setShowPopover(!showPopover);
        }}
      >
        Save
      </SwytchbackButton>
      <Popover 
        id={"take-snapshot-popover"} 
        open={showPopover} 
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setShowPopover(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: 5, // Adjust the radius to your preference
            },
          },
        }}
        >
        <Paper
          className={`${classes.paperStyle}`}>
          <div className={`${classes.title}`}>Data Cut Name</div>
          <div className={`${classes.buttonContainerStyle}`}>
            <SwytchbackTextField
              id="exportNameTF"
              style={{width: '100%'}}
              label="Enter Name"
              value={name}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div className={`${classes.buttonContainerStyle}`}>
            <SwytchbackFrameButton style={{width: '100%'}} onClick={handleSave}>
              Save  
            </SwytchbackFrameButton>
          </div>
        </Paper>
      </Popover>
    </div>
  );
}

export default TakeSnapshotPopover;