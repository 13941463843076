import React from "react";
import { Image, ProgressBar }  from 'react-bootstrap';
import noOverlayImage from '../../No-Button-Unclicked-128.png';
import yesOverlayImage from '../../Yes-Button-Unclicked-128.png';


export default class SwydgetBinaryResults extends React.Component {
  render() {

    const { wysh } = this.props;

    var cardHeight = 55;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var cardWidthStyle = cardWidth + 'vh';
    var decisionOverlayHeightStyle = cardHeight/8 + 'vh';

    var pctLiked = wysh.aggregatedResults.getPctLikedFloat();
    var pctDisliked = wysh.aggregatedResults.getPctDislikedFloat();
    var pctLikedLabel = wysh.aggregatedResults.getPctLiked() + "% of people answered YES";
    var pctDislikedLabel = wysh.aggregatedResults.getPctDisliked() + "% of people answered NO";

    var overlayImage = null;


    if (wysh.wyshScore.resultLiteral === "yes") {
      overlayImage = yesOverlayImage;
    }
    else if (wysh.wyshScore.resultLiteral === "no") {
      overlayImage = noOverlayImage;
    }


    let cardDetailsDivStyle = {
      width: '100%',
      paddingLeft: 5,
      paddingRight: 5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'left'
    };

    let aggregatedResultsContainerDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: cardWidthStyle
    };

    let decisionDivStyle = {
      height: decisionOverlayHeightStyle,
      width: decisionOverlayHeightStyle,
      marginLeft: 6,
      marginRight: 12,
      objectFit: 'cover'
    };

    let progressBarContainerDivStyle = {
      marginTop: 0
    };

    let aggregatedResultsDivStyle = {
      padding: 0,
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 4,
      color: '#ffffff',
      fontSize: 11,
      fontWeight: 'normal',
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    };

    let progressBarBackgroundColorStyle = {
      backgroundColor: '#e2effe',
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 10,
      height: 6
    };

    let progressBarLikedStyle = {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      paddingLeft: 10,
      minWidth: 10,
      backgroundColor: '#157cf8',
      borderRadius: 25,
      fontSize: 14
    };

    let progressBarDislikedStyle = {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      paddingLeft: 10,
      minWidth: 10,
      backgroundColor: '#ed1c24',
      borderRadius: 25,
      fontSize: 12
    };


    return (
      <div style={cardDetailsDivStyle}>
        <div style={aggregatedResultsContainerDivStyle}>
        { overlayImage !== null ?
          <Image src={overlayImage} style={decisionDivStyle}></Image>
          :
          <div></div>
        }
          <div style={progressBarContainerDivStyle}>
            <div style={aggregatedResultsDivStyle}>{pctLikedLabel}</div>
            <ProgressBar style={progressBarBackgroundColorStyle}>
              <ProgressBar style={progressBarLikedStyle} now={pctLiked}/>
            </ProgressBar>
            <div style={aggregatedResultsDivStyle}>{pctDislikedLabel}</div>
            <ProgressBar style={progressBarBackgroundColorStyle}>
              <ProgressBar style={progressBarDislikedStyle} now={pctDisliked}/>
            </ProgressBar>
          </div>
        </div>
      </div>
    );
  }
}
