import React from 'react';
import ReactDOM from 'react-dom';
import App from './App2';
import './index.css';


ReactDOM.render(
  <App />,
  document.getElementById('swytchback-admin-app')
);
