export default class DecisionToBeSubmitted
{
  constructor() {
    this.key = null;
    this.wysh = null;
    this.firstName = null;
    this.lastName = null;
    this.productId = null;
    this.event = null;
    this.decisionRaw = null;
    this.decisionValue = 0;
    this.coordinates = null;
    this.freeResponseAnswer = null;
  }
}
