import React from "react";
import SwydgetBinaryResults from "./SwydgetBinaryResults";
import SwydgetScalarResults from "./SwydgetScalarResults";

export default class SwydgetWyshResults extends React.Component {
  render() {

    const { wysh } = this.props;

    var resultComponent;

    if (wysh.questionType === "scalar") {
      resultComponent = <SwydgetScalarResults wysh={wysh}/>;
    }
    else {
      resultComponent = <SwydgetBinaryResults wysh={wysh}/>;
    }


    return (
      <div>
      {resultComponent}
      </div>
    );
  }
}
