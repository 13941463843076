import React, { useState } from 'react';
import * as ld from 'lodash';
import dayjs from 'dayjs';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import classes from "../../../css/modules/FilterModals.module.css";
import WyshTable from '../../../components/WyshTable';
import { Switch, TextField } from '@mui/material';
import Decision from '../../../classes/Decision';
import { DatePicker } from '@mui/x-date-pickers';
import SwytchbackDatePicker from '../controls/SwytchbackDatePicker';

const FilterByResponsesModal = ({
  showFilterRespondentModal,
  resetRespondentFilter,
  closeFilterRespondentModal,
  user,
  swydget,
  updateFilters,
  applyFilters,
  handleCheckboxChange,
  handleFilterCheckboxChange,
  handleFilterByResponsdentsSegmentCheckboxChange,
  toggleContentFilter
}) => {

  const isFilterTermChecked = (filterTerm, filterValue) => {

    if (filterTerm === undefined) {
      return false;
    }

    let keys = Object.keys(swydget.filter.attributeDictForFilteringRespondents);
    for (var i = 0; i < keys.length; i++) {
      if (filterTerm === keys[i] && swydget.filter.attributeDictForFilteringRespondents[keys[i]] === filterValue) {
        return true;
      }
    }

    return false;
  }

  const generateAttributeCheckBoxes = () => {
    var array = [];

    var attributeKeysArray = Array.from(swydget.attributesMap.keys());
    attributeKeysArray.sort();

    for (var i = 0; i < attributeKeysArray.length; i++) {

      // get a sorted array of the experienced values for this key
      let term = attributeKeysArray[i];
      let possibleValuesSet = swydget.attributesMap.get(term);

      var possibleValuesArray = Array.from(possibleValuesSet);
      possibleValuesArray.sort();

      for (var j = 0; j < possibleValuesArray.length; j++) {

        var shouldBeChecked = false;

        if (isFilterTermChecked(term, possibleValuesArray[j])) {
          shouldBeChecked = true;
        }

        let val = possibleValuesArray[j];
        array.push(<div className="switchDivStyle" key={term + ":" + possibleValuesArray[j]}>
          <div className={`${classes.labelStyle}`}>
            {term}
          </div>
          <div className={`${classes.labelStyle}`}>
            {possibleValuesArray[j]}
          </div>
          <Switch
            checked={shouldBeChecked}
            onChange={(e) => { handleCheckboxChange(term, val, e) }}
            color="primary"
          />
        </div>);
      }
    }

    return array;
  };

  const responsesAttributesCheckBoxArray = generateAttributeCheckBoxes();

  const isFilterSegmentChecked = (segment) => {

    if (segment === undefined) {
      return false;
    }

    for (var i = 0; i < swydget.filter.requiredSegmentIdsForFilteringRespondents.length; i++) {
      if (segment.segment_id === swydget.filter.requiredSegmentIdsForFilteringRespondents[i]) {
        return true;
      }
    }

    return false;
  }

  const generateResponsesSegmentCheckBoxArray = () => {

    var array = [];

    for (var key in swydget.segmentIdCountMap) {

      let segment = user.segmentBucket.getSegment(key) ? user.segmentBucket.getSegment(key) : null;

      if (segment) {

        var shouldBeChecked = isFilterSegmentChecked(segment) ? true : false;
        let segmentCount = swydget.segmentIdCountMap[key];

        array.push(<div className="switchDivStyle" key={segment.segment_name}>
          <div className={`${classes.labelStyle}`}>
            {segment.segment_name}: {segmentCount}
          </div>
          <Switch
            checked={shouldBeChecked}
            onChange={(e) => { handleFilterByResponsdentsSegmentCheckboxChange(segment, e) }}
            color="primary"
          />
        </div>);
      }
    }

    return array;
  }

  const responsesSegmentCheckBoxArray = generateResponsesSegmentCheckBoxArray();

  const generateFilterRespondentsByContentRows = () => {

    let fullWidthWidgetContainerStyle = {
      marginBottom: 0,
      overflow: 'auto'
    }

    let wyshes = swydget.getOrderedWyshes();

    return <div style={fullWidthWidgetContainerStyle}>
      <WyshTable
        orderedWyshList={swydget.orderedWyshList}
        eventInFocus={swydget}
        onCheckboxChangeMethod={handleFilterCheckboxChange}
        selectionHandler={toggleContentFilter}
        mode="filter-respondents"
        sortOrder={"trueOrder"} />
    </div>;
  }

  const generateFilterByDecisionDateForm = () => {

    // let startDate = swydget.filter.filterStartDate ? swydget.filter.filterStartDate : new Date(swydget.createdTimeSinceEpoch * 1000);
    // console.log(startDate);

    return (
      <div className={`${classes.datePickersDivStyle}`}>
        <div className={`${classes.datePickerDivStyle}`}>
          <SwytchbackDatePicker
            label="Start Date"
            value={dayjs(swydget.filter.filterStartDate ? swydget.filter.filterStartDate : Decision.formatDate(new Date(swydget.createdTimeSinceEpoch * 1000)))}
            onChange={(newValue) => handleFilterStartDateChange(newValue)}
          />
        </div>
        <div className={`${classes.datePickerDivStyle}`}>
          <SwytchbackDatePicker
            label="End Date"
            value={dayjs(swydget.filter.filterEndDate ? swydget.filter.filterEndDate : Decision.formatDate(new Date()))}
            onChange={(newValue) => handleFilterEndDateChange(newValue)}
          />
        </div>
      </div>
    )
  }

  const handleFilterStartDateChange = (newDate) => {
    const filter = swydget.filter;
    const newFilter = ld.cloneDeep(filter);

    const startDate = dayjs(newDate).toDate();
    const endDate = dayjs(swydget.filter.filterEndDate ? swydget.filter.filterEndDate : Decision.formatDate(new Date())).toDate();

    if (startDate < endDate) {
      newFilter.filterStartDate = Decision.formatDate(startDate);
      newFilter.filterEndDate = Decision.formatDate(endDate);
    }
    else {
      newFilter.filterStartDate = Decision.formatDate(startDate);
      newFilter.filterEndDate = Decision.formatDate(startDate);
    }

    updateFilters(newFilter);
  }

  const handleFilterEndDateChange = (newDate) => {

    const filter = swydget.filter;
    const newFilter = ld.cloneDeep(filter);

    const startDate = dayjs(swydget.filter.filterStartDate ? swydget.filter.filterStartDate : Decision.formatDate(new Date(swydget.createdTimeSinceEpoch * 1000))).toDate();
    const endDate = dayjs(newDate).toDate();

    if (endDate > startDate) {
      newFilter.filterStartDate = Decision.formatDate(startDate);
      newFilter.filterEndDate = Decision.formatDate(endDate);
    }
    else {
      newFilter.filterStartDate = Decision.formatDate(endDate);
      newFilter.filterEndDate = Decision.formatDate(endDate);
    }


    updateFilters(newFilter);
  }

  return (
    <Modal
      onHide={closeFilterRespondentModal}
      size="xl"
      show={showFilterRespondentModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Select Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="filter-by-responses-tabs">
            <Tab eventKey={1} title="Survey Responses">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCentredeContainer fullWidthPane tallModalHeight'>
                    <div style={{ width: '100%', padding: 0 }}>
                      {generateFilterRespondentsByContentRows()}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={3} title="Date">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCentredeContainer fullWidthPane tallModalHeight'>
                    <div style={{ width: '100%' }}>
                      {generateFilterByDecisionDateForm()}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={4} title="Segments">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCentredeContainer fullWidthPane tallModalHeight'>
                    <div style={{ width: '100%', padding: 20 }}>
                      {responsesSegmentCheckBoxArray && responsesSegmentCheckBoxArray.length > 0 ?
                        responsesSegmentCheckBoxArray
                        :
                        <div className="switchDivStyle" key={"no-segmentation-map"}>
                          <div className={`${classes.labelStyle}`}>
                            No segmentation map loaded
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Attributes">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCentredeContainer fullWidthPane tallModalHeight'>
                    <div style={{ width: '100%', padding: 20}}>
                      {responsesAttributesCheckBoxArray && responsesAttributesCheckBoxArray.length > 0 ?
                        responsesAttributesCheckBoxArray
                        :
                        <div className="switchDivStyle" key={"no-attributes"}>
                          <div className={`${classes.labelStyle}`}>
                            No Attributes
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}>
          <div>
            <Button className="swytchbackActiveButton" style={{ width: 130, marginLeft: 5, marginRight: 5 }} onClick={resetRespondentFilter}>Clear All</Button>
          </div>
          <div>
            <Button className="swytchbackActiveButton" style={{ width: 130, marginLeft: 5, marginRight: 5 }} onClick={closeFilterRespondentModal}>Cancel</Button>
            <Button className="swytchbackActiveButton" style={{ width: 130, marginLeft: 5, marginRight: 5 }} onClick={applyFilters}>Apply</Button>
          </div>
        </div>

      </Modal.Footer>
    </Modal>
  );
}

export default FilterByResponsesModal;