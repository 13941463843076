import React from "react";
import { Image, Button, ButtonToolbar }  from 'react-bootstrap';
import likeButtonImg from '../../Yes-Button-Unclicked-128.png';
import dislikeButtonImg from '../../No-Button-Unclicked-128.png';
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';

export default class SwydgetInstructions extends React.Component {

  constructor() {
    super();

    this.handleSliderChange = this.handleSliderChange.bind(this);

    this.state = {
      currentSliderValue: 2,
    }
  }

  handleSliderChange(value) {
    this.setState({currentSliderValue: Math.round(value)});
  }


  generateScalarToolbar(actionMethod) {

    var cardHeight = 55;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var nextButtonWidthStyle = cardWidth + 'vh';

    let toolbarContainerNoTopBorderDivStyle = {
      padding: 30,
      margin: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    };

    let sliderWrapper = {
      width: nextButtonWidthStyle,
      marginTop: 0,
      marginBottom: 10
    };

    return (
      <div style={toolbarContainerNoTopBorderDivStyle}>
        <div style={sliderWrapper}>
          
        </div>
      </div>
    );
  }

  render() {

    const { eventInFocus } = this.props;
    const { actionMethod } = this.props;
    const wysh = eventInFocus.getWyshes()[0];

    var cardHeight = 45;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';
    var instructionWidthStyle = (cardWidth * 1.3) + 'vh';
    var nextButtonWidthStyle = cardWidth + 'vh';
    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (eventInFocus && eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    let title = "How to Play";
    let subTitle = "View the Prompt & Card";
    let cardInstruction = "Select Your Response";
    let actionInstruction = "Drag Slider Bar for More Options";

    let containerDivStyle = {
      height: '100vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };

    let cardOverlayStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: "rgba(0, 0, 0, 0.65)",
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500,
      textShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000
    };

    let titleDivStyle = {
      height: '7vh',
      width: instructionWidthStyle,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'column',
      fontSize: 18,
      fontWeight: 300

    };

    let oneDivStyle = {
      height: '8vh',
      width: instructionWidthStyle,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'

    };

    let twoDivStyle = {
      height: '65vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let actionButtonDivStyle = {
      height: '20vh',
      width: cardWidthStyle,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let headerDivStyle = {
      height: '15vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let footerDivStyle = {
      height: '25vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidthStyle,
      height: cardHeightStyle,
      padding: 0,
      margin: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      WebkitBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.4)',
      MozBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.4)',
      boxShadow: '0px 3px 20px 10px rgba(0,0,0,0.4)'
    };

    let productImageStyle = {
      width: cardWidthStyle,
      height: cardHeightStyle,
      objectFit: 'cover'
    }

    let buttonToolbarStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: instructionWidthStyle
    }

    let buttonStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 'bold',
      width: 55,
      height: 50
    }

    let conjunctionStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 400,
      width: 55,
      margin: 0,
      height: 50
    }

    let buttonImageStyle = {
      height: 50,
    }

    let primaryContentContainerStyle = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '65vh',
      zIndex: 10
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 'lighter',
      paddingTop: 5
    };

    let middleButtonStyle = {
      width: nextButtonWidthStyle,
      margin: 0,
      height: 40,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#a9a9a9',
      borderRadius: 6,
      padding: 0,
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500,
      background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`
    };

    let selectionButtonToolbarStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: nextButtonWidthStyle,
      padding: 0,
      margin: 0
    };

    return (
      <div style={containerDivStyle}>

        <div style={cardOverlayStyle}>
          <div style={titleDivStyle}>{title}</div>
          <div style={oneDivStyle}>
            <div>
              {subTitle}
            </div>
          </div>
          <div style={twoDivStyle}>
            <div>
              {cardInstruction}
            </div>
            <div style={completionDivContainerStyle}>
              <div style={buttonToolbarStyle}>
                <div style={buttonStyle}>NO</div>
                <div style={buttonStyle}><Image style={buttonImageStyle} src={dislikeButtonImg} responsive="true"/></div>
                <div style={buttonStyle}></div>
                <div style={buttonStyle}><Image style={buttonImageStyle} src={likeButtonImg} responsive="true"/></div>
                <div style={buttonStyle}>YES</div>
              </div>
            </div>
            <div style={completionDivContainerStyle}>
              <div style={buttonToolbarStyle}>
                <div style={conjunctionStyle}>or</div>
              </div>
            </div>
            <div>
              {actionInstruction}
            </div>
            {this.generateScalarToolbar(actionMethod)}
          </div>
          <div style={actionButtonDivStyle}>
            <ButtonToolbar style={selectionButtonToolbarStyle}>
              <Button style={middleButtonStyle} onClick={actionMethod}>Get Started</Button>
            </ButtonToolbar>
          </div>
        </div>
        <div style={containerStyle}>
          <div style={headerDivStyle}>

          </div>
          <div key={0} style={primaryContentContainerStyle}>
            <div style={cardStyle}>
              <Image src={wysh.product.getSecureImageUrl(wysh)} style={productImageStyle}></Image>
            </div>
          </div>
          <div style={footerDivStyle}>

          </div>
        </div>
      </div>
    );
  }
}
