export default class BlockType {

  constructor(blockTypeId, name) {
    this.blockTypeId = blockTypeId;
    this.name = name;
    this.topAvgScore = 1000;
    this.bottomAvgScore = -1000;
  }

  toString() {
    return this.blockTypeId;
  }

  generateResponsesArray(block) {
    return [];
  }

  generateUtilityScoreArray(block) {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    return utilityScoresArray;
  }

  recalculateUtilityScores(block, calculationMethod="best-worst-counting") {

    // utilityScoresArray is the array utility scores generated for each stimuli for each respondent
    const utilityScoresArray = [];

    const takerDict = this._generateTakerDict(block);
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];

    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
    }

    this.calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod);
  }

  calculateIndividualUtilityScoresForAllTakers(stimuliArray, takerDict, calculationMethod) {

  }

  // Called in Swydget toolbars
  generateIndividualUtilityScores(stimuliArray, trialSetsDecisionsMap) {

  }

  toJsonObject() {
    return {
      block_type: this.blockTypeId
    }
  }

  _getBestIndex(decArray, stimuliArray) {

    let bestDecision = null;

    for (const dec of decArray) {
      if (dec.resultNormalized === 1) {
        bestDecision = dec; 
      }
    }

    if (bestDecision) {
      return stimuliArray.indexOf(bestDecision.productId) + 1;
    }
  }

  _getWorstIndex(decArray, stimuliArray) {
    let bestDecision = null;

    for (const dec of decArray) {
      if (dec.resultNormalized === -1) {
        bestDecision = dec; 
      }
    }

    if (bestDecision) {
      return stimuliArray.indexOf(bestDecision.productId) + 1;
    }
  }

  _generateTakerDict(block) {

    const takerDict = block.event.getTakersWithDecisions();
    const blockTakerDict = {};
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();
    const stimuliProductIdArray = [];
    
    for (const stimulus of stimuliArray) {
      stimuliProductIdArray.push(stimulus.product.productId);
    }

    for (const mid in takerDict) {

      const takerDecArray = takerDict[mid];
      let versionId = -1;

      const takerMDDecArray = takerDecArray.filter(decision => {
        return stimuliProductIdArray.includes(decision.productId);
      });

      for (const dec of takerMDDecArray) {
        if (dec.versionId >= 0) {
          versionId = dec.versionId
          break; // Every decision will have the same versionId, so no point in going on
        }
      }

      if (takerMDDecArray.length > 0) {
        blockTakerDict[mid] = {

          // prune decisions for JUST max diff decisions
          "decisions": takerMDDecArray,
          "version_id": versionId
        }
      }
    }

    return blockTakerDict

  }

  _findTrialIndices(stimuli, trialSetsDecisionsMap) {

    const indicies = new Set();

    for (const trialSetIndex of trialSetsDecisionsMap.keys()) {
      const trialSet = trialSetsDecisionsMap.get(trialSetIndex);
      for (const decision of trialSet) {
        if (decision.wysh.equals(stimuli)) {
          indicies.add(trialSetIndex)
        }
      }
    }

    return indicies;
  }

  generateVersions(takerDict) {
    // go through taker by taker and generate their versions
    // Map of trials to their stimuli
    

    for (const takerMid in takerDict) {
      const takerVersionMap = new Map();

      for (const dec of takerDict[takerMid].decisions){
        
        // Load the trial sets with their Stimuli
        let trialSetDecisionArray = takerVersionMap.get(dec.trial);
        
        if (trialSetDecisionArray) {
          trialSetDecisionArray.push(dec);
        }
        else {
          trialSetDecisionArray = [];
          trialSetDecisionArray.push(dec);
          takerVersionMap.set(dec.trial, trialSetDecisionArray);
        }
      }

      // Right here I have the taker's pairwise version map
      // The keys of the map are the index of the trial set de-rotated
      // The keys are in the order they were taken by the user
      // The trial set keys map to a Map of the stimulus to the decision made
      // This should be all the data needed to generate the Importance Scores
      // The Importance Score is stored in the score field of the decision
      takerDict[takerMid].version = takerVersionMap;      
    }
  }

  toJsonString() {
    return JSON.stringify(this.toJsonObject());
  }

  equals(blockType) {
    return blockType && blockType.blockTypeId === this.blockTypeId;
  }
}