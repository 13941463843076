import React, { useState } from "react";
import WyshMeProduct from "../../classes/WyshMeProduct";

export default function BlockCard(props) {

  var cardHeight = WyshMeProduct.getCardHeightAsPct(props.cardSize);
  if (props.fullSize === false) {
    cardHeight *= 0.5;
  }
  
  var blockOneMarginLeft = cardHeight * 0.15;
  var blockOneMarginTop = cardHeight * 0.15;
  var blockTwoMarginLeft = cardHeight * 0.65;
  var blockTwoMarginTop = cardHeight * 0.9;
  var blockThreeMarginLeft = cardHeight * 1.15;
  var blockThreeMarginTop = cardHeight * 1.65;
  var cardWidth = cardHeight * (2.5/3.5); // standard card width
  var cardHeightStyle = cardHeight + 'vh';
  var cardWidthStyle = cardWidth + 'vh';
  var blockCardHeightStyle = (cardHeight * 0.7) + 'vh';
  var blockCardWidthStyle = (cardWidth * 0.7) + 'vh';


  let blockCardsStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: cardWidthStyle,
    height: cardHeightStyle,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    paddingTop: 3,
    paddingLeft: 3
  };

  let blockCardOne = {
    gridArea: '1 / 1 / 2 / 2',
    height: blockCardHeightStyle,
    width: blockCardWidthStyle,
    background: '#aeaeae',
    backgroundColor: '#ffffff',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5%',
    WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
    boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    marginLeft: blockOneMarginLeft,
    marginTop: blockOneMarginTop
  };

  let blockCardTwo = {
    gridArea: '1 / 1 / 2 / 2',
    height: blockCardHeightStyle,
    width: blockCardWidthStyle,
    background: '#aeaeae',
    backgroundColor: '#ffffff',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5%',
    WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
    boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    marginLeft: blockTwoMarginLeft,
    marginTop: blockTwoMarginTop
  };

  let blockCardThree = {
    gridArea: '1 / 1 / 2 / 2',
    height: blockCardHeightStyle,
    width: blockCardWidthStyle,
    background: '#aeaeae',
    backgroundColor: '#ffffff',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5%',
    WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
    boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    marginLeft: blockThreeMarginLeft,
    marginTop: blockThreeMarginTop
  };

  return (
    <div style={blockCardsStyle}>
      <div style={blockCardOne}></div>
      <div style={blockCardTwo}></div>
      <div style={blockCardThree}></div>
    </div>
  );
}
