import React, { useState, useEffect } from "react";
import { Select, MenuItem, TextField } from '@mui/material';
import classes from '../../../css/modules/SequentialMonadicConfigureComponent.module.css';
import { Button } from 'react-bootstrap';
import WyshOption from "../../../classes/WyshOption";
import RandomizeWidget from "../controls/RandomizeWidget";
import PromptWidget from "../controls/PromptWidget";
import BinaryIconSet from "../../../classes/BinaryIconSet";
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackTextField from "../controls/SwytchbackTextField";


export default function SequentialMonadicConfigureComponent(props) {

  const [localBlockWysh, setLocalBlockWysh] = useState(props.blockWysh);
  const [updatedBlockAttributesDict, setUpdatedBlockAttributesDict] = useState({});
  
  useEffect(() => {

  }, [props.localBlockWysh]);
  
  
  const handleDefaultPromptChange = (evt) => {
    const bw = localBlockWysh;
    bw.caption = evt.target.value;
    updatedBlockAttributesDict["defaultPrompt"] = evt.target.value;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBlockNameChange = (evt) => {
    const bw = localBlockWysh;
    bw.product.name = evt.target.value;
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleQuestionTypeChange = (evt) => {
    const bw = localBlockWysh;
    bw.questionType = evt.target.value;
    updatedBlockAttributesDict["questionType"] = evt.target.value;

    if (bw.questionType !== "multiple-choice") {
      
      const scalarWyshOptions = bw.wyshOptions.filter((wo) => {
        return wo.resultNormalized < 98;
      });

      bw.wyshOptions = scalarWyshOptions;
      bw.mcIncludeNoneOfTheAbove = false;
      bw.mcIncludeOther = false;
      bw.randomizeWyshOptions = false;
      bw.mustViewAll = false;

      updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
      updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
      updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
      updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    }

    if (!("wyshOptions" in updatedBlockAttributesDict)) {
      updatedBlockAttributesDict["wyshOptions"] = bw.wyshOptions
    }

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const generateSelectOptions = (startingIdx, count) => {

    var optionsArray = [];

    for (var i = startingIdx; i < count; i++) {
      optionsArray.push(<MenuItem key={"option-" + i} className={classes.swytchbackMenuItem} value={(i + 1).toString()}>{i + 1}</MenuItem>)
    }
    return optionsArray;
  }

  const getOptionsCount = () => {

    const bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;

    var count = currentOptions.length;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized && (currentOptions[i].resultNormalized === 99.0 || currentOptions[i].resultNormalized === 98.0)) {
        count = count - 1;
      }
    }

    return count;
  }

  const handleNumberOfOptionsChange = (evt) => {

    var bw = localBlockWysh
    let currentOptions = bw.wyshOptions;

    let numberOfOptions = evt.target.value;

    if (bw && bw.event === null) {
      return;
    }

    var newOptionsArray = [];
    var noneOfTheAboveOption;
    var otherOption;

    // Find any existing "Other" or "None of the Above options"
    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentOptions[i];
      }
      if (currentOptions[i].resultNormalized === 98.0) {
        otherOption = currentOptions[i];
      }
    }

    for (var i = 0; i < numberOfOptions; i++) {

      var resultNormalized = currentOptions[i] && currentOptions[i].resultNormalized ? currentOptions[i].resultNormalized : i;
      let optionToAdd = new WyshOption();
      optionToAdd.resultNormalized = resultNormalized;
      optionToAdd.resultLiteral = "";

      if (resultNormalized !== 99.0 && resultNormalized !== 98.0) {
        if (i < currentOptions.length) {
          optionToAdd = currentOptions[i];
        }
        else if (i < bw.event.options.length) {
          optionToAdd = bw.event.options[i];
        }
      }
      else {
        optionToAdd.resultLiteral = "";
        optionToAdd.resultNormalized = i;
      }

      newOptionsArray.push(optionToAdd);
    }

    if (otherOption) {
      newOptionsArray.push(otherOption);
    }

    if (noneOfTheAboveOption) {
      newOptionsArray.push(noneOfTheAboveOption);
    }

    newOptionsArray.sort(WyshOption.compareByResultNormalizedAsc);

    console.log(newOptionsArray);
    if (bw) {
      bw.setWyshOptions(newOptionsArray);
      updatedBlockAttributesDict["wyshOptions"] = newOptionsArray;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleRandomizeWyshOptions = (evt) => {
    var bw = localBlockWysh;
    bw.randomizeWyshOptions = !bw.randomizeWyshOptions;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcIncludeNoneOfTheAbove = (evt) => {
    var bw = localBlockWysh;
    bw.mcIncludeNoneOfTheAbove = !bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setupSpecialOptions(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcIncludeOther = (evt) => {
    var bw = localBlockWysh;
    bw.mcIncludeOther = !bw.mcIncludeOther;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setupSpecialOptions(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMustViewAll = (evt) => {
    var bw = localBlockWysh;
    bw.mustViewAll = !bw.mustViewAll;
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleMcOptionsLimitChange = (evt) => {
    var bw = localBlockWysh;
    bw.mcOptionsLimit = parseInt(evt.target.value);
    updatedBlockAttributesDict["randomizeWyshOptions"] = bw.randomizeWyshOptions;
    updatedBlockAttributesDict["mcIncludeNoneOfTheAbove"] = bw.mcIncludeNoneOfTheAbove;
    updatedBlockAttributesDict["mcIncludeOther"] = bw.mcIncludeOther;
    updatedBlockAttributesDict["mcOptionsLimit"] = bw.mcOptionsLimit;
    updatedBlockAttributesDict["mustViewAll"] = bw.mustViewAll;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleBinaryIconSetIdChange = (evt) => {

    let binaryIconSetID = evt.target.value;

    var bw = localBlockWysh;

    if (bw) {
      bw.binaryIconSetID = binaryIconSetID;
      updatedBlockAttributesDict["binaryIconSetID"] = bw.binaryIconSetID;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleDefaultFreeResponseQuestionChange = (evt) => {

    var bw = localBlockWysh;
    
    if (bw) {
      bw.freeResponseQuestions = [evt.target.value];
      updatedBlockAttributesDict["freeResponseQuestions"] = bw.freeResponseQuestions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleOptionNameChange = (idx) => (evt) => {
    editOption(idx, evt.target.value);
  }

  const handleRandomizeStimuli = (randomize) => {
    
    var bw = localBlockWysh;
    bw.randomize = randomize;

    if (bw.randomize === false ) {
      // clear out settings that cannot be enabled when a Swydget is randomized
      bw.issueSubGroups = false;
      bw.subGroupCount = 1;
      bw.limitFreeResponse = false;
      bw.limitFreeResponseCount = 1;
    }
    
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleIssueSubGroupClicked = (issueSubGroups) => {

    var bw = localBlockWysh;

    if (bw) {
      bw.issueSubGroups = issueSubGroups;

      if (bw.issueSubGroups === false) {
        bw.subGroupCount = 1;
      }
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleSubGroupCountChanged = (evt) => {

    var bw = localBlockWysh;
    bw.subGroupCount = parseInt(evt.target.value);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const handleLimitFreeResponseClicked = (limitFreeResponse) => {

    var bw = localBlockWysh;

    if (bw) {
      bw.limitFreeResponse = limitFreeResponse;

      if (bw.limitFreeResponse === false) {
        bw.limitFreeResponseCount = 1;
      }
      setLocalBlockWysh(bw);
      props.updateBlockCallback(bw, updatedBlockAttributesDict);
    }
  }

  const handleLimitFreeResponseCountChanged = (evt) => {

    var bw = localBlockWysh;
    bw.limitFreeResponseCount = parseInt(evt.target.value);
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const editOption = (index, value) => {

    var bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;
    currentOptions[index].resultLiteral = value;

    if (bw) {
      bw.setWyshOptions(currentOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }
    
    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const removeOption = (index) => {

    var bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;
    currentOptions.splice(index, 1);

    if (bw) {
      bw.setWyshOptions(currentOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(bw);
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }

  const setupSpecialOptions = (blockWysh) => {

    var currentWyshOptions = blockWysh.wyshOptions;
    var otherOption = null;
    var noneOfTheAboveOption = null;

    // Get OTHER: 98.0 and remove it from the currentWyshOptions
    for (var i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 98.0) {
        otherOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Get NONE OF THE ABOVE: 99.0 and remove it from the currentWyshOptions
    for (i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Add OTHER: 98.0 back in, if appropriate
    if (blockWysh.mcIncludeOther) {
      if (otherOption) {
        currentWyshOptions.push(otherOption)
      }
      else {
        otherOption = new WyshOption();
        otherOption.resultLiteral = "Other";
        otherOption.resultNormalized = 98.0;
        currentWyshOptions.push(otherOption);
      }
    }

    // Add NONE OF THE ABOVE: 99.0 back in, if appropriate
    if (blockWysh.mcIncludeNoneOfTheAbove) {
      if (noneOfTheAboveOption) {
        currentWyshOptions.push(noneOfTheAboveOption)
      }
      else {
        noneOfTheAboveOption = new WyshOption();
        noneOfTheAboveOption.resultLiteral = "None of the Above";
        noneOfTheAboveOption.resultNormalized = 99.0;
        currentWyshOptions.push(noneOfTheAboveOption);
      }
    }

    if (blockWysh) {
      blockWysh.setWyshOptions(currentWyshOptions);
      updatedBlockAttributesDict["wyshOptions"] = currentWyshOptions;
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }

    setLocalBlockWysh(blockWysh);
    props.updateBlockCallback(blockWysh, updatedBlockAttributesDict);
  }

  const toggleFreeResponseEnabled = (blockWysh, optionValue) => {

    if (blockWysh.hiddenFreeResponseOptions.includes(optionValue)) {
      blockWysh.hiddenFreeResponseOptions.splice(blockWysh.hiddenFreeResponseOptions.indexOf(optionValue), 1);
    }
    else {
      blockWysh.hiddenFreeResponseOptions.push(optionValue);
    }

    updatedBlockAttributesDict["hiddenFreeResponseOptions"] = blockWysh.hiddenFreeResponseOptions;
    setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    setLocalBlockWysh(blockWysh);
    props.updateBlockCallback(blockWysh, updatedBlockAttributesDict);
  }

  const generateChoiceFields = () => {

    const bw = localBlockWysh;
    let currentOptions = bw.wyshOptions;

    return (
        currentOptions.map((option, idx) => (
          <div key={idx} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id="info-input"
              style={{width: '75%'}}
              value={option.resultLiteral}
              onChange={handleOptionNameChange(idx)}
              margin="normal"
              variant="outlined"
              placeholder="Name..."
              maxLength={192}
            />
            { option.resultNormalized !== 99.0 && option.resultNormalized !== 98.0 &&
              <Button
                className="swytchbackButton textFieldHeight" 
                style={{width: '24%'}}
                onClick={() => removeOption(idx)}>
                Remove
              </Button>
            }
          </div>
        ))
    )
  }

  const generateMenuItems = () => {

    const items = []

    const binaryOptions = BinaryIconSet.getOrderedList();
    binaryOptions.map(
      (iconSet) => {
       items.push(<MenuItem key={iconSet.binaryIconSetID} value={iconSet.binaryIconSetID}>{iconSet.name}</MenuItem>) 
      }
    )

    return items;
  }

  const generateDefaultFreeResponseCheckboxes = (stimulus) => {

    // Display Logic: show if response =

    // Here, I have a stimulus. That stimulus has a filter. That filter tells me
    // if this stimulus should have it's box checked and which box should be checked



    var checkboxes = [];

    let divStyle = {
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }

    if (stimulus) {

      if (stimulus.questionType === "binary") {

        checkboxes.push(
          <div key={"verbatim-" + 0} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id={"verbatim-" + 0}
              style={{width: '70%'}}
              value={"No"}
              margin="normal"
              variant="outlined"
            />
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={!(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                active={!(stimulus.hiddenFreeResponseOptions.includes(0) ? true : false)} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}>
                Yes
              </Button>
              <Button
                className={(stimulus.hiddenFreeResponseOptions.includes(0)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%'}}
                active={!(stimulus.hiddenFreeResponseOptions.includes(0) ? true : false)} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 0, e)}>
                No
              </Button>
            </div>
          </div>
        );
        checkboxes.push(
          <div key={"verbatim-" + 1} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              id={"verbatim-" + 1}
              style={{width: '70%'}}
              value={"Yes"}
              margin="normal"
              variant="outlined"
            />
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={!(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                active={!(stimulus.hiddenFreeResponseOptions.includes(1) ? true : false)} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}>
                Yes
              </Button>
              <Button
                className={(stimulus.hiddenFreeResponseOptions.includes(1)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%'}}
                active={!(stimulus.hiddenFreeResponseOptions.includes(1) ? true : false)} 
                onClick={(e) => toggleFreeResponseEnabled(stimulus, 1, e)}>
                No
              </Button>
            </div>
          </div>
        );
      }
      else if (stimulus.questionType === "scalar" || stimulus.questionType === "multiple-choice" || stimulus.questionType === "single-select") {
        for (var i = 0; i < stimulus.wyshOptions.length; i++) {

          let score = stimulus.wyshOptions[i].resultNormalized;

          checkboxes.push(
            <div key={"verbatim-" + i} className="rowContainerSpaced marginBottom">
              <SwytchbackTextField
                id={"verbatim-" + i}
                style={{width: '70%'}}
                value={(i + 1) + " - " + stimulus.wyshOptions[i].resultLiteral}
                margin="normal"
                variant="outlined"
              />
              <div style={{width: '25%'}} className="rowContainer">
                <Button
                  className={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                  style={{width: '50%'}}
                  active={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? true : false)} 
                  onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}>
                  Yes
                </Button>
                <Button 
                  className={(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized)) ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                  style={{width: '50%', borderLeft: '1px solid white'}}
                  active={!(stimulus.hiddenFreeResponseOptions.includes(stimulus.wyshOptions[i].resultNormalized) ? true : false)} 
                  onClick={(e) => toggleFreeResponseEnabled(stimulus, score, e)}>
                  No
                </Button>
              </div>
            </div>
          );
        }
      }
    }

    return <div className="columnContainer">{checkboxes}</div>;
  }

  const generateBlockOptionsForm = () => {

    if (localBlockWysh.questionType === "multiple-choice" ||
        localBlockWysh.questionType === "single-select") {
      if (localBlockWysh.wyshOptions.length === 0) {
        return (
          <div className="rowContainerSpaced marginBottom fullWidth">
            <div className='title'>
              Response Options:
            </div>
            <div style={{width: '15%'}} className='content'>
              <Select
                className="swytchbackSelect fullBorder fullWidth"
                disabled={false}
                inputProps={{ IconComponent: () => null }}
                id="font-family-select"
                value={getOptionsCount()}
                onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                {generateSelectOptions(1, 100)}
              </Select>
            </div>
          </div>    
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <Select
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </Select>
              </div>
            </div>    
            { localBlockWysh.questionType === "multiple-choice" &&
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Set Limit:
              </div>
              <div style={{width: '15%'}} className='content'>
                <Select
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={localBlockWysh.mcOptionsLimit}
                  onChange={(evt) => handleMcOptionsLimitChange(evt)}>
                  {generateSelectOptions(1, getOptionsCount())}
                </Select>
              </div>
            </div>    
            }
            <div className="rowContainerSpaced marginBottom fullWidth">
              <Button
                className={localBlockWysh.randomizeWyshOptions ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localBlockWysh.randomizeWyshOptions} 
                onClick={() => handleRandomizeWyshOptions()}>
                Randomize Options
              </Button>
              <Button
                className={localBlockWysh.mcIncludeNoneOfTheAbove ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localBlockWysh.mcIncludeNoneOfTheAbove} 
                onClick={() => handleMcIncludeNoneOfTheAbove()}>
                None of the Above
              </Button>
              <Button
                className={localBlockWysh.mcIncludeOther ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localBlockWysh.mcIncludeOther} 
                onClick={() => handleMcIncludeOther()}>
                Other
              </Button>
              <Button
                className={localBlockWysh.mustViewAll ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localBlockWysh.mustViewAll} 
                onClick={() => handleMustViewAll()}>
                Must View All Options
              </Button>
            </div>
            <div className="columnContainer fullWidth">
              {generateChoiceFields()}
            </div>
          </div>
        );
      }
    }
    else if (localBlockWysh.questionType === "scalar") {
      if (localBlockWysh.wyshOptions === 0) {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <Select
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </Select>
              </div>
            </div>    
          </div>  
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom fullWidth">
              <div className='title'>
                Response Options:
              </div>
              <div style={{width: '15%'}} className='content'>
                <Select
                  className="swytchbackSelect fullBorder fullWidth"
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={localBlockWysh.wyshOptions.length}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(1, 100)}
                </Select>
              </div>
            </div>    
            <div style={{width: '94%'}} className="columnContainer">
              {generateChoiceFields()}
            </div>
          </div>

        );
      }
    }
    else if (localBlockWysh.questionType === "binary") {
      return (
        <div className="rowContainerSpaced marginBottom fullWidth">
          <div className='title'>
          Select Buttons:
          </div>
          <div style={{width: '40%'}} className='content'>
            <Select
              className="swytchbackSelect fullBorder fullWidth"
              disabled={false}
              inputProps={{ IconComponent: () => null }}
              id="font-family-select"
              value={localBlockWysh.binaryIconSetID}
              onChange={(evt) => handleBinaryIconSetIdChange(evt)}>
              {generateMenuItems()}
            </Select>
          </div>
        </div>    
      );
    }
    else {
      return (
        <div className="swytchbackAdminControl">
          
        </div>
      )
    }
  }

  return (
    <div id="blockSequentialMonadicConfigurationComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <PromptWidget
          readOnly={props.readOnly}
          title={"Block Name:"}
          value={localBlockWysh.product.name}
          callback={handleBlockNameChange}
        />
        <div className="widget fullWidth">
          <div className='title marginBottom'>
            Question Type:
          </div>
          <div className='content'>
            <div className="swytchbackAdminControl">
              <div className="rowContainer">
                <SwytchbackSelect
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="question-type-select"
                  value={localBlockWysh.questionType}
                  onChange={(evt) => handleQuestionTypeChange(evt)}
                >
                  <MenuItem key={"select"} className={classes.swytchbackMenuItem} value={"select"}>select</MenuItem>
                  <MenuItem key={"binary"} className={classes.swytchbackMenuItem} value={"binary"}>Binary (Yes/No)</MenuItem>
                  <MenuItem key={"scalar"} className={classes.swytchbackMenuItem} value={"scalar"}>Scalar</MenuItem>
                  <MenuItem key={"multiple-choice"} className={classes.swytchbackMenuItem} value={"multiple-choice"}>Multiple Choice</MenuItem>
                  <MenuItem key={"single-select"} className={classes.swytchbackMenuItem} value={"single-select"}>Single Select</MenuItem>
                  <MenuItem key={"next"} className={classes.swytchbackMenuItem} value={"next"}>Next</MenuItem>
                  <MenuItem key={"free-response-only"} className={classes.swytchbackMenuItem} value={"free-response-only"}>Verbatim Only</MenuItem>
                </SwytchbackSelect>
                <div className="swytchbackInstruction">{localBlockWysh.getQuestionTypeDescription()}</div>
              </div>
            </div>    
          </div>
        </div>    
        <PromptWidget
          readOnly={props.readOnly}
          title={"Question Prompt:"}
          value={localBlockWysh.caption}
          callback={handleDefaultPromptChange}
        />
        <div className="widget fullWidth">
          <div className='content'>
            <div className="swytchbackAdminControl">
              {generateBlockOptionsForm()}
            </div>
          </div>
        </div> 
        <RandomizeWidget 
          blockWysh={localBlockWysh} 
          callback={handleRandomizeStimuli}
        />
        <div className="widget fullWidth">
          <div className='title marginBottom'>
            Batch Stimuli:
          </div>
          <div className='content rowContainerSpaced marginBottom'>
            <div className="instruction">
              Batch
            </div>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                disabled={props.readOnly || !localBlockWysh.randomize}
                className={localBlockWysh.issueSubGroups === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                active={localBlockWysh.issueSubGroups === true ? true : false} 
                onClick={(e) => handleIssueSubGroupClicked(true)}>
                Yes
              </Button>
              <Button 
                disabled={props.readOnly || !localBlockWysh.randomize}
                className={localBlockWysh.issueSubGroups === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                active={(localBlockWysh.issueSubGroups === false) ? true : false} 
                onClick={(e) => handleIssueSubGroupClicked(false)}>
                No
              </Button>
            </div>
          </div>  
          <div className='content rowContainerSpaced marginBottom'>
            <div className="instruction">
              Count
            </div>  
            <Select
              className="swytchbackSelect fullBorder"
              disabled={props.readOnly || !localBlockWysh.randomize || !localBlockWysh.issueSubGroups}
              inputProps={{ IconComponent: () => null }}
              value={localBlockWysh.subGroupCount}
              id="set-batch-count-select"
              onChange={(evt) => handleSubGroupCountChanged(evt)}
            >
              {generateSelectOptions(0, localBlockWysh.orderedWyshList.wyshes.length)}
            </Select>
          </div>
          <div className='content rowContainerSpaced marginBottom'>
            <div className="instruction">
              Limit Free Response
            </div>  
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                disabled={props.readOnly || !localBlockWysh.randomize}
                className={localBlockWysh.limitFreeResponse === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                active={localBlockWysh.limitFreeResponse === true ? true : false} 
                onClick={(e) => handleLimitFreeResponseClicked(true)}>
                Yes
              </Button>
              <Button 
                disabled={props.readOnly || !localBlockWysh.randomize}
                className={localBlockWysh.limitFreeResponse === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                active={(localBlockWysh.limitFreeResponse === false) ? true : false} 
                onClick={(e) => handleLimitFreeResponseClicked(false)}>
                No
              </Button>
            </div>
          </div>  
          <div className='content rowContainerSpaced marginBottom'>
            <div className="instruction">
              Count
            </div>  
            <Select
              className="swytchbackSelect fullBorder"
              disabled={props.readOnly || !localBlockWysh.randomize || !localBlockWysh.limitFreeResponse}
              inputProps={{ IconComponent: () => null }}
              value={localBlockWysh.limitFreeResponseCount}
              id="set-limit-verbatim-count-select"
              onChange={(evt) => handleLimitFreeResponseCountChanged(evt)}
            >
              {generateSelectOptions(0, localBlockWysh.issueSubGroups === true ? localBlockWysh.subGroupCount : localBlockWysh.orderedWyshList.wyshes.length)}
            </Select>
          </div>
        </div>  
        <div className="widget fullWidth">
          <div className='title marginBottom'>
            Verbatim
          </div>
          <div className='content rowContainerSpaced'>
          { props.readOnly === false ?
            <SwytchbackTextField
              id="free-response-input"
              style={{marginBottom: 10}}
              value={localBlockWysh.freeResponseQuestions[0]}
              onChange={handleDefaultFreeResponseQuestionChange}
              margin="normal"
              variant="outlined"
            />
          :
            <div className="swytchbackAdminControl">
              <div>Default Verbatim:</div>
              <div>{localBlockWysh.freeResponseQuestions[0]}</div>
            </div>
          }
            <div className="swytchbackAdminControl">
              {generateDefaultFreeResponseCheckboxes(localBlockWysh)}
            </div>  
          </div>
        </div>  
      </div>
    </div>  
  );
}