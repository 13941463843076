import React from 'react';
import { Button, Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Backdrop, CircularProgress, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Paper, Popover } from '@mui/material';
import { Edit, Margin, Settings } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import classes from '../css/modules/Results.module.css';
import Decision from "../classes/Decision";
import Event from "../classes/Event";
import FilterWyshWrapper from "../classes/FilterWyshWrapper";
import User from "../classes/User";
import ImportSegmentationModal from "../hooks/admin/swydget/ImportSegmentationModal";
import SwydgetReports from "../components/SwydgetReports";
import WyshTable from "../components/WyshTable";
import CompletionInfo from '../hooks/admin/CompletionInfo';
import EventMembersModal from '../hooks/admin/swydget/EventMembersModal';
import GetSurveyLinkModal from '../hooks/admin/swydget/GetSurveyLinkModal';
// import SurveyResultsMap from '../components/admin/SurveyResultsMap';
import WyshResultsPrintReport from '../hooks/admin/WyshResultsPrintReport';
// import WyshResultsPrintReport from "../components/admin/WyshResultsPrintReport";
import "../css/swytchbackModal.css";
import "../css/swytchbackStyles.css";
import ExportSwydgetModal from "../hooks/admin/ExportSwydgetModal";
import SwydgetIntegrationsModal from "../hooks/admin/SwydgetIntegrationsModal";
import SwydgetTakersModal from "../hooks/admin/SwydgetTakersModal";
import MeService from '../services/MeService';
import loginStore from "../stores/LoginStore";
import meStore from "../stores/MeStore";
import swydgetStore from "../stores/SwydgetStore";
import SwytchbackSelect from '../hooks/admin/controls/SwytchbackSelect';
import DeleteSwydgetModal from '../hooks/admin/swydget/DeleteSwydgetModal';
import FilterByItemsModal from '../hooks/admin/swydget/FilterByItemsModal';
import FilterByResponsesModal from '../hooks/admin/swydget/FilterByResponsesModal';
import SnapshotManagementModal from "../hooks/admin/SnapshotManagementModal";
import TakeSnapshotPopover from '../hooks/admin/TakeSnapshotPopover';
import SwytchbackButton from '../hooks/admin/controls/SwytchbackButton';
import SwytchbackCheckboxSelect from '../hooks/admin/controls/SwytchbackCheckboxSelect';


class Results extends React.Component {

  constructor() {
    super();
    this.user = loginStore.getLoggedInUser();
    this.eventId = "";
    this.loadingMessage = "";
    this.inviteesToAdd = [];
    this.logout = this.logout.bind(this);
    this.eventLoaded = this.eventLoaded.bind(this);
    this.swydgetDecisionCountsLoaded = this.swydgetDecisionCountsLoaded.bind(this);
    this.swydgetDecisionsLoaded = this.swydgetDecisionsLoaded.bind(this);
    this.swydgetGeolocatinoDataLoaded = this.swydgetGeolocatinoDataLoaded.bind(this);
    this.freeResponseAnswersByProductLoaded = this.freeResponseAnswersByProductLoaded.bind(this);
    this.productUploading = this.productUploading.bind(this);
    this.productAdded = this.productAdded.bind(this);
    this.handleNextClicked = this.handleNextClicked.bind(this);
    this.handleDeleteSwydgetClicked = this.handleDeleteSwydgetClicked.bind(this);
    this.handleResultsClicked = this.handleResultsClicked.bind(this);
    this.handleGeoMapClicked = this.handleGeoMapClicked.bind(this);
    this.handleCloneSwydgetClicked = this.handleCloneSwydgetClicked.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleFilterByContentCheckboxChange = this.handleFilterByContentCheckboxChange.bind(this);
    this.handleFilterCheckboxChange = this.handleFilterCheckboxChange.bind(this);
    this.handleFilterStartDateChange = this.handleFilterStartDateChange.bind(this);
    this.handleFilterEndDateChange = this.handleFilterEndDateChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleSurveyInviteesSet = this.handleSurveyInviteesSet.bind(this);
    this.handleCopySurveyLinkChanged = this.handleCopySurveyLinkChanged.bind(this);
    this.handleCopySurveyLinkClicked = this.handleCopySurveyLinkClicked.bind(this);
    this.handleContentLibraryClicked = this.handleContentLibraryClicked.bind(this);
    this.handleSurveyNameChange = this.handleSurveyNameChange.bind(this);
    this.publishSurvey = this.publishSurvey.bind(this);
    this.handleRetiredSwitchChange = this.handleRetiredSwitchChange.bind(this);
    this.surveyPublished = this.surveyPublished.bind(this);
    this.surveyInvitationSent = this.surveyInvitationSent.bind(this);
    this.swydgetTakersLoaded = this.swydgetTakersLoaded.bind(this);
    this.addNewFriendInvitee = this.addNewFriendInvitee.bind(this);
    this.inviteSurveyTakers = this.inviteSurveyTakers.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.openSurveyLink = this.openSurveyLink.bind(this);
    this.closeSurveyLink = this.closeSurveyLink.bind(this);
    this.toggleContentFilter = this.toggleContentFilter.bind(this);
    this.toggleRequiredContentRespondentFilter = this.toggleRequiredContentRespondentFilter.bind(this);
    this.isContentSelected = this.isContentSelected.bind(this);
    this.isContentSelectedFilterRespondents = this.isContentSelectedFilterRespondents.bind(this);
    this.openFilterModal = this.openFilterModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);
    this.openFilterRespondentModal = this.openFilterRespondentModal.bind(this);
    this.closeFilterRespondentModal = this.closeFilterRespondentModal.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);
    this.openEventMembersModal = this.openEventMembersModal.bind(this);
    this.closeEventMembersModal = this.closeEventMembersModal.bind(this);
    this.openCloneSwydgetModal = this.openCloneSwydgetModal.bind(this);
    this.closeCloneSwydgetModal = this.closeCloneSwydgetModal.bind(this);
    this.openDeleteSwydgetModal = this.openDeleteSwydgetModal.bind(this);
    this.closeDeleteSwydgetModal = this.closeDeleteSwydgetModal.bind(this);
    this.openProgressModal = this.openProgressModal.bind(this);
    this.closeProgressModal = this.closeProgressModal.bind(this);
    this.openGenerateWinnerModal = this.openGenerateWinnerModal.bind(this);
    this.closeGenerateWinnerModal = this.closeGenerateWinnerModal.bind(this);
    this.openSwydgetIntegrationsModal = this.openSwydgetIntegrationsModal.bind(this);
    this.closeSwydgetIntegrationsModal = this.closeSwydgetIntegrationsModal.bind(this);
    this.generateWinner = this.generateWinner.bind(this);
    this.navigateToFinalizeSurvey = this.navigateToFinalizeSurvey.bind(this);
    this.toggleEditSurveyNameDisabled = this.toggleEditSurveyNameDisabled.bind(this);
    this.addSurveyTakersHandler = this.addSurveyTakersHandler.bind(this);
    this.cloneSwydgetStarted = this.cloneSwydgetStarted.bind(this);
    this.cloneSwydgetSuccessful = this.cloneSwydgetSuccessful.bind(this);
    this.completionDataLoaded = this.completionDataLoaded.bind(this);
    this.wyshUpdated = this.wyshUpdated.bind(this);
    this.previewSurvey = this.previewSurvey.bind(this);
    this.resetContentFilter = this.resetContentFilter.bind(this);
    this.resetRespondentFilter = this.resetRespondentFilter.bind(this);
    this.swydgetFullyLoaded = this.swydgetFullyLoaded.bind(this);

    var workspace = "results";

    this.state = {
      workspace: workspace,
      isLoading: false,
      isRemoving: false,
      isUpdating: false,
      isLoadingStats: false,
      eventInFocus: new Event(),
      editSurveyNameDisabled: true,
      newProductName: "",
      newProductPrice: "",
      newProductDescription: "",
      newProductLink: "",
      newProductAttributes: {},
      newProductAttribute: "",
      isPublished: false,
      showSurveyLinkModal: false,
      showExportDataModal: false,
      showExportJsonModal: false,
      showFilterModal: false,
      showFilterRespondentModal: false,
      showReportModal: false,
      showEventMembersModal: false,
      showCloneSwydgetModal: false,
      showDeleteSwydgetModal: false,
      showProgressModal: false,
      showImportSegmentationModal: false,
      showGenerateWinnerModal: false,
      showIntegrationFeedbackModal: false,
      showSwydgetIntegrationsModal: false,
      showSettingsModal: false,
      anchorEl: null,
      showSnapshotMgtModal: false,
      snapshotName: "",
      cloneSwydgetMessage: '',
      surveyLink: '',
      surveyLinkCopied: false,
      imageFiles: [],
      surveyTakers: {},
      surveyTakerDict: {}, // I don't think this is functionaly anymore. It had to do with the EXPLICIT adding of survey takers
      completionData: [],
      mapButtonDisabled: false,
      decisionsLoaded: false,
      decisionCountsLoaded: false,
      takersLoaded: false,
      statsRefreshPctCompleted: 0,
      generateWinnerMesssage: "Click Generate Winner to choose a winner for your Swydget.",
      integrationFeedbackTitle: "",
      integrationFeedbackMessage: "",
      sortOrderIndex: "sort",
      selectedSnapshotKey: "-1", // kludgy, but whatever. -1 = live data
      snapshotIdsToDisplay: [],
      filterStartDate: null,
      filterEndDate: null,
      isReportFullyRendered: false
    };

    this.publishButtonLabel = "Next";
    this.surveyTakerArray = [];
    this.decisionsBucket = [];
    this.reportRenderedWyshIds = new Set();
    this.expandedWyshes = [];
  }

  openSurveyLink() {
    this.setState({ showSurveyLinkModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeSurveyLink() {
    this.setState({ showSurveyLinkModal: false });
  }

  /**
  * Determines the index of the argument wysh in the Filter's wyshesToDisplay array
  *
  * @param wysh
  * @return index of wysh (-1 if not found)
  */
  isContentSelected(wysh) {

    if (wysh === undefined || wysh === null) {
      return -1;
    }

    // Go through selected indices in the getEventInFocus
    for (var i = 0; i < this.state.eventInFocus.filter.wyshesToDisplay.length; i++) {
      if (wysh.wyshId === this.state.eventInFocus.filter.wyshesToDisplay[i].wyshId) {
        return i;
      }
    }

    return -1;
  }

  /**
  * Determines the index of the argument wysh in the Filter's requiredPositiveWyshesForFilteringRespondents
  *
  * @param wysh
  * @return index of wysh (-1 if not found)
  */
  isContentSelectedFilterRespondents(wysh) {

    if (wysh === undefined || wysh === null) {
      return false;
    }

    if (this.state.eventInFocus.filter.requiredPositiveWyshesForFilteringRespondents[wysh.wyshId] === undefined ||
      this.state.eventInFocus.filter.requiredPositiveWyshesForFilteringRespondents[wysh.wyshId] === null) {
      return false;
    }

    return true;


    // Go through selected indices in the getEventInFocus
    // for (var i = 0; i < this.state.eventInFocus.filter.requiredPositiveWyshesForFilteringRespondents.length; i++) {
    //   if (wysh.wyshId === this.state.eventInFocus.filter.requiredPositiveWyshesForFilteringRespondents[i].wyshId) {
    //     return i;
    //   }
    // }
    //
    // return -1;
  }

  handleFilterCheckboxChange(wysh, choice, event) {

    let orderedWyshes = this.state.eventInFocus.getOrderedWyshes();
    var wyshIndex = -1;

    for (var i = 0; i < orderedWyshes.length; i++) {
      if (wysh.wyshId === orderedWyshes[i].wyshId) {
        wyshIndex = i;
      }
    }

    this.toggleRequiredContentRespondentFilter(wysh, choice);
  }

  handleFilterStartDateChange(evt) {
    this.setState({ filterStartDate: new Date(evt.target.value) });
  }

  handleFilterEndDateChange(evt) {
    this.setState({ filterEndDate: new Date(evt.target.value) });
  }


  handleRetiredSwitchChange(event) {

    let retiredSelection = event.target.checked;
    var swydget = this.state.eventInFocus;

    if (retiredSelection === true) {
      swydget.swydgetState = "retired";
    }
    else {
      swydget.swydgetState = "active";
    }

    this.setState({ eventInFocus: swydget });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleSortOrderIndexChange = (evt) => {
    this.setState({ sortOrderIndex: evt.target.value });
  }

  handleSelectedSnapshotChange = (evt) => {

    const selSnapIdx = evt.target.value;

    var eif = this.state.eventInFocus;
    const snapshot = eif.snapshotContainer.getSnapshot(selSnapIdx);
    if (snapshot) {
      eif.import(snapshot);
      this.setState({
        selectedSnapshotKey: selSnapIdx,
        eventInFocus: eif,
        snapshotIdsToDisplay: [selSnapIdx]
      });
    }
  }

  handleSnapshotToDisplayChange = (evt) => {

    const snapshotIdsToDisplay = evt.target.value;

    var eif = this.state.eventInFocus;

    this.setState({
      snapshotIdsToDisplay: snapshotIdsToDisplay
    });
    // const snapshot = eif.snapshotContainer.getSnapshot(selSnapIdx);
    // if (snapshot) {
    //   eif.import(snapshot);
    //   this.setState({ selectedSnapshotKey: selSnapIdx, eventInFocus: eif });
    // }
  }

  handleAllDataClicked = () => {
    this.resetRespondentFilter();
    MeService.getDecisionCountsFiltered(this.user, this.state.eventInFocus);
    this.setState({
      selectedSnapshotKey: "-1",
      snapshotIdsToDisplay: [],
      isLoadingStats: true
    });
  }

  toggleContentFilter(index, wysh) {

    // I think we just need to check if the target wysh is a block.
    // if it is, we go through the child wyshes and place them all in the filter.
    // IF ONE of the children is in the filter already, we are removing everyone
    // If non are in the filter, we are adding them all

    var eif = this.state.eventInFocus;
    let wyshInFocus = this.state.eventInFocus.getOrderedWyshes()[index];

    if (wysh.isBlock() === true) {
      let blockWyshes = wysh.orderedWyshList.getOrderedWyshes();

      var togglingOn = true;

      for (var i = 0; i < blockWyshes.length; i++) {
        let indexOfWysh = this.isContentSelected(blockWyshes[i]);

        if (indexOfWysh >= 0) {
          togglingOn = false;
          break;
        }
      }

      if (togglingOn === true) {
        for (var i = 0; i < blockWyshes.length; i++) {
          eif.filter.wyshesToDisplay.push(blockWyshes[i]);
        }
      }
      else {
        for (var i = 0; i < blockWyshes.length; i++) {
          let indexOfWysh = this.isContentSelected(blockWyshes[i]);

          if (indexOfWysh >= 0) {
            eif.filter.wyshesToDisplay.splice(indexOfWysh, 1);
          }
        }
      }
    }
    else {
      let indexOfWysh = this.isContentSelected(wysh);

      if (indexOfWysh < 0) {
        eif.filter.wyshesToDisplay.push(wysh);
      }
      else {
        eif.filter.wyshesToDisplay.splice(indexOfWysh, 1);
      }
    }

    this.setState({ eventInFocus: eif });
  }

  toggleRequiredContentRespondentFilter(wyshInFocus, choice) {

    // let wyshInFocus = this.state.eventInFocus.getOrderedWyshes()[index];
    var eif = this.state.eventInFocus;

    let isSelected = this.isContentSelectedFilterRespondents(wyshInFocus);

    if (isSelected === false) {

      let wrapper = new FilterWyshWrapper(wyshInFocus, [choice]);

      eif.filter.requiredPositiveWyshesForFilteringRespondents[wyshInFocus.wyshId] = wrapper;
    }
    else {

      var wyshWrapper = eif.filter.requiredPositiveWyshesForFilteringRespondents[wyshInFocus.wyshId];

      var selectionsIndex = wyshWrapper.selections.indexOf(choice);

      if (selectionsIndex > -1) {
        wyshWrapper.selections.splice(selectionsIndex, 1);
        if (wyshWrapper.selections.length === 0) {
          delete eif.filter.requiredPositiveWyshesForFilteringRespondents[wyshInFocus.wyshId];
        }
      }
      else {
        wyshWrapper.selections.push(choice);
      }
    }

    this.setState({ eventInFocus: eif });
  }

  openExportDataModal = () => {
    this.state.eventInFocus.clearAllDecisions();
    this.loadDecisionDetails();
    this.setState({ showExportDataModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeExportDataModal = () => {
    this.setState({ showExportDataModal: false });
  }

  openExportJsonModal = () => {
    this.setState({ showExportJsonModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeExportJsonModal = () => {
    this.setState({ showExportJsonModal: false });
  }

  openFilterModal(index) {
    this.setState({ showFilterModal: true });
  }

  closeFilterModal() {
    this.setState({ showFilterModal: false });
  }

  openReportModal() {

    var eif = this.state.eventInFocus;
    eif.filter.selectedProductId = null;

    this.preparePrintPreview();
    this.setState({ showReportModal: true, eventInFocus: eif, showSettingsModal: false, anchorEl: null });
  }

  closeReportModal() {

    this.reportRenderedWyshIds = new Set();

    var eif = this.state.eventInFocus;
    eif.freeResponseAnswersByProduct = {};
    this.setState({ eventInFocus: eif });

    this.setState({ showReportModal: false, eventInFocus: eif });
  }

  openFilterRespondentModal(index) {
    this.setState({ showFilterRespondentModal: true });
  }

  closeFilterRespondentModal() {
    this.setState({ showFilterRespondentModal: false, showProgressModal: false });
  }

  setFilters = () => {

    // Here we want to get the decision counts with filtering
    var eif = this.state.eventInFocus;

    MeService.getDecisionCountsFiltered(this.user, eif);
    MeService.getGeolocationData(this.user, eif);
    this.setState({ 
      eventInFocus: eif, 
      showFilterRespondentModal: false, 
      showProgressModal: true,
      selectedSnapshotKey: "-1",
      snapshotIdsToDisplay: []
    });
  }

  updateFilters = (filter = null) => {

    // Here we want to get the decision counts with filtering
    var eif = this.state.eventInFocus;

    if (filter) {
      console.log(filter);
      eif.filter = filter;
      this.setState({ eventInFocus: eif });
    }
  }

  swydgetFullyLoaded() {
    this.closeProgressModal();
  }

  openEventMembersModal() {
    this.setState({ showEventMembersModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeEventMembersModal() {
    this.setState({ showEventMembersModal: false });
  }

  openImportSegementationModal = () => {
    this.setState({ showImportSegmentationModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeImportSegmentationModal = () => {
    this.setState({ showImportSegmentationModal: false });
  }

  openSnapshotMgtModal = () => {
    this.setState({ showSnapshotMgtModal: true });
  }

  closeSnapshotMgtModal = () => {
    this.setState({ showSnapshotMgtModal: false });
  }


  openCloneSwydgetModal() {
    this.setState({ cloneSwydgetMessage: "Cloning Swydget. This could take a minute...", showCloneSwydgetModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeCloneSwydgetModal() {
    this.setState({ cloneSwydgetMessage: "", showCloneSwydgetModal: false });
  }

  openDeleteSwydgetModal(index) {
    this.setState({ showDeleteSwydgetModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeDeleteSwydgetModal() {
    this.setState({ showDeleteSwydgetModal: false });
  }

  openProgressModal() {
    this.setState({ showProgressModal: true });
  }

  closeProgressModal() {
    this.setState({ showProgressModal: false });
  }

  openGenerateWinnerModal() {
    this.setState({ showGenerateWinnerModal: true, showSettingsModal: false, anchorEl: null });
  }

  closeGenerateWinnerModal() {
    this.setState({ showGenerateWinnerModal: false });
  }

  openIntegrationFeedbackModal = () => {
    this.setState({ showIntegrationFeedbackModal: true });
  }

  closeIntegrationFeedbackModal = () => {
    this.setState({ showIntegrationFeedbackModal: false });
  }

  openSwydgetIntegrationsModal() {
    this.setState({ showSwydgetIntegrationsModal: true });
  }

  closeSwydgetIntegrationsModal() {
    this.setState({ showSwydgetIntegrationsModal: false });
  }


  generateWinner() {
    var surveyTakers = [];

    var surveyTakersKeys = Object.keys(this.state.surveyTakers);

    for (var i = 0; i < surveyTakersKeys.length; i++) {
      if (this.state.surveyTakers.hasOwnProperty(surveyTakersKeys[i])) {
        surveyTakers.push(User.createUserFromJson(this.state.surveyTakers[surveyTakersKeys[i]]));
      }
    }

    var eligibleSurveyTakers = [];

    for (i = 0; i < surveyTakers.length; i++) {
      if (surveyTakers[i].keysDictionary.email && surveyTakers[i].keysDictionary.email[0]) {
        eligibleSurveyTakers.push(surveyTakers[i]);
      }
    }

    if (eligibleSurveyTakers.length > 0) {
      let winningIndex = this.getRandomInt(eligibleSurveyTakers.length);
      let winner = eligibleSurveyTakers[winningIndex];

      this.setState({
        generateWinnerMesssage: winner.userId
      });
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  handleNameChange(event) {
    this.setState({ newProductName: event.target.value });
  }

  handlePriceChange(event) {
    this.setState({ newProductPrice: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ newProductDescription: event.target.value });
  }

  handleLinkChange(event) {
    this.setState({ newProductLink: event.target.value });
  }

  previewSurvey() {
    this.setState({ showSettingsModal: false, anchorEl: null });
    window.open(this.state.eventInFocus.generateSwydgetUrl(), '');
  }

  cloneSwydgetStarted() {
    this.openCloneSwydgetModal();
  }

  cloneSwydgetSuccessful() {
    this.setState({ cloneSwydgetMessage: "Clone complete!" });
  }

  takeSnapshotSuccessful = () => {
    const eif = this.state.eventInFocus;
    this.setState({
      eventInFocus: eif,
      isUpating: false,
      isLoadingStats: false
    });
  }

  wyshUpdated() {
    this.setState({
      isUpating: false
    });
  }

  handleContentLibraryClicked(event) {
    event.preventDefault();
    this.props.navigationHandler("/library/" + this.state.eventInFocus.eventId);
  }

  navigateToFinalizeSurvey() {
    this.props.navigationHandler("/create_survey/finalize/" + this.state.eventInFocus.eventId);
  }

  handleCopySurveyLinkChanged(event) {
    this.setState({
      surveyLink: event.target.value,
      surveyLinkCopied: false
    });
  }

  toggleEditSurveyNameDisabled() {
    if (this.state.editSurveyNameDisabled === true) {
      this.setState({ editSurveyNameDisabled: false });
    }
    else {
      this.setState({ editSurveyNameDisabled: true });
    }
  }

  handleSurveyNameChange(event) {
    var evt = this.state.eventInFocus;
    evt.surveyName = event.target.value;
    this.setState({ eventInFocus: evt });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.eventInFocus.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleCopySurveyLinkClicked(event) {
    this.setState({
      surveyLinkCopied: true
    });
  }

  logout() {
    this.user = null;
  }

  getButtonStyleName(workspace) {
    if (workspace === this.state.workspace) {
      return "swytchbackActiveButton";
    }
    else {
      return "swytchbackInactiveButton";
    }
  }

  clearImage() {
    this.setState({
      newProductName: "",
      newProductPrice: "",
      newProductLink: "",
      newProductDescription: "",
      imageFiles: []
    });
  }

  componentDidMount() {
    this.eventId = this.props.params.eventId;
    MeService.getEvent(this.user.userId, this.user.password, this.eventId);
    loginStore.on("change-logout", this.logout);
    meStore.on("change-eventLoaded", this.eventLoaded);
    meStore.on("change-decisionCountsLoaded", this.swydgetDecisionCountsLoaded);
    meStore.on("change-decisionsLoaded", this.swydgetDecisionsLoaded);
    meStore.on("change-geolocationDataLoaded", this.swydgetGeolocatinoDataLoaded);
    meStore.on("change-freeResponseAnswersByProductLoaded", this.freeResponseAnswersByProductLoaded);
    meStore.on("change-productUploading", this.productUploading);
    meStore.on("change-wyshUpdated", this.wyshUpdated);
    meStore.on("change-surveyPublished", this.surveyPublished);
    meStore.on("change-surveyUnPublished", this.surveyUnPublished);
    meStore.on("change-surveyInvitationSent", this.surveyInvitationSent);
    meStore.on("change-surveyInviteesSet", this.handleSurveyInviteesSet);
    meStore.on("change-surveyTakersLoaded", this.swydgetTakersLoaded);
    meStore.on("change-cloneSwydgetStarted", this.cloneSwydgetStarted);
    meStore.on("change-cloneSwydgetSuccessful", this.cloneSwydgetSuccessful);
    swydgetStore.on("change-fetchCompletionDataSuccessful", this.completionDataLoaded);
  }

  componentWillUnmount() {
    loginStore.removeListener("change-logout", this.logout)
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
    meStore.removeListener("change-decisionCountsLoaded", this.swydgetDecisionCountsLoaded);
    meStore.removeListener("change-decisionsLoaded", this.swydgetDecisionsLoaded);
    meStore.removeListener("change-geolocationDataLoaded", this.swydgetGeolocatinoDataLoaded);
    meStore.removeListener("change-freeResponseAnswersByProductLoaded", this.freeResponseAnswersByProductLoaded);
    meStore.removeListener("change-productUploading", this.productUploading);
    meStore.removeListener("change-wyshUpdated", this.wyshUpdated);
    meStore.removeListener("change-surveyPublished", this.surveyPublished);
    meStore.removeListener("change-surveyUnPublished", this.surveyUnPublished);
    meStore.removeListener("change-surveyInvitationSent", this.surveyInvitationSent);
    meStore.removeListener("change-surveyInviteesSet", this.handleSurveyInviteesSet);
    meStore.removeListener("change-surveyTakersLoaded", this.swydgetTakersLoaded);
    meStore.removeListener("change-cloneSwydgetStarted", this.cloneSwydgetStarted);
    meStore.removeListener("change-cloneSwydgetSuccessful", this.cloneSwydgetSuccessful);
    swydgetStore.removeListener("change-fetchCompletionDataSuccessful", this.completionDataLoaded);
  }

  handleSurveyInviteesSet() {
    console.log("Survey invitees set: F[" + meStore.friendsToInvite.length + "], N:[" + meStore.nonFriendsToInvite.length + "]");

    // To publish, the non-friend wyshme users and non-wyshme users need to be added to my address book, because it
    // needs to be submitted to the backend.

    // On iOS, we publish our friends as survey takers BEFORE the survey is published
    // Afterwards, the WyshMe users that are NOT our friends are invited as well as NON-WyshMe users.
    // - this requires the mutation of the address book to accomodate our new WysheMe friends AND NON-WyshMe friends

    // Tackle the NON-WyshMe users


  }

  handleNextClicked() {
    this.props.navigationHandler("/create_survey/publish_survey/" + this.state.eventInFocus.eventId);

    // TODO: BRING THIS BACK TO LIFE!!!
    // THIS IS WHERE WE GO BACK TO SURVEY TO INVITE MORE TAKERS EXPLICITLY!!!
    // THIS IS GOING ON THE BACK BURNER
    // this.props.navigationHandler("/survey/" + this.state.eventInFocus.eventId);
  }

  handleDeleteSwydgetClicked() {
    this.closeDeleteSwydgetModal();
    MeService.deleteSwydget(this.user, this.state.eventInFocus);
  }

  handleUnpublishClicked = () => {
    MeService.unPublishSurvey(this.user, this.state.eventInFocus);
  }

  handleResultsClicked() {
    this.setState({ workspace: "results" })
  }

  loadDecisionDetails() {
    this.openProgressModal();
    MeService.getDecisions(this.state.eventInFocus);
    MeService.getSurveyTakers(this.user, this.eventId);
  }

  handleGeoMapClicked() {

    // load geo info
    MeService.getGeolocationData(this.user, this.state.eventInFocus);
    this.setState({ workspace: "geo-map" })
  }

  handleCloneSwydgetClicked() {
    this.openCloneSwydgetModal();
    MeService.cloneSwydget(this.user, this.state.eventInFocus);
  }

  inviteSurveyTakers() {
    for (var i = 0; i < meStore.friendsToInvite.length; i++) {
      this.addNewFriendInvitee(meStore.friendsToInvite[i]);
    }
    for (i = 0; i < meStore.nonFriendsToInvite.length; i++) {
      this.addNewFriendInvitee(meStore.nonFriendsToInvite[i]);
    }
    for (i = 0; i < meStore.friendsToInvite.length; i++) {
      MeService.inviteSurveyTakers(this.user, this.state.eventInFocus, meStore.friendsToInvite[i], meStore.friendsToInvite, meStore.nonFriendsToInvite);
    }
    for (i = 0; i < meStore.nonFriendsToInvite.length; i++) {
      MeService.inviteSurveyTakers(this.user, this.state.eventInFocus, meStore.nonFriendsToInvite[i], meStore.friendsToInvite, meStore.nonFriendsToInvite);
    }
  }

  /*
    addNewFriendInvitee


  */
  addNewFriendInvitee(newFriend) {
    this.user.addUserToMyAddressBook(newFriend, this.user.addressBookJson["next_cix"]);
    // this.inviteesToAdd.append(newFriend);
  }

  handleCheckboxChange(term, value, evt) {

    var eventInFocus = this.state.eventInFocus;

    if (evt.target.checked === true) {
      eventInFocus.addFilter(term, value);
    }
    else {
      eventInFocus.deleteFilter(term);
    }

    this.setState({ eventInFocus: eventInFocus });
  }

  handleFilterByResponsdentsSegmentCheckboxChange = (segment, evt) => {

    var eventInFocus = this.state.eventInFocus;

    if (segment && evt.target.checked === true) {
      eventInFocus.filter.requiredSegmentIdsForFilteringRespondents.push(segment.segment_id);
    }
    else {

      const index = eventInFocus.filter.requiredSegmentIdsForFilteringRespondents.indexOf(segment.segment_id);
      if (index > -1) {
        eventInFocus.filter.requiredSegmentIdsForFilteringRespondents.splice(index, 1);
      }
    }

    this.setState({ eventInFocus: eventInFocus });
  }

  handleFilterByContentCheckboxChange(term, value, evt) {

    var eventInFocus = this.state.eventInFocus;

    if (evt.target.checked === true) {
      eventInFocus.addContentFilter(term, value);
    }
    else {
      eventInFocus.deleteContentFilter(term);
    }

    this.setState({ eventInFocus: eventInFocus });
  }

  isFilterTermChecked(filterTerm, filterValue) {

    if (filterTerm === undefined) {
      return false;
    }

    let keys = Object.keys(this.state.eventInFocus.filter.attributeDictForFilteringRespondents);
    for (var i = 0; i < keys.length; i++) {
      if (filterTerm === keys[i] && this.state.eventInFocus.filter.attributeDictForFilteringRespondents[keys[i]] === filterValue) {
        return true;
      }
    }

    return false;
  }

  isFilterByContentTermChecked(filterTerm, filterValue) {

    if (filterTerm === undefined) {
      return false;
    }

    let keys = Object.keys(this.state.eventInFocus.filter.attributeDictForFilteringContent);
    for (var i = 0; i < keys.length; i++) {
      if (filterTerm === keys[i] && this.state.eventInFocus.filter.attributeDictForFilteringContent[keys[i]] === filterValue) {
        return true;
      }
    }

    return false;
  }

  isFilterSegmentChecked(segment) {

    if (segment === undefined) {
      return false;
    }

    for (var i = 0; i < this.state.eventInFocus.filter.requiredSegmentIdsForFilteringRespondents.length; i++) {
      if (segment.segment_id === this.state.eventInFocus.filter.requiredSegmentIdsForFilteringRespondents[i]) {
        return true;
      }
    }

    return false;
  }

  filterDecisionsWithFreeResponseAnswers(decisionsArray) {

    var decisions = [];

    for (var i = 0; i < decisionsArray.length; i++) {
      if (decisionsArray[i].freeResponseAnswers.length > 0) {
        decisions.push(decisionsArray[i]);
      }
    }

    return decisions;
  }

  generateFilterRespondentsByContentRows() {

    let fullWidthWidgetContainerStyle = {
      marginBottom: 0,
      paddingRight: 5,
      paddingLeft: 5,
      height: '50vh',
      overflow: 'scroll'
    }

    let wyshes = this.state.eventInFocus.getOrderedWyshes();

    return <div style={fullWidthWidgetContainerStyle}>
      <WyshTable
        snapshot={this.state.eventInFocus.snapshotContainer.getSnapshot(this.state.selectedSnapshotKey)}
        snapshotsToDisplay={this.state.eventInFocus.snapshotContainer.getSnapshots(this.state.snapshotIdsToDisplay)}
        orderedWyshList={this.state.eventInFocus.orderedWyshList}
        eventInFocus={this.state.eventInFocus}
        onCheckboxChangeMethod={this.handleFilterCheckboxChange}
        mode="filter-respondents"
        sortOrder={this.state.sortOrderIndex}
        itemLabel="Question Card"
        itemsLabel="Question Cards" />
    </div>;
  }

  generateFilterByContentSelectRows() {

    let fullWidthWidgetContainerStyle = {
      margin: 0,
      paddingRight: 5,
      paddingLeft: 5,
      height: '50vh',
      overflow: 'scroll'
    }

    return <div style={fullWidthWidgetContainerStyle}>
      <WyshTable
        snapshot={this.state.eventInFocus.snapshotContainer.getSnapshot(this.state.selectedSnapshotKey)}
        snapshotsToDisplay={this.state.eventInFocus.snapshotContainer.getSnapshots(this.state.snapshotIdsToDisplay)}
        orderedWyshList={this.state.eventInFocus.orderedWyshList}
        eventInFocus={this.state.eventInFocus}
        selectionHandler={this.toggleContentFilter}
        mode="filter-content"
        sortOrder={this.state.sortOrderIndex}
        itemLabel="Question Card"
        itemsLabel="Question Cards" />
    </div>;

  }

  generateFilterByDecisionDateForm() {

    let startDate = new Date(this.state.eventInFocus.createdTimeSinceEpoch * 1000);
    let endDate = new Date();

    if (this.state.eventInFocus.firstCompletionDate) {
      startDate = this.state.eventInFocus.firstCompletionDate;
    }
    if (this.state.eventInFocus.lastCompletionDate) {
      endDate = this.state.eventInFocus.lastCompletionDate;
    }


    if (this.state.filterStartDate) {
      startDate = this.state.filterStartDate;
    }

    if (this.state.filterEndDate) {
      endDate = this.state.filterEndDate;
    }

    return <div className={`${classes.datePickersDivStyle}`}>
      <form className={`${classes.container}`} noValidate>
        <div className={`${classes.datePickerDivStyle}`}>
          <TextField
            id="filterStartDate"
            type="date"
            label="First Completion"
            value={Decision.formatDate(startDate)}
            className={`${classes.datePickerStyle}`}
            onChange={this.handleFilterStartDateChange}
            InputProps={{
              classes: {
                input: `${classes.datePickerInputStyles}`,
              },
            }}
            InputLabelProps={{
              classes: {
                root: `${classes.datePickerInputLabelStyles}`
              },
            }}
          />
        </div>
        <div className={`${classes.datePickerDivStyle}`}>
          <TextField
            id="filterEndDate"
            type="date"
            label="Latest Completion"
            value={Decision.formatDate(endDate)}
            className={`${classes.datePickerStyle}`}
            onChange={this.handleFilterEndDateChange}
            InputProps={{
              classes: {
                input: `${classes.datePickerInputStyles}`,
              },
            }}
            InputLabelProps={{
              classes: {
                root: `${classes.datePickerInputLabelStyles}`
              },
            }}
          />
        </div>
      </form>
    </div>
  }

  resetContentFilter() {
    var eif = this.state.eventInFocus;
    eif.filter.resetContentFilter();
    this.setState({ eventInFocus: eif });
  }

  resetRespondentFilter() {
    var eif = this.state.eventInFocus;
    eif.filter.resetRespondentFilter();
    eif.filter.resetContentFilter();
    this.setState({ eventInFocus: eif, filterStartDate: null, filterEndDate: null });
  }

  eventLoaded() {

    var eif = meStore.eventInFocus;
    var selSnap = this.state.selectedSnapshotKey;

    const snapshots = Array.from(eif.snapshotContainer.snapshots.values());
    snapshots.sort((a, b) => a.created - b.created);
    
    if (snapshots.length > 0) {
      selSnap = snapshots[0].created.toString();
    }
    
    if (selSnap === "-1") {
      // Latest live data
      this.setState({ eventInFocus: eif, selectedSnapshotKey: selSnap, isLoadingStats: true });
      MeService.getDecisionCountsFiltered(this.user, this.state.eventInFocus);
    }
    else {
      var snapshot = eif.snapshotContainer.snapshots.get(selSnap)
      eif.import(snapshot);
      this.setState({ eventInFocus: eif, selectedSnapshotKey: selSnap, isLoadingStats: false });
    }
  }

  swydgetDecisionsLoaded() {
    var eif = this.state.eventInFocus;

    for (var i = 0; i < eif.allDecisionsArray.length; i++) {
      let dec = eif.allDecisionsArray[i];

      let w = eif.findWyshByProductId(dec.productId);
      w.decisionsArray.push(dec);
    }

    this.decisionsBucket = eif.getDecisionsByMidArray();

    // TODO: eliminate
    // eif.refreshWyshStats(this.swydgetFullyLoaded);

    this.setState({
      mapButtonDisabled: false,
      decisionsLoaded: true
    });
  }

  swydgetTakersLoaded() {
    this.setState({
      showProgressModal: false,
      surveyTakers: meStore.getSurveyTakers(),
      takersLoaded: true
    });
  }

  swydgetDecisionCountsLoaded() {
    var eif = meStore.getEventInFocus();

    if (eif) {
      this.setState({
        eventInFocus: eif,
        surveyLink: eif.generateSwydgetUrl(),
        issueSubGroups: eif.issueSubGroups,
        subGroupCount: eif.subGroupCount,
        isLoading: false,
        isRemoving: false,
        isUpating: false,
        isLoadingStats: false,
        newProductName: "",
        newProductPrice: "",
        newProductDescription: "",
        newProductLink: "",
        imageFiles: [],
        decisionCountsLoaded: true
      });

      if (eif.published === false) {
        this.props.navigationHandler("/survey/" + eif.eventId);
      }
    }

    this.closeProgressModal();
  }

  swydgetGeolocatinoDataLoaded() {

    var eif = meStore.getEventInFocus();

    if (eif) {
      this.setState({
        eventInFocus: eif
      });
    }
  }

  freeResponseAnswersByProductLoaded() {

    this.reportRenderedWyshIds = new Set();

    var eif = meStore.getEventInFocus();

    if (eif) {
      this.setState({
        eventInFocus: eif
      });
    }



    // Go through the expandedWyshes and add all the Wyshes WITHOUT something in their wordFrequencyArray to the reportRenderedWyshIds Set
    for (var i = 0; i < this.expandedWyshes.length; i++) {
      if (this.expandedWyshes[i].wordFrequencyArray.length === 0) {
        this.reportRenderedWyshIds.add(this.expandedWyshes[i].wyshId);
      }
    }
  }

  completionDataLoaded() {
    let stats = swydgetStore.completionData;
    this.setState({
      completionData: stats
    })
  }

  productUploading() {
    this.setState({
      isLoading: true
    });
  }

  productAdded() {
    this.setState({
      isLoading: false
    });
  }

  publishSurvey() {
    MeService.publishSurvey(this.user, this.state.eventInFocus, meStore.friendsToInvite, meStore.nonFriendsToInvite);
  }

  surveyPublished() {
    console.log("published survey 6: friend to Invite[" + meStore.friendsToInvite.length + "], prospects: [" + meStore.nonFriendsToInvite.length + "]");

    var e = this.state.eventInFocus
    e.published = true

    this.setState({
      eventInFocus: e,
      isPublished: true
    });
  }

  surveyUnPublished = () => {
    this.props.navigationHandler("/survey/" + this.state.eventInFocus.eventId);
  }

  surveyInvitationSent() {
    console.log("Invitation Complete:  " + meStore.nonFriendsInvitedSuccessfully.length.toString() + "//" + meStore.nonFriendsToInvite.length.toString());
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      imageFiles: acceptedFiles
    });
  }

  uploadProgress() {

  }

  addSurveyTakersHandler(userDict) {
    this.setState({ surveyTakerDict: userDict });
  }

  generateMainBodyRow() {

    var mainBodyContent = <div></div>;

    switch (this.state.workspace) {
      case "results":
        mainBodyContent = this.generateResultsRow();
        break;
      // case "geo-map":
      //   mainBodyContent = this.generateGeoMapRow();
      //   break;
      default:
        mainBodyContent = this.generateResultsRow();
    }

    return mainBodyContent;

  }

  _getWyshesForCurrentContext() {

    if (this.state.sortOrderIndex === "asc") {
      return this.state.eventInFocus.orderedWyshList.getFilteredSortedWyshes("asc", this.filter);
    }
    else if (this.state.sortOrderIndex === "desc") {
      return this.state.eventInFocus.orderedWyshList.getFilteredSortedWyshes("desc", this.filter);
    }

    return this.state.eventInFocus.getFilteredOrderedWyshes();
  }

  generateResultsRow() {

    var rows = [];
    var wyshes = this._getWyshesForCurrentContext();

    return (
      <div>
        {this._generateWyshTable(wyshes, "root")}
      </div>
    );

  }

  _generateWyshTable(wyshes, key) {

    return (
      <Row key="root">
        <Col md={12} className={`${classes.rightWidgetContainerStyle}`}>
          <WyshTable
            snapshot={this.state.eventInFocus.snapshotContainer.getSnapshot(this.state.selectedSnapshotKey)}
            snapshotsToDisplay={this.state.eventInFocus.snapshotContainer.getSnapshots(this.state.snapshotIdsToDisplay)}
            orderedWyshList={this.state.eventInFocus.orderedWyshList}
            eventInFocus={this.state.eventInFocus}
            surveyTitle={this.state.eventInFocus.title}
            mode="active"
            sortOrder={this.state.sortOrderIndex}
            itemLabel="Question Card"
            itemsLabel="Question Cards"
            setWordsToOmitCallback={this.setWordsToOmitCallback}
            setSelectedFreeResponseDecisions={this.setSelectedFreeResponseDecisions} />
        </Col>
      </Row>
    );
  }

  // generateGeoMapRow() {

  //   let fullWidthWidgetContainerStyle = {
  //     marginBottom: 0,
  //     paddingRight: 0,
  //     paddingLeft: 0
  //   }

  //   return <Row>
  //     <Col md={12} style={fullWidthWidgetContainerStyle}>
  //       <div style={{marginTop: 50}}>
  //         <SurveyResultsMap decisions={meStore.decisions} geolocationData={this.state.eventInFocus.geolocationData} height={500}/>
  //       </div>
  //     </Col>
  //   </Row>;
  // }

  isSwydgetReadOnly() {
    if (typeof this.state.eventInFocus !== undefined && this.state.eventInFocus !== null) {
      if (this.user.userId === this.state.eventInFocus.creator.userId) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true
    }
  }

  preparePrintPreview = () => {

    // We need to inflate the
    // this.setState({ isUpdating: true, isReportFullyRendered: false });

    MeService.getFreeResponseAnswers(this.user, this.state.eventInFocus);
  }

  generatePrintPreview = () => {

    this.expandedWyshes = [];
    var wyshes = this.state.eventInFocus.orderedWyshList.getFilteredSortedWyshes(this.state.sortOrderIndex, this.state.eventInFocus.filter);

    var questionNumbersMap = this.state.eventInFocus.generateQuestionNumbersMap();

    for (let questionNumber of questionNumbersMap.keys()) {
      var wysh = questionNumbersMap.get(questionNumber);
      this.expandedWyshes.push(wysh);
    }

    // for (var i = 0; i < wyshes.length; i++) {
    //   this.expandedWyshes.push(wyshes[i]);
    //   if (wyshes[i].isBlock() === true) {
    //     var childWyshes = wyshes[i].orderedWyshList.getFilteredSortedWyshes(this.state.sortOrderIndex, wyshes[i].event.filter);
    //     for (var j = 0; j < childWyshes.length; j++) {
    //       this.expandedWyshes.push(childWyshes[j]);
    //     }
    //   }
    // }

    // if (this.state.eventInFocus.filter.wyshesToDisplay.length > 0) {
    //   wyshes = this.state.eventInFocus.filter.wyshesToDisplay;
    // }

    // we need to expand blockWyshes

    return (
      <div>
        {this.expandedWyshes.map((wysh, wyshIndex) => {
          return (
            <WyshResultsPrintReport
              renderCompleteCallback={this.renderCompleteCallback}
              key={"wrpr-" + wyshIndex}
              wordCloudId={"results-WRPR-" + wysh.wyshId}
              isReportFullyRendered={this.state.isReportFullyRendered}
              wysh={wysh}
              wordsToOmit={wysh.wordsToOmit}
              selectedFreeResponseDecisions={wysh.selectedFreeResponseDecisions}
              showTopResponses={true}
            />
          )
        })
        }
      </div>
    )
  }

  renderCompleteCallback = (wyshId) => {
    this.setState({ isReportFullyRendered: true, isUpdating: false });


    // this.reportRenderedWyshIds.add(wyshId);

    // console.log(this.reportRenderedWyshIds.size + " / " + this.expandedWyshes.length);

    // if (this.reportRenderedWyshIds.size === this.expandedWyshes.length) {
    //   this.setState({ isReportFullyRendered: true, isUpdating: false });
    // }
  }

  setWordsToOmitCallback = (wysh, wordsToOmit) => {

    var eif = this.state.eventInFocus;

    for (var i = 0; i < eif.wyshes.length; i++) {
      if (eif.wyshes[i].wyshId === wysh.wyshId) {
        eif.wyshes[i].wordsToOmit = wordsToOmit;
      }
    }

    this.setState({ eventInFocus: eif });
  }

  setSelectedFreeResponseDecisions = (wysh, selectedFreeResponseDecisions) => {

    var eif = this.state.eventInFocus;

    for (var i = 0; i < eif.wyshes.length; i++) {
      if (eif.wyshes[i].wyshId === wysh.wyshId) {
        eif.wyshes[i].selectedFreeResponseDecisions = selectedFreeResponseDecisions;
      }
    }

    this.setState({ eventInFocus: eif });
  }

  takeSnapshot = (name = "") => {
    this.setState({
      isUpating: true,
      isLoadingStats: true,
      selectedSnapshotKey: "-1",
      snapshotsToDisplay: []
    });
    MeService.takeSnapshot(this.user, this.state.eventInFocus, name);
  }

  updateSnapshot = (snapshot) => {
    var eif = this.state.eventInFocus;
    const originalSnapshot = eif.snapshotContainer.getSnapshot(snapshot.created);
    originalSnapshot.name = snapshot.name;
    originalSnapshot.color = snapshot.color;
    this.setState({ eventInFocus: eif });
  }

  saveSnapshot = () => {
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  deleteSnapshot = (key) => {
    var eif = this.state.eventInFocus;
    eif.snapshotContainer.deleteSnapshot(key);
    this.setState({ eventInFocus: eif });
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  qualtricsPushInitiatedCallback = () => {

    this.setState({
      showIntegrationFeedbackModal: true,
      integrationFeedbackTitle: "Push to Qualtrics Initiated",
      integrationFeedbackMessage: "This may take up to an hour. Check your qualtrics admin console for progress."
    });

  }

  generateSnapshotsMenuItems = () => {

    const snapshots = Array.from(this.state.eventInFocus.snapshotContainer.snapshots.values());
    snapshots.sort((a, b) => a.created - b.created);


    const menuItems = snapshots.map((snapshot, snapshotIndex) => {
      return (
        <MenuItem key={snapshotIndex} value={snapshot.created}>{snapshot.name}</MenuItem>
      )
    }
    );

    menuItems.push(<MenuItem key={-1} value={-1} onClick={this.handleAllDataClicked}>All Data</MenuItem>)

    return menuItems;
  }

  render() {

    this.decisionsBucket = this.state.eventInFocus.getDecisionsByMidArray();

    let decisionsByMidArray = this.decisionsBucket[0];
    let respondentRosterArray = this.decisionsBucket[1];
    let freeResponseByMidArray = this.decisionsBucket[2];
    let takersFreeResponseDict = this.decisionsBucket[3];

    let centeredDivStyle = {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid'
    };

    var buttonStyle = {
      marginLeft: 0,
      marginRight: 5
    };

    var smallButtonStyle = {
      marginLeft: 5,
      marginRight: 5,
      fontSize: 11
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 1
    };

    let toolbarLabelStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0
    };

    let divModalTitle = {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      paddingRight: 20
    }

    let toolbarGlyphStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
      margin: 0
    };

    var topButtonStyle = {
      color: '#53585f',
      border: 0,
      fontSize: 30,
      backgroundColor: '#ffffff',
      padding: 0,
      margin: 0,
    };

    let modalButtonStyle = {
      width: 130,
      marginLeft: 5,
      marginRight: 5
    };

    if (this.state.isLoading === true) {
      this.loadingMessage = "Loading...";
    }
    else {
      this.loadingMessage = "";
    }

    if (this.state.eventInFocus.title === "" && this.state.eventInFocus.wyshes.length === 0) {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={6} style={toolbarStyle}>
                  <div style={titleStyle}>Loading...</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
    else if (this.state.eventInFocus.isPermitted(this.user) === true) {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={9} style={toolbarStyle}>
                  <form className={`${classes.container}`} noValidate autoComplete="off">
                    <TextField
                      disabled={this.state.editSurveyNameDisabled}
                      id="surveyName"
                      value={this.state.eventInFocus.surveyName}
                      margin="normal"
                      variant="standard"
                      sx={{
                        '& .MuiInputBase-root': {
                          // Apply styles to the root element of the input
                          backgroundColor: 'transparent'
                        },
                        '& .MuiInputBase-input': {
                          // Apply styles to the input element
                          color: '#53585f',
                          textAlign: 'left',
                          fontSize: 24,
                          fontWeight: 100
                        }
                      }}
                    />
                  </form>
                  <div className={`${classes.iconButtonContainer}`}>
                    <IconButton className={`${classes.spaced}`} onClick={this.toggleEditSurveyNameDisabled} >
                      <Edit className={`${classes.icon}`} />
                    </IconButton>
                  </div>
                </Col>
                <Col md={1} style={toolbarStyle}>

                </Col>
                {/* <Col md={1} style={toolbarStyle}>
                  <Button className={this.getButtonStyleName("geo-map")} disabled={this.state.mapButtonDisabled} style={buttonStyle} onClick={this.handleGeoMapClicked}>Map</Button>
                </Col> */}
                {/* <Col md={1} style={toolbarStyle}>
                  <ExportSwydgetModal
                    id={"export-swydget-popover"}
                    buttonText="Export"
                    title="Set Export Name"
                    content="This is the content of the popover."
                    placement="left"
                    swydget={this.state.eventInFocus}
                  />
                </Col> */}
                <Col md={2} style={toolbarGlyphStyle}>
                  <SwytchbackButton
                    onClick={
                      (evt) => {
                        this.setState({
                          showSettingsModal: true,
                          anchorEl: evt.currentTarget
                        });
                      }
                    }
                    style={{ width: '100%' }}
                  >
                    Survey Menu
                  </SwytchbackButton>
                  <Popover
                    id={"resultsS-advanced-popover"}
                    open={this.state.showSettingsModal}
                    anchorEl={this.state.anchorEl}
                    onClose={() => {
                      this.setState({ showSettingsModal: false });
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          borderRadius: 5, // Adjust the radius to your preference
                        },
                      },
                    }}
                  >
                    <Paper sx={{ backgroundColor: '#ffffff', width: 300 }}>
                      <div className={`${classes.title}`}>Advanced</div>
                      <div className={`${classes.buttonContainerStyle}`}>
                        <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.previewSurvey}>
                          View Survey (New Tab)
                        </SwytchbackButton>
                        <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openSurveyLink}>
                          Survey Link(s)
                        </SwytchbackButton>
                        <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openExportDataModal}>
                          Data Tables (Excel)
                        </SwytchbackButton>
                        <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openReportModal}>
                          Reports (PDF)
                        </SwytchbackButton>
                        <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openExportJsonModal}>
                          Survey Doc (JSON)
                        </SwytchbackButton>
                        {this.user && this.user.hasIntegrations() &&
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openSwydgetIntegrationsModal}>
                            Send to
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false &&
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openEventMembersModal}>
                            Manage Access (Team)
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false &&
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openGenerateWinnerModal}>
                            Contest (Generate Winner)
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false && // disables the button hmmm
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.openImportSegementationModal}>
                            Import Meta-Data (CSV)
                          </SwytchbackButton>
                        }
                        {false && this.isSwydgetReadOnly() === false && // disables the button hmmm. DISABLING TEMPORARILY
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.handleCloneSwydgetClicked}>
                            Clone
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false &&
                          <SwytchbackButton style={{ width: '100%', marginBottom: 6 }} onClick={this.handleUnpublishClicked}>
                            Un-Publish
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false &&
                          <SwytchbackButton
                            backgroundcolor="#ff0000"
                            hoverbackgoundcolor="#eb4646"
                            textcolor="#ffffff"
                            style={{ width: '100%', marginBottom: 6 }}
                            onClick={this.openDeleteSwydgetModal}>
                            Delete
                          </SwytchbackButton>
                        }
                        {this.isSwydgetReadOnly() === false &&
                          <div style={centeredDivStyle}>
                            <FormControlLabel
                              classes={{
                                label: `${classes.switchLabelStyle}`
                              }}
                              label="Retire"
                              control={
                                <Switch
                                  checked={this.state.eventInFocus.isRetired()}
                                  onChange={this.handleRetiredSwitchChange}
                                  color="primary"
                                />
                              }
                              labelPlacement="start"
                            />
                          </div>
                        }
                      </div>
                    </Paper>
                  </Popover>
                </Col>
              </Row>
              <Row key="swydgetCompletionInfo">
                <Col md={12} className={`${classes.rightWidgetContainerStyle}`}>
                  <CompletionInfo 
                    swydget={this.state.eventInFocus} 
                    snapshot={this.state.eventInFocus.snapshotContainer.getSnapshot(this.state.selectedSnapshotKey)}
                  />
                </Col>
              </Row>
              <Row>
              <Col md={1} style={toolbarLabelStyle}>
                  <div style={{ margin: 0, padding: 0, color: '#53585f', fontWeight: 400, fontSize: 14 }}>
                    Select Data:
                  </div>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <SwytchbackSelect
                    style={{ width: '100%', color: '#000000' }}
                    id="select-snapshot"
                    value={this.state.selectedSnapshotKey}
                    onChange={this.handleSelectedSnapshotChange}
                  >
                    {this.generateSnapshotsMenuItems()}
                  </SwytchbackSelect>
                </Col>
                <Col md={1} style={toolbarLabelStyle}>
                  <div style={{ margin: 0, padding: 0, color: '#53585f', fontWeight: 400, fontSize: 14 }}>
                    Cut Data:
                  </div>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <SwytchbackButton onClick={this.openFilterRespondentModal} style={{ width: '100%' }}>Apply Filters</SwytchbackButton>
                </Col>
                <Col md={1} style={toolbarStyle}>
                  <TakeSnapshotPopover
                    takeSnapshot={this.takeSnapshot}
                  />
                </Col>
                <Col md={1} style={toolbarStyle}>
                </Col>
                <Col md={1} style={toolbarLabelStyle}>
                  <div style={{ margin: 0, padding: 0, color: '#53585f', fontWeight: 400, fontSize: 14 }}>
                    Compare Cuts:
                  </div>
                </Col>
                <Col md={2} style={toolbarStyle}>
                  <SwytchbackCheckboxSelect
                    style={{ width: '100%', color: '#000000' }}
                    swydget={this.state.eventInFocus}
                    snapshot={this.state.eventInFocus.snapshotContainer.getSnapshot(this.state.selectedSnapshotKey)}
                    snapshotIdsToDisplay={this.state.snapshotIdsToDisplay}
                    onChange={this.handleSnapshotToDisplayChange}
                  />
                </Col>
                <Col md={1} style={toolbarStyle}>
                  <SwytchbackButton style={{ width: '100%' }}
                    onClick={this.openSnapshotMgtModal}
                  >
                    Manage
                  </SwytchbackButton>
                </Col>
              </Row>
              {this.generateMainBodyRow()}
            </Container>
          </div>

          <GetSurveyLinkModal showModal={this.state.showSurveyLinkModal} onHideAction={this.closeSurveyLink} eventInFocus={this.state.eventInFocus} />
          <ExportSwydgetModal
            swydget={this.state.eventInFocus}
            showModal={this.state.showExportJsonModal}
            onHideAction={this.closeExportJsonModal}
          />
          {this.state.showExportDataModal === true &&
            <Modal
              size="lg"
              show={this.state.showExportDataModal}
              onHide={this.closeExportDataModal}
              backdrop="static"
              enforceFocus={false}
            >
              <Modal.Header className="swytchbackModalHeader" closeButton>
                <Modal.Title className="swytchbackModalTitle" style={divModalTitle}>
                  <div>Swydget Reports (.xlsx)</div>
                  {this.state.eventInFocus.areDetailsLoaded() === false ?
                    <div className={`${classes.rowDivStyle}`}>
                      <div style={{ paddingRight: 10, fontSize: 16, color: '#911313' }}>Generating Reports...</div>
                      <CircularProgress size={20} />
                    </div>
                    :
                    <div className={`${classes.rowDivStyle}`}>
                      <div style={{ paddingRight: 10, fontSize: 16, color: '#0ba616' }}>Reports Ready</div>
                    </div>
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="swytchbackModalBody coloredBackground">
                <div className="hookFullPaneContainer swytchbackGradientPane">
                  <SwydgetReports
                    productsArray={this.state.eventInFocus.generateSummaryArray()}
                    decisionsByMidArray={decisionsByMidArray}
                    respondentRosterArray={respondentRosterArray}
                    freeResponseByMidArray={freeResponseByMidArray}
                    takersFreeResponseDict={takersFreeResponseDict}
                    eventInFocus={this.state.eventInFocus}
                    title={this.state.eventInFocus.getSurveyNameStripped()}
                    surveyTakers={this.state.surveyTakers}
                    createdTimeSinceEpoch={this.state.eventInFocus.createdTimeSinceEpoch}
                    itemLabel="Item"
                    itemsLabel="Items"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="swytchbackModalFooter">
                <SwytchbackButton style={{ width: '50%' }} onClick={this.closeExportDataModal}>Done</SwytchbackButton>
              </Modal.Footer>
            </Modal>
          }
          {this.state.showFilterModal === true &&
            <FilterByItemsModal
              showFilterModal={this.state.showFilterModal}
              resetContentFilter={this.resetContentFilter}
              closeFilterModal={this.closeFilterModal}
              swydget={this.state.eventInFocus}
              toggleContentFilter={this.toggleContentFilter}
              handleFilterByContentCheckboxChange={this.handleFilterByContentCheckboxChange}
            />

          }

          <FilterByResponsesModal
            showFilterRespondentModal={this.state.showFilterRespondentModal}
            resetRespondentFilter={this.resetRespondentFilter}
            closeFilterRespondentModal={this.closeFilterRespondentModal}
            user={this.user}
            swydget={this.state.eventInFocus}
            applyFilters={this.setFilters}
            updateFilters={this.updateFilters}
            handleCheckboxChange={this.handleCheckboxChange}
            handleFilterCheckboxChange={this.handleFilterCheckboxChange}
            handleFilterByResponsdentsSegmentCheckboxChange={this.handleFilterByResponsdentsSegmentCheckboxChange}
            toggleContentFilter={this.toggleContentFilter}
          />


          <Modal
            size="xl"
            show={this.state.showReportModal}
            onHide={this.closeReportModal}
            backdrop="static"
            enforceFocus={false}
          >
            <Modal.Header className="swytchbackModalHeader" closeButton>
              <Modal.Title className="swytchbackModalTitle" style={divModalTitle}>
                <div style={divModalTitle}>
                  <div>Report</div>

                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, margin: 0 }}>
              <div className="rightButtonContainer">
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <SwytchbackButton className={this.state.isUpdating ? "swytchbackInactiveButton" : "swytchbackActiveButton"} disabled={this.state.isUpdating} style={modalButtonStyle} onClick={handlePrint}>Save & Print</SwytchbackButton>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
              {this.state.showReportModal &&
                <div ref={el => (this.componentRef = el)}>
                  {this.generatePrintPreview()}
                </div>
              }
            </Modal.Body>
            <Modal.Footer className="swytchbackModalFooter">
              <ReactToPrint content={() => this.componentRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <SwytchbackButton className={this.state.isUpdating ? "swytchbackInactiveButton" : "swytchbackActiveButton"} disabled={this.state.isUpdating} style={modalButtonStyle} onClick={handlePrint}>Save & Print</SwytchbackButton>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
              <SwytchbackButton style={modalButtonStyle} onClick={this.closeReportModal}>Done</SwytchbackButton>
            </Modal.Footer>
          </Modal>



          <EventMembersModal
            showModal={this.state.showEventMembersModal}
            onHideAction={this.closeEventMembersModal}
          />

          <GetSurveyLinkModal showModal={this.state.showSurveyLinkModal} onHideAction={this.closeSurveyLink} eventInFocus={this.state.eventInFocus} />
          <ImportSegmentationModal
            showModal={this.state.showImportSegmentationModal}
            onHideAction={this.closeImportSegmentationModal}
            user={this.user}
            swydget={this.state.eventInFocus}
          />

          {/* <Modal show={this.state.showImportSegmentationModal} onHide={this.closeImportSegmentationModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Import Segmantation Mapping</Modal.Title>
              <Modal.Body>
                <ImportSegmentationModal user={this.user} swydget={this.state.eventInFocus}/>
              </Modal.Body>
              <Modal.Footer>
                <SwytchbackButton style={{width: '50%'}} onClick={this.closeImportSegmentationModal}>Done</SwytchbackButton>
              </Modal.Footer>
            </Modal.Header>
          </Modal> */}
          <Modal show={this.state.showCloneSwydgetModal} onHide={this.closeCloneSwydgetModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Cloning Swydget...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.cloneSwydgetMessage}
            </Modal.Body>
            <Modal.Footer>
              <SwytchbackButton style={{ width: '50%' }} onClick={this.closeCloneSwydgetModal}>Done</SwytchbackButton>
            </Modal.Footer>
          </Modal>

          <DeleteSwydgetModal
            showDeleteSwydgetModal={this.state.showDeleteSwydgetModal}
            closeDeleteSwydgetModal={this.closeDeleteSwydgetModal}
            swydgetName={this.state.eventInFocus.surveyName}
            handleDeleteSwydgetClicked={this.handleDeleteSwydgetClicked}
          />

          <SwydgetTakersModal
            user={this.user}
            swydget={this.state.eventInFocus}
            show={this.state.showGenerateWinnerModal}
            handleClose={this.closeGenerateWinnerModal}
          />

          <Modal show={this.state.showIntegrationFeedbackModal} onHide={this.closeIntegrationFeedbackModal} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{this.state.integrationFeedbackTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.integrationFeedbackMessage}
            </Modal.Body>
            <Modal.Footer>
              <SwytchbackButton style={{ width: 100 }} onClick={this.closeIntegrationFeedbackModal}>Close</SwytchbackButton>
            </Modal.Footer>
          </Modal>
          <SwydgetIntegrationsModal user={this.user} swydget={this.state.eventInFocus} onHide={this.closeSwydgetIntegrationsModal} show={this.state.showSwydgetIntegrationsModal} />
          <SnapshotManagementModal
            swydget={this.state.eventInFocus}
            showModal={this.state.showSnapshotMgtModal}
            onHideAction={this.closeSnapshotMgtModal}
            deleteAction={this.deleteSnapshot}
            updateSnapshotAction={this.updateSnapshot}
            saveSnapshotAction={this.saveSnapshot}
          />
          <Backdrop className={`${classes.backdrop}`} open={this.state.isLoadingStats}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
    }
    else {
      return (
        <div>
          <div>
            <Container>
              <Row style={headerRowStyle}>
                <Col md={6} style={toolbarStyle}>
                  <div style={titleStyle}>Not Authorized</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }
}

const ResultsWrapper = () => {

  const navigate = useNavigate();
  const params = useParams();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <Results navigationHandler={handleNavigation} params={params} />
}

export default ResultsWrapper