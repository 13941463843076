import React, { useState } from "react";
import { Button }  from 'react-bootstrap';
import { TextField, Select, MenuItem } from "@mui/material";
import classes from '../../../css/modules/BranchLogicManager.module.css';
import CardEditor from "../../../components/admin/CardEditor";
import SwytchbackRadioButtonGroup from "../controls/SwytchbackRadioButtonGroup";
import SkipRule from "../../../classes/SkipRule";
import BranchLogicStatement from "../../../classes/BranchLogicStatement";
import '../../../css/swytchbackModal.css';
import SwytchbackTextField from "../controls/SwytchbackTextField";
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackFrameButton from "../controls/SwytchbackFrameButton";


export default function StimulusBranchLogicManagerComponent(props) {

  const [localStimulus, setLocalStimulus] = useState(props.stimulus);
  const [optionTarget, setOptionTarget] = useState(null);
  const [parentWyshId, setParentWyshId] = useState("no_parent_wysh");

  const getCardHeight = () => {
    var h = window.innerHeight;
    var w = window.innerWidth;
    return h >= w ? 20 : 30;
  }

  const cardHeight = getCardHeight();
  const cardWidth = cardHeight * (2.5/3.5); 
  const cardHeightStyle = cardHeight + 'vh';
  const cardWidthStyle = cardWidth + 'vh';
  
      
  const imagePreviewStyle = {
    width: cardWidthStyle,
    height: cardHeightStyle,
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: '5%',
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    overflow: 'hidden'
  };

  


  // I AM PORTING ALL THE METHODS FROM BRANCH LOGIC MANAGER AND THEN STYLING!!!!


  const handleNextWyshChange = (idx, w, negation) => (evt) => {

    const stimulus = localStimulus;
    
    let skipRule = new SkipRule();
    skipRule.observedWyshId = stimulus.wyshId;
    skipRule.resultNormalized = stimulus.getWyshOptions()[idx] ? stimulus.getWyshOptions()[idx].resultNormalized : idx;
    skipRule.negation = negation;

    let bls = new BranchLogicStatement();
    bls.skipRules.push([skipRule]);
    if (evt.target.value === "surveyComplete" || evt.target.value === "blockComplete") {
      bls.nextWyshId = evt.target.value;
    }
    else {
      bls.nextWyshId = stimulus.getRemainingWyshes()[evt.target.value].wyshId;
    }

    stimulus.wyshRouter.addBranchLogicStatement(bls);

    displayNextOption(idx);

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const toggleNegationHandler = (option, evt) => {

    // Is there a BLS for this option?
    // if so, toggle the negation boolean
    // if not, add a new BranchLogicStatement
    const isChecked = evt.target.value ? (evt.target.value === 'true') : false;
    const stimulus = localStimulus;

    var blsExists = false;

    for (var bls of stimulus.wyshRouter.branchLogicStatements) {
      for (var skipRules of bls.skipRules) {
        for (var sr of skipRules) {
          if (option.resultNormalized === sr.resultNormalized) {
            blsExists = true;
            sr.negation = isChecked;
            stimulus.wyshRouter.addBranchLogicStatement(bls);
          }
        }
      }
    }

    if (blsExists === false) {
      bls = new BranchLogicStatement();
      bls.nextWyshId = stimulus.nextWysh ? stimulus.nextWysh.wyshId : stimulus.parentWysh ? "blockComplete" : "surveyComplete"
      let skipRule = new SkipRule();
      skipRule.observedWyshId = stimulus.wyshId;
      skipRule.resultNormalized = option.resultNormalized;
      skipRule.negation = isChecked;
      bls.skipRules.push([skipRule]);
      stimulus.wyshRouter.addBranchLogicStatement(bls);
    }

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const isResponseChecked = (stimulus, option) => {
    // default to true unless there is a skip rule for a given option that has its negation set
    for (var i = 0; i < stimulus.wyshRouter.branchLogicStatements.length; i++){
      for (var j = 0; j < stimulus.wyshRouter.branchLogicStatements[i].skipRules.length; j++) {
        for (var k = 0; k <  stimulus.wyshRouter.branchLogicStatements[i].skipRules[j].length; k++) {
          if (option.resultNormalized === stimulus.wyshRouter.branchLogicStatements[i].skipRules[j][k].resultNormalized) {
            return stimulus.wyshRouter.branchLogicStatements[i].skipRules[j][k].negation;
          }
        }
      }
    }
    return false;
  }

  const getNextWyshIndex = (stimulus, optionIndex) => {

    const resultNormalized = stimulus.getWyshOptions()[optionIndex] ? stimulus.getWyshOptions()[optionIndex].resultNormalized : optionIndex
    
    let remainingWyshes = stimulus.getRemainingWyshes();
    var nextWysh = stimulus.getNextWysh();

    let nextWyshId = stimulus.wyshRouter.getNextWysh(resultNormalized);
    if (nextWyshId && (nextWyshId === "surveyComplete" || nextWyshId === "blockComplete")) {
      return nextWyshId;
    }
    else if (nextWyshId) {
      nextWysh = stimulus.event.findWysh(nextWyshId);
    }
    
    let nwi = stimulus.getWyshIndex(nextWysh, remainingWyshes);

    if (nwi < 0) {
      return (stimulus.parentWysh) ? "blockComplete" : "surveyComplete"
    }

    return nwi
  }

  const displayNextOption = (idx) => {
    const nextWyshIndex = getNextWyshIndex(localStimulus, idx);
    const remainingWyshes = localStimulus.getRemainingWyshes();
    if (nextWyshIndex && nextWyshIndex === "surveyComplete") {
      setOptionTarget(null);
    }
    else if (nextWyshIndex && nextWyshIndex === "blockComplete") {
      setOptionTarget(null);
    }
    else if (nextWyshIndex !== null) {
      setOptionTarget(remainingWyshes[nextWyshIndex]);
    }
  };

  const handleMouseLeave = (idx) => {
    setOptionTarget(null);
  };
    

  const getNextWyshPreview = () => {
    if (optionTarget) {

      console.log(optionTarget.isBlock());
      
      const imageStyle = {
        width: cardWidthStyle,
        height: cardHeightStyle,
        objectFit: optionTarget.product.styles.objectFit
      };
  
      const product = optionTarget.product;
      return  <div style={imagePreviewStyle}>
                <div style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                  height: cardHeightStyle,
                  width: cardWidthStyle,
                  background: 'linear-gradient(#0167EC, #00B6F9)',
                  overflow: 'hidden'
                }}>
                  {(product.getSecureImageUrl() && product.getSecureImageUrl() !== "") &&
                    <img src={product.getSecureImageUrl()} style={imageStyle} alt='edit content' />
                  }
                  {CardEditor.toMarkup(product, cardHeight, optionTarget.isBlock() === true ? optionTarget.product.name : "")}
                </div>
              </div>
    }
    else {
      return <div style={imagePreviewStyle}>
        <div style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          height: cardHeightStyle,
          width: cardWidthStyle,
          background: 'linear-gradient(#0167EC, #00B6F9)',
          overflow: 'hidden'
        }}>
          
        </div>
      </div>
    }
  }


  const clear = () => {
    const stimulus = localStimulus;
    stimulus.wyshRouter.clearAllBranchLogicStements();

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const generateChoiceFields = (readOnly) => {

    const stimulus = localStimulus;

    if (stimulus === null || stimulus === undefined) {
      return (
        <div></div>
      );
    }

    if (stimulus.getWyshOptions().length === 0) {
      return (
        <div>
          Skip rules not supported for this stimuli type
        </div>
      );
    }

    let allWyshes = stimulus.getAllSiblingWyshes();
    let remainingWyshes = stimulus.getRemainingWyshes();
    let myIndex = stimulus.getWyshIndex(stimulus, allWyshes);

    return (

      <div className="divRowStyle" style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          {stimulus.getWyshOptions().map((option, idx) => (
            <div key={idx} className="divColumnStyle" style={{marginBottom: 10}}>
              <SwytchbackTextField
                className="bigDog"
                id={"skip-rules-prompt-input" + idx}
                style={{margin: '0px 0px 6px 0px', padding: 0, width: '100%'}}
                value={stimulus.questionType && stimulus.questionType !== "binary" ? (idx + 1) + " - " + option.resultLiteral : option.resultLiteral}
                margin="normal"
                variant="outlined"
              />
              <div key={idx} className={"rowContainerSpaced " + `${classes.marginBottom}`}>
                <SwytchbackRadioButtonGroup
                  ifSelected={isResponseChecked(stimulus, option)}
                  ifSelectedChanged={(e) => toggleNegationHandler(option, e)}
                />
                <SwytchbackSelect
                    onMouseEnter={() => displayNextOption(idx)}
                    onMouseLeave={() => handleMouseLeave(idx)}
                    style={{width: 160}}
                    disabled={false}
                    inputProps={{ IconComponent: () => null }}
                    id={"skip-to-select-" + idx}
                    value={getNextWyshIndex(stimulus, idx)}
                    onChange={handleNextWyshChange(idx, stimulus, isResponseChecked(stimulus, option))}>
                    {remainingWyshes.map((stimulus, wyshIndex) => (
                      <MenuItem key={wyshIndex} value={wyshIndex}>Q{myIndex + wyshIndex + 2}:</MenuItem>
                    ))}
                    { stimulus.parentWysh &&
                      <MenuItem key={"blockComplete"} value={"blockComplete"}>Block Complete</MenuItem>
                    }
                    <MenuItem key={"surveyComplete"} value={"surveyComplete"}>Survey Complete</MenuItem>
                  </SwytchbackSelect>  
              </div>
            </div>
            
          ))}
        </div>
      </div>  
    )
  }
  
  return (
    <div id="stimulusVerbatimComponent" className="rowContainer fullWidth">
      <div className="divColumnStyle bigDog">
        <div className="widget fullWidth standardModalHeight mainPane">
          <div className="titleContainer">
            <div className='title'>
              Skip To:
            </div>
            <div className='instruction'>
              Select a Skip To destination for each response option
            </div>
          </div>  
          <div className='content'>
            <div className="swytchbackAdminControl">
              {generateChoiceFields()}
            </div>
            <div className={`${classes.rightJustifiedContainer}`}>
              <SwytchbackFrameButton style={{width: 160, marginTop:20}} onClick={clear}>Clear Logic</SwytchbackFrameButton>
            </div>
          </div>
        </div>    
      </div>
      <div className="columnCenteredContainer rightPane standardModalHeight">
        <div className="widget">
          <div className="titleContainer">
            <div className='title' style={{marginBottom: 10}}>
              Preview:
            </div>
          </div>  
          <div className='content marginBottom'>
            {getNextWyshPreview()}
          </div>
        </div>  
      </div>
    </div>
  );
}