import React, { useState } from 'react';
import { Switch, MenuItem } from '@mui/material';
import Decision from '../../../classes/Decision';
import Wysh from '../../../classes/Wysh';
import "../../../css/swytchbackTable.css";
import SwytchbackSelect from '../controls/SwytchbackSelect';
import SwytchbackTextField from '../controls/SwytchbackTextField';

const VerbatimDecisionsTable = ({ decisionsWithFreeResponses, toggleHandler, selectedFreeResponseDecisions }) => {
  const [sortOrder, setSortOrder] = useState("longest");
  const [filterTerm, setFilterTerm] = useState("");

  const handleSortOrderChange = (evt) => {
    setSortOrder(evt.target.value);
  };

  const handleFilterTermChange = (evt) => {
    setFilterTerm(evt.target.value);
  };

  const getSortMethod = () => {
    switch (sortOrder) {
      case "highest":
        return Decision.compareByResultNormalizedDesc;
      case "lowest":
        return Decision.compareByResultNormalizedAsc;
      case "newest":
        return Decision.compareByCreationDateDesc;
      case "oldest":
        return Decision.compareByCreationDateAsc;
      case "longest":
        return Decision.compareByFreeResponseLengthDesc;
      default:
        return Decision.compareByResultNormalizedDesc;
    }
  };

  let filteredDecArray = [];
  let decisionsWithFreeResponsesCount = 0;

  if (decisionsWithFreeResponses) {
    decisionsWithFreeResponsesCount = decisionsWithFreeResponses.length;
    decisionsWithFreeResponses.sort(getSortMethod())

    filteredDecArray = decisionsWithFreeResponses.filter(decision => {
      if (filterTerm === "") {
        return true;
      }
      else if (decision.freeResponseAnswers && decision.freeResponseAnswers.length > 0) {
        if (decision.freeResponseAnswers[0].toLowerCase().indexOf(filterTerm.toLowerCase()) > -1) {
          return true;
        }
      }
    });
  }


  const generateRows = (decisionsWithFreeResponses, toggleHandler, selectedFreeResponseDecisions) => {
    const limit = 200;
    const rows = [];

    if (decisionsWithFreeResponses) {
      for (let i = 0; i < decisionsWithFreeResponses.length && i < limit; i++) {
        const created = new Date(decisionsWithFreeResponses[i].decisionCreatedTimestampStr);
        const createdStr = `${created.getMonth() + 1}/${created.getDate()}/${created.getFullYear()}`;
        const d = decisionsWithFreeResponses[i];
        rows.push(
          <tr key={i}>
            <td className="tdLeft">{decisionsWithFreeResponses[i].resultLiteral}</td>
            <td className="tdLeft">{decisionsWithFreeResponses[i].freeResponseAnswers[0]}</td>
            <td className="tdRight">{createdStr}</td>
            {toggleHandler && selectedFreeResponseDecisions &&
              <td className="tdRight">
                <Switch
                  color="primary"
                  checked={Wysh.containsDecision(d, selectedFreeResponseDecisions)}
                  onChange={(e) => toggleHandler(d, e)}
                />
              </td>
            }
          </tr>
        );
      }
    }

    return rows;
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', height: 80, padding: 0, marginBottom: 10 }}>
        <label style={{ fontSize: 14, fontWeight: 400, color: '#ffffff' }}>SORT BY</label>
        <SwytchbackSelect
          style={{ color: '#ffffff' }}
          disabled={false}
          inputProps={{ IconComponent: () => null }}
          id="font-family-select"
          value={sortOrder}
          onChange={handleSortOrderChange}
        >
          <MenuItem key={"highest"} value={"highest"}>Highest Scores</MenuItem>
          <MenuItem key={"lowest"} value={"lowest"}>Lowest Scores</MenuItem>
          <MenuItem key={"newest"} value={"newest"}>Most Recent</MenuItem>
          <MenuItem key={"oldest"} value={"oldest"}>Oldest</MenuItem>
          <MenuItem key={"longest"} value={"longest"}>Longest</MenuItem>
        </SwytchbackSelect>
      </div>
      <SwytchbackTextField
        id="free-response-term-filter"
        label="Search by Term"
        value={filterTerm}
        onChange={handleFilterTermChange}
        margin="normal"
        variant="outlined"
      />

      <div style={{ color: '#ffffff', backgroundColor: 'transparent' }}>
        <div style={{ paddingBottom: 10, paddingTop: 30 }}>Displaying: {filteredDecArray.length} of {decisionsWithFreeResponsesCount}</div>
        <table className="adminTable">
          <thead>
            <tr className="tHeadTr">  
              <th className="tHeadLeft">Answer</th>
              <th className="tHeadLeft">Response</th>
              <th className="tHeadRight">Date</th>
              {toggleHandler &&
                <th className="tHeadRight"></th>
              }
            </tr>
          </thead>
          <tbody>
            {generateRows(filteredDecArray, toggleHandler, selectedFreeResponseDecisions)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerbatimDecisionsTable;
