import React from 'react';
import { Modal, Button, FormGroup, FormControl }  from 'react-bootstrap';
import "../../css/swytchbackStyles.css";
import "../../css/swytchbackModal.css";
import User from "../../classes/User";
import LoginStore from "../../stores/LoginStore";
import SwytchbackTextField from '../../hooks/admin/controls/SwytchbackTextField';



class InviteFriendFormModal extends React.Component {

  constructor() {
    super();
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.inviteFriend = this.inviteFriend.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.cleanUp = this.cleanUp.bind(this);

    this.invitationStatus = "";
    // this.inviteFriendHandler = null;

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      invitationStatus: "",
      loggedInUser: User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"))
    };
  }

  componentDidMount() {
    LoginStore.on("loginStore_user_logged_in", this.setLoggedInUser);
  }

  componentWillUnmount() {
    LoginStore.removeListener("loginStore_user_logged_in", this.setLoggedInUser);
  }

  setLoggedInUser() {
    this.setState({
      loggedInUser: LoginStore.getLoggedInUser()
    });
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value})
  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value})
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value})
  }

  clearForm() {
    this.setState({
      email: "",
      firstName: "",
      lastName: "",
      invitationStatus: ""
    });
    this.invitationStatus = "";
  }

  cleanUp(onHideAction) {
    // Execute clean up relevant to THIS modal:
    // Some method

    // Execute the parent's hide action
    onHideAction();
  }

  inviteFriend(inviteFriendHandler) {

    this.setState({invitationStatus: "Invitation sent to " + this.state.email});

    inviteFriendHandler(this.state.email, this.state.firstName, this.state.lastName);

  }

  shouldPrimaryActionBeDisabled() {
    if (this.state.email.length > 0 && this.state.firstName.length > 0 && this.state.lastName.length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  render() {

    const { inviteFriendHandler } = this.props;

    // trigger to display the modal
    const { showModal } = this.props;

    // The method to execute when the modal is closed or cancelled manually
    const { onHideAction } = this.props;


    let containerStyle = {
      backgroundColor: '#ffffff'
    };

    let titleStyle = {
      fontWeight: 500,
      fontSize: 20,
      paddingTop: 20,
      paddingLeft: 20
    }

    let formContainerDivStyle = {
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let fieldsDivStyle = {
      width: '90%',
      marginTop: 30,
      marginBottom: 0
    };

    let inputStyle = {
      height: 50,
      backgroundColor: '#f1f2f6',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    };

    let inviteStatusDivStyle = {
      marginTop: 12,
      marginBottom: 12
    };

    let buttonStyle = {
      margin: 5
    };

    let modalHeaderStyle = {
      backgroundColor: '#eceef0',
      color: '#606368',
      border: 0
    };

    let sectionDivStyle = {
      margin: 20
    };

    let modalFooterStyle = {
      backgroundColor: '#eceef0',
      color: '#606368',
      border: 0
    }


    return (
      <Modal 
        show={showModal} 
        onHide={() => this.cleanUp(onHideAction)}
        enforceFocus={false} 
        backdrop="static"
      >
        <Modal.Header className="swytchbackModalHeader" closeButton>
          <Modal.Title className="swytchbackModalTitle">Invite Colleagues</Modal.Title>
        </Modal.Header>
        <Modal.Body className="swytchbackModalBody coloredBackground">
          <div className="hookFullPaneContainer swytchbackGradientPane">
            <div className="fullPaneContainer">
              <div className="contentContainer">
                <div className='columnCenteredContainer fullWidthPane standardModalHeight'>
                  <SwytchbackTextField
                    id="email"
                    style={{width: '70%'}}
                    label="Enter Email"
                    value={this.state.email}
                    placeholder="Email..."
                    onChange={this.handleEmailChange}
                    margin="normal"
                    variant="outlined"
                  />
                  <SwytchbackTextField
                    id="firstName"
                    style={{width: '70%'}}
                    label="Enter First Name"
                    value={this.state.firstName}
                    placeholder="First Name..."
                    onChange={this.handleFirstNameChange}
                    margin="normal"
                    variant="outlined"
                  />
                  <SwytchbackTextField
                    id="lastName"
                    style={{width: '70%'}}
                    label="Enter Last Name"
                    value={this.state.lastName}
                    placeholder="Last name..."
                    onChange={this.handleLastNameChange}
                    margin="normal"
                    variant="outlined"
                  />
                  <div style={inviteStatusDivStyle}>{this.state.invitationStatus}</div>
                </div>
              </div>
            </div>
          </div>  
        </Modal.Body>
        <Modal.Footer className="swytchbackModalFooter">
          <Button className="swytchbackActiveButton" style={{width: 130, marginRight: 6}} onClick={() => this.inviteFriend(inviteFriendHandler)}>
            Invite
          </Button>
          <Button className="swytchbackActiveButton" style={{width: 130}} onClick={this.clearForm}>
            Clear
          </Button>
        </Modal.Footer>
      </Modal>




    );
  }
}

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <FormControl {...props} />
    </FormGroup>
  );
}

export default InviteFriendFormModal;
