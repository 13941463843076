import Wysh from './Wysh';

export default class WyshWordFrequency
{
  constructor() {
    this.word = "";
    this.count = 0.0;
    this.totalResultsNormalized = 0.0;
    this.weight = 0.0;
    this.fontSize = 24;
    this.fontWeight = 900;
  }

  static compareByNameAsc(a, b) {
    return a.word.localeCompare(b.word);
  }

  static compareByNameDesc(a, b) {
    return b.word.localeCompare(a.word);
  }

  static compareByCountAsc(a, b) {
    return a.count - b.count;
  }

  static compareByCountDesc(a, b) {
    return b.count - a.count;
  }

  static generateWordFrequencyArray(wfa) {

    // WFA:
    // - count
    // - total_svd_result_normalized
    // - word

    if (wfa === null || wfa === undefined) {
      return []
    }

    var wfaWeighted = []
    var totalCount = 0;

    for (var i = 0; i < wfa.length; i++) {
      for (let key in wfa[i]) {
        totalCount += wfa[i]['count'];
      }
    }

    var fontSize = 24;

    for (i = 0; i < wfa.length; i++) {
      var wordFreq = new WyshWordFrequency();
      wordFreq.word = wfa[i]['word'];
      wordFreq.count = wfa[i]['count'];
      wordFreq.totalResultsNormalized = wfa[i]['total_svd_result_normalized'];
      wordFreq.weight = wordFreq.count * 100 / totalCount;
      wordFreq.fontSize = fontSize;
      wordFreq.fontWeight = Wysh.determineFontWeight(wordFreq.weight * 120);
      wfaWeighted.push(wordFreq);

      fontSize = fontSize * 0.99;
    }

    return wfaWeighted;
  }

  static generateWordFrequencyDict(wordFreqDict) {

    var wfd = {};

    for (var productId in wordFreqDict) {
      wfd[productId] = WyshWordFrequency.generateWordFrequencyArray(wordFreqDict[productId]);
    }

    return wfd;
  }

  static getCompositeWordFrequencyArray(wyshes) {

    var compositeWfa = [];
    var wfMap= new Map();

    for (var i = 0; i < wyshes.length; i++) {
      if (wyshes[i].wordFrequencyArray.length > 0) {
        for (var j = 0; j < wyshes[i].wordFrequencyArray.length; j++) {
          if (wfMap.has(wyshes[i].wordFrequencyArray[j].word)) {
            var wfObject = wfMap.get(wyshes[i].wordFrequencyArray[j].word);
            wfObject.count += wyshes[i].wordFrequencyArray[j].count;
            wfObject.total_svd_result_normalized += wyshes[i].wordFrequencyArray[j].totalResultsNormalized;
          }
          else {
            wfObject = {
              word: wyshes[i].wordFrequencyArray[j].word,
              count: wyshes[i].wordFrequencyArray[j].count,
              total_svd_result_normalized: wyshes[i].wordFrequencyArray[j].totalResultsNormalized
            };
            wfMap.set(wyshes[i].wordFrequencyArray[j].word, wfObject);
          }
        }
      }
    }

    for (let wfObject of wfMap.values()) {
      compositeWfa.push(wfObject);
    }

    return WyshWordFrequency.generateWordFrequencyArray(compositeWfa);
  }

  static getTooltip(word, wordFrequencyArray) {

    var normalizedResult = 0.0;

    var wordFreq = null;
    for (var i = 0; i < wordFrequencyArray.length; i++) {
      if (wordFrequencyArray[i].word.toLowerCase() === word.text.toLowerCase()) {
        wordFreq = wordFrequencyArray[i];
        break;
      }
    }

    normalizedResult = wordFreq.totalResultsNormalized/wordFreq.count;

    return "likes: " + wordFreq.totalResultsNormalized + ", count: " + wordFreq.count + ", pct: " + (normalizedResult * 100).toFixed(1) + "%";
  }
}
