import Wysh from "./Wysh";
import Decision from "./Decision";
import User from "./User";
import WyshMeProduct from "./WyshMeProduct";
import OrderedWyshList from "./OrderedWyshList";
import WyshWordFrequency from "./WyshWordFrequency";
import TakerData from "./TakerData";
import SnapshotContainer from "./SnapshotContainer";
import Filter from "./Filter";
import * as Utilities from "../services/Utilities";
import swytchbackLogo from '../SwytchbackLogoBox-500.png';
import BlockTypes from "./BlockTypes";


export default class Event {
  constructor() {
    this.eventId = "";
    this.creatorMid = "";
    this.surveyName = "";
    this.swydgetState = "active"; // active, retired, archived, deleted
    this.title = "";
    this.frontImage = "";
    this.defaultPrompt = "";
    this.description = "";
    this.previewTitle = "";
    this.previewDescription = "";
    this.published = false;
    this.wyshesCount = 0;
    this.newestWysh = new Wysh();
    this.creator = new User();
    this.createdTimeSinceEpoch = 0;
    this.updatedTimeSinceEpoch = 0;
    this.rewardText = '';
    this.rewardUrl = '';
    this.contestPrizeText = '';
    this.callToActionInstructionText = '';
    this.callToActionSubmitButtonText = '';
    this.coverPhotoUrl = '';
    this.wallpaperUrl = '';
    this.companyLogoUrl = '';
    this.defaultProduct = new WyshMeProduct();
    this.getStartedText = 'Get Started';
    this.tosLink = '';
    this.privacyLink = '';
    this.rewardButtonText = "Tap Here";
    // this.requestDataJson = {};
    this.wyshes = [];
    this.invitees = [];
    this.orderedWyshList = new OrderedWyshList();
    this.surveyTakerCount = 0;
    this.surveyTakerCompletedCount = 0;
    this.decisionsByMidDict = {};
    this.decisionsByMidDictWithDupes = {};
    this.decisionsByMidArray = [];
    this.allDecisionsArray = [];
    this.swydgetTakersArray = [];
    this.metaDataArray = [];
    this.respondentRoster = [];
    this.contentCatalog = [];
    this.randomize = false;
    this.showResults = false;
    this.showRecommendations = false;
    this.showSwytchbackTOS = true;
    this.locationEnabled = false;
    this.surveyCompletionCount = 0;
    this.issueSubGroups = false;
    this.subGroupCount = 0;
    this.limitFreeResponse = false;
    this.limitFreeResponseCount = 0;
    this.surveyType = "";
    this.options = [];
    this.binaryIconSetID = "";
    this.scalarDescription = "Drag slider below";
    this.mcSingleInstruction = "Select an answer";
    this.mcMultipleInstruction = "Select your answers";
    this.socialSharingChannels = [];
    this.attributeKeysArray = [];
    this.attributesMap = new Map();
    this.filter = new Filter(this);
    this.respondentFilterRequiredWyshes = [];
    this.globalAttributesArray = [];
    this.geolocationData = {};
    this.freeResponseAnswersByProduct = {};
    this.completionPct = 0.0;
    this.completionCount = 0;
    this.firstCompletionDate = new Date();
    this.lastCompletionDate = new Date();
    this.authorPrivacyAcceptedPct = 0.0;
    this.swytchbackPrivacyAcceptedPct = 0.0;
    this.swytchbackTosAcceptedPct = 0.0;
    this.avgDurationInSeconds = 0.0;
    this.segmentIdCountMap = {};
    this.compositeWordFrequencyArray = [];
    this.takerData = new TakerData();
    this.snapshotContainer = new SnapshotContainer();
  }

  import(snapshot) {
    if (snapshot) {
      this.filter.import(snapshot);
      if (snapshot.completionInfo) {
        this.firstCompletionDate = snapshot.completionInfo.getFirstCompletionDate();
        this.lastCompletionDate = snapshot.completionInfo.getLastCompletionDate();
        this.completionPct = snapshot.completionInfo.completionPct;
        this.completionCount = snapshot.completionInfo.completionCount;
        this.segmentIdCountMap = snapshot.completionInfo.segmentIdCounts
      }

      // apply snapshot data to Swydget wyshes
      for (var i in this.wyshes) {
        this.wyshes[i].applySnapshot(snapshot);
      }
    }
  }

  stripLeadingZeroes(term) {
    var nonZeroFound = false;
    var countToTrimFromMid = 0;
    for (var i = 0; i < term.length && nonZeroFound === false; i++) {
      if (term.charAt(i) !== "0") {
        nonZeroFound = true;
      }
      else {
        countToTrimFromMid += 1;
      }
    }

    return term.slice(countToTrimFromMid);
  }

  areDetailsLoaded() {
    if (Object.keys(this.decisionsByMidDict).length > 0) {
      return true;
    }

    return false;
  }

  generateBlockId() {

    console.log("REMOVE!!!");

    const seen = new Set();

    this.orderedWyshList.wyshes.filter(wysh => {
      const hash = wysh.product.productId;

      if (wysh.isBlock() === false) {
        return false;
      }

      if (seen.has(hash)) {
        return false;
      }
      seen.add(hash);
      return true;
    });

    var blockIndex = 0;
    var blockId = "block-id:" + blockIndex;

    for (var i = 0; seen.has(blockId); i++) {
      blockIndex += 1;
      blockId = "block-id:" + blockIndex;
    }

    return blockId;
  }

  generateSwydgetUrl() {
    var port = '';
    if (window.location.port !== '') {
      port = ":" + window.location.port;
    }

    var eventIdArg = this.eventId;
    if (Utilities.endsWith(eventIdArg, "#") === true || Utilities.endsWith(eventIdArg, "%23") === true) {
      eventIdArg = eventIdArg.slice(0, -1); // "12345.0"
    }

    var workStr = eventIdArg;
    workStr = workStr.replace("evt.", "");
    workStr = this.stripLeadingZeroes(workStr);

    var mid = workStr.substring(0, workStr.indexOf("-"));

    workStr = workStr.slice(mid.length + 1);

    var eid = this.stripLeadingZeroes(workStr);

    if (eid.length === 0) {
      eid = "0";
    }

    var protocol = "https://"
    if (window.location.hostname === "localhost") {
      protocol = "http://";
    }

    return protocol + window.location.hostname + port + "/swydget/" + mid + "/" + eid;
  }

  initFromJson(eventJson) {

    if (eventJson["_id"]) {
      this.eventId = eventJson["_id"];
    }

    if (eventJson["event"] && eventJson["event"]["created_by"]) {
      this.creatorMid = eventJson["event"]["created_by"];
    }

    if (eventJson["title"]) {
      this.title = eventJson["title"];
    }

    if (eventJson["front_image"]) {
      this.frontImage = eventJson["front_image"];
    }

    if (eventJson.event && eventJson.event.default_prompt) {
      this.defaultPrompt = eventJson.event.default_prompt;
    }

    if (eventJson.event && eventJson.event.description) {
      this.description = eventJson.event.description;
    }

    if (eventJson.event && eventJson.event.preview_title) {
      this.previewTitle = eventJson.event.preview_title;
    }

    if (eventJson.event && eventJson.event.preview_description) {
      this.previewDescription = eventJson.event.preview_description;
    }

    if (eventJson["event"] && eventJson["event"]["preview_title"]) {
      this.previewTitle = eventJson["event"]["preview_title"];
    }

    if (eventJson["event"] && eventJson["event"]["preview_description"]) {
      this.previewDescription = eventJson["event"]["preview_description"];
    }

    if (eventJson["event"] && eventJson["event"]["survey_name"]) {
      this.surveyName = eventJson["event"]["survey_name"];
    }

    if (eventJson["wyshes-count"]) {
      this.wyshesCount = eventJson["wyshes-count"];
    }

    if (eventJson["event"] && eventJson["event"]["survey_type"]) {
      this.surveyType = eventJson["event"]["survey_type"];
    }

    if (eventJson["event"] && eventJson["event"]["swydget_state"]) {
      this.swydgetState = eventJson["event"]["swydget_state"];
    }

    if (eventJson["event"] && eventJson["event"]["options"]) {
      this.options = eventJson["event"]["options"];
    }

    if (eventJson["event"] && eventJson["event"]["binary_icon_set_id"]) {
      this.binaryIconSetID = eventJson["event"]["binary_icon_set_id"];
    }

    if (eventJson["event"] && eventJson["event"]["scalar_description"]) {
      this.scalarDescription = eventJson["event"]["scalar_description"];
    }

    if (eventJson["event"] && eventJson["event"]["mc_single_instruction"]) {
      this.mcSingleInstruction = eventJson["event"]["mc_single_instruction"];
    }

    if (eventJson["event"] && eventJson["event"]["mc_multiple_instruction"]) {
      this.mcMultipleInstruction = eventJson["event"]["mc_multiple_instruction"];
    }

    if (eventJson["event"] && eventJson["event"]["_created"]) {
      this.createdTimeSinceEpoch = eventJson["event"]["_created"];
    }

    if (eventJson["event"] && eventJson["event"]["_updated"]) {
      this.updatedTimeSinceEpoch = eventJson["event"]["_updated"];
    }

    if (eventJson["creator_contact"]) {
      this.creator = User.createUserFromJson(eventJson["creator_contact"]);
    }

    if (eventJson["event"] && eventJson["event"]["published"]) {
      this.published = eventJson["event"]["published"];
    }

    if (eventJson["event"] && eventJson["newest_wysh"]) {
      this.newestWysh = Wysh.createFromJson(eventJson["newest_wysh"]);
    }

    if (eventJson["event"] && eventJson["event"]["reward_text"]) {
      this.rewardText = eventJson["event"]["reward_text"];
    }

    if (eventJson["event"] && eventJson["event"]["contest_prize_text"]) {
      this.contestPrizeText = eventJson["event"]["contest_prize_text"];
    }

    if (eventJson["event"] && eventJson["event"]["call_to_action_instructions_text"]) {
      this.callToActionInstructionText = eventJson["event"]["call_to_action_instructions_text"];
    }

    if (eventJson["event"] && eventJson["event"]["call_to_action_submit_button_text"]) {
      this.callToActionSubmitButtonText = eventJson["event"]["call_to_action_submit_button_text"];
    }

    if (eventJson["event"] && eventJson["event"]["reward_url"]) {
      this.rewardUrl = eventJson["event"]["reward_url"];
    }

    if (eventJson["event"] && eventJson["event"]["cover_photo_url"]) {
      this.coverPhotoUrl = eventJson["event"]["cover_photo_url"];
    }

    if (eventJson["event"] && eventJson["event"]["wallpaper_url"]) {
      this.wallpaperUrl = eventJson["event"]["wallpaper_url"];
    }

    if (eventJson["event"] && eventJson["event"]["company_logo_url"]) {
      this.companyLogoUrl = eventJson["event"]["company_logo_url"];
    }

    if (eventJson.event && eventJson.event.default_product) {
      this.defaultProduct = WyshMeProduct.createFromJson(eventJson.event.default_product);
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("randomize")) {
      this.randomize = eventJson["event"]["randomize"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("show_results")) {
      this.showResults = eventJson["event"]["show_results"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("location_enabled")) {
      this.locationEnabled = eventJson["event"]["location_enabled"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("show_recommendations")) {
      this.showRecommendations = eventJson["event"]["show_recommendations"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("show_swytchback_tos")) {
      this.showSwytchbackTOS = eventJson["event"]["show_swytchback_tos"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("issue_sub_groups")) {
      this.issueSubGroups = eventJson["event"]["issue_sub_groups"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("sub_group_count")) {
      this.subGroupCount = eventJson["event"]["sub_group_count"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("limit_free_response")) {
      this.limitFreeResponse = eventJson["event"]["limit_free_response"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("limit_free_response_count")) {
      this.limitFreeResponseCount = eventJson["event"]["limit_free_response_count"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("get_started_text")) {
      this.getStartedText = eventJson["event"]["get_started_text"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("social_sharing_channels")) {
      this.socialSharingChannels = eventJson["event"]["social_sharing_channels"];
    }


    if (eventJson["event"] && eventJson["event"].hasOwnProperty("tos_link")) {
      this.tosLink = eventJson["event"]["tos_link"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("privacy_link")) {
      this.privacyLink = eventJson["event"]["privacy_link"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("reward_button_text")) {
      this.rewardButtonText = eventJson["event"]["reward_button_text"];
    }

    if (eventJson["event"] && eventJson["event"].hasOwnProperty("invitees")) {
      // create the users and place them in the invitees array
      let inviteesArray = eventJson["event"].invitees;

      for (var i = 0; i < inviteesArray.length; i++) {
        this.invitees.push(User.createUserFromJson(inviteesArray[i]));
      }
    }

    if (eventJson.taker_data) {
      this.takerData = TakerData.createFromJson(eventJson.taker_data);
    }

    if (this.randomize === true) {
      this.orderedWyshList.isRandomized(true);
    }

    if (eventJson["wyshes"]) {
      var wyshesJson = eventJson["wyshes"];
      if (wyshesJson.constructor === Array) {
        for (i = 0; i < wyshesJson.length; i++) {
          var wyshGtins = wyshesJson[i]["wysh"]["product_info"]["gtins"];
          if (wyshGtins !== "wyshme-fake-eventlist-product") {
            var w = Wysh.createFromJson(wyshesJson[i]["wysh"]);
            if (wyshesJson[i]["wysh"]["wysh_id_prev"]) {
              var prevWysh = new Wysh();
              prevWysh.wyshId = wyshesJson[i]["wysh"]["wysh_id_prev"];
              // w.previousWysh = prevWysh;
            }
            if (wyshesJson[i]["wysh"]["wysh_id_next"]) {
              var nextWysh = new Wysh();
              nextWysh.wyshId = wyshesJson[i]["wysh"]["wysh_id_next"];
              w.nextWysh = nextWysh;
            }

            if (wyshesJson[i]["wysh"]["parent_wysh_id"]) {
              var parentWysh = new Wysh();
              parentWysh.wyshId = wyshesJson[i]["wysh"]["parent_wysh_id"];
              w.parentWysh = parentWysh;
            }

            w.event = this;
            this.wyshes.push(w);
          }
        }

        // reconcile wyshes
        for (i = 0; i < this.wyshes.length; i++) {
          if (this.wyshes[i].nextWysh) {
            this.wyshes[i].nextWysh = this.findWysh(this.wyshes[i].nextWysh.wyshId);
          }
          if (this.wyshes[i].parentWysh) {
            this.wyshes[i].parentWysh = this.findWysh(this.wyshes[i].parentWysh.wyshId);
          }
        }

        this.orderedWyshList.setWyshes(this.wyshes);
      }
    }

    if (eventJson.event && eventJson.event.snapshots_container) {
      this.snapshotContainer = SnapshotContainer.createFromJson(eventJson.event.snapshots_container, this);
    }


    this.generateAttributesMap();
  }

  toExportJsonString() {

    var myJsonObject = this.toJsonObject();


    return JSON.stringify(myJsonObject);
  }

  toJsonObject() {

    return {
      eventId: this.eventId,
      creatorMid: this.creatorMid,
      survey_name: this.surveyName,
      survey_type: this.surveyType,
      options: this.options,
      swydget_state: this.swydgetState,
      title: this.title,
      front_image: this.frontImage,
      default_prompt: this.defaultPrompt,
      description: this.description,
      preview_title: this.previewTitle,
      preview_description: this.previewDescription,
      wyshesCount: this.wyshesCount,
      published: this.published,
      binary_icon_set_id: this.binaryIconSetID,
      scalarDescription: this.scalarDescription,
      mcSingleInstruction: this.mcSingleInstruction,
      mcMultipleInstruction: this.mcMultipleInstruction,
      ordered_wysh_list: this.orderedWyshList.toJsonObject(),
      default_product: this.defaultProduct.toJsonObject(),
      get_started_text: this.getStartedText,
      front_image: this.frontImage,
      cover_photo_url: this.coverPhotoUrl,
      company_logo_url: this.companyLogoUrl,
      wallpaper_url: this.wallpaperUrl,
      reward_text: this.rewardText,
      reward_url: this.rewardUrl,
      reward_button_text: this.rewardButtonText,
      social_sharing_channels: this.socialSharingChannels,
      contest_prize_text: this.contestPrizeText,
      call_to_action_instructions_text: this.callToActionInstructionText,
      call_to_action_submit_button_text: this.callToActionSubmitButtonText,
      tos_link: this.tosLink,
      randomize: this.randomize,
      issue_sub_groups: this.issueSubGroups,
      sub_group_count: this.subGroupCount,
      limit_free_response: this.limitFreeResponse,
      limit_free_response_count: this.limitFreeResponseCount,
      show_results: this.showResults,
      location_enabled: this.locationEnabled,
      show_recommendations: this.showRecommendations,
      show_swytchback_tos: this.showSwytchbackTOS
    };
  }

  setFirstCompletionDate(dateStr) {
    this.firstCompletionDate = new Date(dateStr);
  }

  setLastCompletionDate(dateStr) {
    this.lastCompletionDate = new Date(dateStr);
  }

  getFirstCompletionDateStr() {
    return Decision.formatDate(this.firstCompletionDate);
  }

  getLastCompletionDateStr() {
    return Decision.formatDate(this.lastCompletionDate);
  }

  getDateUpdatedStr() {
    return Decision.formatDate(this.getDateUpdatedDate());
  }

  getDateUpdatedDate() {
    return new Date(this.updatedTimeSinceEpoch * 1000);
  }

  getAverageDurationStr() {

    if (this.avgDurationInSeconds === 0.0) {
      return "00:00";
    }

    var mins = Math.floor(this.avgDurationInSeconds / 60);
    var secs = Math.floor(this.avgDurationInSeconds % 60);

    return [String(mins).padStart(2, '0'), String(secs).padStart(2, '0')].join(':');
  }

  getDateCreatedStr() {
    return Decision.formatDate(this.getDateCreatedDate());
  }

  getDateCreatedDate() {
    return new Date(this.createdTimeSinceEpoch * 1000);
  }

  hasContestSetup() {
    if (this.contestPrizeText !== "" ||
      this.callToActionInstructionText !== "" ||
      this.callToActionSubmitButtonText !== "") {
      return true;
    }
    else {
      return false;
    }
  }

  isRetired() {
    if (this.swydgetState === "retired") {
      return true;
    }
    else {
      return false;
    }
  }

  isEligibleForPublishing() {

    // Check 1: must have some stimuli
    if (this.getOrderedWyshes().length === 0) {
      return false;
    }

    // Check 2: are all block requirements satisfied
    const allStimuli = this.orderedWyshList.getAllDescendantWyshes();
    for (const stimuli of allStimuli) {
      if (stimuli.isEligibleForPublishing() === false) {
        return false;
      }
    }

    return true;
  }

  getWyshes() {
    return this.orderedWyshList.getWyshesTwo(this.randomize, this.issueSubGroups, this.subGroupCount, this.limitFreeResponse, this.limitFreeResponseCount);
  }

  getBlocks() {

    var blocks = [];

    let allWyshes = this.getOrderedWyshes();

    for (var i = 0; i < allWyshes.length; i++) {
      if (allWyshes[i].isBlock() === true) {
        blocks.push(allWyshes[i]);
      }
    }

    return blocks;
  }

  getOrderedWyshes() {
    return this.orderedWyshList.getOrderedWyshes();
  }

  getFilteredOrderedWyshes() {
    return this.filter.filterContent(this.getOrderedWyshes());
  }

  findWyshByProductId(productId) {

    for (var i = 0; i < this.wyshes.length; i++) {
      if (this.wyshes[i].product.productId === productId) {
        return this.wyshes[i];
      }
    }

    return null;
  }

  findEquivalentWysh(wysh) {

    if (wysh) {
      for (var i = 0; i < this.wyshes.length; i++) {
        if (this.wyshes[i].product.productId === wysh.product.productId) {
          return this.wyshes[i];
        }
      }
    }

    return null;
  }

  /*
  _getWyshesByResult

  private method that returns the wyshes order by results lowest to highest in 2 sections:
  Used by public APIs for ASC and DESC.

  1. binary
  2. scalar

  each sub array will be sorted by result and then stiched back together
  */
  _getWyshesByResult(sortDirection) {

    var filteredOrderedWyshes = this.getFilteredOrderedWyshes();

    var binaryWyhes = [];
    var scalarWyshes = [];
    var allWyshes = [];

    for (var i = 0; i < filteredOrderedWyshes.length; i++) {
      if (filteredOrderedWyshes[i].questionType && filteredOrderedWyshes[i].questionType === "scalar") {
        scalarWyshes.push(filteredOrderedWyshes[i]);
      }
      else {
        binaryWyhes.push(filteredOrderedWyshes[i]);
      }
    }

    if (sortDirection === "asc") {
      binaryWyhes.sort(Wysh.compareAverageNormalizedAsc);
      scalarWyshes.sort(Wysh.compareAverageNormalizedAsc);
    }
    else {
      binaryWyhes.sort(Wysh.compareAverageNormalizedDesc);
      scalarWyshes.sort(Wysh.compareAverageNormalizedDesc);
    }

    allWyshes = binaryWyhes.concat(scalarWyshes);

    return allWyshes;
  }

  /*
  getWyshesByResultAsc

  return the wyshes order by results highest to lowest in 2 sections:

  1. binary
  2. scalar

  each sub array will be sorted by result and then stiched back together
  */
  getWyshesByResultAsc() {
    return this.orderedWyshList.getFilteredSortedWyshes("asc", this.filter);
  }

  /*
  getWyshesByResultAsc

  return the wyshes order by results highest to lowest in 2 sections:

  1. binary
  2. scalar

  each sub array will be sorted by result and then stiched back together
  */
  getWyshesByResultDesc() {
    return this.orderedWyshList.getFilteredSortedWyshes("desc", this.filter);
  }

  findWysh(wyshId) {

    var wysh = null;

    for (var i = 0; i < this.wyshes.length; i++) {
      if (this.wyshes[i].wyshId === wyshId) {
        wysh = this.wyshes[i];
        break;
      }
    }

    return wysh;
  }

  shuffle() {
    return this.orderedWyshList.shuffle();
  }

  shuffleTheseWyshes(wyshes) {
    var array = wyshes;

    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  _isLegacy() {
    for (const dec of this.allDecisionsArray) {
      if (dec.requestDataJson && dec.requestDataJson.session_timestamp) {
        if (dec.requestDataJson.session_timestamp > 0) {
          return false;
        }
      }
    }
    return true;
  }

  _scrubDecisions() {
    // SCRUB DECISIONS
    // TODO: MOVE ALL REPORTING STUFF TO THE BACKEND!!!

    var corruptMids = [];
    for (const dec of this.allDecisionsArray) {
      if (dec.requestDataJson && dec.requestDataJson.session_timestamp === 0) {
        if (corruptMids.includes(dec.takerMid) === false) {
          corruptMids.push(dec.takerMid);
        }
      }
    }

    this.allDecisionsArray = this.allDecisionsArray.filter(decision => {

      if (corruptMids.includes(decision.takerMid)) {
        return false
      }
      return true;
    });
  }

  setAllDecisionsArray(decisions) {

    this.allDecisionsArray = decisions;

    if (this._isLegacy() === false) {
      this._scrubDecisions();
    }

    this.decisionsByMidDict = this.getTakersWithDecisions();
    this.decisionsByMidDictWithDupes = this.getTakersWithDecisionsIncludeDupes();
  }

  clearAllDecisions() {
    this.decisionsByMidDict = {};
    this.decisionsByMidArray = [];
    this.allDecisionsArray = [];
    this.swydgetTakersArray = [];
    this.metaDataArray = [];
    this.respondentRoster = [];
    this.contentCatalog = [];
  }

  /**
  * getAllProductsArray
  *
  * Returns an unordered array of all the WyshMeProducts used in the Swydget.
  *
  * @param wysh
  * @return boolean passes
  */
  getAllProductsArray() {

    var products = [];

    for (var i = 0; i < this.wyshes.length; i++) {
      products.push(this.wyshes[i].product);
    }

    return products;
  }

  getTakersWithDecisions() {

    var decisions = this.allDecisionsArray;
    var takers = {};

    for (var i = 0; i < decisions.length; i++) {
      let dec = decisions[i];
      var wysh = this.findWyshByProductId(dec.productId);
      dec.wysh = wysh;

      if (takers[dec.takerMid]) {
        var takesArray = takers[dec.takerMid];
        takesArray.push(dec);
      }
      else {
        takers[dec.takerMid] = [dec];
      }
    }

    for (const mid in takers) {

      var earlistSessionTimestamp = 0;
      decisions = takers[mid];

      for (i = 0; i < decisions.length; i++) {
        if (decisions[i].requestDataJson && decisions[i].requestDataJson.session_timestamp) {
          if (earlistSessionTimestamp === 0) {
            earlistSessionTimestamp = decisions[i].requestDataJson.session_timestamp;
          }
          else if (decisions[i].requestDataJson.session_timestamp < earlistSessionTimestamp) {
            earlistSessionTimestamp = decisions[i].requestDataJson.session_timestamp;
          }
        }
      }

      // remove duplicates takes
      let uniqueDecisions = decisions.filter(decision => {
        if (decision.requestDataJson &&
          decision.requestDataJson.session_timestamp &&
          decision.requestDataJson.session_timestamp !== earlistSessionTimestamp) {
          return false;
        }
        return true;
      });

      takers[mid] = uniqueDecisions;
    }

    for (let mid in takers) {
      if (this.filter.areDecisionsSignificant(takers[mid]) === false) {
        takers[mid] = [];
      }
    }

    return takers;
  }

  getTakersWithDecisionsIncludeDupes() {

    var decisions = this.allDecisionsArray;
    var takersSessionDict = {};

    // Instead of a mapping of mids to an array of decisions,
    // we map mids to a dictionary of session timestamps to an array of decisions

    for (var i = 0; i < decisions.length; i++) {
      let dec = decisions[i];
      var wysh = this.findWyshByProductId(dec.productId);
      dec.wysh = wysh;

      if (takersSessionDict[dec.takerMid]) {
        if (takersSessionDict[dec.takerMid][dec.requestDataJson.session_timestamp]) {
          takersSessionDict[dec.takerMid][dec.requestDataJson.session_timestamp].push(dec)
        }
        else {
          takersSessionDict[dec.takerMid][dec.requestDataJson.session_timestamp] = [dec]
        }
      }
      else {
        takersSessionDict[dec.takerMid] = {}
        takersSessionDict[dec.takerMid][dec.requestDataJson.session_timestamp] = [dec]
      }
    }

    for (const mid in takersSessionDict) {
      for (const ts in takersSessionDict[mid]) {
        const decisionArray = takersSessionDict[mid][ts];
        if (this.filter.areDecisionsSignificant(decisionArray) === false) {
          takersSessionDict[mid][ts] = [];
        }
      }
    }

    return takersSessionDict;
  }

  findEmailForMid(mid) {
    if (this.swydgetTakersArray[mid] && this.swydgetTakersArray[mid].contact && this.swydgetTakersArray[mid].contact.keys.email) {
      return this.swydgetTakersArray[mid].contact.keys.email[0].v;
    }
    else {
      return "";
    }
  }

  _pushReportHeaders(wysh, indexLabel, takersDecisionsArrayHeaders, takersFreeResponseArrayHeaders, takersFreeResponseDict) {
    if (wysh) {
      if (wysh.questionType === "multiple-choice") {
        for (var i = 0; i < wysh.wyshOptions.length; i++) {
          // Kind of Kludgy: 99.0 is a special answer (none of the above) in Multuiple Choice. It should always be listed as its true value
          const resultNormalized = (wysh.wyshOptions[i].resultNormalized !== 99.0 && wysh.wyshOptions[i].resultNormalized !== 98.0) ? wysh.wyshOptions[i].resultNormalized + 1 : wysh.wyshOptions[i].resultNormalized
          takersDecisionsArrayHeaders.push("Q" + indexLabel + "_" + (resultNormalized));
        }
      }
      else {
        takersDecisionsArrayHeaders.push("Q" + indexLabel);
      }
      takersFreeResponseArrayHeaders.push("Question " + indexLabel, wysh.freeResponseQuestions[0]);
      takersFreeResponseDict[wysh.wyshId] = { "wysh": wysh, "question_number": indexLabel, "question": wysh.freeResponseQuestions[0], decisions: [] };
    }
  }

  _pushReportDecisions(wysh, decisionsArray, takerDecisionsByStimuliArray, takersFreeResponseByStimuliArray, takersFreeResponseDict) {

    if (wysh.parentWysh && (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) || wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE))) {
      let decision = this._findDecision(decisionsArray, wysh.product.productId);

      if (decision) {
        const resultNormalized = decision.score;
        takerDecisionsByStimuliArray.push(resultNormalized);
      }
      else {
        takerDecisionsByStimuliArray.push("");
      }
    }
    else if (wysh.questionType === "multiple-choice") {
      var decisions = this._findDecisions(wysh, decisionsArray);
      var resultNormalizedSet = new Set();
      for (var i = 0; i < decisions.length; i++) {
        resultNormalizedSet.add(decisions[i].resultNormalized);
      }

      for (i = 0; i < wysh.wyshOptions.length; i++) {
        if (resultNormalizedSet.has(wysh.wyshOptions[i].resultNormalized)) {
          // Kind of Kludgy: 99.0 is a special answer (none of the above) in Multuiple Choice. It should always be listed as its true value
          const resultNormalized = (wysh.wyshOptions[i].resultNormalized !== 99.0 && wysh.wyshOptions[i].resultNormalized !== 98.0) ? wysh.wyshOptions[i].resultNormalized + 1 : wysh.wyshOptions[i].resultNormalized
          takerDecisionsByStimuliArray.push(resultNormalized);
        }
        else {
          takerDecisionsByStimuliArray.push(0);
        }
      }

      if (wysh.freeResponseQuestions.length > 0) {
        if (decisions && decisions.length > 0 && decisions[0].freeResponseAnswers[0]) {
          takersFreeResponseByStimuliArray.push(decisions[0].resultLiteral, decisions[0].freeResponseAnswers[0]);
        }
        else {
          takersFreeResponseByStimuliArray.push("", "");
        }
      }

      if (decisions && decisions.length > 0 && takersFreeResponseDict[wysh.wyshId]) {
        takersFreeResponseDict[wysh.wyshId].decisions.push(decisions[0]);
      }
    }
    else {
      let decision = this._findDecision(decisionsArray, wysh.product.productId);

      if (decision) {
        const resultNormalized = (wysh.questionType !== "binary" && decision.resultNormalized !== 99.0 && decision.resultNormalized !== 98.0) ? decision.resultNormalized + 1 : decision.resultNormalized
        takerDecisionsByStimuliArray.push(resultNormalized);
      }
      else {
        takerDecisionsByStimuliArray.push("");
      }

      if (wysh.freeResponseQuestions.length > 0) {
        if (decision && decision.freeResponseAnswers[0]) {
          takersFreeResponseByStimuliArray.push(decision.resultLiteral, decision.freeResponseAnswers[0]);
        }
        else {
          takersFreeResponseByStimuliArray.push("", "");
        }
      }

      if (decision && takersFreeResponseDict[wysh.wyshId]) {
        takersFreeResponseDict[wysh.wyshId].decisions.push(decision);
      }
    }





    // if (longitude === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.longitude) {
    //   longitude = decision.requestDataJson.geolocation_info.longitude;
    // }
    //
    // if (latitude === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.latitude) {
    //   latitude = decision.requestDataJson.geolocation_info.latitude;
    // }
    //
    // if (accuracy === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.accuracy) {
    //   accuracy = decision.requestDataJson.geolocation_info.accuracy;
    // }
    //
    // TEMPORARILY DISABLE GEO
    // if (latitude) {
    //   takerDecisionsByStimuliArray.push(latitude);
    //   takerInfo.push(latitude);
    // }
    // else {
    //   takerDecisionsByStimuliArray.push("");
    //   takerInfo.push("");
    // }
    // if (longitude) {
    //   takerDecisionsByStimuliArray.push(longitude);
    //   takerInfo.push(longitude);
    // }
    // else {
    //   takerDecisionsByStimuliArray.push("");
    //   takerInfo.push("");
    // }
    // if (accuracy) {
    //   takerDecisionsByStimuliArray.push(accuracy);
    //   takerInfo.push(accuracy);
    // }
    // else {
    //   takerDecisionsByStimuliArray.push("");
    //   takerInfo.push("");
    // }
  }

  _generateGlobalQueryParamKeysSet() {

    var queryParamsKeysSet = new Set();

    for (var i = 0; i < this.allDecisionsArray.length; i++) {
      if (this.allDecisionsArray[i].requestDataJson && this.allDecisionsArray[i].requestDataJson.query_params) {
        for (var key in this.allDecisionsArray[i].requestDataJson.query_params) {
          queryParamsKeysSet.add(key);
        }
      }
    }

    return queryParamsKeysSet;

  }

  generateQuestionNumbersMap() {

    var labelMap = new Map();
    let filteredOrderedWyshes = this.getFilteredOrderedWyshes();

    for (var i = 0; i < filteredOrderedWyshes.length; i++) {
      var myQuestionNumber = (i + 1).toString();
      filteredOrderedWyshes[i].questionNumber = myQuestionNumber;
      labelMap.set(myQuestionNumber, filteredOrderedWyshes[i]);
      if (filteredOrderedWyshes[i].isBlock() === true) {
        filteredOrderedWyshes[i].generateQuestionNumbersMap(myQuestionNumber, labelMap);
      }

    }

    return labelMap;

  }

  getWyshSummaryArray() {

  }

  getOptionSummaryArray() {

  }

  generateSummaryArray() {

    var summaryArray = [];
    summaryArray.push([
      "Question_Number",
      "Question_Text",
      "Question_Prompt",
      "Question_Type",
      "Question_Count",
      "Option_Value",
      "Option_Text",
      "Option_Count",
      "Option_Pct",
      "Skip_Logic",
      "Verbatim_Prompt",
      "Verbatim_Logic",
      "Content_Image_URL",
      "Content_Media_URL",
      "Content_Name",
      "Product_ID"]);

    var questionNumbersMap = this.generateQuestionNumbersMap();

    for (let questionNumber of questionNumbersMap.keys()) {

      var wysh = questionNumbersMap.get(questionNumber);
      let questionType = wysh.questionType;

      if (wysh.parentWysh && wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF)) {
        questionType = "maxdiff";
      }
      else if (wysh.parentWysh && wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE)) {
        questionType = "pairwise";
      }

      switch (questionType) {
        case "pairwise":
          summaryArray.push([
            "Q" + questionNumber,
            wysh.product.description,
            wysh.getWyshPrompt(),
            questionType,
            wysh.takerCount,
            "",
            "Importance Score",
            "",
            wysh.getAverageScore(),
            "",
            "",
            "",
            wysh.product.imageUrl,
            wysh.product.buyPage,
            wysh.product.name,
            wysh.product.productId
          ]);

          break;
        case "maxdiff":
          summaryArray.push([
            "Q" + questionNumber,
            wysh.product.description,
            wysh.getWyshPrompt(),
            questionType,
            wysh.takerCount,
            "",
            "Utility Score",
            "",
            wysh.getAverageScore(),
            "",
            "",
            "",
            wysh.product.imageUrl,
            wysh.product.buyPage,
            wysh.product.name,
            wysh.product.productId
          ]);

          break;
        case "binary":
        case "next":
        case "free-response-only":

          summaryArray.push([
            "Q" + questionNumber,
            wysh.product.description,
            wysh.getWyshPrompt(),
            wysh.questionType,
            wysh.takerCount,
            "",
            "",
            "",
            "",
            "",
            wysh.freeResponseQuestions.length > 0 ? wysh.freeResponseQuestions[0] : "",
            "",
            wysh.product.imageUrl,
            wysh.product.buyPage,
            wysh.product.name,
            wysh.product.productId
          ]);

          if (!wysh.isBlock()) {

            var frLogic = "";
            if (wysh.freeResponseQuestions.length > 0) {
              frLogic = wysh.hiddenFreeResponseOptions.includes(1) ? "No" : "Yes";
            }

            summaryArray.push([
              "",
              "",
              "",
              "",
              "",
              1,
              "Yes",
              wysh.aggregatedResults.likesCount,
              wysh.getAverage(),
              wysh.hasSkipLogic(1) ? "Yes" : "",
              "",
              frLogic,
              "",
              "",
              "",
              ""
            ]);

            if (wysh.freeResponseQuestions.length > 0) {
              frLogic = wysh.hiddenFreeResponseOptions.includes(0) ? "No" : "Yes";
            }

            summaryArray.push([
              "",
              "",
              "",
              "",
              "",
              0,
              "No",
              wysh.aggregatedResults.dislikesCount,
              1 - wysh.getAverage(),
              wysh.hasSkipLogic(0) ? "Yes" : "",
              "",
              frLogic,
              "",
              "",
              "",
              ""
            ]);
          }
          break;
        case "scalar":
        case "single-select":
        case "multiple-choice":

          summaryArray.push([
            "Q" + questionNumber,
            wysh.product.description,
            wysh.getWyshPrompt(),
            wysh.questionType,
            wysh.takerCount,
            "",
            "",
            "",
            "",
            "",
            wysh.freeResponseQuestions.length > 0 ? wysh.freeResponseQuestions[0] : "",
            "",
            wysh.product.imageUrl,
            wysh.product.buyPage,
            wysh.product.name,
            wysh.product.productId
          ]);

          for (var i = 0; i < wysh.wyshOptions.length; i++) {

            var percentage = 0.0;
            if (wysh.wyshOptions[i].decisionsCount > 0) {
              percentage = wysh.wyshOptions[i].decisionsCount / wysh.takerCount;
            }

            const resultNormalized = (wysh.wyshOptions[i].resultNormalized !== 99.0 && wysh.wyshOptions[i].resultNormalized !== 98.0) ? wysh.wyshOptions[i].resultNormalized + 1 : wysh.wyshOptions[i].resultNormalized


            var frLogic = "";
            if (wysh.freeResponseQuestions.length > 0) {
              frLogic = wysh.hiddenFreeResponseOptions.includes(wysh.wyshOptions[i].resultNormalized) ? "No" : "Yes";
            }

            summaryArray.push([
              "",
              "",
              "",
              "",
              "",
              resultNormalized,
              wysh.wyshOptions[i].resultLiteral,
              wysh.wyshOptions[i].decisionsCount,
              percentage,
              wysh.hasSkipLogic(wysh.wyshOptions[i].resultNormalized) ? "Yes" : "",
              "",
              frLogic,
              "",
              "",
              "",
              ""
            ]);
          }

          break;

        default:
          summaryArray.push([
            "Q" + questionNumber,
            wysh.product.description,
            wysh.getWyshPrompt(),
            wysh.questionType,
            wysh.takerCount,
            "",
            "",
            "",
            "",
            "",
            wysh.freeResponseQuestions.length > 0 ? wysh.freeResponseQuestions[0] : "",
            "",
            wysh.product.imageUrl,
            wysh.product.buyPage,
            wysh.product.name,
            wysh.product.productId
          ]);

          if (!wysh.isBlock()) {

            var frLogic = "";
            if (wysh.freeResponseQuestions.length > 0) {
              frLogic = wysh.hiddenFreeResponseOptions.includes(1) ? "No" : "Yes";
            }

            summaryArray.push([
              "",
              "",
              "",
              "",
              "",
              1,
              "Yes",
              wysh.aggregatedResults.likesCount,
              wysh.getAverage(),
              wysh.hasSkipLogic(1) ? "Yes" : "",
              "",
              frLogic,
              "",
              "",
              "",
              ""
            ]);

            if (wysh.freeResponseQuestions.length > 0) {
              frLogic = wysh.hiddenFreeResponseOptions.includes(0) ? "No" : "Yes";
            }

            summaryArray.push([
              "",
              "",
              "",
              "",
              "",
              0,
              "No",
              wysh.aggregatedResults.dislikesCount,
              1 - wysh.getAverage(),
              wysh.hasSkipLogic(0) ? "Yes" : "",
              "",
              frLogic,
              "",
              "",
              "",
              ""
            ]);
          }
      }

      summaryArray.push([]);
    }

    return summaryArray;
  }

  generateCohortSummaryArray() {

    console.log(this.snapshotContainer);

    const headerArray = [
      "Question_Number",
      "Question_Text",
      "Question_Prompt",
      "Question_Type",
      "Option_Value",
      "Option_Text"
    ];

    const stimuliArray = [];

    const snapshotKeys = Array.from(this.snapshotContainer.snapshots.keys());
    snapshotKeys.sort((a, b) => {

      return parseInt(a) - parseInt(b);


    });



    // Snapshots - 1 for countsand another for percentages
    for (const snapshotKey of snapshotKeys) {
      const wyshSnapshot = this.snapshotContainer.snapshots.get(snapshotKey);
      headerArray.push(wyshSnapshot.name);
    }
    for (const snapshotKey of snapshotKeys) {
      const wyshSnapshot = this.snapshotContainer.snapshots.get(snapshotKey);
      headerArray.push(wyshSnapshot.name);
    }

    // Resume Stimulus metadata headers
    headerArray.push("Skip_Logic");
    headerArray.push("Verbatim_Prompt");
    headerArray.push("Verbatim_Logic");
    headerArray.push("Content_Image_URL");
    headerArray.push("Content_Media_URL");
    headerArray.push("Content_Name");
    headerArray.push("Product_ID");

    stimuliArray.push(headerArray);

    const questionNumbersMap = this.generateQuestionNumbersMap();
    for (const questionNumber of questionNumbersMap.keys()) {

      const wysh = questionNumbersMap.get(questionNumber);
      let questionType = wysh.questionType;

      if (wysh.parentWysh && wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF)) {
        questionType = "maxdiff";
      }
      else if (wysh.parentWysh && wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE)) {
        questionType = "pairwise";
      }

      const stimulusMetadataArray = [
        "Q" + questionNumber,
        wysh.product.description,
        wysh.getWyshPrompt(),
        questionType,
        "",
        ""
      ];

      for (const snapshotKey of snapshotKeys) {
        stimulusMetadataArray.push(wysh.getTakerCount(this.snapshotContainer.snapshots.get(snapshotKey)))
      }

      for (const snapshotKey of snapshotKeys) {
        stimulusMetadataArray.push(wysh.getTakerCount(this.snapshotContainer.snapshots.get(snapshotKey)))
      }

      // Resume Stimulus metadata
      stimulusMetadataArray.push("");
      stimulusMetadataArray.push(wysh.freeResponseQuestions.length > 0 ? wysh.freeResponseQuestions[0] : ""),
      stimulusMetadataArray.push("");
      stimulusMetadataArray.push(wysh.product.imageUrl);
      stimulusMetadataArray.push(wysh.product.buyPage);
      stimulusMetadataArray.push(wysh.product.name);
      stimulusMetadataArray.push(wysh.product.productId);


      stimuliArray.push(stimulusMetadataArray);

      for (const stimlusOptionData of wysh.getOptionsArray(snapshotKeys)) {
        const spacerArray = ["", "", "", ""];
        const optionsArray = [...spacerArray, ...stimlusOptionData]
        stimuliArray.push(optionsArray);
      }





    }


    return stimuliArray;
  }

  getDecisionsByMidArray() {

    let queryParamsKeysSet = this._generateGlobalQueryParamKeysSet();

    var questionNumbersMap = this.generateQuestionNumbersMap();

    // Got through ordered wyshes and find the first decision in the taker array

    // let filteredOrderedWyshes = this.getFilteredOrderedWyshes();

    var takersDecisionsArray = [];
    var takersFreeResponseArray = [];
    var takersFreeResponseDict = {};
    var takersInfoArray = [];
    var takerIndex = 1;

    // TEMPORARILY DISABLE GEO
    // takersInfoArray.push(["respondent_index", "respondent_mid", "respondent_email", "respondent_latitude", "respondent_longitude", "respondent_accuracy"]);
    var takersInfoArrayHeaders = ["respondent_index", "respondent_mid", "respondent_email", "segment", "segment_name"];
    for (let key of queryParamsKeysSet) { takersInfoArrayHeaders.push(key) };
    takersInfoArray.push(takersInfoArrayHeaders);

    var takersDecisionsArrayHeaders = ["respondent_index", "stimuli_count"];
    var takersFreeResponseArrayHeaders = [];

    for (let questionNumber of questionNumbersMap.keys()) {
      this._pushReportHeaders(questionNumbersMap.get(questionNumber), questionNumber, takersDecisionsArrayHeaders, takersFreeResponseArrayHeaders, takersFreeResponseDict);
    }

    // TEMPORARILY DISABLE GEO
    // takersDecisionsArrayHeaders.push("latitude");
    // takersDecisionsArrayHeaders.push("longitude");
    // takersDecisionsArrayHeaders.push("accuracy");


    takersDecisionsArray.push(takersDecisionsArrayHeaders);
    takersFreeResponseArray.push(takersFreeResponseArrayHeaders);

    for (var mid in this.decisionsByMidDict) {

      let decisionsArray = this.decisionsByMidDict[mid];

      if (this.filter.areDecisionsSignificant(decisionsArray) === true) {

        var segmentId = decisionsArray && decisionsArray.length > 0 ? decisionsArray[0].segmentId : 0;
        var segment = this.creator.segmentBucket.getSegment(segmentId);

        var takerDecisionsByStimuliArray = [];
        var takersFreeResponseByStimuliArray = [];
        var takerInfo = [];
        var contextIdMap = new Map();

        takerInfo.push(takerIndex);
        takerInfo.push(mid);
        takerInfo.push(this.findEmailForMid(mid));
        takerInfo.push(segment ? segment.ordinal : 0);

        if (segmentId !== null && this.creator.segmentBucket.segments.hasOwnProperty(segmentId.toString())) {
          takerInfo.push(this.creator.segmentBucket.segments[segmentId.toString()].segment_name);
        }
        else {
          takerInfo.push(null);
        }

        takerDecisionsByStimuliArray.push(takerIndex);
        takerDecisionsByStimuliArray.push(this.wyshes.length);

        // Go through the Wyshes array and put the resultsNormailzed in the array or a blank if not there

        for (let key of queryParamsKeysSet) {
          for (var i = 0; i < decisionsArray.length; i++) {
            if (decisionsArray[i].requestDataJson &&
              decisionsArray[i].requestDataJson.query_params) {

              if (contextIdMap.has(key) === false && decisionsArray[i].requestDataJson.query_params[key]) {
                contextIdMap.set(key, decisionsArray[i].requestDataJson.query_params[key]);
                break;
              }
            }
          }
        }

        for (let key of queryParamsKeysSet) {
          let value = contextIdMap.get(key);
          if (value) {
            takerInfo.push(value);
          }
          else {
            takerInfo.push("");
          }
        };

        for (let questionNumber of questionNumbersMap.keys()) {
          var wysh = questionNumbersMap.get(questionNumber);
          this._pushReportDecisions(wysh, decisionsArray, takerDecisionsByStimuliArray, takersFreeResponseByStimuliArray, takersFreeResponseDict);
        }

        // LEGACY - Look to this if we ever reinstate location reporting

        // for (i = 0; i < filteredOrderedWyshes.length; i++) {

        // if (filteredOrderedWyshes[i].isBlock() === false) {
        //   let decision = this._findDecision(decisionsArray, filteredOrderedWyshes[i].product.productId);
        //   if (decision) {
        //     takerDecisionsByStimuliArray.push(decision.resultNormalized);
        //   }
        //   else {
        //     takerDecisionsByStimuliArray.push("");
        //   }
        //
        //
        //   if (filteredOrderedWyshes[i].freeResponseQuestions.length > 0) {
        //     if (decision && decision.freeResponseAnswers[0]) {
        //       takersFreeResponseByStimuliArray.push(decision.resultLiteral, decision.freeResponseAnswers[0]);
        //     }
        //     else {
        //       takersFreeResponseByStimuliArray.push("", "");
        //     }
        //   }
        //
        //   if (decision) {
        //     takersFreeResponseDict[filteredOrderedWyshes[i].wyshId].decisions.push(decision);
        //   }
        //
        //   // if (longitude === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.longitude) {
        //   //   longitude = decision.requestDataJson.geolocation_info.longitude;
        //   // }
        //   //
        //   // if (latitude === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.latitude) {
        //   //   latitude = decision.requestDataJson.geolocation_info.latitude;
        //   // }
        //   //
        //   // if (accuracy === null && decision && decision.requestDataJson && decision.requestDataJson.geolocation_info && decision.requestDataJson.geolocation_info.accuracy) {
        //   //   accuracy = decision.requestDataJson.geolocation_info.accuracy;
        //   // }
        //   //
        //   // TEMPORARILY DISABLE GEO
        //   // if (latitude) {
        //   //   takerDecisionsByStimuliArray.push(latitude);
        //   //   takerInfo.push(latitude);
        //   // }
        //   // else {
        //   //   takerDecisionsByStimuliArray.push("");
        //   //   takerInfo.push("");
        //   // }
        //   // if (longitude) {
        //   //   takerDecisionsByStimuliArray.push(longitude);
        //   //   takerInfo.push(longitude);
        //   // }
        //   // else {
        //   //   takerDecisionsByStimuliArray.push("");
        //   //   takerInfo.push("");
        //   // }
        //   // if (accuracy) {
        //   //   takerDecisionsByStimuliArray.push(accuracy);
        //   //   takerInfo.push(accuracy);
        //   // }
        //   // else {
        //   //   takerDecisionsByStimuliArray.push("");
        //   //   takerInfo.push("");
        //   // }
        // }
        // }

        takersInfoArray.push(takerInfo);
        takersDecisionsArray.push(takerDecisionsByStimuliArray);
        takersFreeResponseArray.push(takersFreeResponseByStimuliArray);

        takerIndex += 1;
      }
    }

    return [takersDecisionsArray, takersInfoArray, takersFreeResponseArray, takersFreeResponseDict];

  }

  getDecisionsByMidWithDupesArray() {

    let queryParamsKeysSet = this._generateGlobalQueryParamKeysSet();

    var questionNumbersMap = this.generateQuestionNumbersMap();


    var takersDecisionsArray = [];
    var takersFreeResponseArray = [];
    var takersFreeResponseDict = {};
    var takersInfoArray = [];
    var takerIndex = 1;

    var takersInfoArrayHeaders = ["session_index", "session_timestamp", "respondent_mid", "Date (UTC)", "Time (UTC)", "respondent_email", "segment", "segment_name"];
    for (let key of queryParamsKeysSet) { takersInfoArrayHeaders.push(key) };
    takersInfoArray.push(takersInfoArrayHeaders);

    var takersDecisionsArrayHeaders = ["session_index", "session_timestamp", "respondent_mid", "Date (UTC)", "Time (UTC)", "stimuli_count"];
    var takersFreeResponseArrayHeaders = [];

    for (let questionNumber of questionNumbersMap.keys()) {
      this._pushReportHeaders(questionNumbersMap.get(questionNumber), questionNumber, takersDecisionsArrayHeaders, takersFreeResponseArrayHeaders, takersFreeResponseDict);
    }

    takersDecisionsArray.push(takersDecisionsArrayHeaders);
    takersFreeResponseArray.push(takersFreeResponseArrayHeaders);

    // Go through decisions by mid and session timestamp
    for (const mid in this.decisionsByMidDictWithDupes) {
      for (const ts in this.decisionsByMidDictWithDupes[mid]) {
        let decisionsArray = this.decisionsByMidDictWithDupes[mid][ts];
        const tsDate = new Date();
        tsDate.setTime(ts * 1000);

        if (this.filter.areDecisionsSignificant(decisionsArray) === true) {

          var segmentId = decisionsArray && decisionsArray.length > 0 ? decisionsArray[0].segmentId : 0;
          var segment = this.creator.segmentBucket.getSegment(segmentId);

          var takerDecisionsByStimuliArray = [];
          var takersFreeResponseByStimuliArray = [];
          var takerInfo = [];
          var contextIdMap = new Map();

          takerInfo.push(takerIndex);
          takerInfo.push(ts);
          takerInfo.push(mid);
          takerInfo.push(Decision.formatDateAndTimeUTC(tsDate));
          takerInfo.push(Decision.formatTimeUTC(tsDate));
          takerInfo.push(this.findEmailForMid(mid));
          takerInfo.push(segment ? segment.ordinal : 0);

          if (segmentId !== null && this.creator.segmentBucket.segments.hasOwnProperty(segmentId.toString())) {
            takerInfo.push(this.creator.segmentBucket.segments[segmentId.toString()].segment_name);
          }
          else {
            takerInfo.push(null);
          }

          takerDecisionsByStimuliArray.push(takerIndex);
          takerDecisionsByStimuliArray.push(ts);
          takerDecisionsByStimuliArray.push(mid);
          takerDecisionsByStimuliArray.push(Decision.formatDateAndTimeUTC(tsDate));
          takerDecisionsByStimuliArray.push(Decision.formatTimeUTC(tsDate));
          takerDecisionsByStimuliArray.push(this.wyshes.length);

          // Go through the Wyshes array and put the resultsNormailzed in the array or a blank if not there

          for (let key of queryParamsKeysSet) {
            for (var i = 0; i < decisionsArray.length; i++) {
              if (decisionsArray[i].requestDataJson &&
                decisionsArray[i].requestDataJson.query_params) {

                if (contextIdMap.has(key) === false && decisionsArray[i].requestDataJson.query_params[key]) {
                  contextIdMap.set(key, decisionsArray[i].requestDataJson.query_params[key]);
                  break;
                }
              }
            }
          }

          for (let key of queryParamsKeysSet) {
            let value = contextIdMap.get(key);
            if (value) {
              takerInfo.push(value);
            }
            else {
              takerInfo.push("");
            }
          };

          for (let questionNumber of questionNumbersMap.keys()) {
            var wysh = questionNumbersMap.get(questionNumber);
            this._pushReportDecisions(wysh, decisionsArray, takerDecisionsByStimuliArray, takersFreeResponseByStimuliArray, takersFreeResponseDict);
          }


          takersInfoArray.push(takerInfo);
          takersDecisionsArray.push(takerDecisionsByStimuliArray);
          takersFreeResponseArray.push(takersFreeResponseByStimuliArray);

          takerIndex += 1;
        }
      }
    }

    return [takersDecisionsArray, takersInfoArray, takersFreeResponseArray, takersFreeResponseDict];
  }

  _findDecision(decisionsArray, productId) {

    for (var i = 0; i < decisionsArray.length; i++) {
      if (decisionsArray[i].productId === productId) {
        return decisionsArray[i];
      }
    }

    return null;
  }

  _findDecisions(wysh, decisionsArray) {

    var decisions = [];

    for (var i = 0; i < decisionsArray.length; i++) {
      if (decisionsArray[i].productId === "d5c1a513-ba6f-4dd5-b03d-7f44e7e349f8") {
        // console.log(decisionsArray[i]);
      }
      if (decisionsArray[i].productId === wysh.product.productId) {
        decisions.push(decisionsArray[i]);
      }
    }

    return decisions;
  }

  /**
  * processDecisionCounts
  *
  * Load this Swydget's Products with the appropriate decision counts for each options
  *
  * @param decisionCounts object containing a dictionary of product IDs to options and their count
  */
  processDecisionCounts(decisionCounts, takerCountsByProduct) {

    // var wyshes = this.orderedWyshList.getWyshes();
    var wyshes = this.orderedWyshList.getOrderedWyshes();
    for (var i = 0; i < wyshes.length; i++) {
      wyshes[i].resetAggregateResults();
    }

    // scale the average scores for max diff
    let topScore = -1000;
    let bottomScore = 1000;

    for (var productId in decisionCounts) {
      if (decisionCounts.hasOwnProperty(productId)) {
        const scoresDict = decisionCounts[productId];
        if (scoresDict.hasOwnProperty("average_score")) {
          const avgScore = scoresDict["average_score"];
          if (avgScore > topScore) {
            topScore = avgScore;
          }
          if (avgScore < bottomScore) {
            bottomScore = avgScore;
          }
        }
      }
    }

    for (var productId in decisionCounts) {
      if (decisionCounts.hasOwnProperty(productId)) {
        var wysh = this.findWyshByProductId(productId);
        if (wysh) {
          wysh.processDecisionCounts(decisionCounts[productId], topScore, bottomScore);
          wysh.takerCount = takerCountsByProduct[productId];
        }
        else {
          console.log("Stimuli not found: " + productId);
        }
      }
    }
  }

  /**
  * Determines if the argument Wysh's attributes match the current settings
  * for this Events content filter.
  *
  * @param wysh
  * @return boolean passes
  */
  passesContentFilter(wysh, selectedIndicesArray) {

    for (var key in this.filter.attributeDictForFilteringContent) {
      if (this.filter.attributeDictForFilteringContent.hasOwnProperty(key)) {
        var attributeValue = wysh.product.attributes[key];

        if (attributeValue === undefined) {
          // The attribute in the filter is NOT present in the product.
          // We fail!
          return false
        }
        else if (attributeValue !== this.filter.attributeDictForFilteringContent[key]) {
          // The attribute exists, but it does not match the value
          // of the filter.
          // we fail!
          return false
        }
      }
    }

    return true;
  }

  /**
  * filterWyshes
  *
  * Filters the Wyshes of this Event using the argument filter parameters. The array
  * of Wyshes returned will be ordered. The filter is defined as follows:
  *
  * {
  ""



  *
  * }
  *
  * @param decisionArray (of Decision objects)
  * @return boolean filter requirements satisfied
  */
  filterWyshes(filter) {

  }

  addFilter(term, value) {
    this.filter.attributeDictForFilteringRespondents[term] = value;
  }

  deleteFilter(term) {
    delete this.filter.attributeDictForFilteringRespondents[term];
  }

  addContentFilter(term, value) {
    this.filter.attributeDictForFilteringContent[term] = value;
  }

  deleteContentFilter(term) {
    delete this.filter.attributeDictForFilteringContent[term];
  }

  /**
  * Generates a Map of all the possible keys to a Set of all experienced values
  *
  * Also, generates an array containing all the possible dictionary keys
  * contained in the products of this Swydget.
  */
  generateAttributesMap() {

    var keysSet = new Set();
    this.attributesMap = new Map();

    for (var i = 0; i < this.wyshes.length; i++) {
      for (var key in this.wyshes[i].product.attributes) {
        if (this.wyshes[i].product.attributes.hasOwnProperty(key)) {
          keysSet.add(key);

          if (this.attributesMap.has(key)) {
            var possibleValuesSet = this.attributesMap.get(key);
            possibleValuesSet.add(this.wyshes[i].product.attributes[key]);
          }
          else {
            possibleValuesSet = new Set();
            possibleValuesSet.add(this.wyshes[i].product.attributes[key]);
            this.attributesMap.set(key, possibleValuesSet);
          }
        }
      }
    }

    this.attributeKeysArray = Array.from(keysSet);
  }

  getGlobalAttributesArray() {
    if (this.globalAttributesArray.length === 0) {
      var products = [];

      for (var i = 0; i < this.wyshes.length; i++) {
        products.push(this.wyshes[i].product);
      }

      this.globalAttributesArray = WyshMeProduct.generateGlobalAttributesArray(products);
    }

    return this.globalAttributesArray;
  }

  findSurveyTakers(surveyTakersJsonArray) {
    var surveyTakerMidSet = new Set();
    var surveyTakerCompletedMidSet = new Set();

    for (var i = 0; i < surveyTakersJsonArray.length; i++) {
      if (surveyTakersJsonArray[i]["svt_event_id"] === this.eventId) {
        surveyTakerMidSet.add(surveyTakersJsonArray[i]);
        if (surveyTakersJsonArray[i]["svt_completed"] !== null) {
          surveyTakerCompletedMidSet.add(surveyTakersJsonArray[i]);
        }
      }
    }
    this.surveyTakerCount = surveyTakerMidSet.size;
    this.surveyTakerCompletedCount = surveyTakerCompletedMidSet.size;
  }

  getPath() {
    var path = "survey/"
    if (this.published === true) {
      path = "results/"
    }

    path += this.eventId

    return path;
  }

  getSurveyNameStripped() {
    return this.surveyName.replace(/[\W_]+/g, "_");
  }

  getCompletionPct() {
    if (this.surveyTakerCount === 0.0 && this.surveyTakerCompletedCount === 0.0) {
      return (0.0).toFixed(1);
    }

    return (Math.round((this.surveyTakerCompletedCount / this.surveyTakerCount) * 1000) / 10).toFixed(1);
  }

  getSecureImageUrl(urlString) {
    var secureUrl = urlString;

    if (Utilities.startsWith(secureUrl, "http:") === true) {
      let replaceRegEx = /http:/g;
      secureUrl = secureUrl.replace(replaceRegEx, "https:");
    }

    return secureUrl;
  }

  getDefaultCardBackgroundUrl() {
    return this.getSecureImageUrl(this.defaultProduct.imageUrl);
  }

  getWallpaperUrl() {
    return this.getSecureImageUrl(this.wallpaperUrl);
  }

  getCoverPhotoUrl() {

    var imageUrl = "";

    if (this.coverPhotoUrl !== '') {
      imageUrl = this.getSecureImageUrl(this.coverPhotoUrl);
    }
    else {
      imageUrl = swytchbackLogo;
    }

    return imageUrl;
  }

  getRewardUrl(queryStringJson = null) {

    if (Utilities.startsWith(this.rewardUrl, "http:") === false && Utilities.startsWith(this.rewardUrl, "https:") === false) {
      this.rewardUrl = "http://" + this.rewardUrl;
    }

    const url = new URL(this.rewardUrl);
    const inputParams = new URLSearchParams(url.search);

    for (const k in queryStringJson) {
      inputParams.append(k, queryStringJson[k]);
    }

    url.search = inputParams;

    return url.toString()
  }

  getTosLink() {
    return this.tosLink.trim() !== '' && this.creator.getCompanyName !== '' ? this.tosLink : "https://swytchback.com/sweepstakes-terms-of-service/";
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  /**
  * filterAndOrder
  *
  * Filter and order the arguement decision array to have one decision per product
  * in the order specified by the Swydget.
  *
  * @param decisionsArray (of Decision objects)
  * @return filteredAndOrderedDecisionArray
  */
  filterAndOrder(decisionsArray) {

    var filteredAndOrderedDecisionArray = [];
    var orderedWyshes = this.getWyshes();

    for (var i = 0; i < orderedWyshes.length; i++) {
      filteredAndOrderedDecisionArray.push(this.findFirstDecision(orderedWyshes[i], decisionsArray));
    }

    return filteredAndOrderedDecisionArray;
  }

  /**
  * findFirstDecision
  *
  * Go through the argument Decisions array and return the first Decision encountered
  * made on the argument Wysh. If a Decision is not found, return null.
  *
  * @param decisionsArray (of Decision objects)
  * @param wysh
  * @return firstDecision. Null if not found
  */
  findFirstDecision(wysh, decisionsArray) {
    if (wysh !== null) {
      for (var i = 0; i < decisionsArray.length; i++) {
        if (wysh.wyshId === decisionsArray[i].wysh.wyshId) {
          return decisionsArray[i];
        }
      }
    }

    return null;
  }

  generateDecisionsByMidArray() {
    // Go through the wyshes and make an array of all the MIDs of the takers

    this.decisionsByMidArray = [];
    this.metaDataArray = [];
    this.respondentRoster = [["respondent_index", "respondent_mid", "respondent_latitude", "respondent_longitude", "respondent_accuracy", "respondent_email"]];
    this.contentCatalog = [];

    var orderedWyshes = this.getWyshes();

    for (var i = 0; i < orderedWyshes.length; i++) {
      this.contentCatalog.push([i, orderedWyshes[i].product.productId]);
    }

    var respondentIndex = 1;

    for (var takerMid in this.decisionsByMidDict) {
      if (this.decisionsByMidDict.hasOwnProperty(takerMid)) {

        // Here is what is happening here:
        // 1. Get a user's decisions
        // 2. Sort those decisions by time submitted
        // 3. push them on the decision and meta data arrays

        // Here is what is happening here:
        // 1. Get a Users decisions
        // 2. Sort those decisions by time submitted
        // 3. Get the ordered list of products
        // 4. Go through the products and find the earliest submission for each product
        // 5. push that onto the array

        var decisionsArrayForTaker = this.decisionsByMidDict[takerMid];
        if (decisionsArrayForTaker.length === this.wyshes.length && this.filter.areDecisionsSignificant(decisionsArrayForTaker)) {

          decisionsArrayForTaker.sort(Decision.compareByCreationDateAsc);

          var midMetaData = [];
          var midDecisions = [];
          var respondentInfo = [];

          midDecisions.push(respondentIndex);
          midMetaData.push("respondent_index");
          midDecisions.push(this.wyshes.length);
          midMetaData.push("stimuli_count");


          respondentInfo.push(respondentIndex);
          respondentInfo.push(takerMid);

          var geoData = null;

          for (i = 0; i < orderedWyshes.length; i++) {

            let decision = this.findFirstDecision(orderedWyshes[i], decisionsArrayForTaker);

            if (decision) {

              if (this.surveyType === "forced_rank") {

                // Forced Ranking is a little different. When we are submitting a decision here,
                // we are actually submitting a binary "yes" for the selected concept/product and
                // also submitting a binary "no" for the other choices.
                // So, the decisions that are 0 are just the compeitors that did not get selected. We don't
                // want to push them onto the results. We only want the winners.
                if (decision.resultNormalized > 0) {

                  midDecisions.push(decision.round);
                  midMetaData.push("round");
                  midDecisions.push(decision.trial);
                  midMetaData.push("trial");
                  midDecisions.push(this.getProductIndex(decision.productId));
                  midMetaData.push("winner");

                  if (decision.requestDataJson && this.isString(decision.requestDataJson) === false && "competitors" in decision.requestDataJson) {
                    for (var j = 0; j < decision.requestDataJson["competitors"].length; j++) {
                      midDecisions.push(this.getProductIndex(decision.requestDataJson["competitors"][j]));
                      midMetaData.push("competitor " + (j + 1));
                    }
                  }
                }
              }
              else {
                // midMetaData.push("content_index");
                // midMetaData.push("result_literal");
                midMetaData.push("result_normalized_" + (i + 1));

                // midDecisions.push(this.getProductIndex(orderedWyshes[i].product.productId));
                if (decision !== null) {

                  // midDecisions.push(decision.resultLiteral);
                  midDecisions.push(decision.resultNormalized);
                }
                else {
                  midDecisions.push("");
                }
              }

              if (geoData === null && decision.requestDataJson) {
                geoData = decision.requestDataJson.geolocation_info;
              }
            }
          }

          midMetaData.push("latitude");
          midMetaData.push("longitude");
          midMetaData.push("accuracy");

          if (geoData !== null &&
            geoData.hasOwnProperty("latitude") &&
            geoData.hasOwnProperty("longitude") &&
            geoData.hasOwnProperty("accuracy")) {
            midDecisions.push(geoData.latitude);
            midDecisions.push(geoData.longitude);
            midDecisions.push(geoData.accuracy);
            respondentInfo.push(geoData.latitude);
            respondentInfo.push(geoData.longitude);
            respondentInfo.push(geoData.accuracy);
          }
          else {
            midDecisions.push("");
            midDecisions.push("");
            midDecisions.push("");
            respondentInfo.push("");
            respondentInfo.push("");
            respondentInfo.push("");
          }

          this.decisionsByMidArray.push(midDecisions);

          if (this.metaDataArray && this.metaDataArray[0]) {
            if (this.metaDataArray[0].length < midMetaData.length) {
              this.metaDataArray[0] = midMetaData;
            }
          }
          else {
            this.metaDataArray.push(midMetaData);
          }

          this.respondentRoster.push(respondentInfo);

          respondentIndex += 1;
        }
      }
    }
  }

  getProductsArray() {

    var productsArray = [];

    var questionNumbersMap = this.generateQuestionNumbersMap();

    // generate headers for attributes
    var headers = ["index", "product_id", "name", "prompt", "card_text", "image_url"];
    var globalAttributesArray = this.getGlobalAttributesArray();

    for (var i = 0; i < globalAttributesArray.length; i++) {
      for (var key in globalAttributesArray[i]) {

        var value = globalAttributesArray[i][key] === key ? "" : ": " + globalAttributesArray[i][key]

        headers.push(key + value);
      }
    }

    productsArray.push(headers);

    for (let questionNumber of questionNumbersMap.keys()) {
      let wysh = questionNumbersMap.get(questionNumber);
      productsArray.push(wysh.toSimpleArray(questionNumber));
    }

    return productsArray;
  }

  getProductIndex(productId) {
    var productsArray = this.getProductsArray();

    var index = -1;

    for (var i = 0; i < productsArray.length; i++) {
      if (productsArray[i][1] === productId) {
        index = i + 1;
        break;
      }
    }

    return index;
  }

  isSocialChannelEnabled(channelCode) {

    var isEnabled = false;

    for (var i = 0; i < this.socialSharingChannels.length; i++) {
      if (this.socialSharingChannels[i] === channelCode) {
        isEnabled = true;
        break;
      }
    }

    return isEnabled;
  }

  enableSocialChannel(channelCode) {
    if (this.isSocialChannelEnabled(channelCode) === false) {
      this.socialSharingChannels.push(channelCode);
    }
  }

  disableSocialChannel(channelCode) {
    if (this.isSocialChannelEnabled(channelCode) === true) {
      var index = this.socialSharingChannels.indexOf(channelCode);

      if (index > -1) {
        this.socialSharingChannels.splice(index, 1);
      }
    }
  }

  getCompositeWordFrequencyArray(wyshes) {

    var compositeWfa = [];
    var wfMap = new Map();

    for (var i = 0; i < wyshes.length; i++) {
      if (wyshes[i].wordFrequencyArray.length > 0) {
        for (var j = 0; j < wyshes[i].wordFrequencyArray.length; j++) {
          if (wfMap.has(wyshes[i].wordFrequencyArray[j].word)) {
            var wfObject = wfMap.get(wyshes[i].wordFrequencyArray[j].word);
            wfObject.count += wyshes[i].wordFrequencyArray[j].count;
            wfObject.total_svd_result_normalized += wyshes[i].wordFrequencyArray[j].totalResultsNormalized;
          }
          else {
            wfObject = {
              word: wyshes[i].wordFrequencyArray[j].word,
              count: wyshes[i].wordFrequencyArray[j].count,
              total_svd_result_normalized: wyshes[i].wordFrequencyArray[j].totalResultsNormalized
            };
            wfMap.set(wyshes[i].wordFrequencyArray[j].word, wfObject);
          }
        }
      }
    }

    for (let wfObject of wfMap.values()) {
      compositeWfa.push(wfObject);
    }

    return WyshWordFrequency.generateWordFrequencyArray(compositeWfa);
  }

  /**
  * Does the argument user have access to this swydgetTableRows.
  *
  * @param user
  * @return boolean
  */
  isPermitted(user) {
    // Go through the
    // invitees for this Swydget and determine if the argument user is present.

    if (user === null || user === undefined) {
      return false;
    }

    for (var i = 0; i < this.invitees.length; i++) {
      if (this.invitees[i].isEqual(user)) {
        return true;
      }
    }

    return false;
  }

  getVersionId() {
    const wyshes = this.wyshes;
    for (const w of wyshes) {
      if (w.getBlockType() && w.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
        return w.getMaxDiffVersion().version;
      }
      else if (w.getBlockType() && w.getBlockType().equals(BlockTypes.PAIRWISE) === true) {
        return w.getPairwiseVersion().version;
      }
    }
    return null;
  }

  static findTakerInfo(mid, takersInfoArray) {
    for (var i = 0; i < takersInfoArray.length; i++) {
      if (takersInfoArray[i].length > 1) {
        if (takersInfoArray[i][1] === mid) {
          return takersInfoArray[i];
        }
      }
    }

    return [];
  }

  static compareByDateUpdatedAsc(a, b) {
    return a.updatedTimeSinceEpoch - b.updatedTimeSinceEpoch
  }

  static compareByDateUpdatedDesc(a, b) {
    return b.updatedTimeSinceEpoch - a.updatedTimeSinceEpoch
  }

  static compareByNameDesc(a, b) {
    return b.surveyName.localeCompare(a.surveyName);
  }

  static compareByNameAsc(a, b) {
    return a.surveyName.localeCompare(b.surveyName);
  }

  static compareByCompletionsDesc(a, b) {
    return b.takerData.takerCompletedCount - a.takerData.takerCompletedCount;
  }

  static compareByCompletionsAsc(a, b) {
    return a.takerData.takerCompletedCount - b.takerData.takerCompletedCount;
  }

  static compareByCompletionsDateAsc(a, b) {
    return a.takerData.lastCompletedDate - b.takerData.lastCompletedDate
  }

  static compareByCompletionsDateDesc(a, b) {
    return b.takerData.lastCompletedDate - a.takerData.lastCompletedDate
  }

  toJsonString() {
    const jsonString = JSON.stringify({
      eventId: this.eventId,
      creatorMid: this.creatorMid,
      survey_name: this.surveyName,
      swydget_state: this.swydgetState,
      title: this.title,
      front_image: this.frontImage,
      default_prompt: this.defaultPrompt,
      description: this.description,
      preview_title: this.previewTitle,
      preview_description: this.previewDescription,
      wyshesCount: this.wyshesCount,
      published: this.published,
      binary_icon_set_id: this.binaryIconSetID,
      scalarDescription: this.scalarDescription,
      mcSingleInstruction: this.mcSingleInstruction,
      mcMultipleInstruction: this.mcMultipleInstruction
    });

    return jsonString;
  }

  static generateEventId(mid, eid) {
    // prepend the necessary 0's to the mid.
    var fullMid = "evt.";

    for (var i = 0; i < 16 - mid.length; i++) {
      fullMid += "0";
    }

    fullMid += mid;

    // prepend the necessary 0's to the eventId.
    var fullEventId = "-";

    for (i = 0; i < 8 - eid.length; i++) {
      fullEventId += "0";
    }

    fullEventId += eid;

    return fullMid + fullEventId;
  }

  static createFromJson(eventJson) {
    var event = new Event();
    event.initFromJson(eventJson);
    return event;
  }

  static createFromJsonAndResults(eventJson, resultsJsonArray) {
    var event = new Event();
    event.initFromJson(eventJson);
    return event;
  }
}
