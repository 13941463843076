import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "../../../css/swytchbackStyles.css";
import "../../../css/swytchbackModal.css";
import SwytchbackFrameButton from '../controls/SwytchbackFrameButton';

const ResolveConflictModal = ({show, swydget, resolveConflict}) => {

    return (
      <Modal
        show={show} 
        onHide={close} 
        enforceFocus={false} 
        backdrop="static"
      >
        <Modal.Header className="swytchbackModalHeader">
          <Modal.Title className="swytchbackModalTitle">Swydget In Conflicted State</Modal.Title>
        </Modal.Header>
        <Modal.Body className="swytchbackModalBody coloredBackground">
          <div className="centeredContainerDivStyle swytchbackGradientPane" style={{padding: 50, color: '#ffffff', fontSize: 16}}>
            <div>There is a problem with the stimuli order in {swydget.surveyName}</div>
            <SwytchbackFrameButton 
              style={{padding: 20, margin: 10, marginTop: 30}}
              onClick={resolveConflict}
              >
              Resolve Conflict?
            </SwytchbackFrameButton>      
          </div>  
        </Modal.Body>
      </Modal>
    );
};

export default ResolveConflictModal;

