import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import SwytchbackTextField from '../controls/SwytchbackTextField';
// import ImageUploadDialog from '../../../components/admin/ImageUploadDialog';
import "../../../css/swytchbackModal.css"
import "../../../css/swytchbackStyles.css"
import ImageUploadDialog from './ImageUploadDialog';
import SwydgetWelcome from '../../../components/swydget/SwydgetWelcome';

const WelcomePageModal = ({
  showWelcomePageModal,
  closeWelcomePageModal,
  primaryText, 
  handlePrimaryTextChange, 
  secondaryText, 
  handleSecondaryTextChange, 
  getStartedText, 
  handleGetStartedTextChange,
  coverImageFiles,
  coverPhotoUrl,
  onDrop,
  clearImage,
  swydget,
  shouldUpdateSwydgetViewBeDisabled,
  handleApplyWelcomeInfoClicked,
  isLoading
}) => {

  return (

    <Modal
      onHide={closeWelcomePageModal}
      size="xl"
      show={showWelcomePageModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Welcome Page</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="welcome-page-tabs">
            <Tab eventKey={1} title="Text">
              <div className="columnCenteredContainer">
                <SwytchbackTextField
                  style={{width: '70%', marginTop: 30}}
                  id="primaryTextTF"
                  value={primaryText}
                  onChange={handlePrimaryTextChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Primary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="secondaryTextTF"
                  value={secondaryText}
                  onChange={handleSecondaryTextChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Secondary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%', marginBottom: 30}}
                  id="getStartedTextTF"
                  value={getStartedText}
                  onChange={handleGetStartedTextChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Get Started Text"
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Welcome Image">
              <ImageUploadDialog 
                caption="Welcome Image" 
                imageFiles={coverImageFiles} 
                imageUrl={coverPhotoUrl} 
                onDrop={onDrop} 
                clearImage={clearImage}
              />
            </Tab>
            <Tab eventKey={3} title="Preview">
              <SwydgetWelcome 
                eventInFocus={swydget} 
                height={70} 
              />
            </Tab>
          </Tabs>  
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={handleApplyWelcomeInfoClicked} 
          disabled={shouldUpdateSwydgetViewBeDisabled()}
        >
          {isLoading ? 'Saving...' : 'Apply'}
        </Button>
      </Modal.Footer>
    </Modal>
    
  );

}

export default WelcomePageModal;
  



