export default class SwytchbackService
{
  constructor(serviceId) {
    this.id = serviceId;
    this.name = "Abstract SwytchbackService";
    this.level = 0;
    this.userHeadcount = 0;
    this.swydgetsPublishedLimit = 0;
    this.swydgetCompletedLimit = 0;
  }

  equals(swytchbackService) {
    return swytchbackService && swytchbackService.id === this.id;
  }

  static compareByNameDesc(a, b) {
    return b.name.localeCompare(a.name);
  }

  static compareByNameAsc(a, b) {
    return a.name.localeCompare(b.name);
  }
}
