
export default class WyshSurveyAggregateResults
{
  constructor() {
    this.wysh = null;
    this.likesCount = 0.0;
    this.dislikesCount = 0.0;
    this.totalResponses = 0.0;
    this.progressBarMultiplier = 1.0;
  }

  getPctLiked(snapshot=null) {

    if (this.likesCount === 0.0) {
      return (0.0).toFixed(1);
    }

    return (Math.round((this.likesCount / (this.totalResponses)) * 1000) / 10).toFixed(1);
  }

  getPctDisliked(snapshot=null) {

    if (this.dislikesCount === 0.0) {
      return (0.0).toFixed(1);
    }

    return (Math.round((this.dislikesCount / this.totalResponses) * 1000) / 10).toFixed(1);
  }

  getPctLikedFloat(snapshot=null) {

    if (this.likesCount === 0.0) {
      return 0.0;
    }

    return Math.round((this.likesCount / this.totalResponses) * 1000) / 10;
  }

  getPctDislikedFloat(snapshot=null) {

    if (this.dislikesCount === 0.0) {
      return 0.0;
    }

    return Math.round((this.dislikesCount / this.totalResponses) * 1000) / 10;
  }

  getAverageOption(options) {
    var index = Math.round(this.getAverage());
    if (index < options.length) {
      return options[index].resultLiteral;
    }
    else {
      return "Indeterminate";
    }
  }

  loadDecisionTotals() {

    // reset options bukcets
    for (var i = 0; i < this.wysh.wyshOptions.length; i++) {
      this.wysh.wyshOptions[i].decisionsArray = [];
    }

    for (i = 0; i < this.wysh.decisionsArray.length; i++) {
      for (var j = 0; j < this.wysh.wyshOptions.length; j++) {
        if (this.wysh.decisionsArray[i].resultNormalized === this.wysh.wyshOptions[j].resultNormalized) {
          this.wysh.wyshOptions[j].decisionsArray.push(this.wysh.decisionsArray[i]);
        }
      }
    }
  }

  getAverage() {
    if (this.likesCount === 0.0 && this.dislikesCount === 0.0) {
      return 0.0;
    }

    return this.likesCount / this.totalResponses;
  }

  getTotalResponses() {
    return this.totalResponses;
  }

  getLikesCount() {
    return this.likesCount;
  }

  resetOptionTotals() {
    // reset options buckets
    this.wysh.decisionsArray = [];
    for (var i = 0; i < this.wysh.wyshOptions.length; i++) {
      this.wysh.wyshOptions[i].decisionsArray = [];
    }
  }

  _addDecisionToOptionTotals(d) {

    for (var i = 0; i < this.wysh.wyshOptions.length; i++) {
      if (d.resultNormalized === this.wysh.wyshOptions[i].resultNormalized) {
        this.wysh.wyshOptions[i].decisionsArray.push(d);
      }
    }
  }

  _setProgressBarMultiplier() {

    var highestCount = 0.0;

    if (this.wysh.questionType === "binary" || this.wysh.questionType === "" || this.wysh.questionType === undefined || this.wysh.questionType === null) {
      if (this.dislikesCount > this.likesCount){
        highestCount = this.dislikesCount;
      }
      else {
        highestCount = this.likesCount;
      }
    }
    else {
      for (var i = 0; i < this.wysh.wyshOptions.length; i++) {
        if (this.wysh.wyshOptions[i].decisionsCount > highestCount) {
          highestCount = this.wysh.wyshOptions[i].decisionsCount;
        }
      }
    }

    highestCount === 0.0 ? this.progressBarMultiplier = 0.0 : this.progressBarMultiplier = 100/highestCount;
  }


  reset() {
    this.likesCount = 0;
    this.dislikesCount = 0
    this.totalResponses = 0;
    this.progressBarMultiplier = 0;
  }

  getProgressBarMultiplier(snapshot=null) {

    var highestCount = 0.0
    var progressBarMultiplier = 0.0;

    for (var i = 0; i < this.wysh.wyshOptions.length; i++) {
      if (this.wysh.wyshOptions[i].decisionsCount > highestCount) {
        highestCount = this.wysh.wyshOptions[i].decisionsCount;
      }
    }

    progressBarMultiplier = (highestCount === 0.0) ? 0.0 : 100/highestCount;

    return progressBarMultiplier;
  }

  // TODO: eliminate
  // refreshStats() {
  //
  //   console.log("refreshStats...");
  //
  //   let keys = Object.keys(this.wysh.event.decisionsByMidDict);
  //   // On Results this is never even executed
  //
  //   if (keys.length > 0) {
  //     // We have gathered decisions
  //
  //     this.totalResponses = 0.0;
  //     this.likesCount = 0.0;
  //     this.dislikesCount = 0.0;
  //
  //     this.resetOptionTotals();
  //
  //     for (var i = 0; i < keys.length; i++) {
  //       var decArray = this.wysh.event.decisionsByMidDict[keys[i]];
  //       if (this.wysh.event.filter.areDecisionsSignificant(decArray)) {
  //         for (var j = 0; j < decArray.length; j++) {
  //           if (decArray[j].productId === this.wysh.product.productId && decArray[j].eventId === this.wysh.event.eventId) {
  //             this._addDecisionToOptionTotals(decArray[j]);
  //             this.totalResponses += 1;
  //             if (decArray[j].resultNormalized > 0) {
  //               this.likesCount += decArray[j].resultNormalized;
  //             }
  //             else {
  //               this.dislikesCount += 1;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //
  //   // This is the only thing of substance hit in this method any longer
  //   this._setProgressBarMultiplier();
  // }


  static create(wysh, likesCount, dislikesCount)
  {
    var agr = new WyshSurveyAggregateResults();
    agr.wysh = wysh;
    agr.likesCount = likesCount;
    agr.dislikesCount = dislikesCount;
    return agr;
  }
}
