import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import classes from "../../../css/modules/FilterModals.module.css";
import WyshTable from '../../../components/WyshTable';
import { Switch } from '@mui/material';

const FilterByItemsModal = ({showFilterModal, resetContentFilter, closeFilterModal, swydget, toggleContentFilter, handleFilterByContentCheckboxChange}) => {
  
  const isFilterByContentTermChecked = (filterTerm, filterValue) => {

    if (filterTerm === undefined) {
      return false;
    }

    let keys = Object.keys(swydget.filter.attributeDictForFilteringContent);
    for (var i = 0; i < keys.length; i++) {
      if (filterTerm === keys[i] && swydget.filter.attributeDictForFilteringContent[keys[i]] === filterValue) {
        return true;
      }
    }

    return false;
  }

  const generateFilterByItemsAttributeCheckBoxes = () => {
    var array = [];

    var attributeKeysArray = Array.from(swydget.attributesMap.keys());
    attributeKeysArray.sort();

    for (var i = 0; i < attributeKeysArray.length; i++) {

      // get a sorted array of the experienced values for this key
      let term = attributeKeysArray[i];
      let possibleValuesSet = swydget.attributesMap.get(term);

      var possibleValuesArray = Array.from(possibleValuesSet);
      possibleValuesArray.sort();

      for (var j = 0; j < possibleValuesArray.length; j++) {

        var shouldBeChecked = false;

        if (isFilterByContentTermChecked(term, possibleValuesArray[j])) {
          shouldBeChecked = true;
        }

        let val = possibleValuesArray[j];
        array.push( <div className="switchDivStyle" key={term + ":" + possibleValuesArray[j]}>
                      <div className={`${classes.labelStyle}`}>
                        {term}
                      </div>
                      <div className={`${classes.labelStyle}`}>
                        {possibleValuesArray[j]}
                      </div>
                      <Switch
                        checked={shouldBeChecked}
                        onChange={(e) => {handleFilterByContentCheckboxChange(term, val, e)}}
                        color="primary"
                      />
                    </div>);
      }
    }

    return array;
  };

  const contentAttributesCheckBoxArray = generateFilterByItemsAttributeCheckBoxes();


  const generateFilterByContentSelectRows = () => {

    const fullWidthWidgetContainerStyle = {
      margin: 0,
      height: '70vh',
      overflow:'auto'
    }

    return (
      <div style={fullWidthWidgetContainerStyle}>
        <WyshTable
          orderedWyshList={swydget.orderedWyshList}
          eventInFocus={swydget}
          selectionHandler={toggleContentFilter}
          mode="filter-content"
          sortOrder={"trueOrder"}/>
      </div>
    );

  }

  return (
    <Modal
      onHide={closeFilterModal}
      size="xl"
      show={showFilterModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Items: Filter By</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="filter-by-items-tabs">
            <Tab eventKey={1} title="Select">
              <div className="tabContentContainer">
                <div style={{width: '100%', padding: 0}}>
                  {generateFilterByContentSelectRows()}
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Attributes">
              <div className="tabContentContainer">
                <div style={{
                  width: '100%',
                  padding: 20,
                  height: '50vh',
                  overflow:'auto'}}>
                  { contentAttributesCheckBoxArray && contentAttributesCheckBoxArray.length > 0 ?
                    contentAttributesCheckBoxArray
                    :
                    <div className="switchDivStyle" key={"no-attributes"}>
                      <div className={`${classes.labelStyle}`}>
                        No Attributes
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className="swytchbackActiveButton" style={{width: 130, marginLeft: 5, marginRight: 5}} onClick={resetContentFilter}>Reset</Button>
        <Button className="swytchbackActiveButton" style={{width: 130, marginLeft: 5, marginRight: 5}} onClick={closeFilterModal}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterByItemsModal;