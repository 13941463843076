import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Tabs, Tab }  from 'react-bootstrap';
import "../../css/swydgetStyles.css";
import "../../css/swytchbackModal.css";
import QualtricsPushSwydgetButton from "../../hooks/admin/QualtricsPushSwydgetButton";



export default function SwydgetIntegrationsModal(props) {

  const [pushInitiated, setPushInitiated] = useState(false);

  const qualtricsPushInitiatedCallback = () => {
    setPushInitiated(true)
  }

  useEffect(() => {
    // code to run on component mount

    // console.log("ONLY ONCE");
  }, [])


  return (
    <Modal show={props.show} onHide={props.onHide} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Send to</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody">
        <div>
          <Tabs className="swytchbackModalTabs"
                id="integration-admin-tabs"
                defaultActiveKey="qualtrics">
            <Tab eventKey="qualtrics" title="Qualtrics">
              <div className="contentContainerCentered">
                { pushInitiated ?
                  <div className="feedbackContainer">
                    <div className="title">Push to Qualtrics Initiated</div>
                    <div className="message">This may take up to an hour. Check your qualtrics admin console for progress.</div>
                  </div>
                  :
                  <div style={{width: 200}}>
                    <QualtricsPushSwydgetButton user={props.user} swydget={props.swydget} qualtricsPushInitiatedCallback={qualtricsPushInitiatedCallback}/>
                  </div>
                }

              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button
          className="swytchbackActiveButton"
          style={{width: 100, marginLeft: 5, marginRight: 5}}
          onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
