import React, { useState } from 'react';
import { Paper, Popover } from '@mui/material';
import { Button, Modal } from 'react-bootstrap';
import '../../css/swytchbackModal.css';
import classes from "../../css/modules/AccountManager.module.css";
import SwytchbackTextField from './controls/SwytchbackTextField';
import SwytchbackFrameButton from './controls/SwytchbackFrameButton';
import SwytchbackButton from './controls/SwytchbackButton';



export default function ExportSwydgetModal(props) {

  const { onHideAction } = props;
  const { showModal } = props;

  const textField = {
    marginTop: 0,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
  };

  const swytchbackInputStyles = {
    fontSize: 14
  };

  const swytchbackInputLabelStyles = {
    fontSize: 16
  };

  const buttonStyle = {
    width: '100%',
    border: '1px solid red'
  };

  const [exportFileName, setExportFileName] = useState(props.swydget ? props.swydget.getSurveyNameStripped() : "");
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event) => {
    setExportFileName(event.target.value);
  };

  const exportJson = () => {

    const blob = new Blob([props.swydget.toExportJsonString()], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = exportFileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    // setShowPopover(!showPopover);
  }

  return (
    <Modal
      size="lg"
      onHide={onHideAction}
      show={showModal || false}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Survey Doc (JSON)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <div className="fullPaneContainer">
            <div className="contentContainer">
              <div className='columnCentredeContainer fullWidthPane shortModalHeight'>
                <div className={`${classes.sectionContainer}`}>
                  <SwytchbackTextField
                    id="exportNameTF"
                    style={{width: '100%'}}
                    label="Enter Export File Name"
                    value={exportFileName}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                
                  <SwytchbackFrameButton style={{width: 150}} onClick={exportJson}>
                    Export  
                  </SwytchbackFrameButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <SwytchbackButton onClick={onHideAction}>Close</SwytchbackButton>
      </Modal.Footer>
    </Modal>
  );
}