import Filter from "./Filter";
import Decision from "./Decision";

export default class WyshSnapshot
{
  constructor(created) {
    this.wyshDecisionCounts = {}; // new Map();
    this.wyshTakerCount = 0;
    this.wordsToOmit = [];
    this.selectedFreeResponseDecisions = [];
    this.created = created;
  }

  static createFromJson(json, created) {
    var snapshot = new WyshSnapshot(created);

    if (json && json.wysh_taker_count) {
      snapshot.wyshTakerCount = json.wysh_taker_count
    }

    if (json && json.wysh_decision_counts) {
      for (const k in json.wysh_decision_counts) {
        // snapshot.wyshDecisionCounts.set(k, json.wysh_decision_counts[k]);
        snapshot.wyshDecisionCounts[k] = json.wysh_decision_counts[k];
      }
    }

    if (json && json.data_scrubbing_dict) {
      if (json.data_scrubbing_dict.words_to_omit) {
        snapshot.wordsToOmit = json.data_scrubbing_dict.words_to_omit;
      }

      if (json.data_scrubbing_dict.selected_free_response_decisions) {
        snapshot.selectedFreeResponseDecisions = json.data_scrubbing_dict.selected_free_response_decisions;
      }
    }

    return snapshot;
  }
}
