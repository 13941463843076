import React from "react";
import { Image, Button, Modal }  from 'react-bootstrap';
import Iframe from 'react-iframe';

export default class SwydgetProductRecommendation extends React.Component {

  constructor() {
    super();
    this.product = {};
    this.isLiked = this.isLiked.bind(this);
    this.isDisliked = this.isDisliked.bind(this);
    this.openProductLinkModal = this.openProductLinkModal.bind(this);
    this.closeProductLinkModal = this.closeProductLinkModal.bind(this);

    this.state = {
      liked: true,
      showProductLinkModal: false
    };
  };

  isLiked() {
    this.setState({ liked: true });
  }

  isDisliked() {
    this.setState({ liked: false });
  }

  openProductLinkModal() {
    if (this.product.buyPage === '') {
      this.setState({ showProductLinkModal: true});
    }
    else {
      window.open(this.product.buyPage, '_parent');
    }
  }

  closeProductLinkModal() {
    this.setState({ showProductLinkModal: false });
  }

  getProductLabel(product) {

    let textDiv = {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 6,
      paddingRight: 6,
      textAlign: 'center',
      margin: 0,
      color: '#ffffff',
      fontWeight: 800,
      fontSize: 18,
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    }

    var productLabel = {

    };

    if (product.name.length > 0) {
      productLabel =
        <div style={textDiv}>
          {product.name}
        </div>

    }
    else {
      productLabel =
        <div style={textDiv}>
          {product.name}
        </div>
    }

    return productLabel;
  }

  getPriceLabel(product) {

    let priceLabelContainerDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto'
    };

    let textDiv = {
      padding: 0,
      margin: 0,
      color: '#ffffff',
      fontWeight: 800,
      fontSize: 18,
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    }

    var label = <div style={priceLabelContainerDivStyle}></div>

    if (product.price > 0) {
      label = <div style={priceLabelContainerDivStyle}>
        <div style={textDiv}>
          ${product.getPrice(2)}
        </div>
      </div>
    }

    return label;
  }

  generateMediaModalContent(cardHeightStyle) {

    if (this.product.buyPage === '') {

      let containerDivStyle = {
        height: '65vh',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      };

      let imageStyle = {
        // maxHeight: '100%',
        // maxWidth: '100%'
      };

      return (
        <div style={containerDivStyle}><Image style={imageStyle} src={this.product.getSecureImageUrl()} responsive="true"/></div>
      );
    } else {
      return (
        <Iframe url={this.product.buyPage}
          width="100%"
          height={cardHeightStyle}
          display="initial"
          position="relative"
          allowFullScreen/>
      );
    }
  }

  render() {

    const { idx } = this.props;
    const { numberOfRecommendations } = this.props;
    this.product = this.props.product;

    var cardHeight = 55;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';

    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 30
    };

    let cardContainerDivStyle = {
      marginTop: 10,
      marginBottom: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto'
    };

    let wyshDetailDivStyle = {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 6,
      width: cardWidthStyle,
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#ffffff'
    }

    let wyshCountDivStyle = {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 20,
      width: cardWidthStyle,
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#ffffff'
    }

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidthStyle,
      height: cardHeightStyle,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fffddd',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      marginBottom: 0,
      WebkitBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.4)',
      MozBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.4)',
      boxShadow: '0px 3px 25px 10px rgba(0,0,0,0.4)'
    };

    let productImageStyle = {
      width: cardWidthStyle,
      height: cardHeightStyle,
      objectFit: this.product.styles.objectFit
    }

    let nameAndPriceDivStyle = {
      position: 'absolute',
      width: '100%',
      top: 5,
      paddingLeft: 5,
      paddingRight: 5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'left'
    }

    let cardInstructionDivStyle = {
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 300
    }

    return (
      <div key={idx} style={containerStyle}>
      <div style={wyshCountDivStyle}>{idx + 1}/{numberOfRecommendations}</div>
        <div key={idx} ref={"card-" + idx} style={cardContainerDivStyle}>
          <div style={cardStyle} onClick={this.openProductLinkModal}>
            <div style={nameAndPriceDivStyle}>
              <div style={wyshDetailDivStyle}>{this.getProductLabel(this.product)}</div>
              <div style={wyshDetailDivStyle}>{this.getPriceLabel(this.product)}</div>
            </div>
            <Image src={this.product.getSecureImageUrl()} style={productImageStyle}></Image>
          </div>
        </div>
        <div style={cardInstructionDivStyle}>Tap Card to View</div>

        <Modal show={this.state.showProductLinkModal} onHide={this.closeProductLinkModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.product.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.generateMediaModalContent(cardHeightStyle)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeProductLinkModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
