/*

Utilities houses any and all useful tools Swytchback might need.

*/


export function startsWith(target, term) {
  if (target && term) {
    if ((target.indexOf(term) < 0) || (target.indexOf(term) > 0)) {
      return false;
    }
    else {
      return true;
    }
  }
  else {
    return false;
  }
}

export function endsWith(target, term) {
  if (target && term) {
    if (term.length > target.length) {
      return false;
    }
    else {
      var textAtEndOfTarget = target.substring(target.length - term.length, target.length)

      if (textAtEndOfTarget === term) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  else {
    return false;
  }

}
