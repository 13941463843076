import React, { Component } from 'react';
import { Checkbox, TextField } from '@mui/material';
import { Tabs, Tab, FormGroup, FormControl, Modal, Button }  from 'react-bootstrap';
import sha256 from "js-sha256";
import Dropzone from 'react-dropzone';
import classes from '../../css/modules/UserInfoFormModal.module.css';
import '../../css/imageuploaddialog.css';
import "../../productCreationModal.css";
import LoginStore from "../../stores/LoginStore";
import Auth from '../../services/AuthService';
import SwytchbackServiceFactory from "../../classes/services/SwytchbackServiceFactory";
import addContentImg from '../../Plus-Trans-96.png';

class UserInfoFormModal extends Component {

  constructor() {
    super();

    this.startUpdate = this.startUpdate.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateSuccessful = this.updateSuccessful.bind(this);
    this.updatePasswordSuccessful = this.updatePasswordSuccessful.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handlePrivacyPolicyChange = this.handlePrivacyPolicyChange.bind(this);
    this.openResetPasswordModal = this.openResetPasswordModal.bind(this);
    this.closeResetPasswordModal = this.closeResetPasswordModal.bind(this);


    this.modalTitle = "Title";
    this.modalMessage = "Message";

    this.loggedInUser = LoginStore.getLoggedInUser();
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }

    this.state = {
      loggedInUser: this.loggedInUser,
      showAlertModal: false,
      showResetPasswordModal: false,
      firstName: this.loggedInUser.firstName,
      lastName: this.loggedInUser.lastName,
      company: this.loggedInUser.company,
      privacyPolicyLink: this.loggedInUser.privacyPolicyLink,
      password: "",
      birthday: this.loggedInUser.dob,
      gender: this.loggedInUser.gender,
      authorizedServices: this.loggedInUser.authorizedServices,
      imageFiles: []
    };
  }

  componentDidMount() {
    LoginStore.on("loginStore_user_update_start", this.startUpdate);
    LoginStore.on("loginStore_user_update_successful", this.updateSuccessful);
    LoginStore.on("loginStore_user_logged_in", this.updatePasswordSuccessful);
  }

  componentWillUnmount() {
    LoginStore.removeListener("loginStore_user_update_start", this.startUpdate);
    LoginStore.removeListener("loginStore_user_update_successful", this.updateSuccessful);
    LoginStore.removeListener("loginStore_user_logged_in", this.updatePasswordSuccessful);
  }

  startUpdate() {

  }

  updatePasswordSuccessful() {
    this.closeResetPasswordModal();
  }

  updateUser() {
    this.loggedInUser.birthday = this.state.birthday;
    this.loggedInUser.firstName = this.state.firstName;
    this.loggedInUser.lastName = this.state.lastName;
    this.loggedInUser.company = this.state.company;
    this.loggedInUser.gender = this.state.gender;
    this.loggedInUser.privacyPolicyLink = this.state.privacyPolicyLink;

    if (this.state.imageFiles[0]) {
      Auth.uploadProfilePhoto(this.state.loggedInUser, this.state.imageFiles[0]);
    }
    else {
      Auth.updateUser(this.loggedInUser);
    }

    this.setState({imageFiles: []})
  }

  updatePassword() {
    Auth.updatePassword(this.loggedInUser, sha256(this.state.password));
  }

  updateSuccessful() {

    this.loggedInUser = LoginStore.getLoggedInUser();

    this.setState({
      loggedInUser: this.loggedInUser,
      showAlertModal: false,
      showResetPasswordModal: false,
      firstName: this.loggedInUser.firstName,
      lastName: this.loggedInUser.lastName,
      company: this.loggedInUser.company,
      password: "",
      birthday: this.loggedInUser.dob,
      gender: this.loggedInUser.gender,
      authorizedServices: this.loggedInUser.authorizedServices
    });
  }

  cleanUp(onHideAction) {
    //this.reset();
    console.log("removing imageFiles");
    this.setState({
      imageFiles: []
    })
    onHideAction();
  }

  clearImage() {
    this.setState({
      imageFiles: []
    });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      imageFiles: acceptedFiles
    });
  }

  openResetPasswordModal() {
    this.setState({
      showResetPasswordModal: true
    })
  }

  closeResetPasswordModal() {
    this.setState({
      showResetPasswordModal: false
    })
  }

  handleFirstNameChange(event) {
    this.setState({firstName: event.target.value})
  }

  handleLastNameChange(event) {
    this.setState({lastName: event.target.value})
  }

  handleCompanyChange(event) {
    this.setState({company: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  handleBirthdayChange(event) {
    this.setState({
      birthday: event.target.value
    });
  }

  handleCheckboxChange(evt) {

    var authSvcs = this.state.authorizedServices;

    if (evt.target.checked === true) {
      authSvcs[evt.target.name] = SwytchbackServiceFactory.getService(evt.target.name);
    }
    else {
      delete authSvcs[evt.target.name];
    }

    this.setState({
      authorizedServices: authSvcs
    });
  }

  handlePrivacyPolicyChange(evt) {
    this.setState({
      privacyPolicyLink: evt.target.value
    });
  }

  generateServicesCheckBoxes() {

    let divStyle = {
      padding: 0,
      margin: 0,
      height: 30,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row'
    }

    var array = [];

    for (var i = 0; i < SwytchbackServiceFactory.getAllServices().length; i++) {

      var shouldBeChecked = false;

      if (this.state.authorizedServices.hasOwnProperty(SwytchbackServiceFactory.getAllServices()[i].id)) {
        shouldBeChecked = true;
      }

      array.push(<div style={divStyle} key={SwytchbackServiceFactory.getAllServices()[i].id}>
                    <Checkbox
                      style={{
                        margin: 0,
                        padding: 0
                      }}
                      name={SwytchbackServiceFactory.getAllServices()[i].id}
                      checked={shouldBeChecked}
                      onChange={this.handleCheckboxChange}
                    >
                      {SwytchbackServiceFactory.getAllServices()[i].name}
                    </Checkbox>
                  </div>);
    }

    return array;
  }

  render() {

    // trigger to display the modal
    const { showModal } = this.props;

    // The method to execute when the modal is closed or cancelled manually
    const { onHideAction } = this.props;

    const { advancedMode } = this.props;

    const { cardHeight } = this.props;

    var cardWidth = cardHeight;
    
    let containerStyle = {
      width: '100%',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    };

    let titleStyle = {
      width: '100%',
      marginTop: 0,
      marginBottom: 10,
      padding: 0,
      textAlign: 'left',
      color: '#767876',
      fontSize: 20,
      fontWeight: 400
    }

    let formDivStyle = {
      width: '80%',
      marginTop: 20,
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }

    let fieldContainerDivStyle = {
      width: '100%',
      marginTop: 0,
      marginBottom: 20
    };

    let datePickerDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      paddingLeft: 5,
      paddingRight: 5,
      marginBottom: 40
    }

    let modalHeaderStyle = {
      backgroundColor: '#eceef0',
      color: '#606368'
    };

    let modalFooterStyle = {
      backgroundColor: '#eceef0',
      color: '#606368'
    }

    let buttonStyle = {
      width: 100,
      marginLeft: 5,
      marginRight: 5
    };

    console.log(`${classes.swytchbackModal}`);

    return (
      <Modal size="xl" show={showModal} onHide={() => this.cleanUp(onHideAction)}>
        <Modal.Header style={modalHeaderStyle} closeButton>
          <Modal.Title >User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: 0, margin: 0, width: 700}}>
          <Tabs className="productCreationModal" defaultActiveKey={1} id="UserInfoFormModal-Tabs">
            <Tab eventKey={1} title="Update Photo">
              <div className="tableContainerStyle">
                <div className="centeredContainerDivStyle">

                  { this.state.imageFiles.length > 0 ?
                    <div className="imagePreviewStyle">
                      <div className="imageContainerDivStyle">
                        {this.state.imageFiles.map((file) => <img key={0} alt='' src={URL.createObjectURL(file)} className="imageStyle" /> )}
                      </div>
                    </div>
                    :
                    <Dropzone 
                      onDrop={this.onDrop} 
                      onDropRejected={() => {console.log("drop rejected")}}
                      multiple={false} 
                      accept={{
                        'image/png': ['.png'],
                        'image/jpg': ['.jpg', '.jpeg'],
                        'image/gif': ['.gif']
                      }}
                    >  
                      {({getRootProps, getInputProps}) => (
                        <section className="swytchbackDropzoneContainer">
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="swytchbackDropzoneMessage">
                            { this.state.loggedInUser.photo === "" ?
                              <div className="imagePreviewStyle">
                                <div className="imageContainerDivStyle">
                                  <img src={addContentImg} className="addContentImageStyle" alt='add content'/>
                                  <div className="addContentInstructionsDiv">Click to Select</div>
                                  <div className="addContentInstructionsDiv">Image</div>
                                </div>  
                              </div>
                              :
                              <div className="imagePreviewStyle">
                                <div className="imageContainerDivStyle">
                                  <img src={this.state.loggedInUser.photo} className="imageStyle" alt='Swytchback card background'/>
                                </div>
                              </div>
                            } 
                            </div>  
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  }
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Update Details">
              <div style={containerStyle}>
                <div style={formDivStyle}>
                  <div style={titleStyle}>User Info</div>
                  <div style={fieldContainerDivStyle}>
                    <TextField
                      id="first_name"
                      label="Enter First Name"
                      className={`${classes.newSwydgetTitleInputStyle}`}
                      InputProps={{
                        classes: {
                          input: `${classes.swytchbackInputStyles}`,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `${classes.swytchbackInputLabelStyles}`
                        },
                      }}
                      value={this.state.firstName}
                      onChange={this.handleFirstNameChange}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="last_name"
                      label="Enter Last Name"
                      className={`${classes.newSwydgetTitleInputStyle}`}
                      InputProps={{
                        classes: {
                          input: `${classes.swytchbackInputStyles}`,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `${classes.swytchbackInputLabelStyles}`
                        },
                      }}
                      value={this.state.lastName}
                      onChange={this.handleLastNameChange}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="company"
                      label="Enter Company"
                      className={`${classes.newSwydgetTitleInputStyle}`}
                      InputProps={{
                        classes: {
                          input: `${classes.swytchbackInputStyles}`,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `${classes.swytchbackInputLabelStyles}`
                        },
                      }}
                      value={this.state.company}
                      onChange={this.handleCompanyChange}
                      margin="normal"
                      variant="outlined"
                    />

                    <div className={`${classes.labelStyle}`}>Birthday</div>
                    <div style={datePickerDivStyle}>
                      <TextField
                        id="bithdayDatePicker"
                        type="date"
                        value={this.state.birthday}
                        onChange={this.handleBirthdayChange}
                        className={`${classes.datePickerStyle}`}
                        InputProps={{
                          classes: {
                            input: `${classes.swytchbackCalendarInputStyles}`,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: `${classes.swytchbackCalendarInputLabelStyles}`
                          },
                        }}
                      />
                    </div>
                    <TextField
                      id="privacy_policy"
                      label="Enter Privacy Policy Link"
                      className={`${classes.newSwydgetTitleInputStyle}`}
                      InputProps={{
                        classes: {
                          input: `${classes.swytchbackInputStyles}`,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `${classes.swytchbackInputLabelStyles}`
                        },
                      }}
                      value={this.state.privacyPolicyLink}
                      onChange={this.handlePrivacyPolicyChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            { (advancedMode && advancedMode === true) &&
            <Tab eventKey={3} title="Services">
              <div style={containerStyle}>
                <div style={formDivStyle}>
                  <div style={fieldContainerDivStyle}>
                    <div style={titleStyle}>Select Swytchback Services</div>
                    {this.generateServicesCheckBoxes()}
                  </div>
                </div>
              </div>
            </Tab>
          }
          </Tabs>
        </Modal.Body>
        <Modal.Footer style={modalFooterStyle}>
        { this.state.imageFiles.length > 0 &&
          <Button className="swytchbackActiveButton" style={buttonStyle} onClick={this.clearImage}>Clear</Button>
        }
          <Button className="swytchbackActiveButton" style={buttonStyle} onClick={this.updateUser}>Apply</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UserInfoFormModal;