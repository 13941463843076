import dispatcher from "../Dispatcher";
import { useNavigate } from 'react-router';
import User from "../classes/User";
import SegmentBucket from "../classes/SegmentBucket";
import MessagingCampaignBucket from "../classes/MessagingCampaignBucket";
import Notification from "../classes/notifications/Notification";
import axios from "axios";

export function createUser(serverUrl, authEndpoint, username, passwordSha256, dob, firstName, lastName, gender) {

  dispatcher.dispatch({
    type: "LOGIN_CREATE_USER_START"
  });

  const uid = username;
  localStorage.setItem("username", uid);
  localStorage.setItem("password", passwordSha256);

  const clearText = uid + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);


  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + authEndpoint, config)
    .then((data) => {
        // get the response code and log the user in
        // 1. Get the code
        const { results } = data.data;

        var loggedInUser = User.createUserFromNetwork(uid, passwordSha256, results);

        console.log(loggedInUser);

        dispatcher.dispatch({
          type: "LOGIN_CREATE_USER_USER_EXISTS",
          loggedInUserJsonString: loggedInUser.toJsonString()
        });
    }
  )
  .catch(function (error) {

    console.log("HERE WE ACTUALLY CREATE THE ACCOUNT");
    console.log(error);

    var errorMessage = "";
    var errorCode = "";
    if (error.message === "Network Error") {
      // If it is a network error, that transcends anything application specific
      errorMessage = "An error occurred accessing the Swtychback servers. Please try again later.";
      errorCode = "network_error";

      dispatcher.dispatch({
        type: "LOGIN_CREATE_USER_NETWORK_ERROR",
        error: error,
        reason: errorCode,
        message: errorMessage
      });
    }
    else {
      // We are communicating properly, but login Failed. The reason message is
      // coming from the http response from the backend.
      //
      // Possible Reasons:
      // 1. Bad credentials
      // 2. Unknown user

      errorCode = error.response.data.message;
      if (errorCode === "Bad credentials") {
        errorCode = "bad_creds";
        errorMessage = "User already exists, but your password is incorrect.";

        dispatcher.dispatch({
          type: "LOGIN_CREATE_USER_BAD_CREDS",
          error: error,
          reason: errorCode,
          message: errorMessage
        });
      }
      else {
        errorCode = "unknown_user";
        errorMessage = "User not in system! Yay!!! Let's create him.";
        signUp(serverUrl, uid, passwordSha256, dob, firstName, lastName, gender)
      }
    }

    console.log(errorCode + ": " + errorMessage);
  });
}

export function signUp(serverUrl, username, passwordSha256, dob, firstName, lastName, gender) {

  console.log("signUp...");
  console.log(dob);

  var parametersJson = {
    "user-id": {
      "t": "email",
      "v": username
    },
    "password": passwordSha256,
    "dob": dob,
    "first_name": firstName,
    "last_name": lastName,
    "gender": gender
  }

  const endpoint = "/1/users/";

  dispatcher.dispatch({
    type: "LOGIN_CREATE_USER"
  });

  axios.post(serverUrl + endpoint, parametersJson)
    .then((data) => {

      const { results } = data.data;

      var loggedInUser = User.createUserFromNetwork(username, passwordSha256, results["user"]);

      console.log(loggedInUser);

      dispatcher.dispatch({
        type: "LOGIN_CREATE_USER_SUCCESSFUL",
        loggedInUserJsonString: loggedInUser.toJsonString()
      });
    }
  )
  .catch(function (error) {
    console.log(error);
    console.log("error");
  });
}

/*

updateUser

Updates a user's info. Does NOT update and profile photo info. That is
done in uploadProfilePhoto.

*/
export function updateUser(serverUrl, user) {

  // password is ALREADY sha256 encoded
  const passwordSha256 = user.password;
  const uid = user.userId
  const clearText = uid + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    first_name: user.firstName,
    last_name: user.lastName,
    company: user.company,
    dob: user.dob,
    gender: user.gender,
    privacy_policy_link: user.privacyPolicyLink,
    authorized_services: user.authorizedServices,
    oauth_artifacts_dict: user.oauthArtifactsDict
  };

  const endpoint = "/1/users/user/profile";

  dispatcher.dispatch({
    type: "LOGIN_UPDATE_USER_START"
  });

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {
        // get the response code and log the user in
        // 1. Get the code
        const { results } = data.data;

        console.log("trying to get the oauth stuff");
        console.log(results);
        var loggedInUser = User.createUserFromNetwork(uid, passwordSha256, results);

        dispatcher.dispatch({
          type: "LOGIN_UPDATE_USER_SUCCESSFUL",
          loggedInUserJsonString: loggedInUser.toJsonString()
        });
    }
  )
  .catch(function (error) {
    console.log(error);
    console.log("error");
  });
}

export function updateOauthArtifacts(serverUrl, user, oauthArtifacts) {
  // password is ALREADY sha256 encoded
  const passwordSha256 = user.password;
  const uid = user.userId
  const clearText = uid + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  console.log(oauthArtifacts);

  var parametersJson = {
    integration: oauthArtifacts.integration,
    authorization_code: oauthArtifacts.authorization_code,
    access_token: oauthArtifacts.access_token,
    refresh_token: oauthArtifacts.refresh_token
  };

  const endpoint = "/1/users/user/oauth_artifacts";

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {
        // get the response code and log the user in
        // 1. Get the code
        const { results } = data.data;

        console.log(results);
    }
  )
  .catch(function (error) {
    console.log(error);
    console.log("error");
  });

}

export function logoutUser(id) {
  dispatcher.dispatch({
    type: "LOGOUT_USER"
  });
}

/*
uploadProfilePhoto

Upload a user's profile image and update their user definition to reflect the
new image.
*/
export function uploadProfilePhoto(serverUrl, user, photoFile) {

  const endpoint = "/1/users/user/profile/photo";
  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  console.log("photo size %f", photoFile.size);
  var formData = new FormData();
  formData.append("photo_file", photoFile);

  axios.post(serverUrl + endpoint, formData, config)
    .then((data) => {

      updateUser(serverUrl, user);

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/*
getRegistrationStatusForUsers

Returns a particular Event/Survey
*/
export function inviteesLoaded(username, passwordSha256, serverUrl, inviteesDict) {

  // 1. Construct parametersJso
  // {
  //   "candidates" : [
  //     {
  //       "t" : "email",
  //       "v" : "test105@catboytech.com"
  //     },
  //     {
  //       "t" : "email",
  //       "v" : "test106@catboytech.com"
  //     },
  //     {
  //       "t" : "email",
  //       "v" : "test109@catboytech.com"
  //     },
  //     {
  //       "t" : "email",
  //       "v" : "test108@catboytech.com"
  //     },
  //     {
  //       "t" : "phone",
  //       "v" : "6502220105"
  //     }
  //   ]
  // }

  var parametersJson = {};
  var candidatesArray = [];

  var keys = Object.keys(inviteesDict);
  for (var i = 0; i < keys.length; i++) {
    candidatesArray.push({ "t": "email", "v": inviteesDict[keys[i]].userId });
  }

  parametersJson["candidates"] = candidatesArray;

  const knownUsersEndpoint = "/1/users/known";
  const myFriendsEndpoint = "/1/friends/";

  dispatcher.dispatch({
    type: "AUTH_FETCH_USERS_REGISTRATIOM_STATUS"
  });

  const clearText = username + ":" + passwordSha256;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var myFriends = [];
  var friendsToInvite = [];
  var nonFriendsToInvite = [];

  axios.all([
    axios.post(serverUrl + knownUsersEndpoint, parametersJson, config),
    axios.get(serverUrl + myFriendsEndpoint, config)
  ]).then(axios.spread(function(knownUsersResponse, myFriendsResponse) {

    var myFriendsJson = myFriendsResponse.data.results.friends;
    for (var i = 0; i < myFriendsJson.length; i++) {
      var f = User.createUserFromAddressBookJson(myFriendsJson[i]);
      if (f.isConnected === true) {
        myFriends.push(f);
      }
    }

    var keys = Object.keys(inviteesDict);

    for (i = 0; i < keys.length; i++) {
      if (User.isUserInArray(inviteesDict[keys[i]], myFriends) === true) {
        friendsToInvite.push(inviteesDict[keys[i]]);
      }
      else {
        nonFriendsToInvite.push(inviteesDict[keys[i]]);
      }
    }

    dispatcher.dispatch({
      type: "AUTH_FETCH_USERS_REGISTRATIOM_STATUS_SUCCESSFUL",
      myFriends: myFriends,
      friendsToInvite: friendsToInvite,
      nonFriendsToInvite: nonFriendsToInvite
    });


  })).catch(function (error) {
    console.log(error);
  });

}

/**
* addSegment
*
* Adds a segment in the User's profile. It returns the User's segmentBucket
*
* @param serverUrl
* @param user authenticated user
* @param segmentName
*/
export function addSegment(serverUrl, user, segmentName) {

  const endpoint = "/1/users/user/segment";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "segment_name": segmentName
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {


      dispatcher.dispatch({
        type: "AUTH_ADD_SEGMENT_SUCCESSFUL",
        segmentBucket: SegmentBucket.createSegmentBucketFromJson(data.data.results.segment_bucket.mid, data.data.results.segment_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* updateSegment
*
* Updates a segment in the User's profile. It returns the User's segmentBucket
*
* @param serverUrl
* @param user authenticated user
* @param segment
*/
export function updateSegment(serverUrl, user, segment) {

  const endpoint = "/1/users/user/segment";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "segment_id": segment.segment_id.toString(),
    "segment_name": segment.segment_name
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "AUTH_UPDATE_SEGMENT_SUCCESSFUL",
        segmentBucket: SegmentBucket.createSegmentBucketFromJson(data.data.results.segment_bucket.mid, data.data.results.segment_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* deleteSegment
*
* Deletes a segment in the User's profile. It returns the User's segmentBucket
*
* @param serverUrl
* @param user authenticated user
* @param segment
*/
export function deleteSegment(serverUrl, user, segment) {

  const endpoint = "/1/users/user/segment";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String},
    data: {
      "segment_id": segment.segment_id.toString()
    }
  };


  axios.delete(serverUrl + endpoint, config)
    .then((data) => {

      console.log("delete complete");
      dispatcher.dispatch({
        type: "AUTH_DELETE_SEGMENT_SUCCESSFUL",
        segmentBucket: SegmentBucket.createSegmentBucketFromJson(data.data.results.segment_bucket.mid, data.data.results.segment_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* addMessagingCampaign
*
* Adds a messaging campaign in the User's profile. It returns the User's messagingCampaignBucket
*
* @param serverUrl
* @param user authenticated user
* @param messagingCampaignName
*/
export function addMessagingCampaign(serverUrl, user, messagingCampaignName) {

  const endpoint = "/1/users/user/messaging_campaign";

  const clearText = user.userId + ":" + user.password;
  console.log(clearText);
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "messaging_campaign_name": messagingCampaignName,
    "messages": [],
    "phone_numbers": []
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {


      dispatcher.dispatch({
        type: "AUTH_ADD_MESSAGING_CAMPAIGN_SUCCESSFUL",
        messagingCampaignBucket: MessagingCampaignBucket.createMessagingCampaignBucketFromJson(data.data.results.messaging_campaign_bucket.mid, data.data.results.messaging_campaign_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* updateMessagingCampaign
*
* Updates a messaging campaign in the User's profile. It returns the User's messagingCampaignBucket
*
* @param serverUrl
* @param user authenticated user
* @param messagingCampaign
*/
export function updateMessagingCampaign(serverUrl, user, messagingCampaign) {

  const endpoint = "/1/users/user/messaging_campaign";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  console.log(messagingCampaign);

  var parametersJson = {
    "messaging_campaign_id": messagingCampaign.messaging_campaign_id.toString(),
    "messaging_campaign_name": messagingCampaign.messaging_campaign_name,
    "messages": messagingCampaign.messages,
    "phone_numbers": messagingCampaign.phone_numbers,
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "AUTH_UPDATE_MESSAGING_CAMPAIGN_SUCCESSFUL",
        messagingCampaignBucket: MessagingCampaignBucket.createMessagingCampaignBucketFromJson(data.data.results.messaging_campaign_bucket.mid, data.data.results.messaging_campaign_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* deleteMessagingCampaign
*
* Deletes a messaging campaign in the User's profile. It returns the User's messagingCampaignBucket
*
* @param serverUrl
* @param user authenticated user
* @param messagingCampaign
*/
export function deleteMessagingCampaign(serverUrl, user, messagingCampaign) {

  const endpoint = "/1/users/user/messaging_campaign";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String},
    data: {
      "messaging_campaign_id": messagingCampaign.messaging_campaign_id.toString()
    }
  };


  axios.delete(serverUrl + endpoint, config)
    .then((data) => {

      console.log("delete complete");
      dispatcher.dispatch({
        type: "AUTH_DELETE_MESSAGING_CAMPAIGN_SUCCESSFUL",
        messagingCampaignBucket: MessagingCampaignBucket.createMessagingCampaignBucketFromJson(data.data.results.messaging_campaign_bucket.mid, data.data.results.messaging_campaign_bucket)
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* sendMessaingCampaignMessages
*
* Send the messages for the argument messaging campaign
*
* @param serverUrl
* @param user authenticated user
* @param messagingCampaign
*/
export function sendMessaingCampaignMessages(serverUrl, user, messagingCampaign) {

  const endpoint = "/1/users/user/send_sms_to_contacts";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);
  var config = {
    headers: {'Authorization': base64String}
  };

  var parametersJson = {
    "phone_numbers": messagingCampaign.phone_numbers,
    "message": messagingCampaign.messages[0]
  }

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      dispatcher.dispatch({
        type: "AUTH_SEND_MESSAGING_CAMPAIGN_MESSAGES_SUCCESSFUL"
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}
