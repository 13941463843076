import React, { useState, useEffect } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import meStore from "../../stores/MeStore";
import Wysh from "../../classes/Wysh";
import MeService from "../../services/MeService";
import swytchbackStyles from "../../css/swytchbackStyles.css";
import swytchbackModal from "../../css/swytchbackModal.css";


export default function StimuliImportModal(props) {

  let reader = new FileReader();
  const importJsonPreFlightMessage = "Click 'Choose File' to select a stimuli file to import."
  const importJsonFileChosenMessage = "File selected and ready to upload. Click Upload to submit stimuli."
  const importJsonErrorMessage = "The stimuli file is invalid. Retry exporting from the source block or stimuli and importing again."
    
  const [selectedTabKey, setSelectedTabKey] = useState("blocks");
  const [stimuliImportJson, setStimuliImportJson] = useState(null);
  const [importJsonMessage, setImportJsonMessage] = useState(importJsonPreFlightMessage)
  const [importingState, setImportingState] = useState("ready");

  const tableStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 20
  }

  const rowTableStyle = {
    margin: 5
  }


  const importStimuli = () => {
    setImportingState("importing");
    var newWysh = Wysh.importWyshFromJson(props.swydget, stimuliImportJson);
    newWysh.parentWysh = props.parentWysh;
    MeService.importWysh(props.user, props.swydget, newWysh);
  }

  useEffect(() => {

    meStore.on("meStore-importStimuliSuccessful", uploadComplete);

    return () => {
      meStore.removeListener("meStore-importStimuliSuccessful", uploadComplete);
    }
  }, []);

  const uploadComplete = () => {
    setImportingState("done");
    setStimuliImportJson(null);
    close();
  }

  const close = () => {
    setImportingState("ready");
    props.handleClose();
  }

  const handleTabSelect = (key) => {
    setSelectedTabKey(key);
    setStimuliImportJson(null);
  }

  const isImportDisabled = () => {
    let isDisabled = true; 
    if (importingState === "importing" || importingState === "done") {
      isDisabled = true;
    } 
    else if (stimuliImportJson) {
      isDisabled = false;
    }
    
    return isDisabled;
  }

  const readWyshImportJson = () => {

    const stimuliImportJson = JSON.parse(reader.result);

    if (stimuliImportJson.hasOwnProperty("wysh_id"))  {
      setStimuliImportJson(stimuliImportJson);
      setImportJsonMessage(importJsonFileChosenMessage);
    }
    else {
      setStimuliImportJson(null);
      setImportJsonMessage(importJsonErrorMessage);
    }
  }

  const handleWyshImportFileChosen = (wyshImportFile) => {
    reader = new FileReader();
    reader.onloadend = readWyshImportJson;
    reader.readAsText(wyshImportFile);
  }
 
  return (
    <Modal show={props.show} onHide={close} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">
          <div className="feedbackContainer"></div>
          <div>Import Block/Stimuli JSON</div>
          <div className="feedbackContainer">
          {importingState === "importing" &&
            <CircularProgress size={20}/>
          }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody">
        <div>
          <Tabs 
            className="swytchbackModalTabs"
            id="stimuli-library-tabs-tabs"
            onSelect={handleTabSelect}
            defaultActiveKey="blocks">
            <Tab eventKey="blocks" title="Blocks">
              <div style={tableStyle}>
                <div style={rowTableStyle}>
                  <input
                    type="file"
                    onChange={e => handleWyshImportFileChosen(e.target.files[0])}
                  />
                </div>
                <div style={rowTableStyle}>
                  {importJsonMessage}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>  
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className={stimuliImportJson ? "swytchbackActiveButton" : "swytchbackInactiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={isImportDisabled()} 
          onClick={importStimuli}>
          Import
        </Button>
        <Button 
          className={importingState === "importing"  ? "swytchbackInactiveButton" : "swytchbackActiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={importingState === "importing"} 
          onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
