import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import BlockTypes from "../../../classes/BlockTypes";


export default function BlockTypeSelector(props) {

  const [blockType, setBlockType] = useState(props.blockWysh ? props.blockWysh.getBlockType() : BlockTypes.STANDARD);
  const [updatedBlockAttributesDict, setUpdatedBlockAttributesDict] = useState({});
  
  const handleClick = (blockType) => {
    setBlockType(blockType);
    const bw = props.blockWysh;
    bw.questionType = "binary";
    bw.setBlockType(blockType);
    bw.mustViewAll = false;
    if (blockType.equals(BlockTypes.PAIRWISE) === true || blockType.equals(BlockTypes.MAXDIFF) === true) {
      bw.questionType = "binary";
      updatedBlockAttributesDict["questionType"] = "binary";
      setUpdatedBlockAttributesDict(updatedBlockAttributesDict);
    }
    props.updateBlockCallback(bw, updatedBlockAttributesDict);
  }


  return (
    <div id="stimulusVerbatimComponent" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="hookContentContainer column">
        <div className="widget wide">
          <div className='title marginBottom'>
            Standard
          </div>
          <div className='content rowContainerSpaced'>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={blockType.equals(BlockTypes.STANDARD) === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.STANDARD) === true ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                Yes
              </Button>
              <Button 
                className={blockType.equals(BlockTypes.STANDARD) === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.STANDARD) === false ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                No
              </Button>
            </div>
          </div>
        </div>
        <div className="widget wide">
          <div className='title marginBottom'>
            Forced Choice
          </div>
          <div className='content rowContainerSpaced'>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={blockType.equals(BlockTypes.FORCEDCHOICE) === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.FORCEDCHOICE) === true ? true : false} 
                onClick={() => {handleClick(BlockTypes.FORCEDCHOICE)}}>
                Yes
              </Button>
              <Button 
                className={blockType.equals(BlockTypes.FORCEDCHOICE) === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.FORCEDCHOICE) === false ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                No
              </Button>
            </div>
          </div>
        </div>
        <div className="widget wide">
          <div className='title marginBottom'>
            Sequential Monadic
          </div>
          <div className='content rowContainerSpaced'>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={blockType.equals(BlockTypes.SEQUENTIALMONADIC) === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.SEQUENTIALMONADIC) === true ? true : false} 
                onClick={() => {handleClick(BlockTypes.SEQUENTIALMONADIC)}}>
                Yes
              </Button>
              <Button 
                className={blockType.equals(BlockTypes.SEQUENTIALMONADIC) === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.SEQUENTIALMONADIC) === false ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                No
              </Button>
            </div>
          </div>
        </div>
        <div className="widget wide">
          <div className='title marginBottom'>
            Pairwise
          </div>
          <div className='content rowContainerSpaced'>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={blockType.equals(BlockTypes.PAIRWISE) === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.PAIRWISE) === true ? true : false} 
                onClick={() => {handleClick(BlockTypes.PAIRWISE)}}>
                Yes
              </Button>
              <Button 
                className={blockType.equals(BlockTypes.PAIRWISE) === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.PAIRWISE) === false ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                No
              </Button>
            </div>
          </div>
        </div>
        <div className="widget wide">
          <div className='title marginBottom'>
            Max Diff
          </div>
          <div className='content rowContainerSpaced'>
            <div style={{width: '25%'}} className="rowContainer">
              <Button
                className={blockType.equals(BlockTypes.MAXDIFF) === true ? "swytchbackButton swytchbackButtonActive left" : "swytchbackButton left"} 
                style={{width: '50%'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.MAXDIFF) === true ? true : false} 
                onClick={() => {handleClick(BlockTypes.MAXDIFF)}}>
                Yes
              </Button>
              <Button 
                className={blockType.equals(BlockTypes.MAXDIFF) === false ? "swytchbackButton swytchbackButtonActive right" : "swytchbackButton right"} 
                style={{width: '50%', borderLeft: '1px solid white'}}
                disabled={props.readOnly}
                active={blockType.equals(BlockTypes.MAXDIFF) === false ? true : false} 
                onClick={() => {handleClick(BlockTypes.STANDARD)}}>
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
