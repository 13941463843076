import * as QualtricsActions from "../actions/QualtricsActions";


class QualtricsService {

  constructor() {

    this.serverUrl = "http://localhost";
    this.cloneServerUrl = "http://localhost";
    this.wm_new_port = 5002;
    this.wm_old_port = 5000;

    var appStage = process.env.REACT_APP_BACKEND;
    var backendUrl = process.env.REACT_APP_BACKEND_URL;
    var cloneBackendUrl = process.env.REACT_APP_CLONE_BACKEND_URL;

    if (backendUrl) {
      this.serverUrl = backendUrl;
      this.cloneServerUrl = cloneBackendUrl;
      if (backendUrl.indexOf("127.0.0.1") > -1) {
        this.wm_new_port = 7002;
        this.wm_old_port = 7000;
      }
      else {
        this.wm_new_port = 80;
        this.wm_old_port = 7000;
      }
    }
    else {
      if (appStage) {
        switch (appStage) {
          case "backend-dev":
            this.serverUrl = "https://backend-dev.swytchback.com";
            this.cloneServerUrl = "https://bridge-dev.swytchback.com";
            this.wm_new_port = 80;
            this.wm_old_port = 7000;
            break;
          case "backend-prod":
            this.serverUrl = "https://backend-prod.swytchback.com";
            this.cloneServerUrl = "https://bridge-prod.swytchback.com";
            this.wm_new_port = 80;
            this.wm_old_port = 7000;
            break;
          default:
            this.serverUrl = "http://localhost";
            this.cloneServerUrl = "http://localhost";
            this.wm_new_port = 5002;
            this.wm_old_port = 5000;
        }
      }
    }
  }

  /**
  * whoAmI
  *
  * Get the Swytchback user's information from Qualtrics
  *
  * @param user authenticated Swytchback user
  */
  whoAmI(user, whoAmICallback) {
    QualtricsActions.whoAmI(this.serverUrl + ":" + this.wm_new_port, user, whoAmICallback);
  }

  /**
  * getSurveys
  *
  * Get the Swytchback user's Qualtrics surveys
  *
  * @param user authenticated Swytchback user
  */
  getSurveys(user, getSurveysCallBack) {
    QualtricsActions.getSurveys(this.serverUrl + ":" + this.wm_new_port, user, getSurveysCallBack);
  }

  /**
  * createSurvey
  *
  * @param user authenticated Swytchback user
  * @param swydget Swydget to harvest survey details, blocks and stimuli
  * @param language English, Spanish, etc.
  * @param projectCategory Qualtrics project category (default: CORE)
  * @param createSurveyCallback Callback to update state of calling component
  */
  createSurvey(user, swydget, language, projectCategory, createSurveyCallback) {
    createSurveyCallback();
    QualtricsActions.createSurvey(this.serverUrl + ":" + this.wm_new_port, user, swydget, language, projectCategory, createSurveyCallback);
  }

  /**
  * postResponses
  *
  * @param user authenticated Swytchback user
  * @param swydget Swydget to harvest survey details, blocks and stimuli
  * @param postResponsesCallback Callback to update state of calling component
  */
  postResponses(user, swydget, qualtricsSurveyId, postResponsesCallback) {
    QualtricsActions.postResponses(this.serverUrl + ":" + this.wm_new_port, user, swydget, qualtricsSurveyId, postResponsesCallback);
  }
}

export default new QualtricsService()
