import styled from '@emotion/styled';
import { Button } from '@mui/material';

const SwytchbackButton = styled(Button)(({backgroundcolor='transparent', bordercolor="#c4c4c4", hoverbordercolor="#000000", textcolor="#000000", hoverbackgoundcolor="transparent", height=53}) => ({
  backgroundColor: backgroundcolor,
  color: textcolor,
  height: height,
  border: '1px solid',
  borderColor: bordercolor,
  borderRadius: 10,
  textTransform: 'none', // Prevent text transformation
  '&:hover': {
    backgroundColor: hoverbackgoundcolor,
    borderColor: hoverbordercolor, // Change background color on hover
  },
  '& .MuiTouchRipple-root': {
    color: '#157cf8',
    opacity: 0.5
  },
}));

export default SwytchbackButton;
