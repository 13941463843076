import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';

const SwytchbackCheckbox = styled(Checkbox)(({textcolor="#ffffff", checkedtextcolor="#4caf50"}) => ({
  color: textcolor,
  '& .MuiCheckbox-root': {
    color: textcolor
  },
  '&.Mui-checked': {
    color: checkedtextcolor,
  },
}));

export default SwytchbackCheckbox;
