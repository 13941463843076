import React from "react";
import { ProgressBar } from 'react-bootstrap';
import '../../css/swytchbackStats.css';

const PairwiseStimuliResults = ({ wysh }) => {
  const aggregatedResultsDivStyle = {
    padding: 0,
    color: '#329147',
    fontSize: 12,
    fontWeight: 500
  };

  return (
    <div style={aggregatedResultsDivStyle}></div>
  );
};

export default PairwiseStimuliResults;
