import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Popover, Paper } from '@mui/material';
import classes from "../../css/modules/AccountManager.module.css";
import SwytchbackFrameButton from './controls/SwytchbackFrameButton';
import SwytchbackTextField from './controls/SwytchbackTextField';


export default function ExportWyshPopover(props) {

  const [exportFileName, setExportFileName] = useState(props.stimuli ? props.stimuli.getExportFileName() : "");
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleChange = (event) => {
    setExportFileName(event.target.value);
  };

  const exportJson = () => {

    const blob = new Blob([props.stimuli.toExportJsonString()], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = exportFileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    <div style={{width: 130, marginLeft: 5, marginRight: 5}}>
      <Button
        className="swytchbackActiveButton fullWidth"
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          setShowPopover(!showPopover);
        }}
      >
        {props.buttonText}
      </Button>
      <Popover 
        id={"export-swydget-popover"} 
        open={showPopover} 
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setShowPopover(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: 5, // Adjust the radius to your preference
            },
          },
        }}
        >
        <Paper
          className={`${classes.paperStyle}`}>
          <div className={`${classes.title}`}>Set Export Name</div>
          <div className={`${classes.buttonContainerStyle}`}>
            <SwytchbackTextField
              id="exportNameTF"
              style={{width: '100%'}}
              label="Enter Export File Name"
              value={exportFileName}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div className={`${classes.buttonContainerStyle}`}>
            <SwytchbackFrameButton style={{width: '100%'}} onClick={exportJson}>
              Export  
            </SwytchbackFrameButton>
          </div>
        </Paper>
      </Popover>
    </div>



    
    
  );
}
