import React, { useState } from 'react';

const SwytchbackTextArea = (props) => {

  const [text, setText] = useState('');

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <textarea
      value={props.value}
      onChange={props.onChange}
      style={{
        width: '100%', // Fixed width
        height: '100px', // Fixed height
        border: 'none', // No border
        outline: 'none', // No outline
        resize: 'none', // No resize handle
        padding: '6px', // Optional padding
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontSize: 14
      }}
    />
  );
};

export default SwytchbackTextArea;
