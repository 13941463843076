import React, { useState, useEffect } from "react";
import { Button }  from 'react-bootstrap';
import Decision from "../../classes/Decision";
import QualtricsService from "../../services/QualtricsService";
import axios from "axios";
import "../../css/integrations.css";

export default function QualtricsPushSwydgetButton(props) {

  const [errorMsg, setErrorMsg] = useState(null);
  const [pushInitiated, setPushInitiated] = useState(false);

  const isInstalled = props.user && props.user.isIntegrationInstalled("qualtrics") ? true : false

  useEffect(() => {
    // code to run on component mount

    // console.log("ONLY ONCE");
  }, [])

  const qualtricsPushInitiatedCallback = () => {
    props.qualtricsPushInitiatedCallback()
  }

  return (
    <Button
      className={isInstalled ? "swytchbackActiveButton" : "swytchbackInactiveButton"}
      disabled={isInstalled ? false : true}
      style={{marginBottom: 6}}
      onClick={() => {
        QualtricsService.createSurvey(props.user, props.swydget, "english", "ResearchCore", qualtricsPushInitiatedCallback)
      }}>
      Push to Qualtrics
    </Button>
  );
}
