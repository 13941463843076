import React, { useState, useEffect } from "react";
import { Modal, Button }  from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import classes from '../../css/modules/ImportSwydgetModal.module.css';
import Event from "../../classes/Event";
import WyshMeProduct from "../../classes/WyshMeProduct";
import OrderedWyshList from "../../classes/OrderedWyshList";
import useHttpGet from "../../hooks/utilities/AxiosHttpGet";
import useHttpPut from "../../hooks/utilities/AxiosHttpPut";
import useHttpPost from "../../hooks/utilities/AxiosHttpPost";
import MeService from "../../services/MeService";
import "../../css/swytchbackStyles.css";
import SwytchbackService from "../../classes/services/SwytchbackService";


export default function ImportSwydgetModal(props) {

  const importJsonPreFlightMessage = "Click 'Choose File' to select a Swydget file to import."
  const importJsonFileChosenMessage = "Swydget file selected and ready to import. Click Upload to import."
  const importJsonImportingMessage = "Importing Swydget. Stand by..."
  const importJsonDoneMessage = "Swydget has been successfully imported."
  const importJsonErrorMessage = "The Swydget file is invalid. Retry exporting the source Swydget and importing again."
  var reader = new FileReader();
  const oldServerUrl = MeService.serverUrl + ":" + MeService.wm_old_port;
  const newServerUrl = MeService.serverUrl + ":" + MeService.wm_new_port;
  const { putData, putLoading, putError, put } = useHttpPut(props.user);
  const { postData, postLoading, postError, post } = useHttpPost(props.user);
  const { getData, getLoading, getError, get } = useHttpGet(props.user);

  const [importingState, setImportingState] = useState(props.importingState);
  const [importJsonMessage, setImportJsonMessage] = useState(importJsonPreFlightMessage);
  const [swydgetImportJson, setSwydgetImportJson] = useState(null);
  const [bakeryFreshSwydget, setBakeryFreshSwydget] = useState(null);

  useEffect(() => {

    setImportingState(props.importingState);
    if (props.importingState === "done") {
      setSwydgetImportJson(null);
      setImportJsonMessage(importJsonDoneMessage);
    }

  }, [props.importingState]);

  const readWyshImportJson = () => {

    const json = JSON.parse(reader.result);

    if (json.hasOwnProperty("eventId")){
      setSwydgetImportJson(json);
    }
    else {
      setImportJsonMessage(importJsonErrorMessage);
      setImportingState("done");
    }
  }

  const handleWyshImportFileChosen = (wyshImportFile) => {
    setImportJsonMessage(importJsonFileChosenMessage);
    reader = new FileReader();
    reader.onloadend = readWyshImportJson;
    reader.readAsText(wyshImportFile);
  }

  const swydgetCreationCallback = (swydgetData) => {

    if (swydgetImportJson && swydgetData && swydgetData.results && swydgetData.results.event) {
      // The Swydget was successfully created!!!
      const bakeryFreshSwydget = Event.createFromJson(swydgetData.results.event);
      setBakeryFreshSwydget(bakeryFreshSwydget);
      const completeSwydgetEndpoint = newServerUrl + "/1/events/event/" + bakeryFreshSwydget.eventId;
      const completeSwydgetEncodedEndpoint = completeSwydgetEndpoint.replace(/#/g, "%23");

      // Update the Swydget
      const updateSurveyJson = {
        "req_auth_token": "",
        "survey_name": swydgetImportJson.survey_name,
        "survey_type": swydgetImportJson.survey_type,
        "default_prompt": swydgetImportJson.default_prompt,
        "options": swydgetImportJson.options,
        "binary_icon_set_id": swydgetImportJson.binary_icon_set_id,
        "scalar_description": swydgetImportJson.scalarDescription,
        "mc_single_instruction": swydgetImportJson.mcSingleInstruction,
        "mc_multiple_instruction": swydgetImportJson.mcMultipleInstruction,
        "get_started_text": swydgetImportJson.get_started_text,
        "title": swydgetImportJson.title,
        "description": swydgetImportJson.description,
        "front_image": swydgetImportJson.front_image,
        "cover_photo_url": swydgetImportJson.cover_photo_url,
        "company_logo_url" : swydgetImportJson.company_logo_url,
        "wallpaper_url": swydgetImportJson.wallpaper_url,
        "reward_text": swydgetImportJson.reward_text,
        "reward_url": swydgetImportJson.reward_url,
        "reward_button_text": swydgetImportJson.reward_button_text,
        "social_sharing_channels": swydgetImportJson.social_sharing_channels,
        "call_to_action_instructions_text": swydgetImportJson.call_to_action_instructions_text,
        "contest_prize_text": swydgetImportJson.contest_prize_text,
        "call_to_action_submit_button_text": swydgetImportJson.call_to_action_submit_button_text,
        "tos_link": swydgetImportJson.tos_link,
        "randomize": swydgetImportJson.randomize,
        "issue_sub_groups": swydgetImportJson.issue_sub_groups,
        "sub_group_count": swydgetImportJson.sub_group_count,
        "limit_free_response": swydgetImportJson.limit_free_response,
        "limit_free_response_count": swydgetImportJson.limit_free_response_count,
        "show_results": swydgetImportJson.show_results,
        "location_enabled": swydgetImportJson.location_enabled,
        "default_product_str": JSON.stringify(swydgetImportJson.default_product),
      };

      console.log(updateSurveyJson);

      // Going to hit an API here WITHOUT THE WHOLE REACT ROUTER THING
      post(completeSwydgetEncodedEndpoint, updateSurveyJson, creatingSwydgetCompletedCallback);
    }
  }

  const creatingSwydgetCompletedCallback = (data) => {

    // get the Swydget from the back end
    if (data && data.request_dict && data.request_dict.event_id) {
      const eventId = data.request_dict.event_id;
      const encodedEventId = eventId.replace("#", "%23");
      const endpoint = newServerUrl + "/1/events/event/" + encodedEventId;
      get(endpoint, yetAnotherCallback);
    }
  }

  const yetAnotherCallback = (data) => {
    const eventJson = data.result.event;
    const swydget = Event.createFromJsonAndResults(eventJson, []);
    console.log(swydgetImportJson);
    const owl = OrderedWyshList.importOWLFromJson(swydget, swydgetImportJson['ordered_wysh_list']);
    const defaultProduct = WyshMeProduct.createFromJson(swydgetImportJson['default_product']);
    console.log(defaultProduct);
    MeService.importOrderedWyshList(props.user, swydget, owl);
  }

  const uploadSwydgetJson = () => {

    setImportJsonMessage(importJsonImportingMessage);
    setImportingState("importing");

    const endpoint = MeService.serverUrl + ":" + MeService.wm_old_port + "/event_list/create";

    var userJson = props.user.addressBookJson.contacts[0];

    props.user.addCamelCaseFieldsToUser(userJson);

    var parametersJson = {
      "address_book": props.user.addressBookJson,
      "user-id": userJson,
      "event_info": {
        name : "",
        note : "",
        invited : [

        ],
        when : "",
        where : ""
      }
    };

    // Going to hit an API here WITHOUT THE WHOLE REACT ROUTER THING
    put(endpoint, parametersJson, swydgetCreationCallback);
  }

  const close = () => {
    setSwydgetImportJson(null);
    setImportJsonMessage(importJsonPreFlightMessage);
    setImportingState("ready");
    props.handleClose(bakeryFreshSwydget ? bakeryFreshSwydget.eventId : null);
  }

  const uploadDisabled = importingState === "importing" || importingState === "done" ? true : false;

  return (
    <Modal show={props.show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={`${classes.divModalTitle}`}>
            <div>Import Swydget</div>
            {importingState === "importing" &&
              <CircularProgress size={20}/>
            }

          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <input
              type="file"
              onChange={e => handleWyshImportFileChosen(e.target.files[0])}
              disabled={importingState === "done"}
              style={{"padding":"10px"}}
            />
          </div>
          <div>
            {importJsonMessage}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
          <Button 
            className={uploadDisabled ? "swytchbackInactiveButton" : "swytchbackActiveButton"} 
            style={{margin: 5, width: 130}} 
            disabled={uploadDisabled ? true : false} 
            onClick={uploadSwydgetJson}
          >
            Upload
          </Button>
          <Button 
            className={`${classes.buttonStyle}` + importingState === "importing"  ? " swytchbackInactiveButton" : " swytchbackActiveButton"} 
            style={{margin: 5, width: 130}} 
            disabled={importingState === "importing"} 
            onClick={close}
          >
            Close
          </Button>
      </Modal.Footer>
    </Modal>
  );
}
