import React from "react";
import BinaryStats from "./BinaryStats";
import ScalarStats from "./ScalarStats";
import MultipleChoiceStats from "./MultipleChoiceStats";
import MultipleChoiceSingleStats from "./MultipleChoiceSingleStats";
import MaxDiffStimuliStats from "./MaxDiffStimuliStats";
import PairwiseStimuliStats from "./PairwiseStimuliStats";
import BlockTypes from "../../classes/BlockTypes";


export default class AdminWyshStats extends React.Component {
  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;

    const { mode } = this.props;

    var resultComponent;

    let questionType = wysh.questionType;

    if (wysh.parentWysh && wysh.parentWysh.isBlock() === true && (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) === true || wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE) === true)) {
      questionType = wysh.parentWysh.getBlockType().blockTypeId;
    }

    switch (questionType) {
      case "next":
        resultComponent = <div style={{ padding: 6 }}></div>
        break;
      case "free-response-only":
        resultComponent = <div style={{ padding: 6 }}></div>
        break;
      case "scalar":
        resultComponent = <ScalarStats wysh={wysh} snapshot={snapshot} mode={mode}/>;
        break;
      case "multiple-choice":
        resultComponent = <MultipleChoiceStats wysh={wysh} snapshot={snapshot} mode={mode}/>;
        break;
      case "single-select":
        resultComponent = <MultipleChoiceSingleStats wysh={wysh} snapshot={snapshot} mode={mode}/>;
        break;
      case BlockTypes.MAXDIFF.blockTypeId:
        resultComponent = <MaxDiffStimuliStats wysh={wysh} mode={mode}/>;
        break;  
      case BlockTypes.PAIRWISE.blockTypeId:
        resultComponent = <PairwiseStimuliStats wysh={wysh} mode={mode}/>;
        break;    
        
      default:
        resultComponent = <BinaryStats wysh={wysh} snapshot={snapshot} />;
    }

    return (
      <div style={{ padding: 6 }}>
      {resultComponent}
      </div>
    );
  }
}
