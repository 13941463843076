import React, { Component } from 'react';
import { Tab, Tabs, Table, Button, OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { TextField, Switch } from '@mui/material';
import classes from "../../css/modules/SwydgetOptionsModal.module.css";
import loginStore from "../../stores/LoginStore";
import MeService from "../../services/MeService";
import "../../css/swytchbackStyles.css";
import "../../css/swytchbackModal.css";


class SwydgetOptionsModal extends Component {

  constructor(props) {
    super(props);

    this.user = loginStore.getLoggedInUser();

    this.handleRandomizeClicked = this.handleRandomizeClicked.bind(this);
    this.handleIssueSubGroupClicked = this.handleIssueSubGroupClicked.bind(this);
    this.handleSubGroupCountChanged = this.handleSubGroupCountChanged.bind(this);
    this.handleLimitFreeResponseClicked = this.handleLimitFreeResponseClicked.bind(this);
    this.handleLimitFreeResponseCountChanged = this.handleLimitFreeResponseCountChanged.bind(this);
    this.updateBranchLogic = this.updateBranchLogic.bind(this);

    var eif = props.eventInFocus;
    var randomize = false;
    var issueSubGroups = false;
    var subGroupCount = 1;
    var limitFreeResponse = false;
    var limitFreeResponseCount = 1;


    if (eif) {
      randomize = eif.randomize;
      issueSubGroups = eif.issueSubGroups;
      subGroupCount = eif.subGroupCount;
      limitFreeResponse = eif.limitFreeResponse;
      limitFreeResponseCount = eif.limitFreeResponseCount;
    }

    this.state = {
      eventInFocus: props.eventInFocus || null,
      blockWysh: props.blockWysh || null,
      randomize: randomize,
      issueSubGroups: issueSubGroups,
      subGroupCount: subGroupCount,
      limitFreeResponse: limitFreeResponse,
      limitFreeResponseCount: limitFreeResponseCount
    };
  }

  handleRandomizeClicked(evt) {

    var eif = this.state.eventInFocus;
    eif.randomize = evt.target.checked;

    if (eif.randomize === false) {
      eif.issueSubGroups = false;
      eif.limitFreeResponse = false;
      eif.limitFreeResponseCount = 1;
      eif.subGroupCount = 1;
      this.setState({
        eventInFocus: eif,
        limitFreeResponseDisabled: true,
        issueSubGroupsDisabled: true,
        limitFreeResponseCount: 1,
        subGroupCount: 1
      });
    }
    else {
      this.setState({
        eventInFocus: eif,
        limitFreeResponseDisabled: false,
        issueSubGroupsDisabled: false
      });
    }

    this.setState({eventInFocus: eif});
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);

    // clear the WyshRouter for each WYSH
    var wyshes = this.state.eventInFocus.getWyshes();
    for (var i = 0; i < wyshes.length; i++) {
      wyshes[i].wyshRouter.clearAllBranchLogicStements();
    }

    MeService.updateWyshes(this.user, this.state.eventInFocus, wyshes);
  }

  handleIssueSubGroupClicked(evt) {

    var eif = this.state.eventInFocus;
    eif.issueSubGroups = evt.target.checked;

    if (eif.issueSubGroups === true && this.state.eventInFocus.limitFreeResponseCount > this.state.eventInFocus.subGroupCount) {
      eif.subGroupCount = this.state.subGroupCount;
    }
    else {
      eif.subGroupCount = 1;
    }

    this.setState({eventInFocus: eif});
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleSubGroupCountChanged(evt) {

    var fieldValue = evt.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      if (this.state.eventInFocus.randomize === true && this.state.eventInFocus.issueSubGroups === true && this.state.eventInFocus.orderedWyshList.wyshes.length > 2) {
        if (count < 1) {
          count = 1;
        }
        else if (count > Math.floor(this.state.eventInFocus.orderedWyshList.wyshes.length, 10)) {
          count = Math.floor(this.state.eventInFocus.orderedWyshList.wyshes.length, 10);
        }
      }
      else
      {
        count = 1;
      }

      var eif = this.state.eventInFocus;
      eif.subGroupCount = count;
      this.setState({eventInFocus: eif});
      MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }
  }

  handleLimitFreeResponseClicked(evt) {

    var eif = this.state.eventInFocus;
    eif.limitFreeResponse = evt.target.checked;
    if (eif.limitFreeResponse === true && this.state.eventInFocus.limitFreeResponseCount > this.state.eventInFocus.subGroupCount) {
      eif.limitFreeResponseCount = this.state.eventInFocus.limitFreeResponseCount;
    }
    else {
      eif.limitFreeResponseCount = 1;
    }
    this.setState({eventInFocus: eif});
    MeService.updateEvent(this.user, this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
  }

  handleLimitFreeResponseCountChanged(evt) {

    var fieldValue = evt.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      if (this.state.eventInFocus.randomize === true && this.state.eventInFocus.orderedWyshList.wyshes.length > 2) {
        if (count < 1) {
          count = 1;
        }
        else if (this.state.eventInFocus.issueSubGroups === true && this.state.eventInFocus.subGroupCount > 0 && count > this.state.eventInFocus.subGroupCount) {
          count = this.state.eventInFocus.subGroupCount;
        }
        else if (count > Math.floor(this.state.eventInFocus.orderedWyshList.wyshes.length, 10)) {
          count = Math.floor(this.state.eventInFocus.orderedWyshList.wyshes.length, 10);
        }
      }
      else
      {
        count = 1;
      }

      var eif = this.state.eventInFocus;
      eif.limitFreeResponseCount = count;
      this.setState({eventInFocus: eif});
      MeService.updateEvent(this.user,this.state.eventInFocus, this.state.title, this.state.rewardText, this.state.rewardUrl, this.state.contestPrizeText);
    }
  }

  updateBranchLogic(updatedWysh) {
    if (this.state.blockWysh) {
      var bw = this.state.blockWysh;
      bw.wyshRouter.branchLogicStatements = updatedWysh.wyshRouter.branchLogicStatements
      MeService.updateWysh(this.user, this.state.eventInFocus, bw);
    }
  }

  render() {

    const { showSwydgetOptionsModal, closeSwydgetOptionsModal, readOnly } = this.props;

    return (
      <Modal show={showSwydgetOptionsModal} onHide={closeSwydgetOptionsModal} enforceFocus={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Swydget Options</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'maxHeight': '70vh', 'overflowY': 'auto', padding: 0, margin: 0}}>
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="uncontrolled-tab-example">
            <Tab eventKey={1} title="Advanced">
              <div className={`${classes.tableContainerStyle}`}>
                <div className={`${classes.centeredContainerDivStyle}`}>
                  <form className={`${classes.container}`} noValidate autoComplete="off">
                    <div className={`${classes.toggleRowDivStyle}`}>
                      <div className={`${classes.columnLabelDivStyle}`}>
                        Randomize
                      </div>
                      <div className={`${classes.columnDataDivStyle}`}>
                        <Switch
                          disabled={readOnly}
                          checked={this.state.eventInFocus.randomize}
                          onChange={this.handleRandomizeClicked}
                          color="primary"
                        />
                      </div>
                    </div>
                    <div className={`${classes.toggleRowDivStyle}`}>
                      <div className={`${classes.columnLabelDivStyle}`}>
                        Batch
                      </div>
                      <div className={`${classes.columnDataDivStyle}`}>
                        <Switch
                          disabled={readOnly || !this.state.eventInFocus.randomize}
                          checked={this.state.eventInFocus.issueSubGroups}
                          onChange={this.handleIssueSubGroupClicked}
                          color="primary"
                        />
                      </div>
                    </div>
                    <div className={`${classes.toggleRowDivStyle}`}>
                      <div className={`${classes.columnLabelDivStyle}`}>

                      </div>
                      <div className={`${classes.columnDataDivStyle}`}>
                        <TextField
                          disabled={readOnly}
                          id="subGroupCount"
                          className={`${classes.numberTextField}`}
                          label="Count"
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.subGroupCount}
                          type="number"
                          onChange={this.handleSubGroupCountChanged}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={`${classes.toggleRowDivStyle}`}>
                      <div className={`${classes.columnLabelDivStyle}`}>
                        Limit Free Response
                      </div>
                      <div className={`${classes.columnDataDivStyle}`}>
                        <Switch
                          disabled={readOnly || !this.state.eventInFocus.randomize}
                          checked={this.state.eventInFocus.limitFreeResponse}
                          onChange={this.handleLimitFreeResponseClicked}
                          color="primary"
                        />
                      </div>
                    </div>
                    <div className={`${classes.toggleRowDivStyle}`}>
                      <div className={`${classes.columnLabelDivStyle}`}>

                      </div>
                      <div className={`${classes.columnDataDivStyle}`}>
                        <TextField
                          disabled={readOnly}
                          id="limitFreeResponseCount"
                          className={`${classes.numberTextField}`}
                          label="Count"
                          InputProps={{
                            classes: {
                              input: `${classes.swytchbackInputStyles}`,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: `${classes.swytchbackInputLabelStyles}`
                            },
                          }}
                          value={this.state.eventInFocus.limitFreeResponseCount}
                          type="number"
                          onChange={this.handleLimitFreeResponseCountChanged}
                          margin="normal"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button className="swytchbackActiveButton" onClick={closeSwydgetOptionsModal}>Close</Button>
        </Modal.Footer>
      </Modal>

    );
  };
}

export default SwydgetOptionsModal;
