import React from "react";

export default class MultipleChoiceStats extends React.Component {

  generateModeDivs(literalArray) {

    let divStyle = {
      textAlign: 'left'
    }

    var modes =[];
    for (var i = 0; i < literalArray.length; i++) {
      modes.push(<div key={i} style={divStyle}>{literalArray[i]}</div>);
    }
    return modes;
  }

  render() {

    const { wysh } = this.props;

    const modeStyle = (this.props.mode ? (this.props.mode === 'dark' ? "smallLabelStyle dark" : "smallLabelStyle light") : "smallLabelStyle light");

    let statDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginTop: 8,
      marginBottom: 8
    }

    let statLabelDivStyle = {
      fontWeight: 500,
      fontSize: 14
    }

    return (
      <div className={modeStyle} style={statDivStyle}>
        <div style={statLabelDivStyle}>
          
        </div>
      </div>
    );
  }
}
