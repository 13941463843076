import React from 'react';
import { Image, Button, ButtonToolbar }  from 'react-bootstrap';


export default function BlockToolbar(props) {

  const wysh = props.wysh;
  const submitFunction = props.submitFunction; 

  let toolbarContainerNoTopBorderDivStyle = {
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center'
  };

  let selectionButtonToolbarStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: 320,
    paddingTop: 20,
    margin: 0
  };

  let buttonImageStyle = {
    height: 50,
    borderRadius: '50%',
    WebkitBoxShadow: '0px 0px 10px 4px rgba(0,0,0,0.7)',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    MozBoxShadow: '0px 0px 10px 4px rgba(0,0,0,0.7)',  /* Firefox 3.5 - 3.6 */
    boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.7)'  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  };

  let rightButtonStyle = {
    border: 0,
    marginLeft: 10,
    marginRight: 10,
    padding: 0,
    borderRadius: '50%',
    backgroundColor: 'transparent'
  };

  return (
    <div style={toolbarContainerNoTopBorderDivStyle}>
      <ButtonToolbar style={selectionButtonToolbarStyle}>
        <Button style={rightButtonStyle} onClick={() => submitFunction("next", "0.0")}><Image style={buttonImageStyle} src="https://s3.us-west-2.amazonaws.com/swydget.swytchback.com/binaryIconSets/left_right_gray/right-arrow-button-gray.png"/></Button>
      </ButtonToolbar>
    </div>
  );
};