import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { Container, Row, Col, Image, Modal, Button }  from 'react-bootstrap';
import sha256 from "js-sha256";
import "../css/swytchbackModal.css";
import "../css/swytchbackStyles.css";
import classes from "../css/modules/Login.module.css";
import AuthService from '../services/AuthService';
import User from "../classes/User";
import LoginStore from "../stores/LoginStore";
import swytchBackLogo from '../SwytchbackLoginLogo.png';
import { TextField } from '@mui/material';

class Login extends React.Component {

  constructor() {
    super();
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.loginFailed = this.loginFailed.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }
    this.modalTitle = "Title";
    this.modalMessage = "Message";

    this.state = {
      loggedInUser: this.loggedInUser,
      isUserLoggedIn: this.isUserLoggedIn,
      showAlertModal: false
    };
  }

  componentWillMount() {
    this.registerWithLoginStore();
  }

  componentWillUnmount() {
    this.unRegisterWithLoginStore();
  }

  registerWithLoginStore() {
    // LoginStore.on("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.on("change-logout", this.setLoggedInUser);
    LoginStore.on("change-loginFailed", this.loginFailed);
  }

  unRegisterWithLoginStore() {
    // LoginStore.removeListener("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.removeListener("change-logout", this.setLoggedInUser);
    LoginStore.removeListener("change-loginFailed", this.loginFailed);
  }

  openAlertModal() {
    this.modalTitle = "Connecting to Swytchback...";
    this.modalMessage = "Attempting to log in..."
    this.setState({ showAlertModal: true });
  }

  closeAlertModal() {
    this.setState({ showAlertModal: false });
  }

  setLoggedInUser() {
    this.loggedInUser = LoginStore.getLoggedInUser();
    if (this.loggedInUser != null) {
      this.isUserLoggedIn = true;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: true
      });
    }
    else {
      this.isUserLoggedIn = false;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: false
      });
    }

    // this.props.navigationHandler("/");
  }


  login = (e) => {
    e.preventDefault();
    var username = this.state.username && this.state.username.length > 0 ? this.state.username.toLowerCase() : null;

    if (username) {
      AuthService.login(username, sha256(this.state.password), "/", this.props.navigationHandler);
      this.openAlertModal();
    }
  }

  logout(e) {
    e.preventDefault();
    AuthService.logout(this.state.username, this.state.password)
  }

  loginFailed() {

    if (LoginStore.errorOccurred === true) {
      this.modalMessage = LoginStore.errorMessage;
    }

    this.modalTitle = "Login Failed";

    this.setState({ showAlertModal: true });
  }


  handleUsernameChange(event) {
    this.setState({username: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  generateModal() {
    return (
      <Modal show={this.state.showAlertModal} onHide={this.closeAlertModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            {this.modalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeAlertModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  render() {

    let containerStyle = {
      height: '60vh',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let formStyle = {
      marginLeft: 0,
      marginRight: 0,
      width: '75%'
    }

    let loginBlockStyle = {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let submitButtonStyle = {
      width: '100%',
      backgroundColor: '#1994fc'
    }

    let copyrightDivStyle = {
      marginTop: 20
    }

    let titleStyle = {
      width: '100%',
      textAlign: 'center',
      marginTop: 40,
      marginBottom: 0,
      padding: 0,
      color: '#000000',
      fontSize: 24,
      fontWeight: 500
    }

    let dividerStyle = {
      width: '80%',
      height: 2,
      backgroundColor: '#b7b7b7'
    }

    let logoImageStyle = {
      height: 80,
      marginBottom: 15,
      paddingRight: 30
    }

    if (this.isUserLoggedIn === false) {
      return (
        <div style={{backgroundColor: '#ffffff'}}>
          <Container>
            <Row>
              <Col md={12}>
                <div className='swytchbackModalBody' style={{
                  padding: 20,
                  marginTop: 50,
                  width: '100%',
                  borderRadius: '10px',
                  WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                  MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
                  boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
                }}>
                  
                  <Image style={logoImageStyle} src={swytchBackLogo} responsive="true"/>
                  <div style={containerStyle}>
                    <div style={titleStyle}>Client Sign In</div>
                    <hr style={dividerStyle} />
                    <div className='columnCenteredContainer'>
                      <div className="widget wide">
                        <div className='content'>    
                          <TextField
                            disabled={false}
                            id="usernameTf"
                            className={`${classes.textField}`}
                            InputProps={{
                              classes: {
                                input: `${classes.swytchbackInputStyles}`,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: `${classes.swytchbackInputLabelStyles}`
                              },
                            }}
                            value={this.state.username}
                            onChange={this.handleUsernameChange}
                            margin="dense"
                            variant="outlined"
                            placeholder="Email"
                          />
                          <TextField
                            disabled={false}
                            id="passwordTf"
                            className={`${classes.textField}`}
                            InputProps={{
                              classes: {
                                input: `${classes.swytchbackInputStyles}`,
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: `${classes.swytchbackInputLabelStyles}`
                              },
                            }}
                            value={this.state.password}
                            type="password"
                            onChange={this.handleUPasswordChange}
                            margin="dense"
                            variant="outlined"
                            placeholder="Password"
                          />
                          <Button className={"swytchbackActiveButton " + `${classes.submitButton}`} onClick={(evt) => this.login(evt)}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>  
                  </div>  
                </div>
              </Col>
            </Row>
          </Container>
          {this.generateModal()}
        </div>
      );
    }
    else {
      return (
        <Navigate to="/" />
      );
    }

  }
}

const LoginWrapper = () => {

  const navigate = useNavigate();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <Login navigationHandler={handleNavigation}/>
}

export default LoginWrapper;
