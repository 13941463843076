import React from 'react';
import { TextField } from '@mui/material';
import { Container, Row, Col, FormControl, FormGroup, InputGroup, Modal, Image, Button }  from 'react-bootstrap';
import classes from '../css/modules/CreateScalarSurvey.module.css';
import loginStore from "../stores/LoginStore";
import meStore from "../stores/MeStore";
import MeSrvc from "../services/MeService";
import emojiIcon from '../assets/emoji_icon.png';
import { Picker } from 'emoji-mart';


class CreateScalarSurvey extends React.Component {

  constructor() {
    super();

    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.handleScalarDescriptionChange = this.handleScalarDescriptionChange.bind(this);
    this.createScalarSurvey = this.createScalarSurvey.bind(this);
    this.createEventComplete = this.createEventComplete.bind(this);
    this.openErrorModal = this.openErrorModal.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleNumberOfOptionsChange = this.handleNumberOfOptionsChange.bind(this);
    this.handleOptionNameChange = this.handleOptionNameChange.bind(this);
    this.displayEmojiPicker = this.displayEmojiPicker.bind(this);
    this.setEmojiPickerWrapperRef = this.setEmojiPickerWrapperRef.bind(this);
    this.handleClickOutsideEmojiWrapper = this.handleClickOutsideEmojiWrapper.bind(this);
    this.addEmoji = this.addEmoji.bind(this);
    this.isNextDisabled = this.isNextDisabled.bind(this);

    this.emojiPickerBottom = "20px";
    this.emojiPickerRight = "20px";
    this.emojiWrapperRef = null;

    this.nextButtonActiveStyle = {
      color: '#ffffff',
      width: '100%',
      backgroundColor: '#0059ff',
      borderColor: '#0059ff',
      marginTop: 12,
      float: 'right'
    };

    this.nextButtonInactiveStyle = {
      color: '#0059ff',
      width: '100%',
      backgroundColor: '#ffffff',
      borderColor: '#0059ff',
      marginTop: 12,
      float: 'right'
    };

    this.state = {
      question: "",
      scalarDescription: "",
      showErrorModal: false,
      options: [{"name": ""}, {"name": ""}],
      nextButtonStyle: this.nextButtonInactiveStyle,
      showEmojiPicker: false,
      optionInFocus: -1
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideEmojiWrapper);
  }

  componentWillMount() {
    meStore.on("change", this.createEventComplete);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideEmojiWrapper);
    meStore.removeListener("change", this.createEventComplete);
  }

  openErrorModal() {
    this.setState({ showErrorModal: true });
  }

  closeErrorModal() {
    this.setState({ showErrorModal: false });
  }


  createEventComplete() {
    // console.log("We are complete: " + meStore.latestCreatedEventId );
  }

  isNextDisabled() {
    if (this.state.question === undefined || this.state.question === null) {
      return true;
    }

    for (var i = 0; i < this.state.options.length; i++) {
      var option = this.state.options[i];
      if (option.name.trim() === "") {
        return true;
      }
    }

    return false;
  }


  clearForm() {
    this.setState({
      question: ""
    });
  }

  createScalarSurvey(e) {
    e.preventDefault();

    MeSrvc.createScalarSurvey(loginStore.getLoggedInUser(), meStore.newSwydgetTitle, this.state.question, this.state.scalarDescription, this.state.options);
  }

  isFormComplete(optionsArray, question) {
    // the next button should only be active AFTER the user has populated
    // ALL of the options/choices fields.

    var completed = false;

    for (var i = 0; i < optionsArray.length; i++) {
      var value = optionsArray[i].name;
      if (value.length > 0) {
        completed = true;
      }
      else {
        completed = false;
        break;
      }
    }

    // Question field must be set as well
    if (question.length === 0) {
      completed = false;
    }

    if (completed === true) {
      this.setState({nextButtonStyle: this.nextButtonActiveStyle});
    }
    else {
      this.setState({nextButtonStyle: this.nextButtonInactiveStyle});
    }

    return completed;
  }

  handleQuestionChange(event) {
    this.setState({ question: event.target.value });
    this.isFormComplete(this.state.options, event.target.value);
    // if (this.isFormComplete() === true) {
    //   this.setState({question: event.target.value,nextButtonStyle: this.nextButtonActiveStyle});
    // }
    // else {
    //   this.setState({question: event.target.value, nextButtonStyle: this.nextButtonInactiveStyle});
    // }
  }

  handleScalarDescriptionChange(event) {
    this.setState({ scalarDescription: event.target.value });
  }

  handleNumberOfOptionsChange(event) {
    let numberOfOptions = event.target.value;
    var options = [];

    for (var i = 0; i < numberOfOptions; i++) {
      options.push({"name": ""});
    }

    this.setState({options: options});

    this.isFormComplete(options, this.state.question);
  }

  handleOptionNameChange = (idx) => (evt) => {
    const newOptions = this.state.options.map((option, oidx) => {
      if (idx !== oidx) return option;
      return { ...option, name: evt.target.value };
    });

    this.setState({ options: newOptions });
    this.isFormComplete(newOptions, this.state.question);
  }

  displayEmojiPicker(idx) {
    this.setState({ showEmojiPicker: true, optionInFocus: idx});
  }

  setEmojiPickerWrapperRef(node) {
    this.emojiWrapperRef = node;
  }

  handleClickOutsideEmojiWrapper(e) {
    if (this.emojiWrapperRef && !this.emojiWrapperRef.contains(e.target)) {
      this.setState({ showEmojiPicker: false, optionInFocus: -1});
    }
  }

  addEmoji(e) {
    var revisedOptions = this.state.options;
    revisedOptions[this.state.optionInFocus].name = revisedOptions[this.state.optionInFocus].name + e.native;

    this.setState({ options: revisedOptions, showEmojiPicker: false, optionInFocus: -1});
  }

  generateChoiceFields() {

    let inputStyle = {
      height: 50,
      marginTop:15,
      backgroundColor: '#ffffff',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    }

    let buttonStyle = {
      height: 50,
      width: 40,
      marginTop:15,
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0
    }

    let emojiIconStyle = {
      height: 18,
      margin: 0,
      padding: 0
    }

    let rowStyle = {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    }

    let labelStyle = {
      width: '10%',
      textAlign: 'center',
      fontWeight: 300,
      fontSize: 20
    }



    return (

      <div>
        {this.state.options.map((option, idx) => (
          <div key={idx} style={rowStyle}>
            <div style={labelStyle}>{idx + 1}</div>
            <div style={{width: '90%'}}>
              <FormGroup controlId={"inputName" + idx}>
                <InputGroup>
                  <FormControl
                    key={idx}
                    style={inputStyle}
                    id={"inputName" + idx}
                    value={option.name}
                    type="text"
                    placeholder="Name..."
                    onChange={this.handleOptionNameChange(idx)}
                    maxLength={64}
                  />
                  <InputGroup.Button>
                    <Button bsStyle="info" style={buttonStyle} onClick={() => this.displayEmojiPicker(idx)}><Image src={emojiIcon} style={emojiIconStyle} /></Button>
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
            </div>
          </div>
        ))}
      </div>

    )
  }

  render() {

    const { classes } = this.props;

    var disableNext = this.isNextDisabled();

    let headerRowStyle = {
      borderBottom: 2,
      borderBottomColor: '#1994fc',
      borderBottomStyle: 'solid',
      marginBottom: 20
    };

    let toolbarStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flexDirection: 'row',
      height: 80,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10
    };

    let titleStyle = {
      color: '#53585f',
      textAlign: 'left',
      fontSize: 28,
      fontWeight: 100
    };

    let dividerStyle = {
      height: 74,
      borderBottom: 2,
      borderBottomColor: '#666',
      borderBottomStyle: 'solid',
      marginBottom: 0,
      paddingLeft: 0,
      textAlign: 'center'
    };

    let fullWidthWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    };

    let addContentFormDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 30,
      marginRight: 30
    };

    let tableContainerStyle = {
      border: 1,
      borderColor: '#afb8c8',
      borderStyle: 'solid',
      backgroundColor: '#f7f6f7'
    };

    let tableTitleDivStyle = {
      height: 60,
      backgroundColor: '#e0e5ee',
      color: '#53585f',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 'bold',
      paddingTop: 20
    };

    let widgetContentContainerDivStyle = {
      padding: 20,
      backgroundColor: '#f7f6f7'
    };

    let textAreaStyle = {
      height: 200,
      width: '100%',
      backgroundColor: '#ffffff',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    };


    return (
      <div>
        <div>
          <Container>
            <Row style={headerRowStyle}>
              <Col md={4} style={toolbarStyle}>
                <div style={titleStyle}>Quick-Create Scalar</div>
              </Col>
              <Col md={6} style={toolbarStyle}>

              </Col>
              <Col md={2} style={toolbarStyle}>
                <button disabled={disableNext} type="button" className="btn btn-primary" style={this.state.nextButtonStyle} onClick={this.createScalarSurvey}>Next</button>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={fullWidthWidgetContainerStyle}>
                <div style={tableContainerStyle}>
                  <div style={tableTitleDivStyle}>Swydget Prompt</div>
                  <div style={widgetContentContainerDivStyle}>
                    <FormGroup controlId="scalarSurveyQuestionTextarea">
                      <label>Prompt</label>
                      <FormControl
                        value={this.state.question}
                        onChange={this.handleQuestionChange}
                        style={textAreaStyle}
                        componentClass="textarea"
                        placeholder="Enter Swydget Prompt..."
                      />
                    </FormGroup>
                    <div>
                      <TextField
                        id="surveyFileName"
                        label="Scalar Description"
                        className={`${classes.scalarDescriptionInputStyle}`}
                        InputProps={{
                          classes: {
                            input: `${classes.swytchbackInputStyles}`,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: `${classes.swytchbackInputLabelStyles}`
                          },
                        }}
                        value={this.state.scalarDescription}
                        onChange={this.handleScalarDescriptionChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </div>
                    <div>
                      <FormGroup controlId="numberOfOptionsSelect">
                        <label>Select</label>
                        <FormControl componentClass="select" placeholder="select" onChange={this.handleNumberOfOptionsChange}>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                        </FormControl>
                      </FormGroup>
                    </div>
                  </div>
                  <div style={addContentFormDivStyle}>
                    {this.generateChoiceFields()}
                  </div>
                  {
                    this.state.showEmojiPicker &&

                    <div ref={this.setEmojiPickerWrapperRef} style={{zIndex: 1000, position: 'fixed', bottom: this.emojiPickerBottom, right: this.emojiPickerRight}}>
                      <Picker onSelect={this.addEmoji} showPreview={false}/>
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={dividerStyle}>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal show={this.state.showErrorModal} onHide={this.closeErrorModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Question Missing!</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              You must enter a question for the Survey.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeErrorModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CreateScalarSurvey;
