import React from "react";
import { Button, Modal }  from 'react-bootstrap';

export default class FriendsTableRow extends React.Component {
  constructor() {
    super();

    this.openActionModal = this.openActionModal.bind(this);
    this.closeActionModal = this.closeActionModal.bind(this);

    this.state = {
      showActionModal: false
    }
  }

  openActionModal() {
    this.setState({showActionModal: true});
  }

  closeActionModal() {
    this.setState({showActionModal: false});
  }

  onExecution(actionHandler, user) {
    actionHandler(user);
    this.closeActionModal();
  }

  render() {

    const { user } = this.props;
    const { icon} = this.props;
    const { glyphiconStyle } = this.props;
    const { isConnected } = this.props;
    const { actionHandler } = this.props;
    const { actionDisabled } = this.props;
    const { confirmationTitle } = this.props;
    const { confimationMessage } = this.props;
    const { confirmationButtonText } = this.props;

    if (user.lastName === "Bower") {
      console.log(user);
    }

    var cardHeight = 7;
    var cardHeightStyle = cardHeight + 'vh';

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardHeightStyle,
      height: cardHeightStyle,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundImage: "url('" + user.getProfilePhotoUrl() + "')",
      backgroundColor: '#fffddd',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      marginBottom: 0
    };

    let trStyle = {
      height: 100,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    }

    let tdStyle = {
      textAlign: 'center',
      verticalAlign: 'middle'
    }

    let nameTdStyle = {
      textAlign: 'left',
      verticalAlign: 'middle'
    }

    let tdButtonStyle = {
      textAlign: 'center',
      verticalAlign: 'middle'
    }

    let buttonStyle = {
      width: 50,
      height: 50,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#53585f',
      borderRadius: '50%',
      marginTop:0,
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0
    }

    var userLabel = user.firstName + " " + user.lastName;
    if (isConnected === false) {
      userLabel += " (friendship pending)";
    }


    return (
      <tr style={trStyle}>
        <td style={tdStyle}><div style={cardStyle}></div></td>
        <td style={nameTdStyle}>{userLabel}</td>
        <td style={tdButtonStyle}>
          <Button style={buttonStyle} disabled={actionDisabled} onClick={this.openActionModal}>{icon}</Button>
        </td>
        <Modal show={this.state.showActionModal} onHide={this.closeActionModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{confirmationTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confimationMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" onClick={() => this.onExecution(actionHandler, user)}>{confirmationButtonText}</Button>
          </Modal.Footer>
        </Modal>
      </tr>
    );
  }
}
