import React from "react";
import { ProgressBar }  from 'react-bootstrap';

export default class SwydgetScalarResults extends React.Component {


  _generateOptionTotalDivs(wysh, cardWidth) {

    var cardWidthStyle = cardWidth + 'vh';

    let aggregatedResultsDivStyle = {
      padding: 0,
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 4,
      color: '#ffffff',
      fontSize: 11,
      fontWeight: 'normal',
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    };

    let progressBarBackgroundColorStyle = {
      backgroundColor: '#e2effe',
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 10,
      height: 6
    };

    let progressBarLikedStyle = {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      paddingLeft: 10,
      minWidth: 10,
      backgroundColor: '#157cf8',
      borderRadius: 25,
      fontSize: 14
    };

    var optionsDivs = [];

    for (var i = 0; i < wysh.wyshOptions.length; i++) {
      optionsDivs.push(
        <div key={i} style={{width: cardWidthStyle}}>
          <div style={aggregatedResultsDivStyle}>{wysh.wyshOptions[i].resultLiteral}: {wysh.wyshOptions[i].decisionsCount}</div>
          <ProgressBar style={progressBarBackgroundColorStyle}>
            <ProgressBar style={progressBarLikedStyle} now={wysh.wyshOptions[i].decisionsCount * wysh.aggregatedResults.progressBarMultiplier} />
          </ProgressBar>
        </div>
      );
    }

    return optionsDivs;
  }

  render() {

    const { wysh } = this.props;

    var cardHeight = 55;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var cardWidthStyle = cardWidth + 'vh';

    let cardDetailsDivStyle = {
      width: '100%',
      paddingLeft: 5,
      paddingRight: 5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'left'
    };

    let aggregatedResultsContainerDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: cardWidthStyle
    };

    let progressBarContainerDivStyle = {
      marginTop: 0
    };

    let averageResultsDivStyle = {
      padding: 0,
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 4,
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 500,
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    }

    let secondaryTextDivStyle = {
      padding: 0,
      marginLeft: 4,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 4,
      color: '#ffffff',
      fontSize: 12,
      fontWeight: 300,
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)'
    }

    return (
      <div style={cardDetailsDivStyle}>
        <div style={averageResultsDivStyle}>
          You Chose: {wysh.wyshScore.resultLiteral}
        </div>
        <div style={secondaryTextDivStyle}>
          Others Chose:
        </div>
        <div style={aggregatedResultsContainerDivStyle}>
          <div style={progressBarContainerDivStyle}>
            {this._generateOptionTotalDivs(wysh, cardWidth * 0.8)}
          </div>
        </div>
      </div>
    );
  }
}
