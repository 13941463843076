import React, { useState, useEffect, useRef } from 'react';
import { FormControl, FormGroup, TextField } from '@mui/material';
import classes from '../../../css/modules/FreeResponseToolbar.module.css';
import "../../../css/multipleChoiceStyles.css";
import { ArrowCircleDown, ArrowCircleDownRounded, ArrowCircleRight, ArrowCircleUp } from '@mui/icons-material';
import SwytchbackTextField from '../../admin/controls/SwytchbackTextField';


export default function FreeResponseToolbar(props) {

  const inputRef = useRef(null);

  const submitFunction = props.submitFunction;

  const [choicesExpanded, setChoicesExpanded] = useState(false);
  const [choicesDivClasses, setChoicesDivClasses] = useState("choicesTrayStyle enable-scrolling collapsed");
  const [readOnly, setReadOnly] = useState(false);
  const [freeResponseAnswer, setFreeResponseAnswer] = useState("");

  useEffect(() => {
    inputRef.current.onblur = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      document.body.scrollTop = 0;
    };

    inputRef.current.click();
  });

  const toggleChoicesHeight = () => {

    switch (choicesExpanded) {
      case true:
        setChoicesDivClasses("choicesTrayStyle enable-scrolling collapsed")
        break;
      case false:
        setChoicesDivClasses("choicesTrayStyle enable-scrolling expanded")
        inputRef.current.setAttribute('autofocus', 'autofocus');
        inputRef.current.focus();
        break;
      default:
    }

    setChoicesExpanded(!choicesExpanded);
  }

  const submitFreeResponseDecision = () => {
    setFreeResponseAnswer("");
    setChoicesExpanded(false);
    setChoicesDivClasses("choicesTrayStyleNoTransition enable-scrolling collapsed");
    submitFunction(freeResponseAnswer);
  }

  return (
    <div className="containerDivStyle" style={{width: props.width, minWidth: 300}}>
      <FormControl component="fieldset" className="formControl">
        <FormGroup>
          <div className="contentDivStyle">
            <div id="freeResponseContainerDiv" className="choicesContainerStyle">
              <div className="instructionDivStyle fixedTitleDivStyle roundedCornersStyle">
                {props.wysh && props.wysh.freeResponseQuestions.length > 0 ? props.wysh.freeResponseQuestions[0] : "Enter your response here:"}
              </div>
              <div
                id="freeResponseAnswerDiv"
                className={choicesDivClasses}
                >
                <div id="freeResponseDiv" className="choicesDivStyle">
                  <div className="freeResponseMeatDivStyle">
                    <SwytchbackTextField
                      inputRef={inputRef}
                      style={{width: '100%'}}
                      id="productDescription"
                      value={freeResponseAnswer}
                      onChange={(e) => {setFreeResponseAnswer(e.target.value)}}
                      margin="normal"
                      variant="outlined"
                      multiline={true}
                      minRows={5}
                      placeholder="..."
                    />
                    <div className="buttonToolbarStyle">
                    { choicesExpanded ?
                      <div className="circle">
                        <ArrowCircleDown
                          style={{fontSize: 34}}
                          className="mcGlyphStyle"
                          onClick={() => {toggleChoicesHeight();}}
                        />
                      </div>
                      :
                      <div className="circle pulse">
                        <ArrowCircleUp
                          style={{fontSize: 34}}
                          className="mcGlyphStyle"
                          onClick={() => {toggleChoicesHeight();}}
                        />
                      </div>
                    }
                      <div className="freeResponseButtonDiv">
                      {freeResponseAnswer && freeResponseAnswer.length > 0 &&
                        <div className="circle pulse">
                          <ArrowCircleRight
                            style={{fontSize: 34}}
                            className="mcGlyphStyle"
                            onClick={() => {
                              submitFreeResponseDecision(freeResponseAnswer);
                            }}
                          />
                        </div>
                      }
                      </div>
                    </div>
                  </div>
                  <div
                    id="mcScrollBottomDiv"
                    style={{minHeight: 1, minWidth: 50}}>
                  </div>
                </div>
              </div>
              <div className="mcChoicesHeader">
                { choicesExpanded === false &&
                  <div className="circle pulse">
                    <ArrowCircleUp
                      style={{fontSize: 34}}
                      className="mcGlyphStyle"
                      onClick={() => {toggleChoicesHeight();}}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );

}
