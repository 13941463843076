import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import SwytchbackSelect from './SwytchbackSelect';

const SwytchbackCheckboxSelect = ({swydget, snapshot, onChange=null, snapshotIdsToDisplay=[]}) => {

  const [selectedSnapshots, setSelectedSnapshots] = useState(snapshotIdsToDisplay);

  const generateSnapshotsMenuItems = () => {

    const snapshots = Array.from(swydget.snapshotContainer.snapshots.values());
    snapshots.sort((a, b) => b.created - a.created);

    const menuItems = snapshots.map((s, snapshotIndex) => {
        if (s.equals(snapshot) === false) {
          return (
            <MenuItem key={snapshotIndex} value={s.created}>{s.name}</MenuItem>
          )
        }
      }
    );

    return menuItems;
  }

  useEffect(() => {

    if (snapshot) {
      if (snapshotIdsToDisplay.length > 0) {
        if (snapshotIdsToDisplay[0] === -1) {
          setSelectedSnapshots([]);  
        }
        else {
          setSelectedSnapshots(snapshotIdsToDisplay);
        }
      }
      else {
        const snapshots = Array.from(swydget.snapshotContainer.snapshots.values());
        snapshots.sort((a, b) => b.created - a.created);
      }
    }
  }, [snapshotIdsToDisplay]);

  const handleChange = (evt) => {

    if (onChange) {
      onChange(evt);
    }

    setSelectedSnapshots(evt.target.value);
  };

  return (
    <SwytchbackSelect
      multiple
      style={{width: '100%', color: '#000000'}}
      value={snapshot ? selectedSnapshots : []}
      disabled={snapshot ? false : true}
      onChange={handleChange}
    >
      {generateSnapshotsMenuItems()}
    </SwytchbackSelect>
  );
};

export default SwytchbackCheckboxSelect;
