import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { geolocated } from 'react-geolocated';
import Swydget from "../../components/Swydget";

function SwydgetLocationEnabled({ eventInFocus, userId=undefined }) {

  console.log("SwydgetLocationEnabled!!!");
  if (userId !== undefined) {
    return <Swydget email={userId} eventInFocus={eventInFocus}/>
  }
  else {
    return <Swydget eventInFocus={eventInFocus}/>
  }
}

export default SwydgetLocationEnabled;

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(SwydgetLocationEnabled);
