import Segment from './Segment';

export default class SegmentBucket
{
  constructor() {
    this.version = 1;
    this.mid = "";
    this.segments = {};
  }

  initSegmentBucketFromJson(mid, json) {

    this.mid = mid;

    for (var k in json.segments) {
      var segJson = json.segments[k];
      var s = new Segment();

      s.segment_id = segJson.segment_id;
      s.segment_name = segJson.segment_name;
      s.ordinal = segJson.ordinal;
      s._created = segJson._created;
      s._updated = segJson._updated;
      this.segments[k] = s;
    }
  }

  getSegment(segmentId) {
    return this.segments[segmentId];
  }

  _getSegmentByOrdinal(ordinal) {
    for (var k in this.segments) {
      if (this.segments.hasOwnProperty(k)) {
        if (this.segments[k].ordinal === ordinal) {
          return this.segments[k];
        }
      }
    }

    return null;
  }

  inflateSegmentOrdinalMapping(segmentationMapping) {
    for (var mid in segmentationMapping) {
      segmentationMapping[mid] = this._getSegmentByOrdinal(segmentationMapping[mid]);
    }

    return segmentationMapping;
  }

  static createSegmentBucketFromJson(mid, json)
  {
    var segmentBucket = new SegmentBucket();
    segmentBucket.initSegmentBucketFromJson(mid, json);
    return segmentBucket;
  }

}
