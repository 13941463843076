import * as LoginActions from "../actions/LoginActions";
import LoginActionsHook from "../hooks/actions/LoginActionsHook";


class AuthService {

  constructor() {

    this.authEndpoint = "/1/users/user/profile";
    this.serverUrl = "http://localhost";
    this.wm_new_port = 5002;

    var appStage = process.env.REACT_APP_BACKEND;
    var backendUrl = process.env.REACT_APP_BACKEND_URL;

    if (backendUrl) {
      this.serverUrl = backendUrl;
      if (backendUrl.indexOf("127.0.0.1") > -1) {
        this.wm_new_port = 7002;
      }
      else {
        this.wm_new_port = 80;
      }
    }
    else {
      if (appStage) {
        switch (appStage) {
          case "backend-dev":
            this.serverUrl = "https://backend-dev.swytchback.com";
            this.wm_new_port = 80;
            break;
          case "backend-prod":
            this.serverUrl = "https://amazon.wyshme.com";
            this.wm_new_port = 80;
            break;
          default:
        }
      }
    }
  }


  login(username, password, destination, navigationHandler, firstIndex=0, lastIndex=9) {
    // 1. Fetch profile from back extends
    //    - HTTP 200 means success!!!
    //    - Anything else is failure
    // 2. Store creds locally

    const { loginUser } = LoginActionsHook("user");
    loginUser(username, password, this.serverUrl + ":" + this.wm_new_port, this.authEndpoint, destination, navigationHandler, firstIndex, lastIndex);
  }

  logout() {
    LoginActions.logoutUser();
  }

  createUser(username, password, dob, firstName, lastName, gender) {
    // 1. Fetch profile from back extends
    //    - HTTP 200 means success!!!
    //    - Anything else is failure
    // 2. Store creds locally

    LoginActions.createUser(this.serverUrl + ":" + this.wm_new_port, this.authEndpoint, username, password, dob, firstName, lastName, gender);
  }

  updateUser(user) {
    LoginActions.updateUser(this.serverUrl + ":" + this.wm_new_port, user);
  }

  updateOauthArtifacts(user, oauthArtifacts) {
    LoginActions.updateOauthArtifacts(this.serverUrl + ":" + this.wm_new_port, user, oauthArtifacts);
  }

  /*
  inviteFriend

  Invite a friend
  POST /1/friends/
  {
  	"user_id": {
                "t": "email",
                "v": "test105@catboytech.com"
              },
  	"first_name": "James",
  	"last_name": "Hetfield"
  }

  */
  inviteFriend(user, navigationHandler, email, firstName, lastName) {
    const { inviteFriend } = LoginActionsHook();
    inviteFriend(this.serverUrl + ":" + this.wm_new_port, user, navigationHandler, email, firstName, lastName);
  }

  /*
  deleteFriend

  Deletes a friend from the User's list of friends

  */
  deleteFriend(user, navigationHandler, friendToDelete) {
    const { deleteFriend } = LoginActionsHook();
    deleteFriend(this.serverUrl + ":" + this.wm_new_port, user, navigationHandler, friendToDelete);
  }

  /*
  acknowledgeFriendRequest

  Send an acknowlegedment of a friend request.
  POST /1/friends/invitations/responses/
  {
  	"msg_id": "38",
  	"accept": true,
  	"acknowledge": true
  }
  */
  acknowledgeFriendRequest(user, navigationHandler, friendRequestNotification, accept) {

    const { acknowledgeFriendRequest } = LoginActionsHook();
    acknowledgeFriendRequest(this.serverUrl + ":" + this.wm_new_port, user, navigationHandler, friendRequestNotification, accept);
  }

  /*
  updatePassword

  Send an acknowlegedment of a friend request.
  PUT /1/users/user/password
  {
  	"password": "eec308d1635553649b9605e55124726e11546ff760e3218c05a8256a02755583"
  }
  */
  updatePassword(user, navigationHandler, newPasswordSha256) {
    const { updatePassword } = LoginActionsHook();
    updatePassword(this.serverUrl + ":" + this.wm_new_port, user, navigationHandler, newPasswordSha256);
  }


  /*
  uploadProfilePhoto

  Upload a profile image for the logged in user.

  POST /1/users/user/profile/photo
  */
  uploadProfilePhoto(user, photoFile) {
    LoginActions.uploadProfilePhoto(this.serverUrl + ":" + this.wm_new_port, user, photoFile);
  }


  signUp(username, password, dob, firstName, lastName, gender) {
    LoginActions.signUp(this.serverUrl + ":" + this.wm_new_port, username, password, dob, firstName, lastName, gender);
  }

  handleAuth() {

  }

  inviteesLoaded(user, inviteesDict) {
    LoginActions.inviteesLoaded(user.userId, user.password, this.serverUrl + ":" + this.wm_new_port, inviteesDict);
  }

  addSegment(user, segmentName) {
    LoginActions.addSegment(this.serverUrl + ":" + this.wm_new_port, user, segmentName);
  }

  updateSegment(user, segment) {
    LoginActions.updateSegment(this.serverUrl + ":" + this.wm_new_port, user, segment);
  }

  deleteSegment(user, segment) {
    LoginActions.deleteSegment(this.serverUrl + ":" + this.wm_new_port, user, segment);
  }

  addMessagingCampaign(user, messagingCampaignName) {
    LoginActions.addMessagingCampaign(this.serverUrl + ":" + this.wm_new_port, user, messagingCampaignName);
  }

  updateMessagingCampaign(user, messagingCampaign) {
    LoginActions.updateMessagingCampaign(this.serverUrl + ":" + this.wm_new_port, user, messagingCampaign);
  }

  deleteMessagingCampaign(user, messagingCampaign) {
    LoginActions.deleteMessagingCampaign(this.serverUrl + ":" + this.wm_new_port, user, messagingCampaign);
  }

  sendMessaingCampaignMessages(user, messagingCampaign) {
    LoginActions.sendMessaingCampaignMessages(this.serverUrl + ":" + this.wm_new_port, user, messagingCampaign);
  }
}

export default new AuthService()
