import React, { useState, useEffect, useCallback } from 'react';
import { Image, Modal, Button, Tabs, Tab }  from 'react-bootstrap';
import "../../css/swydgetStyles.css";
import "../../css/swytchbackModal.css";
import QualtricsAdmin from "../../hooks/admin/QualtricsAdmin";
import LoginStore from "../../stores/LoginStore";
import AuthService from "../../services/AuthService";


const installQualtrics = () => {
  window.location.replace("/install_qualtrics");
}

const disconnectQualtrics = () => {
  var loggedInUser = LoginStore.getLoggedInUser();
  if (loggedInUser.oauthArtifactsDict.qualtrics) {
    delete loggedInUser.oauthArtifactsDict.qualtrics;
    AuthService.updateUser(loggedInUser);
  }
}

export default function IntegrationsModal(props) {

  useEffect(() => {
    // code to run on component mount

    // console.log("ONLY ONCE");
  }, [])


  return (
    <Modal show={props.show} onHide={props.onHide} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Integrations</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody">
        <div>
          <Tabs className="swytchbackModalTabs"
                id="integration-admin-tabs"
                defaultActiveKey="qualtrics">
            <Tab eventKey="qualtrics" title="Qualtrics">
              <div className="contentContainerCentered">
                <QualtricsAdmin user={props.user} installMethod={installQualtrics} disconnectMethod={disconnectQualtrics}/>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button
          className="swytchbackActiveButton"
          style={{width: 100, marginLeft: 5, marginRight: 5}}
          onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
