export default class TakerData
{
  constructor() {
    this.takerCount = 0;
    this.takerCompletedCount = 0;
    this.completionPct = 0.0;
    this.lastCompletedDate = null;
  }

  static createFromJson(eventJson) {
    var td = new TakerData();
    td.takerCount = eventJson.taker_count ? eventJson.taker_count : 0;
    td.takerCompletedCount = eventJson.taker_completed_count ? eventJson.taker_completed_count : 0;
    td.completionPct = eventJson.completion_pct ? eventJson.completion_pct : 0.0;
    td.lastCompletedDate = eventJson.last_completed_date ? new Date(eventJson.last_completed_date * 1000) : null;

    return td;
  }

}
