import React from "react";
import moment from 'moment';

export default class SurveyTakerTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.keyValues = [];
    this.dates = [];

    this.generateKeyDivs = this.generateKeyDivs.bind(this);
    this.generateCreatedDivs = this.generateCreatedDivs.bind(this);
    this.toJsonArray = this.toJsonArray.bind(this);


  }

  toJsonArray() {
    var jsonArray = [];

    for (var i = 0; i < this.keyValues.length; i++) {
      for (var j = 0; j < this.dates.length; j++) {
        var row = [this.keyValues[i], this.dates[j]]
        jsonArray.push(row);
      }
    }

    return jsonArray;
  }

  generateKeyDivs (surveyTaker) {
    var divs = [];
    var keysTypesDict = surveyTaker['contact']['keys'];

    var i = 0;
    for (var key in keysTypesDict) {
      if (key !== "wyshListName" && key !== "anonymous_id") {
        var keyTypeArray = keysTypesDict[key];
        for (var j = 0; j < keyTypeArray.length; j++) {
          divs.push(<div key={i}>{keyTypeArray[j]['v']}</div>);
          this.keyValues.push(keyTypeArray[j]['v']);
          i++;
        }
      }
    }

    return divs;
  }

  generateCreatedDivs (surveyTaker) {
    var divs = [];
    var datesSurveyTaken = [];

    for (var i = 0; i < surveyTaker['created'].length; i++) {
      var m = moment(surveyTaker['created'][i]);

      // Check to see if we already have this date in the datesSurveyTaken array
      if (datesSurveyTaken.length > 0) {
        var dateAlreadyLoaded = false;
        for (var j = 0; j < datesSurveyTaken.length; j++) {
          if ((m.year() === datesSurveyTaken[j].year() && m.month() === datesSurveyTaken[j].month() && m.date() === datesSurveyTaken[j].date())) {
            dateAlreadyLoaded = true;
          }
        }
        if (dateAlreadyLoaded === false) {
          datesSurveyTaken.push(m);
          this.dates.push(m);
        }
      }
      else {
        datesSurveyTaken.push(m);
        this.dates.push(m);
      }
    }

    for (i = 0; i < datesSurveyTaken.length; i++) {
      divs.push(<div key={i}>{datesSurveyTaken[i].format("YYYY-MM-DD")}</div>);
    }


    return divs;
  }

  render() {

    const { surveyTaker } = this.props;

    let rightTdStyle = {
      textAlign: 'right',
      verticalAlign: 'top'
    }

    let leftTdStyle = {
      textAlign: 'left',
      verticalAlign: 'top'
    }

    var keyDivs = this.generateKeyDivs(surveyTaker);
    var createdDivs = this.generateCreatedDivs(surveyTaker);


    return (
      <tr style={{borderBottom: 1, borderBottomColor: '#afb8c8', borderBottomStyle:'solid'}}>
        <td style={leftTdStyle}>
          {keyDivs}
        </td>
        <td style={rightTdStyle}>
          {createdDivs}
        </td>
      </tr>
    );
  }
}
