import React, { Component } from 'react';
//import '../App.css';

class StepNumbers extends Component {

  render() {

    const { idx } = this.props;
    const { totalSteps } = this.props;



    let numberCircleSelectedStyle =  {
      color: '#53585f',
      marginTop: 16,
      borderRadius: '50%',
      width:40,
      height: 40,
      padding: 8,
      background: '#ffffff',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#53585f',
      textAlign: 'center',
      fontSize: 14,
      marginRight: 20
    }

    let numberCircleNotSelectedStyle =  {
      color: '#dddee0',
      marginTop: 16,
      borderRadius: '50%',
      width:40,
      height: 40,
      padding: 8,
      background: '#ffffff',
      border: 2,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      textAlign: 'center',
      fontSize: 14,
      marginRight: 20
    }

    let stepsDivStyle = {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      flexDirection: 'row'
    }

    var stepDivs = [];

    for (var i = 0; i < totalSteps; i++) {

      if (i === idx) {
        stepDivs.push(<div key={i} style={numberCircleSelectedStyle}>{i + 1}</div>);
      }
      else {
        stepDivs.push(<div key={i} style={numberCircleNotSelectedStyle}>{i + 1}</div>);
      }

    }


    return (
      <div style={stepsDivStyle}>
        {stepDivs}
      </div>

    );
  }
}

export default StepNumbers;
