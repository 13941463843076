import React from "react";
import { Image }  from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import "../../css/swydgetStyles.css";
import CardEditor from "../../components/admin/CardEditor";
import noOverlayImage from '../../assets/swydget/thumbs-down-button.png';
import yesOverlayImage from '../../assets/swydget/thumbs-up-button.png';
import nextCardImage from '../../SwytchbackDefaultWallpaper-1192.png';
import defaultCardBackgroundImage from '../../assets/swydget/default-card-background.png';



export default class SwydgetMaxDiffCardCollection extends React.Component {

  constructor() {
    super();
    this.wyshBucket = null;
    this.selectedCardIndex = 0;
    this.totalWyshBucketsCount = 0;
    this.loadedImageIds = new Set();

    this.state = {
      allImagesLoaded: false,
    };
  };

  componentDidMount() {
    // for (const trial of this.props.maxDiffBlock.getMaxDiffVersion().trialSets) {
    //   console.log(trial.itemIndices);
    // }
  }

  componentWillUnmount() {

  }

  componentDidUpdate(prevProps) {

    if (prevProps.cardInFocusIndex > -1 && this.props.cardInFocusIndex === -1) {
      this.resetElement(0, 1);
      this.resetElement(1, 1);
      this.resetElement(2, 1);
      this.resetElement(3, 1);
    }
  }
 
  onCardLoad(wyshId) {

    this.loadedImageIds.add(wyshId);
    if (this.areAllImagesLoaded() === true) {
      if (this.props.cardLoadedCallback) {
        this.props.cardLoadedCallback();
      }
    }
  }

  areAllImagesLoaded() {
    
    for (const w of this.wyshBucket) {
      if (!this.loadedImageIds.has(w.wyshId)) {
        return false;
      }
    }

    return true;
  }

  getProductImage(wysh, cardHeight, cardWidth) {

    let productCleanImageStyle = {
      width: cardWidth,
      height: cardHeight,
      objectFit: wysh ? wysh.product.styles.objectFit : "cover",
      background: 'linear-gradient(#0167EC, #00B6F9)',
      zIndex: -10
    };

    if (wysh) {
      if (wysh.getStimuliImageUrl()) {
        return  <Image 
                  key={wysh.wyshId}
                  src={wysh.getStimuliImageUrl()} 
                  style={productCleanImageStyle}
                  onLoad={() => this.onCardLoad(wysh.wyshId)}>
                </Image>
      }
      else {
        return <Image 
                  key={wysh.wyshId}
                  src={defaultCardBackgroundImage} 
                  style={productCleanImageStyle}
                  onLoad={() => this.onCardLoad(wysh.wyshId)}>
                </Image>
      }
    }
  }

  getProductImages(cardHeight, cardWidth) {

    let hiddenStyle = {
      display: 'none'
    };
    
    const images = [];

    for (const wysh of this.wyshBucket) {
      images.push(this.getProductImage(wysh, cardHeight, cardWidth))
    }

    return images;
  }

  getProgressIndicator = (cardHeight, cardWidth) => {

    const showIndicatorStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: cardHeight,
      width: cardWidth,
      zIndex: 10
    };

    const hideIndicatorStyle = {
      display: 'none'
    }

    const indicatorStyle = {
      color: '#ffffff'
    }

    if (this.areAllImagesLoaded()) {
      return <div style={hideIndicatorStyle}></div>;
    }
    else {
      return <div style={showIndicatorStyle}>
        <CircularProgress style={indicatorStyle} size={20}/>
      </div>
    }
  }

  getOverlay = (cardIdx, cardHeight, cardWidth) => {

    const wysh = this.wyshBucket[cardIdx];

    const showIndicatorStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: cardHeight,
      width: cardWidth,
      zIndex: 10
    };

    const hideIndicatorStyle = {
      display: 'none'
    }

    const indicatorStyle = {
      color: '#ffffff'
    }

    const imageStyle = {
      height: '5vh',
      width: '5vh'
    }

    for (const dec of this.props.maxDiffCurrentTrialSetDecisions) {
      if (dec.wysh.equals(wysh)) {
        switch (dec.resultNormalized) {
          case -1:
            return <div style={showIndicatorStyle}>
              <Image src={noOverlayImage} style={imageStyle}></Image>
            </div>
            break;
          case 1: 
            return <div style={showIndicatorStyle}>
              <Image src={yesOverlayImage} style={imageStyle}></Image>
            </div>
            break;
          default:
            break;
        }
      }
    }
  }

  resizeElement = (cardIdx, scale, cardWidth, cardHeight) => {

    let xTranslation = 0;
    let yTranslation = 0;
    let value = 27;

    switch (cardIdx) {
      case 0:
        xTranslation = value;
        yTranslation = value;
        break;
      case 1:
        xTranslation = -value;
        yTranslation = value;
        break;
      case 2:
        xTranslation = value;
        yTranslation = -value;
        break;
      case 3:
        xTranslation = -value;
        yTranslation = -value;
        break;
              
      default:
        xTranslation = 0;
        yTranslation = 0
        break;
    }

    let move = "translate(" + xTranslation + "%, " + yTranslation + "%)"
    let transformCommand = "scale(" + scale + ", " + scale + ") " + move;

    var element = document.getElementById("little-card-" + cardIdx);
    element.style.transition = "all 300ms ease-in 0ms";
    element.style.transform = transformCommand;
    element.style.zIndex = 100;
  }

  resetElement = (cardIdx, scale) => {

    let move = "translate(0px, 0px)"
    
    var element = document.getElementById("little-card-" + cardIdx);
    element.style.transition = "all 300ms ease-in 0ms";
    element.style.transform = "scale(" + scale + ", " + scale + ") " + move;
    element.style.zIndex = 1;
  }


  toggleCardInFocus = (cardIdx, cw, ch) => {
    
    if (cardIdx === this.props.cardInFocusIndex) {
      // resize everybody to small
      this.resetElement(0, 1);
      this.resetElement(1, 1);
      this.resetElement(2, 1);
      this.resetElement(3, 1);
      this.props.cardSelectedCallback(-1);
    }
    else {
      // resize everybody ELSE to small
      // resize cardInFocusIndex to big
      this.resizeElement(cardIdx, 2.0, cw, ch);
      // tell the Swydget that a card has been selected
      this.props.cardSelectedCallback(cardIdx);
    }

    
  }

  render() {

    const { cardWidth } = this.props;
    const { cardHeight } = this.props;
    const { cardPosition } = this.props;

    const littleCardWidth = '15vh';
    const littleCardHeight = '21vh';

    this.currentMaxDiffTrialSetIndex = this.props.maxDiffCurrentTrialSetIndex;
    this.wyshBucket = this.props.maxDiffBlock.maxDiffTrialSets[this.props.maxDiffCurrentTrialSetIndex] ? this.props.maxDiffBlock.maxDiffTrialSets[this.props.maxDiffCurrentTrialSetIndex].items : [];
    this.selectedCardIndex = this.props.selectedCardIndex;
    this.totalWyshBucketsCount = this.wyshBucket.length;

    
    if (this.selectedCardIndex >= this.wyshBucket.length) {
      this.selectedCardIndex = this.wyshBucket.length - 1;
    }

    let littleCardStyle0 = {
      zIndex: 1,
      width: littleCardWidth,
      height: littleCardHeight,
      top: 25,
      left: 25,
    };
    
    let littleCardStyle1 = {
      zIndex: 1,
      width: littleCardWidth,
      height: littleCardHeight,
      right: 25,
      top: 25,
    };

    let littleCardStyle2 = {
      zIndex: 1,
      width: littleCardWidth,
      height: littleCardHeight,
      bottom: 25,
      left: 25
    };

    let littleCardStyle3 = {
      zIndex: 1,
      width: littleCardWidth,
      height: littleCardHeight,
      right: 25,
      bottom: 25
    };


    let cardContainerDivStyle = {
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'center',
      // alignItems: 'center',
      // flexWrap: 'wrap'
    }

    let shadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      WebkitBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      MozBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      boxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)'
    };

    let noShadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%'
    };

    let nextCardImageStyle = {
      width: cardWidth + 2,
      height: cardHeight + 2,
      objectFit: 'cover',
      zIndex: -10
    };

    var cardStyle = noShadowCardStyle;
    if (cardPosition && cardPosition.shadow === true) {
      cardStyle = shadowCardStyle;
    }

    let decisionDivStyle = {
      position: 'absolute',
      height: 100,
      width:100
    }

    var overlayImage = null;

    // Here we need set the overlay based on most and least preferrable
    overlayImage = yesOverlayImage;
    
    return (

        <div style={cardContainerDivStyle}>
          <Image src={nextCardImage} style={cardStyle}></Image>
          { cardPosition.blur === false &&
          <React.Fragment>
            <div id="little-card-0" className="cardStyle cardStyleAbsolute" style={littleCardStyle0} onClick={() => { this.toggleCardInFocus(0, littleCardWidth, littleCardHeight)}}>
              <div style={{backgroundColor: '#ffffff', zIndex: -100}}>
                { this.areAllImagesLoaded() && cardPosition && cardPosition.blur === false ? 
                this.getProductImage(this.wyshBucket[0], littleCardHeight, littleCardWidth)
                :
                <Image src={nextCardImage} style={nextCardImageStyle}></Image>
                } 
                { CardEditor.toMarkup(this.wyshBucket[0].product, 20) }
                { this.getProgressIndicator(littleCardHeight, littleCardWidth) }
                { this.getOverlay(0, littleCardHeight, littleCardWidth) }
              </div>
              { cardPosition && cardPosition.blur === false && 
                <div style={{display: 'none'}}>
              {this.getProductImages(littleCardHeight, littleCardWidth)}
                </div>
              } 
            </div>
            <div id="little-card-1" className="cardStyle cardStyleAbsolute" style={littleCardStyle1} onClick={() => { this.toggleCardInFocus(1, littleCardWidth, littleCardHeight)}}>
              <div style={{backgroundColor: '#ffffff', zIndex: -100}}>
                { this.areAllImagesLoaded() && cardPosition && cardPosition.blur === false ? 
                this.getProductImage(this.wyshBucket[1], littleCardHeight, littleCardWidth)
                :
                <Image src={nextCardImage} style={nextCardImageStyle}></Image>
                } 
                { CardEditor.toMarkup(this.wyshBucket[1].product, 20) }
                { this.getProgressIndicator(littleCardHeight, littleCardWidth) }
                { this.getOverlay(1, littleCardHeight, littleCardWidth) }
              </div>
              { cardPosition && cardPosition.blur === false && 
                <div style={{display: 'none'}}>
              {this.getProductImages(littleCardHeight, littleCardWidth)}
                </div>
              } 
            </div>  
            <div id="little-card-2" className="cardStyle cardStyleAbsolute" style={littleCardStyle2} onClick={() => { this.toggleCardInFocus(2, littleCardWidth, littleCardHeight)}}>
              <div style={{backgroundColor: '#ffffff', zIndex: -100}}>
                { this.areAllImagesLoaded() && cardPosition && cardPosition.blur === false ? 
                this.getProductImage(this.wyshBucket[2], littleCardHeight, littleCardWidth)
                :
                <Image src={nextCardImage} style={nextCardImageStyle}></Image>
                } 
                { CardEditor.toMarkup(this.wyshBucket[2].product, 20) }
                { this.getProgressIndicator(littleCardHeight, littleCardWidth) }
                { this.getOverlay(2, littleCardHeight, littleCardWidth) }
              </div>
              { cardPosition && cardPosition.blur === false && 
                <div style={{display: 'none'}}>
              {this.getProductImages(littleCardHeight, littleCardWidth)}
                </div>
              } 
            </div>  
            <div id="little-card-3" className="cardStyle cardStyleAbsolute" style={littleCardStyle3} onClick={() => { this.toggleCardInFocus(3, littleCardWidth, littleCardHeight)}}>
              <div style={{backgroundColor: '#ffffff', zIndex: -100}}>
                { this.areAllImagesLoaded() && cardPosition && cardPosition.blur === false ? 
                this.getProductImage(this.wyshBucket[3], littleCardHeight, littleCardWidth)
                :
                <Image src={nextCardImage} style={nextCardImageStyle}></Image>
                } 
                { CardEditor.toMarkup(this.wyshBucket[3].product, 20) }
                { this.getProgressIndicator(littleCardHeight, littleCardWidth) }
                { this.getOverlay(3, littleCardHeight, littleCardWidth) }
              </div>
              { cardPosition && cardPosition.blur === false && 
                <div style={{display: 'none'}}>
              {this.getProductImages(littleCardHeight, littleCardWidth)}
                </div>
              } 
            </div> 
          </React.Fragment>  
        } 
        </div>
    );
  }
}
