import React from "react";
import { Image, FormGroup, FormControl }  from 'react-bootstrap';
import { Button, Link, Snackbar } from '@mui/material';
import classes from "../../css/modules/Swydget.module.css";
import MeService from '../../services/MeService';
import ProductStyles from "../../classes/ProductStyles";
import swytchbackLogoBorder from '../../SwytchbackLogoBorder.png';
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';


class SwydgetContestEntry extends React.Component {

  constructor(props){

    super(props);
    this.eventInFocus = {};
    this.openAcceptPrivacySnackBar = this.openAcceptPrivacySnackBar.bind(this);
    this.closeAcceptPrivacySnackBar = this.closeAcceptPrivacySnackBar.bind(this);
    this.acceptPrivacyPolicy = this.acceptPrivacyPolicy.bind(this);
    this.navigateToRewardUrl = this.navigateToRewardUrl.bind(this);
    this.navigateToAppStore = this.navigateToAppStore.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.joinContestHandler = this.joinContestHandler.bind(this);


    this.userKey = this.props.userKey;

    var email = "";

    if (this.userKey && this.userKey.t === "email") {
      email = this.userKey.v;
    }

    this.state = {
      email: email,
      showAcceptPrivacySnackBar: true,
      privacyPolicyAccepted: false
    };

  }

  componentDidMount() {
    var emailInput = document.getElementById("emailField");
    emailInput.onblur = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      document.body.scrollTop = 0;
    }
  }

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back&#8480;</span></span>;
  }

  openAcceptPrivacySnackBar() {
    this.setState({ showAcceptPrivacySnackBar: true });
  }

  closeAcceptPrivacySnackBar() {
    // this.setState({ showAcceptPrivacySnackBar: false });
  }

  acceptPrivacyPolicy() {
    this.setState({
      showAcceptPrivacySnackBar: false,
      privacyPolicyAccepted: true
    });
  }

  navigateToAppStore() {
    window.open('https://swytchback.com/', '_self');
    // window.open('https://itunes.apple.com/us/app/swytchback/id1178824453?mt=8', '_self');
  }

  navigateToRewardUrl() {
    window.open(this.eventInFocus.rewardUrl, '', 'width=1000');
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  joinContestHandler() {

    // Only enter the contest if the user key has been passed in. Sometimes, if this
    // component is used for a preview, the user key will not bbe passed in.

    var email = this.userKey.v;

    if (this.state.privacyPolicyAccepted === true &&
        this.state.email &&
        this.state.email.trim().length > 6 &&
        this.state.email.trim().indexOf("@") > 0) {
      email = this.state.email;
    }

    if (this.userKey && email) {
      var newUserKey = {
        "t": "email",
        "v": email.trim()
      }
      console.log(this.userKey);
      console.log(newUserKey);
      MeService.resolveKeyAndAcceptPrivacyPolicy(this.userKey, newUserKey, this.eventInFocus.eventId);
    }
  }

  isEmailInvalid() {

    // if (this.state.privacyPolicyAccepted === true &&
    //     this.state.email &&
    //     this.state.email.trim().length > 6 &&
    //     this.state.email.trim().indexOf("@") > 0) {
    //   return false;
    // }

    if (this.state.privacyPolicyAccepted === true) {
      return false;
    }

    return true;
  }

  getDeclarationsMessage(eventInFocus) {

    return (
      <div id="message-id" style={{fontSize: 14, lineHeight: 1.6}}>
        <div>
          To provide your information below you must accept our <Link href={eventInFocus.getTosLink()} className={`${classes.linkStyle}`} underline="always" target="_blank">terms of service</Link>.
        </div>
      </div>
    );
  }

  render() {

    this.userKey = this.props.userKey;
    this.eventInFocus = this.props.eventInFocus;
    const { height } = this.props;

    var instructions = this.eventInFocus.callToActionInstructionText;
    var buttonLabel = this.eventInFocus.callToActionSubmitButtonText;
    var isSubmitButtonDisabled = this.isEmailInvalid();

    if (instructions === "") {
      instructions = "Enter your email";
    }

    if (buttonLabel === "") {
      buttonLabel = "Submit";
    }

    var ht = 100;

    if (height) {
      ht = height;
    }

    let headerHeight = ht * 0.15 + 'vh';
    let taglineHeight = ht * 0.1 + 'vh';
    let formHeight = ht * 0.40 + 'vh';
    let footerHeight = ht * 0.15 + 'vh';

    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (this.eventInFocus && this.eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = this.eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    let containerDivStyle = {
      height: ht + 'vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: -10
    };

    let footerDivStyle = {
      height: footerHeight,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10
    };

    let titleDivStyle = {
      color: '#fff',
      textAlign: 'center',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(this.eventInFocus.contestPrizeText),
      fontWeight: 500,
      padding: 10
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff'
    };

    let swytchbackLogoStyle = {
      margin: 10,
      height: 30,
      width: 'auto',
      zIndex: 10
    };

    let defaultCardBackgroundStyle = {
      borderRadius: '50%',
      margin: 0,
      height: ht*0.1 + 'vh',
      width: ht*0.1 + 'vh',
      objectFit: 'cover'
    }

    let headerDivStyle = {
      height: headerHeight,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: 1
    };

    let taglineDivStyle = {
      height: taglineHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: 1
    };

    let formDivStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      height: formHeight,
      marginLeft: 20,
      marginTop: 0,
      marginRight: 20,
      marginBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0
    };

    let surveyTypeNameStyle = {
      width: '100%',
      color: '#ffffff',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(instructions),
      fontWeight: 300,
      paddingBottom:6,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#ffffff'
    };

    let buttonContainerStyle = {
      width: '100%'
    };

    let linksContainerStyle = {
      width: '100%',
      paddingTop: 20,
      paddingBottom: 20
    }

    let linkStyle = {
      width: '100%',
      paddingTop: 5
    }

    return (
      <div style={containerDivStyle}>
        <div style={taglineDivStyle}>
          <div style={titleDivStyle}>{this.eventInFocus.contestPrizeText}</div>
        </div>
        <div style={formDivStyle}>
          <div style={surveyTypeNameStyle}>{instructions}</div>
          <FormGroup style={{ width: '100%', paddingTop: 12 }} controlId="emailField">
            <FormControl
              value={this.state.email}
              type="email"
              placeholder="..."
              onChange={this.handleEmailChange}
            />
          </FormGroup>
          <div style={buttonContainerStyle}>
            <Button
              className={`${classes.enabledButton}`}
              disabled={isSubmitButtonDisabled}
              onClick={this.joinContestHandler}>{buttonLabel}
            </Button>
          </div>
        </div>
        <div style={footerDivStyle}>
          <div style={completionDivContainerStyle}>
            <Image style={swytchbackLogoStyle} src={swytchbackLogoBorder} responsive="true"/>
          </div>
          <div style={completionDivContainerStyle}>
            <div>
              {this.generateLogo()}
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          open={this.state.showAcceptPrivacySnackBar}
          onClose={this.closeAcceptPrivacySnackBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={this.getDeclarationsMessage(this.eventInFocus)}
          action={[
            <Button key="undo" size="small" style={{fontSize: 16, color: '#1579fb'}} onClick={this.acceptPrivacyPolicy}>
              Accept
            </Button>
          ]}
        />

      </div>
    );
  }
}

export default SwydgetContestEntry;
