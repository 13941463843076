import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Form, Table, Button, OverlayTrigger, Popover, Modal, Collapse } from 'react-bootstrap';
import { CircularProgress, FormControl, MenuItem, Switch } from '@mui/material';
import { CloudUpload, InsertDriveFile } from '@mui/icons-material';
import { ExcelRenderer } from 'react-excel-renderer';
import classes from '../css/modules/WyshTable.module.css';
import loginStore from "../stores/LoginStore";
import meStore from "../stores/MeStore";
import Wysh from "../classes/Wysh";
import WyshMeProduct from "../classes/WyshMeProduct";
import WyshWordFrequency from "../classes/WyshWordFrequency";
import MeService from "../services/MeService";
import "../css/swytchbackStyles.css"
import ProductTableRow from "./ProductTableRow";
import BlockTableRow from "./BlockTableRow";
import ProductLibraryPickerTable from "./admin/ProductLibraryPickerTable";
import BlockOptionsModal from './admin/BlockOptionsModal';
import SwydgetOptionsModal from "./admin/SwydgetOptionsModal";
import ResultsModalBlock from "../hooks/admin/ResultsModalBlock";
import Card from '../hooks/admin/Card';
import StimuliLibraryModal from '../hooks/admin/StimuliLibraryModal'
import SwytchbackTooltip from "./admin/SwytchbackTooltip";
import StimuliImportModal from '../hooks/admin/StimuliImportModal';
import BlockTypes from '../classes/BlockTypes';
import WyshOption from '../classes/WyshOption';
import AddStimulusModal from '../hooks/admin/stimuli/AddStimulusModal';




class WyshTable extends Component {

  constructor(props) {
    super(props);

    this.user = loginStore.getLoggedInUser();
    this.eventLoaded=this.eventLoaded.bind(this);
    this.updateWyshes = this.updateWyshes.bind(this);
    this.previewSurvey = this.previewSurvey.bind(this);
    this.openAddContentModal = this.openAddContentModal.bind(this);
    this.closeAddContentModal = this.closeAddContentModal.bind(this);
    this.openProductLibraryModal = this.openProductLibraryModal.bind(this);
    this.closeProductLibraryModal = this.closeProductLibraryModal.bind(this);
    this.openBlockOptionsModal = this.openBlockOptionsModal.bind(this);
    this.closeBlockOptionsModal = this.closeBlockOptionsModal.bind(this);
    this.handleBlockPromptChange = this.handleBlockPromptChange.bind(this);
    this.handleRandomizeClicked = this.handleRandomizeClicked.bind(this);
    this.handleDisplayBlockCardClicked = this.handleDisplayBlockCardClicked.bind(this);
    this.handleIssueSubGroupClicked = this.handleIssueSubGroupClicked.bind(this);
    this.handleSubGroupCountChanged = this.handleSubGroupCountChanged.bind(this);
    this.handleLimitFreeResponseClicked = this.handleLimitFreeResponseClicked.bind(this);
    this.handleLimitFreeResponseCountChanged = this.handleLimitFreeResponseCountChanged.bind(this);
    this.resetToDefaultScalarOptions = this.resetToDefaultScalarOptions.bind(this);
    this.editOption = this.editOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.spreadSheetUploadSuccessful = this.spreadSheetUploadSuccessful.bind(this);


    this.reader = new FileReader();
    var blockCardText = "";
    var blockPrompt = "";
    var randomize = false;
    var displayBlockCard = false;
    var issueSubGroups = false;
    var subGroupCount = 1;
    var limitFreeResponse = false;
    var limitFreeResponseCount = 1;
    var binaryIconSetID = "yes_no";
    var scalarOptionsCount = 2;
    this.spreadSheetUploadStimuliPreFlightMessage = "Click Upload to submit spreadsheet."
    this.spreadSheetUploadStimuliSuccessfulMessage = "Spreadsheet stimuli have been submitted. Check back later to confirm."
    this.spreadSheetUploadStimuliErrorMessage = "There was a problem uploading spreadsheet stimuli. Please try again."
    
    if (props.parentWysh) {
      blockCardText = props.parentWysh.product.description;
      blockPrompt = props.parentWysh.caption;
      randomize = props.parentWysh.randomize;
      displayBlockCard = props.parentWysh.displayBlockCard;
      issueSubGroups = props.parentWysh.issueSubGroups;
      subGroupCount = props.parentWysh.subGroupCount;
      limitFreeResponse = props.parentWysh.limitFreeResponse;
      limitFreeResponseCount = props.parentWysh.limitFreeResponseCount;
      binaryIconSetID = props.parentWysh.binaryIconSetID;
    }
    else if (props.eventInFocus){
      blockPrompt = props.eventInFocus.surveyName;
      randomize = props.eventInFocus.randomize;
      issueSubGroups = props.eventInFocus.issueSubGroups;
      subGroupCount = props.eventInFocus.subGroupCount;
      limitFreeResponse = props.eventInFocus.limitFreeResponse;
      limitFreeResponseCount = props.eventInFocus.limitFreeResponseCount;
      binaryIconSetID = props.eventInFocus.binaryIconSetID;
    }

    this.state = {
      parentWysh: props.parentWysh || null,
      // eventInFocus: props.eventInFocus || null,
      blockInFocus: null,
      blockCardText: blockCardText,
      blockPrompt: blockPrompt,
      randomize: randomize,
      displayBlockCard: displayBlockCard,
      issueSubGroups: issueSubGroups,
      subGroupCount: subGroupCount,
      limitFreeResponse: limitFreeResponse,
      limitFreeResponseCount: limitFreeResponseCount,
      showProductLibraryModal: false,
      showAddContentModal: false,
      showBlockOptionsModal: false,
      showCompositeWordCloud: false,
      showBatchUploadModal: false,
      showExcelUploadModal: false,
      showResultsBlockModal: false,
      showStimuliLibraryModal: false,
      showStimuliImportModal: false,
      scalarOptions: [],
      binaryIconSetID: binaryIconSetID,
      isUpdating: false,
      cardSize: props.cardSize || "large",
      acceptedFiles: [],
      isUploading: false,
      isLoadingFreeResponses: false,
      spreadsheetUploadState: "preflight", // or "uploadComplete", "uploadError"
      spreadSheetUploadStimuliMessage: this.spreadSheetUploadStimuliPreFlightMessage,
      stimuliImportJson: null
    }
  }

  componentDidMount() {
    meStore.on("change-productAdded", this.closeAddContentModal);
    meStore.on("meStore-importStimuliSuccessful", this.closeAddContentModal);
    meStore.on("meStore-createProducts", this.startActivityMonitor);
    meStore.on("meStore-addProductsToSwydgetSuccessful", this.closeBatchUploadModal);
    meStore.on("change-eventLoaded", this.eventLoaded);
    meStore.on("change-freeResponseAnswersByProductLoaded", this.freeResponseAnswersLoaded);
    meStore.on("meStore-spreadSheetStimuliUploadSuccessful", this.spreadSheetUploadSuccessful);
    meStore.on("change-wyshesUpdated", this.wyshesUpdated);
    meStore.on("change-wyshUpdated", this.wyshesUpdated);
  }

  componentWillUnmount() {
    meStore.removeListener("change-productAdded", this.closeAddContentModal);
    meStore.removeListener("meStore-importStimuliSuccessful", this.closeAddContentModal);
    meStore.removeListener("meStore-createProducts", this.startActivityMonitor);
    meStore.removeListener("meStore-addProductsToSwydgetSuccessful", this.closeBatchUploadModal);
    meStore.removeListener("change-eventLoaded", this.eventLoaded);
    meStore.removeListener("change-freeResponseAnswersByProductLoaded", this.freeResponseAnswersLoaded);
    meStore.removeListener("meStore-spreadSheetStimuliUploadSuccessful", this.spreadSheetUploadSuccessful);
    meStore.removeListener("change-wyshesUpdated", this.wyshesUpdated);
    meStore.removeListener("change-wyshUpdated", this.wyshesUpdated);
  }

  componentDidUpdate(prevProps) {
    var updatedState = {};

    var isStateDirty = false;

    if (this.props && prevProps && this.props.cardSize !== prevProps.cardSize) {
      isStateDirty = true;
      updatedState["cardSize"] = this.props.cardSize;
    }

    if (isStateDirty === true) {
      this.setState(updatedState);
    }
  }

  eventLoaded() {

    this.setState( { isUpdating: false, showAddContentModal: false });

  }

  wyshesUpdated = () => {
    var pw = this.props.parentWysh;
    this.setState({ parentWysh: pw, isUpdating: false });
  }

  previewSurvey(previewUrl) {
    window.open(previewUrl, '');
  }

  openProductLibraryModal() {
    this.setState({ showProductLibraryModal: true });
  }

  closeProductLibraryModal() {
    this.setState({ showProductLibraryModal: false });
  }

  openStimliLibraryModal= () => {
    this.setState({ showStimuliLibraryModal: true });
  }

  closeStimuliLibraryModal = () => {
    this.setState({ showStimuliLibraryModal: false });
  }

  openStimuliImportModal= () => {
    this.setState({ showStimuliImportModal: true });
  }

  closeStimuliImportModal = () => {
    this.setState({ showStimuliImportModal: false });
  }

  openAddContentModal() {
    if (this.state.parentWysh) {
      this.setNumberOfScalarOptionsFromBlock(this.state.parentWysh.wyshOptions.length);
    }
    else if (this.props.eventInFocus) {
      this.setNumberOfScalarOptionsFromSwydget(this.props.eventInFocus.options.length);
    }
    else {
      this.setNumberOfScalarOptionsFromSwydget(2);
    }

    this.setState({ showAddContentModal: true });
  }

  closeAddContentModal() {
    var pw = this.props.parentWysh;
    this.setState({ parentWysh: pw, showAddContentModal: false });
  }

  startActivityMonitor = () => {
    this.setState({isUploading: true});
  }

  openBatchUploadModal = () => {
    if (this.state.parentWysh) {
      this.setNumberOfScalarOptionsFromBlock(this.state.parentWysh.wyshOptions.length);
    }
    else if (this.props.eventInFocus) {
      this.setNumberOfScalarOptionsFromSwydget(this.props.eventInFocus.options.length);
    }
    else {
      this.setNumberOfScalarOptionsFromSwydget(2);
    }

    this.setState({showBatchUploadModal: true});
  }

  closeBatchUploadModal = () => {
    this.setState({showBatchUploadModal: false, isUploading: false, acceptedFiles: []});
  }

  openExcelUploadModal = () => {
    if (this.state.parentWysh) {
      this.setNumberOfScalarOptionsFromBlock(this.state.parentWysh.wyshOptions.length);
    }
    else if (this.props.eventInFocus) {
      this.setNumberOfScalarOptionsFromSwydget(this.props.eventInFocus.options.length);
    }
    else {
      this.setNumberOfScalarOptionsFromSwydget(2);
    }

    this.setState({showExcelUploadModal: true});
  }

  closeExcelUploadModal = () => {
    this.setState({
      showExcelUploadModal: false,
      spreadsheetUploadState: "preflight",
      spreadSheetUploadStimuliMessage: this.spreadSheetUploadStimuliPreFlightMessage,
      isUploading: false, acceptedFiles: []
    });
  }

  spreadSheetUploadSuccessful() {
    this.setState({
      spreadsheetUploadState: "uploadComplete",
      spreadSheetUploadStimuliMessage: this.spreadSheetUploadStimuliSuccessfulMessage
    });
  }

  addBlock = () => {
    MeService.createBlock(this.user,
                            this.props.eventInFocus,
                            "",
                            "",
                            this.props.parentWysh);
  }

  onFileDrop = (acceptedFiles) => {
    this.setState({acceptedFiles: acceptedFiles});
  }

  onDropRejected = () => {
    console.log("Drop Rejected");
    
    this.setState({
      acceptedFiles: []
    })
  }

  handleBatchUploadImages = () => {
    MeService.createProducts(
      this.user,
      this.props.eventInFocus,
      this.state.acceptedFiles,
      this.props.parentWysh);
  }

  freeResponseAnswersLoaded = () => {
    this.setState({isLoadingFreeResponses: false});
  }

  openBlockOptionsModal() {
    if (this.props.parentWysh) {

      var wyshes = this.props.parentWysh.event.wyshes;
      for (var i = 0; i < wyshes.length; i++) {
        var nextWyshId = wyshes[i].nextWysh ? wyshes[i].nextWysh.wyshId : "NONE";
        var nextWyshName = wyshes[i].nextWysh ? wyshes[i].nextWysh.product.name : "NONE";
        var isBlock = wyshes[i].block ? "BLOCK" : "";
      }

      var nextWyshId = this.props.parentWysh.nextWysh ? this.props.parentWysh.nextWysh.wyshId : "NONE";
    }
    this.setState({ showBlockOptionsModal: true });
  }

  closeBlockOptionsModal() {
    this.setState({ showBlockOptionsModal: false });
  }

  handleCardSizeChange = (cardSize) => {
    this.setState({ cardSize: cardSize });
  }

  handleBlockPromptChange(evt) {
    this.setState({ blockPrompt: evt.target.value});
    if (this.props.parentWysh) {
      var pw = this.props.parentWysh;
      pw.caption = evt.target.value;
      MeService.updateWysh(this.user, this.props.eventInFocus, pw);
    }
    else if (this.props.eventInFocus) {
      console.log("Update the EVENT and then send the update in");
    }
  }

  handleRandomizeClicked(evt) {

    if (this.props.parentWysh) {
      var pw = this.props.parentWysh;
      pw.randomize = evt.target.checked;

      if (pw.randomize === false) {
        // clear out settings that cannot be enabled when a Swydget is randomized
        pw.issueSubGroups = false;
        pw.subGroupCount = 1;
        pw.limitFreeResponse = false;
        pw.limitFreeResponseCount = 1;
        this.setState({
          randomize: evt.target.checked,
          issueSubGroups: false,
          subGroupCount: 1,
          limitFreeResponse: false,
          limitFreeResponseCount: 1
        });
      }
      else {
        this.setState({randomize: evt.target.checked});
      }

      MeService.updateWysh(this.user, this.props.eventInFocus, pw);
    }
    else if (this.props.eventInFocus) {

      var eif = this.props.eventInFocus;
      eif.randomize = evt.target.checked;

      if (eif.randomize === false) {
        eif.issueSubGroups = false;
        eif.subGroupCount = 1;
        eif.limitFreeResponse = false;
        eif.limitFreeResponseCount = 1;
        this.setState({
          randomize: evt.target.checked,
          issueSubGroups: false,
          subGroupCount: 1,
          limitFreeResponse: false,
          limitFreeResponseCount: 1
        });
      }
      else {
        this.setState({randomize: evt.target.checked});
      }

      MeService.updateEvent(this.user, eif, eif.title, eif.rewardText, eif.rewardUrl, eif.contestPrizeText);
    }
  }

  handleDisplayBlockCardClicked(evt) {
    if (this.props.parentWysh) {
      var pw = this.props.parentWysh;
      pw.displayBlockCard = evt.target.checked;

      this.setState({displayBlockCard: evt.target.checked});

      MeService.updateWysh(this.user, this.props.eventInFocus, pw);
    }
  }

  handleIssueSubGroupClicked(evt) {

    if (this.props.parentWysh) {
      var pw = this.props.parentWysh;
      pw.issueSubGroups = evt.target.checked;

      if (pw.issueSubGroups === false) {
        pw.subGroupCount = 1;
      }
      this.setState({issueSubGroups: evt.target.checked, subGroupCount: pw.subGroupCount});

      MeService.updateWysh(this.user, this.props.eventInFocus, pw);
    }
    else if (this.props.eventInFocus) {
      var eif = this.props.eventInFocus;
      eif.issueSubGroups = evt.target.checked;

      if (eif.issueSubGroups === false) {
        eif.subGroupCount = 1;
      }
      this.setState({issueSubGroups: evt.target.checked, subGroupCount: eif.subGroupCount});

      MeService.updateEvent(this.user, eif, eif.title, eif.rewardText, eif.rewardUrl, eif.contestPrizeText);
    }
  }

  handleSubGroupCountChanged(evt) {

    var fieldValue = evt.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      this.setState({
        subGroupCount: count
      });

      if (this.props.parentWysh) {
        var pw = this.props.parentWysh;
        pw.subGroupCount = count;
        MeService.updateWysh(this.user, this.props.eventInFocus, pw);
      }
      else if (this.props.eventInFocus) {
        var eif = this.props.eventInFocus;
        eif.subGroupCount = count;
        MeService.updateEvent(this.user, eif, eif.title, eif.rewardText, eif.rewardUrl, eif.contestPrizeText);
      }
    }
  }
  
  handleLimitFreeResponseClicked(evt) {

    if (this.props.parentWysh) {
      var pw = this.props.parentWysh;
      pw.limitFreeResponse = evt.target.checked;

      if (pw.limitFreeResponse === false) {
        pw.limitFreeResponseCount = 1;
      }
      this.setState({limitFreeResponse: evt.target.checked, limitFreeResponseCount: pw.limitFreeResponseCount});

      MeService.updateWysh(this.user, this.props.eventInFocus, pw);
    }
    else if (this.props.eventInFocus) {
      var eif = this.props.eventInFocus;
      eif.limitFreeResponse = evt.target.checked;

      if (eif.limitFreeResponse === false) {
        eif.limitFreeResponseCount = 1;
      }
      this.setState({limitFreeResponse: evt.target.checked, limitFreeResponseCount: eif.limitFreeResponseCount});

      MeService.updateEvent(this.user, eif, eif.title, eif.rewardText, eif.rewardUrl, eif.contestPrizeText);
    }
  }

  handleLimitFreeResponseCountChanged(evt) {
    var fieldValue = evt.target.value;

    var count = 0;

    if (fieldValue.trim() !== "") {
      count = parseInt(fieldValue, 10);

      this.setState({
        limitFreeResponseCount: count
      });

      if (this.props.parentWysh) {
        var pw = this.props.parentWysh;
        pw.limitFreeResponseCount = count;
        MeService.updateWysh(this.user, this.props.eventInFocus, pw);
      }
      else if (this.props.eventInFocus) {
        var eif = this.props.eventInFocus;
        eif.limitFreeResponseCount = count;
        MeService.updateEvent(this.user, eif, eif.title, eif.rewardText, eif.rewardUrl, eif.contestPrizeText);
      }
    }
  }

  getTableHeaders(mode) {

    let theadStyleTr = {
      height: 4
    };

    let fixed90Th = {
      width: 90,
      textAlign: 'center',
      fontWeight: 400
    };

    let fixed160Th = {
      width: 160,
      textAlign: 'center',
      fontWeight: 400
    };

    let fixed300Th = {
      width: 300,
      fontWeight: 400
    }

    let imgStyleTh = {
      width: 160,
      fontWeight: 400
    };

    let tHeadCenteredStyleTh = {
      width: 160,
      textAlign: 'left',
      fontWeight: 400
    };

    let theadsStyleTh = {
      fontWeight: 400
    };

    switch (mode) {
      case "active":
        // ACTIVE: must have 5 <TH>
        return (
          <tr style={theadStyleTr}>
            <th style={imgStyleTh}></th>
            <th style={fixed90Th}></th>
            <th style={fixed300Th}></th>
            <th style={fixed160Th}></th>
            <th style={theadsStyleTh}></th>
          </tr>
        );
      case "draft":
        // DRAFT: must have 5 <TH>
        return (
          <tr style={theadStyleTr}>
            <th style={imgStyleTh}></th>
            <th style={fixed90Th}></th>
            <th style={theadsStyleTh}></th>
            <th style={fixed160Th}></th>
            <th style={fixed90Th}></th>
          </tr>
        );
      case "filter-content":
        // FILTER-CONTENT: must have 3 <TH>
        return (
          <tr style={theadStyleTr}>
            <th style={imgStyleTh}>Question Card</th>
            <th style={fixed90Th}></th>
            <th style={theadsStyleTh}>Name</th>
            <th style={tHeadCenteredStyleTh}></th>
          </tr>
        );
      case "filter-respondents":
        // FILTER-RESPONDENTS: must have 3 <TH>
        return (
          <tr style={theadStyleTr}>
            <th style={imgStyleTh}></th>
            <th style={fixed90Th}></th>
            <th style={theadsStyleTh}>Response(s) Selected</th>
            <th style={theadsStyleTh}>Include</th>
          </tr>
        );
      default:
        console.log("NOTHING");
    }
  }

  _generateProductTableRows(wyshes, snapshot, snapshotsToDisplay, mode, sortOrder, myProducts, selectionHandler, onCheckboxChangeMethod, setWordsToOmitCallback, setSelectedFreeResponseDecisions) {

    var rows = [];
    // TO REMOVE: var selHandler = (selectionHandler) ? selectionHandler : this.openEditContentModal;
    var selHandler = selectionHandler;

    if (wyshes) {

      for (var i = 0; i < wyshes.length; i++) {

        var fullSize = this.state.blockInFocus === null ? true : false;

        if (wyshes[i].isBlock() === true) {

          rows.push(<BlockTableRow
            key={wyshes[i].wyshId}
            myProducts={myProducts}
            productIndex={i}
            mode={mode}
            sortOrder={sortOrder}
            wysh={wyshes[i]}
            snapshot={snapshot}
            snapshotsToDisplay={snapshotsToDisplay}
            updateWyshesAction={this.updateWyshes}
            setWordsToOmitCallback={setWordsToOmitCallback}
            setSelectedFreeResponseDecisions={setSelectedFreeResponseDecisions}
            selectionHandler={selHandler}
            onCheckboxChangeMethod={onCheckboxChangeMethod}
            isUpdating={this.state.isUpdating}
            cardSize={this.state.cardSize}
            updateBlockInFocus={this.updateBlockInFocus}
            showChildTable={this.state.blockInFocus && this.state.blockInFocus.equals(wyshes[i])}
            fullSize={fullSize}
            />);
        }
        else {
          rows.push(<ProductTableRow
            key={wyshes[i].wyshId}
            myProducts={myProducts}
            productIndex={i}
            mode={mode}
            sortOrder={sortOrder}
            wysh={wyshes[i]}
            snapshot={snapshot}
            snapshotsToDisplay={snapshotsToDisplay}
            updateWyshesAction={this.updateWyshes}
            setWordsToOmitCallback={setWordsToOmitCallback}
            setSelectedFreeResponseDecisions={setSelectedFreeResponseDecisions}
            selectionHandler={selHandler}
            selected={wyshes[i].event.filter.isContentSelected(wyshes[i])}
            onCheckboxChangeMethod={onCheckboxChangeMethod}
            isUpdating={this.state.isUpdating}
            cardSize={this.state.cardSize}
            fullSize={fullSize}
            />);
        }
      }
    }

    return rows;
  }

  _generateProductCards(wyshes, mode, sortOrder, myProducts, selectionHandler, onCheckboxChangeMethod) {

    var rows = [];
    // TO REMOVE: var selHandler = (selectionHandler) ? selectionHandler : this.openEditContentModal;
    var selHandler = selectionHandler;

    if (wyshes && wyshes.length > 0 && wyshes[0].parentWysh !== null) {
      for (var i = 0; i < wyshes.length; i++) {
        rows.push(<div key={"grid-card-" + i} style={{margin: 20}}><Card wysh={wyshes[i]} product={wyshes[i].product} cardHeight={WyshMeProduct.getCardHeightAsPct(this.state.cardSize)} /></div>);
      }
    }

    return rows;
  }

  updateWyshes(swydget, mutatedWyshes, wyshToRemove) {

    this.setState({ isUpdating: true });

    MeService.updateWyshes(this.user, swydget, Wysh.removeDuplicates(mutatedWyshes), wyshToRemove);
  }

  getTitle() {

    // Get the parent of the wyshes. It will be the same by definition.
    // If there are no wyshes or no parent wysh, try to find the eventInFocus
    // and use its surveyName. Otherwise, return ""

    if (this.props.parentWysh) {
      return this.props.parentWysh.isBlock() ? this.props.parentWysh.getBlockName() : this.props.parentWysh.getWyshPrompt();
    }
    else if (this.props.eventInFocus) {
      return this.props.eventInFocus.surveyName
    }

    return "";
  }

  generateProductLibraryModal(myProducts, swydget) {
    return (
      <Modal show={this.state.showProductLibraryModal} onHide={this.closeProductLibraryModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Product Library</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'maxHeight': '70vh', 'overflowY': 'auto'}}>
          <ProductLibraryPickerTable eventInFocus={swydget} parentWysh={this.props.parentWysh}/>
        </Modal.Body>
        <Modal.Footer>
          <Button className="swytchbackActiveButton" onClick={this.closeProductLibraryModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  generateOptionsModal() {

    const { classes, mode } = this.props;

    // This is a little hinky. There are many "modes" a WyshTable can be in (draft, active, filter-respondents, etc)
    // We only want the controls to be active if we are in draft mode. All other modes should be readonly or not even
    // display the cog wheel

    if (this.props.parentWysh) {

      return (
        <BlockOptionsModal
          readOnly={!(mode === "draft")}
          showBlockOptionsModal={this.state.showBlockOptionsModal}
          closeBlockOptionsModal={this.closeBlockOptionsModal}
          eventInFocus={this.props.eventInFocus}
          blockWysh={this.props.parentWysh}
        />
      );
    }
    else {
      return (
        <SwydgetOptionsModal
          readOnly={!(mode === "draft")}
          showSwydgetOptionsModal={this.state.showBlockOptionsModal}
          closeSwydgetOptionsModal={this.closeBlockOptionsModal}
          eventInFocus={this.props.eventInFocus}
          blockWysh={this.props.parentWysh}
        />
      );
    }
  }

  generateAddContentModal() {

    var isLoading = false;

    let newWysh = new Wysh();

    return (
      <AddStimulusModal 
        onHideAction={this.closeAddContentModal}
        addStimulusAction={this.uploadStimulus}
        showModal={this.state.showAddContentModal}
        blockWysh={this.props.parentWysh}
        swydget={this.props.eventInFocus}
      />
    )
  }

  setNumberOfScalarOptionsFromSwydget = (value) => {
    var newOptionsArray = [];

    for (var i = 0; i < value; i++) {
      var optionToAdd = {"name": ""};

      if (i < this.state.scalarOptions.length && i < this.props.eventInFocus.options.length) {
        optionToAdd = this.state.scalarOptions[i];
      }
      else if (i < this.props.eventInFocus.options.length) {
        optionToAdd = this.props.eventInFocus.options[i];
      }

      newOptionsArray.push(optionToAdd);
    }

    this.setState({scalarOptions: newOptionsArray});
  }

  setNumberOfScalarOptionsFromBlock = (value) => {
    var newOptionsArray = [];

    for (var i = 0; i < value; i++) {
      let optionToAdd = new WyshOption();
      optionToAdd.resultLiteral = "";
      
      if (i < this.state.scalarOptions.length && i < this.state.parentWysh.wyshOptions.length) {
        optionToAdd = this.state.scalarOptions[i];
      }
      else if (i < this.state.parentWysh.wyshOptions.length) {
        optionToAdd = new WyshOption();
        optionToAdd.resultLiteral = this.state.parentWysh.wyshOptions[i].resultLiteral;
        optionToAdd.resultNormalized = this.state.parentWysh.wyshOptions[i].resultNormalized;
      }

      newOptionsArray.push(optionToAdd);
    }

    this.setState({scalarOptions: newOptionsArray});
  }

  resetToDefaultScalarOptions() {
    var newOptionsArray = [];
    for (var i = 0; i < this.props.eventInFocus.options.length; i++) {
      newOptionsArray.push(this.props.eventInFocus.options[i]);
    }
    this.setState({scalarOptions: newOptionsArray});
  }

  editOption(index, value) {

    var newOptionsArray = [];
    for (var i = 0; i < this.state.scalarOptions.length; i++) {
      if (i === index) {
        newOptionsArray.push({"name": value});
      }
      else {
        newOptionsArray.push(this.state.scalarOptions[i]);
      }
    }

    this.setState({scalarOptions: newOptionsArray});
  }

  removeOption(index) {

    var newOptionsArray = [];
    for (var i = 0; i < this.state.scalarOptions.length; i++) {
      if (i !== index) {
        newOptionsArray.push(this.state.scalarOptions[i]);
      }
    }

    this.setState({scalarOptions: newOptionsArray});
  }

  uploadStimulus = (stimulus, imageFiles=null) => {
    MeService.uploadStimulus(this.user, this.props.eventInFocus, stimulus, imageFiles);
  }

  generateGearIcon(mode) {

    var showWordCloudIcon = false;
    // var showWordCloudIcon = this.props.parentWysh && mode === "active" ? true : false; // A parentWysh was passed in meaning we are a block
    var showResizingIcons = this.props.parentWysh ? false : true; // If a parentWysh was NOT passed in, we are not in a Block, so DO show the resizing icons
    var showSettingsCog = this.props.parentWysh ? true : false; // If a parentWysh was NOT passed in, we are not in a Block, do NOT show the settings cog

    if (mode && (mode === "active" || mode === "draft" )) {

      // word cloud only available for blocks
      // cog available for everyone
      // resizing only at the root


      return (
        <div className={`${classes.toolkitContainerDiv}`}>
          { showWordCloudIcon &&
          <div style={{marginRight: 10}}>
            <Button className={`${classes.cogButtonStyle}`} onClick={() => this.generateCompositeWordCloud(this.props.eventInFocus)}>
              <CloudUpload className={`${classes.glyphStyleLarge}`} />
            </Button>
          </div>
          }
          { showResizingIcons &&
          <React.Fragment>
            <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("small")}>
              <InsertDriveFile className={`${classes.glyphStyleSmall}`} style={{fontSize: 20}} />
            </Button>
            <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("medium")}>
            <InsertDriveFile className={`${classes.glyphStyleMedium}`} style={{fontSize: 27}}/>
            </Button>
            <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("large")}>
              <InsertDriveFile className={`${classes.glyphStyleLarge}`} style={{fontSize: 34}}/>
            </Button>
          </React.Fragment>
          }
          { showSettingsCog &&
          <Button className="swytchbackActiveButton" onClick={this.openBlockOptionsModal}>
            Block Settings
          </Button>
        }
        </div>
      );
    }
    else if (mode && (mode === "filter-content" || mode === "filter-repsondents" )) {
      return (
        <div className={`${classes.toolkitContainerDiv}`}>
          <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("small")}>
            <InsertDriveFile className={`${classes.glyphStyleSmall}`} style={{fontSize: 20}} />
          </Button>
          <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("medium")}>
            <InsertDriveFile className={`${classes.glyphStyleMedium}`} style={{fontSize: 27}}/>
          </Button>
          <Button className={`${classes.cogButtonStyle}`} onClick={() => this.handleCardSizeChange("large")}>
            <InsertDriveFile className={`${classes.glyphStyleLarge}`} style={{fontSize: 34}}/>
          </Button>
        </div>
      );
    }
  }

  fetchWyshes(mode, orderedWyshList, sortOrder, filter) {

    var wyshes = [];

    switch (mode) {
      case "active":
        wyshes = (orderedWyshList) ? orderedWyshList.getFilteredSortedWyshes(sortOrder, filter) : [];
        break;
      case "filter-content":
        wyshes = (orderedWyshList) ? orderedWyshList.getSortedWyshes(sortOrder) : [];
        break;
      case "filter-respondents":
        wyshes = (orderedWyshList) ? orderedWyshList.getSortedWyshes(sortOrder) : [];
        break;

      default:
        wyshes = orderedWyshList.getOrderedWyshes();
    }

    return wyshes;
  }

  generateImageGrid = (
    mode,
    orderedWyshList,
    eventInFocus,
    sortOrder,
    myProducts,
    selectionHandler,
    onCheckboxChangeMethod
  ) => {

    var myCards = this._generateProductCards(this.fetchWyshes(mode, orderedWyshList, sortOrder, eventInFocus.filter),
                                                mode,
                                                sortOrder,
                                                myProducts,
                                                selectionHandler,
                                                onCheckboxChangeMethod);

    return (
      <div className={`${classes.container}`}>
        {myCards}
      </div>
    )
  }

  generateCompositeWordCloud = (swydget) => {

    MeService.getFreeResponseAnswers(this.user, swydget);

    this.setState({
      isLoadingFreeResponses: true,
      showResultsBlockModal: !this.state.showResultsBlockModal
    });
  }

  updateBlockInFocus = (block) => {
    this.setState({blockInFocus: block});
  }

  generateAddCardTooltipMessage = () => {
    if (this.props.parentWysh) {
      return "Add cards to \"" + this.props.parentWysh.getBlockName() + "\"";
    } else if (this.props.eventInFocus) {
      return "Add cards to \"" + this.props.eventInFocus.surveyName + "\"";
    }
    else {
      return "Add cards";
    }
  }

  generateAddBlockTooltipMessage = () => {
    if (this.props.parentWysh) {
      return "Add block to \"" + this.props.parentWysh.getBlockName() + "\"";;
    } else if (this.props.eventInFocus) {
      return "Add block to \"" + this.props.eventInFocus.surveyName + "\"";;
    }
    else {
      return "Add block";
    }
  }

  closeResultBlockModal = () => {
    this.setState({ showResultsBlockModal: !this.state.showResultsBlockModal });
  }

  openExcelSheet = (evt) => {
    let fileObj = evt.target.files[0];
    //just pass the fileObj as parameter

    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);
      }
      else{
        var validCols = [];
        for (var i = 0; i < resp.cols.length; i++) {
          validCols.push(resp.cols[i]);
        }

        var validRows = [];
        for (var i = 0; i < resp.rows.length; i++) {
          if (resp.rows[i].length > 0) {
            validRows.push(resp.rows[i]);
          }
        }


        this.setState({
          excelCols: validCols,
          excelRows: validRows
        });
      }
    });
  }

  handleUploadExcelSheet = () => {
    MeService.batchUploadStimuli(this.user, this.props.eventInFocus, this.state.excelRows, this.props.parentWysh);
  }

  generateResultsModalBlock = (mode, swydget, blockWyshes, compositeWfa, orderedWyshList, sortOrder, myProducts, compositeWords, callbacks) => {

    // The Modal shouldn't be figuring things out. It should just be presenting
    // Let's execute our business logic here and then


    return (
      <ResultsModalBlock
        mode={mode}
        swydget={swydget}
        blockWyshes={blockWyshes}
        compositeWfa={compositeWfa}
        orderedWyshList={orderedWyshList}
        sortOrder={sortOrder}
        myProducts={myProducts}
        isLoadingFreeResponses={this.state.isLoadingFreeResponses}
        showModal={this.state.showResultsBlockModal}
        onHideAction={this.closeResultBlockModal}
        words={compositeWords}
        callbacks={callbacks}
      />
    );
  }

  _displayAddBlock = () => {

    const pw = this.state.parentWysh;

    
    if (pw === null) {
      return true;
    }
    else {
      if (pw.isBlock() === true && pw.getBlockType() && pw.getBlockType().equals(BlockTypes.STANDARD) === true || pw.getBlockType().equals(BlockTypes.SEQUENTIALMONADIC) === true) {
        // You can only add blocks to a standard block
        return true;
      } 
    }

    return false;
  }

  _isAddingContentBlocked = () => {

    const pw = this.state.parentWysh;

    if (pw === null) {
      return false;
    }
    else if (pw.isBlock() === true && pw.getBlockType() && pw.getBlockType().equals(BlockTypes.MAXDIFF) === true) {
      // The upper limit on Max Diff blocks is 40
      return pw.orderedWyshList.getAllDescendantWyshes().length <= 40 ? false : true;
    }

    return false;
  }


  render() {

    const { eventInFocus, orderedWyshList, mode, sortOrder, previewUrl } = this.props;
    const { snapshot } = this.props;
    const { snapshotsToDisplay } = this.props;
    const { setWordsToOmitCallback } = this.props;
    const { setSelectedFreeResponseDecisions } = this.props;
    const { myProducts } = this.props;
    const { selectionHandler } = this.props;
    const { onCheckboxChangeMethod } = this.props;

    var footerStyle = (this.props.parentWysh && this.props.parentWysh.isBlock() === true) ? `${classes.tableFooterBlockDivStyle}` : `${classes.tableFooterRootDivStyle}`;
    var presentExcelUploadButton = this.props.parentWysh && this.props.parentWysh.isBlock() === true

    var filteredSortedWyshes = this.fetchWyshes(mode, orderedWyshList, sortOrder, eventInFocus.filter);
    var myRows = this._generateProductTableRows(filteredSortedWyshes,
                                                snapshot,
                                                snapshotsToDisplay,
                                                mode,
                                                sortOrder,
                                                myProducts,
                                                selectionHandler,
                                                onCheckboxChangeMethod,
                                                setWordsToOmitCallback,
                                                setSelectedFreeResponseDecisions);

    var compositeWords = [];

    var compositeWfa = WyshWordFrequency.getCompositeWordFrequencyArray(filteredSortedWyshes);

    compositeWfa.sort(WyshWordFrequency.compareByCountDesc);

    for (var i = 0; i < compositeWfa.length; i++) {
      compositeWords.push({text: compositeWfa[i].word, value: compositeWfa[i].count});
    }


    var title = this.getTitle();

    let containerStyle = {
      backgroundColor: '#ffffff',
      marginBottom: 0
    };

    let tableContainerStyle = {
      backgroundColor: '#f7f6f7'
    };

    let tableStyle = {
      backgroundColor: '#ffffff',
      margin: 0
    }

    let tableMenuDivStyle = {
      height: 50,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: '#ffffff',
      marginBottom:0
    };

    let sectionTitleDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#a1b2b7',
      fontSize: 14,
      fontWeight: 800,
    };

    let inFocusSectionTitleDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#346CF9',
      fontSize: 24,
      fontWeight: 900,
    };

    let theadStyle = {
      borderBottom: '1px solid #b7b7b7'
    };

    let toolbarDivStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    };

    let buttonStyle = {
      width: 130,
      marginLeft: 5,
      marginRight: 5
    };

    let topButtonStyle = {
      width: 160,
      paddingTop: 6,
      paddingBottom: 6,
      margin: 0
    };

    let dropzoneStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: 200,
      textColorColor: '#ffffff',
      color: '#000000',
      borderStyle: 'solid',
      backgroundColor: '#eeeeee',
      borderRadius: '5px',
      padding:0,
      margin: 0
    };

    let dropzoneActiveStyle = {
      borderStyle: 'solid',
      backgroundColor: '#aeaeae'
    };

    var cardHeight = 100;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width

    let imageStyle = {
      height: cardHeight,
      width: cardWidth,
      objectFit: 'contain',
      borderRadius: '5%',
    };

    let divModalTitle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      paddingRight: 20
    };

    const screenOptions = {
      colors: ["#346CF9", "#809DF2", "#342BD9", "#282BA9", "#D7E9FC", "#2E3192"],
      enableTooltip: false,
      deterministic: false,
      fontFamily: "Helvetica, Arial, sans-serif",
      fontSizes: [10, 80],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 1,
      rotations: 1,
      rotationAngles: [0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 2000
    };

    const callbacks = {
      getWordColor:  (word) => Wysh.getWordColor(word, compositeWfa),
      getWordTooltip: (word) => WyshWordFrequency.getTooltip(word, compositeWfa),
    }

    var titleDivStyle = this.state.blockInFocus ? sectionTitleDivStyle : inFocusSectionTitleDivStyle;

    return (
      <div style={containerStyle}>
        <div style={toolbarDivStyle}>
          <div style={tableMenuDivStyle}>
          { this.generateGearIcon(mode) }
          </div>
          { previewUrl &&
            <Button className="swytchbackActiveButton" style={topButtonStyle} onClick={() => this.previewSurvey(previewUrl)}>Preview</Button>
          }
        </div>
        <div style={tableContainerStyle}>

          <Table style={tableStyle} responsive>
            <thead style={theadStyle}>

                {this.getTableHeaders(mode)}

            </thead>
            <tbody>
                { myRows }
            </tbody>
          </Table>
        </div>
      { mode === "draft" && this.state.blockInFocus === null &&
        <div style={toolbarDivStyle}>
          <div className={footerStyle}>
            <div className={`${classes.buttonRowDivStyle}`}>
              {this._displayAddBlock() === true &&
              <SwytchbackTooltip title={this.generateAddBlockTooltipMessage()} placement="top-start">
                <span>
                  <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.addBlock()}>
                    + Add Block
                  </Button>
                </span>
              </SwytchbackTooltip>
              } 
            </div>
            <div className={`${classes.buttonRowDivStyle}`}>
              <div style={{margin: 6, width: 100}}>
                Add Cards:
              </div>
              <SwytchbackTooltip title={this.generateAddCardTooltipMessage()} placement="top-start">
                <span>
                  <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.openAddContentModal()}>
                    Card
                  </Button>
                </span>
              </SwytchbackTooltip>
              <SwytchbackTooltip title={this.generateAddCardTooltipMessage()} placement="top-start">
                <span>
                  <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.openBatchUploadModal()}>
                    Cards
                  </Button>
                </span>
              </SwytchbackTooltip>
              <SwytchbackTooltip title={this.generateAddCardTooltipMessage()} placement="top-start">
                <span>
                  <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.openStimuliImportModal()}>
                    Import
                  </Button>
                </span>
              </SwytchbackTooltip>
              { presentExcelUploadButton &&
                <SwytchbackTooltip title={this.generateAddCardTooltipMessage()} placement="top-start">
                  <span>
                    <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.openExcelUploadModal()}>
                      Excel
                    </Button>
                  </span>
                </SwytchbackTooltip>
              }
              <SwytchbackTooltip title={this.generateAddCardTooltipMessage()} placement="top-start">
                <span>
                  <Button disabled={this._isAddingContentBlocked()} className={this._isAddingContentBlocked() === true ? "swytchbackInactiveButton" : "swytchbackActiveButton"} style={{margin: 6, width: 120}} onClick={() => this.openStimliLibraryModal()}>
                    Library
                  </Button>
                </span>
              </SwytchbackTooltip>
            </div>
          </div>
        </div>
      }

        { this.state.showStimuliLibraryModal && 
        <StimuliLibraryModal 
          user={this.user}
          show={this.state.showStimuliLibraryModal} 
          handleClose={this.closeStimuliLibraryModal} 
          swydget={this.props.eventInFocus} 
          parentWysh={this.props.parentWysh}
        />
        }
        { this.state.showStimuliImportModal && 
        <StimuliImportModal 
          user={this.user}
          show={this.state.showStimuliImportModal} 
          handleClose={this.closeStimuliImportModal} 
          swydget={this.props.eventInFocus} 
          parentWysh={this.props.parentWysh}
        />
        }

        { this.generateProductLibraryModal(myProducts, eventInFocus)}
        { this.state.showAddContentModal === true &&
          this.generateAddContentModal()
        }
        { this.state.showResultsBlockModal && this.generateResultsModalBlock(mode, eventInFocus, filteredSortedWyshes, compositeWfa, orderedWyshList, sortOrder, myProducts, compositeWords, callbacks) }
        { this.state.showBlockOptionsModal && this.generateOptionsModal()}
        <Modal show={this.state.showBatchUploadModal} onHide={this.closeBatchUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div style={divModalTitle}>
                <div>Upload Images</div>
              { this.state.isUploading  &&
                <div className={`${classes.rowDivStyle}`}>
                  <div style={{paddingRight: 10, fontSize: 16, color: '#911313'}}>Uploading Images...</div>
                  <CircularProgress size={20}/>
                </div>
              }
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`${classes.dropZoneContainer}`}>
              <Dropzone
                onDrop={this.onFileDrop}
                onDropRejected={() => {this.onDropRejected()}}
                maxFiles={5}
                accept={{
                  'image/png': ['.png'],
                  'image/jpg': ['.jpg', '.jpeg'],
                  'image/gif': ['.gif']
                }}
              >
                {({getRootProps, getInputProps}) => (
                  <section className="swytchbackDropzoneContainer">
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <div className="swytchbackDropzoneMessage">
                        Drag and drop image files or click here to select
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className={`${classes.imageList}`}>
                { this.state.acceptedFiles.length > 0 ?
                  <div style={{padding: 20}}>
                    {this.state.acceptedFiles.map(
                      (file, i) => {
                        const previewUrl = URL.createObjectURL(file);
                        return <img key={i} src={previewUrl} style={imageStyle}/>
                      } 
                    )}
                  </div>
                  :
                  <div style={{padding: 20}}>No images loaded</div>
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="swytchbackActiveButton" style={buttonStyle} disabled={this.state.isUploading} onClick={this.closeBatchUploadModal}>Cancel</Button>
            <Button className="swytchbackActiveButton" style={buttonStyle} disabled={this.state.isUploading} onClick={this.handleBatchUploadImages}>Upload</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showExcelUploadModal} onHide={this.closeExcelUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div style={divModalTitle}>
                <div>Upload Excel File</div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <input
                  type="file"
                  onChange={this.openExcelSheet}
                  style={{"padding":"10px"}}
                />
              </div>
              <div>
                {this.state.spreadSheetUploadStimuliMessage}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          {
            this.state.spreadsheetUploadState === "preflight" ?
            <React.Fragment>
              <Button className="swytchbackActiveButton" style={buttonStyle} disabled={this.state.isUploading} onClick={this.closeExcelUploadModal}>Cancel</Button>
              <Button className="swytchbackActiveButton" style={buttonStyle} disabled={this.state.isUploading} onClick={this.handleUploadExcelSheet}>Upload</Button>
            </React.Fragment>
            :
            <React.Fragment>
              <Button className="swytchbackActiveButton" style={buttonStyle} disabled={this.state.isUploading} onClick={this.closeExcelUploadModal}>Done</Button>
            </React.Fragment>
          }

          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default WyshTable;