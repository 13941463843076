import React from "react";
import { Button, ButtonToolbar }  from 'react-bootstrap';
import WyshMeProduct from "../../classes/WyshMeProduct";
import SwydgetProductRecommendation from "./SwydgetProductRecommendation";
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';
import "./swydget.css";


export default class SwydgetRecommendations extends React.Component {

  constructor() {
    super();
    this.numberOfRecommendations = 3;
  }

  getCards(productLibrary, accumulatedAttributesDict) {

    for (var i = 0; i < productLibrary.length; i++) {
      productLibrary[i].calculateRelevanceIndex(accumulatedAttributesDict);
    }

    productLibrary.sort(WyshMeProduct.compareByRelevanceIndexDesc);

    var cards = [];

    for (i = 0; i < productLibrary.length && i < this.numberOfRecommendations; i++) {

      var card = <SwydgetProductRecommendation key={i} idx={i} numberOfRecommendations={3} product={productLibrary[i]} />

      cards.push(card);
    }

    return cards;
  }

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back</span></span>;
  }

  render() {

    const { eventInFocus } = this.props;
    const { primaryAction } = this.props;
    const { productLibrary } = this.props;
    const { accumulatedAttributesDict } = this.props;

    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (eventInFocus && eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    let containerDivStyle = {
      height: '100vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    };

    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10
    }

    let headerDivStyle = {
      position: 'absolute',
      top: 0,
      height: '15vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 20,
      textShadow: '0px 2px 10px rgba(0, 0, 0, 1)',
      background:  `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))`
    };

    let headerContentDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 500,
      zIndex: 20
    }

    let titleDivStyle = {
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 300
    };

    let selectionButtonToolbarStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      padding: 0,
      margin: 0,
      marginBottom: 50
    };

    let middleButtonStyle = {
      width: '100%',
      margin: 0,
      padding: 0,
      height: 40,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#a9a9a9',
      borderRadius: 6,
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500,
      background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`

      // WebkitBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      // MozBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Firefox 3.5 - 3.6 */
      // boxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    return (
      <div className="containerDiv" style={containerDivStyle}>
        <div style={containerStyle}>
          <div style={headerDivStyle}>
            <div style={headerContentDivStyle}>
              <div style={titleDivStyle}>Scroll Down to View Your Recommendations</div>
            </div>
          </div>
          <div key={0} className="scrollingDiv">
            <div style={{zIndex: 5}}>
              {this.getCards(productLibrary, accumulatedAttributesDict)}
              <ButtonToolbar style={selectionButtonToolbarStyle}>
                <Button style={middleButtonStyle} onClick={primaryAction}>{"I'm Done"}</Button>
              </ButtonToolbar>
            </div>
          </div>
        </div>


      </div>
    );
  }
}
