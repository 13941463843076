import React, { useState, useEffect } from 'react';
import "../../css/swytchbackModal.css";
import "../../css/swytchbackTable.css";
import Wysh from "../../classes/Wysh";
import Decision from "../../classes/Decision";
import Card from '../../hooks/admin/Card';
import AdminWyshStats from "../../components/stats/AdminWyshStats";
import AdminWyshResults from "../../components/stats/AdminWyshResults";
import SwytchbackWordCloud from './controls/SwytchbackWordCloud';

const WyshResultsPrintReport = ({ wysh, wordCloudId, wordsToOmit, selectedFreeResponseDecisions, renderCompleteCallback, showTopResponses }) => {

  const [wordsSet, setWordsSet] = useState(new Set());
  
  const wfa = Wysh.removeStopWords(wysh.wordFrequencyArray, wordsToOmit);
  const wordArrayColumns = [[]];
  const words = [];
  
  useEffect(() => {
    setWordsSet(new Set());
  }, [wysh]);

  var currColumn = 0;
  for (var i = 0; i < wfa.length; i++) {
    if (i > 0 && i % 10 === 0) {
      currColumn += 1;
      wordArrayColumns.push([]);
    }
    wordArrayColumns[currColumn].push(wfa[i]);
    words.push({ key: wfa[i].word, value: wfa[i].count });
  }

  
  const generateWordFrequencyTable = (wordArrayColumns) => {
    const width = 100 / wordArrayColumns.length;
    return (
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            {wordArrayColumns.map((wordArray, idx) => (
              <td key={"col-" + idx} style={{ width: width + "%", verticalAlign: 'top' }}>
                {generateWordFrequencyList(wordArray, "100%", idx)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const generateWordFrequencyList = (wordArray, width, idx) => (
    <div className="columnContainer" key={idx} style={{ width: width }}>
      {wordArray.map((wordFreq, idx) => (
        <div style={{ fontSize: 16, fontWeight: wordFreq.fontWeight }} key={wordFreq.word}>
          {wordFreq.word}: {wordFreq.count}
        </div>
      ))}
    </div>
  );

  const generateCuratedFreeResponses = (selectedFreeResponseDecisions) => {
    const freeResponseDecisions = selectedFreeResponseDecisions.length === 0 ?
      wysh.generateDecisionsWithFreeResponses().sort(Decision.compareByFreeResponseLengthDesc).slice(0, 10) :
      selectedFreeResponseDecisions;

    return (
      <div className="divEditListContainer">
        {freeResponseDecisions.map((decision, idx) => (
          <div key={decision.takerMid + decision.productId + "-fr"} className="divQuote">
            "{decision.freeResponseAnswers.length > 0 ? decision.freeResponseAnswers[0] : ""}"
          </div>
        ))}
      </div>
    );
  };

  const generateBlockMarkup = (wysh) => {
    const questionNumber = wysh.questionNumber.length > 0 ? "Q" + wysh.questionNumber : "";
    const wyshTitle = wysh.product.name ? wysh.product.name + ": " + wysh.caption : wysh.caption;

    return (
      <div>
        <div className="sectionContainer">
          <div className="sectionTitle">
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '85%', textAlign: "left" }}>{wyshTitle}</td>
                  <td style={{ width: '15%', textAlign: "right" }}>{questionNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="swydgetNameContainer">
            {wysh.event.surveyName}
          </div>
          <div className="swydgetTitleContainer">
            {wysh.event.title}
          </div>
          <div className="blockTitleContainer">
            {wysh.product.name}
          </div>
        </div>
        <div className="page-break"></div>
      </div>
    )  
  };

  const generateWyshMarkup = (wysh, wordArrayColumns) => {

    const questionNumber = wysh.questionNumber.length > 0 ? "Q" + wysh.questionNumber : "";
    const wyshTitle = wysh.product.name ? wysh.product.name + ": " + wysh.caption : wysh.caption;

    return (
      <div>
        <div className="sectionContainer">
          <div className="sectionTitle">
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '85%', textAlign: "left" }}>{wyshTitle}</td>
                  <td style={{ width: '15%', textAlign: "right" }}>{questionNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <table className="swytchbackTable">
            <tbody>
              <tr>
                <td>
                  <Card wysh={wysh} product={wysh.product} cardHeight={30} />
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          
                          {wysh.wordFrequencyArray.length > 0 &&
                          <SwytchbackWordCloud 
                            wysh={wysh}
                            height={500}
                            width={700}
                            mode="light"
                          />
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ float: 'left', width: '40%', paddingLeft: 60 }}>
                            <AdminWyshStats style={{ float: 'right' }} wysh={wysh} />
                          </div>
                          <div style={{ float: 'left', width: '60%', padding: 0 }}>
                            <AdminWyshResults wysh={wysh} width={300} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="sectionContainer">
          <div className="sectionTitle">Top Words</div>
          <table className="swytchbackTable">
            <tbody>
              <tr>
                <td style={{ width: "100%" }} colSpan="2">
                  {generateWordFrequencyTable(wordArrayColumns)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {showTopResponses &&
          <div className="sectionContainer">
            <div className="sectionTitle">Top Responses</div>
            <table className="swytchbackTable">
              <tbody>
                <tr>
                  <td style={{ width: "100%" }} colSpan="2">
                    {generateCuratedFreeResponses(selectedFreeResponseDecisions)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        <div className="page-break"></div>
      </div>
    );
  }  

  

  // Determine which markup to generate based on Wysh type
  const markup = wysh.isBlock() ? generateBlockMarkup(wysh, wordArrayColumns) : generateWyshMarkup(wysh, wordArrayColumns);

  return markup;
};

export default WyshResultsPrintReport;
