export default class OAuthArtifacts
{
  constructor() {
    this.integration = "";
    this.authorization_code = "";
    this.access_token = "";
    this.refresh_token = "";
  }

  initFromJson(json) {
    if (json.hasOwnProperty("integration")) {
      this.integration = json["integration"];
    }
    if (json.hasOwnProperty("authorization_code")) {
      this.authorization_code = json["authorization_code"];
    }
    if (json.hasOwnProperty("access_token")) {
      this.access_token = json["access_token"];
    }
    if (json.hasOwnProperty("refresh_token")) {
      this.refresh_token = json["refresh_token"];
    }
  }

  static createFromJson(json)
  {
    var oauth = new OAuthArtifacts();
    oauth.initFromJson(json);
    return oauth;
  }
}
