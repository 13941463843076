import React, { Component } from 'react';
import classes from '../../css/modules/ProductLibraryPickerTableRow.module.css';
import loginStore from "../../stores/LoginStore";
import MeService from '../../services/MeService';


class ProductLibraryPickerTableRow extends Component {

  constructor(props) {
    super(props);

    this.user = loginStore.getLoggedInUser();

    this.openProductModal = this.openProductModal.bind(this);
    this.closeProductModal = this.closeProductModal.bind(this);
    this.addProductToSwydget = this.addProductToSwydget.bind(this);

    this.state = {
      showProductModal: false,
      isUpating: false
    };
  };

  openProductModal() {
    this.setState({ showProductModal: true });
  }

  closeProductModal() {
    this.setState({ showProductModal: false });
  }

  addProductToSwydget(isUploading, product, parentWysh) {

    if (!isUploading) {

      var prompt = "";
      var qt = this.props.eventInFocus.surveyType;
      var optArray = this.props.eventInFocus.options;
      var binaryIconSetID = this.props.eventInFocus.binaryIconSetID;
      var freeResponseQuestions = [];

      if (parentWysh) {
        qt = parentWysh.questionType;
        optArray = parentWysh.wyshOptions;
        binaryIconSetID = parentWysh.binaryIconSetID;
        freeResponseQuestions = parentWysh.freeResponseQuestions
      }

      // If the questionType and the options are not specified, get them from the event
      if (qt === "") {
       qt = "binary";
      }

      if (parentWysh) {
        prompt = parentWysh.caption
      }
      else if (this.props.eventInFocus) {
        prompt = this.props.eventInFocus.title;
      }

      if (this.props.eventInFocus.findWyshByProductId(product.productId) === null) {
        MeService.addProductToSwydget(
          this.user,
          product,
          this.props.eventInFocus,
          qt,
          optArray,
          prompt,
          freeResponseQuestions,
          binaryIconSetID,
          false, // isBlock???
          parentWysh
        );
      }
    }
  }

  render() {

    const { product } = this.props;
    const { parentWysh } = this.props;
    const { disabled } = this.props;
    const { isUploading } = this.props;

    var cardHeight = 10;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var cardHeightStyle = cardHeight + 'vh';
    var cardWidthStyle = cardWidth + 'vh';
    var textColor = "#000000";
    var textShadow = "0.0vh 0.0vh 0.5vh rgba(255,255,255,1.0)";

    if (product.styles.textShadow && product.styles.textShadow === "light") {
      textColor = "#ffffff";
      textShadow = "0.0vh 0.0vh 0.5vh rgba(0,0,0,1.0)";
    }

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidthStyle,
      height: cardHeightStyle,
      padding: '0.75vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: product.styles.textColor,
      fontFamily: product.styles.getFontFamily(),
      // textShadow: textShadow,
      fontSize: "1.0vh",
      backgroundImage: "url('" + product.getSecureImageUrl() + "')",
      backgroundColor: '#ffffff',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      marginBottom: 0,
      WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
      boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };


    return (
      <tr className={disabled ? `${classes.disabledTrStyle}` : `${classes.trStyle}`}>
        <td className={`${classes.tdCardStyle}`} onClick={() => this.addProductToSwydget(isUploading, product, parentWysh)}><div style={cardStyle}>{product.description}</div></td>
        <td className={`${classes.tdNameStyle}`} onClick={() => this.addProductToSwydget(isUploading, product, parentWysh)}>{product.name}</td>
      </tr>
    )
  }
}

export default ProductLibraryPickerTableRow;
