import React from "react";
import { Button }  from 'react-bootstrap';
import moment from 'moment';

export default class NotificationsTableRow extends React.Component {

  getDateString = (timeInSeconds) => {
    return (timeInSeconds > 160000000) ? moment(new Date(timeInSeconds*1000)).format('YYYY-MM-DD HH:mm:ss') : "";
  }

  render() {

    const { notification } = this.props;
    const { notificationHandler } = this.props;

    var cardHeight = 7;
    var cardHeightStyle = cardHeight + 'vh';

    let cardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardHeightStyle,
      height: cardHeightStyle,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundImage: "url('" + notification.photo + "')",
      backgroundColor: '#fffddd',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      marginBottom: 0
    };

    let trStyle = {
      margin: 0,
      padding: 0,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    }

    let trGrayStyle = {
      margin: 0,
      padding: 0,
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7',
      backgroundColor: '#eeeeee'
    }

    let tdStyle = {
      textAlign: 'center',
      verticalAlign: 'middle'
    }

    let tdButtonStyle = {
      textAlign: 'right',
      verticalAlign: 'middle'
    }

    let nameTdStyle = {
      // maxWidth: 400,
      textAlign: 'left',
      verticalAlign: 'middle'
    }

    var dateTdStyle = {
      minWidth: 120,
      fontSize: 10,
      textAlign: 'left',
      verticalAlign: 'middle'
    }

    var topButtonStyle = {
      width: 100,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 12,
      paddingBottom: 12,
      marginLeft: 5,
      marginRight: 5
    };

    var rowStyle = (notification.header.acknowledged > 0) ? trGrayStyle : trStyle;

    // console.log(c);
    // console.log();

    return (
      <tr style={rowStyle}>
        <td style={tdStyle}><div style={cardStyle}></div></td>
        <td style={dateTdStyle}>{this.getDateString(notification.header.created)}</td>
        <td style={dateTdStyle}>{this.getDateString(notification.header.acknowledged)}</td>
        <td style={nameTdStyle}>{notification.title}</td>
        <td style={tdButtonStyle}>
          { notification.acceptText && notification.header.acknowledged === 0 &&
            <Button className="swytchbackActiveButton" style={topButtonStyle} onClick={() => notificationHandler(notification, true)}>{notification.acceptText}</Button>
          }
          { notification.rejectText && notification.header.acknowledged === 0 &&
            <Button className="swytchbackActiveButton" style={topButtonStyle} onClick={() => notificationHandler(notification, false)}>{notification.rejectText}</Button>
          }
        </td>
      </tr>
    );
  }
}
