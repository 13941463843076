import Wysh from "./Wysh";
import BranchLogicStatement from "./BranchLogicStatement";
import SkipRule from "./SkipRule";
import BlockTypes from "./BlockTypes";

/*
WyshRouter

The WyshRouter is where the Wysh turns to dermine where the Swydget should go after
a user has made a decision. It contains a dictionary/Set mapping

*/
export default class WyshRouter
{
  constructor() {
    this.wysh = null;
    this.branchLogicStatements = [];
  }

  clearAllBranchLogicStements() {
    this.branchLogicStatements = [];
  }

  /**
  * importWyshRouter
  *
  * Import the argument Wysh's WyshRouter in the context of this WyshRouter
  *
  * @param serverUrl
  * @param user
  * @param swydget
  */
  importWyshRouter(originalWysh) {

    this.branchLogicStatements = [];
    var originalRouter = originalWysh.wyshRouter;

    for (var i = 0; i < originalRouter.branchLogicStatements.length; i++) {

      var correspondingBls = new BranchLogicStatement();
      var originalBls = originalRouter.branchLogicStatements[i];

      if (originalBls.nextWyshId) {
        if (originalBls.nextWyshId === -1) {
          // Jump to survey complete
          correspondingBls.nextWyshId = originalBls.nextWyshId;
        }
        else {
          let originalWyshNextWysh = originalWysh.event.findWysh(originalBls.nextWyshId);
          if (originalWyshNextWysh) {
            let originalWyshNextWyshProduct = originalWyshNextWysh.product;
            var myWyshNextWysh = this.wysh.event.findWyshByProductId(originalWyshNextWyshProduct.productId);
            correspondingBls.nextWyshId = myWyshNextWysh.wyshId;
          }
          else if (originalBls.nextWyshId === "surveyComplete" || originalBls.nextWyshId === "blockComplete") {
            correspondingBls.nextWyshId = originalBls.nextWyshId;
          }
        }

        for (var j = 0; j < originalBls.skipRules.length; j++) {
          var skipRulesOrRow = [];
          for (var k = 0; k < originalBls.skipRules[j].length; k++) {
            var correspondingSkipRule = new SkipRule();

            var skipRuleObservedWysh = originalWysh.event.findWysh(originalBls.skipRules[j][k].observedWyshId);
            var correspondingSkipRuleObservedWysh = this.wysh.event.findWyshByProductId(skipRuleObservedWysh.product.productId);

            correspondingSkipRule.observedWyshId = correspondingSkipRuleObservedWysh.wyshId;
            correspondingSkipRule.resultNormalized = originalBls.skipRules[j][k].resultNormalized;
            correspondingSkipRule.negation = originalBls.skipRules[j][k].negation;

            skipRulesOrRow.push(correspondingSkipRule);
          }
          correspondingBls.skipRules.push(skipRulesOrRow);
        }
      }

      this.branchLogicStatements.push(correspondingBls);
    }
  }

  addBranchLogicStatement(bls) {

    this._trimBranchLogicStatements(bls);

    var indexOfBls = -1;

    for (var i = 0; i < this.branchLogicStatements.length; i++) {

      if (this.branchLogicStatements[i].equals(bls) === true) {
        indexOfBls = i;
      }
    }

    if (indexOfBls < 0) {
      this.branchLogicStatements.push(bls);
    }
    else {
      this.branchLogicStatements.splice(indexOfBls, 1);
      this.branchLogicStatements.push(bls);
    }
  }

  _trimBranchLogicStatements(bls) {

    // Certain question types have different rules for what is allowed when it comes to skip logic
    if (this.wysh && this.wysh.questionType === "multiple-choice") {
      this.clearAllBranchLogicStements();
    }
    else {
      // All other questions only allow the selection of 1 item.
      // Therefore, you cannot have more than one negation SkipRule.
      // Let's go through the SkipRules and rip out any BLS that have negation
      var scrubAllBLS = false;
      var scrubNegation = false;
      var negationIndices = [];
      for (var i = 0; i < this.branchLogicStatements.length; i++) {
        for (const skipRules of this.branchLogicStatements[i].skipRules) {
          for (const sr of skipRules) {

            // Gather indicies with negation
            if (sr.observedWyshId === this.wysh.wyshId &&
                this.branchLogicStatements[i].containsNegation(this.wysh.wyshId)) {
              negationIndices.push(i);
            }
            // If the argument BLS has negation and the SkipRule under scrutiny has negation,
            // AND the observedWyshId of the SkipRule under scrutiny is the same as the wyshId
            // of the Wysh this WyshRouter belongs to..
            // remove the BLS of the SkipRule being scrutinzed.
            if (sr.observedWyshId === this.wysh.wyshId &&
                this.branchLogicStatements[i].containsNegation(this.wysh.wyshId) &&
                bls.containsNegation(this.wysh.wyshId)) {
              scrubAllBLS = true;
            }
            else if (sr.observedWyshId === this.wysh.wyshId &&
                this.branchLogicStatements[i].containsNegation(this.wysh.wyshId) &&
                bls.containsNegation(this.wysh.wyshId) === false) {
              scrubNegation = true;
            }
          }
        }
      }

      if (scrubNegation) {
        for (var idx of negationIndices) {
          this.branchLogicStatements.splice(idx, 1);
        }
      }

      if (scrubAllBLS) {
        this.clearAllBranchLogicStements()
      }
    }
  }

  getNextWysh(resultNormalized) {

    for (var i = 0; i < this.branchLogicStatements.length; i++) {
      for (var j = 0; j < this.branchLogicStatements[i].skipRules.length; j++) {
        for (var k = 0; k < this.branchLogicStatements[i].skipRules[j].length; k++) {
          let skipRule = this.branchLogicStatements[i].skipRules[j][k];
          if (skipRule.resultNormalized === resultNormalized) {
            return this.branchLogicStatements[i].nextWyshId;
          }
        }
      }
    }

    return null; //No BranchLogicStatements involve the argument optionIndex
  }

  isInBranchLogic(wysh){
    for (var i = 0; i < this.branchLogicStatements.length; i ++) {
      if (wysh.wyshId === this.branchLogicStatements[i].nextWyshId) {
        return true;
      }
    }

    return false;
  }

  findWyshIndex(wysh, wyshes) {

    if (wysh && wyshes) {
      for (var i = 0; i < wyshes.length; i++) {
        if (wysh && wysh.wyshId === wyshes[i].wyshId) {
          return i;
        }
      }
    }

    return -1;
  }

  findNextParentSibling(wyshes) {

    // This wysh is on the top so there are no parents or parent siblings
    if (this.wysh.parentWysh === null) {
      return null;
    }

    var myIndex = this.findWyshIndex(this.wysh, wyshes);
    var myParentIndex = this.findWyshIndex(this.wysh.parentWysh, wyshes);

    // I want to find the next card that has the same parent as my parent (making it my parent's sibling)

    var myParent = wyshes[myParentIndex]
    var myGrandpa = myParent.parentWysh;

    if (myGrandpa) {
      for (var i = myParentIndex + 1; i < wyshes.length; i++) {
        if (wyshes[i].parentWysh && wyshes[i].parentWysh.equals(myGrandpa) === true) {
          return wyshes[i];
        }
      }
    }
    else {
      // we don't have a grandpa so we are on the root
      for (var i = myParentIndex + 1; i < wyshes.length; i++) {
        if (wyshes[i].parentWysh === null) {
          return wyshes[i];
        }
      }
    }

    // next parent sibling not found
    return null;
  }

  getNextWyshFromAncestors(wysh, swydgetWyshes, decisionsToBeSubmittedArray) {

    if (wysh === null) {
      return null;
    }

    var nextWysh = null;
    var pw = wysh.parentWysh;

    // if we don't have a parentWysh, get the nextWysh of the argument wysh
    if (pw && pw.parentWysh) {
      for (var i = 0; pw.parentWysh !== null; i++) {

        pw = pw.parentWysh;
        var nw = pw.wyshRouter.getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray);

        if (nw) {
          nextWysh = nw;
          return nextWysh;
        }
      }
    }
    else if (pw){
      nextWysh = pw.wyshRouter.getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray);
    }

    return nextWysh;
  }

  getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray) {

    // A WyshRouter is attached to a Wysh. This is the wysh (block or wysh) that
    // we are trying to determine where to go next.

    // Where might we go?
    // 1. The next Wysh in the ordered array of Wyshes passed in
    // 2. The next Wysh of our Wysh's PARENT Wysh.
    // 3. The next Wysh as determined by our BranchLogicStatements



    var nextWysh = null;
    var currentIndex = this.findWyshIndex(this.wysh, swydgetWyshes);
    var nextWyshIndex = currentIndex + 1;

    if (nextWyshIndex === swydgetWyshes.length) {
      nextWysh = new Wysh();
      nextWysh.wyshId = "surveyComplete";
      return nextWysh;
    }

    if (this.wysh.parentWysh && this.wysh.parentWysh.equals(swydgetWyshes[nextWyshIndex].parentWysh) === true || this.wysh.parentWysh === null && swydgetWyshes[nextWyshIndex].parentWysh === null) {
      // the next card is a member of the block I am in so it is the nextWysh
      // unless BLS dictates otherwise

      if (this.wysh.parentWysh && this.wysh.parentWysh.getBlockType().equals(BlockTypes.FORCEDCHOICE) === true) {
        // We are in a forced choice block and we want to punch out and go to my parent's next sibling
        var nextWysh = this.wysh.parentWysh.wyshRouter.getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray);
      }
      else {
        // We are in a standard block and just want to see OUR next sibling
        nextWysh = swydgetWyshes[nextWyshIndex];
      }
    }
    else if (swydgetWyshes[nextWyshIndex].parentWysh && swydgetWyshes[nextWyshIndex].parentWysh.equals(this.wysh) === true) {
      // the next card is one of my children so I arrived back to here from a blockComplete
      // So, we need to find our next sibling and that is the next wysh unless our BLS says otherwise
      for (var i = nextWyshIndex; i < swydgetWyshes.length; i++) {
        if ((swydgetWyshes[i].parentWysh && this.wysh.parentWysh && swydgetWyshes[i].parentWysh.wyshId === this.wysh.parentWysh.wyshId) ||
            (swydgetWyshes[i].parentWysh === null && this.wysh.parentWysh === null)) {
          nextWysh = swydgetWyshes[i];
          break;
        }
      }
    }
    else if (this.wysh.parentWysh) {
      // The next card was NOT a member of the block I am in so I need to jump out of the block and navigate to the
      // sibling of my parent wysh (the block I am in)

      var nextParentSiblingWysh = this.wysh.parentWysh.wyshRouter.getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray);

      if (nextParentSiblingWysh) {
        // if there is a next sibling wysh of my parent, we head there
        nextWysh = nextParentSiblingWysh;
      }
      else {
        // If there is not a next sibling wysh of my parent AND my parent exists (I'm not at the root)
        // Find the next parent sibling of the parent wysh (my grandpa's next sibling)

        var nw = this.getNextWyshFromAncestors(this.wysh, swydgetWyshes, decisionsToBeSubmittedArray)

        if (nw) {
          nextWysh = nw;
        }
      }
    }

    // sort the branchLogicStatements so the ones with negation are processed first
    this.branchLogicStatements.sort(BranchLogicStatement.compareByNegation);

    // APPLY OUR BRANCH LOGIC STATEMENTS IN DETERMINING WHERE TO GO NEXT!!!
    for (var i = 0; i < this.branchLogicStatements.length; i++) {
      let nextWyshId = this.branchLogicStatements[i].nextWyshId;
      var blsSatisfied = this.branchLogicStatements[i].interpret(decisionsToBeSubmittedArray);
      if (nextWyshId === "surveyComplete" && blsSatisfied === true) {
        nextWysh = new Wysh();
        nextWysh.wyshId = "surveyComplete";
        return nextWysh;
      }
      else if (nextWyshId === "blockComplete" && blsSatisfied === true) {
        if (this.branchLogicStatements[i].interpret(decisionsToBeSubmittedArray) === true) {

          var parentNextWysh = this.wysh.parentWysh.wyshRouter.getNextWyshBasedOnDecisions(swydgetWyshes, decisionsToBeSubmittedArray);

          if (parentNextWysh === null) {
            parentNextWysh = this.getNextWyshFromAncestors(this.wysh.parentWysh, swydgetWyshes, decisionsToBeSubmittedArray);
          }

          if (parentNextWysh) {
            return parentNextWysh
          }
          else {
            nextWysh = new Wysh();
            nextWysh.wyshId = "surveyComplete";
            return nextWysh;
          }
        }
      }
      else if (blsSatisfied === true) {
        nextWysh = this.wysh.event.findWysh(nextWyshId);
        if (nextWysh === null) {
          nextWysh = new Wysh();
          nextWysh.wyshId = "surveyComplete";
        }
        return nextWysh;
      }
    }



    return nextWysh;
  }

  toJsonObject() {

    var blsAsJson = []

    for (const bls of this.branchLogicStatements) {
      blsAsJson.push(bls.toJson());
    }

    return {
      wysh_id: this.wysh ? this.wysh.wyshId : "",
      branch_logic_statements: blsAsJson
    };
  }

  importWyshRouterFromJson(wyshRouterJson) {
    if (wyshRouterJson["branch_logic_statements"]) {
      let blsArray = wyshRouterJson["branch_logic_statements"];
      for (var i = 0; i < blsArray.length; i++) {
        this.addBranchLogicStatement(BranchLogicStatement.createFromJson(blsArray[i]));
      }
    }
  }
}
