import React from 'react';
import { useNavigate } from 'react-router';
import { Container, Row, Col, Modal, Button }  from 'react-bootstrap';
import User from "../classes/User";
import EditUserInfo from '../components/admin/EditUserInfo';
import UserInfoFormModal from '../components/admin/UserInfoFormModal';


class CreateAccount extends React.Component {

  constructor() {
    super();
    this.takeNextStep = this.takeNextStep.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }
    this.modalTitle = "Title";
    this.modalMessage = "Message";

    this.state = {
      loggedInUser: this.loggedInUser,
      showAlertModal: false,
      step: "edit_info"
    };

  }

  openAlertModal() {
    this.modalTitle = "Coming Soon";
    this.modalMessage = "You will be able to specify a reward for this survey."
    this.setState({ showAlertModal: true });
  }

  closeAlertModal() {
    this.setState({ showAlertModal: false });
  }

  getCardHeight = () => {
    var h = window.innerHeight;
    var w = window.innerWidth;
    return h >= w ? 20 : 30;
  }

  generateModal() {
    return (
      <Modal show={this.state.showAlertModal} onHide={this.closeAlertModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{this.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {this.modalMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeAlertModal}>Continue</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  takeNextStep() {
    var currentStep = this.state.step;

    switch (currentStep) {
      case "edit_info":
        this.props.navigationHandler("/account_info");
        // this.setState({step: "select_services"});
        break;
      default:
        console.log("nothing to do: " + currentStep);
    }
  }

  generateContent() {
    switch (this.state.step) {
      case "edit_info":
        return <EditUserInfo primaryAction={this.takeNextStep}/>;
      case "select_services":
        return <UserInfoFormModal cardHeight={this.getCardHeight()}/>;
      default:

    }
  }

  render() {

    let containerStyle = {
      height: '60vh',
      width: '70vw',
      border: 1,
      borderStyle: 'solid',
      borderColor: '#d8dcd9',
      borderRadius: 20,
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let loginBlockStyle = {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let titleStyle = {
      width: '100%',
      textAlign: 'center',
      marginTop: 40,
      marginBottom: 0,
      padding: 0,
      color: '#000000',
      fontSize: 24,
      fontWeight: 500
    }

    let dividerStyle = {
      width: '80%',
      height: 2,
      backgroundColor: '#b7b7b7'
    }

    return (
      <div>
        <Container>
          <Row>
            <Col md={6} mdOffset={3} style={loginBlockStyle}>
              <div style={containerStyle}>
                <div style={titleStyle}>Create Swytchback Account</div>
                <hr style={dividerStyle} />
                {this.generateContent()}
              </div>
            </Col>
          </Row>
        </Container>
        {this.generateModal()}
      </div>
    );
  }
}

const CreateAccountWrapper = () => {

  const navigate = useNavigate();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <CreateAccount navigationHandler={handleNavigation} />
}

export default CreateAccountWrapper;
