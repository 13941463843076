import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Image, Button, ButtonToolbar, Modal, FormGroup, FormControl }  from 'react-bootstrap';
import Draggable from 'react-draggable';
import Cookies from 'universal-cookie';
import { LinearProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import "./swydget/swydget.css";
import "../css/swytchbackStyles.css";
import "../css/swydgetStyles.css";
import MeService from '../services/MeService';
import DecisionToBeSubmitted from '../classes/DecisionToBeSubmitted';
import swydgetStore from "../stores/SwydgetStore";
import meStore from "../stores/MeStore";
import SwydgetWelcome from "./swydget/SwydgetWelcome";
import SwydgetRetired from "./swydget/SwydgetRetired";
import SwydgetInstructions from "./swydget/SwydgetInstructions";
import SwydgetResults from "./swydget/SwydgetResults";
import SwydgetRecommendations from "./swydget/SwydgetRecommendations";
import SwydgetComplete from "./swydget/SwydgetComplete";
import SwydgetContestEntry from "./swydget/SwydgetContestEntry";
import SwydgetSwipeCard from "./swydget/SwydgetSwipeCard";
import SwydgetForcedRankCardCollection from "./swydget/SwydgetForcedRankCardCollection";
import MediaModal from "../hooks/swydget/MediaModal";
import MultipleChoiceToolbar from "../hooks/swydget/toolbars/MultipleChoiceToolbar";
import FreeResponseToolbar from "../hooks/swydget/toolbars/FreeResponseToolbar";
import swytchbackDefaultWallpaper from '../SwytchbackDefaultWallpaper-1200.png';
import ProductStyles from '../classes/ProductStyles';
import BlockTypes from '../classes/BlockTypes';
import SwydgetMaxDiffCardCollection from './swydget/SwydgetMaxDiffCardCollection';
import BinaryToolbar from '../hooks/swydget/toolbars/BinaryToolbar';
import BlockToolbar from '../hooks/swydget/toolbars/BlockToolbar';
import ScalarToolbar from '../hooks/swydget/toolbars/ScalarToolbar';
import ForcedChoiceToolbar from '../hooks/swydget/toolbars/ForcedChoiceToolbar';
import MaxDiffToolbar from '../hooks/swydget/toolbars/MaxDiffToolbar';
import SwydgetPairwiseCardCollection from './swydget/SwydgetPairwiseCardCollection';
import PairwiseToolbar from '../hooks/swydget/toolbars/PairwiseToolbar';
import NextToolbar from '../hooks/swydget/toolbars/NextToolbar';



class Swydget extends Component {

  constructor(props) {
    super(props);

    this.classes = {}; // material-ui style injection stuff

    this.completionDivContainerStyle = {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff'
    };

    this.toolbarContainerDivStyle = {
      position: 'absolute',
      bottom: 0,
      backgroundColor: '#136ffb',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      borderTop: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#ffffff'
    };

    this.buttonToolbarStyle = {
      width: 300,
      padding: 0,
      margin: 0,
      height: 80
    }

    this.buttonStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      height: 80,
      backgroundColor: '#f7f6f7'
    }

    this.like = this.like.bind(this);
    this.dislike = this.dislike.bind(this);
    this.openProductLinkModal = this.openProductLinkModal.bind(this);
    this.closeProductLinkModal = this.closeProductLinkModal.bind(this);
    this.openFreeResponseModal = this.openFreeResponseModal.bind(this);
    this.closeFreeResponseModal = this.closeFreeResponseModal.bind(this);
    this.harvestFreeResponse = this.harvestFreeResponse.bind(this);
    this.setDecisionValuesAndHarvestFreeResponse = this.setDecisionValuesAndHarvestFreeResponse.bind(this);
    this.submitProductDecision = this.submitProductDecision.bind(this);
    this.openAlertOverlay = this.openAlertOverlay.bind(this);
    this.closeAlertOverlay = this.closeAlertOverlay.bind(this);
    this.userKeySet = this.userKeySet.bind(this);
    this.userKeyResolved = this.userKeyResolved.bind(this);
    this.anonymousKeySet = this.anonymousKeySet.bind(this);
    this.navigateToInstructions = this.navigateToInstructions.bind(this);
    this.navigateToSurvey = this.navigateToSurvey.bind(this);
    this.navigateToSurveyComplete = this.navigateToSurveyComplete.bind(this);
    this.navigateToSurveyResults = this.navigateToSurveyResults.bind(this);
    this.navigateToSurveyRecommendations = this.navigateToSurveyRecommendations.bind(this);
    this.navigateToAppStore = this.navigateToAppStore.bind(this);
    this.navigateToContestEntryForm = this.navigateToContestEntryForm.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSliderTouch = this.handleSliderTouch.bind(this);
    this.handleScalarSliderUnclicked = this.handleScalarSliderUnclicked.bind(this);
    this.handleScalarSliderClicked = this.handleScalarSliderClicked.bind(this);
    this.productLibraryLoaded = this.productLibraryLoaded.bind(this);
    this.handleFreeResponseAnswerChange = this.handleFreeResponseAnswerChange.bind(this);
    this.getTarget = this.getTarget.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.dismissLeft = this.dismissLeft.bind(this);
    this.dismissRight = this.dismissRight.bind(this);
    this.skipCard = this.skipCard.bind(this);
    this.snapToCenter = this.snapToCenter.bind(this);


    this.userKey = null;
    this.surveyTakerMid = null;
    this.eventInFocus = this.props.eventInFocus;
    this.email = this.props.email;
    this.firstName = this.props.firstName;
    this.lastName = this.props.lastName;
    this.surveyComplete = false;
    this.productInFocus = null;
    this.cards = [];
    this.wyshes = this.eventInFocus.getWyshes();
    this.accumulatedAttributesDict = {};
    this.multipleChoiceSelectionsSet = new Set();
    this.forcedChoiceSelectionSet = new Set();

    // this.decisionRaw = "";
    // this.decisionValue = 0.0;
    this.decisionsGathered = [];
    this.decisionsDataSet = new Set();
    this.viewedTimestamp = 0;
    this.submissionTimestamp = 0;

    this.state = {
      index: 0,
      rootWyshesCount: this.wyshes ? this.wyshes.length : 0,
      rootWyshesIndex: 0,
      currentView: 'getStarted',
      cards: [],
      showProductLinkModal: false,
      showFreeResponseModal: false,
      showAlertOverlay: false,
      currentProductName: '',
      currentProductLink: '',
      isCurrentProductLinkImage: false,
      currentSliderValue: 0,
      maxDiffCurrentTrialSetIndex: 0,
      maxDiffCardSelectedIndex: -1,
      maxDiffCurrentTrialSetDecisions: [],
      pairwiseCurrentTrialSetIndex: 0,
      pairwiseCardSelectedIndex: -1,
      pairwiseCurrentTrialSetDecisions: [],
      isScalarNextDisabled: true,
      isScalarOptionHidden: true,
      alertTitle: "Alert",
      alertMessage: "Something Alarming",
      freeResponseQuestion: "",
      freeResponseAnswer: "",
      showPrompt: true,
      containerWidth: 20,
      containerHeight: 20,
      xValue: 0,
      yValue: 0,
      swipeLeftX: 0,
      swipeRightX: 0,
      cardHeight: 100,
      cardWidth: 100,
      cardPositions: {},
      disableControls: false
    }



    // if (this.eventInFocus.randomize === true) {
    //
    //   if (this.eventInFocus.issueSubGroups === true) {
    //     var wyshesSubset = [];
    //     var shuffledWyshes = this.eventInFocus.shuffle();
    //     for (var i = 0; i < this.eventInFocus.subGroupCount; i++) {
    //       wyshesSubset.push(shuffledWyshes[i]);
    //     }
    //
    //     this.wyshes = wyshesSubset;
    //   }
    //   else {
    //     this.wyshes = this.eventInFocus.shuffle();
    //   }
    // }
    //
    // for (var i = 0; i < this.wyshes.length; i++) {
    //   let w = this.wyshes[i];
    //   var childWyshes = [];
    //   if (w.randomize === true) {
    //     childWyshes = w.orderedWyshList.shuffle();
    //   }
    //   else {
    //     childWyshes = w.orderedWyshList.getWyshes();
    //   }
    // }
  }

  getTarget() {
    return ReactDOM.findDOMNode(this.target);
  }

  componentWillMount() {
    swydgetStore.on("change-userKeySet", this.userKeySet);
    swydgetStore.on("change-userKeyResolved", this.userKeyResolved);
    swydgetStore.on("change-anonymousKeySet", this.anonymousKeySet);
    meStore.on("change-surveyCreatorProductLibraryLoaded", this.productLibraryLoaded);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    swydgetStore.removeListener("change-userKeySet", this.userKeySet);
    swydgetStore.removeListener("change-userKeyResolved", this.userKeyResolved);
    swydgetStore.removeListener("change-anonymousKeySet", this.anonymousKeySet);
    meStore.removeListener("change-surveyCreatorProductLibraryLoaded", this.productLibraryLoaded);
    this.cards = [];
  }

  addBlockCards(wysh) {
    if (wysh === null || wysh.isBlock() === false) {
      return;
    }

    var block = wysh;
    var blockWyshes = block.orderedWyshList.getWyshesTwo(block.randomize, block.issueSubGroups, block.subGroupCount, block.limitFreeResponse, block.limitFreeResponseCount);
    if (blockWyshes && blockWyshes.length > 0) {

      // Place the cards within the BLOCK after the BLOCK card
      for (var i = 0; i < blockWyshes.length; i++) {
        this.wyshes.splice(this.state.index + 1 + i, 0, blockWyshes[i]);
      }
    }
  }

  initializeBlock = (block) => {

    if (block === null && block.isBlock() === false) {
      return;
    }

    var insertIndex = this.findWyshIndex(block) + 1;
    var blockWyshes = block.orderedWyshList.getWyshesTwo(block.randomize, block.issueSubGroups, block.subGroupCount, block.limitFreeResponse, block.limitFreeResponseCount);

    if (blockWyshes && blockWyshes.length > 0) {
      for (var i = 0; i < blockWyshes.length; i++) {
        this.wyshes.splice(insertIndex + i, 0, blockWyshes[i]);
      }
    }

    if (blockWyshes && blockWyshes.length > 0 && blockWyshes[0].isBlock() === true) {
      this.initializeBlock(blockWyshes[0]);
    }
  }

  componentDidMount() {
    this.setUpDimensions();
    window.addEventListener('resize', this.handleResize);

    MeService.getProductsByMid(this.eventInFocus.creatorMid);

    var cardPositions = this.state.cardPositions;
    
    if (this.wyshes && this.wyshes.length > 0 && this.wyshes[0].isBlock()) {
      this.initializeBlock(this.wyshes[0]);
    }

    var firstCardVisible = false;
    var firstCardFreeResponseOnly = false;
    var startingIndex = 0;
    
    for (var i = 0; i < this.wyshes.length; i++) {

      // Set the Card Positions
      // if (this.wyshes[i].isBlock() && 
      //     this.wyshes[i].displayBlockCard === false && 
      //     this.wyshes[i].getBlockType() &&
      //     this.wyshes[i].getBlockType().equals(BlockTypes.FORCEDCHOICE) === false &&
      //     this.wyshes[i].getBlockType().equals(BlockTypes.MAXDIFF) === false) {
      if (this.wyshes[i].isBlock() && this.wyshes[i].getBlockType() &&
          (this.wyshes[i].getBlockType().equals(BlockTypes.STANDARD) ||
          this.wyshes[i].getBlockType().equals(BlockTypes.SEQUENTIALMONADIC))
          ) {      
        cardPositions["card-" + this.wyshes[i].wyshId] = {x: -2000, y: 0, shadow: false, blur: false, cw: this.state.containerWidth};
        this.addFakeDecisionToGetPastUndesiredBlockCardBrief(this.wyshes[i]);
      }
      else {
        if (firstCardVisible === false) {
          cardPositions["card-" + this.wyshes[i].wyshId] = {x: 0, y: 0, shadow: false, blur: false, cw: this.state.containerWidth};
          firstCardVisible = true;
          firstCardFreeResponseOnly = this.wyshes[i].isFreeResponseOnly();
          startingIndex = i;
        }
        else {
          cardPositions["card-" + this.wyshes[i].wyshId] = {x: 0, y: 0, shadow: false, blur: true, cw: this.state.containerWidth};
        }
      }
    }

    if (this.wyshes.length > 1) {
      cardPositions["card-" + this.wyshes[this.wyshes.length - 1].wyshId] = {x: 0, y: 0, shadow: true, blur: true, cw: this.state.containerWidth};
    }
    else {
      cardPositions["card-" + this.wyshes[0].wyshId] = {x: 0, y: 0, shadow: true, blur: false, cw: this.state.containerWidth};
    }

    if (this.eventInFocus.swydgetState === "deleted" || this.eventInFocus.swydgetState === "retired" || this.eventInFocus.swydgetState === "archived") {
      this.setState({
        currentView: "surveyRetired",
        cardPositions: cardPositions,
        index: startingIndex
      });
    }
    else {
      this.setState({
        cardPositions: cardPositions,
        index: startingIndex,
        showFreeResponseModal: firstCardFreeResponseOnly
      });
    }
  }

  setUpDimensions() {

    let swydgetContainerHeight = document.getElementById('swydget_container').offsetHeight;
    let swydgetContainerWidth = document.getElementById('swydget_container').offsetWidth;
    var heightMultipier = 0.7;

    if (swydgetContainerWidth > swydgetContainerHeight) {
      heightMultipier = 0.4;
    }
    else if (swydgetContainerWidth * 1.6 < swydgetContainerHeight) {
      heightMultipier = 1.4;
    }

    let mediaModalWidth = document.getElementById('swydget_container').offsetWidth - 72;
    let mediaModalHeight = mediaModalWidth * heightMultipier;
    let contHeight = document.getElementById('swydget_container').offsetHeight * 0.45;
    let contWidth = document.getElementById('swydget_container').offsetWidth;
    let cardHeight = contHeight;
    let cardWidth = cardHeight * (2.5/3.5); // standard card width

    this.setState({
      containerWidth: contWidth,
      containerHeight: contHeight,
      xValue: (contWidth / 2) - cardWidth/2,
      yValue: (contHeight / 2) - cardHeight/2,
      swipeLeftX: contWidth *.18,
      swipeRightX: contWidth *.82,
      cardHeight: cardHeight,
      cardWidth: cardWidth,
      mediaModalWidth: mediaModalWidth,
      mediaModalHeight: mediaModalHeight
    });
  }

  areDecisionsPositive() {
    for (let decision of this.decisionsDataSet) {
      if (decision["decisionValue"] > 0.0) {
        return true;
      }
    }
    return false;
  }

  isFreeResponseHarvestAppropriate(wysh) {
    if (
        wysh.isBlock() === true ||
        wysh.gatherFreeResponse === false
      ) {
      return false;
    }

    for (let decision of this.decisionsDataSet) {

      // hiddenFreeResponseOptions is an array of decision values that should NOT trigger the free response modal
      // In MC, the user might select one that hides the free response while also selecting another that does not
      // hide the free response. In this situation, we will show the free response modal.

      // So, we will return true (harvesting free response is appropriate) if any of my decisions
      // are NOT in the hiddenFreeResponseOptions array

      if (wysh.hiddenFreeResponseOptions.includes(parseFloat(decision["decisionValue"])) === false) {
        return true
      }
    }

    return false;
  }

  handleResize(e) {
    this.setUpDimensions();
  }

  handleStart(e, dragElement) {
    dragElement.node.style.transition = "none";
  }

  handleDrag(e, dragElement) {

    var cardPositionsDict = this.state.cardPositions;

    var w = this.wyshes[this.state.index];

    if (cardPositionsDict["card-" + w.wyshId].shadow === false) {
      cardPositionsDict["card-" + w.wyshId].shadow = true;
    }

    if (cardPositionsDict["card-" + (w.wyshId + 1)] !== undefined) {
      cardPositionsDict["card-" + (w.wyshId + 1)].blur = true;
    }


    cardPositionsDict["card-" + w.wyshId].x = dragElement.x;
    cardPositionsDict["card-" + w.wyshId].cw = this.state.containerWidth;

    this.setState({ cardPositions: cardPositionsDict});
  }

  handleStop(e, dragElement) {

    let boxCenter = this.state.containerWidth / 2 + dragElement.lastX;

    var w = this.wyshes[this.state.index];

    if (w.isSwipeSupported()) {
      if (boxCenter < this.state.swipeLeftX) {
        if (w.questionType === "next") {
          this.setDecisionValuesAndHarvestFreeResponse("yes", "1.0", dragElement);
        }
        else {
          this.setDecisionValuesAndHarvestFreeResponse("no", "0.0", dragElement);
        }
      }
      else if (boxCenter > this.state.swipeRightX) {
        this.setDecisionValuesAndHarvestFreeResponse("yes", "1.0", dragElement); //binary, next
      }
      else {
        this.snapToCenter(dragElement.node.id);
      }
    }
    else{
      // swiping should not be allowed in these circumstances at all
      // TODO: incorporate blockType in here
      this.snapToCenter(dragElement.node.id);
    }  
  }

  dismissLeft(id) {
    var element = document.getElementById(id);
    var cardPositions = this.state.cardPositions;

    cardPositions[id] = {x: -2000, y: 0, cw: this.state.containerWidth};
    this.setState({cardPositions: cardPositions});

    element.style.transition = "transform 1000ms ease-in 0ms";
    element.style.transform = "translate(-2000px, 0px) rotate(181deg)";
  }

  dismissRight(id) {
    var element = document.getElementById(id);
    var cardPositions = this.state.cardPositions;

    cardPositions[id] = {x: 2000, y: 0};
    this.setState({cardPositions: cardPositions});

    element.style.transition = "transform 1000ms ease-in 0ms";
    element.style.transform = "translate(2000px, 0px) rotate(179deg)";
  }

  skipCard(id) {
    var element = document.getElementById(id);
    var cardPositions = this.state.cardPositions;

    cardPositions[id] = {x: 2000, y: 0};
    this.setState({cardPositions: cardPositions});
    if (element) {
      element.style.transition = "transform 0ms ease-in 0ms";
      element.style.transform = "translate(2000px, 0px) rotate(179deg)";
    }
  }

  snapToCenter(id) {
    var cardPositionsDict = this.state.cardPositions;

    let w = this.wyshes[this.state.index];

    if (cardPositionsDict["card-" + w.wyshId].shadow === true) {
      cardPositionsDict["card-" + w.wyshId].shadow = false;
      cardPositionsDict["card-" + w.wyshId].x = 0;
      cardPositionsDict["card-" + w.wyshId].y = 0;
      cardPositionsDict["card-" + w.wyshId].cw = this.state.containerWidth;
      cardPositionsDict["card-" + w.wyshId].blur = false;
      this.setState({ cardPositions: cardPositionsDict});
    }

    var element = document.getElementById(id);
    element.style.transition = "transform 1000ms cubic-bezier(1, 0, 1, 1) 100ms";
    element.style.transform = "translate(0px, 0px)";
  }

  resetCardBlurs() {
    var cardPositionsDict = this.state.cardPositions;

    for (var key in cardPositionsDict) {
      if (key !== null) {
        var cp = cardPositionsDict[key];
        cp.blur = true;
      }
    }

    cardPositionsDict["card-" + this.state.index].blur = false;

    this.setState({ cardPositions: cardPositionsDict});
  }

  setCookie(cookieName, cookieValue) {
    const d = new Date();
    d.setTime(d.getTime() + (10*365*24*60*60*1000)); //Expires in 10 years
    let expires = "expires=" + d.toUTCString();
    const cookieString = cookieName + "=" + cookieValue + ";" + expires + ";path=/;SameSite=None;secure;partitioned"; 
    document.cookie = cookieString;
  }

  /* state change handlers */
  userKeySet() {
    this.userKey = swydgetStore.surveyTakerKey;
    this.surveyTakerMid = swydgetStore.surveyTakerMid;

    // We have the userKey of the survey taker!!!
    const jsonString = JSON.stringify(this.userKey);
    this.setCookie("sb", encodeURIComponent(jsonString));
  }

  anonymousKeySet() {
    this.userKeySet();

    var wyshInFocus = this.wyshes[this.state.index > this.wyshes.length - 1 ? 0 : this.state.index];

    this.setState({
      currentView: "takeSurvey",
      showProductLinkModal: wyshInFocus && wyshInFocus.product.getSecureBuyPage().length > 0,
      currentProductLink: wyshInFocus && wyshInFocus.product.getSecureBuyPage() ? wyshInFocus.product.getSecureBuyPage() : ""
    });
  }

  getInitialSliderMaxLimit() {
    var maxLimit = 0;

    for (var i = 0; i < this.wyshes.length; i++) {
      maxLimit = this.getSliderMaxLimit(i);
      if (maxLimit > 0) {
        return maxLimit;
      }
    }

    return maxLimit;
  }

  getInitialSliderDefaultValue() {
    if (this.wyshes[0] && this.wyshes[0].isBlock() && this.wyshes[0].getBlockType().equals(BlockTypes.FORCEDCHOICE) === true && this.wyshes[0].randomize === false) {
      // Forced Choice block that is NOT randomized
      return 0;
    }
    var maxLimit = this.getInitialSliderMaxLimit();
    if (maxLimit > 0) {
      return Math.round(maxLimit / 2);
    }
    else {
      return 0;
    }
  }

  getSliderMaxLimit(index) {
    var maxLimit = 0;

    if (this.wyshes[index] !== undefined && this.wyshes[index] !== null) {
      if (this.wyshes[index].isBlock() && this.wyshes[index].getBlockType().equals(BlockTypes.FORCEDCHOICE) === true) {
        maxLimit = this.wyshes[index].orderedWyshList.getWyshes().length;
      }
      else if (this.wyshes[index].questionType === "scalar" || this.wyshes[index].questionType === "multiple-choice" || this.wyshes[index].questionType === "single-select") {
        if (this.wyshes[index].wyshOptions.length > 0) {
          maxLimit = this.wyshes[index].wyshOptions.length - 1;
        }
      }
    }

    return Math.round(maxLimit);
  }

  getSliderDefaultValue(index) {

    if (this.wyshes[index] && this.wyshes[index].isBlock() && this.wyshes[index].getBlockType().equals(BlockTypes.FORCEDCHOICE) === true && this.wyshes[index].randomize === false) {
      return 0;
    }
    else {
      var defaultValue = this.getSliderMaxLimit(index);
      if (defaultValue > 0) {
        return Math.round(defaultValue / 2);
      }
      else {
        return defaultValue;
      }
    }
  }

  userKeyResolved() {
    this.userKeySet();
    this.setState({
      currentView: "surveyComplete"
    });
  }

  openProductLinkModal() {

    var w = this.wyshes[this.state.index];
    // var link = w.product.getSecureBuyPage();
    var link = w.product.buyPage;
    var productImageUrl = w.product.getSecureImageUrl(w);

    if (link !== '') {
      // if (w.product.isBuyPageSecure() === true) {
        this.setState({
          showProductLinkModal: true,
          currentProductName: w.product.name,
          currentProductLink: link,
          isCurrentProductLinkImage: false
        });
      // }
      // else {
      //   window.open(link, '');
      // }
    }
    else if (productImageUrl !== '') {
      this.setState({
        showProductLinkModal: true,
        currentProductName: w.product.name,
        currentProductLink: productImageUrl,
        isCurrentProductLinkImage: true
      });
    }
  }

  closeProductLinkModal() {
    this.setState({ showProductLinkModal: false, currentProductName: '', currentProductLink: '', isCurrentProductLinkImage: false });
  }

  openFreeResponseModal(question) {
    this.setState({
      showPrompt: false,
      showFreeResponseModal: true,
      freeResponseQuestion: question
    });
  }

  closeFreeResponseModal() {
    this.setState({
      showPrompt: true,
      showFreeResponseModal: false,
      freeResponseAnswer: ""
    });
  }

  openAlertOverlay() {
    this.setState({showAlertOverlay: true});
  }

  closeAlertOverlay() {
    this.setState({showAlertOverlay: false});
  }

  cardLoadedCallback = () => {
    this.setState({disableControls: false});
  }

  setViewedTimestamp = (loadedTimeStamp) => {
    this.viewedTimestamp = loadedTimeStamp;
  }

  getCards() {

    let cardContainerDivStyle = {
      top: this.state.yValue - 20,
      left: this.state.xValue - 20,
      height: this.state.cardHeight + 40,
      width: this.state.cardWidth + 40,
      position: 'absolute',
      overflow: 'hidden',
      padding: 20
    };

    var cards = [];

    swydgetStore.setMaxListeners(this.wyshes.length + 5);

    for (var i = this.wyshes.length - 1 ; i > -1; i--) {

      let w = this.wyshes[i];

      var id = "card-" + w.wyshId;

      if (w.getBlockType() && w.getBlockType().equals(BlockTypes.FORCEDCHOICE)) {
        
        var swydgetWyshes = w.orderedWyshList.getWyshes();

        cards.push( <Draggable
                      key={i}
                      axis="both"
                      handle=".handle"
                      disabled={true}
                      scale={1}
                      position={this.state.cardPositions[id]}
                      onStart={this.handleStart}
                      onDrag={this.handleDrag}
                      onStop={this.handleStop}>
                      <div id={id} style={cardContainerDivStyle}>
                        <div className="handle">
                          <SwydgetForcedRankCardCollection
                            forcedChoiceBlock={w}
                            cardLoadedCallback={this.cardLoadedCallback}
                            selectedCardIndex={this.state.currentSliderValue}
                            setViewedTimestamp={this.setViewedTimestamp}
                            totalWyshBucketsCount={swydgetWyshes.length}
                            clickAction={this.openProductLinkModal}
                            cardHeight={this.state.cardHeight}
                            cardWidth={this.state.cardWidth}
                            cardPosition={this.state.cardPositions[id]}
                          />
                        </div>
                      </div>
                    </Draggable>);
      }
      else if (w.getBlockType() && w.getBlockType().equals(BlockTypes.MAXDIFF)) {

        var swydgetWyshes = w.orderedWyshList.getWyshes();

        cards.push( <Draggable
                      key={i}
                      axis="both"
                      handle=".handle"
                      disabled={true}
                      scale={1}
                      position={this.state.cardPositions[id]}
                      onStart={this.handleStart}
                      onDrag={this.handleDrag}
                      onStop={this.handleStop}>
                      <div id={id} style={cardContainerDivStyle}>
                        <div className="handle">
                          <SwydgetMaxDiffCardCollection
                            maxDiffBlock={w}
                            maxDiffCurrentTrialSetIndex={this.state.maxDiffCurrentTrialSetIndex}
                            maxDiffCurrentTrialSetDecisions={this.state.maxDiffCurrentTrialSetDecisions}
                            cardLoadedCallback={this.cardLoadedCallback}
                            setViewedTimestamp={this.setViewedTimestamp}
                            cardSelectedCallback={this.maxDiffCardSelectedCallback}
                            cardInFocusIndex={this.state.maxDiffCardSelectedIndex}
                            selectedCardIndex={this.state.currentSliderValue}
                            cardHeight={this.state.cardHeight}
                            cardWidth={this.state.cardWidth}
                            cardPosition={this.state.cardPositions[id]}
                          />
                        </div>
                      </div>
                    </Draggable>);
      }
      else if (w.getBlockType() && w.getBlockType().equals(BlockTypes.PAIRWISE)) {

        var swydgetWyshes = w.orderedWyshList.getWyshes();

        cards.push( <Draggable
                      key={i}
                      axis="both"
                      handle=".handle"
                      disabled={true}
                      scale={1}
                      position={this.state.cardPositions[id]}
                      onStart={this.handleStart}
                      onDrag={this.handleDrag}
                      onStop={this.handleStop}>
                      <div id={id} style={cardContainerDivStyle}>
                        <div className="handle">
                          <SwydgetPairwiseCardCollection
                            block={w}
                            currentTrialSetIndex={this.state.pairwiseCurrentTrialSetIndex}
                            currentTrialSetDecisions={this.state.pairwiseCurrentTrialSetDecisions}
                            cardLoadedCallback={this.cardLoadedCallback}
                            setViewedTimestamp={this.setViewedTimestamp}
                            cardSelectedCallback={this.pairwiseCardSelectedCallback}
                            cardInFocusIndex={this.state.pairwiseCardSelectedIndex}
                            selectedCardIndex={this.state.currentSliderValue}
                            cardHeight={this.state.cardHeight}
                            cardWidth={this.state.cardWidth}
                            cardPosition={this.state.cardPositions[id]}
                          />
                        </div>
                      </div>
                    </Draggable>);
      }
      else if (w.questionType && (w.questionType === "scalar" || w.questionType === "multiple-choice" || w.questionType === "single-select")) {
        cards.push( <Draggable
                      key={i}
                      axis="both"
                      handle=".handle"
                      disabled={true}
                      scale={1}
                      position={this.state.cardPositions[id]}
                      onStart={this.handleStart}
                      onDrag={this.handleDrag}
                      onStop={this.handleStop}>
                      <div id={id} style={cardContainerDivStyle}>
                        <div className="handle">
                          <SwydgetSwipeCard
                            idx={i}
                            wysh={w}
                            cardLoadedCallback={this.cardLoadedCallback}
                            setViewedTimestamp={this.setViewedTimestamp}
                            totalWyshesCount={this.wyshes.length}
                            clickAction={this.openProductLinkModal}
                            cardHeight={this.state.cardHeight}
                            cardWidth={this.state.cardWidth}
                            cardPosition={this.state.cardPositions[id]}/>
                          </div>
                      </div>
                    </Draggable>);
      } 
      else {
        cards.push( <Draggable
                      key={i}
                      axis="both"
                      handle=".handle"
                      disabled={this.state.disableControls}
                      scale={1}
                      position={this.state.cardPositions[id]}
                      onStart={this.handleStart}
                      onDrag={this.handleDrag}
                      onStop={this.handleStop}>
                      <div id={id} style={cardContainerDivStyle}>
                        <div className="handle">
                          <SwydgetSwipeCard
                            idx={i}
                            wysh={w}
                            cardLoadedCallback={this.cardLoadedCallback}
                            setViewedTimestamp={this.setViewedTimestamp}
                            totalWyshesCount={this.wyshes.length}
                            clickAction={this.openProductLinkModal}
                            cardHeight={this.state.cardHeight}
                            cardWidth={this.state.cardWidth}
                            cardPosition={this.state.cardPositions[id]}
                          />
                        </div>
                      </div>
                    </Draggable>);
      }
    }

    return cards;
  }

  like() {
    this.submitProductDecision("yes", "1.0");
  }

  dislike() {
    this.submitProductDecision("no", "0.0");
  }

  navigateToInstructions() {
    this.setState({
      currentView: "instructions"
    });
  }

  navigateToSurvey() {

    document.removeEventListener('touchmove', this.preventScroll);

    var wyshInFocus = this.wyshes[this.state.index > this.wyshes.length - 1 ? 0 : this.state.index];

    if (this.state.index === 0) {
      this.setViewedTimestamp(new Date().getTime());
    }

    // right here, we need to register
    if (this.userKey || this.email) {

      this.setState({
        currentView: "takeSurvey",
        disableControls: wyshInFocus && wyshInFocus.product ? wyshInFocus.product.hasImage() : false,
        showProductLinkModal: wyshInFocus && wyshInFocus.product.getSecureBuyPage().length > 0,
        currentProductLink: wyshInFocus && wyshInFocus.product.getSecureBuyPage() ? wyshInFocus.product.getSecureBuyPage() : ""
      });
    }
    else {
      const versionId = this.eventInFocus.getVersionId();
      MeService.registerAnonymousSurveyTaker(this.eventInFocus.eventId, versionId ? versionId : -1);
    }
  }

  navigateToSurveyComplete() {
    this.setState({
      currentView: "surveyComplete"
    });
  }

  navigateToSurveyResults() {
    this.setState({
      currentView: "surveyResults"
    });
  }

  navigateToSurveyRecommendations() {
    this.setState({
      currentView: "surveyRecommendations"
    });
  }

  navigateToContestEntryForm() {
    this.setState({
      currentView: "enterContest"
    });
  }

  navigateToAppStore() {
    window.open('https://swytchback.com/', '_self');
    // window.open('https://itunes.apple.com/us/app/swytchback/id1178824453?mt=8', '_self');
  }

  setDecisionValuesAndHarvestFreeResponse(decisionRaw, decisionValue, dragElement=null) {

    this.decisionsDataSet.add({
      "wysh": this.wyshes[this.state.index],
      "decisionValue": decisionValue,
      "decisionRaw": decisionRaw
    });

    // this.decisionRaw = decisionRaw;
    // this.decisionValue = decisionValue;
    this.harvestFreeResponse(dragElement);
  }

  harvestFreeResponse(dragElement=null) {

    var w = this.wyshes[this.state.index];

    if (this.isFreeResponseHarvestAppropriate(w) === true) {
      if (dragElement) {
        this.snapToCenter(dragElement.node.id);
      }
      this.openFreeResponseModal(w.freeResponseQuestions[0]);
    }
    else {
      this.submitProductDecision();
    }
  }

  submitScalarDecision(decisionRaw, decisionValue) {
    if (this.state.isScalarNextDisabled === true) {
      this.alertTitle = "Selection Not Made";
      this.alertMessage = "Drag slider to select answer.";
      this.openAlertOverlay();
    }
    else {
      this.setState({isScalarNextDisabled: true, isScalarOptionHidden: true});
      this.setDecisionValuesAndHarvestFreeResponse(decisionRaw, decisionValue);
    }
  }

  submitForcedChoiceDecision = (forcedChoiceWyshArray, selectedIdx) => {
    this.forcedChoiceSelectionSet = new Set();
    this.setState({isScalarNextDisabled: true, isScalarOptionHidden: true});

    if (this.eventInFocus.published === true) {
      for (var i = 0; i < forcedChoiceWyshArray.length; i++) {
        if (i === selectedIdx) {
          this.decisionsDataSet.add({
            "wysh": forcedChoiceWyshArray[i],
            "decisionValue": "1.0",
            "decisionRaw": "yes"
          });
        }
        else {
          this.decisionsDataSet.add({
            "wysh": forcedChoiceWyshArray[i],
            "decisionValue": "0.0",
            "decisionRaw": "no"
          });
        }
      }
    }

    this.submitProductDecision();
  }

  forcedChoiceSliderChangeCallback = (index) => {
    this.setState({ currentSliderValue: index });
  }

  maxDiffCardSelectedCallback = (cardInFocusIndex) => {
    this.setState({ maxDiffCardSelectedIndex: cardInFocusIndex });
  }

  maxDiffTrialSetChangeCallback = (index) => {
    this.setState({ maxDiffCurrentTrialSetIndex: index, maxDiffCardSelectedIndex: -1 });
  }

  pairwiseCardSelectedCallback = (cardInFocusIndex) => {
    this.setState({ pairwiseCardSelectedIndex: cardInFocusIndex });
  }

  pairwiseTrialSetChangeCallback = (index) => {
    this.setState({ pairwiseCurrentTrialSetIndex: index, pairwiseCardSelectedIndex: -1 });
    this.setState({ maxDiffCurrentTrialSetIndex: index, maxDiffCardSelectedIndex: -1 });
  }


  submitMultipleChoiceDecision = (selectedChoices) => {

    this.multipleChoiceSelectionsSet = selectedChoices;
    for (let mc of this.multipleChoiceSelectionsSet) {
      this.decisionsDataSet.add({
        "wysh": this.wyshes[this.state.index],
        "decisionValue": mc.resultNormalized.toString(),
        "decisionRaw": mc.resultLiteral
      });
    }

    this.setState({isScalarNextDisabled: true, isScalarOptionHidden: true});
    this.harvestFreeResponse();
  }

  submitFreeResponseOnlyDecision = (freeResponseAnswer) => {

    console.log("submitFreeResponseOnlyDecision");

    var wyshFound = false;
    console.log(this.decisionsDataSet);

    for (const dec of this.decisionsDataSet) {
      console.log(dec);
      if (dec.wysh.wyshId === this.wyshes[this.state.index].wyshId) {
        console.log("decision for this wysh already in there. Need to put the free response in there");
        wyshFound = true;
      }
    }

    if (wyshFound === false) {
      this.decisionsDataSet.add({
        "wysh": this.wyshes[this.state.index],
        "decisionValue": "1.0",
        "decisionRaw": ""
      });

    }

    this.submitProductDecision(freeResponseAnswer);
  }

  setSurveyComplete() {

    if (this.eventInFocus.published === true) {
      MeService.swydgetComplete(this.userKey, this.eventInFocus.eventId);
    }
    this.surveyComplete = true;

    // here we need to set the state of the Swydget since we are declaring the Swydget is complete

    if (this.eventInFocus.showResults === true) {
      this.navigateToSurveyResults();
    }
    else if (this.eventInFocus.showRecommendations === true) {
      this.navigateToSurveyRecommendations();
    }
    else if (this.eventInFocus.hasContestSetup() === true) {
      this.navigateToContestEntryForm();
    }
    else {
      this.navigateToSurveyComplete();
    }
  }

  submitMaxDiffDecisionsMap = (trialSetsDecisionsMap) => {

    var currentWysh = this.wyshes[this.state.index];
    this.dismissRight(["card-" + currentWysh.wyshId]);

    if (this.state.index === this.wyshes.length - 1 && currentWysh.orderedWyshList.wyshes.length === 0) {
      this.setSurveyComplete();
    }

    var key;

    if (this.email) {
      key = {
        t: 'email',
        v: this.email
      }
    }
    else if (this.userKey) {
      key = this.userKey;
    }

    console.log(this.userKey);
    this.submissionTimestamp = new Date().getTime();
    const dwellTime = this.submissionTimestamp - this.viewedTimestamp;
    const userInteractionJson = {
      ts_submission: this.submissionTimestamp,
      ts_viewed: this.viewedTimestamp,
      dwell: dwellTime
    };

    // Submit the Map to the backend!!!
    if (this.eventInFocus.published === true) {
      MeService.submitMaxDiffDecisions(key, this.eventInFocus, trialSetsDecisionsMap, userInteractionJson);
    }

    this.incrementProductIndex();
  }

  submitPairwiseDecisionMap = (trialSetsDecisionsMap) => {

    var currentWysh = this.wyshes[this.state.index];
    this.dismissRight(["card-" + currentWysh.wyshId]);

    if (this.state.index === this.wyshes.length - 1 && currentWysh.orderedWyshList.wyshes.length === 0) {
      this.setSurveyComplete();
    }

    var key;

    if (this.email) {
      key = {
        t: 'email',
        v: this.email
      }
    }
    else if (this.userKey) {
      key = this.userKey;
    }

    console.log(this.userKey);
    this.submissionTimestamp = new Date().getTime();
    const dwellTime = this.submissionTimestamp - this.viewedTimestamp;
    const userInteractionJson = {
      ts_submission: this.submissionTimestamp,
      ts_viewed: this.viewedTimestamp,
      dwell: dwellTime
    };

    // Submit the Map to the backend!!!
    if (this.eventInFocus.published === true) {
      console.log(trialSetsDecisionsMap);
    
      MeService.submitMaxDiffDecisions(key, this.eventInFocus, trialSetsDecisionsMap, userInteractionJson);
    }

    this.incrementProductIndex();
  }

  maxDiffCardPreferencesUpdatedCallback = (decisions) => {
    this.setState({maxDiffCurrentTrialSetDecisions: decisions, maxDiffCardSelectedIndex: -1});
  }

  pairwiseCardPreferencesUpdatedCallback = (decisions) => {
    this.setState({pairwiseCurrentTrialSetDecisions: decisions, pairwiseCardSelectedIndex: -1});
  }

  submitProductDecision(freeResponseAnswer="") {

    // Close the free response modal if open
    if (this.state.showFreeResponseModal === true) {
      this.closeFreeResponseModal();
    }

    var currentWysh = this.wyshes[this.state.index];

    // Dismiss the card based on the question type
    if (currentWysh.questionType === "scalar" ||
        currentWysh.questionType === "multiple-choice" ||
        currentWysh.questionType === "single-select" ||
        currentWysh.isFreeResponseOnly()) {
      this.dismissRight(["card-" + currentWysh.wyshId]);
    }
    else {

      // We used to just have the single decisionValue and then we would decide what we want to do based on that.
      // Now we have a set of decisionValues in the decisionsDataSet
      if (this.areDecisionsPositive()) {
        this.dismissRight(["card-" + currentWysh.wyshId]);
      }
      else {
        this.dismissLeft(["card-" + currentWysh.wyshId]);
      }
    }

    if (this.state.index === this.wyshes.length - 1 && currentWysh.orderedWyshList.wyshes.length === 0) {
      this.setSurveyComplete();
    }

    var key;

    if (this.email) {
      key = {
        t: 'email',
        // v: 'test117@catboytech.com'
        v: this.email
      }
    }
    else if (this.userKey) {
      key = this.userKey;
    }

    console.log(this.userKey);
    this.submissionTimestamp = new Date().getTime();
    const dwellTime = this.submissionTimestamp - this.viewedTimestamp;

    const userInteractionJson = {
      ts_submission: this.submissionTimestamp,
      ts_viewed: this.viewedTimestamp,
      dwell: dwellTime
    };
    
    for (var decisionData of this.decisionsDataSet) {
      let decisionToSubmit = new DecisionToBeSubmitted();
      if (this.surveyTakerMid) {
        decisionToSubmit.surveyTakerMid = this.surveyTakerMid;
      }
      var w = decisionData["wysh"];
      decisionToSubmit.key = key;
      decisionToSubmit.wysh = w
      decisionToSubmit.firstName = this.firstName;
      decisionToSubmit.lastName = this.lastName;
      decisionToSubmit.productId = w.product.productId;
      decisionToSubmit.event = this.eventInFocus;
      decisionToSubmit.decisionRaw = decisionData["decisionRaw"];
      decisionToSubmit.decisionValue = decisionData["decisionValue"];
      decisionToSubmit.completionDivContainerStyle = this.props.coords;
      decisionToSubmit.freeResponseAnswer = freeResponseAnswer;
      this.decisionsGathered.push(decisionToSubmit);

      if (this.eventInFocus.published === true && w.isBlock() === false) {
        MeService.postProductDecision(
          false,
          key,
          this.firstName,
          this.lastName,
          w.product.productId,
          this.eventInFocus.eventId,
          decisionData["decisionRaw"],
          decisionData["decisionValue"],
          this.props.coords,
          freeResponseAnswer,
          userInteractionJson,
          this.props.location
        );
      }

      w.wyshScore.resultLiteral = decisionData["decisionRaw"];
      w.wyshScore.resultNormalized = decisionData["decisionValue"];

      var numberOfOptions = w.wyshOptions.length;

      if (w.questionType === "binary") {
        numberOfOptions = 2;
      }

      this.processProductAttributes(w.product, parseFloat(this.decisionValue), numberOfOptions);
    }

    this.incrementProductIndex();
  }

  processProductAttributes(product, decisionValue, numberOfOptions) {
    // decisionValue is the numeric score on this product.

    // Iterate through the attributes and add them to the attributes dictionary
    // Offense Run: power, finesse, speed, committee
    // Offense Pass: west coast, vertical, precision, spread
    // Defense: run stoppers, fast pursuit, great pass rush, great coverage
    // Players: great team, superstars, discplined

    let prdAttributes = product.attributes;

    if (prdAttributes) {
      var keys = Object.keys(prdAttributes);

      for (var i = 0; i < keys.length; i++) {

        var attr = this.accumulatedAttributesDict[keys[i]];
        let attribute = {
          "value": prdAttributes[keys[i]],
          "score": decisionValue,
          "numberOfOptions": numberOfOptions
        }

        if (attr) {
          attr.values.push(attribute);
        }
        else {
          var valueObj = {"values": [attribute]};
          this.accumulatedAttributesDict[keys[i]] = valueObj;
        }
      }
    }
  }

  generateSwydgetResultsButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToSurveyComplete}>Check for Reward</Button>
        </ButtonToolbar>
      </div>
    );
  }

  generateSwydgetRecommendationsButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToSurveyComplete}>Check for Reward</Button>
        </ButtonToolbar>
      </div>
    );
  }

  generateSwydgetCompleteButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToAppStore}>Get the App</Button>
        </ButtonToolbar>
      </div>
    );
  }

  handleScalarSliderUnclicked(e) {
    var w = this.wyshes[this.state.index];

    if (w.isBlock() && w.forcedChoice) {
      if (this.forcedChoiceSelectionSet.size === w.orderedWyshList.getWyshes().length) {
        this.setState({
          isScalarNextDisabled: false,
        });
      }
      else {
        this.setState({
          isScalarNextDisabled: true,
          isScalarOptionHidden: true
        });
      }
    }
    else {
      this.setState({
        isScalarNextDisabled: false,
      });
    }
  }

  handleScalarSliderClicked(e) {
    this.setState({
      isScalarOptionHidden: false,
      // isScalarNextDisabled: true,
    });
  }

  handleSliderChange(event, value) {

    var w = this.wyshes[this.state.index];

    if (w.isBlock() && w.forcedChoice) {
      this.forcedChoiceSelectionSet.add(value);
      console.log(this.forcedChoiceSelectionSet);
    }

    this.setState({
      currentSliderValue: Math.round(value),
      // isScalarNextDisabled: false,
      showAlertOverlay: false,
      isScalarOptionHidden: false
    });
  }

  handleSliderTouch(value) {
    this.setState({
      // isScalarNextDisabled: false,
      showAlertOverlay: false
    });
  }

  handleFreeResponseAnswerChange(event) {
    this.setState({ freeResponseAnswer: event.target.value });
  }

  generateSwydgetSurveyButtonToolbar() {

    var questionType = this.wyshes[this.state.index].questionType;

    if (this.wyshes[this.state.index].orderedWyshList.wyshes.length > 0) {
      questionType = "block"
    }

    var toolbar = <div></div>;

    if (this.state.showFreeResponseModal) {
      toolbar = <FreeResponseToolbar
                  width={this.state.cardWidth + 100}
                  wysh={this.wyshes[this.state.index]}
                  submitFunction={this.submitFreeResponseOnlyDecision}
                />
    }
    else {
      if (this.wyshes[this.state.index]) {
        if (this.wyshes[this.state.index].isBlockType(BlockTypes.FORCEDCHOICE)) {
          return  <ForcedChoiceToolbar
                    forcedChoiceBlock={this.wyshes[this.state.index]}
                    submitFunction={this.submitForcedChoiceDecision}
                    sliderChangeCallback={this.forcedChoiceSliderChangeCallback}
                  />
        }
        else if (this.wyshes[this.state.index].isBlockType(BlockTypes.MAXDIFF)) {
          return  <MaxDiffToolbar
                    maxDiffBlock={this.wyshes[this.state.index]}
                    maxDiffCardSelectedIndex={this.state.maxDiffCardSelectedIndex}
                    maxDiffCardPreferencesUpdatedCallback={this.maxDiffCardPreferencesUpdatedCallback}
                    submitFunction={this.submitMaxDiffDecisionsMap}
                    maxDiffTrialSetChangeCallback={this.maxDiffTrialSetChangeCallback}
                  />
        }
        else if (this.wyshes[this.state.index].isBlockType(BlockTypes.PAIRWISE)) {
          return  <PairwiseToolbar
                    pairwiseBlock={this.wyshes[this.state.index]}
                    cardSelectedIndex={this.state.pairwiseCardSelectedIndex}
                    cardPreferencesUpdatedCallback={this.pairwiseCardPreferencesUpdatedCallback}
                    submitFunction={this.submitPairwiseDecisionMap}
                    trialSetChangeCallback={this.pairwiseTrialSetChangeCallback}
                  />
        }
      }

      switch (questionType) {
        case "block": {
          toolbar = <BlockToolbar
                      wysh={this.wyshes[this.state.index]}
                      submitFunction={this.setDecisionValuesAndHarvestFreeResponse}
                    />
          break;
        }
        case "scalar": {
          toolbar = <ScalarToolbar
                      wysh={this.wyshes[this.state.index]}
                      submitFunction={this.setDecisionValuesAndHarvestFreeResponse}
                    />
          break;
        }
        case "multiple-choice": {
          toolbar = <MultipleChoiceToolbar
                      wysh={this.wyshes[this.state.index]}
                      width={this.state.cardWidth}
                      singleChoice={false}
                      instruction={this.eventInFocus.mcMultipleInstruction}
                      choices={this.wyshes[this.state.index].wyshOptions}
                      submitFunction={this.submitMultipleChoiceDecision}
                    />
          break;
        }
        case "single-select": {
          toolbar = <MultipleChoiceToolbar
                      wysh={this.wyshes[this.state.index]}
                      width={this.state.cardWidth}
                      singleChoice={true}
                      instruction={this.eventInFocus.mcSingleInstruction}
                      choices={this.wyshes[this.state.index].wyshOptions}
                      submitFunction={this.submitMultipleChoiceDecision}
                    />
          break;
        }
        case "next": {
          toolbar = <NextToolbar
                      wysh={this.wyshes[this.state.index]}
                      submitFunction={this.setDecisionValuesAndHarvestFreeResponse}
                    />  
          break;
        }
        default: {
          toolbar = <BinaryToolbar
                      wysh={this.wyshes[this.state.index]}
                      submitFunction={this.setDecisionValuesAndHarvestFreeResponse}
                    />
        }
      }
    }

    return toolbar;
  }

  preventScroll = (e) => {
    e.preventDefault();
  }

  generateSwydgetWelcome() {

    document.ontouchmove = function(e){ e.preventDefault(); }
    document.addEventListener(
      'touchmove',
      this.preventScroll,
      { passive: false }
    );
    
    var actionMethod = this.navigateToSurvey;

    return (
      <div>
        <SwydgetWelcome sessionTimestamp={this.props.sessionTimestamp} eventInFocus={this.eventInFocus} action={actionMethod}/>
      </div>
    );
  }

  generateSwydgetRetired() {

    document.ontouchmove = function(e){ e.preventDefault(); }
    // document.ontouchmove = function(){ return true; }

    return (
      <div>
        <SwydgetRetired eventInFocus={this.eventInFocus}/>
      </div>
    );
  }

  generateSwydgetInstructions() {
    // <div onClick={this.navigateToSurvey}>
    return (
      <div>
        <SwydgetInstructions eventInFocus={this.eventInFocus} actionMethod={this.navigateToSurvey} />
      </div>
    );
  }

  generateSwydgetSurvey() {

    // TODO: Make sure this is necessary
    document.ontouchmove = function(){ return true; }


    document.body.style.overflow='hidden';

    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (this.eventInFocus && this.eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = this.eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    var titleDivStyle = this.state.showPrompt ? "titleDivStyle" : "titleDivStyle fadeOut";

    let containerDivStyle = {
      height: '100vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      overflow: 'hidden'
    };

    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative'
    }

    let headerDivStyle = {
      height: '20vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 10,
      zIndex: 10
    };

    let footerDivStyle = {
      height: '30vh',
      width: '100vh',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 19,
      overflow: 'visible'
    };

    let primaryContentContainerStyle = {
      height: '50vh',
      width: '100vw',
      position: 'relative',
      margin: 0,
      padding: 0,
      zIndex: 10
    };




    var wyshInFocus = this.wyshes[this.state.index > this.wyshes.length - 1 ? 0 : this.state.index];

    var prompt = wyshInFocus.isBlock() === false ? wyshInFocus.getWyshPrompt() : wyshInFocus.getWyshPrompt();

    if (wyshInFocus.isBlock() && wyshInFocus.forcedChoice) {
      prompt = wyshInFocus.caption;
    }

    var currentProgress = 100 * this.state.rootWyshesIndex / this.state.rootWyshesCount;

    let iconHeight = this.state.cardWidth * 0.4;
    let iconWidth = this.state.cardWidth * 0.4;

    let moreInfoButtonStyle = {
      height: iconHeight,
      width: iconWidth
    }

    let moreInfoTextStyle = {
      color: '#ffffff',
      fontSize: 18,
      fontWeight:600,
      marginTop: 20
    }

    let moreInfoContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '30vh'
    }

    return (
      <div style={containerDivStyle}>
        <div style={containerStyle}>
          <div style={headerDivStyle}>
            <div style={{
              width: this.state.cardWidth + 40,
              padding: 20
            }}>
              <LinearProgress variant="determinate" value={currentProgress} />
            </div>
            <div style={{fontSize: ProductStyles.determineFontSizeBasedOnLength(prompt)}} className={titleDivStyle}>
              {prompt}
            </div>
          </div>
          <div id="cardContainer" ref="cardContainer" style={primaryContentContainerStyle}>
            {this.cards}
          </div>
          <div style={footerDivStyle}>
            {this.generateSwydgetSurveyButtonToolbar()}
          </div>
        </div>
      </div>
    );
  }

  generateSurveyResults() {

    document.ontouchmove = function(){ return true; }

    var actionMethod = this.navigateToSurveyComplete;

    if (this.eventInFocus.showRecommendations === true) {
      actionMethod = this.navigateToSurveyRecommendations;
    }
    else if (this.eventInFocus.hasContestSetup() === true) {
      actionMethod = this.navigateToContestEntryForm
    }

    // TODO: Fix this KLUDGE
    // The eventInFocus is passed in to the Swydget from the SwydgetContainer
    // Decisions are then accumulated and submitted to the backend
    // After each submission, the SwydgetContainer's eventInFocus is updated, but
    // this.eventInFocus is not updated. Thus, the WyshScore values for each
    // Wysh accumulate as expected. The props.eventInFocus (which is updated after each
    // submission) is passed in to the results. As a result, the accumulated WyshScore's
    // for each Wysh is LOST. We need to copy them over to the props.eventInFocus to capture
    // the user's fresh results.
    for (var i = 0; i < this.eventInFocus.getWyshes().length; i++) {
      console.log("does this happen");
      this.props.eventInFocus.getWyshes()[i].wyshScore = this.eventInFocus.getWyshes()[i].wyshScore;
    }

    return (
      <SwydgetResults eventInFocus={this.props.eventInFocus} primaryAction={actionMethod} />
    );
  }

  generateSurveyRecomendations() {

    document.ontouchmove = function(){ return true; }

    var actionMethod = this.navigateToSurveyComplete;

    if (this.eventInFocus.contestPrizeText !== "") {
      actionMethod = this.navigateToContestEntryForm
    }

    return (
      <SwydgetRecommendations eventInFocus={this.eventInFocus} accumulatedAttributesDict={this.accumulatedAttributesDict} productLibrary={meStore.getSurveyCreatorProductLibrary()} productImageUrl={this.eventInFocus.getCoverPhotoUrl()} primaryAction={actionMethod} />
    );
  }

  generateSurveyComplete() {

    document.ontouchmove = function(e){ e.preventDefault(); }

    console.log(this.props);
    

    return (
      <SwydgetComplete eventInFocus={this.eventInFocus} location={this.props.location}/>
    );
  }

  generateSwydgetContestEntry() {

    document.ontouchmove = function(e){ e.preventDefault(); }

    var uk;

    if (this.userKey) {
      uk = this.userKey;
    }

    return (
      <SwydgetContestEntry userKey={uk} eventInFocus={this.eventInFocus} />
    );
  }

  findWyshIndex(wysh) {
    for (var i = 0; i < this.wyshes.length; i++) {
      if (wysh.wyshId === this.wyshes[i].wyshId) {
        return i;
      }
    }

    return -1;
  }

  loadBlockWyshes = (nextWysh) => {

    var cardPositionsDict = this.state.cardPositions;
    if (nextWysh) {
      var blockWyshes = nextWysh.orderedWyshList.getWyshesTwo(nextWysh.randomize, nextWysh.issueSubGroups, nextWysh.subGroupCount, nextWysh.limitFreeResponse, nextWysh.limitFreeResponseCount);
      if (blockWyshes && blockWyshes.length > 0) {
        var insertIndex = this.findWyshIndex(nextWysh) + 1;
        for (var i = 0; i < blockWyshes.length; i++) {
          this.wyshes.splice(insertIndex + i, 0, blockWyshes[i]);
          cardPositionsDict["card-" + blockWyshes[i].wyshId] = {x: 0, y: 0, shadow: false, blur: true, cw: this.state.containerWidth};
        }
      }
    }

    return cardPositionsDict;
  }

  incrementProductIndex() {

    // Enter Branch Logic:
    // We started with an ordered list of Wyshes.
    // We might have randomized them
    // Each Wysh has A WyshRouter that tells it where to go
    // Use that WyshRouter to determine the index of the next Wysh we should go to.
    

    var w = this.wyshes[this.state.index];
    var rootWyshesIndex = this.state.rootWyshesIndex;
    var rootWyshesCount = this.state.rootWyshesCount;

    this.setViewedTimestamp(new Date().getTime());

    var cardPositionsDict = this.state.cardPositions;

    var nextWysh = w.wyshRouter.getNextWyshBasedOnDecisions(this.wyshes, this.decisionsGathered);

    const bt = w.getBlockType();
    
    if (nextWysh) { //&& childWyshes.length === 0) {

      if (w.isBlockType(BlockTypes.FORCEDCHOICE) || w.isBlockType(BlockTypes.MAXDIFF) || w.isBlockType(BlockTypes.PAIRWISE)) {
        // We just finished a Forced Choice block so clear the card out.
        cardPositionsDict["card-" + w.wyshId].x = -2000;
      }

      // Is the nextWysh a block and more importantly a STANDARD or SEQUENTIALMONADIC block.
      // If so load the block wyshes into the deck
      if (nextWysh.isBlockType(BlockTypes.STANDARD) || nextWysh.isBlockType(BlockTypes.SEQUENTIALMONADIC)) {
        cardPositionsDict = this.loadBlockWyshes(nextWysh);
      }

      // how many root wyshes are there? Progress Meter will now only consider completion of root wyshes
      rootWyshesCount = 0;
      for (var i = 0; i < this.wyshes.length; i++) {
        if (this.wyshes[i].parentWysh === null) {
          rootWyshesCount += 1; // no parentWysh means this wysh is on the root.
        }
      }

      if (!nextWysh.parentWysh) {
        let nextWyshIndex = nextWysh.getWyshIndex(nextWysh, this.wyshes);

        rootWyshesIndex = 0;

        for (var j = 0; j < this.wyshes.length && j < nextWyshIndex; j++) {
          if (!this.wyshes[j].parentWysh) {
            rootWyshesIndex += 1;
          }
        }
      }

      // increment the index and set the proper shadow
      var currentIndex = this.state.index + 1;

    
      if (nextWysh.wyshId === "surveyComplete") {
        this.setSurveyComplete();
      }
      else if (nextWysh.wyshId === "blockComplete") {
        this.setSurveyComplete();
      }
      else {
        for (var i = 0; i < this.wyshes.length; i++) {
          if (nextWysh.wyshId === this.wyshes[i].wyshId) {
            currentIndex = i;
            break;
          }
        }

        // if (nextWysh.isBlock() && nextWysh.forcedChoice) {
        if (nextWysh.isBlockType(BlockTypes.FORCEDCHOICE)) {
          cardPositionsDict["card-" + nextWysh.wyshId].shadow = false;
          cardPositionsDict["card-" + nextWysh.wyshId].x = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].y = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].cw = this.state.containerWidth;
          cardPositionsDict["card-" + nextWysh.wyshId].blur = false;
        }
        else if (nextWysh.isBlockType(BlockTypes.MAXDIFF)) { 
          cardPositionsDict["card-" + nextWysh.wyshId].shadow = false;
          cardPositionsDict["card-" + nextWysh.wyshId].x = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].y = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].cw = this.state.containerWidth;
          cardPositionsDict["card-" + nextWysh.wyshId].blur = false;
        }
        else if (nextWysh.isBlockType(BlockTypes.PAIRWISE)) { 
          cardPositionsDict["card-" + nextWysh.wyshId].shadow = false;
          cardPositionsDict["card-" + nextWysh.wyshId].x = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].y = 0;
          cardPositionsDict["card-" + nextWysh.wyshId].cw = this.state.containerWidth;
          cardPositionsDict["card-" + nextWysh.wyshId].blur = false;
        }
        // else if (nextWysh.isBlock() && nextWysh.displayBlockCard === false) {
        else if (nextWysh.isBlockType(BlockTypes.STANDARD) || nextWysh.isBlockType(BlockTypes.SEQUENTIALMONADIC) ) {  

          this.addFakeDecisionToGetPastUndesiredBlockCardBrief(this.wyshes[currentIndex]);
          currentIndex += 1;


          // load this blocks wyshes
          var nextNonBlockWysh = this.wyshes[currentIndex];
          // current index is now looking at the first child in the block we are in
          if (nextNonBlockWysh) {
            for (var i = 1; nextNonBlockWysh.isBlock() === true && nextNonBlockWysh.getBlockType().equals(BlockTypes.FORCEDCHOICE) === false; i++) {
              cardPositionsDict = this.loadBlockWyshes(nextNonBlockWysh);
              currentIndex += 1;
              nextNonBlockWysh = this.wyshes[currentIndex];
              if (nextNonBlockWysh.isBlock() === true) {
                this.addFakeDecisionToGetPastUndesiredBlockCardBrief(this.wyshes[currentIndex]);
              }
            }
          }
          else {
            // You encountered a block with nothing in it. This should not be allowed as it is nonsensical.
            // In draft mode, it is fine, but when you preview it, we should probably warn the user.
            this.setSurveyComplete();
          }
        }
      }
    }
    else {
      this.setSurveyComplete();
    }

    if ((currentIndex - this.state.index) > 1) {
      // pitch skipped cards
      for (i = this.state.index + 1; i < currentIndex; i++) {

        let w = this.wyshes[i];
        this.skipCard("card-" + w.wyshId);

        let isBlock = w.isBlock();
      }
    }

    if (this.wyshes[currentIndex] && cardPositionsDict["card-" + this.wyshes[currentIndex].wyshId] !== undefined) {
      cardPositionsDict["card-" + this.wyshes[currentIndex].wyshId].blur = false;
    }

    this.decisionsDataSet = new Set();
    this.setState({
      disableControls: this.wyshes[currentIndex] && this.wyshes[currentIndex].product ? this.wyshes[currentIndex].product.hasImage() : false,
      currentSliderValue: this.getSliderDefaultValue(currentIndex),
      index: currentIndex,
      rootWyshesIndex: rootWyshesIndex,
      rootWyshesCount: rootWyshesCount,
      cardPositions: cardPositionsDict,
      showProductLinkModal: this.wyshes[currentIndex] && this.wyshes[currentIndex].product.getSecureBuyPage().length > 0,
      currentProductLink: this.wyshes[currentIndex] && this.wyshes[currentIndex].product.getSecureBuyPage() ? this.wyshes[currentIndex].product.getSecureBuyPage() : "",
      showPrompt: true,
      showFreeResponseModal: this.wyshes[currentIndex] ? this.wyshes[currentIndex].isFreeResponseOnly() : false
    });
  }

  addFakeDecisionToGetPastUndesiredBlockCardBrief(wysh){

    if (!wysh) {
      return;
    }

    // We need to gather the decision to open the block so skip logic works properly
    var key;

    if (this.email) {
      key = {
        t: 'email',
        // v: 'test117@catboytech.com'
        v: this.email
      }
    }
    else if (this.userKey) {
      key = this.userKey;
    }

    let decisionToSubmit = new DecisionToBeSubmitted();
    if (this.surveyTakerMid) {
      decisionToSubmit.surveyTakerMid = this.surveyTakerMid;
    }
    decisionToSubmit.key = key;
    decisionToSubmit.wysh = wysh;
    decisionToSubmit.firstName = this.firstName;
    decisionToSubmit.lastName = this.lastName;
    decisionToSubmit.productId = wysh.product.productId;
    decisionToSubmit.event = this.eventInFocus;
    decisionToSubmit.decisionRaw = "skipped";
    decisionToSubmit.decisionValue = 0;
    decisionToSubmit.completionDivContainerStyle = this.props.coords;
    decisionToSubmit.freeResponseAnswer = this.state.freeResponseAnswer;
    this.decisionsGathered.push(decisionToSubmit);
  }

  generateMediaModalContent(cardHeightStyle) {
    if (this.state.isCurrentProductLinkImage === true) {

      let containerDivStyle = {
        height: '65vh',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      };

      let imageStyle = {
        maxHeight: '100%',
        maxwidth: '100%'
      };

      return (
        <div style={containerDivStyle}><Image style={imageStyle} src={this.state.currentProductLink} responsive="true"/></div>
      );
    } else {

      let iframeContainerStyle = {
        padding: 0,
        margin: 0,
        width: '100%',
        height: this.state.mediaModalHeight - 32,
        overflow: 'hidden'
      }

      let responsiveIframeStyle = {
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }

      return (
        <div style={iframeContainerStyle}>
          <iframe
            style={responsiveIframeStyle}
            src={this.state.currentProductLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>


      );
    }
  }
  
  productLibraryLoaded() {

  }

  render() {

    document.ontouchmove = function(e){ e.preventDefault(); }

    // this.classes = this.props.classes;
    const cookies = new Cookies();
    // this.userKey = cookies.get("sb");

    var cardHeight = 40;
    var cardHeightStyle = cardHeight + 'vh';

    var port = '';
    if (window.location.port !== '') {
      port = ":" + window.location.port;
    }

    var swydgetUrl = "http://" + window.location.hostname + port + "/solo/swydget/" + this.eventInFocus.eventId;

    let tableStyle = {
      height: '100vh',
      position: 'relative',
      backgroundColor: '#1997fc'
    }

    let textAreaStyle = {
      height: 80,
      color: '#000000',
      backgroundColor: '#ffffff',
      border: 2,
      borderRadius: 6,
      borderStyle: 'solid',
      borderColor: '#dddee0',
      fontWeight: 300,
      fontSize: 18
    };

    this.cards = this.getCards();

    var body = <div></div>;
    if (this.state.currentView === "surveyRetired") {
      body = this.generateSwydgetRetired();
    }
    else if (this.state.currentView === "getStarted") {
      body = this.generateSwydgetWelcome();
    }
    else if (this.state.currentView === "instructions") {
      body = this.generateSwydgetInstructions();
    }
    else if (this.state.currentView === "surveyComplete") {
      body = this.generateSurveyComplete();
    }
    else if (this.state.currentView === "enterContest") {
      body = this.generateSwydgetContestEntry();
    }
    else if (this.state.currentView === "surveyResults") {
      body = this.generateSurveyResults();
    }
    else if (this.state.currentView === "surveyRecommendations") {
      body = this.generateSurveyRecomendations();
    }
    else {
      body = this.generateSwydgetSurvey();
    }

    var description = "Real Answers. Real Results. Real Fast.";
    if (this.eventInFocus.rewardText !== "") {
      description = this.eventInFocus.rewardText;
    }

    return (
      <div id="swydget_container" style={tableStyle}>
        <Helmet>
          <title>Swytchback - {this.eventInFocus.title}</title>
          <meta property="og:url"                content={swydgetUrl} />
          <meta property="og:type"               content="article" />
          <meta property="og:title"              content={this.eventInFocus.title}/>
          <meta property="og:description"        content={description} />
          <meta property="og:image"              content={this.eventInFocus.getCoverPhotoUrl()} />
        </Helmet>
        {body}

        <MediaModal
          show={this.state.showProductLinkModal}
          onHide={this.closeProductLinkModal}
          mediaModalWidth={this.state.mediaModalWidth}
          mediaModalHeight={this.state.mediaModalHeight}
          cardHeight={40}
          currentProductLink={this.state.currentProductLink}
          isCurrentProductLinkImage={this.state.isCurrentProductLinkImage}
        />
        <Modal className="swytchbackClearModal" show={false} onHide={this.closeFreeResponseModal} backdrop="static">
          <Modal.Header style={{ backgroundColor: '#3F3F3F', border: '1px solid #3F3F3F' }} closeButton={false}>
            <Modal.Title style={{ color: '#FFFFFF', backgroundColor: '#3F3F3F', fontSize: 16}}>{this.state.freeResponseQuestion}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: '#FFFFFF', backgroundColor: '#3F3F3F', border: 0 }}>
            <div>
              <FormGroup controlId="formControlsTextarea">
                <div style={{display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',}}>
                  <FormControl
                    readOnly={false}
                    value={this.state.freeResponseAnswer}
                    onChange={this.handleFreeResponseAnswerChange}
                    style={textAreaStyle}
                    componentClass="textarea"
                    placeholder="..."
                  />
                  <Button className="swytchbackActiveButton" onClick={this.submitProductDecision} style={{ marginTop: 20, borderRadius: 6, backgroundColor: '#000000', color: '#ffffff', borderColor: '#5E5E5E' }}>NEXT</Button>
                </div>
              </FormGroup>
            </div>
          </Modal.Body>
        </Modal>
        { this.state.disableControls &&
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000000
          }}>
          </div>
        } 
      </div>
    );
  }
}

// Swydget.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default Swydget;
