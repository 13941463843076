import React, { useState, useEffect } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import meStore from "../../stores/MeStore";
import Wysh from "../../classes/Wysh";
import MeService from "../../services/MeService";
import swytchbackStimuliLibrary from "../../assets/import_library/stimuli/stimuli_library.json";
import swytchbackStyles from "../../css/swytchbackStyles.css";
import swytchbackModal from "../../css/swytchbackModal.css";
import { CloudUpload, RadioButtonUnchecked } from "@mui/icons-material";

const compareByNameAsc = (a, b) => {
  return a.name.localeCompare(b.name);
}

export default function StimuliLibraryModal(props) {

  const [selectedTabKey, setSelectedTabKey] = useState("blocks");
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [fileName, setFileName] = useState("");
  const [importingState, setImportingState] = useState("ready");
  const [stimuliImportJson, setStimuliImportJson] = useState(null);
  const stimuliArray = [];
  const blocksArray = [];

  

  const selectStimuli = (stimuliJson, idx) => {
    setFileName(stimuliJson.file_name);
    if (idx === selectedIdx) {
      setSelectedIdx(-1)
      setFileName("");
      setStimuliImportJson(null);
    }
    else {
      setSelectedIdx(idx)
    }
  }
  
  const importStimuli = () => {
    setImportingState("importing");
    var newWysh = Wysh.importWyshFromJson(props.swydget, stimuliImportJson);
    newWysh.parentWysh = props.parentWysh;
    MeService.importWysh(props.user, props.swydget, newWysh);
  }

  useEffect(() => {

    meStore.on("meStore-importStimuliSuccessful", uploadComplete);

    return () => {
      meStore.removeListener("meStore-importStimuliSuccessful", uploadComplete);
    }
  }, []);

  useEffect(() => {

    import('../../assets/import_library/stimuli/' + fileName)
      .then(data => {
        setStimuliImportJson(data);
      })
      .catch(error => {

      });

  }, [fileName]);

  const uploadComplete = () => {
    setImportingState("done");
    setSelectedIdx(-1);
    setFileName("");
    setStimuliImportJson(null);
    close();
  }

  const close = () => {
    setImportingState("ready");
    props.handleClose();
  }

  const getGlyphicon = (idx) => {
    return idx === selectedIdx ? <CloudUpload /> : <RadioButtonUnchecked />;
  }

  for (let s of swytchbackStimuliLibrary) {
    if (s.block) {
      blocksArray.push(s);
    }
    else {
      stimuliArray.push(s);
    }
  }

  blocksArray.sort(compareByNameAsc);
  stimuliArray.sort(compareByNameAsc);

  const handleTabSelect = (key) => {
    setSelectedTabKey(key);
    setSelectedIdx(-1);
    setFileName("");
    setStimuliImportJson(null);
  }

  const isImportDisabled = () => {
    let isDisabled = true; 
    if (importingState === "importing" || importingState === "done") {
      isDisabled = true;
    } 
    else if (stimuliImportJson) {
      isDisabled = false;
    }

    return isDisabled;
  }
 
  return (
    <Modal
      onHide={close}
      size="xl"
      show={props.show}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">
        <div className="feedbackContainer"></div>
          <div>Block/Stimuli Library</div>
          <div className="feedbackContainer">
          {importingState === "importing" &&
            <CircularProgress size={20}/>
          }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs 
            className="swytchbackModalTabs"
            id="stimuli-library-tabs"
            onSelect={handleTabSelect}
            defaultActiveKey="blocks">
            <Tab eventKey="blocks" title="Blocks">
              <div className="fileTable">
                {blocksArray.map((stimuliJson, idx) => (
                  <div className="fileEntry" key={stimuliJson.file_name}>
                    <div className="title">{stimuliJson.name}</div>
                    <div className="button">
                      <Button onClick={() => selectStimuli(stimuliJson, idx)}>{getGlyphicon(idx)}</Button>
                    </div> 
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="stimuli" title="Stimuli">
              <div className="fileTable">
                {stimuliArray.map((stimuliJson, idx) => (
                  <div className="fileEntry" key={stimuliJson.file_name}>
                    <div className="title">{stimuliJson.name}</div>
                    <div className="button">
                      <Button onClick={() => selectStimuli(stimuliJson, idx)}>{getGlyphicon(idx)}</Button>
                    </div> 
                  </div>
                ))}
              </div>
            </Tab>   
          </Tabs>
        </div>  
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className={stimuliImportJson ? "swytchbackActiveButton" : "swytchbackInactiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={isImportDisabled()} 
          onClick={importStimuli}>
          Import
        </Button>
        <Button 
          className={importingState === "importing"  ? "swytchbackInactiveButton" : "swytchbackActiveButton"}  
          style={{margin: 5, width: 130}} 
          disabled={importingState === "importing"} 
          onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
