import React, { useState } from "react";
import { Button }  from 'react-bootstrap';
import { Select, MenuItem, TextField } from "@mui/material";
import { Picker } from 'emoji-mart';
import classes from '../../../css/modules/StimulusTypeComponent.module.css';
import '../../../css/swytchbackModal.css';
import WyshOption from "../../../classes/WyshOption";
import BinaryIconSet from "../../../classes/BinaryIconSet";
import SwytchbackSelect from "../controls/SwytchbackSelect";
import SwytchbackTextField from "../controls/SwytchbackTextField";


export default function StimulusTypeComponent(props) {

  const updateStimulusCallback = props.updateStimulusCallback;
  
  const [localStimulus, setLocalStimulus] = useState(props.stimulus);
  const [optionInFocus, setOptionInFocus] = useState(-1);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiWrapperRef, setEmojiWrapperRef] = useState(null);

  const handlePromptChange = (evt) => {
    const stimulus = localStimulus;
    stimulus.caption = evt.target.value;
    setLocalStimulus(stimulus);
    updateStimulusCallback(stimulus);
  }

  const handleQuestionTypeChange = (evt) => {
    const stimulus = localStimulus;
    stimulus.questionType = evt.target.value;
    setLocalStimulus(stimulus);
    updateStimulusCallback(stimulus);
  }

  const generateSelectOptions = (count) => {
    var optionsArray = [];
    for (var i = 1; i < count; i++) {
      optionsArray.push(<MenuItem key={"option-" + i} value={(i + 1).toString()}>{i + 1}</MenuItem>);
    }
    return optionsArray;
  }

  const generateMultipleChoiceFields = () => {
    const stimulus = localStimulus;
    let currentOptions = stimulus.wyshOptions;

    return (
        currentOptions.map((option, idx) => (
          <div key={idx} className="rowContainerSpaced marginBottom">
            <SwytchbackTextField
              disabled={false}
              id="info-input"
              style={{width: '75%'}}
              value={option.resultLiteral}
              onChange={handleOptionNameChange(idx)}
              margin="normal"
              variant="outlined"
              placeholder="Name..."
              maxLength={192}
            />
            { option.resultNormalized !== 99.0 && option.resultNormalized !== 98.0 &&
              <Button
                className="swytchbackButton textFieldHeight" 
                style={{width: '24%'}}
                onClick={() => removeOption(idx)}>
                Remove
              </Button>
            }
          </div>
        ))
    )
  }

  const handleBinaryIconSetIdChange = (evt) => {
    const stimulus = localStimulus;

    if (stimulus) {
      stimulus.binaryIconSetID = evt.target.value;
    }

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleNumberOfOptionsChange = (evt) => {

    const stimulus = localStimulus;
    let currentOptions = stimulus.wyshOptions;

    let numberOfOptions = evt.target.value;
    
    var newOptionsArray = [];
    var noneOfTheAboveOption;
    var otherOption;

    // Find any existing "Other" or "None of the Above options"
    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentOptions[i];
      }
      if (currentOptions[i].resultNormalized === 98.0) {
        otherOption = currentOptions[i];
      }
    }

    for (var i = 0; i < numberOfOptions; i++) {

      var resultNormalized = currentOptions[i] && currentOptions[i].resultNormalized ? currentOptions[i].resultNormalized : i;
      let optionToAdd = new WyshOption();
      optionToAdd.resultNormalized = resultNormalized;
      optionToAdd.resultLiteral = "";

      if (resultNormalized !== 99.0 && resultNormalized !== 98.0) {
        if (i < currentOptions.length) {
          optionToAdd = currentOptions[i];
        }
      }
      else {
        optionToAdd.resultLiteral = "";
        optionToAdd.resultNormalized = i;
      }

      newOptionsArray.push(optionToAdd);
    }

    if (otherOption) {
      newOptionsArray.push(otherOption);
    }

    if (noneOfTheAboveOption) {
      newOptionsArray.push(noneOfTheAboveOption);
    }

    newOptionsArray.sort(WyshOption.compareByResultNormalizedAsc);

    if (stimulus) {
      stimulus.setWyshOptions(newOptionsArray);
    }

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleOptionNameChange = (idx) => (evt) => {
    editOption(idx, evt.target.value);
  }

  const editOption = (index, value) => {
    const stimulus = localStimulus;
    let currentOptions = stimulus.wyshOptions;
    currentOptions[index].resultLiteral = value;

    if (stimulus) {
      stimulus.setWyshOptions(currentOptions);
    }
    
    setLocalStimulus(stimulus);
    updateStimulusCallback(stimulus);
  }

  const handleMcOptionsLimitChange = (evt) => {
    const stimulus = localStimulus;
    stimulus.mcOptionsLimit = parseInt(evt.target.value);
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleRandomizeWyshOptions = (evt) => {
    const stimulus = localStimulus;
    stimulus.randomizeWyshOptions = !stimulus.randomizeWyshOptions;
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleMcIncludeNoneOfTheAbove = (evt) => {
    const stimulus = localStimulus;
    stimulus.mcIncludeNoneOfTheAbove = !stimulus.mcIncludeNoneOfTheAbove;
    setupSpecialOptions(stimulus, stimulus.mcIncludeOther, stimulus.mcIncludeNoneOfTheAbove)
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleMcIncludeOther = (evt) => {
    const stimulus = localStimulus;
    stimulus.mcIncludeOther = !stimulus.mcIncludeOther;
    setupSpecialOptions(stimulus, stimulus.mcIncludeOther, stimulus.mcIncludeNoneOfTheAbove)
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const handleMustViewAll = (evt) => {
    const stimulus = localStimulus;
    stimulus.mustViewAll = !stimulus.mustViewAll;
    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const getOptionsCount = () => {
    let currentOptions = localStimulus.wyshOptions;
    var count = currentOptions.length;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].resultNormalized && (currentOptions[i].resultNormalized === 99.0 || currentOptions[i].resultNormalized === 98.0)) {
        count = count - 1;
      }
    }

    return count;
  }

  const generateMenuItems = () => {

    const items = []

    const binaryOptions = BinaryIconSet.getOrderedList();
    binaryOptions.map(
      (iconSet) => {
       items.push(<MenuItem key={iconSet.binaryIconSetID} value={iconSet.binaryIconSetID}>{iconSet.name}</MenuItem>) 
      }
    )

    return items;
  }

  const generateStimulusOptionsForm = () => {
    if (localStimulus.questionType === "multiple-choice" ||
        localStimulus.questionType === "single-select") {
      if (localStimulus.wyshOptions.length === 0) {
        return (
          <div className="rowContainerSpaced marginBottom">
            <div className='title'>
              Response Options:
            </div>
            <div className='content'>
              <SwytchbackSelect
                disabled={false}
                inputProps={{ IconComponent: () => null }}
                id="font-family-select"
                value={getOptionsCount()}
                onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                {generateSelectOptions(100)}
              </SwytchbackSelect>
            </div>
          </div>    
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom">
              <div className='title'>
                Response Options:
              </div>
              <div className='content'>
                <SwytchbackSelect
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(100)}
                </SwytchbackSelect>
              </div>
            </div>    
            { localStimulus.questionType === "multiple-choice" &&
            <div className="rowContainerSpaced marginBottom">
              <div className='title'>
                Set Limit:
              </div>
              <div className='content'>
                <SwytchbackSelect
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={localStimulus.mcOptionsLimit}
                  onChange={(evt) => handleMcOptionsLimitChange(evt)}>
                  {generateSelectOptions(getOptionsCount())}
                </SwytchbackSelect>
              </div>
            </div>    
            }
            <div className="rowContainerSpaced marginBottom">
              <Button
                className={localStimulus.randomizeWyshOptions ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localStimulus.randomizeWyshOptions} 
                onClick={() => handleRandomizeWyshOptions()}>
                Randomize Options
              </Button>
              <Button
                className={localStimulus.mcIncludeNoneOfTheAbove ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localStimulus.mcIncludeNoneOfTheAbove} 
                onClick={() => handleMcIncludeNoneOfTheAbove()}>
                None of the Above
              </Button>
              <Button
                className={localStimulus.mcIncludeOther ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localStimulus.mcIncludeOther} 
                onClick={() => handleMcIncludeOther()}>
                Other
              </Button>
              <Button
                className={localStimulus.mustViewAll ? "swytchbackButton swytchbackButtonActive" : "swytchbackButton"} 
                style={{width: '24%'}}
                active={localStimulus.mustViewAll} 
                onClick={() => handleMustViewAll()}>
                Must View All Options
              </Button>
            </div>
            <div className="columnContainer">
              {generateMultipleChoiceFields()}
            </div>
            {
              showEmojiPicker &&
              <div ref={setEmojiPickerWrapperRef} className="emojiPickerStyle">
                <Picker onSelect={addEmoji} showPreview={false}/>
              </div>
            }
          </div>
        );
      }
    }
    else if (localStimulus.questionType === "scalar") {
      if (localStimulus.wyshOptions === 0) {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom">
              <div className='title'>
                Response Options:
              </div>
              <div className='content'>
                <SwytchbackSelect
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={getOptionsCount()}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(100)}
                </SwytchbackSelect>
              </div>
            </div>    
          </div>  
        )
      }
      else {
        return (
          <div className="swytchbackAdminControl">
            <div className="rowContainerSpaced marginBottom">
              <div className='title'>
                Response Options:
              </div>
              <div className='content'>
                <SwytchbackSelect
                  disabled={false}
                  inputProps={{ IconComponent: () => null }}
                  id="font-family-select"
                  value={localStimulus.wyshOptions.length}
                  onChange={(evt) => handleNumberOfOptionsChange(evt)}>
                  {generateSelectOptions(100)}
                </SwytchbackSelect>
              </div>
            </div>    
            <div style={{width: '100%'}} className="columnContainer">
              {generateMultipleChoiceFields()}
            </div>
          </div>

        );
      }
    }
    else if (localStimulus.questionType === "binary") {
      return (
        <div className="rowContainerSpaced marginBottom">
          <div className='title'>
            Select Buttons:
          </div>
          <div className='content'>
            <SwytchbackSelect
              disabled={false}
              inputProps={{ IconComponent: () => null }}
              id="font-family-select"
              value={localStimulus.binaryIconSetID}
              onChange={(evt) => handleBinaryIconSetIdChange(evt)}>
              {generateMenuItems()}
            </SwytchbackSelect>
          </div>
        </div>    
      );
    }
    else {
      return (
        <div className="swytchbackAdminControl">
          
        </div>
      )
    }
  }

  

  const displayEmojiPicker = (idx) => {
    setShowEmojiPicker(true);
    setOptionInFocus(idx);
  }

  const setEmojiPickerWrapperRef = (node) => {
    setEmojiWrapperRef(node);
  }

  const addEmoji = (e) => {

    setShowEmojiPicker(false);
    setOptionInFocus(-1);

    const stimulus = localStimulus;
    let currentOptions = stimulus.wyshOptions;
    editOption(optionInFocus, currentOptions[optionInFocus].resultLiteral + e.native);
  }

  const removeOption = (index) => {
    const stimulus = localStimulus;
    let currentOptions = stimulus.wyshOptions;
    currentOptions.splice(index, 1);

    if (stimulus) {
      stimulus.setWyshOptions(currentOptions);
    }

    setLocalStimulus(stimulus);
    props.updateStimulusCallback(stimulus);
  }

  const setupSpecialOptions = (stimulus, includeOther, includeNoneOfTheAbove) => {

    var currentWyshOptions = stimulus.wyshOptions;
    var otherOption = null;
    var noneOfTheAboveOption = null;

    // Get OTHER: 98.0 and remove it from the currentWyshOptions
    for (var i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 98.0) {
        otherOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Get NONE OF THE ABOVE: 99.0 and remove it from the currentWyshOptions
    for (i = 0; i < currentWyshOptions.length; i++) {
      if (currentWyshOptions[i].resultNormalized === 99.0) {
        noneOfTheAboveOption = currentWyshOptions[i];
        currentWyshOptions.splice(i, 1);
      }
    }

    // Add OTHER: 98.0 back in, if appropriate
    if (includeOther) {
      if (otherOption) {
        currentWyshOptions.push(otherOption)
      }
      else {
        otherOption = new WyshOption();
        otherOption.resultLiteral = "Other";
        otherOption.resultNormalized = 98.0;
        currentWyshOptions.push(otherOption);
      }
    }

    // Add NONE OF THE ABOVE: 99.0 back in, if appropriate
    if (includeNoneOfTheAbove) {
      if (noneOfTheAboveOption) {
        currentWyshOptions.push(noneOfTheAboveOption)
      }
      else {
        noneOfTheAboveOption = new WyshOption();
        noneOfTheAboveOption.resultLiteral = "None of the Above";
        noneOfTheAboveOption.resultNormalized = 99.0;
        currentWyshOptions.push(noneOfTheAboveOption);
      }
    }

    stimulus.wyshOptions = currentWyshOptions;
  }

  return (
    <div id="stimulusTypeConfig" className="hookFullPaneContainer standardModalHeight fullWidth">
      <div className="widget fullWidth">
        <div className='title marginBottom'>
          Question Type:
        </div>
        <div className='content'>
          <div className="swytchbackAdminControl">
            <div className="rowContainer">
              <SwytchbackSelect
                disabled={false}
                inputProps={{ IconComponent: () => null }}
                id="font-family-select"
                value={localStimulus.questionType} 
                onChange={(evt) => handleQuestionTypeChange(evt)}
              >
                <MenuItem key={"binary"} value={"binary"}>Binary (Yes/No)</MenuItem>
                <MenuItem key={"scalar"} value={"scalar"}>Scalar</MenuItem>
                <MenuItem key={"multiple-choice"} value={"multiple-choice"}>Multiple Choice</MenuItem>
                <MenuItem key={"single-select"} value={"single-select"}>Single Select</MenuItem>
                <MenuItem key={"next"} value={"next"}>Next</MenuItem>
                <MenuItem key={"free-response-only"} value={"free-response-only"}>Verbatim</MenuItem>
              </SwytchbackSelect>
              <div className="swytchbackInstruction">{localStimulus.getQuestionTypeDescription()}</div>
            </div>  
          </div>
        </div>
      </div>
      <div className="widget fullWidth">
        <div className='title marginBottom'>
          Question Prompt:
        </div>
        <div className='content'>
          <div className="swytchbackAdminControl">
            <SwytchbackTextField
              style={{width: '100%'}}
              disabled={false}
              id="info-input"
              value={localStimulus.caption}
              onChange={handlePromptChange}
              margin="normal"
              variant="outlined"
              placeholder="Type a prompt here..."
            />
          </div>
        </div>
      </div>    
      <div className="widget fullWidth">
        <div className='content'>
          <div className="swytchbackAdminControl">
            {generateStimulusOptionsForm()} 
          </div>
        </div>  
      </div>  
    </div>
  )
   

}