import React, { useState, useEffect } from 'react';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import "../../../productCreationModal.css";
import "../../../css/swytchbackStyles.css";
import "../../../css/swytchbackModal.css";
import meStore from "../../../stores/MeStore";
import loginStore from "../../../stores/LoginStore";
import FriendsTable from '../../../hooks/admin/user/FriendsTable';
import FriendsTableRow from '../../../hooks/admin/user/FriendsTableRow';
import NotificationsTableRow from '../../../components/admin/NotificationsTableRow';
import InviteSwydgetMember from './InviteSwydgetMember';
import AuthService from '../../../services/AuthService';
import MeService from '../../../services/MeService';
import { useNavigate } from 'react-router';
import { Delete } from '@mui/icons-material';

const EventMembersModal = ({ showModal, onHideAction }) => {
  const [eventInFocus, setEventInFocus] = useState(meStore.getEventInFocus());
  const [loggedInUser, setLoggedInUser] = useState(loginStore.getLoggedInUser());
  const navigate = useNavigate();

  useEffect(() => {
    const setLoggedInUserHandler = () => {
      setLoggedInUser(loginStore.getLoggedInUser());
    };

    const eventLoadedHandler = () => {
      setEventInFocus(meStore.getEventInFocus());
    };

    meStore.on("change-eventLoaded", eventLoadedHandler);
    loginStore.on("loginStore_user_logged_in", setLoggedInUserHandler);

    return () => {
      meStore.removeListener("change-eventLoaded", eventLoadedHandler);
      loginStore.removeListener("loginStore_user_logged_in", setLoggedInUserHandler);
    };
  }, []);

  const inviteFriend = (email, firstName, lastName) => {
    console.log("invite friend!");
    AuthService.inviteFriend(loggedInUser, email, firstName, lastName);
  };

  const inviteExistingFriend = (friend) => {
    console.log("inviteExistingFriend!");
    MeService.inviteEventMember(loggedInUser, friend, eventInFocus);
  };

  const removeFriend = (friendToRemove) => {
    MeService.removeSwydgetMember(loggedInUser, friendToRemove, eventInFocus);
  };

  const generateFriendRows = () => {
    var rows = [];
    var inviteesAdded = [];

    if (eventInFocus) {
      for (var i = 0; i < eventInFocus.invitees.length; i++) {
        var isDisabled = true;

        if (loggedInUser.userId === eventInFocus.creator.userId) {
          isDisabled = false;
        }

        if (eventInFocus.invitees[i].userId === loggedInUser.userId) {
          isDisabled = true;
        }

        var addInvitee = true;
        for (var j = 0; j < inviteesAdded.length; j++) {
          if (inviteesAdded[j].userId === eventInFocus.invitees[i].userId) {
            addInvitee = false;
            break;
          }
        }

        if (addInvitee) {
          inviteesAdded.push(eventInFocus.invitees[i]);
          rows.push(
            <FriendsTableRow
              key={i}
              user={eventInFocus.invitees[i]}
              isConnected={true}
              actionHandler={removeFriend}
              actionDisabled={isDisabled}
              icon={<Delete />}
              glyphiconStyle="default"
              confirmationTitle="Remove Swydget Collaborator"
              confimationMessage={"Do you want to remove " + eventInFocus.invitees[i].firstName + " " + eventInFocus.invitees[i].lastName + " as a collaborator?"}
              confirmationButtonText="Remove"
            />
          );
        }
      }
    }

    return rows;
  };

  const acknowledgeNotification = (notification, accept) => {
    AuthService.acknowledgeFriendRequest(loggedInUser, navigate, notification, accept);
  };

  const generateUnackNotificationsRows = () => {
    var rows = [];

    if (loggedInUser.myUnacknowldgedNotifications) {
      for (var i = 0; i < loggedInUser.myUnacknowldgedNotifications.length; i++) {
        rows.push(
          <NotificationsTableRow
            key={i}
            notification={loggedInUser.myUnacknowldgedNotifications[i]}
            notificationHandler={acknowledgeNotification}
          />
        );
      }
    }

    return rows;
  };

  const isSwydgetImmutable = () => {
    if (typeof eventInfFocus !== undefined &&
      eventInFocus !== null &&
      loggedInUser.userId === eventInFocus.creator.userId
    ) {
      return false;
    }
    else {
      return true;
    }
  };

  const cleanUp = (onHideAction) => {
    onHideAction();
  };

  return (
    <Modal size="xl" show={showModal} onHide={onHideAction} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Share Results</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="event-members-modal">
            <Tab eventKey={1} title="Share">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                    <InviteSwydgetMember 
                      author={eventInFocus && eventInFocus.creator ? eventInFocus.creator.firstName + " " + eventInFocus.creator.lastName : ""} 
                      isSwydgetImmutable={isSwydgetImmutable()} 
                      invitees={eventInFocus ? eventInFocus.invitees: []} 
                      inviteExistingFriendHandler={inviteExistingFriend}
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="Shared With">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='rowContainerSpaced fullWidthPane standardModalHeight'>
                    <FriendsTable rows={generateFriendRows()} enableInvitePeople={false} />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className="swytchbackActiveButton" style={{width: 100}} onClick={onHideAction}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventMembersModal;
