import SwytchbackService from "./SwytchbackService";

export default class SwytchbackServiceResearch extends SwytchbackService
{
  constructor(serviceId) {
    super(serviceId);
    this.id = serviceId;
    this.name = "Research";
  }

  static compareByNameDesc(a, b) {
    return b.name.localeCompare(a.name);
  }

  static compareByNameAsc(a, b) {
    return a.name.localeCompare(b.name);
  }
}
