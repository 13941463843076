import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './App.css';
import Footer from "./hooks/layout/Footer";
import Header from "./hooks/layout/Header";
import ComingSoon from './pages/ComingSoon';
import CreateForcedRankSurvey from './pages/CreateForcedRankSurvey';
import CreateScalarSurvey from './pages/CreateScalarSurvey';
import Me from './pages/Me';
import SelectSurveyType from './pages/SelectSurveyType';
import FinalizeSurvey from './pages/FinalizeSurvey';
import PublishSurvey from './pages/PublishSurvey';
import Survey from './pages/Survey';
import Results from './pages/Results';
import AccountInfo from './pages/AccountInfo';
import AdvancedUserTools from './pages/AdvancedUserTools';
import Notifications from './pages/Notifications';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import SwydgetNotAvailableContainer from './pages/SwydgetNotAvailableContainer';
import SwydgetContainer from './pages/SwydgetContainer';
import Widget from './components/Widget';

{/* <div className="App">
  <Header />
  <Main />
  <Footer />
</div> */}
function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Me />} />  
            <Route path="coming_soon" element={<ComingSoon />} />
            <Route path="create_survey/create_scalar_survey" element={<CreateScalarSurvey />} />
            <Route path="create_survey/create_forced_rank_survey" element={<CreateForcedRankSurvey />} />
            <Route path="create_survey/select_type" element={<SelectSurveyType />} />
            <Route path="create_survey/finalize/:eventId" element={<FinalizeSurvey />} />
            <Route path="create_survey/publish_survey/:eventId" element={<PublishSurvey />} />
            <Route path="reports" element={<ComingSoon />} />
            <Route path="survey/:eventId" element={<Survey />} />
            <Route path="results/:eventId" element={<Results />} />
            <Route path="account_info" element={<AccountInfo />} />
            <Route path="advanced_user_tools" element={<AdvancedUserTools />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="/guest/login" element={<Login />} />
            <Route path="/guest/create_account" element={<CreateAccount />} />
            <Route path="/guest/not_available" element={<SwydgetNotAvailableContainer />} />
            <Route path="/swydget/:mid/:eid" element={<SwydgetContainer />}></Route>
            <Route path="*" element={<SwydgetNotAvailableContainer />} />
          </Routes>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0}}>
            <Footer />
          </div>
          
        </div>
      </BrowserRouter>
    </LocalizationProvider>


    
  );
}

export default App;