import React, { Component } from 'react';
import { Image, Button, ButtonToolbar, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactSwipe from 'react-swipe';
import Iframe from 'react-iframe';
import Cookies from 'universal-cookie';
import { Slider } from '@mui/material';
import { Helmet } from 'react-helmet';
// import { geolocated } from 'react-geolocated';
import "./swydget/swydget.css";
import MeService from '../services/MeService';
import swydgetStore from "../stores/SwydgetStore";
import meStore from "../stores/MeStore";
import SwydgetWelcome from "./swydget/SwydgetWelcome";
import SwydgetInstructions from "./swydget/SwydgetInstructions";
import SwydgetResults from "./swydget/SwydgetResults";
import SwydgetRecommendations from "./swydget/SwydgetRecommendations";
import SwydgetComplete from "./swydget/SwydgetComplete";
import SwydgetContestEntry from "./swydget/SwydgetContestEntry";
import SwydgetForcedRankCardCollection from "./swydget/SwydgetForcedRankCardCollection";
import swytchbackDefaultWallpaper from '../SwytchbackDefaultWallpaper-1200.png';
import nextCardButtonImg from '../assets/swydget/next-button-thick.png';

// const SliderWithTooltip = createSliderWithTooltip(Slider);

/*
  SwydgetForcedRanking

  Forced Ranking randomly splits the pool of concepts (Wyshes) into buckets where the survey taker
  is forced to chose a clear winner. Those winners are then advanced to the next round where they compete
  with the other winners. When the final round is complete, we can create an coarsely sorted array of the concepts

  For example:

  wysh 1
  wysh 2
  wysh 3
  wysh 4           wysh 2

  wysh 5
  wysh 6
  wysh 7
  wysh 8           wysh 7

  wysh 9
  wysh 10
  wysh 11
  wysh 12          wysh 12

  wysh 13
  wysh 14
  wysh 15
  wysh 16          wysh 13           wysh 7 is the winner


  Navigate back down through the accumulated buckets and add them to the sorted array
  if they are not already in the array.

  Step 1: Place the Concepts in random buckets

  Step 2: Define the presentation of a buckets

  Step 3: present each bucket to the survey takers

  Step 4: Create buckets of the winners from step 3

  Step 5: repeat steps 3 and 4 until there is one final bucket.

  Step 6: Force the survey taker to rank the final bucket

*/


class SwydgetForcedRanking extends Component {

  constructor(props) {
    super(props);

    this.completionDivContainerStyle = {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff'
    };

    this.toolbarContainerDivStyle = {
      position: 'absolute',
      bottom: 0,
      backgroundColor: '#136ffb',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      borderTop: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#ffffff'
    };

    this.buttonToolbarStyle = {
      width: 300,
      padding: 0,
      margin: 0,
      height: 80
    }

    this.buttonStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      height: 80,
      backgroundColor: '#f7f6f7'
    }

    this.openProductLinkModal = this.openProductLinkModal.bind(this);
    this.closeProductLinkModal = this.closeProductLinkModal.bind(this);
    this.userKeySet = this.userKeySet.bind(this);
    this.userKeyResolved = this.userKeyResolved.bind(this);
    this.navigateToInstructions = this.navigateToInstructions.bind(this);
    this.navigateToSurvey = this.navigateToSurvey.bind(this);
    this.navigateToSurveyComplete = this.navigateToSurveyComplete.bind(this);
    this.navigateToSurveyResults = this.navigateToSurveyResults.bind(this);
    this.navigateToSurveyRecommendations = this.navigateToSurveyRecommendations.bind(this);
    this.navigateToAppStore = this.navigateToAppStore.bind(this);
    this.navigateToContestEntryForm = this.navigateToContestEntryForm.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSliderTouch = this.handleSliderTouch.bind(this);
    this.productLibraryLoaded = this.productLibraryLoaded.bind(this);


    this.userKey = null;
    this.eventInFocus = this.props.eventInFocus;
    this.email = this.props.email;
    this.firstName = this.props.firstName;
    this.lastName = this.props.lastName;
    this.surveyComplete = false;
    this.productInFocus = null;
    this.cards = [];
    this.wyshes = this.eventInFocus.getWyshes();
    this.accumulatedAttributesDict = {};

    this.rounds = [];
    this.roundWinners = [];
    this.currentRoundIndex = 0;
    this.currentChunkIndicesViewed = [];
    this.initialRandomWyshBucketCount = 0;
    this.toDoWyshes = [];

    this.rounds.push(this.generateRound(this.eventInFocus, this.wyshes));
    this.initialRandomWyshBucketCount = this.rounds[this.currentRoundIndex].length;

    this.state = {
      index: 0,
      currentView: 'getStarted',
      cards: [],
      showProductLinkModal: false,
      currentProductName: '',
      currentProductLink: '',
      isCurrentProductLinkImage: false,
      currentSliderValue: 0, // this.getInitialSliderDefaultValue(),
      maxSliderValue: this.getInitialSliderMaxLimit()
    }


    if (this.eventInFocus.randomize === true) {
      this.wyshes = this.eventInFocus.shuffle();
    }
  }

  componentWillMount() {
    swydgetStore.on("change-userKeySet", this.userKeySet);
    swydgetStore.on("change-userKeyResolved", this.userKeyResolved);
    meStore.on("change-surveyCreatorProductLibraryLoaded", this.productLibraryLoaded);
  }

  componentWillUnmount() {
    swydgetStore.removeListener("change-userKeySet", this.userKeySet);
    swydgetStore.removeListener("change-userKeyResolved", this.userKeyResolved);
    meStore.removeListener("change-surveyCreatorProductLibraryLoaded", this.productLibraryLoaded);
  }

  componentDidMount() {
    MeService.getProductsByMid(this.eventInFocus.creatorMid);
  }

  /* state change handlers */
  userKeySet() {
    this.userKey = swydgetStore.surveyTakerKey;

    // We have the userKey of the survey taker!!!
    const jsonString = JSON.stringify(this.userKey);
    // this.setCookie("sb", encodeURIComponent(jsonString));

    this.setState({currentSliderValue: this.getSliderDefaultValue(this.state.index)});
  }

  generateRound(survey, roundWyshes) {

    var roundBuckets = [];
    let desiredBucketSize = 4;
    let randomWyshArray= survey.shuffleTheseWyshes(roundWyshes);
    var roundBucketCount = Math.floor(randomWyshArray.length/desiredBucketSize);

    if (randomWyshArray.length % desiredBucketSize > 0) {
      roundBucketCount += 1;
    }

    // load the round desiredBuckets
    for (var i = 0; i < roundBucketCount; i++) {
      roundBuckets.push([]);
    }

    var currentBucketIndex = 0;

    for (i = 0; i < randomWyshArray.length; i++) {
      if (currentBucketIndex < roundBuckets.length) {
        roundBuckets[currentBucketIndex].push(randomWyshArray[i]);

        if (currentBucketIndex === roundBuckets.length - 1) {
          currentBucketIndex = 0;
        }
        else {
          currentBucketIndex += 1;
        }
      }
    }

    return roundBuckets;
  }

  getInitialSliderMaxLimit() {
    var maxLimit = 0;

    for (var i = 0; i < this.rounds[this.currentRoundIndex].length; i++) {
      maxLimit = this.rounds[this.currentRoundIndex][i].length;
      if (maxLimit > 0) {
        return maxLimit;
      }
    }

    return maxLimit;
  }

  getInitialSliderDefaultValue() {

    // In the standard swydget, we want the initial slider value to be the halfway point
    // of the options. Here, we just want it to always be 0 so the user definitely
    // sees the first option.

    return 0;
  }

  getSliderDefaultValue(index) {

    // In the standard swydget, we want the initial slider value to be the halfway point
    // of the options. Here, we just want it to always be 0 so the user definitely
    // sees the first option.

    return 0;
  }

  userKeyResolved() {
    this.userKeySet();
    this.setState({
      currentView: "surveyComplete"
    });
  }

  openProductLinkModal() {
    if (this.refs.reactSwipe) {
      var w = this.wyshes[this.refs.reactSwipe.getPos()];
      // var link = w.product.getSecureBuyPage();
      var link = w.product.buyPage;
      var productImageUrl = w.product.getSecureImageUrl(w);

      if (link !== '') {
        // if (w.product.isBuyPageSecure() === true) {
        //   this.setState({ showProductLinkModal: true, currentProductName: w.product.name, currentProductLink: link, isCurrentProductLinkImage: false });
        // }
        // else {
          window.open(link, '');
        // }
      }
      else if (productImageUrl !== '') {
        this.setState({ showProductLinkModal: true, currentProductName: w.product.name, currentProductLink: productImageUrl, isCurrentProductLinkImage: true });
      }
    }
  }

  closeProductLinkModal() {
    this.setState({ showProductLinkModal: false, currentProductName: '', currentProductLink: '', isCurrentProductLinkImage: false });
  }

  getCards() {

    // Very much different than the Regular Swydget
    // Here we are passing a set of Wyshes into a single "card" that
    // will display the currently reflected card by the slider.

    var cards = [];

    swydgetStore.setMaxListeners(this.wyshes.length + 5);

    for (var i = 0; i < this.rounds[this.currentRoundIndex].length; i++) {
      var cardCollection = <SwydgetForcedRankCardCollection wyshBucket={this.rounds[this.currentRoundIndex][i]} selectedCardIndex={this.state.currentSliderValue} totalWyshBucketsCount={this.rounds[this.currentRoundIndex].length} clickAction={this.openProductLinkModal}/>
      cards.push(<div key={i}>{cardCollection}</div>);
    }

    return cards;
  }

  navigateToInstructions() {
    this.setState({
      currentView: "instructions"
    });
  }

  navigateToSurvey() {
    this.setState({
      currentView: "takeSurvey"
    });
  }

  navigateToSurveyComplete() {
    this.setState({
      currentView: "surveyComplete"
    });
  }

  navigateToSurveyResults() {
    this.setState({
      currentView: "surveyResults"
    });
  }

  navigateToSurveyRecommendations() {
    this.setState({
      currentView: "surveyRecommendations"
    });
  }

  navigateToContestEntryForm() {
    this.setState({
      currentView: "enterContest"
    });
  }

  navigateToAppStore() {
    window.open('https://swytchback.com/', '_self');
    // window.open('https://itunes.apple.com/us/app/swytchback/id1178824453?mt=8', '_self');
  }

  submitProductDecision(decisionRaw, decisionValue) {

    // Forced Ranking is a little different. When we are submitting a decision here,
    // we are actually submitting a binary "yes" for the selected concept/product and
    // also submitting a binary "no" for the other choices.
    // we are also placing the winner into a new bucket at the end

    // I discovered that when the first vote for a brand new taker gets submitted, we do not
    // have a key for that user. After the 1st submission, we get a new anonymous key, but we
    // are also slamming out the other votes for a trial asynchronosly with the same undefined
    // user key.

    // If the user key is undefined, we need to submit 1 of the decisions for the trial and
    // store the others to be submitted with the next card. We'll have a queue of JSON objects
    // with all the info to submit the decisions later:
    // 1. productId
    // 2. eventId
    // 3. decisionLiteral
    // 4. decisionNormalized
    // 5. coordinates
    // 6. the round object
    // 7. roundNumber
    // 8. trialNumber


    // this.submitProductDecision(this.wyshes[this.state.index].getOptionValue(this.state.currentSliderValue), this.state.currentSliderValue.toString())

    if (this.refs.reactSwipe) {
      // var w = this.wyshes[this.refs.reactSwipe.getPos()];
      let w = this.rounds[this.currentRoundIndex][this.state.index][this.state.currentSliderValue];

      var key;

      if (this.userKey) {
        key = this.userKey;
      }
      else if (this.email) {
        key = {
          t: 'email',
          // v: 'test117@catboytech.com'
          v: this.email
        }
      }
      console.log(this.userKey);

      // Clear out any old Wyshes because the user had no key yet
      if (this.toDoWyshes.length > 0) {
        for (var i = 0; i < this.toDoWyshes.length; i++) {
          for (var j = 0; j < this.rounds[0][0].length; j++) {
            if (this.toDoWyshes[i].wyshId === this.rounds[0][0][j].wyshId) {

              console.log("sending negative response for " + this.rounds[0][0][j].wyshId);
              let w = this.rounds[0][0][j];
              MeService.postForcedRankDecision(key, this.firstName, this.lastName, this.rounds[0][0][j].product.productId, this.eventInFocus.eventId, "no", "0.0", this.props.coords, this.rounds[this.currentRoundIndex][this.state.index], 1, 1);
              this.rounds[0][0][j].wyshScore.resultLiteral = "no";
              this.rounds[0][0][j].wyshScore.resultNormalized = 0.0;

              var numberOfOptions = w.wyshOptions.length;

              if (this.rounds[0][0][j].questionType === "binary") {
                numberOfOptions = 2;
              }

              this.processProductAttributes(this.rounds[0][0][j].product, parseFloat(decisionValue), numberOfOptions);
            }
          }
        }

        this.toDoWyshes = [];
      }



      // now we iterate through the random bucket and if the product/concept we selected is selected, respond positively, otherwise respond negatively
      for (i = 0; i < this.rounds[this.currentRoundIndex][this.state.index].length; i++) {

        if (this.rounds[this.currentRoundIndex][this.state.index][i].product.productId === w.product.productId) {

          MeService.postForcedRankDecision(key, this.firstName, this.lastName, this.rounds[this.currentRoundIndex][this.state.index][i].product.productId, this.eventInFocus.eventId, "yes", "1.0", this.props.coords, this.rounds[this.currentRoundIndex][this.state.index], this.currentRoundIndex + 1, this.state.index + 1);
          this.rounds[this.currentRoundIndex][this.state.index][i].wyshScore.resultLiteral = "yes";
          this.rounds[this.currentRoundIndex][this.state.index][i].wyshScore.resultNormalized = 1.0;

          numberOfOptions = w.wyshOptions.length;

          if (this.rounds[this.currentRoundIndex][this.state.index][i].questionType === "binary") {
            numberOfOptions = 2;
          }

          this.processProductAttributes(this.rounds[this.currentRoundIndex][this.state.index][i].product, parseFloat(decisionValue), numberOfOptions);

          this.roundWinners.push(this.rounds[this.currentRoundIndex][this.state.index][i]);

        }
        else {

          if (key === undefined) {
            this.toDoWyshes.push(this.rounds[this.currentRoundIndex][this.state.index][i]);
          }
          else {
            MeService.postForcedRankDecision(key, this.firstName, this.lastName, this.rounds[this.currentRoundIndex][this.state.index][i].product.productId, this.eventInFocus.eventId, "no", "0.0", this.props.coords, this.rounds[this.currentRoundIndex][this.state.index], this.currentRoundIndex + 1, this.state.index + 1);
            this.rounds[this.currentRoundIndex][this.state.index][i].wyshScore.resultLiteral = "no";
            this.rounds[this.currentRoundIndex][this.state.index][i].wyshScore.resultNormalized = 0.0;

            numberOfOptions = w.wyshOptions.length;

            if (this.rounds[this.currentRoundIndex][this.state.index][i].questionType === "binary") {
              numberOfOptions = 2;
            }

            this.processProductAttributes(this.rounds[this.currentRoundIndex][this.state.index][i].product, parseFloat(decisionValue), numberOfOptions);
          }
        }
      }

      if (this.state.index === this.rounds[this.currentRoundIndex].length - 1) {
        // Right here, we have completed all the chunks for this ROUND
        // 1. take the winners bucket and create another round
        // 2. Increment the currentRoundIndex
        // 3. If there is nothing in the winners bucket, we are done and mark the surveyComplete

        if (this.roundWinners.length > 1){
          this.rounds.push(this.generateRound(this.eventInFocus, this.eventInFocus.shuffleTheseWyshes(this.roundWinners)));
          this.currentRoundIndex = this.currentRoundIndex + 1;
          this.roundWinners = [];
          this.currentChunkIndicesViewed = [];

          this.setState({index: 0, currentSliderValue: 0});
        }
        else {
          this.surveyComplete = true;
        }
      }
      else {
        this.refs.reactSwipe.next();
        this.incrementProductIndex();
      }
    }
  }

  processProductAttributes(product, decisionValue, numberOfOptions) {
    // decisionValue is the numeric score on this product.

    // Iterate through the attributes and add them to the attributes dictionary
    // Offense Run: power, finesse, speed, committee
    // Offense Pass: west coast, vertical, precision, spread
    // Defense: run stoppers, fast pursuit, great pass rush, great coverage
    // Players: great team, superstars, discplined

    let prdAttributes = product.attributes;

    if (prdAttributes) {
      var keys = Object.keys(prdAttributes);

      for (var i = 0; i < keys.length; i++) {

        var attr = this.accumulatedAttributesDict[keys[i]];
        let attribute = {
          "value": prdAttributes[keys[i]],
          "score": decisionValue,
          "numberOfOptions": numberOfOptions
        }

        if (attr) {
          attr.values.push(attribute);
        }
        else {
          var valueObj = {"values": [attribute]};
          this.accumulatedAttributesDict[keys[i]] = valueObj;
        }
      }
    }
  }

  generateSwydgetInstructionsButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToSurvey}>Play</Button>
        </ButtonToolbar>
      </div>
    );
  }

  generateSwydgetResultsButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToSurveyComplete}>Check for Reward</Button>
        </ButtonToolbar>
      </div>
    );
  }

  generateSwydgetRecommendationsButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToSurveyComplete}>Check for Reward</Button>
        </ButtonToolbar>
      </div>
    );
  }

  generateSwydgetCompleteButtonToolbar() {
    return (
      <div style={this.toolbarContainerDivStyle}>
        <ButtonToolbar style={this.buttonToolbarStyle}>
          <Button className="singleButtonStyle" onClick={this.navigateToAppStore}>Get the App</Button>
        </ButtonToolbar>
      </div>
    );
  }

  shouldSelectBeDisabled(index) {
    if (this.currentChunkIndicesViewed.indexOf(index) < 0) {
      this.currentChunkIndicesViewed.push(index);
    }

    if (this.currentChunkIndicesViewed[this.currentChunkIndicesViewed.length - 1] === this.rounds[this.currentRoundIndex][this.state.index].length - 1) {
      return false;
    }
    else {
      return true;
    }
  }

  // generateScalarToolbar() {
  //
  //   var isSelectDisabled = this.shouldSelectBeDisabled(this.state.currentSliderValue);
  //
  //   var cardHeight = 55;
  //   var cardWidth = cardHeight * (2.5/3.5); // standard card width
  //   var nextButtonWidthStyle = cardWidth + 'vh';
  //
  //   var maxLimit = this.rounds[this.currentRoundIndex][this.state.index].length - 1;
  //
  //   let toolbarContainerNoTopBorderDivStyle = {
  //     backgroundColor: 'rgba(0,0,0,0)',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     width: '100%',
  //     textAlign: 'center'
  //   };
  //
  //   let selectionButtonToolbarStyle = {
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     position: 'relative',
  //     width: nextButtonWidthStyle,
  //     padding: 0,
  //     marginTop: 50
  //   };
  //
  //   let middleButtonStyle = {
  //     width: nextButtonWidthStyle,
  //     margin: 0,
  //     padding: 0,
  //     height: 40,
  //     border: 1,
  //     borderStyle: 'solid',
  //     borderColor: '#a9a9a9',
  //     borderRadius: 6,
  //     color: '#ffffff',
  //     fontSize: 18,
  //     fontWeight: 500,
  //     background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`
  //
  //     // WebkitBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  //     // MozBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Firefox 3.5 - 3.6 */
  //     // boxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  //   };
  //
  //   let sliderWrapper = {
  //     width: nextButtonWidthStyle,
  //     marginTop: 0,
  //     marginBottom: 10
  //   };
  //
  //   var label = this.state.currentSliderValue + 1;
  //
  //   if (this.rounds[this.currentRoundIndex][this.state.index][this.state.currentSliderValue].product.name !== "") {
  //     label = this.rounds[this.currentRoundIndex][this.state.index][this.state.currentSliderValue].product.name;
  //   }
  //
  //   return (
  //     <div style={toolbarContainerNoTopBorderDivStyle}>
  //         <div style={sliderWrapper}>
  //           <SliderWithTooltip
  //             style={{margin: 0}}
  //             min={0}
  //             max={maxLimit}
  //             value={this.state.currentSliderValue}
  //             onChange={this.handleSliderChange}
  //             onBeforeChange={this.handleSliderTouch}
  //             tipFormatter={value => label}
  //           />
  //         </div>
  //       <ButtonToolbar style={selectionButtonToolbarStyle}>
  //         <Button style={middleButtonStyle} disabled={isSelectDisabled} onClick={() => this.submitProductDecision(this.wyshes[this.state.index].getOptionValue(this.state.currentSliderValue), this.state.currentSliderValue.toString())}>SELECT</Button>
  //       </ButtonToolbar>
  //     </div>
  //   );
  // }

  handleScalarSliderUnclicked = (e) => {
    this.setState({
      isScalarNextDisabled: false,
    });
  }

  handleScalarSliderClicked = (e) => {
    this.setState({
      isScalarOptionHidden: false,
      // isScalarNextDisabled: true,
    });
  }


  generateScalarToolbar() {

    var cardHeight = 45;
    var cardWidth = cardHeight * (2.5/3.5); // standard card width
    var containerWidth = cardWidth + 12;
    var nextButtonWidthStyle = cardWidth + 'vh';
    var containerWidthStyle = containerWidth + 'vh';

    var maxLimit = this.rounds[this.currentRoundIndex][this.state.index].length - 1;

    let toolbarContainerNoTopBorderDivStyle = {
      paddingTop: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: containerWidthStyle,
      textAlign: 'center'
    };

    let sliderWrapper = {
      width: nextButtonWidthStyle,
      marginTop: 0,
      marginBottom: 0
    };

    let activeButtonStyle = {
      width: 26,
      marginLeft: 8,
      padding: 0,
      backgroundColor: 'transparent',
      border: 0
      // WebkitBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      // MozBoxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Firefox 3.5 - 3.6 */
      // boxShadow: '0px 3px 25px 10px rgba(0,0,0,0.7)',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    };

    let buttonImageStyle = {
      margin: 0,
      padding: 0
    };

    var optionText = "";

    optionText = this.state.currentSliderValue.toString();

    if (this.rounds[this.currentRoundIndex][this.state.index][this.state.currentSliderValue].product.name !== "") {
      optionText = this.rounds[this.currentRoundIndex][this.state.index][this.state.currentSliderValue].product.name;
    }

    let scalarOptionsTextActiveStyle = {
      width: nextButtonWidthStyle,
      backgroundColor: '#ffffff',
      color: '#000000',
      marginBottom: 20,
      padding: 10,
      borderRadius: 6,
      fontSize: 14
    }

    var scalarOptionsTextStyle = scalarOptionsTextActiveStyle;

    return (
      <div style={toolbarContainerNoTopBorderDivStyle}>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
          }}>
          <div style={{
            width: 50,
            marginBottom: 20
            }}>

          </div>


          <div style={scalarOptionsTextStyle}>
            {optionText}
          </div>

          <div style={{
            width: 50,
            marginBottom: 20
            }}>
            { this.state.isScalarNextDisabled === false &&
              <Button
                style={activeButtonStyle}
                ref={button => {this.target = button;}}
                onClick={() => this.submitProductDecision(this.wyshes[this.state.index].getOptionValue(this.state.currentSliderValue), this.state.currentSliderValue.toString())}
                >
                <Image style={buttonImageStyle} src={nextCardButtonImg} responsive/>
              </Button>
            }
          </div>

        </div>

        <div style={sliderWrapper}>
          <Slider
            id="scalarSlider"
            classes={{root: this.props.classes.slider,
                      thumb: this.props.classes.thumb,
                      track: this.props.classes.track,
                      rail: this.props.classes.rail}}
            min={0}
            max={maxLimit}
            value={this.state.currentSliderValue}
            onClick={this.handleScalarSliderClicked}
            onChange={this.handleSliderChange}
            onChangeCommitted={this.handleScalarSliderUnclicked}
          />
        </div>
      </div>
    );
  }
  handleSliderChange(event, value) {
    this.setState({
      currentSliderValue: Math.round(value)
    });
  }


  handleSliderTouch(value) {

    // Nothing is really happening here. This is where we would place logic
    // if we needed to handle when the slider was tapped.


  }

  generateSwydgetSurveyButtonToolbar() {

    var toolbar = this.generateScalarToolbar();

    return toolbar;
  }

  generateSwydgetWelcome() {

    var actionMethod = this.navigateToSurvey;
    // if (this.userKey) {
    //   actionMethod = this.navigateToSurvey;
    // }

    return (
      <div onClick={actionMethod}>
        <SwydgetWelcome eventInFocus={this.eventInFocus} action={actionMethod} />
      </div>
    );
  }

  generateSwydgetInstructions() {
    // <div onClick={this.navigateToSurvey}>
    return (
      <div>
        <SwydgetInstructions eventInFocus={this.eventInFocus} actionMethod={this.navigateToSurvey} />
      </div>
    );
  }

  generateSwydgetSurvey() {

    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;

    if (this.eventInFocus && this.eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = this.eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }

    let cardCountDivStyle = {
      marginTop: 5,
      marginBottom: 0,
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 400,
      padding: 0
    };

    let titleDivStyle = {
      marginTop: 0,
      marginBottom: 0,
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 600,
      padding: 0
    };

    let containerDivStyle = {
      height: '100vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };

    let containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }

    let headerDivStyle = {
      height: '10vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10
    };

    let footerDivStyle = {
      height: '25vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10
    };

    let primaryContentContainerStyle = {
      height: '65vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      zIndex: 10
    };

    var cardQuestion = this.eventInFocus.title;

    if (this.eventInFocus.getWyshes()[this.state.index].caption !== undefined && this.eventInFocus.getWyshes()[this.state.index].caption !== "") {
      cardQuestion = this.eventInFocus.getWyshes()[this.state.index].caption;
    }

    return (
      <div style={containerDivStyle}>
        <div style={containerStyle}>
          <div style={headerDivStyle}>
            <div style={cardCountDivStyle}>Round {this.currentRoundIndex + 1}: {this.state.index + 1}/{this.cards.length}</div>
            <div style={titleDivStyle}>{cardQuestion}</div>
          </div>
          <div style={primaryContentContainerStyle}>
            <div>
              <ReactSwipe key={this.rounds[this.currentRoundIndex].length} ref="reactSwipe" className="carousel" swipeOptions={{continuous: false, speed: 500, disableScroll: true}}>{this.cards}</ReactSwipe>
            </div>
          </div>
          <div style={footerDivStyle}>
            {this.generateSwydgetSurveyButtonToolbar()}
          </div>
        </div>
      </div>
    );
  }

  generateSurveyResults() {

    var actionMethod = this.navigateToSurveyComplete;

    if (this.eventInFocus.showRecommendations === true) {
      actionMethod = this.navigateToSurveyRecommendations;
    }
    else if (this.eventInFocus.contestPrizeText !== "") {
      actionMethod = this.navigateToContestEntryForm
    }

    return (
      <SwydgetResults eventInFocus={this.eventInFocus} primaryAction={actionMethod} />
    );
  }

  generateSurveyRecomendations() {

    var actionMethod = this.navigateToSurveyComplete;

    if (this.eventInFocus.contestPrizeText !== "") {
      actionMethod = this.navigateToContestEntryForm
    }

    return (
      <SwydgetRecommendations eventInFocus={this.eventInFocus} accumulatedAttributesDict={this.accumulatedAttributesDict} productLibrary={meStore.getSurveyCreatorProductLibrary()} productImageUrl={this.eventInFocus.getCoverPhotoUrl()} primaryAction={actionMethod} />
    );
  }

  generateSurveyComplete() {
    return (
      <SwydgetComplete eventInFocus={this.eventInFocus} />
    );
  }

  generateSwydgetContestEntry() {

    var uk;

    if (this.userKey) {
      uk = this.userKey;
    }

    return (
      <SwydgetContestEntry userKey={uk} eventInFocus={this.eventInFocus} productImageUrl={this.eventInFocus.getCoverPhotoUrl()} />
    );
  }

  incrementProductIndex() {

    if (this.refs.reactSwipe) {

      this.setState({ index: this.refs.reactSwipe.getPos(), currentSliderValue: 0 });
      this.currentChunkIndicesViewed = [];

    }
    else {
      console.log("incrementProductIndex -> the reactSwipe was undefined...");
    }
  }

  generateMediaModalContent(cardHeightStyle) {
    if (this.state.isCurrentProductLinkImage === true) {

      let containerDivStyle = {
        height: '65vh',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      };

      let imageStyle = {
        // maxHeight: '100%',
        // maxWidth: '100%'
      };

      return (
        <div style={containerDivStyle}><Image style={imageStyle} src={this.state.currentProductLink} responsive/></div>
      );
    } else {
      return (
        <Iframe url={this.state.currentProductLink}
          width="100%"
          height={cardHeightStyle}
          display="initial"
          position="relative"
          allowFullScreen/>
      );
    }
  }

  productLibraryLoaded() {


  }

  render() {

    const cookies = new Cookies();
    // this.userKey = cookies.get("sb");

    var cardHeight = 40;
    var cardHeightStyle = cardHeight + 'vh';

    var swydgetUrl = this.eventInFocus.generateSwydgetUrl();

    let tableStyle = {
      height: '100vh',
      position: 'relative',
      backgroundColor: '#1997fc'
    }

    this.cards = this.getCards();

    var body = <div></div>;
    var buttonPanel = <div></div>;
    if (this.state.currentView === "getStarted") {
      // body = this.generateSurveyComplete();
      // body = this.generateSwydgetContestEntry();
      body = this.generateSwydgetWelcome();
      buttonPanel = <div></div>;
    }
    else if (this.state.currentView === "instructions") {
      body = this.generateSwydgetInstructions();
      buttonPanel = <div></div>;
      // buttonPanel = this.generateSwydgetInstructionsButtonToolbar();
    }
    else if (this.state.currentView === "surveyComplete") {
      body = this.generateSurveyComplete();
      buttonPanel = <div></div>;
      // buttonPanel = this.generateSwydgetCompleteButtonToolbar();
    }
    else if (this.state.currentView === "enterContest") {
      body = this.generateSwydgetContestEntry();
      buttonPanel = <div></div>;
    }
    else if (this.state.currentView === "surveyResults") {
      body = this.generateSurveyResults();
      buttonPanel = <div></div>;
    }
    else if (this.state.currentView === "surveyRecommendations") {
      body = this.generateSurveyRecomendations();
      buttonPanel = <div></div>;
    }
    else {
      if (this.surveyComplete) {
        if (this.eventInFocus.showResults === true) {
          this.navigateToSurveyResults();
        }
        else if (this.eventInFocus.showRecommendations === true) {
          this.navigateToSurveyRecommendations();
        }
        else {
          this.navigateToSurveyComplete();
        }
      }
      else {
        body = this.generateSwydgetSurvey();
        // buttonPanel = this.generateSwydgetSurveyButtonToolbar();
        buttonPanel = <div></div>;
      }
    }

    var description = "Real Answers. Real Results. Real Fast.";
    if (this.eventInFocus.rewardText !== "") {
      description = this.eventInFocus.rewardText;
    }

    return (
      <div id="swydget_container" style={tableStyle}>
        <Helmet>
          <title>Swytchback - {this.eventInFocus.title}</title>
          <meta property="og:url"                content={swydgetUrl} />
          <meta property="og:type"               content="article" />
          <meta property="og:title"              content={this.eventInFocus.title}/>
          <meta property="og:description"        content={description} />
          <meta property="og:image"              content={this.eventInFocus.getCoverPhotoUrl()} />
        </Helmet>
        {body}
        {buttonPanel}
        <Modal show={this.state.showProductLinkModal} onHide={this.closeProductLinkModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.currentProductName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.generateMediaModalContent(cardHeightStyle)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeProductLinkModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>


    );
  }
}

SwydgetForcedRanking.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SwydgetForcedRanking;