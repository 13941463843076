import React from "react";
import { Image }  from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import "../../css/swydgetStyles.css";
import swydgetStore from "../../stores/SwydgetStore";
import CardEditor from "../../components/admin/CardEditor";
import moreInfoButton from '../../assets/info-button_256_gray.png';
import nextCardImage from '../../SwytchbackDefaultWallpaper-1192.png';
import defaultCardBackgroundImage from '../../assets/swydget/default-card-background.png';

export default class SwydgetSwipeCard extends React.Component {

  constructor() {
    super();
    this.processUserDecision = this.processUserDecision.bind(this);
    this.swipeExecuted = this.swipeExecuted.bind(this);
    this.wysh = null;
    this.totalWyshesCount = 0;
    this.productImage = null;
    
    this.state = {
      decision: "undefined",
      imageLoaded: false
    };
  };

  componentDidMount() {
    swydgetStore.on("change-userKeySet", this.processUserDecision);
    swydgetStore.on("change-SWYDGET_POST_PRODUCT_DECISION", this.swipeExecuted);
  }

  componentWillUnmount() {
    swydgetStore.removeListener("change-userKeySet", this.processUserDecision);
    swydgetStore.removeListener("change-SWYDGET_POST_PRODUCT_DECISION", this.swipeExecuted);
  }


  processUserDecision() {

    if (this.wysh.product.productId === swydgetStore.mostRecentDecisionProductId) {

      this.setState({ decision: swydgetStore.mostRecentDecision });

    }
  }

  swipeExecuted() {
    if (this.wysh.product.productId === swydgetStore.mostRecentDecisionProductId) {

      this.setState({ decision: swydgetStore.mostRecentDecision });

    }
  }

  generateProductPriceLabel() {
    if (this.wysh.product.price > 0) {
      return "$" + this.wysh.product.getPrice(2);
    }
  };

  calculateMovementPct(cp) {

    if (cp.cw === undefined) {
      return 1.0;
    }
    else {
      var halfCw = cp.cw/2; // You can multiply this by a number between 0 and 1 to play with the rate the icon becomes fully opaque
      return cp.x/halfCw;
    }
  }

  determineFontSize() {
    // if (this.wysh && this.wysh.product.description.length > 80){
      return "3.1vh";
    // }
    // else {
    //   return "4vh";
    // }
  }

  determineLineHeight() {
    if (this.wysh && this.wysh.product.description.length > 80){
      return "3.6vh";
    }
    else {
      return "4.8vh";
    }
  }

  onCardLoad = () => {

    this.setState({imageLoaded: true});
    if (this.props.cardLoadedCallback) {
      this.props.cardLoadedCallback();
    }

    if (this.props.setViewedTimestamp) {
      this.props.setViewedTimestamp(new Date().getTime());
    }

  }

  getProductImage(product, cardHeight, cardWidth) {

    let productCleanImageStyle = {
      width: cardWidth,
      height: cardHeight,
      objectFit: product.styles.objectFit,
      background: 'linear-gradient(#0167EC, #00B6F9)',
      zIndex: -10
    };

    let productCleanImageHiddenStyle = {
      display: 'none'
    };

    var productImageUrl = product.getSecureImageUrl(this.wysh);

    if (product && productImageUrl !== "") {
      return  <Image 
                src={productImageUrl} 
                style={this.state.imageLoaded ? productCleanImageStyle : productCleanImageHiddenStyle} 
                onLoad={this.onCardLoad}>
              </Image>
    }
    else {
      return <Image src={defaultCardBackgroundImage} onLoad={this.onCardLoad} style={productCleanImageStyle}></Image>
    }
  }

  getProgressIndicator = (cardHeight, cardWidth) => {

    const showIndicatorStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: cardHeight,
      width: cardWidth,
      zIndex: 10
    };

    const hideIndicatorStyle = {
      display: 'none'
    }

    const indicatorStyle = {
      color: '#ffffff'
    }

    if (this.state.imageLoaded) {
      return <div style={hideIndicatorStyle}></div>;
    }
    else {
      return <div style={showIndicatorStyle}>
        <CircularProgress style={indicatorStyle} size={cardWidth / 4}/>
      </div>
    }
  }

  render() {

    const { clickAction } = this.props;
    const { cardWidth } = this.props;
    const { cardHeight } = this.props;
    const { cardPosition } = this.props;

    this.wysh = this.props.wysh;
    this.totalWyshesCount = this.props.totalWyshesCount;

    let shadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      WebkitBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      MozBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      boxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)'
    };

    let noShadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%'
    };

    let nextCardImageStyle = {
      width: cardWidth + 2,
      height: cardHeight + 2,
      objectFit: 'cover',
      zIndex: -10
    };

    var cardStyle = noShadowCardStyle;
    if (cardPosition.shadow === true) {
      cardStyle = shadowCardStyle;
    }

    let yesOverlayStyle = {
      position: 'absolute',
      top: 20,
      left: 20,
      height: 50,
      zIndex: 10,
      opacity: Math.abs(this.calculateMovementPct(cardPosition))
    }

    let noOverlayStyle = {
      position: 'absolute',
      top: 20,
      right: 20,
      height: 50,
      zIndex: 10,
      opacity: Math.abs(this.calculateMovementPct(cardPosition))
    }

    let iconHeight = cardWidth * 0.2;
    let iconWidth = cardWidth * 0.2;

    let moreInfoContainerStyle = {
      position: 'absolute',
      bottom: iconWidth / 4,
      left: iconWidth / 4,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let moreInfoButtonStyle = {
      height: iconHeight,
      width: iconWidth
    }

    let moreInfoTextStyle = {
      color: '#ffffff',
      fontSize: 14,
      fontWeight:400,
      marginLeft: 10,
      marginRight: 10
    }



    var textColor = "#000000";
    var textShadow = "0.0vh 0.0vh 0.5vh rgba(255,255,255,1.0)";

    if (this.wysh.product.styles.textShadow && this.wysh.product.styles.textShadow === "light") {
      textColor = "#ffffff";
      textShadow = "0.0vh 0.0vh 0.5vh rgba(0,0,0,1.0)";
    }

    let textBoxContainerStyle = {
      margin: 0,
      padding: '1.1vw',
      position: 'absolute',
      display: 'flex',
      justifyContent: this.wysh.product.styles.verticalAlign,
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: this.wysh.product.styles.justification,
      top: 0,
      left: 0,
      width: cardWidth,
      height: cardHeight,
      color: this.wysh.product.styles.textColor,
      fontFamily: this.wysh.product.styles.getFontFamily(),
      // textShadow: textShadow,
      fontSize: this.wysh.product.determineFontSize(1.5),
      zIndex: -7
    }

    var overlayImage = null;
    var overlayStyle = {};

    if (this.wysh.questionType === "binary" || this.wysh.questionType === "") {
      if (this.calculateMovementPct(cardPosition) > 0) {
        overlayImage = this.wysh.binaryIconSet.yesUrl;
        overlayStyle = yesOverlayStyle
      }
      else if (this.calculateMovementPct(cardPosition) < 0) {
        overlayImage = this.wysh.binaryIconSet.noUrl;
        overlayStyle = noOverlayStyle;
      }
    }

    // REMOVED FROM BELOW BECAUSE IT WASN'T BEING USED, BUT IT DOES WORK JUST FINE:
    // { cardPosition.blur === false &&
    // <Image  onClick={() => clickAction()} src={moreInfoButton} style={moreInfoButtonStyle}></Image>
    // }

    // TEMPORARY KLUDGE: Disabled the view again by adding false to the conditional logic to display (line 275)
    return (

      <div style={cardStyle}><div style={{backgroundColor: 'rgba(0, 0, 0, 0.0)', zIndex: -100}}>
        { cardPosition.blur === true ?
        <Image src={nextCardImage} style={nextCardImageStyle}></Image>
        :
        this.getProductImage(this.wysh.product, cardHeight, cardWidth)
        }

        { cardPosition.blur === false && this.wysh.product.hasImage() &&
          this.getProgressIndicator(cardHeight, cardWidth)
        }  

        { cardPosition.blur === false &&
          <React.Fragment>
            {CardEditor.toMarkup(this.wysh.product, 45)}
          </React.Fragment>
        }

        { false && cardPosition.blur === false && this.wysh.product.isBuyPageRerenderable() &&
          <div style={moreInfoContainerStyle}>
            <Image onClick={() => clickAction()} src={moreInfoButton} style={moreInfoButtonStyle}></Image>
            <div onClick={() => clickAction()} style={moreInfoTextStyle}>
              Click to View Again
            </div>
          </div>
        }
        { overlayImage !== null &&
        <Image src={overlayImage} style={overlayStyle}></Image>
        }
        
      </div></div>
    );
  }
}
