import React, { useState, useEffect }from 'react';
import { Button, ButtonToolbar, Image }  from 'react-bootstrap';
// import "../../../css/swydgetStyles.css";
import "../../../css/maxDiffStyles.css";
import Decision from '../../../classes/Decision';
import yesOverlayImage from '../../../assets/swydget/thumbs-up-button.png';
import { ArrowCircleRight } from '@mui/icons-material';



export default function PairwiseToolbar(props) {
  
  const pairwiseBlock = props.pairwiseBlock;
  const stimuliArray = pairwiseBlock.orderedWyshList.getOrderedWyshes();
  const trialSetChangeCallback = props.trialSetChangeCallback;
  const cardPreferencesUpdatedCallback = props.cardPreferencesUpdatedCallback;
  const submitFunction = props.submitFunction; 
  
  const [currentTrialSetIndex, setCurrentTrialSetIndex] = useState(0);
  const [isOptionTextHidden, setIsOptionTextHidden] = useState(false);
  const [mostPreferable, setMostPreferable] = useState(null);
  const [trialSetsDecisionsMap, setTrialSetDecisionsMap] = useState(new Map());
  const [wyshArray, setWyshArray] = useState(pairwiseBlock.pairwiseTrialSets[currentTrialSetIndex].items);

  const cardHeight = 45;
  const cardWidth = cardHeight * (2.5/3.5); // standard card width
  const containerWidth = cardWidth + 12;
  const nextButtonWidthStyle = cardWidth + 'vh';
  const containerWidthStyle = containerWidth + 'vh';


  useEffect(() => {
    var decisions = generateDecisions();
    cardPreferencesUpdatedCallback(decisions)

  }, [mostPreferable]);

  useEffect(() => {
    setWyshArray(pairwiseBlock.pairwiseTrialSets[currentTrialSetIndex].items);
  }, [currentTrialSetIndex]);
  

  let toolbarContainerNoTopBorderDivStyle = {
    paddingTop: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: containerWidthStyle,
    textAlign: 'center'
  };

  let selectionButtonToolbarStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: 320,
    paddingTop: 0,
    margin: 0
  };

  const buttonStyle = {
    border: 0,
    marginLeft: 10,
    marginRight: 10,
    padding: 0,
    borderRadius: '50%',
    backgroundColor: 'transparent'
  };

  const buttonImageStyle = {
    height: '7vh',
    width: '7vh'
  };

  let optionsTextActiveStyle = {
    width: nextButtonWidthStyle,
    minHeight: 40,
    color: '#000000',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  let optionsTextInactiveStyle = {
    width: nextButtonWidthStyle,
    minHeight: 40,
    color: '#aeaeae',
    marginBottom: 20,
    padding: 10,
    borderRadius: 6,
    fontSize: 14
  }

  var optionsTextStyle = optionsTextActiveStyle;
  
  if (isOptionTextHidden === false) {
    optionsTextStyle = optionsTextInactiveStyle;
  }

  const processDecisions = () => {

    var decisions = generateDecisions();
    trialSetsDecisionsMap.set(currentTrialSetIndex, decisions);
    
    if (currentTrialSetIndex < props.pairwiseBlock.getPairwiseVersion().trialSets.length - 1) {
      setCurrentTrialSetIndex(currentTrialSetIndex + 1);
      trialSetChangeCallback(currentTrialSetIndex + 1);
    }
    else {
      setCurrentTrialSetIndex(0);
      trialSetChangeCallback(0);

      console.log(pairwiseBlock.getBlockType());
      console.log(trialSetsDecisionsMap);
      pairwiseBlock.getBlockType().generateIndividualUtilityScores(stimuliArray, trialSetsDecisionsMap)

      submitFunction(trialSetsDecisionsMap);
    }
    
    setMostPreferable(null);
    setIsOptionTextHidden(false);
  }

  const generateDecisions = () => {

    const decisions = [];
    for (const w of wyshArray) {
      if (w.equals(mostPreferable)){
        const d = new Decision();
        d.wysh = w;
        d.resultNormalized = 1;
        d.resultLiteral = "most preferable";
        d.trial = props.pairwiseBlock.getPairwiseVersion().trialSets[currentTrialSetIndex].trialSetId - 1;
        decisions.push(d);
      }
      else {
        const d = new Decision();
        d.wysh = w;
        d.resultNormalized = 0;
        d.resultLiteral = "";
        d.trial = props.pairwiseBlock.getPairwiseVersion().trialSets[currentTrialSetIndex].trialSetId - 1;
        decisions.push(d);
      }
    }
    
    return decisions
  }

  const handleSliderUnclicked = () => {
    isTrialSetComplete();
  }

  const isTrialSetComplete = () => {
    // A trial set is complete if
    // 1. the user has seen all the stimuli
    // 2. Most preferable is set
    // 3. Least preferable is set
    
    return mostPreferable;
  }

  const selectMostPreferable = () => {

    if (props.cardSelectedIndex >= 0) {
      const selectedWysh = wyshArray[props.cardSelectedIndex];
      setMostPreferable(selectedWysh);
    }
  }

  const isButtonDisabled = () => {
    return props.cardSelectedIndex > -1 ? false : true;
  }

  return (
    <div style={toolbarContainerNoTopBorderDivStyle}>
      <ButtonToolbar style={selectionButtonToolbarStyle}>
        <Button 
          style={buttonStyle}
          disabled={isButtonDisabled()} 
          onClick={() => selectMostPreferable()}>
          <Image src={yesOverlayImage} style={buttonImageStyle}></Image>
        </Button>
      </ButtonToolbar>
      { isTrialSetComplete() &&
        <div className="nextButtonContainerStyle">
          <div className="circle pulse">
            <ArrowCircleRight 
              style={{fontSize: 34}}
              className="maxDiffNextGlyphStyle"
              onClick={() => {
                processDecisions()
              }}
            />
          </div>
        </div>
      }
    </div>
  );
};