import MessagingCampaign from './MessagingCampaign';

export default class MessagingCampaignBucket
{
  constructor() {
    this.version = 1;
    this.mid = "";
    this.messaging_campaigns = {};
  }

  initMessagingCampaignBucketFromJson(mid, json) {
    this.mid = mid;

    for (var k in json.messaging_campaigns) {
      var mcJson = json.messaging_campaigns[k];
      var mc = new MessagingCampaign();

      mc.messaging_campaign_id = mcJson.messaging_campaign_id;
      mc.messaging_campaign_name = mcJson.messaging_campaign_name;
      mc.messages = mcJson.messages;
      mc.phone_numbers = mcJson.phone_numbers

      for (var i = 0; i < mc.phone_numbers.length; i++) {
        mc.phone_numbers_string += mc.phone_numbers[i];
        mc.phone_numbers_string += "\n";
      }

      mc._created = mcJson._created;
      mc._updated = mcJson._updated;

      this.messaging_campaigns[k] = mc;
    }
  }

  static createMessagingCampaignBucketFromJson(mid, json)
  {
    var bucket = new MessagingCampaignBucket();
    bucket.initMessagingCampaignBucketFromJson(mid, json);
    return bucket;
  }

}
