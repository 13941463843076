// SkipRule
//
// A Wysh can have 1 or more SkipRule. A SkipRule has logic that determines whether or
// not its target Wysh should be navigated to. It possesses:
//
//  1. The Wysh it belongs to.
//  2. A boolean evaluation method that determines whether the rule has been satisfied
//  3. It's target WYSH
//
//
// var isSatisfied = false;
//
// [
//   [rule1, rule2], // must BOTH eval to true
//   [rule3, rule3, rule4]
// ]
//
//
//
// {
// ....
//
//   "skip_logic": {
//     "target_wysh": [wysh_id]
//     "rules": [
//       [
//         "wysh_id": [wysh_id]
//         "result_normalized": [NUMBER],
//         "negation": [BOOLEAN]
//       ]
//     ]
//
//   }
// }

export default class SkipRule
{
  constructor() {
    this.observedWyshId = null;
    this.resultNormalized = 0.0;
    this.negation = false;
  }

  toSimpleJson() {
    return { wysh_id: this.observedWyshId, result_normalized: this.resultNormalized, negation: this.negation }
  }

  interpret(decisionsToBeSubmittedArray) {

    // if negation is true, we want to go through all the decisions to make sure that the
    // required decision is not present.

    if (this.negation === true) {
      // go through the decisions and make sure the observedWyshId does NOT have a decision with the
      // resultNormalized
      for (var i = 0; i < decisionsToBeSubmittedArray.length; i++) {
        if (this.observedWyshId === decisionsToBeSubmittedArray[i].wysh.wyshId) {
          if (Number(decisionsToBeSubmittedArray[i].decisionValue) === this.resultNormalized) {
            return false;
          }
        }
      }

      return true;
    }
    else {
      // We want to find the resultNormaized in the decision.

      for (i = 0; i < decisionsToBeSubmittedArray.length; i++) {
        if (this.observedWyshId === decisionsToBeSubmittedArray[i].wysh.wyshId) {
          if (Number(decisionsToBeSubmittedArray[i].decisionValue) === this.resultNormalized) {
            return true;
          }
        }
      }

      return false;
    }
  }

  initFromJson(skipRuleJson) {
    this.observedWyshId = skipRuleJson.wysh_id;
    this.resultNormalized = skipRuleJson.result_normalized;
    this.negation = skipRuleJson.negation;
  }

  static createFromJson(skipRuleJson) {
    var skipRule = new SkipRule();
    skipRule.initFromJson(skipRuleJson);
    return skipRule;
  }
}
