import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Paper, Popover } from '@mui/material';
import { Delete, Settings } from '@mui/icons-material';
import { Container, Row, Col, Image, Button, Modal }  from 'react-bootstrap';
import "../css/swytchbackStyles.css";
import classes from "../css/modules/AccountManager.module.css";
import UserInfoFormModal from '../hooks/admin/user/UserInfoFormModal';
import CredentialsModal from "../hooks/admin/user/CredentialsModal";
import FriendsTable from '../components/admin/FriendsTable';
import FriendsTableRow from '../components/admin/FriendsTableRow';
import IntegrationsModal from "../hooks/admin/IntegrationsModal";
import AuthService from '../services/AuthService';
import User from "../classes/User";
import OAuthArtifacts from "../classes/OAuthArtifacts";
import LoginStore from "../stores/LoginStore";
import addContentImg from '../Plus-Trans-96.png';


class AccountInfo extends React.Component {

  constructor() {
    super();
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleUPasswordChange = this.handleUPasswordChange.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.openCredentialsModal = this.openCredentialsModal.bind(this);
    this.closeCredentialsModal = this.closeCredentialsModal.bind(this);
    this.openUserInfoModal = this.openUserInfoModal.bind(this);
    this.closeUserInfoModal = this.closeUserInfoModal.bind(this);
    this.uploadProfilePhoto = this.uploadProfilePhoto.bind(this);
    this.openIntegrationsModal = this.openIntegrationsModal.bind(this);
    this.closeIntegrationsModal = this.closeIntegrationsModal.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.inviteFriend = this.inviteFriend.bind(this);
    this.deleteFriend = this.deleteFriend.bind(this);
    this.logout = this.logout.bind(this);
    this.advancedMode = false;


    // 1. fetch username and password from local storage
    this.loggedInUser = User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString"));
    this.isUserLoggedIn = false;
    if (this.loggedInUser.userId !== "")
    {
      this.isUserLoggedIn = true
    }

    this.state = {
      showCredentialsModal: false,
      showUserInfoModal: false,
      showIntegrationsModal: false,
      loggedInUser: this.loggedInUser,
      isUserLoggedIn: this.isUserLoggedIn,
      imageFiles: [],
      showSettingsModal: false,
      anchorEl: null
    };
  }

  componentWillUnmount() {
    LoginStore.removeListener("auth_add_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_update_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_delete_segment_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_add_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_update_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("auth_delete_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.removeListener("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.removeListener("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.removeListener("change-logout", this.setLoggedInUser);
  }

  componentDidMount() {

    LoginStore.on("auth_add_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_update_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_delete_segment_successful", this.setLoggedInUser);
    LoginStore.on("auth_add_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("auth_update_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("auth_delete_messaing_campaign_successful", this.setLoggedInUser);
    LoginStore.on("loginStore_user_logged_in", this.setLoggedInUser);
    LoginStore.on("loginStore_user_update_successful", this.setLoggedInUser);
    LoginStore.on("change-logout", this.setLoggedInUser);

    this.updateOauthUserInfo();

    var param = this.getUrlParameter("idempotent");

    if (param) {
      if (param === "true") {
        this.advancedMode = true;
      }
    }

    AuthService.login(this.state.loggedInUser.userId, this.state.loggedInUser.password, "/account_info" + this.props.location.search, this.props.navigationHandler);
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  updateOauthUserInfo() {
    var integration = this.getUrlParameter("integration");
    var authCode = this.getUrlParameter("ac");
    var access_token = this.getUrlParameter("at");
    var refresh_token = this.getUrlParameter("rt");

    if (integration && authCode && access_token && refresh_token && this.state.loggedInUser) {
      console.log("integration: " + integration);
      console.log("auth code: " + authCode);
      console.log("access token: " + access_token);
      console.log("refresh token: " + refresh_token);
      let oauthArtifacts = new OAuthArtifacts();
      oauthArtifacts.integration = integration;
      oauthArtifacts.authorization_code = authCode;
      oauthArtifacts.access_token = access_token;
      oauthArtifacts.refresh_token = refresh_token;
      this.state.loggedInUser.oauthArtifactsDict[integration] = oauthArtifacts;

      AuthService.updateOauthArtifacts(this.state.loggedInUser, oauthArtifacts);
    }
  }

  openCredentialsModal() {
    this.setState({ showCredentialsModal: true, showSettingsModal: false });
  }

  closeCredentialsModal() {
    this.setState({ showCredentialsModal: false });
  }

  openUserInfoModal() {
    this.setState({ showUserInfoModal: true, showSettingsModal: false });
  }

  closeUserInfoModal() {
    this.setState({ showUserInfoModal: false });
  }

  openIntegrationsModal() {
    this.setState({ showIntegrationsModal: true, showSettingsModal: false });
  }

  closeIntegrationsModal() {
    this.setState({ showIntegrationsModal: false });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      imageFiles: acceptedFiles
    });
  }

  clearImage() {
    this.setState({
      imageFiles: []
    });
  }

  logout() {
    // this.refs.accountInfoMoreFunctionsOverlayTrigger.hide();
    AuthService.logout(this.state.loggedInUser.userId, this.state.loggedInUser.password)
  }


  uploadProfilePhoto() {
    AuthService.uploadProfilePhoto(this.state.loggedInUser, this.state.imageFiles[0]);
  }

  setLoggedInUser() {
    this.loggedInUser = LoginStore.getLoggedInUser();
    if (this.loggedInUser != null) {
      this.isUserLoggedIn = true;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: true,
        showUserInfoModal: false,
        imageFiles: []
      });
    }
    else {
      this.isUserLoggedIn = false;
      this.setState({
        loggedInUser: LoginStore.getLoggedInUser(),
        isUserLoggedIn: false,
        imageFiles: []
      });
    }

  }

  inviteFriend(email, firstName, lastName) {
    AuthService.inviteFriend(this.state.loggedInUser, email, firstName, lastName);
  }

  deleteFriend(friendToDelete) {
    AuthService.deleteFriend(this.state.loggedInUser, friendToDelete);
  }

  handleUsernameChange(event) {
    this.setState({username: event.target.value})
  }

  handleUPasswordChange(event) {
    this.setState({password: event.target.value})
  }

  generateFriendRows() {

    var rows = [];

    if (this.state.loggedInUser.myFriends) {
      for (var i = 0; i < this.state.loggedInUser.myFriends.length; i++) {
        rows.push(<FriendsTableRow
          key={i}
          user={this.state.loggedInUser.myFriends[i]}
          isConnected={this.state.loggedInUser.myFriends[i].isConnected}
          actionHandler={this.deleteFriend}
          icon={<Delete />}
          glyphiconStyle="default"
          confirmationTitle="Remove"
          confimationMessage={"Do you want to remove " +this.state.loggedInUser.myFriends[i].firstName +  " " + this.state.loggedInUser.myFriends[i].lastName + "?"}
          confirmationButtonText="Remove"
        />);
      }
    }

    return rows;
  }

  handleMessageChangeMethod = (evt, mc) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[mc.messaging_campaign_id].messages[0] = evt.target.value;

    this.setState({
      loggedInUser: u
    });
  }

  handlePhoneNumbersChangeMethod = (evt, mc) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[mc.messaging_campaign_id].setPhoneNumbers(evt.target.value);

    this.setState({
      loggedInUser: u
    });
  }

  handleUpdateMessagingCampaignName = (evt, user, messagingCampaign) => {
    var u = this.state.loggedInUser;

    u.messagingCampaignBucket.messaging_campaigns[messagingCampaign.messaging_campaign_id].messaging_campaign_name = evt.target.value;

    this.setState({
      loggedInUser: u
    });
  }

  getCardHeight = () => {
    var h = window.innerHeight;
    var w = window.innerWidth;
    return h >= w ? 20 : 30;
  }

  installHubspot = () => {
    // window.location.replace("https://app.hubspot.com/oauth/authorize?client_id=ecfe98cd-82a2-4d65-a0d0-903f79e11dc7&redirect_uri=http://localhost:3000/hubspot-oauth-callback&scope=contacts%20timeline%20crm.objects.contacts.read");
    window.location.replace("/install_hubspot");
  }

  installQualtrics = () => {
    window.location.replace("/install_qualtrics");
  }

  render() {


    if (this.state.loggedInUser && this.state.isUserLoggedIn === true) {
      var rows = this.generateFriendRows();

      var cardHeight = this.getCardHeight();
  
      var cardWidth = cardHeight;
      var cardHeightStyle = cardHeight + 'vh';
      var cardWidthStyle = cardWidth + 'vh';
  
      let headerRowStyle = {
        borderBottom: 2,
        borderBottomColor: '#1994fc',
        borderBottomStyle: 'solid'
      };
  
      let titleStyle = {
        color: '#53585f',
        textAlign: 'left',
        fontSize: 28,
        fontWeight: 100
      };
  
      let toolbarStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexDirection: 'row',
        height: 80,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 10,
      };
  
      let toolbarGlyphStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        height: 80,
        padding: 0,
        margin: 0
      };
  
      let colBoxStyle = {
        padding: 0,
        marginTop: 20
      }
  
      let spaceBetweenRowStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
  
      let imageStyle = {
        borderRadius: '50%',
        margin: 10,
        height: cardHeightStyle,
        width: cardHeightStyle,
        objectFit: 'cover'
      };
  
      let tableContainerStyle = {
        border: 1,
        borderColor: '#afb8c8',
        borderStyle: 'solid',
        backgroundColor: '#f7f6f7'
      };
  
      let centeredContainerDivStyle = {
        minHeight: 400,
        marginTop: 0,
        backgroundColor: '#f7f6f7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      };
  
      let imagePreviewStyle = {
        width: cardHeightStyle,
        height: 'auto',
        backgroundColor: '#ffffff',
        border: 6,
        borderStyle: 'solid',
        borderColor: '#ffffff',
        marginTop: 40,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,
        padding: 0,
        overflow: 'hidden',
        WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
        boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      };
  
      let imagePreviewContainerDivStyle = {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        height: cardHeightStyle,
        width: cardWidthStyle,
        overflow: 'hidden'
      };
  
  
      let dropzoneStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: cardWidthStyle,
        height: cardHeightStyle,
        backgroundColor: '#ffffff',
        padding: 0,
        marginTop: 40,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,
        WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
        boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      };
  
      let dropzoneActiveStyle = {
        borderStyle: 'solid',
        backgroundColor: '#eee'
      };
  
      let addContentImgDiv = {
        textAlign: 'center',
        color:'#0c34fb'
      };
  
      let addContentImageStyle = {
        width: 50,
        paddingTop: 0,
        marginBottom:20
      };
  
      let addContentInstructionsDiv = {
        color: '#0c34fb',
        fontSize: 16,
      };
  
      let thumbnailImgStyle = {
        position: 'relative',
        width: cardHeightStyle,
        minHeight: cardHeightStyle,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('" + this.state.loggedInUser.getProfilePhotoUrl() + "')",
        backgroundColor: '#ffffff',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        border: 6,
        borderStyle: 'solid',
        borderColor: '#ffffff'
      };
  
      let imagePreviewNoMarginStyle = {
        width: 'auto',
        height: cardHeightStyle,
        backgroundColor: '#ffffff',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
        boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      };
  
      var topButtonStyle = {
        color: '#53585f',
        border: 0,
        fontSize: 30,
        backgroundColor: '#ffffff',
        padding: 0,
        margin: 0,
      };
  
      let userNameDivStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        height: this.getCardHeight() + 'vh',
        margin: 10,
        padding: 0,
        color: '#53585f',
        fontSize: 20,
        fontWeight: 600
      }
  
      let buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
      }
  
      let buttonRowStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row',
        border: '2px solid green'
      }
  
      let enabledButtonStyle = {
        width: 160,
        paddingTop: 6,
        paddingBottom: 6,
        marginBottom: 6
      }
  
      let emphasisDivStyle = {
        fontSize: 20,
        fontWeight: 900
      }
  
      let infoDivStyle = {
        fontSize: 14,
        fontWeight: 400
      }
  
      return (
        <div>
          <Container>
            <Row style={headerRowStyle}>
              <Col md={10} style={toolbarStyle}>
                <div style={titleStyle}>Account</div>
              </Col>
              <Col md={2} style={toolbarGlyphStyle}>
                <Button 
                  style={topButtonStyle}
                  onClick={
                    (evt) => {
                      this.setState({
                        showSettingsModal: true,
                        anchorEl: evt.currentTarget
                      });
                    }
                  }
                >
                  <Settings style={{fontSize: 34}}/>
                </Button>
                <Popover 
                  id={"settings-popper"} 
                  open={this.state.showSettingsModal} 
                  anchorEl={this.state.anchorEl}
                  onClose={() => {
                    this.setState({showSettingsModal: false});
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  slotProps={{
                    paper: {
                      style: {
                        borderRadius: 5, // Adjust the radius to your preference
                      },
                    },
                  }}
                  >
                  <Paper sx={{ backgroundColor: '#ffffff', width: 300 }}>
                    <div className={`${classes.title}`}>{this.state.loggedInUser.firstName + " " + this.state.loggedInUser.lastName}</div>
                    <div className={`${classes.buttonContainerStyle}`}>
                      <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={this.openUserInfoModal}>
                        Edit Profile
                      </Button>
                      { this.advancedMode && 
                      <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={this.openIntegrationsModal}>
                        Integrations
                      </Button>
                      }
                      <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={this.logout}>
                        Sign Out
                      </Button>
                      <Button className={"swytchbackActiveButton " + `${classes.buttonStyle}`} onClick={this.openCredentialsModal}>
                        Reset Password
                      </Button>
                    </div>
                  </Paper>
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col md={4} style={colBoxStyle}>
                <Image style={imageStyle} src={this.state.loggedInUser.getProfilePhotoUrl()} />
              </Col>
              <Col md={8} style={colBoxStyle}>
                <div style={userNameDivStyle}>
                  <div style={emphasisDivStyle}>
                    {this.state.loggedInUser.firstName} {this.state.loggedInUser.lastName}
                  </div>
                  <div style={infoDivStyle}>
                    Email: {this.state.loggedInUser.getEmail()}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={colBoxStyle}>
                <div style={{
                  padding: 20,
                  width: '100%',
                  borderRadius: '10px',
                  WebkitBoxShadow: '1px 1px 3px 3px #ccc',  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                  MozBoxShadow: '1px 1px 3px 3px #ccc',  /* Firefox 3.5 - 3.6 */
                  boxShadow: '1px 1px 3px 3px #ccc',  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
                }}>
                  <FriendsTable rows={rows} enableInvitePeople={this.advancedMode} />
                </div>
              </Col>
            </Row>
          </Container>
          <UserInfoFormModal cardHeight={cardHeight} advancedMode={this.advancedMode} showModal={this.state.showUserInfoModal} onHideAction={this.closeUserInfoModal}/>
          <CredentialsModal showModal={this.state.showCredentialsModal} onHideAction={this.closeCredentialsModal}/>
          <IntegrationsModal user={this.state.loggedInUser} show={this.state.showIntegrationsModal} onHide={this.closeIntegrationsModal} />
        </div>
      );
    }
    else {
      return(
        <Navigate to="/guest/login" />
      )
    }
  }
}

const AccountInfoWrapper = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (destination) => {
    navigate(destination);
  }

  return <AccountInfo navigationHandler={handleNavigation} location={location} />
}

export default AccountInfoWrapper;
