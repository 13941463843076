import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { InsertDriveFile } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCodeSVG from 'qrcode.react';
import classes from '../../../css/modules/GetSurveyLinkModal.module.css';
import "../../../css/swytchbackModal.css";
import SwytchbackTextField from '../controls/SwytchbackTextField';
import SwytchbackFrameButton from '../controls/SwytchbackFrameButton';


const GetSurveyLinkModal = ({ eventInFocus, showModal, onHideAction }) => {
  const [surveyLink, setSurveyLink] = useState("");
  const [surveyLinkCopied, setSurveyLinkCopied] = useState(false);
  const [iframeTagCopied, setIframeTagCopied] = useState(false);
  const [javascriptSnippetCopied, setJavascriptSnippetCopied] = useState(false);
  const [iframeTag, setIframeTag] = useState('');
  const [javascriptSnippet, setJavascriptSnippet] = useState('');
  const [size, setSize] = useState('small');

  useEffect(() => {
    setSurveyLink(eventInFocus ? eventInFocus.generateSwydgetUrl() : "");
    setIframeTag(eventInFocus ? generateIframeTag(eventInFocus): "");
    setJavascriptSnippet(eventInFocus ? generateJavascriptSnippet(eventInFocus, "animated"): "");
  }, []);

  const handleCopySurveyLinkChanged = (event) => {
    setSurveyLink(event.target.value);
    setSurveyLinkCopied(false);
  };

  const handleCopySurveyLinkClicked = () => {
    setSurveyLinkCopied(true);
    setIframeTagCopied(false);
    setJavascriptSnippetCopied(false);
  };

  const handleCopyIframeTagClicked = () => {
    setSurveyLinkCopied(false);
    setIframeTagCopied(true);
    setJavascriptSnippetCopied(false);
  };

  const handleCopyJavascriptSnippetClicked = () => {
    setSurveyLinkCopied(false);
    setIframeTagCopied(false);
    setJavascriptSnippetCopied(true);
  };

  const generateIframeTag = (survey) => {
    return `<iframe width='400' height='600' src='${survey.generateSwydgetUrl()}' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>`;
  };

  const generateJavascriptSnippet = (survey, presentation) => {
    let snippet = `<script>
              (function (w,d,s,o,f,js,fjs) {
                w['Swydget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
              }(window, document, 'script', 'swydget', 'https://live.swytchback.com/dist/main.js'));
              swydget('display', { labelOne: '${survey.title}', url: '${survey.generateSwydgetUrl()}', delay: 0, presentation: '${presentation}' });
            </script>`;
    return snippet.replace(/\s+/g, '');
  };

  const handleSizeChange = (size) => {
    setSize(size);
  };

  const getSize = () => {
    if (size === 'medium') return 256;
    else if (size === 'large') return 512;
    return 128;
  };
  
  return (
    <Modal size="xl" show={showModal} onHide={onHideAction} backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Share/Embed</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="uncontrolled-tab-example">
            <Tab eventKey={1} title="Share">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCentredeContainer fullWidthPane shortModalHeight'>
                    <div className={`${classes.sectionContainer}`}>
                      <SwytchbackTextField
                        className={`${classes.textField}`}
                        label="Reusable Survey Link"
                        value={surveyLink}
                        onChange={handleCopySurveyLinkChanged}
                        margin="normal"
                        variant="outlined"
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.label }}
                      />
                      <CopyToClipboard text={surveyLink} onCopy={handleCopySurveyLinkClicked}>
                        <SwytchbackFrameButton style={{width: 150}}>
                          Copy Link
                        </SwytchbackFrameButton>
                      </CopyToClipboard>
                    </div>
                    <div className={`${classes.sectionContainer}`}>  
                      <SwytchbackTextField
                        className={`${classes.textField}`}
                        label="Embed Javascript Snippet"
                        value={javascriptSnippet}
                        margin="normal"
                        variant="outlined"
                      />
                      <CopyToClipboard text={javascriptSnippet} onCopy={handleCopyJavascriptSnippetClicked}>
                        <SwytchbackFrameButton style={{width: 150}}>
                          Copy Snippet
                        </SwytchbackFrameButton>
                      </CopyToClipboard>
                    </div>
                    <div className={`${classes.sectionContainer}`}>  
                      <SwytchbackTextField
                        className={`${classes.textField}`}
                        label="Embed Swydget"
                        value={iframeTag}
                        margin="normal"
                        variant="outlined"
                      />
                      <CopyToClipboard text={iframeTag} onCopy={handleCopyIframeTagClicked}>
                        <SwytchbackFrameButton style={{width: 150}}>
                          Copy IFrame Tag
                        </SwytchbackFrameButton>
                      </CopyToClipboard>
                    </div>
                    <div className={`${classes.sectionContainer}`}>
                      {surveyLinkCopied && <div style={{ color: '#1997fc', width: '100%', marginTop: 20, textAlign: 'center' }}>Survey link copied to your clipboard.</div>}
                      {iframeTagCopied && <div style={{ color: '#1997fc', width: '100%', marginTop: 20, textAlign: 'center' }}>IFrame tag copied to your clipboard.</div>}
                      {javascriptSnippetCopied && <div style={{ color: '#1997fc', width: '100%', marginTop: 20, textAlign: 'center' }}>Javascript snippet copied to your clipboard.</div>}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey={2} title="QR Code">
              <div className="fullPaneContainer">
                <div className="contentContainer">
                  <div className='columnCenteredContainer fullWidthPane standardModalHeight'>
                    <div className="widget">
                      <div className="titleContainer">
                        <div className="title">
                          QR Code
                        </div>
                      </div>                      
                    </div>
                    <div className="content buttonContainer">
                      <Button style={{ color: '#ffffff', border: 0, backgroundColor: 'transparent', padding: 0, margin: 0 }} onClick={() => handleSizeChange("small")}>
                        <InsertDriveFile style={{ fontSize: 12, margin: 2 }} />
                      </Button>
                      <Button style={{ color: '#ffffff', border: 0, backgroundColor: 'transparent', padding: 0, margin: 0 }} onClick={() => handleSizeChange("medium")}>
                        <InsertDriveFile style={{ fontSize: 26, margin: 2 }} />
                      </Button>
                      <Button style={{ color: '#ffffff', border: 0, backgroundColor: 'transparent', padding: 0, margin: 0 }} onClick={() => handleSizeChange("large")}>
                        <InsertDriveFile style={{ fontSize: 46, margin: 2 }} />
                      </Button>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <QRCodeSVG value={surveyLink} size={getSize()} />
                    </div>
                  </div>  
                </div>
              </div>
            </Tab>
          </Tabs>    
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="swytchbackActiveButton oneThird" onClick={onHideAction}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};


export default GetSurveyLinkModal;
