import React from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Switch } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, DeleteForever } from "@mui/icons-material";
import { Modal, Button, Collapse } from 'react-bootstrap';
import classes from "../css/modules/BlockTableRow.module.css";
import meStore from "../stores/MeStore";
import loginStore from "../stores/LoginStore";
import MeService from "../services/MeService";
import BlockCard from "../hooks/admin/BlockCard";
import SwytchbackTooltip from "../components/admin/SwytchbackTooltip";
import WyshTable from "../components/WyshTable";
import BlockTypes from "../classes/BlockTypes";
import ImportMaxDiffUtilityScoresModal from "../hooks/admin/ImportMaxDiffUtilityScoresModal";
import RecalcUtilityScoresModal from "../hooks/admin/RecalcUtilityScoresModal";
import SwytchbackServiceFactory from "../classes/services/SwytchbackServiceFactory";
import BarAndLineCharts from "../hooks/stats/BlockBarAndLineCharts";
import MaxDiffBlockStats from "../hooks/stats/MaxDiffBlockStats";


class BlockTableRow extends React.Component {

  constructor() {
    super();
    this.user = loginStore.getLoggedInUser();
    this.wyshOptions = [];
    this.decisionsLoadedAction = null; // export or re-calc

    this.handleParentWyshChange = this.handleParentWyshChange.bind(this);
    this.moveWyshDown = this.moveWyshDown.bind(this);
    this.moveWyshUp = this.moveWyshUp.bind(this);
    this.openRemoveWyshModal = this.openRemoveWyshModal.bind(this);
    this.closeRemoveWyshModal = this.closeRemoveWyshModal.bind(this);
    this.toggleChildTable = this.toggleChildTable.bind(this);
    this.updateWysh = this.updateWysh.bind(this);
    this.removeWysh = this.removeWysh.bind(this);
    this.wyshRemoved = this.wyshRemoved.bind(this);

    this.state = {
      parentWyshId: "no_parent_wysh",
      showRemoveWyshModal: false,
      removalFeedbackMessage: "Would you like to remove this block from the Swydget?",
      removalEnabled: false,
      removalCancelButtonLabel: "Cancel",
      deckToolTipLabel: "expand",
      responsesArray: [],
      utilityScoresArray: [],
      showImportUtilityScoresModal: false
    }
  }

  componentDidMount() {
    meStore.on("change-wyshRemoved", this.wyshRemoved);
    meStore.on("change-decisionsLoaded", this.decisionsLoaded);
    meStore.on("change-decisionsAudited", this.decisionsAudited);

    var wysh = this.props.wysh;
    if (wysh && wysh.parentWysh) {
      this.setState({ parentWyshId: wysh.parentWysh.wyshId });
    }
    else {
      this.setState({ parentWyshId: "no_parent_wysh" });
    }
  }

  componentWillUnmount() {
    meStore.removeListener("change-wyshRemoved", this.wyshRemoved);
    meStore.removeListener("change-decisionsLoaded", this.decisionsLoaded);
    meStore.removeListener("change-decisionsAudited", this.decisionsAudited);
  }

  openRemoveWyshModal(wysh) {
    this.setState({
      removalFeedbackMessage: "Would you like to remove this block from the Swydget?",
      removalEnabled: true,
      removalCancelButtonLabel: "Cancel",
      showRemoveWyshModal: true
    });
  }

  closeRemoveWyshModal() {
    this.setState({ showRemoveWyshModal: false });
  }

  toggleChildTable(wysh) {

    if (this.props.showChildTable) {
      this.props.updateBlockInFocus(null);
    }
    else {
      for (var idx in wysh.orderedWyshList.wyshes) {
        let w = wysh.orderedWyshList.wyshes[idx];
        let thisWyshId = w.wyshId;
        let nextWyshId = w.nextWysh ? w.nextWysh.wyshId : "NULL";
      }
      this.props.updateBlockInFocus(wysh);
    }

    this.setState({
      deckToolTipLabel: this.props.showChildTable ? "expand" : "collapse"
    });
  }

  determineFontSize(product, cardHeight) {
    return "1.4vh";
  }

  generateParentSelect(wysh, updateWyshesAction) {

    let allBlocks = wysh.event.getBlocks();
    if (wysh.block === true || wysh.parentWysh) {
      allBlocks = [];
    }

    // remove argument Wysh so it isn't displayed in the Select dropdown
    let pos = allBlocks.map(function (w) { return w.wyshId; }).indexOf(wysh.wyshId);
    if (pos > -1) {
      allBlocks.splice(pos, 1);
    }


    return (
      <div key="set-parent-wysh-div" className={`${classes.container}`}>
        <FormControl className={`${classes.formControl}`}>
          <Select
            className={`${classes.swytchbackSelect}`}
            id="set-block-select"
            value={this.state.parentWyshId}
            onChange={this.handleParentWyshChange(wysh, updateWyshesAction)}
          >
            <MenuItem key="no_parent_wysh" className={`${classes.swytchbackMenuItem}`} value="no_parent_wysh">None</MenuItem>
            {allBlocks.map((wysh, wyshIndex) => {
              return (
                <MenuItem key={wysh.wyshId} className={`${classes.swytchbackMenuItem}`} value={wysh.wyshId}>{wysh.caption}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    );
  }

  moveWyshDown = (wysh, updateWyshesAction) => {

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    updateWyshesAction(wysh.event, owl.moveWyshDown(wysh));
  }

  moveWyshUp = (wysh, updateWyshesAction) => {

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    updateWyshesAction(wysh.event, owl.moveWyshUp(wysh));
  }

  removeWysh = (wysh, updateWyshesAction) => {

    // this.props.updateBlockInFocus(null);

    var owl = wysh.event.orderedWyshList;
    if (wysh.parentWysh) {
      owl = wysh.parentWysh.orderedWyshList;
    }

    updateWyshesAction(wysh.event, owl.remove(wysh), wysh);
  }

  handleParentWyshChange = (wysh, updateWyshesAction) => (evt) => {

    var parentWysh = wysh.event.findWysh(evt.target.value);

    // if the parentWysh is NULL, this means we are moving the
    // wysh to the Swydget (Event's) orderedWyshList
    if (wysh.parentWysh === null && parentWysh === null) {
      // The wysh didn't have a parent and the user did not specify a parent
      // so do nothing
      return;
    }

    if (parentWysh && parentWysh.equals(wysh.parentWysh)) {
      // already in the right spot, do nothing
      return;
    }

    if (parentWysh === null && wysh.parentWysh !== null) {

      wysh.wyshRouter.clearAllBranchLogicStements();

      // The wysh was in a block and is now being moved to the Swydget level
      updateWyshesAction(wysh.event, wysh.event.orderedWyshList.add(wysh));
    }
    else if (parentWysh !== null && wysh.parentWysh === null) {

      // Wysh was at the Swydget level and is being moved down into
      // a block
      if (parentWysh.equals(wysh) === false) {
        updateWyshesAction(wysh.event, parentWysh.orderedWyshList.add(wysh));
      }
    }

    // Clear out the parentWyshId state variable because it is going to be set properly when the OrderedWyshList tree is updated.
    this.setState({ parentWyshId: "no_parent_wysh" });
  }

  updateWysh(questionType,
    scalarOptions,
    imageFile,
    question,
    attributes,
    productStyles,
    name,
    price,
    description,
    contentLink,
    freeResponseQuestion,
    binaryIconSetID,
    removeImage,
    randomize,
    issueSubGroups,
    subGroupCount,
    limitFreeResponse,
    limitFreeResponseCount,
    branchLogicStatements) {


    var wysh = this.props.wysh;

    wysh.questionType = questionType;
    wysh.setWyshOptions(scalarOptions);
    wysh.binaryIconSetID = binaryIconSetID;
    wysh.caption = question;
    wysh.product.attributes = attributes;
    wysh.product.styles = productStyles;
    wysh.product.name = name;
    wysh.product.price = price;
    wysh.product.description = description;
    wysh.product.buyPage = contentLink;

    if (freeResponseQuestion.trim() !== "") {
      // Only 1 free response question allowed at the moment
      wysh.freeResponseQuestions = [];
      wysh.freeResponseQuestions.push(freeResponseQuestion);
    }
    else {
      wysh.freeResponseQuestions = [];
    }
    wysh.randomize = randomize;
    wysh.issueSubGroups = issueSubGroups;
    wysh.subGroupCount = subGroupCount;
    wysh.limitFreeResponse = limitFreeResponse;
    wysh.limitFreeResponseCount = limitFreeResponseCount;

    wysh.wyshRouter.branchLogicStatements = branchLogicStatements;

    MeService.updateWysh(this.user, wysh.event, wysh);
  }

  wyshRemoved() {
    this.closeProductModal();
  }

  _determineQuestionType(wysh) {

    if (wysh) {
      if (wysh.questionType) {
        return wysh.questionType;
      }
    }
    else if (wysh && wysh.event) {
      if (wysh.event.surveyType) {
        return wysh.event.surveyType;
      }
    }
    else {
      return "select";
    }
  }

  _generateWyshTable(mode, sortOrder, wysh, snapshot, snapshotsToDisplay, tableRef, wyshes, swydget, handleScrollFunction, myProducts, onCheckboxChangeMethod, selectionHandler, setWordsToOmitCallback = null, setSelectedFreeResponseDecisions = null) {

    var key = "root"
    var blockPrompt = swydget.surveyName;
    var blockCardText = "";

    let rightWidgetContainerStyle = {
      padding: 5,
      marginTop: 20
    };

    var wt = <WyshTable
      myRef={tableRef}
      myProducts={myProducts}
      cardSize={this.props.cardSize}
      title={blockPrompt}
      parentWysh={wysh}
      eventInFocus={swydget}
      snapshot={snapshot}
      snapshotsToDisplay={snapshotsToDisplay}
      orderedWyshList={wysh.orderedWyshList}
      surveyTitle={swydget.title}
      mode={mode}
      sortOrder={sortOrder}
      blockCardText={wysh.product.description}
      onCheckboxChangeMethod={onCheckboxChangeMethod}
      onHandleFreeResponseQuestionChange={this.props.onHandleFreeResponseQuestionChange}
      selectionHandler={selectionHandler}
      setWordsToOmitCallback={setWordsToOmitCallback}
      setSelectedFreeResponseDecisions={setSelectedFreeResponseDecisions}
      blockPrompt={wysh.caption} />;


    return (
      <div key={key}>
        {wt}
      </div>
    );
  }

  decisionsLoaded = () => {
    // Here we want to generate the report and down load it.
    // The event has the decisions
    const bt = this.props.wysh.getBlockType();

    if (bt && (bt.equals(BlockTypes.MAXDIFF) || bt.equals(BlockTypes.PAIRWISE))) {
      this.setState({
        responsesArray: bt.generateResponsesArray(this.props.wysh),
        utilityScoresArray: bt.generateUtilityScoreArray(this.props.wysh)
      });
    }

    switch (this.decisionsLoadedAction) {
      case "export":
        this.exportBlockSpreadsheet();
        break;
      case "re-calc":
        this.openRecalcUtilityScoresModal();
        break;
      default:
        break;
    } 
  }

  decisionsAudited = () => {
    // Go get the midToMdv from the MeStore
    // Update the current responsesArray to reflect the proper Max Diff Designs
    console.log("meStore.midToMdv");
  }

  exportBlockSpreadsheet = () => {

    const fileName = this.props.wysh ? this.props.wysh.getBlockName() + "-max-diff-report" : "max-diff-report";

    const stimuliArray = []
    const stimuli = this.props.wysh.orderedWyshList.getOrderedWyshes();

    let index = 1;
    stimuliArray.push(["index", "prd_uuid", "description"]);
    for (const stimulus of stimuli) {
      stimuliArray.push([index, stimulus.product.productId, stimulus.product.description]);
      index += 1;
    }

    const mdra = this.state.responsesArray;

    for (const maxDiffResponses of mdra) {
      const mid = maxDiffResponses[0];
      const mdv = maxDiffResponses[1];

      const midToMdv = meStore.midToMdv[mid]
      if (midToMdv) {
        const correctMdv = parseInt(midToMdv, 10);
        if (correctMdv !== mdv) {
          maxDiffResponses[1] = correctMdv;
        }
      }
    }

    const wb = XLSX.utils.book_new();
    const stimuliWs = XLSX.utils.aoa_to_sheet(stimuliArray);
    const decisionsWs = XLSX.utils.aoa_to_sheet(this.state.responsesArray);
    const utilityScoresWs = XLSX.utils.aoa_to_sheet(this.state.utilityScoresArray);
    XLSX.utils.book_append_sheet(wb, stimuliWs, "Stimuli");
    XLSX.utils.book_append_sheet(wb, decisionsWs, "Decisions");
    XLSX.utils.book_append_sheet(wb, utilityScoresWs, "Utility Scores");
    XLSX.writeFile(wb, fileName + ".xlsx");
  }

  openImportMaxDiffUtilityScoresModal = () => {
    this.setState({ showImportUtilityScoresModal: true });
  }

  closeImportMaxDiffUtilityScoresModal = () => {
    this.setState({ showImportUtilityScoresModal: false });
  }

  getChart = (block, snapshot=null, snapshotsToDisplay=[]) => {
    if (block && block.isBlockType(BlockTypes.PAIRWISE) === true) {
      return (
        <BarAndLineCharts 
          block={block} 
          mode="light" 
          snapshot={snapshot}
          snapshotsToDisplay={snapshotsToDisplay}
          units=""
        />
      )
    } 
    else if (block && block.isBlockType(BlockTypes.MAXDIFF) === true) {
      return (
        <BarAndLineCharts
          block={block}
          mode="light"
          snapshot={snapshot}
          snapshotsToDisplay={snapshotsToDisplay}
          units=""
        />  
      )
    }
    else {
      return (
        <div>No chart available</div>
      )
    }
  }

  render() {

    const { productIndex } = this.props;
    const { wysh } = this.props;
    const { snapshot } = this.props;
    const { snapshotsToDisplay } = this.props;
    const { mode } = this.props;
    const { sortOrder } = this.props;
    const { selectionHandler } = this.props;
    const { updateWyshesAction } = this.props;
    const { setWordsToOmitCallback } = this.props;
    const { setSelectedFreeResponseDecisions } = this.props;
    const { selected } = this.props;
    const { onCheckboxChangeMethod } = this.props;
    const { myProducts } = this.props;
    const { isUpdating } = this.props;
    const { fullSize } = this.props;

    var binaryIconSetID = "";
    var prompt = "";
    var freeResponseQuestion = "";
    this.wyshOptions = wysh.wyshOptions;

    if (wysh) {
      prompt = wysh.caption;
      let freeResponseQuestionArray = wysh.freeResponseQuestions;
      if (freeResponseQuestionArray.length > 0) {
        freeResponseQuestion = freeResponseQuestionArray[0];
      }
      if (wysh.binaryIconSetID && wysh.binaryIconSetID !== "") {
        binaryIconSetID = wysh.binaryIconSetID;
      }
      else {
        binaryIconSetID = wysh.event.binaryIconSetID;
      }
    }

    var options = "";
    if (wysh.questionType !== "binary" && wysh.wyshOptions) {
      options = wysh.wyshOptions.length;
    }

    var textColor = "#000000";
    var textShadow = "0.0vh 0.0vh 0.5vh rgba(255,255,255,1.0)";

    if (wysh.product.styles.textShadow && wysh.product.styles.textShadow === "light") {
      textColor = "#ffffff";
      textShadow = "0.0vh 0.0vh 0.5vh rgba(0,0,0,1.0)";
    }

    let tdStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
      paddingTop: 20,
      paddingBottom: 20,
      border: 0
    }

    let leftTdStyle = {
      textAlign: 'left',
      verticalAlign: 'middle',
      border: 0
    };

    let trStyle = {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    };

    let trSelectedStyle = {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7',
      backgroundColor: '#e0e5ee'
    };

    let buttonStyle = {
      backgroundColor: '#ffffff',
      color: '#a7aaa9',
      border: 0,
      width: 50,
      marginBottom: 3
    }

    let divColumnStyle = {
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }


    let productIndexDivStyle = {
      margin: 5
    }

    var rowStyle = trStyle;
    if (selected === true) {
      rowStyle = trSelectedStyle;
    }

    var freeResponseLabel = "None";
    if (wysh.freeResponseQuestions.length > 0) {
      freeResponseLabel = wysh.freeResponseQuestions[0];
    }

    var skipRulesPresentLabel = "no";
    if (wysh.wyshRouter.branchLogicStatements.length > 0) {
      skipRulesPresentLabel = "yes";
    }

    var wyshTypeLabel = "binary";
    if (wysh.isBlock() === true) {
      wyshTypeLabel = "BLOCK";
    }
    else if (wysh.questionType === "scalar") {
      wyshTypeLabel = wysh.questionType + ": " + options;
    }

    switch (mode) {
      case "active":
        // ACTIVE: must have 5 <TD>
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.toggleChildTable(wysh)}>
                <BlockCard mode={mode} fullSize={fullSize} cardSize={this.props.cardSize} />
              </td>
              <td style={tdStyle}>Q{productIndex + 1}</td>
              <td style={leftTdStyle}>
                <div className="productTableRow_cellContainer" style={{marginBottom: 20}}>
                  <div className="productTableRow_cellTopStyle">
                    Block name:
                  </div>
                  <div className="productTableRow_cellBottomStyle">
                    {wysh.getBlockName()}
                  </div>
                </div>
                <div className="productTableRow_cellContainer" style={{marginBottom: 20}}>
                  <div className="productTableRow_cellTopStyle">
                    Number of Cards:
                  </div>
                  <div className="productTableRow_cellBottomStyle">
                    {wysh.orderedWyshList.wyshes.length}
                  </div>
                </div>
              </td>
              <td style={tdStyle} colSpan={2}>
                {this.getChart(wysh, snapshot, snapshotsToDisplay)}
              </td>
            </tr>
            <tr style={trStyle}>
              <td colSpan="5">
                <div style={{ paddingLeft: 30 }}>
                  <Collapse in={this.props.showChildTable} timeout={1000}>
                    <div id="example-collapse-text">
                      {this._generateWyshTable(mode, sortOrder, wysh, snapshot, snapshotsToDisplay, null, wysh.orderedWyshList.getOrderedWyshes(), wysh.event, null, myProducts, null, null, setWordsToOmitCallback, setSelectedFreeResponseDecisions)}
                    </div>
                  </Collapse>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      case "draft":
        // DRAFT: must have 6 <TD>
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.toggleChildTable(wysh)}>
                <SwytchbackTooltip title={this.state.deckToolTipLabel} placement="top">
                  <div>
                    <BlockCard mode={mode} fullSize={fullSize} cardSize={this.props.cardSize} />
                  </div>
                </SwytchbackTooltip>
              </td>
              <td style={tdStyle}>
                <Button style={buttonStyle} disabled={isUpdating} onClick={() => this.moveWyshUp(wysh, updateWyshesAction)}><KeyboardArrowUp /></Button>
                <div style={productIndexDivStyle}>{productIndex + 1}</div>
                <Button style={buttonStyle} disabled={isUpdating} onClick={() => this.moveWyshDown(wysh, updateWyshesAction)}><KeyboardArrowDown /></Button>
              </td>
              <td style={leftTdStyle}>
                <div className={`${classes.cellContainer}`}>
                  <div className={`${classes.cellTopStyle}`}>
                    Block Name:
                  </div>
                  <div className={`${classes.cellBottomStyle}`}>
                    {wysh.getBlockName()}
                  </div>
                </div>
              </td>
              <td style={leftTdStyle}>
                <div className={`${classes.cellContainer}`}>
                  <div className={`${classes.cellTopStyle}`}>
                    Number of Cards:
                  </div>
                  <div className={`${classes.cellBottomStyle}`}>
                    {wysh.orderedWyshList.wyshes.length}
                  </div>
                </div>
                <div className={`${classes.cellContainer}`}>
                  <div className={`${classes.cellTopStyle}`}>
                    Block Type:
                  </div>
                  <div className={`${classes.cellBottomStyle}`}>
                    {wysh.getBlockType() ? wysh.getBlockType().name : "legacy"}
                  </div>
                </div>
              </td>
              <td style={tdStyle}>
                <div className={`${classes.cellContainer}`}>
                  <div className={`${classes.cellBottomStyle}`}>
                    <Button
                      variant="contained"
                      onClick={() => this.openRemoveWyshModal(wysh)}
                    >
                      <DeleteForever sx={{ width: '40px', height: '40px' }} className={`${classes.removeButtonStyle}`} />
                    </Button>  
                    
                  </div>
                </div>
              </td>
              <Modal show={this.state.showRemoveWyshModal} onHide={this.closeRemoveWyshModal} backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title>Remove Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.removalFeedbackMessage}
                </Modal.Body>
                <Modal.Footer>
                  <Button className="swytchbackActiveButton" style={{ marginLeft: 10, width: 100 }} onClick={this.closeRemoveWyshModal}>{this.state.removalCancelButtonLabel}</Button>
                  {this.state.removalEnabled === true &&
                    <Button className="swytchbackAlertButton" style={{ marginLeft: 10, width: 100 }} onClick={() => this.removeWysh(wysh, updateWyshesAction)}>Remove</Button>
                  }
                </Modal.Footer>
              </Modal>
            </tr>
            <tr style={trStyle}>
              <td colSpan="5">
                <div style={{ paddingLeft: 30 }}>
                  <Collapse in={this.props.showChildTable} timeout={1000}>
                    <div id="example-collapse-text">
                      {this._generateWyshTable(mode, sortOrder, wysh, snapshot, snapshotsToDisplay, null, wysh.orderedWyshList.getOrderedWyshes(), wysh.event, null, myProducts)}
                    </div>
                  </Collapse>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      case "filter-content":
        // FILTER-CONTENT: must have 3 <TD>
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.toggleChildTable(wysh)}>
                <SwytchbackTooltip title={this.state.deckToolTipLabel} placement="top">
                  <div>
                    <BlockCard mode={mode} fullSize={fullSize} cardSize={this.props.cardSize} />
                  </div>
                </SwytchbackTooltip>
              </td>
              <td style={tdStyle}>Q{productIndex + 1}</td>
              <td style={leftTdStyle}>{wysh.getBlockName()}</td>
              <td style={tdStyle}>
                <Switch
                  checked={wysh.event.filter.isBlockSelected(wysh)}
                  color="primary"
                  onChange={() => selectionHandler(productIndex, wysh)}
                />
              </td>
            </tr>
            <tr style={trStyle}>
              <td colSpan="4">
                <div style={{ paddingLeft: 30 }}>
                  <Collapse in={this.props.showChildTable} timeout={1000}>
                    <div id="example-collapse-text">
                      {this._generateWyshTable(mode, sortOrder, wysh, snapshot, snapshotsToDisplay, null, wysh.orderedWyshList.getOrderedWyshes(), wysh.event, null, myProducts, onCheckboxChangeMethod, selectionHandler)}
                    </div>
                  </Collapse>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      case "filter-respondents":
        // FILTER-RESPONDENTS: must have 3 <TD>
        return (
          <React.Fragment>
            <tr>
              <td style={tdStyle} onClick={() => this.toggleChildTable(wysh)}>
                <SwytchbackTooltip title={this.state.deckToolTipLabel} placement="top">
                  <div>
                    <BlockCard mode={mode} fullSize={fullSize} cardSize={this.props.cardSize} />
                  </div>
                </SwytchbackTooltip>
              </td>
              <td style={tdStyle}>Q{productIndex + 1}</td>
              <td style={leftTdStyle}>{wysh.getBlockName()}</td>
              <td style={leftTdStyle}>
                <Switch
                  checked={wysh.event.filter.isBlockSelected(wysh)}
                  color="primary"
                  onChange={() => selectionHandler(productIndex, wysh)}
                />
              </td>
            </tr>
            <tr style={trStyle}>
              <td colSpan="4">
                <div style={{ paddingLeft: 30 }}>
                  <Collapse in={this.props.showChildTable} timeout={1000}>
                    <div id="example-collapse-text">
                      {this._generateWyshTable(mode, sortOrder, wysh, snapshot, snapshotsToDisplay, null, wysh.orderedWyshList.getOrderedWyshes(), wysh.event, null, myProducts, onCheckboxChangeMethod, selectionHandler)}
                    </div>
                  </Collapse>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      default:
        // DEFAULT: must have 5 <TD>
        return (
          <tr style={trStyle}>
            <td style={tdStyle}></td>
            <td style={tdStyle}></td>
            <td style={leftTdStyle}></td>
            <td style={tdStyle}></td>
            <td style={tdStyle}></td>
          </tr>
        );
    }
  }
}

export default BlockTableRow;
