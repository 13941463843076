import React from 'react';
import { Table } from 'react-bootstrap';

const CompletionInfo = ({swydget, snapshot=null}) => {

  const theadStyle = {
    backgroundColor: '#e0e5ee',
    color: '#53585f',
    fontSize: 14,
    fontWeight: 400 // Moved from theadsStyleTd, as it's applied to thead
  };

  const tableStyle = {
    backgroundColor: '#ffffff',
    margin: 0,
    padding: 0
  };

  const tdLabelStyle = {
    fontWeight: 700
  };

  const tdStyle = {}; // Empty styles

  return (
    <div>
      <Table style={tableStyle} responsive>
        <thead style={theadStyle}>
          <tr>
            <td style={theadStyle}>Completions</td>
            <td style={theadStyle}>Completion Pct.</td>
            <td style={theadStyle}>First Completion Date</td>
            <td style={theadStyle}>Last Completion Date</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tdStyle}>{swydget.completionCount}</td>
            <td style={tdStyle}>{(swydget.completionPct * 100).toFixed(2)} %</td>
            <td style={tdStyle}>{swydget.getFirstCompletionDateStr()}</td>
            <td style={tdStyle}>{swydget.getLastCompletionDateStr()}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default CompletionInfo;
