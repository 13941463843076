import Filter from "./Filter";
import SwydgetCompletionInfo from "./SwydgetCompletionInfo";
import Decision from "./Decision";

export default class Snapshot
{
  constructor(snapshotContainer) {
    this.snapshotContainer = snapshotContainer;
    this.completionInfo = null;
    this.filter = null;
    this.created = 0;
    this.name = "";
    this.color = "#0059ff";
  }

  getCreatedDate() {
    return new Date(this.created*1000);
  }

  getCreatedDateStr() {
    return Decision.formatDateAndTime(this.getCreatedDate());
  }

  toJson() {
    return {
      completion_info: this.completionInfo ? this.completionInfo.toJson() : {},
      filter: this.filter ? this.filter.toJson() : {},
      created: this.created,
      name: this.name,
      color: this.color
    };
  }

  equals(snapshot) {
    if (snapshot) {
      if (this.created === snapshot.created) {
        return true;
      }
    }
    return false;
  }

  static createFromJson(json, snapshotContainer) {
    var snapshot = new Snapshot(snapshotContainer);

    if (json.filter) {
      snapshot.filter = Filter.createFromJson(json.filter, snapshotContainer.swydget);
    }

    if (json.created) {
      snapshot.created = json.created;
    }

    if (json.name) {
      snapshot.name = json.name;
    }

    if (json.color) {
      snapshot.color = json.color;
    }

    if (json.completion_info) {
      snapshot.completionInfo = SwydgetCompletionInfo.createFromJson(json.completion_info, snapshotContainer.swydget);
    }

    return snapshot;
  }
}
