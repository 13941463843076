export default class FilterWyshWrapper
{
  constructor(wysh, selections) {
    this.wysh = wysh;
    this.selections = selections;
  }

  toJson() {
    var json = {};

    json.wyshId = this.wysh.wyshId;
    json.productId = this.wysh.product.productId;
    json.selections = this.selections;

    return json;
  }
}
