import React from "react";
import { Image }  from 'react-bootstrap';
import swytchbackLogo from '../../SwytchbackLogoBox-500.png';
import swytchbackLogoBorder from '../../SwytchbackLogoBorder.png';


class SwydgetNotAvailable extends React.Component {

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back&#8480;</span></span>;
  }

  render() {

    const { height } = this.props;

    var ht = 100;

    if (height) {
      ht = height;
    }

    var imageWidthAndHeight = ht * 0.26;
    var imageWidthAndHeightStyle = imageWidthAndHeight + 'vh';
    var messageImageUrl = swytchbackLogo;



    let containerDivStyle = {
      height: ht + 'vh',
      backgroundColor: '#0095ff',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: -10
    };

    let logoContainerDivStyle = {
      height: ht*0.26 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: imageWidthAndHeightStyle,
      minHeight: imageWidthAndHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + messageImageUrl + "')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    }

    let titleDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      height: ht*0.12 + 'vh',
      color: '#fff',
      fontSize: 18,
      fontWeight: 'bold',
      padding: 10,
      zIndex: 10
    };

    let completionImageDivStyle = {
      height: imageWidthAndHeightStyle,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 300
    };

    let swytchbackLogoStyle = {
      margin: 10,
      height: 30,
      width: 'auto',
      zIndex: 10
    };

    return (
      <div style={containerDivStyle}>

          <div style={titleDivStyle}>Sorry, this Swydget is no longer available.</div>
          <div key={0} style={completionImageDivStyle}>
            <div style={thumbnailImgStyle}>

            </div>
          </div>
          <div style={logoContainerDivStyle}>
            <div style={completionDivContainerStyle}>
              <div>
                powered by
              </div>
            </div>
            <div style={completionDivContainerStyle}>
              <Image style={swytchbackLogoStyle} src={swytchbackLogoBorder} responsive="true"/>
            </div>
            <div style={completionDivContainerStyle}>
              <div>
                {this.generateLogo()}
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default SwydgetNotAvailable;
