import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import swytchbackStyles from "../../css/swytchbackStyles.css";
import swytchbackModal from "../../css/swytchbackModal.css";
import MeService from "../../services/MeService";
import * as Utilities from "../../services/Utilities";
import useHttpGet from "../../hooks/utilities/AxiosHttpGet";
import useHttpPost from "../../hooks/utilities/AxiosHttpPost";
import CopyToClipboard from "react-copy-to-clipboard";
import SwytchbackFrameButton from "./controls/SwytchbackFrameButton";


export default function SwydgetTakersModal(props) {

  const [instruction, setInstruction] = useState("Click Generate Winner...");
  const [takersArray, setTakersArray] = useState([]);
  const [winner, setWinner] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const { getData, getLoading, getError, get } = useHttpGet(props.user);
  const { postData, postLoading, postError, post } = useHttpPost(props.user);
  const newServerUrl = MeService.cloneServerUrl + ":" + MeService.wm_new_port;

  const close = () => {
    props.handleClose()
    setInstruction("Click Generate Winner...")
    setWinner(null)
    setTakersArray([])
    setIsFetching(false)
  }

  const generateWinner = () => {
    setIsFetching(true);
    var eventIdArg = props.swydget.eventId;
    if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
      eventIdArg += "#";
    }

    const endpoint = "/1/events/event/" + eventIdArg + "/takers/";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    const clearText = props.user.userId + ":" + props.user.password;
    const base64String = "Basic " + btoa(clearText);
    var config = {
      headers: {'Authorization': base64String}
    };

    get(newServerUrl + encodedEndpoint, getSurveyTakersCallback)
  }

  const flushEmails = () => {
    setIsFetching(true);
    var eventIdArg = props.swydget.eventId;
    if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
      eventIdArg += "#";
    }

    const endpoint = "/1/events/event/" + eventIdArg + "/takers/flush_taker_data";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    const clearText = props.user.userId + ":" + props.user.password;
    const base64String = "Basic " + btoa(clearText);
    var config = {
      headers: {'Authorization': base64String}
    };

    post(newServerUrl + encodedEndpoint, {}, flushSwydgetTakerEmailsCallback)
  }

  const flushSwydgetTakerEmailsCallback = (data) => {
    close()
  }

  const getSurveyTakersCallback = (data) => {
    if (data && data.results && data.results.survey_takers) {
      // Create an array of emails

      const validTakers = [];

      for (const mid in data.results.survey_takers) {
        if (data.results.survey_takers[mid] && data.results.survey_takers[mid].svt_data_json) {
          const taker_keys = data.results.survey_takers[mid].svt_data_json.keys;
          if (taker_keys.email) {
            validTakers.push(taker_keys);
          }
        }
      }

      const randomNumber = Math.floor(Math.random() * validTakers.length);

      setIsFetching(false);
      setTakersArray(validTakers);
      if (validTakers.length === 0) {
        setInstruction("No taker with a valid email found.");
      }
      else {
        setWinner(validTakers[randomNumber]);
      }
    }  
    else {
      setIsFetching(false);
      setInstruction("No taker with a valid email found.");
    }
  }

  return (
    <Modal 
      size="lg"
      show={props.show} 
      onHide={close} 
      enforceFocus={false} 
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">
          <div className="feedbackContainer"></div>
          <div>Generate the Swydget Winner!</div>
          <div className="feedbackContainer">
          {isFetching &&
            <CircularProgress size={20}/>
          }
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="rowContainerSpaced swytchbackGradientPane" style={{padding: 20, color: '#ffffff', fontSize: 16}}>
          <div>
            {winner ? "Winner: " + winner.email[0]['v'] : instruction}
          </div>
          <div>
            <CopyToClipboard text={winner ? winner.email[0]['v'] : instruction}>
              <SwytchbackFrameButton style={{width: 150}}>
                Copy Link
              </SwytchbackFrameButton>
            </CopyToClipboard>
          </div>  
        </div>  
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className="swytchbackAlertButton" style={{marginLeft: 10, width: 140}} onClick={flushEmails}>Flush Emails</Button>
        <Button className="swytchbackActiveButton" style={{marginLeft: 10, width: 200}} onClick={generateWinner}>Generate Winner</Button>
      </Modal.Footer>
    </Modal>
  );
}