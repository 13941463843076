import React, { useState } from "react";
import { Table, Button, FormControl  } from 'react-bootstrap';
import MeService from '../../services/MeService';
import "../../css/swydgetsTable.css";
import { ChevronLeft, ChevronRight, Refresh } from "@mui/icons-material";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";


export default function SwydgetsTable(props) {

  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState("");
  const [ownerFilter, setOwnerFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("edited-recent");

  const { myEventsCurrentWorkspaceTotalSize, user, rows, workspace, itemLabel, itemsLabel } = props;

  const pageForward = () => {
    var p = myEventsCurrentWorkspaceTotalSize > page * 10 ? page + 1 : page;

    if (p > page) {
      var first = 0 + (p - 1) * 10;
      var last = 9 + (p - 1) * 10;
      setPage(p);
      MeService.getEvents(user, user.password, workspace, sortOrder, first, last, filterTerm, ownerFilter);
    }
  };

  const pageBackward = () => {
    var p = page > 1 ? page - 1 : 1;

    if (p < page) {
      var first = 0 + (p - 1) * 10;
      var last = 9 + (p - 1) * 10;
      setPage(p);
      MeService.getEvents(user, user.password, workspace, sortOrder, first, last, filterTerm, ownerFilter);
    }
  }

  const refresh = () => {
    var first = 0 + (page - 1) * 10;
    var last = 9 + (page - 1) * 10;
    MeService.getEvents(user, user.password, workspace, sortOrder, first, last, filterTerm, ownerFilter);
  }

  const getCurrentPageLabel = () => {
    var first = 1 + (page - 1) * 10;
    var last = 10 + (page - 1) * 10;
    if (last > myEventsCurrentWorkspaceTotalSize) {
      last = myEventsCurrentWorkspaceTotalSize;
    }

    return first + "-" + last + " of " + myEventsCurrentWorkspaceTotalSize;
  }

  const handleFilterTermChange = (evt) => {
    setPage(1)
    setFilterTerm(evt.target.value)
    var first = 0;
    var last = 9;
    MeService.getEvents(user, user.password, workspace, sortOrder, first, last, evt.target.value, ownerFilter);
  }

  const handleOwnerFilterChange = (evt) => {
    setPage(1)
    setOwnerFilter(evt.target.value)
    var first = 0;
    var last = 9;
    MeService.getEvents(user, user.password, workspace, sortOrder, first, last, filterTerm, evt.target.value);
  }

  const handleSortChange = (evt) => {
    setPage(1)
    setSortOrder(evt.target.value);
    var first = 0;
    var last = 9;
    MeService.getEvents(user, user.password, workspace, evt.target.value, first, last, filterTerm, ownerFilter);
  }

  var label = itemsLabel;
  if (rows.length === 1) {
    label = itemLabel;
  }

  let tableContainerStyle = {
    backgroundColor: '#f7f6f7'
  };

  let theadStyle = {
    backgroundColor: '#e0e5ee',
    color: '#53585f',
    fontSize: 14
  };

  let theadStyleTr = {
    height: 44
  };

  let theadsStyleTd = {
    fontWeight: 400
  }

  let tableStyle = {
    backgroundColor: '#ffffff',
    margin: 0
  }

  return (
    <div className="swydgetsTable">
      <div className="filterControlDiv">
        <div className="searchNameFilterDiv">
          <label className="controlLabelStyle">SEARCH SWYDGETS</label>
          <TextField
            className="controlStyle"
            variant="outlined"
            placeholder="Search for Swydgets by name"
            value={filterTerm}
            onChange={(evt) => {
              handleFilterTermChange(evt)
            }}
          />
        </div>
        <div className="ownerFilterDiv">
          <InputLabel id="filter-by-owner-label" className="controlLabelStyle">FILTER BY OWNER</InputLabel>
          <Select 
            className="controlStyle"
            labelId="filter-by-owner-label"
            id="filter-by-owner-select"
            value={ownerFilter}
            onChange={(evt) => { handleOwnerFilterChange(evt) }}
            placeholder="select">
            <MenuItem className="swytchbackMenuItem" value="all">All</MenuItem>
            <MenuItem className="swytchbackMenuItem" value="mine">Me</MenuItem>
            <MenuItem className="swytchbackMenuItem" value="shared">Shared</MenuItem>
          </Select>
        </div>
        <div className="sortFilterDiv">
          <InputLabel id="sort-by-label" className="controlLabelStyle">SORT BY</InputLabel>
          <Select
            className="controlStyle"
            labelId="sort-by-label"
            id="sort-by-select"
            value={sortOrder}
            onChange={(evt) => { handleSortChange(evt) }}
            placeholder="select">
            <MenuItem className="swytchbackMenuItem" value="edited-recent">Date Edited: Most Recent</MenuItem>
            <MenuItem className="swytchbackMenuItem" value="edited-oldest">Date Edited: Oldest</MenuItem>
            <MenuItem className="swytchbackMenuItem" value="created-recent">Date Created: Most Recent</MenuItem>
            <MenuItem className="swytchbackMenuItem" value="created-oldest">Date Created: Oldest</MenuItem>
          </Select>
        </div>
      </div>
      <div className="topToolbar">
        <div className="leftContentContainer">
          <div>
            {workspace}: {myEventsCurrentWorkspaceTotalSize} {label}
          </div>
          <div>
            <Button variant="secondary" className="glyphButtonStyle" onClick={refresh}><Refresh /></Button>
          </div>
        </div>
        <div className="rightContentContainer">
          <div>
            {getCurrentPageLabel()}
          </div>
          <Button variant="secondary" className="glyphButtonStyle" onClick={pageBackward}><ChevronLeft /></Button>
          <Button variant="secondary" className="glyphButtonStyle" onClick={pageForward}><ChevronRight /></Button>
        </div>
      </div>
      <div style={tableContainerStyle}>
        <Table style={tableStyle} responsive>
          <thead style={theadStyle}>
            <tr style={theadStyleTr}>
              <th style={theadsStyleTd}>Swydget</th>
              <th style={theadsStyleTd}></th>
              <th style={theadsStyleTd}>Owner</th>
              <th style={theadsStyleTd}>Last Edited</th>
              <th style={theadsStyleTd}>Completions</th>
              <th style={theadsStyleTd}>Last Complete</th>
            </tr>
          </thead>
          <tbody>
            { rows }
          </tbody>
        </Table>
      </div>
    </div>
  );
}
