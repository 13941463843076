import React from "react";
import { ProgressBar } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import classes from '../../css/modules/ScalarResults.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// TODO: Rename this to AggregatedResults

class ScalarResults extends React.Component {

  _generateHorizontalChart(wysh, snapshot = null) {

    const wyshTakerCount = wysh.takerCount;
    const borderColor = this.props.mode ? (this.props.mode === "dark" ? "#D5D5D5" : "#D5D5D5") : "#D5D5D5";
    const textColor = this.props.mode ? (this.props.mode === "dark" ? "#ffffff" : "#000000") : "#000000";
    const backgroundColor = this.props.mode ? (this.props.mode === "dark" ? "#0168EC" : "#0168EC") : "#0168EC";



    const options = {
      indexAxis: 'y',
      elements: {
        bar: {

        },
      },
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColor,
            callback: function (value) {
              return this.getLabelForValue(value)
              // return this.getLabelForValue(value).substring(0, 20)
            }

          },
          grid: {
            color: borderColor, // Grid line color
            borderColor: 'red', // Axis line color
          }
        },
        x: {
          beginAtZero: true,
          grid: {
            color: borderColor, // Grid line color
            borderColor: 'red', // Axis line color
          },
          ticks: {
            color: textColor,
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += context.raw; // Use context.parsed.x for horizontal bars
              label += '%'; // Append your custom text here
              return label;
            }
          }
        },
        title: {
          display: false,
          text: 'Swytchback Rocks',
        },
      },
    };

    const labels = wysh.wyshOptions.map((option) => {
      return option.resultLiteral + " (" + option.decisionsCount + ")";
    });

    const dataArray = wysh.wyshOptions.map((option) => {
      const percentage = option.decisionsCount > 0 ? 100 * option.decisionsCount / wyshTakerCount : 0.0;
      return percentage.toFixed(1);
    });


    const data = {
      labels,
      datasets: [
        {
          data: dataArray,
          borderColor: borderColor,
          backgroundColor: backgroundColor
        }
      ]
    };

    return (
      <Bar options={options} data={data} />
    );
  }




  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this._generateHorizontalChart(wysh, snapshot)}
      </div>
    );
  }
}

export default ScalarResults;
