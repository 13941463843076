import SwytchbackService from "./SwytchbackService";
import SwytchbackServiceFeedback from "./SwytchbackServiceFeedback";
import SwytchbackServiceEngagement from "./SwytchbackServiceEngagement";
import SwytchbackServiceResearch from "./SwytchbackServiceResearch";
import SwytchbackServiceInternal from "./SwytchbackServiceInternal";

export default class SwytchbackServiceFactory
{

  static FEEDBACK = new SwytchbackServiceFeedback("service_feedback");
  static ENGAGEMENT = new SwytchbackServiceEngagement("service_engagement");
  static RESEARCH = new SwytchbackServiceResearch("service_research");
  static INTERNAL = new SwytchbackServiceInternal("service_internal");

  static getAllServices() {

    const AuthorizedServices = {
      FEEDBACK: new SwytchbackServiceFeedback("service_feedback"),
      ENGAGEMENT: new SwytchbackServiceEngagement("service_engagement"),
      RESEARCH: new SwytchbackServiceResearch("service_research"),
      INTERNAL: new SwytchbackServiceInternal("service_internal")
    }

    return  [
              AuthorizedServices.FEEDBACK,
              AuthorizedServices.ENGAGEMENT,
              AuthorizedServices.RESEARCH,
              AuthorizedServices.INTERNAL
            ];
  }

  static getService(serviceId) {

    const AuthorizedServices = {
      FEEDBACK: new SwytchbackServiceFeedback("service_feedback"),
      ENGAGEMENT: new SwytchbackServiceEngagement("service_engagement"),
      RESEARCH: new SwytchbackServiceResearch("service_research"),
      INTERNAL: new SwytchbackServiceInternal("service_internal")
    }

    var service = SwytchbackService

    switch (serviceId) {
      case "service_feedback":
        service = AuthorizedServices.FEEDBACK
        break;
      case "service_engagement":
          service = AuthorizedServices.ENGAGEMENT;
          break;
      case "service_research":
          service = AuthorizedServices.RESEARCH;
          break;
      case "service_internal":
          service = AuthorizedServices.INTERNAL;
          break;

      default:

    }

    return service;
  }
}

export const AuthorizedServices = {
  FEEDBACK: new SwytchbackServiceFeedback("service_feedback"),
  ENGAGEMENT: new SwytchbackServiceEngagement("service_engagement"),
  RESEARCH: new SwytchbackServiceResearch("service_research"),
  INTERNAL: new SwytchbackServiceInternal("service_internal")
}
