import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import "../../../css/swytchbackStyles.css";
import "../../../css/swytchbackModal.css";

const DeleteSwydgetModal = ({showDeleteSwydgetModal, closeDeleteSwydgetModal, swydgetName, handleDeleteSwydgetClicked}) => {

    return (
      <Modal
        show={showDeleteSwydgetModal} 
        onHide={closeDeleteSwydgetModal} 
        enforceFocus={false} 
        backdrop="static"
      >
        <Modal.Header className="swytchbackModalHeader" closeButton>
          <Modal.Title className="swytchbackModalTitle">Delete Swydget?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="swytchbackModalBody coloredBackground">
          <div className="hookFullPaneContainer swytchbackGradientPane" style={{padding: 20, color: '#ffffff', fontSize: 16}}>
            <div className="fullPaneContainer">
              <div className="contentContainer">
                <div className='rowContainerSpaced fullWidthPane shortModalHeight'>
                  {swydgetName}
                </div>
              </div>
            </div>      
          </div>  
        </Modal.Body>
        <Modal.Footer className="swytchbackModalFooter">
          <Button className="swytchbackActiveButton" style={{width: 100}} onClick={closeDeleteSwydgetModal}>Cancel</Button>
          <Button className="swytchbackAlertButton" style={{marginLeft: 10, width: 100}} onClick={handleDeleteSwydgetClicked}>DELETE</Button>
        </Modal.Footer>
      </Modal>
    );
};

export default DeleteSwydgetModal;

