import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';

const FriendsTableRow = ({ user, icon, glyphiconStyle, isConnected, actionHandler, actionDisabled, confirmationTitle, confimationMessage, confirmationButtonText }) => {
  const [showActionModal, setShowActionModal] = useState(false);

  const openActionModal = () => {
    setShowActionModal(true);
  };

  const closeActionModal = () => {
    setShowActionModal(false);
  };

  const onExecution = (actionHandler, user) => {
    actionHandler(user);
    closeActionModal();
  };

  if (user.lastName === "Bower") {
    console.log(user);
  }

  var cardHeight = 7;
  var cardHeightStyle = cardHeight + 'vh';

  let cardStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: cardHeightStyle,
    height: cardHeightStyle,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundImage: "url('" + user.getProfilePhotoUrl() + "')",
    backgroundColor: '#fffddd',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    marginBottom: 0
  };

  let trStyle = {
    height: 100,
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#b7b7b7'
  }

  let tdStyle = {
    textAlign: 'center',
    verticalAlign: 'middle',
    background: 'none'
  }

  let nameTdStyle = {
    textAlign: 'left',
    verticalAlign: 'middle',
    background: 'none',
    color: '#ffffff'
  }

  let tdButtonStyle = {
    textAlign: 'center',
    verticalAlign: 'middle',
    background: 'none'
  }

  let buttonStyle = {
    width: 50,
    height: 50,
    border: 1,
    borderStyle: 'solid',
    borderColor: '#53585f',
    borderRadius: '50%',
    marginTop: 0,
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0
  }

  var userLabel = user.firstName + " " + user.lastName;
  if (isConnected === false) {
    userLabel += " (friendship pending)";
  }

  return (
    <tr style={trStyle}>
      <td style={tdStyle}><div style={cardStyle}></div></td>
      <td style={nameTdStyle}>{userLabel}</td>
      <td style={tdButtonStyle}>
        <Button style={buttonStyle} disabled={actionDisabled} onClick={openActionModal}>{icon}</Button>
      </td>
      <Modal show={showActionModal} onHide={closeActionModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{confirmationTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confimationMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={() => onExecution(actionHandler, user)}>{confirmationButtonText}</Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default FriendsTableRow;
