import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import EmailIcon from '@mui/icons-material/Email';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import classes from "../../../css/modules/BuildModals.module.css";
import "../../../css/swytchbackModal.css"
import "../../../css/swytchbackStyles.css"
import SocialSharingChannel from "../../../components/SocialSharingChannel";
import SwydgetComplete from '../../../components/swydget/SwydgetComplete';
import { Switch } from '@mui/material';
import SwydgetContestEntry from '../../../components/swydget/SwydgetContestEntry';
import CardEditor from '../../../components/admin/CardEditor';
import ImageUploadDialog from './ImageUploadDialog';

const LookAndFeelModal = ({
  showLookAndFeelModal,
  closeLookAndFeelModal,
  stimulus, 
  imageUrl, 
  imageFiles, 
  onDropMethod, 
  handleDescriptionChangeMethod, 
  handleThemeRadioBoxChangeMethod,
  handleJustificationChangeMethod,
  handleVerticalAlignChangeMethod,
  handleFontSizeChangeMethod,
  handleColorPickerChangeMethod,
  handleFontFamilyChangeMethod,
  handleIsBoldChangeMethod,
  handleIsItalicizedChangeMethod,
  handleObjectFitChangeMethod,
  resetImageMethod,
  wallpaperImageFiles,
  wallpaperUrl,
  onWallpaperDrop,
  clearWallpaperImage,
  previewTitle,
  handlePreviewTitleChange,
  previewDescription,
  handlePreviewDescriptionChange,
  handleApplyLookAndFeelClicked
}) => {

  
  return (

    <Modal
      onHide={closeLookAndFeelModal}
      size="xl"
      show={showLookAndFeelModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Look & Feel</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="look-and-feel-tabs">
            <Tab eventKey={1} title="Card">
              <div className="columnCenteredContainer">
                <CardEditor
                  readOnly={false}
                  stimulus={stimulus}
                  sampleCardText="Card Text"
                  productLink={imageUrl}
                  imageFiles={imageFiles}
                  onDropMethod={onDropMethod}
                  handleDescriptionChangeMethod={handleDescriptionChangeMethod}
                  handleThemeRadioBoxChangeMethod={handleThemeRadioBoxChangeMethod}
                  handleJustificationChangeMethod={handleJustificationChangeMethod}
                  handleVerticalAlignChangeMethod={handleVerticalAlignChangeMethod}
                  handleFontSizeChangeMethod={handleFontSizeChangeMethod}
                  handleColorPickerChangeMethod={handleColorPickerChangeMethod}
                  handleFontFamilyChangeMethod={handleFontFamilyChangeMethod}
                  handleIsBoldChangeMethod={handleIsBoldChangeMethod}
                  handleIsItalicizedChangeMethod={handleIsItalicizedChangeMethod}
                  handleObjectFitChangeMethod={handleObjectFitChangeMethod}
                  resetImageMethod={resetImageMethod}
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Wallpaper">
              <div className="columnCenteredContainer">
                <ImageUploadDialog
                  caption="Wallpaper Image"
                  imageFiles={wallpaperImageFiles}
                  imageUrl={wallpaperUrl}
                  onDrop={onWallpaperDrop}
                  clearImage={clearWallpaperImage}
                />
              </div>              
            </Tab>
            <Tab eventKey={3} title="Preview">
              <div className="columnCenteredContainer" style={{padding: 50}}>
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="previewTitleTF"
                  value={previewTitle}
                  onChange={handlePreviewTitleChange}
                  margin="normal"
                  variant="outlined"
                  label="Preview Title"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="previewDescriptionTF"
                  value={previewDescription}
                  onChange={handlePreviewDescriptionChange}
                  margin="normal"
                  variant="outlined"
                  label="Preview Description"
                />
              </div>
            </Tab>
          </Tabs>  
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={handleApplyLookAndFeelClicked}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
    
  );

}

export default LookAndFeelModal;
  



