import { useState } from 'react';

const useFileReader = (handleFileRead) => { // Receive handleFileRead as a parameter
  const [fileContent, setFileContent] = useState(null);
  const [error, setError] = useState(null);

  const readFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      setFileContent(event.target.result);
      setError(null);
      handleFileRead(event.target.result); // Call the handler when the file is read
    };

    reader.onerror = (event) => {
      setError(event.target.error);
      setFileContent(null);
    };

    if (file) {
      reader.readAsText(file);
    } else {
      setError('No file selected');
    }
  };

  return { fileContent, error, readFile };
};

export default useFileReader;
