import MaxDiffTrialSet from "./MaxDiffTrialSet";

export default class MaxDiffVersion
{
  constructor() {
    this.version = -1;
    this.trialSets = [];
    this.wyshes = [];
  }

  getTrialSets(blockWysh) {

    // Here we are going to go through the ORDERED wyshes of the block and 
    // return an ORDERED array of Wyshes.
    // IF there are more items in the trial set than Wyshes, return empty array
    // This can indicated something is wrong

    const orderedWyshes =  blockWysh.orderedWyshList.getOrderedWyshes();
    for (const ts of this.trialSets) {
      ts.setWyshes(orderedWyshes);
    }

    return this.trialSets;
  }


  initFromJson(json) {
    if (json) {
      if ("version" in json) {
        this.version = json.version;
      }
      if ("trial_sets" in json) {
        for (const tsJson of json.trial_sets) {
          this.trialSets.push(MaxDiffTrialSet.createFromJson(tsJson, this));
        }
      }
    }
  }

  static createFromJson(json)
  {
    var maxDiffVersion = new MaxDiffVersion();
    maxDiffVersion.initFromJson(json);
    return maxDiffVersion;
  }
}