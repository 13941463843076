import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import EmailIcon from '@mui/icons-material/Email';
import SwytchbackTextField from '../controls/SwytchbackTextField';
import classes from "../../../css/modules/BuildModals.module.css";
import "../../../css/swytchbackModal.css"
import "../../../css/swytchbackStyles.css"
import SocialSharingChannel from "../../../components/SocialSharingChannel";
import SwydgetComplete from '../../../components/swydget/SwydgetComplete';
import { Switch } from '@mui/material';

const ThankYouPageModal = ({
  showThankYouPageModal,
  closeAddWallpaperModal,
  rewardText, 
  handleRewardTextChange, 
  rewardUrl, 
  handleRewardUrlChange, 
  rewardButtonText, 
  handleRewardButtonTextChange,
  handleEnableSocialChannelClicked,
  swydget,
  handleApplyThankYouPageInfoClicked
}) => {

  const socialSharingChannels = {
    FACEBOOK: new SocialSharingChannel("facebook", "Facebook"),
    TWITTER: new SocialSharingChannel("twitter", "Twitter"),
    LINKED_IN: new SocialSharingChannel("linked_in", "LinkedIn"),
    PINTEREST: new SocialSharingChannel("pinterest", "Pinterest"),
    EMAIL: new SocialSharingChannel("email", "Email"),
  };

  return (

    <Modal
      onHide={closeAddWallpaperModal}
      size="xl"
      show={showThankYouPageModal}
      enforceFocus={false}
      backdrop="static">
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Thank You Page</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane">
          <Tabs className="swytchbackModalTabs" defaultActiveKey={1} id="welcome-page-tabs">
            <Tab eventKey={1} title="Text">
              <div className="columnCenteredContainer">
                <SwytchbackTextField
                  style={{width: '70%', marginTop: 30}}
                  id="rewardTextTF"
                  value={rewardText}
                  onChange={handleRewardTextChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Reward Primary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%'}}
                  id="rewardUrlTF"
                  value={rewardUrl}
                  onChange={handleRewardUrlChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Secondary Text"
                />
                <SwytchbackTextField
                  style={{width: '70%', marginBottom: 30}}
                  id="rewardButtonTextTF"
                  value={rewardButtonText}
                  onChange={handleRewardButtonTextChange}
                  margin="normal"
                  variant="outlined"
                  placeholder="Button Text"
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Social Sharing">
              <div className="columnCenteredContainer">
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    <FacebookIcon sx={{ width: '40px', height: '40px' }}  className={`${classes.addButtonStyle}`} />
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={swydget.isSocialChannelEnabled(socialSharingChannels.FACEBOOK.id)}
                      onChange={() => handleEnableSocialChannelClicked(socialSharingChannels.FACEBOOK.id)}
                      color="primary"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    <XIcon sx={{ width: '40px', height: '40px' }}  className={`${classes.addButtonStyle}`} />
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={swydget.isSocialChannelEnabled(socialSharingChannels.TWITTER.id)}
                      onChange={() => handleEnableSocialChannelClicked(socialSharingChannels.TWITTER.id)}
                      color="primary"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    <LinkedInIcon sx={{ width: '40px', height: '40px' }}  className={`${classes.addButtonStyle}`} />
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={swydget.isSocialChannelEnabled(socialSharingChannels.LINKED_IN.id)}
                      onChange={() => handleEnableSocialChannelClicked(socialSharingChannels.LINKED_IN.id)}
                      color="primary"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    <PinterestIcon sx={{ width: '40px', height: '40px' }}  className={`${classes.addButtonStyle}`} />
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={swydget.isSocialChannelEnabled(socialSharingChannels.PINTEREST.id)}
                      onChange={() => handleEnableSocialChannelClicked(socialSharingChannels.PINTEREST.id)}
                      color="primary"
                    />
                  </div>
                </div>
                <div className='rowContainerSpaced'>
                  <div className={`${classes.paddedItem}`}>
                    <EmailIcon sx={{ width: '40px', height: '40px' }}  className={`${classes.addButtonStyle}`} />
                  </div>
                  <div className={`${classes.paddedItem}`}>
                    <Switch
                      checked={swydget.isSocialChannelEnabled(socialSharingChannels.EMAIL.id)}
                      onChange={() => handleEnableSocialChannelClicked(socialSharingChannels.EMAIL.id)}
                      color="primary"
                    />
                  </div>
                </div>
              </div>              
            </Tab>
            <Tab eventKey={3} title="Preview">
              <SwydgetComplete eventInFocus={swydget} height={70} />
            </Tab>
          </Tabs>  
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button 
          className="swytchbackActiveButton" 
          style={{width: 130, marginLeft: 5, marginRight: 5}} 
          onClick={handleApplyThankYouPageInfoClicked}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
    
  );

}

export default ThankYouPageModal;
  



