import dispatcher from "../Dispatcher";
import axios from "axios";
import * as Utilities from "../services/Utilities";

/**
* whoAmI
*
* HTTP GET /1/qualtrics/who_am_i
#
# Get the Swytchback user's information from Qualtrics
*
* @param user authenticated Swytchback user
*/
export function whoAmI(serverUrl, user, whoAmICallback) {

  const endpoint = "/1/qualtrics/who_am_i";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + endpoint, config)
    .then((data) => {

      const meDict = data.data.results.me;
      
      whoAmICallback(meDict);

      dispatcher.dispatch({
        type: "QUALTRICS_WHO_AM_I_SUCCESSFUL",
      });
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* getSurveys
*
* HTTP GET /1/qualtrics/surveys
#
# Get the Swytchback user's Qualtrics surveys
*
* @param user authenticated Swytchback user
*/
export function getSurveys(serverUrl, user, getSurveysCallBack) {

  const endpoint = "/1/qualtrics/surveys";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  axios.get(serverUrl + endpoint, config)
    .then((data) => {
      getSurveysCallBack(data.data.results.surveys.result.elements);
    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* createSurvey
*
* HTTP POST /1/qualtrics/create_survey
#
# Get the Swytchback user's information from Qualtrics
* {
*   "SurveyName": "string",
*   "Language": "EN",
*   "ProjectCategory": "CORE"
* }
* @param serverUrl
* @param user authenticated Swytchback user
* @param swydget Swydget to harvest survey details, blocks and stimuli
* @param language English, Spanish, etc.
* @param projectCategory Qualtrics project category (default: CORE)
* @param createSurveyCallback Callback to update state of calling component
*/
export function createSurvey(serverUrl, user, swydget, language, projectCategory, createSurveyCallback) {

  var eventIdArg = swydget.eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const encodedEventId = eventIdArg.replace("#", "%23");
  const endpoint = "/1/events/event/" + encodedEventId + "/qualtrics/survey";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  console.log(swydget.generateQuestionNumbersMap());
  console.log(swydget.generateQuestionNumbersMap().entries());

  var questionToWyshArray = []
  for (const [question, wysh] of swydget.generateQuestionNumbersMap()) {
    questionToWyshArray.push({
      'question_number': question,
      'wysh_id': wysh.wyshId
    });
  }

  console.log(questionToWyshArray);


  var parametersJson = {
    "language": language,
    "project_category": projectCategory,
    "question_to_wysh_array": questionToWyshArray
  };

  // Tack on the filter
  parametersJson.filter = swydget.filter.toJson();

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      createSurveyCallback();

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}

/**
* postResponses
*
* HTTP POST /1/qualtrics/create_survey
#
* {
*   "SurveyName": "string",
*   "Language": "EN",
*   "ProjectCategory": "CORE"
* }
* @param serverUrl
* @param user authenticated Swytchback user
* @param swydget Swydget to harvest survey details, blocks and stimuli
* @param qualtricsSurveyId Qualtrics Survey ID that was generated when the survey was created in Qualtrics
* @param postResponsesCallback Callback to update state of calling component
*/
export function postResponses(serverUrl, user, swydget, qualtricsSurveyId, postResponsesCallback) {

  var eventIdArg = swydget.eventId;
  if ((Utilities.endsWith(eventIdArg, "#") === false) && (Utilities.endsWith(eventIdArg, "%23") === false)) {
    eventIdArg += "#";
  }

  const encodedEventId = eventIdArg.replace("#", "%23");
  const endpoint = "/1/events/event/" + encodedEventId + "/qualtrics/survey/responses";

  const clearText = user.userId + ":" + user.password;
  const base64String = "Basic " + btoa(clearText);

  var config = {
    headers: {'Authorization': base64String}
  };

  var questionToWyshArray = []
  for (const [question, wysh] of swydget.generateQuestionNumbersMap()) {
    questionToWyshArray.push({
      'question_number': question,
      'wysh_id': wysh.wyshId
    });
  }

  var parametersJson = {
    "qualtrics_survey_id": qualtricsSurveyId,
    "question_to_wysh_array": questionToWyshArray
  };

  // Tack on the filter
  parametersJson.filter = swydget.filter.toJson();

  axios.post(serverUrl + endpoint, parametersJson, config)
    .then((data) => {

      postResponsesCallback();

    }
  )
  .catch(function (error) {
    console.log(error);
  });
}
