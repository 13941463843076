import SwytchbackServiceFactory from "./services/SwytchbackServiceFactory";
import SwytchbackServiceResearch from "./services/SwytchbackServiceResearch";
import Notification from "./notifications/Notification";
import SegmentBucket from "./SegmentBucket";
import MessagingCampaignBucket from "./MessagingCampaignBucket";
import OAuthArtifacts from "./OAuthArtifacts";

export default class User
{
  constructor() {
    this.userId = "";
    this.keysDictionary = {};
    this.oauthArtifactsDict = {};
    this.password = "";
    this.firstName = "";
    this.lastName = "";
    this.company = "";
    this.phone = "";
    this.photo = "";
    this.gender = "";
    this.myMid = "";
    this.dob = "";
    this.privacyPolicyLink = "";
    this.addressBookJson = "";
    this.nextCix = 0;
    this.contactIdx = 0;
    this.isResgistered = false;
    this.myFriends = [];
    this.myUnacknowldgedNotifications = [];
    this.totalNotificationsCount = 0;
    this.isConnected = false;
    this.authorizedServices = {};
    this.attributes = {};
    this.segmentBucket = new SegmentBucket();
    this.messagingCampaignBucket = new MessagingCampaignBucket();
  }

  addCamelCaseFieldsToUser(userJson) {
    userJson["isFriend"] = userJson["is_friend"];
    userJson["displayName"] = userJson["display_name"];
    userJson["firstName"] = userJson["first_name"];
    userJson["lastName"] = userJson["last_name"];
    userJson["ownerMid"] = userJson["owner-mid"];
  }

  getPrimaryKeyValue(type) {

    var value = ""

    if (this.keysDictionary[type] && this.keysDictionary[type].length > 0) {
      value = this.keysDictionary[type][0].v;
    }

    return value;

  }

  getPhone() {
    return this.getPrimaryKeyValue("phone");
  }

  getEmail() {
    return this.getPrimaryKeyValue("email")
  }

  getCompanyName() {
    if (this.company && this.company.trim() !== "") {
      return this.company.trim();
    }
    else {
      return "";
    }
  }

  loadOAuthArtifacts(oauthArtifactsDict) {
    if (oauthArtifactsDict) {
      for (var key in oauthArtifactsDict) {
        this.oauthArtifactsDict[key] = OAuthArtifacts.createFromJson(oauthArtifactsDict[key])
      }
    }
  }

  isIntegrationInstalled(integration) {
    // console.log(integration + ": " + (integration in this.oauthArtifactsDict));
    return integration in this.oauthArtifactsDict ? true : false
  }

  hasIntegrations() {
    if (this.oauthArtifactsDict) {
      for (var integration in this.oauthArtifactsDict) {
        if (this.isIntegrationInstalled(integration)) {
          return true;
        }
      }
    }

    return false
  }

  initFromNetwork(userId, password, profileFromNetwork) {

    if (profileFromNetwork.oauth_artifacts_dict) {
      this.loadOAuthArtifacts(profileFromNetwork.oauth_artifacts_dict)
    }

    this.userId = userId;
    this.password = password;
    this.keysDictionary = { "email" : [ {"t" : "email", "v" : userId} ] };
    this.firstName = profileFromNetwork.first_name;
    this.lastName = profileFromNetwork.last_name;
    this.company = profileFromNetwork.company;
    this.phone = profileFromNetwork.phone;
    this.photo = profileFromNetwork.photo;
    this.gender = profileFromNetwork.gender;
    this.myMid = profileFromNetwork.address_book.mid;
    this.dob = profileFromNetwork.dob;
    this.privacyPolicyLink = profileFromNetwork.privacy_policy_link;
    this.addressBookJson = profileFromNetwork.address_book;
    this.nextCix = profileFromNetwork.address_book.next_cix;

    if (typeof profileFromNetwork.attributes !== undefined && profileFromNetwork.attributes !== null) {
      this.attributes = profileFromNetwork.attributes;
    }

    if (profileFromNetwork.authorized_services) {
      for (var key in profileFromNetwork.authorized_services) {
        if (profileFromNetwork.authorized_services.hasOwnProperty(key)) {
          let service = SwytchbackServiceFactory.getService(key);
          this.authorizedServices[key] = service;
        }
      }
    }

    if (profileFromNetwork.segment_bucket) {
      this.segmentBucket = SegmentBucket.createSegmentBucketFromJson(this.myMid, profileFromNetwork.segment_bucket);
    }

    if (profileFromNetwork.messaging_campaign_bucket) {
      this.messagingCampaignBucket = MessagingCampaignBucket.createMessagingCampaignBucketFromJson(this.myMid, profileFromNetwork.messaging_campaign_bucket);
    }
  }

  initFromJsonString(userJsonString) {

    const userJson = JSON.parse(userJsonString);

    if (userJson) {
      this.userId = userJson.userId;
      this.password = userJson.password;
      this.keysDictionary = { "email" : [ {"t" : "email", "v" : userJson.userId} ] };
      this.firstName = userJson.firstName;
      this.lastName = userJson.lastName;
      this.company = userJson.company;
      this.phone = userJson.phone;
      this.photo = userJson.photo;
      this.gender = userJson.gender;
      this.myMid = userJson.myMid;
      this.dob = userJson.dob;
      this.privacyPolicyLink = userJson.privacyPolicyLink;
      this.addressBookJson = userJson.addressBookJson;
      this.nextCix = userJson.addressBookJson.nextCix;

      if (typeof userJsonString.attributes !== undefined && userJsonString.attributes !== null) {
        this.attributes = userJsonString.attributes;
      }

      if (userJson.myUnacknowldgedNotifications) {
        for (var i = 0; i < userJson.myUnacknowldgedNotifications.length; i++) {
          this.myUnacknowldgedNotifications.push(Notification.createNotificationFromJson(userJson.myUnacknowldgedNotifications[i]));
        }
      }

      if (userJson.myFriends) {
        for (i = 0; i < userJson.myFriends.length; i++) {
          this.myFriends.push(User.createUserFromJson(userJson.myFriends[i]));
        }
      }

      // Iterate through services and add them from factory
      for (var serviceId in userJson.authorizedServices) {
        if (userJson.authorizedServices.hasOwnProperty(serviceId)) {
          this.authorizedServices[serviceId] = SwytchbackServiceFactory.getService(serviceId);
        }
      }

      // Load 3rd party OAuth integrations
      for (var integration in userJson.oauthArtifactsDict) {
        if (userJson.oauthArtifactsDict.hasOwnProperty(integration)) {
          this.oauthArtifactsDict[integration] = OAuthArtifacts.createFromJson(userJson.oauthArtifactsDict[integration]);
        }
      }

      if (userJson.segmentBucket) {
        this.segmentBucket = SegmentBucket.createSegmentBucketFromJson(this.myMid, userJson.segmentBucket);
      }

      if (userJson.messagingCampaignBucket) {
        this.messagingCampaignBucket = MessagingCampaignBucket.createMessagingCampaignBucketFromJson(this.myMid, userJson.messagingCampaignBucket);
      }

      if (userJson.totalNotificationsCount) {
        this.totalNotificationsCount = userJson.totalNotificationsCount;
      }
    }
  }

  initFromJson(userJson) {

    // Total KLUDGE!!!
    // The JSON returned by the backend varies from time to time. Sometimes, there is a
    // contact object that houses info that might be in the main JSON object returned
    // other times.
    if (userJson.hasOwnProperty("contact")) {
      let contactJson = userJson["contact"];

      if (contactJson.hasOwnProperty("keys")) {
        this.keysDictionary = contactJson["keys"];
      }

      if (contactJson.hasOwnProperty("first_name")) {
        this.firstName = contactJson["first_name"];
      }

      if (contactJson.hasOwnProperty("last_name")) {
        this.lastName = contactJson["last_name"];
      }

      if (contactJson.hasOwnProperty("company")) {
        this.company = contactJson["company"];
      }

      if (contactJson.hasOwnProperty("photo")) {
        this.photo = contactJson["photo"];
      }

      if (contactJson.hasOwnProperty("cix")) {
        this.contactIdx = contactJson["cix"];
      }

      if (contactJson.hasOwnProperty("attributes")) {
        this.attributes = contactJson["attributes"];
      }

      if (contactJson.hasOwnProperty("privacy_policy_link")) {
        this.privacyPolicyLink = contactJson["privacy_policy_link"];
      }
    }
    else {
      if (userJson.hasOwnProperty("keys")) {
        this.keysDictionary = userJson["keys"];
      }

      if (userJson.hasOwnProperty("keysDictionary")) {
        this.keysDictionary = userJson["keysDictionary"];
      }

      if (userJson.hasOwnProperty("first_name")) {
        this.firstName = userJson["first_name"];
      }

      if (userJson.hasOwnProperty("firstName")) {
        this.firstName = userJson["firstName"];
      }

      if (userJson.hasOwnProperty("last_name")) {
        this.lastName = userJson["last_name"];
      }

      if (userJson.hasOwnProperty("lastName")) {
        this.lastName = userJson["lastName"];
      }

      if (userJson.hasOwnProperty("company")) {
        this.company = userJson["company"];
      }

      if (userJson.hasOwnProperty("privacyPolicyLink")) {
        this.privacyPolicyLink = userJson["privacyPolicyLink"];
      }

      if (userJson.hasOwnProperty("privacy_policy_link")) {
        this.privacyPolicyLink = userJson["privacy_policy_link"];
      }

      if (userJson.hasOwnProperty("cix")) {
        this.contactIdx = userJson["cix"];
      }

      if (userJson.hasOwnProperty("contactIdx")) {
        this.contactIdx = userJson["contactIdx"];
      }

      if (userJson.hasOwnProperty("isConnected")) {
        this.isConnected = userJson["isConnected"];
      }

      if (userJson.hasOwnProperty("isResgistered")) {
        this.isResgistered = userJson["isResgistered"];
      }
    }

    if (userJson.hasOwnProperty("photo")) {
      this.photo = userJson["photo"];
    }

    if (userJson["connected"]) {
      this.isConnected = userJson["connected"];
    }

    if (userJson.hasOwnProperty("attributes")) {
      this.attributes = userJson["attributes"];
    }

    if (userJson.hasOwnProperty("oauthArtifactsDict")) {
      this.loadOAuthArtifacts(userJson["oauthArtifactsDict"])
    }

    if (userJson.segment_bucket) {
      // TODO: This is weird. Look into why underscore names are comning in instead of camel case
      this.segmentBucket = SegmentBucket.createSegmentBucketFromJson(this.myMid, userJson.segment_bucket);
    }

    this.reconcileUserId();
  }

  reconcileUserId() {
    if (this.userId === "") {
      // replace the userId with the first email key value
      if (this.keysDictionary.hasOwnProperty("email") && this.keysDictionary.email.length > 0) {
        this.userId = this.keysDictionary.email[0].v;
      }
    }
  }

  toJsonString() {
    const userJsonString = JSON.stringify({
      userId: this.userId,
      password: this.password,
      keys: this.keysDictionary,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      phone: this.phone,
      photo: this.photo,
      gender: this.gender,
      myMid: this.myMid,
      dob: this.dob,
      privacyPolicyLink: this.privacyPolicyLink,
      addressBookJson: this.addressBookJson,
      nextCix: this.nextCix,
      authorizedServices: this.authorizedServices,
      myFriends: this.myFriends,
      myUnacknowldgedNotifications: this.myUnacknowldgedNotifications,
      totalNotificationsCount: this.totalNotificationsCount,
      attributes: this.attributes,
      segmentBucket: this.segmentBucket,
      messagingCampaignBucket: this.messagingCampaignBucket,
      oauthArtifactsDict: this.oauthArtifactsDict
    });

    return userJsonString;
  }

  getSurveyTypesCount() {
    // Everybody gets Binary, Scalar, and Customized
    var count = 3;

    for (var key in this.authorizedServices) {
      if (this.authorizedServices[key] instanceof SwytchbackServiceResearch) {
        count += 1;
      }
    }

    return count;

  }

  addUserToMyAddressBook(newFriend, nextCix)
  {

      if (this.isFriendInAddressBook(newFriend, this.addressBookJson)) {
        newFriend.contactIdx = this.getContactIdxForUserInAB(newFriend);
      }
      else {
        // set the cix in the WyshMeUser so that the post will actually happen
        newFriend.contactIdx = nextCix.toString();

        var contactsJson = this.addressBookJson["contacts"];

        contactsJson[nextCix.toString()] = newFriend.toWyshMeAddressBookJson(this.myMid, nextCix);
        this.addressBookJson["contacts"] = contactsJson;
        this.addressBookJson["next_cix"] = nextCix + 1;
      }
  }

  isFriendInAddressBook(newFriend) {
    var newFriendUserId = "";
    if (newFriend.keysDictionary["email"]) {
      if (newFriend.keysDictionary["email"].length > 0) {
        if (newFriend.keysDictionary["email"][0]["v"]) {
          newFriendUserId = newFriend.keysDictionary["email"][0]["v"];
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

    var contactKeys = Object.keys(this.addressBookJson["contacts"]);

    for (var i = 0; i < contactKeys.length; i++) {
      var contact = this.addressBookJson["contacts"][contactKeys[i]];
      if (contact["keys"]) {
        if (contact["keys"]["email"]) {
          if (contact["keys"]["email"].length > 0) {
            if (contact["keys"]["email"][0]["v"]) {
              if (contact["keys"]["email"][0]["v"] === newFriendUserId) {
                return true;
              }
            }
          }
        }
      }
    }

    return false;
  }

  getContactIdxForUserInAB(newFriend) {
    var cix = 0;

    var newFriendUserId = "";

    if (newFriend.keysDictionary["email"]) {
      if (newFriend.keysDictionary["email"].length > 0) {
        if (newFriend.keysDictionary["email"][0]["v"]) {
          newFriendUserId = newFriend.keysDictionary["email"][0]["v"];
        }
        else {
          return cix;
        }
      }
      else {
        return cix;
      }
    }
    else {
      return cix;
    }

    var contactKeys = Object.keys(this.addressBookJson["contacts"]);

    for (var i = 0; i < contactKeys.length; i++) {
      var contact = this.addressBookJson["contacts"][contactKeys[i]];
      if (contact["keys"]) {
        if (contact["keys"]["email"]) {
          if (contact["keys"]["email"].length > 0) {
            if (contact["keys"]["email"][0]["v"]) {
              if (contact["keys"]["email"][0]["v"] === newFriendUserId) {
                if (contact["cix"]) {
                  return contact["cix"];
                }
              }
            }
          }
        }
      }
    }


    return cix;
  }

  /*
  toWyshMeAddressBookJson

  Turn this User into JSON destined for a User's WyshMe AddressBook. It could be this User or it could be another user.
  - ownerMid: The MID of the User whose address book THIS User is being added.
  - cix: the contact index for the destination address book.
  */
  toWyshMeAddressBookJson(ownerMid, cix)
  {
      var first = ""

      if (this.firstName) {
        first = this.firstName
      }

      var last = ""

      if (this.lastName) {
        last = this.lastName
      }

      var fullName = first + " " + last

      var keyJsonBucket = {};

      var keyTypes = Object.keys(this.keysDictionary);

      for (var kCount = 0; kCount < keyTypes.length; kCount++) {

        var kt = keyTypes[kCount];
        var keyArray = this.keysDictionary[kt];
        if (kt === "email") {
          var tempArray = []
          for (var i = 0; i < keyArray.length; i++)
          {
              var k = keyArray[i];
              const kJson = { "t": k["t"], "v": k["v"] };
              tempArray.push(kJson);
          }

          keyJsonBucket["email"] = tempArray
        }
        if (kt === "phone") {
          tempArray = []
          for (i = 0; i < keyArray.length; i++)
          {
              k = keyArray[i];
              const kJson = { "t": k.type, "v": k.value };
              tempArray.push(kJson);
          }

          keyJsonBucket["phone"] = tempArray
        }
        if (kt === "uid") {
          tempArray = []
          for (i = 0; i < keyArray.length; i++)
          {
              k = keyArray[i];
              const kJson = { "t": k.type, "v": k.value };
              tempArray.push(kJson);
          }

          keyJsonBucket["uid"] = tempArray
        }
        if (kt === "wyshListName") {
          tempArray = []
          for (i = 0; i < keyArray.length; i++)
          {
              k = keyArray[i];
              const kJson = { "t": k.type, "v": k.value };
              tempArray.push(kJson);
          }

          keyJsonBucket["wyshListName"] = tempArray
        }
      }

      var meJson = {
          "first_name": first,
          "last_name": last,
          "display_name": fullName,
          "is_friend": false,
          "keys": keyJsonBucket,
          "photo": "",
          "owner-mid": ownerMid,
          "cix": cix
      }

      return meJson
  }

  getProfilePhotoUrl() {
    var imageUrl = "https://images.wyshme.com/stock/no-user-profile-photo.png";

    if (this.photo !== "") {
      imageUrl = this.photo;
    }

    return imageUrl;
  }

  isAuthorized(service) {
    if (this.authorizedServices.hasOwnProperty(service.id)) {
      return true;
    }
    else {
      return false;
    }
  }

  isEqual(user) {
    if (this.keysDictionary && this.keysDictionary.email && this.keysDictionary.email.length > 0 &&
        user && user.keysDictionary && user.keysDictionary.email && user.keysDictionary.email.length > 0 &&
        this.keysDictionary.email[0].v.toLowerCase() === user.keysDictionary.email[0].v.toLowerCase()) {

      return true;
    }
    else {
      return false;
    }
  }

  static createUserFromJsonString(userJsonString)
  {
    var user = new User();
    user.initFromJsonString(userJsonString);
    return user;
  }

  static createUserFromAddressBookJson(userJson) {
    var user = new User();
    if (userJson["contact"]) {
      user.firstName = userJson["contact"]["first_name"];
      user.lastName = userJson["contact"]["last_name"];
      user.photo = userJson["contact"]["photo"];
      if (userJson["contact"]["keys"]) {
        if (userJson["contact"]["keys"]["email"]){
          // We have an email address in there
          if (userJson["contact"]["keys"]["email"].length > 0) {
            user.userId = userJson["contact"]["keys"]["email"][0]["v"];
          }
        }
      }
    }
    // MID
    if (userJson["mid"]) {
      user.myMid = userJson["mid"];
    }

    // Is the user connected to the loggedInUser?
    if (userJson["connected"]) {
      user.isConnected = userJson["connected"];
    }

    // User profile photo UserTableRow
    if (userJson["photo"]) {
      user.photo = userJson["photo"];
    }

    return user;
  }

  static createUserFromJson(userJson)
  {
    var user = new User();
    user.initFromJson(userJson);
    return user;
  }

  static createUserFromNetwork(userId, password, profileFromNetwork) {
    var user = new User();
    user.initFromNetwork(userId, password, profileFromNetwork);
    return user;
  }

  static isUserInArray(user, users)
  {
    for (var i = 0; i < users.length; i++) {
      if (user.userId === users[i].userId) {
        return true;
      }
    }

    return false;
  }

}
