import React, { useState, useEffect } from 'react';
import { Modal, Button, FormGroup, FormControl } from 'react-bootstrap';
import "../../../css/swytchbackStyles.css";
import "../../../css/swytchbackModal.css";
import sha256 from "js-sha256";
import loginStore from "../../../stores/LoginStore";
import AuthService from '../../../services/AuthService';
import { useNavigate } from 'react-router';
import SwytchbackTextField from '../controls/SwytchbackTextField';

const CredentialsModal = ({ showModal, onHideAction }) => {
  const [loggedInUser, setLoggedInUser] = useState(loginStore.getLoggedInUser());
  const [password, setPassword] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

  const cleanUp = () => {
    setUpdateStatus("");
    setPassword("");
    onHideAction();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const updatePassword = () => {
    AuthService.updatePassword(loggedInUser, navigationHandler, sha256(password));
    cleanUp();
  };

  const updatePasswordSuccessful = () => {
    setLoggedInUser(loginStore.getLoggedInUser());
    setPassword("");
  };

  const logout = () => {
    AuthService.logout(loggedInUser.userId, loggedInUser.password);
  };

  const navigationHandler = useNavigate();

  useEffect(() => {
    loginStore.on("loginStore_user_logged_in", updatePasswordSuccessful);
    return () => {
      loginStore.removeListener("loginStore_user_logged_in", updatePasswordSuccessful);
    };
  }, []);

  const inputStyle = {
    height: 50,
    width: '100%',
    backgroundColor: '#f1f2f6',
    border: 2,
    borderStyle: 'solid',
    borderColor: '#dddee0',
    fontWeight: 300,
    fontSize: 18
  };

  const messageDivStyle = {
    padding: 12,
    color: '#ffffff'
  };

  const modalHeaderStyle = {
    backgroundColor: '#eceef0',
    color: '#606368'
  };

  return (
    <Modal 
      show={showModal} 
      onHide={cleanUp} 
      enforceFocus={false} 
      backdrop="static"
    >
      <Modal.Header className="swytchbackModalHeader" closeButton>
        <Modal.Title className="swytchbackModalTitle">Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="swytchbackModalBody coloredBackground">
        <div className="hookFullPaneContainer swytchbackGradientPane" style={{padding: 12}}>
          <SwytchbackTextField
            id="swytchback_password"
            style={{width: '100%'}}
            value={password}
            label="Enter New Password"
            onChange={handlePasswordChange}
            margin="normal"
            variant="outlined"
          />
          <div style={messageDivStyle}>{updateStatus}</div>
        </div>
      </Modal.Body>
      <Modal.Footer className="swytchbackModalFooter">
        <Button className="swytchbackActiveButton" onClick={updatePassword}>Update</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CredentialsModal;
