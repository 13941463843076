import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Switch, MenuItem } from '@mui/material';
import SwytchbackSelect from '../../hooks/admin/controls/SwytchbackSelect';
import SwytchbackTextField from '../../hooks/admin/controls/SwytchbackTextField';
import Decision from '../../classes/Decision';
import Wysh from '../../classes/Wysh';


class FreeResponseDecisionsTable extends Component {

  constructor() {
    super();

    this.setSortOrder = this.setSortOrder.bind(this);
    this.getSortMethod = this.getSortMethod.bind(this);
    this.handleFilterTermChange = this.handleFilterTermChange.bind(this);

    var sortOrder = "longest";

    this.state = {
      sortOrder: sortOrder, // highest, lowest
      filterTerm: ""
    }
  }

  setSortOrder(e) {
    this.setState({ sortOrder: e.target.value });
  }

  handleFilterTermChange(evt) {
    this.setState({ filterTerm: evt.target.value });
  }

  getSortMethod() {
    var sortMethod = Decision.compareByResultNormalizedDesc;

    switch (this.state.sortOrder) {
      case "highest":
        sortMethod = Decision.compareByResultNormalizedDesc;
        break;
      case "lowest":
        sortMethod = Decision.compareByResultNormalizedAsc;
        break;
      case "newest":
        sortMethod = Decision.compareByCreationDateDesc;
        break;
      case "oldest":
        sortMethod = Decision.compareByCreationDateAsc;
        break;
      case "longest":
        sortMethod = Decision.compareByFreeResponseLengthDesc;
        break;

      default:

    }

    return sortMethod;
  }

  getTableData(toggleHandler) {

    let theadStyleTr = {
      height: 44
    };

    let tHeadAnswerStyleTh = {
      color: '#ffffff',
      backgroundColor: 'transparent',
      width: 50,
      fontWeight: 400
    };

    let tHeadCenteredStyleTh = {
      color: '#ffffff',
      backgroundColor: 'transparent',
      textAlign: 'left',
      fontWeight: 400
    };

    let tHeadDateStyleTh = {
      color: '#ffffff',
      backgroundColor: 'transparent',
      textAlign: 'right',
      width: 50,
      fontWeight: 400
    };

    return (
      <tr style={theadStyleTr}>
        <th style={tHeadAnswerStyleTh}>Answer</th>
        <th style={tHeadCenteredStyleTh}>Response</th>
        <th style={tHeadDateStyleTh}>Date</th>
        { toggleHandler &&
          <th style={tHeadDateStyleTh}>Date</th>
        }
      </tr>
    );
  }

  generateRows(decisionsWithFreeResponses, toggleHandler, selectedFreeResponseDecisions) {

    let limit = 200;

    var rows = [];

    let trStyle = {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#b7b7b7'
    };

    let leftTdStyle = {
      color: '#ffffff',
      backgroundColor: 'transparent',
      textAlign: 'left',
      verticalAlign: 'middle'
    };

    let rightTdStyle = {
      color: '#ffffff',
      backgroundColor: 'transparent',
      textAlign: 'right',
      verticalAlign: 'middle'
    };

    if (decisionsWithFreeResponses) {
      for (var i = 0; i < decisionsWithFreeResponses.length && i < limit; i++) {
        let created = new Date(decisionsWithFreeResponses[i].decisionCreatedTimestampStr);
        let createdStr = (created.getMonth() + 1) + "/" + created.getDate() + "/" + created.getFullYear();
        let d = decisionsWithFreeResponses[i];
        rows.push(<tr key={i} style={trStyle}>
          <td style={leftTdStyle}>{decisionsWithFreeResponses[i].resultLiteral}</td>
          <td style={leftTdStyle}>{decisionsWithFreeResponses[i].freeResponseAnswers[0]}</td>
          <td style={rightTdStyle}>{createdStr}</td>
          { toggleHandler && selectedFreeResponseDecisions &&
            <td style={rightTdStyle}>
              <Switch
                key={i + "free-response-switch"}
                color="primary"
                checked={Wysh.containsDecision(d, selectedFreeResponseDecisions) === true ? true : false }
                onChange={(e) => toggleHandler(d, e)}
              />
            </td>
          }
        </tr>);
      }
    }

    return rows;
  }


  render() {

    const { decisionsWithFreeResponses } = this.props;
    const { toggleHandler } = this.props;
    const { selectedFreeResponseDecisions} = this.props;


    var filteredDecArray = [];
    var decisionsWithFreeResponsesCount = 0;

    if (decisionsWithFreeResponses) {
      decisionsWithFreeResponsesCount = decisionsWithFreeResponses.length;
      decisionsWithFreeResponses.sort(this.getSortMethod());

      filteredDecArray = decisionsWithFreeResponses.filter(decision => {

        if (this.state.filterTerm === "") {
          return true;
        }
        else if (decision.freeResponseAnswers && decision.freeResponseAnswers.length > 0) {
          if (decision.freeResponseAnswers[0].toLowerCase().indexOf(this.state.filterTerm.toLowerCase()) > -1) {
            return true;
          }
        }

        return false;
      });
    }


    let containerStyle = {
      width: '100%'
    };

    let tableContainerStyle = {
      color: '#ffffff',
      backgroundColor: 'transparent',
    };

    let tableStyle = {
      background: 'transparent',
      color: '#ffffff',
      margin: 0
    }

    let theadStyle = {
      color: '#ffffff',
      fontSize: 14
    };

    let controlDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: 80,
      padding: 0,
      marginBottom: 10
    };

    let controlLabelStyle = {
      fontSize: 14,
      fontWeight: 400,
      color: '#ffffff'
    };



    return (
      <div style={containerStyle}>
        <div style={controlDivStyle}>
          <label style={controlLabelStyle}>SORT BY</label>
          <SwytchbackSelect
            style={{color: '#ffffff'}}
            disabled={false}
            inputProps={{ IconComponent: () => null }}
            id="font-family-select"
            value={this.state.sortOrder}
            onChange={(evt) => this.setSortOrder(evt)}>
            <MenuItem key={"highest"} value={"highest"}>Highest Scores</MenuItem>
            <MenuItem key={"lowest"} value={"lowest"}>Lowest Scores</MenuItem>
            <MenuItem key={"newest"} value={"newest"}>Most Recent</MenuItem>
            <MenuItem key={"oldest"} value={"oldest"}>Oldest</MenuItem>
            <MenuItem key={"longest"} value={"longest"}>Longest</MenuItem>
          </SwytchbackSelect>
        </div>
        <SwytchbackTextField
          id="free-response-term-filter"
          label="Search by Term"
          value={this.state.filterTerm}
          onChange={this.handleFilterTermChange}
          margin="normal"
          variant="outlined"
        />

        <div style={tableContainerStyle}>
          <div style={{paddingBottom: 10, paddingTop: 30}}>Displaying: {filteredDecArray.length} of {decisionsWithFreeResponsesCount}</div>
          <Table style={tableStyle} responsive>
            <thead style={theadStyle}>
              { this.getTableData(toggleHandler) }
            </thead>
            <tbody>
              { this.generateRows(filteredDecArray, toggleHandler, selectedFreeResponseDecisions) }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}


export default FreeResponseDecisionsTable;
