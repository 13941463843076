import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import XLSX from 'xlsx';
import { CircularProgress } from '@mui/material';
import classes from '../../css/modules/RecalcUtilityScoresModal.module.css';
import useHttpGet from "../utilities/AxiosHttpGet";
import useHttpPut from "../utilities/AxiosHttpPut";
import useHttpPost from "../utilities/AxiosHttpPost";
import MeService from "../../services/MeService";
import BlockTypes from "../../classes/BlockTypes";
import "../../css/swytchbackStyles.css";
import SwytchbackFrameButton from "./controls/SwytchbackFrameButton";
import ImportMaxDiffUtilityScores from "./ImportMaxDiffUtilityScores";

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}


export default function AdvancedControls({user, block, handleClose, fetchDecisions}) {

  const importPreFlightMessage = "Recalculate utility scores based on the decisions submitted. "
  const importFileChosenMessage = "File selected and ready to import. Click Upload to import."
  const importImportingMessage = "Importing data. Stand by..."
  const importDoneMessage = "Data has been successfully imported."
  const importErrorMessage = "The data file is invalid."
  var reader = new FileReader();
  const newServerUrl = MeService.serverUrl + ":" + MeService.wm_new_port;

  const [importingState, setImportingState] = useState(null);
  const [importMessage, setImportMessage] = useState(importPreFlightMessage);
  const [stimuliData, setStimuliData] = useState(null);
  const [utilityScoreData, setUtilityScoreData] = useState(null);
  const [swydget, setSwydget] = useState(null);

  const { putData, putLoading, putError, put } = useHttpPut(user);
  const { postData, postLoading, postError, post } = useHttpPost(user);
  const { getData, getLoading, getError, get } = useHttpGet(user);

  useEffect(() => {

    setImportingState(importingState);
    if (importingState === "done") {
      setImportMessage(importDoneMessage);
    }

  }, [importingState]);


  const scoresUploadedCallback = (data) => {
    close();
    console.log(data);
  }

  const recalculateUtilityScores = () => {
    console.log("a little redirection... ");

    setImportMessage(importImportingMessage);
    setImportingState("Recalculating Utility Scores");
    const bt = block.getBlockType();

    if (bt) {
      if (bt.equals(BlockTypes.MAXDIFF)) {
        _recalculateUtilityScores()
      }
      else if (bt.equals(BlockTypes.PAIRWISE)) {
        _recalculateUtilityScores()
      }
    }  
    
  }

  const _recalculateUtilityScores = () => {

    const bt = block.getBlockType();
    
    const utilityScoresJsonArray = [];
    bt.recalculateUtilityScores(block);
    const utilityScoresArray = bt.generateUtilityScoreArray(block);

    if (utilityScoresArray.length > 0) {
      for (let i = 1; i < utilityScoresArray.length; i++) {
        const decArray = utilityScoresArray[i];
        const decJson = {};
        decJson["sys_RespNum"] = decArray[0];
        for (let j = 1; j < decArray.length; j++) {
          decJson["STIM_" + j] = decArray[j];
        }
        utilityScoresJsonArray.push(decJson);
      }
    }

    // I need to set the sitmuli data 
    const stimuliArray = block.orderedWyshList.getOrderedWyshes();

    let stimulusIndex = 1;
    const stimuliJsonArray = []
    for (const s of stimuliArray) {
      stimuliJsonArray.push({
        index: stimulusIndex,
        prd_uuid: s.product.productId,
        description: s.product.description
      });

      stimulusIndex += 1;
    }

    const parametersJson = {
      "stimuli": stimuliJsonArray,
      "utility_scores": utilityScoresJsonArray
    }


    const endpoint = MeService.serverUrl + ":" + MeService.wm_new_port + "/1/events/event/" + block.event.eventId + "/wyshes/" + block.wyshId + "/max_diff_scores";
    const encodedEndpoint = endpoint.replace(/#/g, "%23");

    console.log(parametersJson);
    post(encodedEndpoint, parametersJson, scoresUploadedCallback);
  }

  const close = () => {
    setImportMessage(importPreFlightMessage);
    setImportingState("ready");
    handleClose(swydget ? swydget.eventId : null);
  }

  const uploadDisabled = importingState === "importing" || importingState === "done" ? true : false;

  return (
    <div className='columnContainer halfPane'>
      <div className="widget fullWidth">
        <div className='titleContainer'>
          <div className='title'>
            Export:
          </div>
        </div>  
        <div className='content'>
          <div style={centeredContainerStyle}>
            <SwytchbackFrameButton 
              style={{width: '100%'}} 
              onClick={() => fetchDecisions()}
            >
              Export Block Data
            </SwytchbackFrameButton>
          </div>
        </div>
      </div>    
      { block.getBlockType().equals(BlockTypes.MAXDIFF) === true &&
      <div className="widget fullWidth">
        <div className='titleContainer'>
          <div className='title'>
            Import Scores:
          </div>
        </div>  
        <div className='content'>
          <div style={centeredContainerStyle}>
            <SwytchbackFrameButton
              style={{width: '100%' }}
              onClick={recalculateUtilityScores}
            >
              Recalculate Utility Scores
            </SwytchbackFrameButton>
            <div style={{margin: 20}}>
              or
            </div>
            <ImportMaxDiffUtilityScores
              user={user}
              handleClose={close}
              maxDiffBlock={block}
            />
          </div>
        </div>  
      </div>  
      }
    </div>  
  );
}
