import styled from '@emotion/styled';
import { Button } from '@mui/material';

const SwytchbackFrameButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#ffffff',
  border: '1px solid #ffffff',
  borderRadius: 10,
  textTransform: 'none', // Prevent text transformation
  '&:hover': {
    backgroundColor: '#595a5a', // Change background color on hover
  },
});

export default SwytchbackFrameButton;
