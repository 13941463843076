export default class PairwiseTrialSet
{
  constructor() {
    this.version = null;
    this.trialSetId = -1;
    this.itemIndices = [];
    this.items = [];
    this.decisions = [];
  }

  setWyshes(orderedWyshes) {

    let highestIndex = -1;

    for (const i of this.itemIndices){
      if (i > highestIndex) {
        highestIndex = i
      }
    }

    if (orderedWyshes.length >= highestIndex) {
      for (const i of this.itemIndices) {
        this.items.push(orderedWyshes[i - 1]);
      }
    }
  }

  
  initFromJson(json) {
    if (json) {
      if ("trial_set_id" in json) {
        this.trialSetId = json.trial_set_id;
      }
      if ("items" in json) {
        this.itemIndices = json.items;
      }
    }
  }

  static createFromJson(json, version) {
    var trialSet = new PairwiseTrialSet();
    trialSet.initFromJson(json);
    trialSet.version = version;
    return trialSet;
  }
}