import Snapshot from "./Snapshot";

export default class SnapshotContainer
{
  constructor(swydget) {
    this.swydget = swydget;
    this.snapshots = new Map();
    this.defaultColors = [
      "#002861",
      "#00543D",
      "#7C1700",
      "#A45A36",
      "#5A0059",
      "#505050",
      "#0046FF",
      "#00982B",
      "#B50000",
      "#FF9300",
      "#B519C7",
      "#929292",
      "#00C8FF",
      "#00DBA0",
      "#DE6EB6",
      "#FAC632",
      "#F57FFF",
      "#D5D5D5"
    ];
    
  }

  getSnapshotsArray() {
    var snapshots = [];

    for (const s of this.snapshots.values()) {
      snapshots.push(s);
    }

    return snapshots;
  }

  getSnapshot(idx) {
    return this.snapshots.get(idx.toString());
  }

  deleteSnapshot(idx) {
    this.snapshots.delete(idx.toString());
  }

  getSnapshots(snapshotIdsToDisplay) {
    return snapshotIdsToDisplay.map((id) => {
      return this.snapshots.get(id.toString());
    });
  }

  toJson() {
    var json = {}
    json['snapshots'] = {};

    for (const k of this.snapshots.keys()) {
      let snapshotJson = this.snapshots.get(k).toJson();
      json['snapshots'][k.toString()] = snapshotJson
    }

    return json;
  }

  getNextColor() {
    
    const usedColors = [];
    for (const s of this.snapshots.values()) {
      usedColors.push(s.color);
    }

    for (const color of this.defaultColors) {
      if (!usedColors.includes(color)) {
        return color
      }
    }

    return this.generateRandomHexColor();
  }

  generateRandomHexColor() {
    // Generate a random number between 0 and 0xFFFFFF (16777215 in decimal)
    const randomNum = Math.floor(Math.random() * 16777216);

    // Convert the random number to a hex string and pad with leading zeros if necessary
    const hexString = randomNum.toString(16).padStart(6, '0');

    // Return the hex color string prefixed with '#'
    return `#${hexString}`;
  }

  static createFromJson(json, swydget) {
    var sc = new SnapshotContainer(swydget);
    
    if (json.snapshots) {
      for (var k in json.snapshots) {
        sc.snapshots.set(k, Snapshot.createFromJson(json.snapshots[k], sc));
      }
    }

    return sc;
  }
}
