import React, { useState, useEffect } from "react";
import { Button, Image }  from 'react-bootstrap';
import QualtricsService from "../../services/QualtricsService";
import "../../css/integrations.css";
import "../../css/swydgetStyles.css";
import qualtricsLogo from '../../assets/integrations/qualtrics.png';


export default function QualtricsAdmin(props) {

  const [credsAreValid, setCredsAreValid] = useState(false);
  const [validatingCreds, setValidatingCreds] = useState(true);

  const isInstalled = props.user && props.user.isIntegrationInstalled("qualtrics") ? true : false

  const disconnect = () => {
    setCredsAreValid(false)
    props.disconnectMethod();
  }

  const whoAmICallback = (meDict) => {

    // SUCCESS:
    // {
    //   meta: {
    //     httpStatus: "200 - OK"
    //     requestId: "some-request-id"
    //   },
    //   result: {
    //     <A bunch of attributes from Qualtrics
    //   }
    // }
    //
    // INVALID:
    // {
    //   meta: {
    //     error: {
    //       errorCode: "AUTH_6.0"
    //       errorMessage: "invalid_client"
    //     },
    //     httpStatus: "401 - Unauthorized"
    //     requestId: "some-request-id"
    //   }
    // }

    setValidatingCreds(false)
    const meta = meDict.meta;
    if (meta.httpStatus && meta.httpStatus.startsWith("200")) {
      setCredsAreValid(true);
    }
    else {
      disconnect();
    }
    console.log(meta);
  }



  const validatingMessage = () => {

    if (validatingCreds) {
      console.log("Validating Creds");
      return (
        <div>
          Validating Qualtrics Credentials...
        </div>
      )
    }

    if (isInstalled && credsAreValid) {
      console.log("Connected and Valid");
      return (
        <React.Fragment>
          <Image className="logoStyle" src={qualtricsLogo} responsive/>
          <div className="disconnectButtonStyle">
            <Button
              className="swytchbackActiveButton"
              onClick={props.disconnectMethod}>
              Disconnect
            </Button>
          </div>
        </React.Fragment>
      )
    }

    return (
      <Button
        className="swytchbackActiveButton"
        onClick={props.installMethod}>
        Connect to Qualtrics
      </Button>
    )
  }

  useEffect(() => {
    // code to run on component mount
    QualtricsService.whoAmI(props.user, whoAmICallback);
  }, [])

  return (
    <div className="divHeaderButtonContainerStyle">
      <div>
        { validatingMessage() }
      </div>
    </div>
  );
}
