import React from 'react';


class ComingSoon extends React.Component {

  render() {

    return (
      <div>
        <h2>Coming Soon hmmm</h2>
        <div style={{
          border: '1px solid red',
          padding: 20
        }}>
          <iframe 
            src="https://live.swytchback.com/swydget/3f5/27?uid=shitbags" 
            width="700" 
            height="900"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
