export default class SurveyStatus
{
  constructor() {
    this.eventId = "";
    this.isComplete = false;
    this.completedTimestamp = "";
  }

  initFromJson(eventId, statusJson) {

    this.eventId = eventId;

    if (statusJson) {
      if (statusJson["completed"]) {
        this.isComplete = true;
        this.completedTimestamp = statusJson["completed"];
      }
    }
  }

  static createFromJson(eventId, statusJson)
  {
    var status = new SurveyStatus();
    status.initFromJson(eventId, statusJson);
    return status;
  }

}
