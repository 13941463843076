import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import User from "../../../classes/User";
import LoginStore from "../../../stores/LoginStore";
import FriendsTableRow from '../../../hooks/admin/user/FriendsTableRow';
import FriendsTable from '../../../hooks/admin/user/FriendsTable';
import { Add } from '@mui/icons-material';

const InviteSwydgetMember = ({ author, inviteExistingFriendHandler, invitees, isSwydgetImmutable }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(User.createUserFromJsonString(localStorage.getItem("loggedInUserJsonString")));

  useEffect(() => {
    const setLoggedInUser = () => {
      setLoggedInUser(LoginStore.getLoggedInUser());
    };
    LoginStore.on("loginStore_user_logged_in", setLoggedInUser);
    return () => {
      LoginStore.removeListener("loginStore_user_logged_in", setLoggedInUser);
    };
  }, []);

  const isFriendAlreadyInvited = (friend, friends) => {
    for (var i = 0; i < friends.length; i++) {
      if (friend.userId === friends[i].userId) {
        return true;
      }
    }
    return false;
  };

  const generateFriendRows = (invitees, inviteExistingFriendHandler, allInviteDisabled) => {
    var rows = [];
    if (loggedInUser && loggedInUser.myFriends) {
      for (var i = 0; i < loggedInUser.myFriends.length; i++) {
        if (loggedInUser.myFriends.userId !== loggedInUser.userId && !isFriendAlreadyInvited(loggedInUser.myFriends[i], invitees)) {
          rows.push(<FriendsTableRow
            key={i}
            user={loggedInUser.myFriends[i]}
            isConnected={true}
            actionHandler={inviteExistingFriendHandler}
            actionDisabled={allInviteDisabled}
            icon={<Add />}
            glyphiconStyle="success"
            confirmationTitle="Invite"
            confimationMessage={"Do you want to invite " + loggedInUser.myFriends[i].firstName + " " + loggedInUser.myFriends[i].lastName + " to collaborate on this Swydget?"}
            confirmationButtonText="Invite"
          />);
        }
      }
    }
    return rows;
  };

  const clearForm = () => {
    setInvitationStatus("");
  };

  const inviteFriend = (inviteFriendHandler) => {
    setInvitationStatus("Invitation sent to " + email);
    inviteFriendHandler(email, firstName, lastName);
  };

  const inviteExistingFriend = (friend) => {
    setInvitationStatus(friend.firstName + " " + friend.lastName + " (" + friend.userId + ") has been sent an invitation.");
    inviteExistingFriendHandler(friend);
  };

  const rows = generateFriendRows(invitees, inviteExistingFriend, isSwydgetImmutable);

  const containerStyle = {
    width: '100%',
    color: '#ffffff'
  };

  const formContainerDivStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  };

  const inviteStatusDivStyle = {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  };

  return (
    <div style={containerStyle}>
      <div style={inviteStatusDivStyle}><div>Author: {author}</div></div>
      <div style={inviteStatusDivStyle}><div>{invitationStatus}</div></div>
      <div style={formContainerDivStyle}>
        <FriendsTable rows={rows} />
      </div>
    </div>
  );
};

export default InviteSwydgetMember;
