import React from "react";
import { ProgressBar }  from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import '../../css/swytchbackStats.css';

ChartJS.register(ArcElement, Tooltip, Legend);

export default class BinaryResults extends React.Component {

  

  render() {

    const { wysh } = this.props;
    const { snapshot } = this.props;
    const { width } = this.props;

    const modeStyle = (this.props.mode ? (this.props.mode === 'dark' ? "smallLabelStyle dark" : "smallLabelStyle light") : "smallLabelStyle light");


    let yesWon = wysh.aggregatedResults.getPctLikedFloat() >= 50.0 ? true : false

    let cardWidth = (width) ? width : 250;

    var pctLiked = wysh.aggregatedResults.getPctLikedFloat(snapshot);
    var pctDisliked = wysh.aggregatedResults.getPctDislikedFloat(snapshot);
    var pctLikedLabel = wysh.aggregatedResults.getPctLiked(snapshot) + "% of people answered YES";
    var pctDislikedLabel = wysh.aggregatedResults.getPctDisliked(snapshot) + "% of people answered NO";

    let colStyle = {
      textAlign: 'left'
    }

    let progressBarContainerDivStyle = {
      marginTop: 0,
      width: cardWidth
    };

    const data = {
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: '# of Votes',
          data: [pctLiked, pctDisliked],
          backgroundColor: [
            '#0168EC',
            '#D5D5D5'
          ],
          borderColor: [
            '#0168EC ',
            '#D5D5D5'
          ],
          borderWidth: 1,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          labels: {
            // Customize legend text color here
            color: '#000000', // Example color
          },
        },
      },
    };

    return (
      <div style={colStyle}>
        <div style={progressBarContainerDivStyle}>
          <Doughnut data={data} options={options}/>
        </div>
      </div>
    );
  }
}
