import React from "react";

export default class MaxDiffStimuliStats extends React.Component {
  
  render() {

    const { wysh } = this.props;

    const modeStyle = (this.props.mode ? (this.props.mode === 'dark' ? "smallLabelStyle dark" : "smallLabelStyle light") : "smallLabelStyle light");

    const contentBox = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      minHeight: 140
    }

    const cellContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }

    const cellTopStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      fontWeight: 600,
      fontSize: 18
    }

    const cellBottomStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
      padding: 10
    }

    const circleStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '3px solid',
      borderColor: '#329147',
      borderRadius: '50%',
      backgroundColor: '#329147',
      width: '7vh',
      height: '7vh',
      padding: 0,
      margin: 0
    }

    const contentStyle = {
      fontsize: 11,
      color: '#ffffff',
    }

    return (
      <div style={contentBox}>
        <div style={cellContainerStyle}>
          <div className={modeStyle} style={cellTopStyle}>
            Utility Score:
          </div>
          <div style={cellBottomStyle}>
            <div style={circleStyle}>
              <div style={contentStyle}>
                {wysh.getAverageScore().toFixed(1)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
