import React from 'react';
import {
          FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,
          LinkedinShareButton, LinkedinIcon,
          PinterestShareButton, PinterestIcon, EmailShareButton, EmailIcon
        } from 'react-share';

export default class SocialSharingChannel
{

  constructor(id, name) {
    this.id = id;
    this.name = name
  }

  getShareButtons(eventInFocus) {

    const socialSharingChannels = {
      FACEBOOK: new SocialSharingChannel("facebook", "Facebook"),
      TWITTER: new SocialSharingChannel("twitter", "Twitter"),
      LINKED_IN: new SocialSharingChannel("linked_in", "LinkedIn"),
      PINTEREST: new SocialSharingChannel("pinterest", "Pinterest"),
      EMAIL: new SocialSharingChannel("email", "Email"),
    };

    var shareButtonsArray = [];

    for (var i = 0; i < eventInFocus.socialSharingChannels.length; i++) {
      switch (eventInFocus.socialSharingChannels[i]) {
        case socialSharingChannels.FACEBOOK.id: {
          shareButtonsArray.push(
            <FacebookShareButton
              url={eventInFocus.generateSwydgetUrl()}
              quote={eventInFocus.title}>
              <FacebookIcon
                size={44}
                round />
            </FacebookShareButton>
          );
          break;
        }
        case socialSharingChannels.TWITTER.id: {
          shareButtonsArray.push(
            <TwitterShareButton
              url={eventInFocus.generateSwydgetUrl()}
              title={eventInFocus.title}>
              <TwitterIcon
                size={44}
                round />
            </TwitterShareButton>
          );
          break;
        }
        case socialSharingChannels.LINKED_IN.id: {
          shareButtonsArray.push(
            <LinkedinShareButton
              url={eventInFocus.generateSwydgetUrl()}
              title={eventInFocus.title}
              windowWidth={750}
              windowHeight={600}>
              <LinkedinIcon
                size={44}
                round />
            </LinkedinShareButton>
          );
          break;
        }
        case socialSharingChannels.PINTEREST.id: {
          shareButtonsArray.push(
            <PinterestShareButton
              url={eventInFocus.generateSwydgetUrl()}
              media={eventInFocus.newestWysh.product.getSecureImageUrl(eventInFocus.newestWysh)}
              windowWidth={1000}
              windowHeight={730}>
              <PinterestIcon size={44} round />
            </PinterestShareButton>
          );
          break;
        }
        case socialSharingChannels.EMAIL.id: {
          shareButtonsArray.push(
            <EmailShareButton
              url={eventInFocus.generateSwydgetUrl()}
              subject={eventInFocus.title}
              body="body">
              <EmailIcon
                size={44}
                round />
            </EmailShareButton>
          );
          break;
        }
        default: {

        }
      }
    }

    return shareButtonsArray;
  }

}
