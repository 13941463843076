import React from "react";
import classes from "../../css/modules/Stats.module.css";
import BinaryResults from "./BinaryResults";
import StimulusBarAndLineCharts from "./StimulusBarAndLineCharts";
import BlockTypes from "../../classes/BlockTypes";
import MaxDiffStimuliResults from "./MaxDiffStimuliResults";
import PairwiseStimuliResults from "./PairwiseStimuliResults";

const AdminWyshResults = ({ wysh, snapshot, width, mode, snapshotsToDisplay }) => {
  let questionType = wysh.questionType;

  if (
    wysh.parentWysh &&
    wysh.parentWysh.isBlock() === true &&
    (wysh.parentWysh.getBlockType().equals(BlockTypes.MAXDIFF) === true ||
      wysh.parentWysh.getBlockType().equals(BlockTypes.PAIRWISE) === true)
  ) {
    questionType = wysh.parentWysh.getBlockType().blockTypeId;
  }

  let resultComponent;
  
  switch (questionType) {
    case "next":
      resultComponent = <div></div>;
      break;
    case "free-response-only":
      resultComponent = <div></div>;
      break;
    case "scalar":
      resultComponent = (
        <StimulusBarAndLineCharts wysh={wysh} snapshot={snapshot} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} defaultChart={"bar-vertical"} defaultHorizontal={false} />
      );
      break;
    case "multiple-choice":
      resultComponent = (
        <StimulusBarAndLineCharts wysh={wysh} snapshot={snapshot} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} />
      );
      break;
    case "single-select":
      resultComponent = (
        <StimulusBarAndLineCharts wysh={wysh} snapshot={snapshot} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} />
      );
      break;
    case BlockTypes.MAXDIFF.blockTypeId:
      resultComponent = (
        <MaxDiffStimuliResults block={wysh} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} />
      );
      break;
    case BlockTypes.PAIRWISE.blockTypeId:
      resultComponent = (
        <PairwiseStimuliResults block={wysh} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} />
      );
      break;
    default:
      resultComponent = (
        <BinaryResults wysh={wysh} snapshot={snapshot} width={width} mode={mode} snapshotsToDisplay={snapshotsToDisplay} />
      );
  }

  return (
    <div className={`${classes.centeredColumnContainer}`} style={{ width: '100%', height: '100%'}}>
      {resultComponent}
    </div>
  );
};

export default AdminWyshResults;
