import React from "react";
import { Image, Button }  from 'react-bootstrap';
import ProductStyles from "../../classes/ProductStyles";
import SocialSharingChannel from "../SocialSharingChannel";
import swytchbackLogoBorder from '../../SwytchbackLogoBorder.png';
import swytchbackDefaultWallpaper from '../../SwytchbackDefaultWallpaper-1200.png';


export default class SwydgetComplete extends React.Component {

  constructor(props){

    super();
    this.eventInFocus = {};
    this.navigateToRewardUrl = this.navigateToRewardUrl.bind(this);
    this.navigateToAppStore = this.navigateToAppStore.bind(this);
    
    this.queryStringParams = {}
    
    if (props.location) {
      const searchParams = new URLSearchParams(props.location.search);
    
      searchParams.forEach((key, value) => {
        this.queryStringParams[value] = key;
      })
    }
  }

  generateLogo() {
    let swytchStyle = {
      fontSize: 18,
      fontWeight: 'bold',
    };

    let backStyle = {
      fontSize: 18,
      fontWeight: 'lighter',
    };

    return <span><span style={swytchStyle}>Swytch</span><span style={backStyle}>back&#8480;</span></span>;
  }

  navigateToAppStore() {
    window.open('https://swytchback.com/', '_self');
    // window.open('https://itunes.apple.com/us/app/swytchback/id1178824453?mt=8', '_self');
  }

  navigateToRewardUrl() {
    window.location.href = this.eventInFocus.getRewardUrl(this.queryStringParams);
    // window.open(this.eventInFocus.getRewardUrl(this.queryStringParams), 'width=1000');
  }

  generateSocialSharingButtonRow() {
    var shareButtonsArray = new SocialSharingChannel("total", "kludge").getShareButtons(this.eventInFocus);

    var buttons = [];

    for (var i = 0; i < shareButtonsArray.length; i++) {
      buttons.push(
        <div key={i} style={{padding: 10}}>
          {shareButtonsArray[i]}
        </div>
      );


    }

    return buttons;

  }

  render() {

    const { height } = this.props;
    this.eventInFocus = this.props.eventInFocus;
    let productImageUrl = this.eventInFocus.getCoverPhotoUrl();

    var ht = 100;

    if (height) {
      ht = height;
    }

    var imageWidthAndHeight = ht * 0.28;
    var imageWidthAndHeightStyle = imageWidthAndHeight + 'vh';
    var bgImgUrl = swytchbackDefaultWallpaper;
    var bgAttributeValue = `url("${bgImgUrl}")`;
    let headerHeight = ht * 0.15 + 'vh';
    let taglineHeight = ht * 0.1 + 'vh';

    if (this.eventInFocus && this.eventInFocus.wallpaperUrl !== '') {
        bgImgUrl = this.eventInFocus.getWallpaperUrl();
        bgAttributeValue = `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${bgImgUrl}")`;
    }



    let containerDivStyle = {
      height: ht + 'vh',
      background:  bgAttributeValue,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: -10
    };

    let headerDivStyle = {
      height: headerHeight,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'column',
      border: 1
    };

    let taglineDivStyle = {
      height: taglineHeight,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      border: 1
    };

    let thumbnailImgStyle = {
      position: 'relative',
      width: imageWidthAndHeightStyle,
      minHeight: imageWidthAndHeightStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: "url('" + productImageUrl + "')",
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '50%',
      border: 6,
      borderStyle: 'solid',
      borderColor: '#ffffff'
    }

    let sectionContainerDivStyle = {
      height: ht*0.10 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
      paddingBottom: 10
    };

    let socialContainerDivStyle = {
      height: ht*0.1 + 'vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      zIndex: 10
    };

    let titleDivStyle = {
      marginLeft: 15,
      marginRight: 15,
      marginTop: 15,
      marginBottom: 15,
      color: '#fff',
      textAlign: 'center',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(this.eventInFocus.rewardText),
      fontWeight: 500
    };

    let completionImageDivStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: ht*0.3 + 'vh',
      zIndex: 10
    };

    let completionDivContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 300,
    };

    let swytchbackLogoStyle = {
      margin: 10,
      height: 30,
      width: 'auto',
      zIndex: 10
    };

    let defaultCardBackgroundStyle = {
      borderRadius: '50%',
      margin: 0,
      height: ht*0.1 + 'vh',
      width: ht*0.1 + 'vh',
      objectFit: 'cover'
    }

    let middleButtonStyle = {
      margin: 0,
      paddingLeft: 12,
      paddingRight: 12,
      height: 50,
      border: 1,
      borderStyle: 'solid',
      borderColor: '#a9a9a9',
      borderRadius: 6,
      color: '#ffffff',
      fontSize: ProductStyles.determineFontSizeBasedOnLength(this.eventInFocus.rewardButtonText),
      fontWeight: 500,
      background:  `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`
    };



    return (
      <div style={containerDivStyle}>
        <div style={headerDivStyle}>

        </div>
        <div style={taglineDivStyle}>
          <div style={titleDivStyle}>{this.eventInFocus.rewardText}</div>
        </div>
        <div key={0} style={completionImageDivStyle}>
          <div style={thumbnailImgStyle}>

          </div>
        </div>
        { this.eventInFocus.rewardUrl.length > 0 &&
          <div style={socialContainerDivStyle}>
            <Button style={middleButtonStyle} onClick={this.navigateToRewardUrl}>{this.eventInFocus.rewardButtonText}</Button>
          </div>
        }
        <div style={socialContainerDivStyle}>
          {this.generateSocialSharingButtonRow()}
        </div>
        <div style={sectionContainerDivStyle}>
          <div style={completionDivContainerStyle}>
            <Image onClick={this.navigateToAppStore} style={swytchbackLogoStyle} src={swytchbackLogoBorder} responsive="true"/>
          </div>
          <div style={completionDivContainerStyle}>
            <div>
              {this.generateLogo()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
