import React from "react";
import { Image }  from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import CardEditor from "../../components/admin/CardEditor";
import swydgetStore from "../../stores/SwydgetStore";
import noOverlayImage from '../../No-OverLay-400.png';
import yesOverlayImage from '../../Yes-OverLay-400.png';
import nextCardImage from '../../SwytchbackDefaultWallpaper-1192.png';
import defaultCardBackgroundImage from '../../assets/swydget/default-card-background.png';



export default class SwydgetForcedRankCardCollection extends React.Component {

  constructor() {
    super();
    this.processUserDecision = this.processUserDecision.bind(this);
    this.swipeExecuted = this.swipeExecuted.bind(this);
    this.wyshBucket = null;
    this.selectedCardIndex = 0;
    this.totalWyshBucketsCount = 0;
    this.loadedImageIds = new Set();

    this.state = {
      decision: "undefined",
      allImagesLoaded: false
    };
  };

  componentDidMount() {
    swydgetStore.on("change-userKeySet", this.processUserDecision);
    swydgetStore.on("change-SWYDGET_POST_PRODUCT_DECISION", this.swipeExecuted);
  }

  componentWillUnmount() {
    swydgetStore.removeListener("change-userKeySet", this.processUserDecision);
    swydgetStore.removeListener("change-SWYDGET_POST_PRODUCT_DECISION", this.swipeExecuted);
  }


  processUserDecision() {

    if (this.wyshBucket[this.selectedCardIndex].product.productId === swydgetStore.mostRecentDecisionProductId) {

      this.setState({ decision: swydgetStore.mostRecentDecision });

    }
  }

  swipeExecuted() {
    if (this.wyshBucket[this.selectedCardIndex].product.productId === swydgetStore.mostRecentDecisionProductId) {

      this.setState({ decision: swydgetStore.mostRecentDecision });

    }
  }

  onCardLoad(wyshId) {

    this.loadedImageIds.add(wyshId);
    
    if (this.areAllImagesLoaded()) {
      if (this.props.cardLoadedCallback) {
        this.props.cardLoadedCallback(new Date());
      }
    }
  }

  areAllImagesLoaded() {
    
    for (const w of this.wyshBucket) {
      if (!this.loadedImageIds.has(w.wyshId)) {
        return false;
      }
    }

    return true;
  }

  getProductImage(wysh, cardHeight, cardWidth) {

    let productCleanImageStyle = {
      width: cardWidth,
      height: cardHeight,
      objectFit: wysh ? wysh.product.styles.objectFit : "cover",
      background: 'linear-gradient(#0167EC, #00B6F9)',
      zIndex: -10
    };

    if (wysh.getStimuliImageUrl()) {
      return  <Image 
                key={wysh.wyshId}
                src={wysh.product.getSecureImageUrl()} 
                style={productCleanImageStyle}
                onLoad={() => this.onCardLoad(wysh.wyshId)}>
              </Image>
    }
    else {
      return <Image 
                key={wysh.wyshId}
                src={defaultCardBackgroundImage} 
                style={productCleanImageStyle}
                onLoad={() => this.onCardLoad(wysh.wyshId)}>
              </Image>
    }
  }

  getProductImages(cardHeight, cardWidth) {

    let hiddenStyle = {
      display: 'none'
    };
    
    const images = [];

    for (const wysh of this.wyshBucket) {
      images.push(this.getProductImage(wysh, cardHeight, cardWidth))
    }

    return images;
  }

  getProgressIndicator = (cardHeight, cardWidth) => {

    const showIndicatorStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: cardHeight,
      width: cardWidth,
      zIndex: 10
    };

    const hideIndicatorStyle = {
      display: 'none'
    }

    const indicatorStyle = {
      color: '#ffffff'
    }

    if (this.areAllImagesLoaded()) {
      return <div style={hideIndicatorStyle}></div>;
    }
    else {
      return <div style={showIndicatorStyle}>
        <CircularProgress style={indicatorStyle} size={cardWidth / 4}/>
      </div>
    }
  }

  render() {

    const { clickAction } = this.props;
    const { cardWidth } = this.props;
    const { cardHeight } = this.props;
    const { cardPosition } = this.props;
    const { forcedChoiceBlock } = this.props;

    this.wyshBucket = forcedChoiceBlock.orderedWyshList.getWyshes();
    this.selectedCardIndex = this.props.selectedCardIndex;
    this.totalWyshBucketsCount = this.props.totalWyshBucketsCount;
    
    if (this.selectedCardIndex >= this.wyshBucket.length) {
      this.selectedCardIndex = this.wyshBucket.length - 1;
    }

    let shadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%',
      WebkitBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      MozBoxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)',
      boxShadow: '0px 3px 20px 10px rgba(0,0,0,0.2)'
    };

    let noShadowCardStyle = {
      position: 'relative',
      overflow: 'hidden',
      width: cardWidth,
      height: cardHeight,
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5%'
    };

    let nextCardImageStyle = {
      width: cardWidth + 2,
      height: cardHeight + 2,
      objectFit: 'cover',
      zIndex: -10
    };

    var cardStyle = noShadowCardStyle;
    if (cardPosition && cardPosition.shadow === true) {
      cardStyle = shadowCardStyle;
    }

    let decisionDivStyle = {
      position: 'absolute',
      height: 100,
      width:100
    }

    var overlayImage = null;

    if (this.state.decision === "yes") {
      overlayImage = yesOverlayImage;
    }
    else if (this.state.decision === "no") {
      overlayImage = noOverlayImage;
    }

    overlayImage = null;



    return (

        <div style={cardStyle} onClick={() => clickAction()}>
          <div style={{backgroundColor: '#ffffff', zIndex: -100}}>
            { this.areAllImagesLoaded() && cardPosition && cardPosition.blur === false ? 
            this.getProductImage(this.wyshBucket[this.selectedCardIndex], cardHeight, cardWidth)
            :
            <Image src={nextCardImage} style={nextCardImageStyle}></Image>
            } 
            { CardEditor.toMarkup(this.wyshBucket[this.selectedCardIndex].product, 45) }
            { this.getProgressIndicator(cardHeight, cardWidth) }
            { overlayImage !== null ?
              <Image src={overlayImage} style={decisionDivStyle}></Image>
              :
              <div></div>
            }

          </div>
          { cardPosition && cardPosition.blur === false && 
            <div style={{display: 'none'}}>
          {this.getProductImages(cardHeight, cardWidth)}
            </div>
          } 
          
        </div>
    );
  }
}
